import { Box, Grid, Typography } from '@mui/material'
import React from 'react'
import {  Button,  Stack, useMediaQuery } from '@mui/material'
import { Link } from 'react-router-dom'

function Page404() { 
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));


  const buttonstyle={
    backgroundColor: '#019E6F',
        borderRadius: '12px',
        fontSize: '24px',
        width:"180px",
        height:"40px",
        flexShrink:0,
        marginTop:"17px",
   
        fontFamily:'Poppins',
        fontWeight:400,
  }
  return (
    <div>

 <Box className="box"sx={{ height: '100vh', width: '100%', position: 'relative',display:'flex', justifyContent:'center',alignItems:'center' }} >
 <Box
        className="rectangle"
        sx={{
          background: 'linear-gradient(248deg, #FFF6F0 -41.57%, rgba(255, 246, 240, 0.00) 97.88%)',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: -1,
        }}
      />

      <Grid container justifyContent='center' flexDirection='column' textAlign='center' >

      <Typography sx={{ height: isSmallScreen ? '250px' : '250px', textAlign:'center' }}>
  <img src='/404ErrorPage.png' alt='description' style={{ height: '100%', objectFit: 'cover' }} />
</Typography>

    <Typography variant='h2' sx={{color:'#000',fontFamily:'Poppins',fontSize:'50px',fontStyle:'normal',fontWeight:600}}>Page Not Found</Typography>
    <Typography sx={{textAlign:'center',color:'#8A8A8A',fontFamily:'Poppins',fontSize:'20px',fontStyle:'normal',fontWeight:400}} >We're sorry the page you requested could not be found <br/>  Please go back to the Home page.</Typography>
   
   <Link to='/dashboard'>
   <Button variant="contained" style={buttonstyle}>Go Home</Button>
   </Link>
      </Grid>
      
</Box>

    </div>
  )
}

export default Page404