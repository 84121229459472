import React from 'react'
import {
    Document,
    Page,
    PDFViewer,
    StyleSheet,
    Text,
    View,
  } from "@react-pdf/renderer";
export default function AdvisorAdditionalDetails({taskData,styles}) {
  return (
   <>
    <Text style={styles.text}>Additional Details</Text>
                <View style={styles.table}>
                  <View style={styles.tableRow}>
                    <Text style={styles.tableHeaderCell}>Title</Text>
                    <Text style={styles.tableHeaderCell}>Count</Text>
                  </View>
                  <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>Demo Scheduled</Text>
                  
                    <Text style={styles.tableCell}>{taskData?.totalDemos}</Text>
                  </View>
                  <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>Demo Completed</Text>
                    <Text style={styles.tableCell}>
                      {taskData?.completedDemos}
                    </Text>
                  </View>
                  <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>Demo Cancelled</Text>
                    <Text style={styles.tableCell}>
                      {taskData?.cancelledDemos}
                    </Text>
                  </View>
                  <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>Registration Count</Text>
                    <Text style={styles.tableCell}>
                      {taskData?.registrationCount}
                    </Text>
                  </View>

                  <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>Revenue Collected</Text>
                    <Text style={styles.tableCell}>
                      {taskData?.revenueCollected}
                    </Text>
                  </View>
                  <View style={styles.tableRow}>
                    <Text style={styles.tableCell}>Revenue Count</Text>
                    <Text style={styles.tableCell}>
                      {taskData?.revenueCount}
                    </Text>
                  </View>

                  <View style={styles.tableRow}>
                    <Text style={styles.tableCell}> Enrolled Count</Text>
                    <Text style={styles.tableCell}>
                      {taskData?.enrolledCount}
                    </Text>
                  </View>
                </View>
   </>
  )
}
