
// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, Button, ButtonBase } from '@mui/material';

// assets
import MenuIcon from '@mui/icons-material/Menu';

import LogoSection from '../LogoSection';
import AccountPopover from '../../../../components/header/AccountPopover';
import NotificationsPopover from '../../../../components/header/NotificationPopover';
import AddEnquiryPopover from './addEnquiryPopover/AddEnquiryPopover';
import MyTasReport from './tasks/MyTasReport';
import Search from './Search/Search';
import Notification from '../../../../components/header/Notification/Notification';


const Header = ({ handleLeftDrawerToggle }) => {
  const theme = useTheme();
  const userRole = localStorage.getItem('userRole');

 
  const navigateToZoomLink = () => {


    //navigate(zoomLink);

    const zoomLink = 'https://zoom.us/j/97485138279?pwd=Y1cwcXRHUWZWOFN3aTZOdmhHZm4yQT09#success';
    window.open(zoomLink, "_blank");
  };


  return (
    <>
      {/* logo & toggler button */}
      <Box
        sx={{
          width: 228,
          display: 'flex',
          [theme.breakpoints.down('md')]: {
            width: 'auto'
          }
        }}
      >
        <Box component="span" sx={{ display: { xs: 'none', md: 'block' }, flexGrow: 1 }}>
          <LogoSection />
        </Box>
        <ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.dark,
              color: '#fff',
              //color: theme.palette.secondary.light,
              '&:hover': {
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark
              }
            }}
            onClick={handleLeftDrawerToggle}
            color="inherit"
          >
            <MenuIcon stroke={1.5} size="1.3rem" />
          </Avatar>
        </ButtonBase>
      </Box>

      {/* header search */}

      
      
      <Box sx={{ flexGrow: 1 }} />
      
      <Box sx={{ flexGrow: 1 }} />
      <Box display='flex' >
      <Box marginRight={2}>
          <Button     
            variant="rounded"
            sx={{
              background: theme.palette.secondary.dark,
              color: '#fff',
              '&:hover': {
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark
              },
             borderRadius: '12px', 
             overflow: 'hidden' 
            }}
            onClick={navigateToZoomLink}
            color="inherit"
          >
            Join SVO Link
          </Button>
          </Box>
<Box marginRight={2}><Search/></Box>

          <Box marginRight={2}>
             <MyTasReport />
             </Box>

          {userRole !== 'trainer' && (
            <>
             

          <Box marginRight={2}>
            <AddEnquiryPopover />
          </Box>
          </>
        )}

      <Box marginRight={2}>
      <Notification />
      </Box>

      <Box marginRight={2}>
        <AccountPopover />
        </Box>
        </Box>
    </>
  );
};
export default Header;