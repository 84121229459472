import React, { useEffect, useState } from 'react'
import CustomSnackbar from '../../components/ui-component/snackbar/CustomSnackbar'
import { Backdrop, Button, Chip, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import MainCard from '../../../components/Maincard'
import { useNavigate } from 'react-router-dom'
import { fDate } from '../../../utils/formatTime'
import { useGetTokenOrLogout } from '../../../utils/token'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function RequestCallBack() {
    const navigate = useNavigate()
    const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem('userId');
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false)
  const [payments, setPayments] = useState([])
  const [requests, setRequests] = useState([])
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [originalData, setOriginalData] = useState([]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
      };
    
      const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
      };
    
      const [snackbarOpen, setSnackbarOpen] = React.useState(false);
      const [snackbarMessage, setSnackbarMessage] = React.useState('');
      const [snackbarSeverity, setSnackbarSeverity] = React.useState('success');
    
      const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };

      useEffect(() => {
        setIsLoading(true)
        // Fetch expenses data
        const token = getTokenOrLogout();
        if (!token) return;
        let headers = { 
          Authorization: `Bearer ${token}`,
        
        };
        fetch(`${API_ENDPOINT}route2.0/callbackreq/${userId}`,{headers})
          .then((response) => {
            if (!response.ok) {
              throw new Error(`${response.status}`);
            }
            return response.json();
          })
          .then((data) => {
            console.log(data)
            // Sort the filtered data by payment_date
            const sortedData = data.sort((a, b) => new Date(b.request_date) - new Date(a.request_date));
            setRequests(sortedData);
            console.log(sortedData)
            setIsLoading(false)
            
          })
          .catch((error) => {
            if (error.message.includes("401")) {
              navigate("/page401");
            } else if (error.message.includes("403")) {
              navigate("/page403");
            } else if (error.message.includes("404")) {
              navigate("/page404");
            }  else if (error.message.includes("440")) {
              navigate("/page440");
            } 
            else {
              navigate("/page500");
            }
            setIsLoading(false);
          
          });
      }, [userId]);

  return (
    <>
    <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
    <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>Fetching</Typography>
    <CircularProgress color="primary" />
  </Backdrop>


  <Grid container spacing={2} mb={2} > 
  <Grid item xs={12} lg={12} sm={12} md={12}>
  <MainCard>
        
          <Typography
            sx={{
              fontFamily: "Poppins",
              marginLeft: "5px",
              fontSize: "24px",
              fontWeight: 700,
            }}
          >
          Request CallBacks from Student Panel
          </Typography>
          </MainCard>
    </Grid>
   
</Grid>


  <TableContainer component={Paper} sx={{ borderRadius: '15px', padding: '10px' }}>
    <Table>
      <TableHead >
        <TableRow >
          <TableCell sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '18px', fontStyle: 'normal', fontWeight: 600 }}>Enquiry Name</TableCell>
          <TableCell sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '18px', fontStyle: 'normal', fontWeight: 600, }}>Mobile</TableCell>
          <TableCell sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '18px', fontStyle: 'normal', fontWeight: 600, }}>Email</TableCell>
          <TableCell sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '18px', fontStyle: 'normal', fontWeight: 600 }}>Nearest Branch</TableCell>
          <TableCell sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '18px', fontStyle: 'normal', fontWeight: 600 }}>Request Date</TableCell>
          <TableCell sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '18px', fontStyle: 'normal', fontWeight: 600 }}>Assigned To</TableCell>
          <TableCell sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '18px', fontStyle: 'normal', fontWeight: 600 }}>Note</TableCell>
          
        </TableRow>
      </TableHead>
      <TableBody>
        {requests.length === 0 ? (
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant='h5'>No data available</Typography>
          </Grid>
        ) : (
          requests.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
            <TableRow
                key={index}
                style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'inherit', width: '6%' }}
            >
              <TableCell  sx={{
                        color: "blue",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        "&:hover": {
                          textDecoration: "none",
                        },
                      }}
                      >
              {`${row?.first_name ? row?.first_name : ''} ${row?.last_name ? row?.last_name : ''}`} <br />
              </TableCell>
              <TableCell>{row?.mobile_country_code}{row?.mobile}</TableCell>
              <TableCell>{row?.email}</TableCell>
              <TableCell>{row?.nearest_branch_id?.branch_name}</TableCell>
              <TableCell>{fDate(row?.request_date)}</TableCell>
              <TableCell>{row?.request_owner?.first_name}</TableCell>
              <TableCell>{row?.note}</TableCell>
              
            </TableRow>
          )))}
      </TableBody>
    </Table>
    <TablePagination
      rowsPerPageOptions={[5, 10, 25]}
      component="div"
      count={payments.length}
      rowsPerPage={rowsPerPage}
      page={page}
      onPageChange={handleChangePage}
      onRowsPerPageChange={handleChangeRowsPerPage}
    />
  </TableContainer>

  <CustomSnackbar
    open={snackbarOpen}
    onClose={() => setSnackbarOpen(false)}
    message={snackbarMessage}
    severity={snackbarSeverity}
  />
  </>
  )
}

export default RequestCallBack