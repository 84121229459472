import { Box, CardContent, Chip, Collapse, Divider, Grid, Stack, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'

import MainCard from '../../newTestPrep/components/ui-component/card/MainCard';
import AdvisorWidget from '../../views/advisor/dashboard/AdvisorWidget';
import LeadWidget from './LeadWidget';

import ReactApexChart from 'react-apexcharts';
import LeadCalandar from './LeadCalandar';
import Progress from './Progress';
import ReportFilter from './ReportFilter';
import LineChartFilter from './LineChartFilter';
export default function LeadConversation() {
  const [isFilterOpen, setIsFilterOpen] = useState(false);
  const [isFilterOpen2, setIsFilterOpen2] = useState(false);

  const handleFilterToggle = () => {
    setIsFilterOpen((prevIsFilterOpen) => !prevIsFilterOpen);
  };

  const handleFilterToggle2 = () => {
    setIsFilterOpen2((prevIsFilterOpen) => !prevIsFilterOpen);
  };

    const cardData = [
        {
          title: 'New Enquiries',
          backgroundColor: '#fcdcb6',
          iconBackgroundColor: '#FB6D3E',
          value: 2,
          lineImage: '/new_EnquiryLine.svg',
          iconImage: '/new_Enquiry.svg',
        },
        {
          title: 'Demo Classes',
          backgroundColor: '#b6fcbd',
          iconBackgroundColor: '#019E6F',
          value: 0,
          lineImage: '/Demo_Line.svg',
          iconImage: '/demo_classes.svg',
        },
        {
          title: 'Student Enrolled',
          backgroundColor: '#d6e0ff',
          iconBackgroundColor: '#2F5EED',
          value: 0,
          lineImage: '/Student_Eline.svg',
          iconImage: '/student_enrolled.svg',
        },
      ];

      const seriesData = [68, 40, 23];
      const totalSum = seriesData.reduce((acc, val) => acc + val, 0);
  const percentages = seriesData.map((value) => ((value / totalSum) * 100).toFixed(2) + "%");
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'donut',
      },
      plotOptions: {
        pie: {
          donut: {
            size: '50%', // Adjust the size to control the width of the slices
          },
        },
      },
      labels: percentages,
      colors: ['#FF9E69', '#2B4DED', '#E0FBE7'],
      responsive: [{
        breakpoint: 480,
        options: {
          chart: {
            width: 200
          },
        }
      }],
      tooltip: {
        enabled: false,
      },
      legend: {
        show: false,
      },
    },
  });

    
      useEffect(() => {
        setChartData((prevData) => ({
          ...prevData,
          series: seriesData,
        }));
      }, []);

      const jsonData = [
        { value: 68, text:'Demo Classes this month',iconImage: '/Progress_Demo.svg', color:'#3243FD'},
        { value: 22,text:'Student Enrolled this month' ,iconImage: '/Enrolled_progress.svg', color:'#FD995D' },
        { value: 10,text:'Pending Enquiries  this month' ,iconImage: '/Enquiry_Progress.svg', color:'#019E6F'},
      ];

      // Line chart Code
      const initialSeriesData = [
        {
          name: "Student Enrolled",
          data: [31, 40, 28, 51, 42, 109, 100],
        },
        {
          name: "Demo Classes",
          data: [11, 32, 45, 32, 34, 52, 41],
        },
        {
          name: "New Enquiries",
          data: [34, 44, 58, 52, 62, 19, 10],
        },
      ];
      const [LinechartData, setLineChartData] = useState({
        series: [
         
        ],
        options: {
          chart: {
            height: 50,
            type: "area",
          
          },
          dataLabels: {
            enabled: false,
          },
          stroke: {
            curve: "smooth",
          },
          xaxis: {
            type: "datetime",
            labels: {
              show: false, // Set this to false to hide x-axis labels
            },
            categories: [
              "2018-09-19T00:00:00.000Z",
              "2018-09-19T01:30:00.000Z",
              "2018-09-19T02:30:00.000Z",
              "2018-09-19T03:30:00.000Z",
              "2018-09-19T04:30:00.000Z",
              "2018-09-19T05:30:00.000Z",
              "2018-09-19T06:30:00.000Z",
            ],
          },
    
          tooltip: {
            enabled: false,
            x: {
              format: "dd/MM/yy HH:mm",
            },
          },
        },
      });

      useEffect(() => {
        // You can perform any additional logic here if needed
    
        // For example, update the series data with some modified data
        const modifiedSeriesData = initialSeriesData.map((series) => ({
          ...series,
      
        }));
    
        // Update the LinechartData with the modified data
        setLineChartData((prevData) => ({
          ...prevData,
          series: modifiedSeriesData,
        }));
      }, []);
  return (
   

<Grid container spacing={2}>
      <Grid item xs={12} md={12} lg={12} container justifyContent="flex-start">
        <Typography sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '32px', fontStyle: 'normal', fontWeight: 600 }}>
          Lead Conversion Report
        </Typography>
        <Chip
          label="Filter"
          color="primary"
          variant="contained"
          sx={{ marginLeft: 2, cursor: 'pointer' }}
          onClick={handleFilterToggle}
        />
      </Grid>
   


      <Grid item xs={12} md={12} lg={12} container justifyContent="flex-start">
      <Collapse in={isFilterOpen}>
        <ReportFilter />
      </Collapse>
      </Grid>
       
    
      
{cardData.map((data, index) => (
        <LeadWidget key={index} {...data} />
      ))}

<Grid item xs={12} lg={3} md={6}>
    <MainCard>
  {/*}  <LeadCalandar/>*/}
 
      <Stack display='flex'  flexDirection='column' justifyContent='center' alignItems='center'>
      <Typography variant='h2' sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:500}}>Conversion Ratios</Typography>
<ReactApexChart options={chartData.options} series={chartData.series} type="donut" width='280px' />
</Stack>
</MainCard>
</Grid> 

<Stack>
  <Chip
          label="Filter"
          color="primary"
          variant="contained"
          sx={{ marginLeft: 2, cursor: 'pointer' }}
          onClick={handleFilterToggle2}
        />
  </Stack>
  
  <Grid item xs={12} md={12} lg={12} container justifyContent="flex-start">
      <Collapse in={isFilterOpen2}>
        <LineChartFilter />
      </Collapse>
      </Grid>

<Grid item xs={12} lg={9} md={9}>
<MainCard>
  <Stack display='flex' flexDirection='row' alignItems='center'>
    <Stack>
    <img src='/lead_Report.svg' /></Stack>
    <Stack sx={{marginLeft:'4px'}}>
  <Typography variant='h2' sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'24px' , fontStyle:'normal' , fontWeight:500}}>Lead Conversion Report</Typography>
  </Stack>
  </Stack>
<ReactApexChart
    options={LinechartData.options}
    series={LinechartData.series}
    type="area"
 height='300px'
  />
</MainCard>
</Grid>

<Grid item xs={12} lg={3} md={6}>
  <MainCard>
  {jsonData.map((data, index) => (
  <>
        <Progress key={index} value={data.value} img={data.iconImage} text={data.text} sx={{color:data.color}}/>
    
        {index < jsonData.length - 1 && <Divider flexItem style={{ margin: '10px' }} />}
        </>
      ))}
  </MainCard>
</Grid>
        </Grid>
     
        
   

  )
}
