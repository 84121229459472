import {
  Avatar,
  Badge,
  Box,
  Button,
  ButtonBase,
  CircularProgress,
  Divider,
  Popover,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Iconify from "../../iconify";
import NotificationPopup from "./NotificationPopup";
import NotificationComp from "./NotificationComp";
import { fDate, fDateTime, fTimestamp } from '../../../utils/formatTime';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function Notification() {
  const userId = localStorage.getItem("userId");
  const theme = useTheme();
  const [open, setOpen] = useState(null);
  const [displayAll, setDisplayAll] = useState(false); // State to control displaying all notifications
  const [openpopup, setopenpopup] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState(null);
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(false);
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleOpenpopup = (notification) => {
    setSelectedNotification(notification);
    setopenpopup(true);
    handleClose();
  };

  const handleClosepopup = () => {
    setopenpopup(false);
  };

  const dummyNotifications = [
    {
      id: 1,
      message: "You have a new message",
      timestamp: new Date(),
      info: "This is a important message",
    },
    {
      id: 2,
      message: "You have a new friend request",
      timestamp: new Date(),
    },
    {
      id: 3,
      message: "You have a new friend request1",
      timestamp: new Date(),
    },
    {
      id: 4,
      message: "You have a new friend request2",
      timestamp: new Date(),
    },
    {
      id: 5,
      message: "You have a new friend request3",
      timestamp: new Date(),
    },
    {
      id: 6,
      message: "You have a new friend request4",
      timestamp: new Date(),
    },
    {
      id: 7,
      message: "You have a new friend request5",

      timestamp: new Date(),
    },
  ];

  const fetchNotifications = async (userId) => {
    setLoading(true);
    try {
      const response = await fetch(
        `${API_ENDPOINT}notification/notificationbyuser/${userId}`
      );
      const data = await response.json();
      const sortedData = data.sort(
        (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
      );
      setNotifications(sortedData);

      console.log(data);
      setLoading(false);
    } catch (error) {
      console.log("Error:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchNotifications(userId);
  }, [userId]);

  const displayedNotifications = displayAll
    ? notifications
    : notifications.slice(0, 6);

  return (
    <>
      <Badge badgeContent={notifications.length} color="error">
        <ButtonBase sx={{ borderRadius: "12px", overflow: "hidden" }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: "all .2s ease-in-out",
              background: theme.palette.secondary.dark,
              color: '#fff',
              //color: theme.palette.secondary.light,
              '&:hover': {
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark
              }
            }}
            onClick={handleOpen}
            color="inherit"
          >
            <Iconify icon="eva:bell-fill" />
          </Avatar>
        </ButtonBase>
      </Badge>
      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
        PaperProps={{
          sx: {
            mt: 1.5,
            ml: 0.75,
            width: 360,
          },
        }}
      >
        <Box style={{ height: displayedNotifications.length > 6 ? "400px" : "auto" }}>
          <Box
            sx={{
              width: "100%",
              background: "#B9F6CA",
              display: "flex",
              justifyContent: "space-between",
              flexDirection: "row",
              alignItems: "center",
              height: "44px",
            }}
          >
            <Typography
              sx={{ fontSize: "16px", fontWeight: 600, marginLeft: "8px" }}
            >
              Notifications{" "}
            </Typography>
            <Button
              variant="contained"
              style={{
                borderRadius: "24px",
                height: "22px",
                background: "white",
                color: "green",
                marginRight: "8px",
              }}
              onClick={() => setDisplayAll(!displayAll)}
            >
              View {displayAll ? "Less" : "All"}
            </Button>
          </Box>
          {loading ? (
            <CircularProgress />
          ) : (
            <>
              {displayedNotifications.length === 0 ? (
                <Box
                  sx={{
                    height: "300px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  <Typography
                    textAlign="center"
                    sx={{ fontSize: "18px", fontWeight: 500 }}
                  >
                    No Notification
                  </Typography>
                </Box>
              ) : (
                <>
                  {displayedNotifications.map((notification, index) => (
                    <React.Fragment key={notification.id}>
                      <Box
                        sx={{
                          padding: "12px",
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                        onClick={() => handleOpenpopup(notification)}
                      >
                        <ButtonBase
                          sx={{
                            borderRadius: "50px",
                            overflow: "hidden",
                            marginRight: "8px",
                          }}
                        >
                          <Avatar
                            variant="rounded"
                            sx={{
                              ...theme.typography.commonAvatar,
                              ...theme.typography.mediumAvatar,
                              transition: "all .2s ease-in-out",
                              background: theme.palette.secondary.light,
                              color: theme.palette.secondary.dark,
                              "&:hover": {
                                background: theme.palette.secondary.dark,
                                color: theme.palette.secondary.light,
                              },
                              fontSize: "1.5rem",
                            }}
                            color="inherit"
                          >
                            <Iconify icon="eva:bell-fill" />
                          </Avatar>
                        </ButtonBase>
                        <Stack sx={{display:'flex' , flexDirection:'column',}}>
                        <Typography sx={{fontSize:'15px'}}>
                          {notification.notification_title}
                        </Typography>
                       
                        <Typography sx={{fontSize:'12px', color:'grey'}}>{fDateTime(notification.createdAt)}</Typography>
                        </Stack>
                        {notification.notification_priority && (
                            <sup style={{ marginLeft: '4px', fontSize: '3em' }}>
                            <img src='/priority.png' alt="New" style={{ width: '30px', height: '30px', verticalAlign: 'middle' }} />
                          </sup>
                          )}
                      </Box>
                      {index < displayedNotifications.length - 1 && <Divider />}
                    </React.Fragment>
                  ))}
                </>
              )}
            </>
          )}
        </Box>
      </Popover>

      <Box sx={{ position: "absolute", top: 0, right: 5 }}>
        <NotificationPopup
          open={openpopup}
          onClose={handleClosepopup}
          component={
            <NotificationComp
              onClose={handleClosepopup}
              selectedNotification={selectedNotification}
              notifications={notifications}
              setNotifications={setNotifications}
            />
          }
        />
      </Box>
    </>
  );
}
