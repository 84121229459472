import { Box, CircularProgress, Divider, Stack, Typography } from '@mui/material';
import React from 'react'

export default function Progress(props) {
 
  return (
<>
<Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }} >
      <Box sx={{ position: 'relative', display: 'inline-flex' }}>
        <CircularProgress variant="determinate" {...props}  />
        <Box
          sx={{
            top: 0,
            left: 0,
            bottom: 0,
            right: 0,
            position: 'absolute',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography variant="caption" component="div"   >
           
            <img src={props.img} alt="caption" />
          </Typography>
        </Box>
      </Box>
   
      <Box ml={2} sx={{display:'flex' ,flexDirection:'column'}}>
        <Typography sx={{color:'#000' , fontFamily:'Poppins', fontSize:'24px',fontStyle:'normal', fontWeight:600}}>{props.value} %</Typography>
        <Typography variant='body2' sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'16px' , fontStyle:'normal' , fontWeight:400}}>{props.text}</Typography>
      </Box>
   
    </Box>
    </>
);
}
  
