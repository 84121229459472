import React, { useEffect, useState } from 'react'
import ReactApexChart from 'react-apexcharts';

export default function RevenueChart({data}) {
 
      const [chartData, setChartData] = useState({
        series: [],
        options: {
          chart: {
            type: 'bar',
            height: 350,
          },
          plotOptions: {
            bar: {
              horizontal: false,
              columnWidth: '55%',
              endingShape: 'rounded',
              roundedBars: true,
    
            },
          },
          dataLabels: {
            enabled: false,
          },
    
          stroke: {
            show: true,
            width: 2,
            colors: ['transparent'],
          },
    
          xaxis: {
            categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
          },
          yaxis: {
            title: {
              // text: '$ (thousands)',
            },
          },
          fill: {
            opacity: 1,
            colors: ['#2F5EED', '#FF9E69', '#04C088'],
          },
          tooltip: {
            enabled: false,
            y: {
              formatter: function (val) {
                return `$ ${val} thousands`;
              },
            },
          },
        },
      });
    
      useEffect(() => {
        setChartData((prevData) => ({
          ...prevData,
          series: data.map(({ name, data }) => ({ name, data })),
        }));
      });
    
  return (
   <>
    <ReactApexChart className="rounded-bars-chart" options={chartData.options} series={chartData.series} type="bar" height={350} />
   </>
  )
}
