import React, { useEffect, useState } from 'react';
import { Box, Card, CardContent, Typography, Chip, Grid, IconButton, Tooltip } from '@mui/material';
import { Email, Phone, WhatsApp } from '@mui/icons-material';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CallIcon from '@mui/icons-material/Call';
import CustomSnackbar from '../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar';
import CancelIcon from "@mui/icons-material/Cancel";
import CheckIcon from "@mui/icons-material/Check";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const EnquiryDetailsCard = ({ enquiryData }) => {
  const userId = localStorage.getItem('userId');
  const userRole = localStorage.getItem('userRole');
  const {
    id,
    first_name,
    last_name,
    status,
    email,
    mobile,
    mobile_country_code,
    whatsapp_country_code,
    whatsapp_number,
    test_type,
    course,
    advisor_name,
    advisor_id,
    assign_to,
    test_type_id,
    prospective_level_id,
    sub_service_id,
    sub_service
  } = enquiryData;

  const [recycleRequest, setRecycleRequest] = useState(null)

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  
      const showSnackbar = (message, severity) => {
          setSnackbarMessage(message);
          setSnackbarSeverity(severity);
          setSnackbarOpen(true);
      };

  const handleClickToCall = () => {
    const data = {
      user_id: parseInt(userId),
      enquiry_id: id
    };

    console.log(data)

    // Access the authorization token from environment variables
    const authToken = process.env.REACT_APP_CALL_TOKEN;

    // Send the POST request to the server
    fetch(`${API_ENDPOINT}clicktocall`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          console.log('Success')
          showSnackbar('Call Connected', 'success');
          alert('Call Connected')
        } else {
          console.log('Error:', response)
          showSnackbar('Call Not Connected', 'error');
          alert('Call Not Connected')
        }
      })
      .catch((error) => {
        console.error('Error submitting data:', error);
        showSnackbar('Call Not Connected', 'error');
        alert('Error Connecting Call')
      });

  }

  const handleCancelRecycleRequest = () => {
    const data = {
      user_id: parseInt(userId),
      //enquiry_id: id,
      status: 'cancelled',
      is_active: false,
    };

    console.log(data)

    // Send the POST request to the server
    fetch(`${API_ENDPOINT}recyclerequest/updatebyEnquiryId/${id}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          console.log('Success')
          showSnackbar('Recycle Request cancelled successfully', 'success');
          alert('Recycle Request cancelled successfully')
          window.location.reload()
        } else {
          console.log('Error:', response)
          showSnackbar('Error cancelling recycle request', 'error');
          alert('Error cancelling recycle request')
        }
      })
      .catch((error) => {
        console.error('Error submitting data:', error);
        showSnackbar('Network Error cancelling recycle request. Please try again', 'error');
        alert('Network Error cancelling recycle request. Please try again')
      })
    }

    const handleAcceptRecycleRequest = () => {
      const data = {
        user_id: parseInt(userId),
        //enquiry_id: id,
        status: 'accepted',
        is_active: false,
      };
  
      console.log(data)
  
      // Send the POST request to the server
      fetch(`${API_ENDPOINT}recyclerequest/updatebyEnquiryId/${id}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            console.log('Success')
            showSnackbar('Recycle Request accepted successfully', 'success');
            alert('Recycle Request accepted successfully')
            window.location.reload()
          } else {
            console.log('Error:', response)
            showSnackbar('Error accepting recycle request', 'error');
            alert('Error accepting recycle request')
            //window.location.reload()
          }
        })
        .catch((error) => {
          console.error('Error submitting data:', error);
          showSnackbar('Network Error accepting recycle request. Please try again', 'error');
          alert('Network Error accepting recycle request. Please try again')
          //window.location.reload()
        })
      }


  useEffect(() => {
    fetch(`${API_ENDPOINT}recyclerequest/fetchbyEnquiryId/${id}`)
    .then(response => response.json())
    .then(data => {
     // data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
     console.log(data)
      setRecycleRequest(data);
    })
    .catch(error => {
      console.error('Error fetching requests items:', error);
    });
  }, [id]);
    console.log(recycleRequest)

  return (
    <>
    <Card>
      <CardContent>
        <Box display="flex" justifyContent="space-between">
          <Box display='flex'>
          <Typography variant="h3">{first_name} {last_name}</Typography>
          {prospective_level_id && <Chip label={prospective_level_id?.prospective_level} color="secondary" />}
          {recycleRequest && (
             <>
            <Chip color="error" label='Recycle Request Pending' />
                {(userRole === 'data_entry' || userRole === 'center_admin') && (
                   <>
                <Tooltip title="Cancel Recycle Request" arrow>
                  <IconButton color="error" onClick={handleCancelRecycleRequest}>
                    <CancelIcon />
                  </IconButton>
                </Tooltip>

                <Tooltip title="Accept Recycle Request" arrow>
                  <IconButton color="secondary" onClick={handleAcceptRecycleRequest}>
                    <CheckIcon />
                  </IconButton>
                </Tooltip>
                   </>
                )}
              </>
            )}
         
          </Box>

          <Box>

            <IconButton onClick={handleClickToCall} color='secondary'>
              <CallIcon />
            </IconButton>
          </Box>
        </Box>
        <Box mt={2}>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <strong>Email:</strong> {email}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            {/* <strong>Mobile:</strong> {mobile_country_code}{(userRole==="advisor" || userRole==="trainer") ?mobile?.replace(/.(?=.{4,}$)/g, '*') :  mobile} */}
       <strong>Mobile:{mobile_country_code}{mobile}</strong>
       
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            {/* <strong>WhatsApp:</strong> {whatsapp_country_code}{(userRole==="advisor" || userRole==="trainer") ?whatsapp_number?.replace(/.(?=.{4,}$)/g, '*') :  whatsapp_number} */}
            <strong>Whatsapp :{mobile_country_code}{whatsapp_number}</strong>
         </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <strong>Sub Service:</strong> {sub_service || sub_service_id?.name}
          </Typography>
        </Grid>
       
        <Grid item xs={12} sm={6} md={4}>
          <Typography variant="body1">
            <strong>Advisor:</strong> {assign_to?.first_name}
          </Typography>
        </Grid>
      </Grid>
    </Box>
      </CardContent>
    </Card>

      <CustomSnackbar
      open={snackbarOpen}
      onClose={() => setSnackbarOpen(false)}
      message={snackbarMessage}
      severity={snackbarSeverity}
      />
</>
  );
}


export default EnquiryDetailsCard;
