import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, Grid, InputAdornment, InputLabel, Menu, MenuItem, OutlinedInput, Pagination, Select, Typography } from '@mui/material';

// project imports
import MainCard from '../../components/ui-component/card/MainCard'; 
import { gridSpacing } from '../../store/constant'; 

// assets
import SearchIcon from '@mui/icons-material/Search';
import ExpandMoreRoundedIcon from '@mui/icons-material/ExpandMoreRounded';
import TrainerList from './TrainerList';

// ==============================|| USER LIST STYLE 1 ||============================== //

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

const MainTrainerPage = () => {
    const theme = useTheme();
    const [anchorEl, setAnchorEl] = useState(null);
    const [branches, setBranches] = useState([])
    const [searchQuery, setSearchQuery] = useState('');
    const [filterStatus, setFilterStatus] = useState('')
    const [filterBranch, setFilterBranch] = useState('')

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value);
    };

    const handleStatusFilterChange = (event) => {
        setFilterStatus(event.target.value);
      };
    
      const handleBranchFilterChange = (event) => {
        setFilterBranch(event.target.value);
      };

    useEffect(() => {
        // Fetch menu items using the fetch function
        fetch(`${API_ENDPOINT}branch`)
          .then(response => response.json())
          .then(data => {
            setBranches(data);
            console.log(data)
          })
          .catch(error => {
            console.error('Error fetching branches:', error);
          });
      }, []);

    return (
        <MainCard
            title={
                <>
                <Grid container justifyContent='space-between' spacing={gridSpacing}>
                    <Grid item xs={12} md={6} lg={4}>
                    <Grid item>
                        <Typography variant="h3">Trainer List</Typography>
                    </Grid>
                    <Grid item>
                        <OutlinedInput
                            id="input-search-list-style1"
                            placeholder="Search by name"
                            startAdornment={
                                <InputAdornment position="start">
                                    <SearchIcon stroke={1.5} size="16px" />
                                </InputAdornment>
                            }
                            size="small"
                            value={searchQuery}
                            onChange={handleSearchChange}
                        />
                    </Grid>
                    </Grid>

                   
                    <Grid item  xs={6} md={4} lg={3}>
                             <InputLabel style={{ color: 'black' }} >Filter by Branch</InputLabel>
                                <Select
                                    //value={filter.branch}
                                    //onChange={(e) => handleFilterChange('branch', e.target.value)}
                                    //displayEmpty
                                    value={filterBranch}
                                    onChange={handleBranchFilterChange}
                                    fullWidth={true}
                                    placeholder='Filter by Branch'
                                >
                                    <MenuItem value=''>All</MenuItem>
                                    {branches.map((branch) => (
                                        <MenuItem key={branch.id} value={branch.id}>{branch.branch_name} </MenuItem>
                                    ))}
                                </Select>
                            </Grid>

                            <Grid item  xs={6} md={4} lg={3}>
                            <InputLabel style={{ color: 'black' }} >Filter by Status</InputLabel>
                                <Select
                                    //value={filter.branch}
                                    //onChange={(e) => handleFilterChange('branch', e.target.value)}
                                    //displayEmpty
                                    value={filterStatus}
                                    onChange={handleStatusFilterChange}
                                    fullWidth={true}
                                    placeholder='Filter by Status'
                                >
                                    <MenuItem value=''>All</MenuItem>
                                   <MenuItem value='active'>Active</MenuItem>
                                   <MenuItem value='inactive'>Inactive</MenuItem>
                                </Select>
                            </Grid>
               </Grid> 
              
               </>
            }
            content={false}
        >
            <TrainerList searchQuery={searchQuery} filterBranch={filterBranch} filterStatus={filterStatus}/>
         
        </MainCard>
    );
};

export default MainTrainerPage;