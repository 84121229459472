import React, { useState } from 'react'
import AdminDasboardOverview from '../AdminDashboardOverview/AdminDasboardOverview';
import AdminMainPage from '../AdminDashboard.jsx/AdminMainPage';
import AdminDashboardStudent from '../AdminDasboardStudent/AdminDashboardStudent';
import AdminDashboardRevenue from '../AdminDashboardRevenue/AdminDashboardRevenue';
import { Chip, FormControl, InputLabel, MenuItem, Select, Stack, Typography } from '@mui/material';

export default function MainTabs() {
    const data = [
        { id: 1, name: 'Overview ' },
       // { id: 2, name: 'Leads' },
        { id: 3, name: 'Students' },
        // {id:4 ,name:'Employees'},
        {id:5,name:'Revenue'}
        // Add more categories as needed
      ];

      const branches = [
        { id: 'branch1', name: 'Branch 1' },
        { id: 'branch2', name: 'Branch 2' },
        // Add more branches as needed
      ];
    
      const [selectedCategory, setSelectedCategory] = useState(1);
      const [selectedBranch, setSelectedBranch] = useState(null);
    
      const handleChipClick = (categoryId) => {
        setSelectedCategory(categoryId === selectedCategory ? null : categoryId);
      };
    
      const handleBranchChange = (event) => {
        setSelectedBranch(event.target.value);
      };
    

      const renderSelectedComponent = () => {
        switch (selectedCategory) {
          case 1:
            return <AdminDasboardOverview />;
          case 2:
            return <AdminMainPage />;
          case 3:
            return <AdminDashboardStudent />;
          case 4:
            // Render the component for Employees when the category is 4
            // Replace the following line with the appropriate component for Employees
            return <div>Employees Component</div>;
          case 5:
            return <AdminDashboardRevenue />;
          default:
            return null;
        }
      };
  return (
   <>
    <Typography sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'32px' , fontStyle:'normal' , fontWeight:600}}>Admin Dashboard</Typography>
    <Stack direction='row' spacing={1} alignItems='center' sx={{marginBottom:'10px'}} justifyContent='space-between' >
      <Stack direction='row' spacing={1} alignItems='center'>
     {data.map(item => (
   <Chip
      size='medium'
     key={item.id}
     label={item.name}
     onClick={() => handleChipClick(item.id)}
     style={{
       backgroundColor: item.id === selectedCategory ? '#019E6F' : null, // Set your desired background color
       color: item.id === selectedCategory ? '#ffffff' : null, // Set your desired text color
     }}
   />
 ))}
 </Stack>
 <Stack>

 <FormControl sx={{ m: 1, minWidth: 150 }} size="small">
  <InputLabel id="demo-simple-select-label">Filter By Branch</InputLabel>
<Select 
  labelId="demo-simple-select-label"
  id="demo-simple-select"
  value={selectedBranch} 
  onChange={handleBranchChange} 
  >
          <MenuItem value={null}>All Branches</MenuItem>
          {branches.map((branch) => (
            <MenuItem key={branch.id} value={branch.id}>
              {branch.name}
            </MenuItem>
          ))}
        </Select>
        </FormControl>
        </Stack>
 </Stack>

 {renderSelectedComponent()}
   </>
  )
}
