// Author : Ayesha Shaikg
// Date : 02 aug 2024

import { useTheme } from "@emotion/react";
import {
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

export default function OtherTestTypeComp({
  formData,
  handleChange,
  errors,
  handleCriteria,
  setSelectedClassData,
  loading,
  trainerSlots,
  handleBookDemo,
  ButtonName,
  rescheduleReason,
  rescheduleReasons,
  setRescheduleReason,
}) {
  const theme = useTheme();
  return (
    <>
      <Grid item xs={12} lg={12}>
        <Button
          variant="contained"
          color="primary"
          onClick={() => handleCriteria("date")}
        >
          Search Demo Dates
        </Button>
      </Grid>

      <>
        {trainerSlots.length !== 0 && (
          <Grid item xs={12}>
            <Typography variant="h4">
              Demo Slots Available for {formData.demo_date}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} textAlign="center">
          {loading && <CircularProgress />}
        </Grid>

        <Grid
          item
          xs={12}
          textAlign="center"
          sx={{
            display: "flex !important",
            flexDirection: "column !important",
            marginBottom: "1rem",
            marginTop: "1rem",
          }}
        >
          {trainerSlots.length === 0 ? (
            <Typography textAlign="center">
              No slots found for the selected criteria.
            </Typography>
          ) : (
            <Grid container spacing={2}>
              {trainerSlots.map((slot) => (
                <Grid item xs={6} key={slot.id} lg={4} md={4} sm={6}>
                  <Card
                    sx={{
                      padding: "16px",
                      background:
                        theme.palette.mode === "dark"
                          ? theme.palette.dark.main
                          : theme.palette.grey[50],
                      border: "1px solid",
                      borderColor:
                        theme.palette.mode === "dark"
                          ? theme.palette.dark.main
                          : theme.palette.grey[100],
                      "&:hover": {
                        border: `1px solid${theme.palette.primary.main}`,
                      },
                    }}
                  >
                    <CardContent>
                      <Typography variant="h4">
                        Class Name: {slot?.class_name}
                      </Typography>
                      <Typography variant="h4">
                        Trainer: {slot.trainer_id?.first_name}
                      </Typography>
                      <Typography variant="body2">
                        Slot: {slot.slots}
                      </Typography>
                      <FormControlLabel
                        control={
                          <Radio
                            checked={formData.selectedSlot === slot.class_id}
                            onChange={() => {
                              handleChange({
                                target: {
                                  name: "selectedSlot",
                                  value: slot.class_id,
                                },
                              });

                              const selectedClassData = {
                                slot: slot.slots,
                                trainer_id: slot.trainer_id.id,
                              };
                              setSelectedClassData(selectedClassData);
                            }}
                            value={slot.class_id}
                            name="selectedSlot"
                          />
                        }
                        label="Select"
                      />
                    </CardContent>
                  </Card>
                </Grid>
              ))}
            </Grid>
          )}
        </Grid>
      </>

      <Grid item xs={12} lg={12} mt={2}>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12}>
            <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
              {" "}
              Additional Information
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <FormControl>
                  <Typography>
                    Has student taken exam before?{" "}
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        marginLeft: "4px",
                        fontSize: "1em",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <RadioGroup
                    row
                    name="exam_already_given"
                    value={formData?.exam_already_given}
                    onChange={handleChange}
                    error={errors?.exam_already_given}
                    helperText={
                      errors?.exam_already_given ? "Please choose any one" : ""
                    }
                  >
                    <FormControlLabel
                      value="YES"
                      control={<Radio />}
                      label="YES"
                    />
                    <FormControlLabel
                      value="NO"
                      control={<Radio />}
                      label="NO"
                    />
                  </RadioGroup>

                  {errors?.exam_already_given && (
                    <FormHelperText style={{ color: "red" }}>
                      Please choose one option
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {formData?.exam_already_given === "YES" && (
                <Grid item xs={12} lg={4}>
                  <Typography>
                    Overall Band Score{" "}
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        marginLeft: "4px",
                        fontSize: "1em",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <TextField
                    name="score"
                    value={formData.score}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    type="number"
                    error={errors.score}
                    helperText={errors.score ? "Score is required" : ""}
                  />
                </Grid>
              )}

              {formData?.exam_already_given === "NO" && (
                <Grid item xs={12} lg={4}>
                  <Typography>
                    Target Overall Band Score{" "}
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        marginLeft: "4px",
                        fontSize: "1em",
                      }}
                    >
                      *
                    </span>
                  </Typography>
                  <TextField
                    name="band_score_required"
                    value={formData?.band_score_required}
                    onChange={handleChange}
                    size="small"
                    fullWidth
                    type="number"
                    error={errors?.band_score_required}
                    helperText={
                      errors?.band_score_required
                        ? "Overall Score is required"
                        : ""
                    }
                  />
                </Grid>
              )}
            </Grid>
          </Grid>

          {/* Exam Book */}
          <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} lg={6}>
                <FormControl>
                  <Typography>
                    Has student booked the exam ?{" "}
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        marginLeft: "4px",
                        fontSize: "1em",
                      }}
                    >
                      *
                    </span>{" "}
                  </Typography>
                  <RadioGroup
                    row
                    name="is_exam_booked"
                    value={formData?.is_exam_booked}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="YES"
                      control={<Radio />}
                      label="YES"
                    />
                    <FormControlLabel
                      value="NO"
                      control={<Radio />}
                      label="NO"
                    />
                  </RadioGroup>
                  {errors?.is_exam_booked && (
                    <FormHelperText style={{ color: "red" }}>
                      Please choose one option
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>

              {formData?.is_exam_booked === "YES" && (
                <Grid item xs={12} lg={4}>
                  <>
                    <Typography>
                      Date of exam{" "}
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1em",
                        }}
                      >
                        *
                      </span>
                    </Typography>
                    <TextField
                      name="tentative_date"
                      value={formData.tentative_date}
                      onChange={handleChange}
                      size="small"
                      fullWidth
                      type="date"
                    />
                  </>
                </Grid>
              )}

              {formData?.is_exam_booked === "NO" && (
                <Grid item xs={12} lg={4}>
                  <>
                    <Typography>
                      Tentative Date of exam{" "}
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1em",
                        }}
                      >
                        *
                      </span>
                    </Typography>
                    <TextField
                      name="tentative_date"
                      size="small"
                      value={formData.tentative_date}
                      onChange={handleChange}
                      fullWidth
                      type="date"
                    />
                  </>
                </Grid>
              )}
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12}>
            <FormControl fullWidth>
              <Typography>
                How has student prepared for this test?{" "}
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1em",
                  }}
                >
                  *
                </span>
              </Typography>
              <Select
                size="small"
                name="preparation"
                value={formData?.preparation}
                onChange={handleChange}
                error={errors?.preparation}
              >
                <MenuItem value="self">Self</MenuItem>
                <MenuItem value="other institute">Other Institute</MenuItem>
                <MenuItem value="no">No Prepared</MenuItem>
              </Select>
              {errors?.preparation && (
                <FormHelperText style={{ color: "red" }}>
                  Preparation is required
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          {formData.preparation === "other institute" && (
            <Grid item xs={12} lg={12}>
              <Typography>
                Enter Institute Name{" "}
               
              </Typography>
              <TextField fullWidth size="small" name="institute_name" value={formData.institute_name} onChange={handleChange}/>
            </Grid>
          )}

          {/* Reason For Reschedule */}
          {ButtonName === "Reschedule Demo" && (
            <Grid item xs={12} lg={12}>
              <FormControl fullWidth>
                <Typography>
                  Reason for reschedule{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1em",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Select
                  size="small"
                  name="rescheduleReason"
                  value={rescheduleReason}
                  onChange={(event) => setRescheduleReason(event.target.value)}
                >
                  {rescheduleReasons.map((item) => (
                    <MenuItem key={item.id} value={item.id}>
                      {item.reschedule_reason}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>
      </Grid>

      {/* Additional Note */}

      <Grid item xs={12}>
        <Typography>Note (Optional)</Typography>
        <TextField
          name="note"
          placeholder="Type Anything..."
          variant="outlined"
          fullWidth
          value={formData.note}
          onChange={handleChange}
          multiline
          rows={4}
        />
      </Grid>

      <Grid item xs={12} textAlign="end">
        <Button
          variant="contained"
          color="primary"
          onClick={handleBookDemo}
          disabled={!formData.selectedSlot}
        >
          {ButtonName}
        </Button>
      </Grid>
    </>
  );
}
