import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import MainCard from "../../components/ui-component/card/MainCard";
import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import EditIcon from "@mui/icons-material/Edit";
import { useEffect, useState } from "react";
import FilterListIcon from "@mui/icons-material/FilterList";
import { fDate } from "../../../utils/formatTime";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";
//import Chip from "../../components/ui-component/chip/Chip";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function ViewAttendance() {
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const userId = localStorage.getItem('userId');
  console.log(userId)
  const [selectedStudent, setSelectedStudent] = useState(null);
  const [isDialogOpen, setDialogOpen] = useState(false);
  const [isDialogOpen2, setDialogOpen2] = useState(false);
  const [filteredData, setFilteredData] = useState([]);
  const [studentData, setStudentData] = useState([]);
  const [selectedStudentAttendance, setSelectedStudentAttendance] = useState(
    []
  );
  const [branchlist, setbranchlist] = useState([]);
  const [trainerlist, settrainerlist] = useState([]);
  const [selectedBranchIds, setSelectedBranchIds] = useState([]);
  const [trainerid, setselectedtrainerid] = useState([]);
  const [classlist, setclasslist] = useState([]);
  const statusOptions = [
    { value: "active", label: "Active" },
    { value: "inactive", label: "Inactive" },
  ];
  // console.log(" branch id is",selectedBranchIds)
  const [selectedStatus, setSelectedStatus] = useState("");
  const [selectedClassId, setSelectedClassId] = useState(null)
  const [selectedStudentId, setSelectedStudentId] = useState(null)
  const [selectedDate, setSelectedDate] = useState('')

  const handleStatusChange = (event) => {
    setSelectedStatus(event.target.value);
  };

  const [filters, setFilters] = useState({
    enrollId: "",
    studentName: "",
    enrollmentDateFrom: null,
    enrollmentDateTo: null,
    className: [],
    trainerName: [],
    status: [],
    branch: [],
  });

  const [isFilterOpen, setFilterOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const toggleFilterSection = () => {
    setFilterOpen(!isFilterOpen);
  };

  const handleFilterChange = (field) => (e) => {
    if (field === "branch") {
      const selectedBranchNames = e.target.value;

      // Find the selected branch IDs based on the selected branch names
      const selectedBranchIds = branchlist
        .filter((branch) => selectedBranchNames.includes(branch.branch_name))
        .map((branch) => branch.id);

      setSelectedBranchIds(selectedBranchIds);
    }

    if (field === "trainerName") {
      const selectedtrainerNames = e.target.value;
      console.log(selectedtrainerNames);

      // Find the selected branch IDs based on the selected branch names
      const selectedtrainerIds = trainerlist
        .filter((trainer) =>
          selectedtrainerNames.some((name) =>
            `${trainer.first_name} ${trainer.last_name}`.includes(name)
          )
        )
        .map((trainer) => trainer.id);

      setselectedtrainerid(selectedtrainerIds);
    }

    setFilters({
      ...filters,
      [field]: e.target.value,
    });
  };

  console.log("branch id is", selectedBranchIds);
  console.log("trainer id is ", trainerid);

  const handleDateChange = (date) => {
    setFilters({
      ...filters,
      enrollmentDate: date,
    });
  };

  const applyFilters = () => {
    const filteredRows = studentData.filter((row) => {
      // Check each filter criteria
      const enrollIdMatch = filters.enrollId
        ? String(row?.lead_no)?.includes(filters.enrollId)
        : true;

      const studentNameMatch = filters.studentName
        ? `${row.class_details_id[0]?.enquiry_id?.first_name} ${row.class_details_id[0]?.enquiry_id?.last_name}`
            .toLowerCase()
            .includes(filters.studentName.toLowerCase())
        : true;

      const enrollmentDateMatch =
        !filters.enrollmentDateFrom ||
        !filters.enrollmentDateTo ||
        (new Date(row?.joining_date).toISOString().slice(0, 10) >=
          filters.enrollmentDateFrom &&
          new Date(row?.joining_date).toISOString().slice(0, 10) <=
            filters.enrollmentDateTo);

      const branchMatch = filters.branch.length
        ? filters.branch.includes(
            row?.class_details_id[0]?.class_id?.branch_id?.branch_name
          )
        : true;

      const trainerNameMatch = filters.trainerName.length
        ? filters.trainerName.some((name) =>
            `${row.class_details_id[0]?.class_id?.trainer_id?.first_name} ${row.class_details_id[0]?.class_id?.trainer_id?.last_name}`
              .toLowerCase()
              .includes(name.toLowerCase())
          )
        : true;

      const classNameMatch = filters.className.length
        ? filters.className.includes(row.class_details_id[0]?.class_id?.name)
        : true;

      const statusMatch = filters.status.length
        ? filters.status.includes(row.status_of_student.toLowerCase())
        : true;

      // Return true if all criteria match
      return (
        enrollIdMatch &&
        studentNameMatch &&
        enrollmentDateMatch &&
        branchMatch &&
        trainerNameMatch &&
        classNameMatch &&
        statusMatch
      );
    });

    // Update 'filteredData' state with the filtered rows
    setFilteredData(filteredRows);
  };

  const resetFilters = () => {
    setFilters({
      enrollId: "",
      studentName: "",
      enrollmentDateFrom: "",
      enrollmentDateTo: "",
      className: [],
      trainerName: [],
      status: [],
      branch: [],
    });
    setFilteredData(studentData);
  };

  function handleEditAttendance(date, status) {
    try {
      setSelectedDate(date);
      setSelectedStatus(status);
      setDialogOpen2(true);
    } catch (error) {
      console.error("Error editing attendance:", error);
      showSnackbar("Error editing attendance:", "error");
    }
  }

  const handleViewAttendance = async (studentId, classId) => {
    try {
      const response = await fetch(`${API_ENDPOINT}route/viewattendance`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ student_id: studentId, class_id: classId }),
      });

      if (!response.ok) {
        throw new Error("Failed to fetch attendance data");
      }

      const attendanceData = await response.json();

      // Assuming you want to set the selected student data, if needed
      const student = studentData.find((student) => student.id === studentId);
      setSelectedStudent(student);

      setSelectedStudentAttendance(attendanceData);
      setDialogOpen(true);

      setSelectedStudentId(studentId)
      setSelectedClassId(classId)
    } catch (error) {
      console.error("Error fetching attendance data:", error);
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen(false);
  };

  const handleCloseDialog2 = () => {
    setDialogOpen2(false);
  };

  
  useEffect(() => {
    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
      
    };
    const fetchDemoData = async () => {
      try {
        // Fetch demo data based on the user ID
        const response = await fetch(
          `${API_ENDPOINT}route/studentattlist/${userId}`,{headers}
        );

        if (response.ok) { const data = await response.json();
          console.log(data);
  
          setStudentData(data);
          setFilteredData(data);
  
          setIsLoading(false);}
          // Handle non-successful responses
          else if (response.status === 404) {
            navigate("/page404");
          } else if (response.status === 401) {
            navigate("/page401");
          } else if (response.status === 403) {
            navigate("/page403");
          } else if (response.status === 440) {
            navigate("/page440");
          } else {
            navigate("/page500");
          }}
            catch{
              navigate("/page500");
            }
    };

    // Only fetch data if userId is available
    if (userId) {
      fetchDemoData();
    }
  }, [userId]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/fetchbranch/${userId}`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setbranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      if (!selectedBranchIds || selectedBranchIds.length === 0) {
        settrainerlist([]);
        return;
      }

      try {
        const branchPromises = selectedBranchIds.map((id) =>
          fetch(`${API_ENDPOINT}route/trainers/${id}`)
        );
        const branchResponses = await Promise.all(branchPromises);
        const branchData = await Promise.all(
          branchResponses.map((response) => response.json())
        );
        const combinedTrainers = branchData.flat(); // Combine trainers from all branches
        settrainerlist(combinedTrainers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [selectedBranchIds]);

  useEffect(() => {
    const fetchData = async () => {
      if (!trainerid || trainerid.length === 0) {
        setclasslist([]);
        return;
      }

      try {
        const branchPromises = trainerid.map((id) =>
          fetch(`${API_ENDPOINT}route/classbytrainer/${id}`)
        );
        const branchResponses = await Promise.all(branchPromises);
        const branchData = await Promise.all(
          branchResponses.map((response) => response.json())
        );
        const combinedTrainers = branchData.flat(); // Combine trainers from all branches
        setclasslist(combinedTrainers);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [trainerid]); // This useEffect hook depends on `trainerid`

  const columns = [
    {
      field: "lead_no",
      headerName: "Enroll ID",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "studentName",
      headerName: "Student Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div>
          {params.row?.class_details_id[0]?.enquiry_id?.first_name}{" "}
          {params.row.class_details_id[0]?.enquiry_id?.last_name}
        </div>
      ),
    },
    {
      field: "joining_date",
      headerName: "Enrollment Date",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => <div>{fDate(params.value)}</div>,
    },
    {
      field: "branch_id",
      headerName: "Branch",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div>
          {params.row?.class_details_id[0]?.class_id?.branch_id?.branch_name}
        </div>
      ),
    },
    {
      field: "trainerName",
      headerName: "Trainer Name",
      flex: 1,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div>
          {params.row?.class_details_id[0]?.class_id?.trainer_id?.first_name}{" "}
          {params.row?.class_details_id[0]?.class_id?.trainer_id?.last_name}
        </div>
      ),
    },
    {
      field: "class_details_id",
      headerName: "Class",
      flex: 2,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <div>{params.row?.class_details_id[0]?.class_id?.name}</div>
      ),
    },

    {
      field: "status_of_student",
      headerName: "Status",
      flex: 1,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "present_days",
      headerName: "Present Days",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "absent_days",
      headerName: "Days Absent",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "skipped_days",
      headerName: "Days Skipped",
      flex: 0.5,
      headerClassName: "super-app-theme--header",
    },
    {
      field: "viewAttendance",
      headerName: "View Attendance",
      flex: 1.5,
      headerClassName: "super-app-theme--header",
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() =>
            handleViewAttendance(
              params.row?.class_details_id[0]?.student_id?.id,
              params.row?.class_details_id[0]?.class_id?.id
            )
          }
        >
          View Attendance
        </Button>
      ),
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          style={{ flexShrink: 0 }}
          width="240"
          height="200"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }

  const attendanceColumns = [
    { field: "date", headerName: "Date", flex: 1 },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      renderCell: (params) => {
        let chipColor = "";
        let chipLabel = "";

        switch (params.value) {
          case "1":
            chipColor = "secondary";
            chipLabel = "Present";
            break;
          case "0":
            chipColor = "error";
            chipLabel = "Absent";
            break;
          case "-1":
            chipColor = "primary";
            chipLabel = "Absent with permission";
            break;
          default:
            chipColor = "orange";
            chipLabel = "Unknown";
        }

        return (
          <Chip
            label={chipLabel}
            color={chipColor === "default-color" ? "default" : chipColor}
          />
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      renderCell: (params) => (
        <>
          <Button
            variant="contained"
            color="error"
            startIcon={<EditIcon />}
            onClick={() =>
              handleEditAttendance(params.row.date, params.row.status)
            }
          >
            Edit
          </Button>
        </>
      ),
    },
  ];

  function handleEditAttendanceConfirmed(status) {
    try {
      const data = {
        date: selectedDate,
        class_id: selectedClassId,
        attendance_val: status
      };

      if(selectedStudentId) {

      fetch(`${API_ENDPOINT}attendance/updateattendanceby/${selectedStudentId}`, {
            method: 'PUT',
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(data),
      })
        .then((response) => {
          if (response.ok) {
            showSnackbar("Attendance edited successfully", "success");
            handleCloseDialog2()
            handleCloseDialog()
          } else {
            showSnackbar("Failed to edit attendance", "error");
          }
        })
        .catch((error) => {
          console.error("Error editing attendance:", error);
          showSnackbar("Error editing attendance:", "error");
        });
    }
   } catch (error) {
      console.error("Error editing attendance:", error);
      showSnackbar("Error editing attendance:", "error");
    }
  }

  function CustomExportButton({ onClick }) {
    return (
      <Button onClick={onClick} variant="contained" color="secondary">
        Export CSV
      </Button>
    );
  }

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Stack direction='column' spacing={1}>
        <Typography variant="h4" color="secondary" fontWeight={800} marginRight={2}>Fetching</Typography>
        <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <MainCard>
            <Box display="flex" justifyContent="space-between">
              <Box>
                <Typography variant="h3">Student Attendance</Typography>
              </Box>
              <Box>
                <IconButton onClick={toggleFilterSection}>
                  <FilterListIcon />
                </IconButton>
              </Box>
            </Box>
            <Collapse in={isFilterOpen}>
              <Grid container spacing={2}>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    label="Enroll ID"
                    variant="outlined"
                    value={filters.enrollId}
                    onChange={handleFilterChange("enrollId")}
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    label="Student Name"
                    variant="outlined"
                    value={filters.studentName}
                    onChange={handleFilterChange("studentName")}
                    fullWidth
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    label="Enrollment Date From"
                    variant="outlined"
                    value={filters.enrollmentDateFrom}
                    onChange={handleFilterChange("enrollmentDateFrom")}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <TextField
                    label="Enrollment Date To"
                    variant="outlined"
                    value={filters.enrollmentDateTo}
                    onChange={handleFilterChange("enrollmentDateTo")}
                    fullWidth
                    type="date"
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Branch</InputLabel>
                    <Select
                      multiple
                      value={filters.branch}
                      input={<OutlinedInput label="branch" />}
                      onChange={handleFilterChange("branch")}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {branchlist.map((option) => (
                        <MenuItem key={option.id} value={option.branch_name}>
                          {option.branch_name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Trainer Name</InputLabel>
                    <Select
                      multiple
                      value={filters.trainerName}
                      input={<OutlinedInput label="Trainer Name" />}
                      onChange={handleFilterChange("trainerName")}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {trainerlist.map((option) => (
                        <MenuItem
                          key={option.id}
                          value={`${option.first_name} ${option.last_name}`}
                        >
                          {`${option.first_name} ${option.last_name}`}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Class Name</InputLabel>
                    <Select
                      multiple
                      value={filters.className}
                      input={<OutlinedInput label="Class Name" />}
                      onChange={handleFilterChange("className")}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {classlist.map((option) => (
                        <MenuItem key={option.id} value={option.name}>
                          {option.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item xs={12} sm={6} md={4} lg={3}>
                  <FormControl fullWidth>
                    <InputLabel>Status</InputLabel>
                    <Select
                      multiple
                      value={filters.status}
                      input={<OutlinedInput label="Status" />}
                      onChange={handleFilterChange("status")}
                      renderValue={(selected) => selected.join(", ")}
                    >
                      {statusOptions.map((option) => (
                        <MenuItem key={option.value} value={option.value}>
                          {option.label}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid
                  item
                  xs={12}
                  sm={12}
                  lg={12}
                  md={12}
                  sx={{ textAlign: "center" }}
                >
                  <Button
                    variant="contained"
                    style={{
                      background: "#4242f5",
                      width: "150px",
                      marginRight: "7px",
                    }}
                    onClick={applyFilters}
                  >
                    {" "}
                    Apply Filter
                  </Button>
                  <Button
                    variant="contained"
                    style={{
                      background: "#f7690a",
                      width: "150px",
                      marginRight: "7px",
                    }}
                    onClick={toggleFilterSection}
                  >
                    {" "}
                    Cancel
                  </Button>
                  <Button
                    variant="contained"
                    style={{ width: "150px" }}
                    onClick={resetFilters}
                  >
                    {" "}
                    Reset Filter
                  </Button>
                </Grid>
              </Grid>
            </Collapse>
          </MainCard>
        </Grid>

        <Grid item xs={12}>
          <MainCard>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              "& .super-app-theme--header": {
                backgroundColor: "#abf7b1",
                fontWeight: "bold",
              },
            }}
          >
            <DataGrid
              rows={filteredData}
              columns={columns}
              autoHeight
              pageSize={10}
              //rows={[]}
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
           
              }}
            
              
            />
          </Box>
          </MainCard>
        </Grid>
      </Grid>

      <Dialog
        open={isDialogOpen}
        onClose={handleCloseDialog}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent>
          {selectedStudent && (
            <>
              <Typography variant="h4" gutterBottom>
                {`${selectedStudent.class_details_id[0]?.enquiry_id?.first_name}'s Attendance`}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Enrollment No: {selectedStudent.lead_no}
              </Typography>
              <Typography variant="subtitle1" gutterBottom>
                Class: {selectedStudent.class_details_id[0]?.class_id?.name}
              </Typography>

              {/* DataGrid for attendance */}
              <DataGrid
                rows={selectedStudentAttendance}
                columns={attendanceColumns}
                getRowId={(row) => row?.date}
                pageSize={10}
                autoHeight
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                  toolbar: GridToolbar,
                }}
                sx={{
                  "--DataGrid-overlayHeight": "300px",
                  backgroundColor: "rgba(255,255,255)",
                  boxShadow: 2,
                  border: 2,
                  borderColor: "primary.light",
                  "& .MuiDataGrid-cell:hover": {
                    color: "primary.main",
                  },
                }}
              />
            </>
          )}
        </DialogContent>
      </Dialog>

      <Dialog
        open={isDialogOpen2}
        onClose={handleCloseDialog2}
        maxWidth="xs"
        fullWidth
      >
        <DialogTitle variant="h4">Select Status</DialogTitle>
        <DialogContent>
          <FormControl fullWidth>
            <InputLabel>Status</InputLabel>
            <Select value={selectedStatus} onChange={handleStatusChange}>
              <MenuItem value={"1"}>Present</MenuItem>
              <MenuItem value={"0"}>Absent</MenuItem>
              <MenuItem value={"-1"}>Absent with permission</MenuItem>
             
            </Select>
          </FormControl>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => handleEditAttendanceConfirmed(selectedStatus)}
            color="secondary"
            variant="contained"
          >
            Confirm
          </Button>
          <Button onClick={handleCloseDialog2} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>
      <CustomSnackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          severity={snackbarSeverity}
          />
    </>
  );
}

export default ViewAttendance;
