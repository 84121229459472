import React, { useState } from "react";
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Box, Typography } from '@mui/material';
import MainCard from "../../newTestPrep/components/ui-component/card/MainCard";

const PersistentNotification = ({ open, handleClose, handleMarkRead, message }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Box style={{ maxWidth: '100%' }}>
    <MainCard >
        <Box sx={{display:'flex' , justifyContent:'center', alignItems:'center' , flexDirection:'column'}}>
        <Box  sx={{width:'80px' ,height:'50px'}}>
        <img src='/bell.png' width='100%'/>
        </Box>
        <Typography sx={{marginTop:'45px' , fontWeight:600, fontSize:'22px'}}>{message}</Typography>
        <Typography sx={{fontSize:'18px' , fontWeight:500}}></Typography>
        <Button  variant='contained'  color='error' style={{borderRadius:'24px' ,marginTop:'50px', fontSize:'18px'}} onClick={handleMarkRead}>Close</Button>
        </Box>
    </MainCard>
    </Box>
      </DialogContent>
    </Dialog>
  );
};

export default PersistentNotification;
