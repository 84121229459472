import {
  Autocomplete,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import AddCircleIcon from "@mui/icons-material/AddCircle";
import { DataGrid } from "@mui/x-data-grid";
import DeleteIcon from "@mui/icons-material/Delete";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
export default function RepresentativeForm({ onClose }) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [eventName, setEventName] = useState(null);
  const [eventList, setEventList] = useState([]);
  const [userList, setUserList] = useState([]);
  const [userName, setUserName] = useState(null);
  const [deskTypeList, setDeskTypeList] = useState([]);
  const [deskType, setdeskType] = useState(null);
  const [deskNumber, setDeskNumber] = useState(null);
  const [countryList, setCountryList] = useState([]);
  const [countryName, setCountryName] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [serViceName, setserviceName] = useState(null);
  const [subServiceList, setSubserviceList] = useState([]);
  const [subserviceName, setSubserviceName] = useState(null);
  const [errorMessage, setErrorMessage] = useState({});

  const [representativeData, setRepresentativeData] = useState([]);
  const [idCounter, setIdCounter] = useState(0);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [eventLoading,setEventLoading]=useState(false)

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  useEffect(() => {
    const fetchData = async () => {
setEventLoading(true)
      try {
        const response = await fetch(`${API_ENDPOINT}campaign/event/withname`);
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
          const filteredcampaign = data.filter(
            (campaign) => campaign.campaign_type_id?.id === 1
          );
          setEventList(filteredcampaign);
setEventLoading(false)
          console.log("data1", filteredcampaign);
        } else {
          setEventLoading(false)
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        setEventLoading(false)
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const handleEventChange = (event,value) => {
    setEventName(value);
    setErrorMessage((prev) => ({ ...prev, eventName: "" }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUserList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleuserChange = (event,value) => {
    setUserName(value);
    setErrorMessage((prev) => ({ ...prev, userName: "" }));
  };

  const handleDeskTypeChange = (e) => {
    setdeskType(e.target.value);
    setErrorMessage((prev) => ({ ...prev, deskType: "" }));
  };

  const handleDeskNumberChange = (e) => {
    setDeskNumber(e.target.value);
    setErrorMessage((prev) => ({ ...prev, deskNumber: "" }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}service`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setServiceList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleServiceChange = (e) => {
    setserviceName(e.target.value);
    setErrorMessage((prev) => ({ ...prev, serViceName: "" }));
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setSubserviceList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleSubServiceChange = (e) => {
    setSubserviceName(e.target.value);
    setErrorMessage((prev) => ({ ...prev, subserviceName: "" }));
  };

  const handleDelete = (id) => {
    setRepresentativeData(representativeData.filter((data) => data.id !== id));
  };

  const columns = [
    {
      field: "event",
      headerName: "Event",
      width: 450,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "user",
      headerName: "User",
      width: 170,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "deskType",
      headerName: "Desk Type",
      width: 170,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "deskNumber",
      headerName: "Desk Number",
      width: 170,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "service",
      headerName: "Service",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "subService",
      headerName: "Sub Service",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "country",
      headerName: "Country",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
    },
    {
      field: "action",
      headerName: "Action",
      width: 100,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          <DeleteIcon
            color="error"
            onClick={() => handleDelete(params.row?.id)}
          />{" "}
        </div>
      ),
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }

  console.log("eventName", eventName);

  const handleAdd = () => {
    let errors = {};
    if (!eventName) errors.eventName = "Please select an event.";
    if (!userName) errors.userName = "Please select a user.";
    if (!deskType) errors.deskType = "Please enter desk type.";
    if (!deskNumber) errors.deskNumber = "Please enter desk number.";

    if (!serViceName) errors.serViceName = "Please select a service.";
    if (!subserviceName) errors.subserviceName = "Please select a sub-service.";

    if (Object.keys(errors).length > 0) {
      setErrorMessage(errors);
      return;
    }
    const selectedEvent = eventList.find((event) => event.id === eventName.id);

    const selectedUser = userList.find((user) => user.id === userName.id);
    const selectedService = serviceList.find(
      (service) => service.id === serViceName
    );
    const selectedSubService = subServiceList.find(
      (subService) => subService.id === subserviceName
    );
    const newRepresentative = {
      id: idCounter, // Use idCounter as the unique ID
      event: selectedEvent ? selectedEvent.campaign_name : null,
      eventId: eventName.id,
      user: selectedUser
        ? `${selectedUser.first_name} ${selectedUser.last_name}`
        : "",
      userId: userName.id,
      deskType: deskType,
      deskNumber: deskNumber,
      service: selectedService ? selectedService.service_name : null,
      serviceId: serViceName,
      subService: selectedSubService ? selectedSubService.name : null,
      subServiceId: subserviceName,
      country: countryName,
    };
    setRepresentativeData([...representativeData, newRepresentative]);
    // Reset form fields if needed
    setIdCounter(idCounter + 1);
    setEventName(null);
    setUserName(null);
    setDeskNumber("");
    setdeskType(null);
    setserviceName(null);
    setSubserviceName(null);
  };

  console.log("representativeData", representativeData);

  const handleSubmit = async () => {
    const dataToSubmit = representativeData.map((data) => ({
      event_id: data.eventId,
      counsellor_id: data.userId,
      registration_desk: data.deskType,
      desk_number: parseInt(data.deskNumber),
      service_id: data.serviceId,
      sub_service_id: data.subServiceId,
      user_id: parseInt(userId),
    }));
    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/createeventrep`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(dataToSubmit),
      });
      if (response.ok) {
        const result = await response.json();
        console.log("Data submitted successfully:", result);
        showSnackbar("Data submitted successfully");
        setTimeout(() => {
          onClose();
        }, 1500);
      } else if (response.status === 403) {
        showSnackbar("User is  already registered for the  event ", "error");
      } else if (response.status === 404) {
        showSnackbar("Failed To Create ", "error");
        setTimeout(() => {
          onClose();
        }, 1500);
      } else if (response.status === 500) {
        showSnackbar("Please Select Event ", "error");
      } else {
        setTimeout(() => {
          onClose();
        }, 1500);
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error submitting data:", error);
      showSnackbar("Error submitting data", "error");
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={8} md={8} sm={8}>
          <FormControl sx={{ width: "100%" }}>
            <Typography sx={{ fontWeight: 600 }}>
              Event <span style={{ color: "red" }}>*</span>{" "}
            </Typography>
            <Autocomplete
                size="small"
                value={eventName}
                noOptionsText={
                    eventLoading && eventList.length === 0
                      ? "Loading...."
                      : ""
                  }
                onChange={handleEventChange}
                options={eventList}
                getOptionLabel={(option) =>
                 option.campaign_name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                      endAdornment: (
                        <>
                          {eventLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
          </FormControl>
          <Typography variant="caption" color="error">
            {errorMessage.eventName}
          </Typography>
        </Grid>

        <Grid item xs={12} lg={4} md={4} sm={4}>
          <FormControl sx={{ width: "100%" }}>
            <Typography sx={{ fontWeight: 600 }}>
              User <span style={{ color: "red" }}>*</span>
            </Typography>
            {/* <Select
              value={userName}
              onChange={handleuserChange}
              MenuProps={MenuProps}
              sx={{ borderRadius: "15px" }}
            >
              {userList.map((name) => (
                <MenuItem key={name.id} value={name.id}>
                  {name.first_name} {name.last_name}
                </MenuItem>
              ))}
            </Select> */}
              <Autocomplete
                size="small"
                value={userName}
             
                onChange={handleuserChange}
                options={userList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
          </FormControl>
          <Typography variant="caption" color="error">
            {errorMessage.userName}
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} md={6} sm={6}>
          <FormControl sx={{ width: "100%" }}>
            <Typography sx={{ fontWeight: 600 }}>
              Desk Type <span style={{ color: "red" }}>*</span>
            </Typography>
            <Select
            size="small"
              value={deskType}
              onChange={handleDeskTypeChange}
              MenuProps={MenuProps}
              sx={{ borderRadius: "15px" }}
            >
              <MenuItem value="General Counselling">
                General Counselling
              </MenuItem>
            </Select>
          </FormControl>
          <Typography variant="caption" color="error">
            {errorMessage.deskType}
          </Typography>
        </Grid>

        <Grid item xs={12} lg={3} md={6} sm={6}>
          <Typography sx={{ fontWeight: 600 }}>
            Desk Number <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField size="small"
            type="number"
            InputProps={{
              style: { borderRadius: "15px" },
            }}
            fullWidth
            value={deskNumber}
            onChange={handleDeskNumberChange}
          />

          <Typography variant="caption" color="error">
            {errorMessage.deskNumber}
          </Typography>
        </Grid>

        {/* <Grid item xs={12} md={6} sm={6} lg={6}>
          <FormControl sx={{ width: "100%" }}>
            <Typography sx={{ fontWeight: 600 }}>Country</Typography>
            <Select
              value={countryName}
              onChange={handleCountryChange}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
              sx={{ borderRadius: "15px" }}
            >
              {countryList.map((name) => (
                <MenuItem key={name} value={name}>
                  <Checkbox checked={countryName.indexOf(name) > -1} />
                  <ListItemText primary={name} />
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid> */}

        <Grid item xs={12} lg={3} sm={6} md={6}>
          <FormControl sx={{ width: "100%" }}>
            <Typography sx={{ fontWeight: 600 }}>
              Service <span style={{ color: "red" }}>*</span>
            </Typography>
            <Select
             size="small"
              value={serViceName}
              onChange={handleServiceChange}
              MenuProps={MenuProps}
              sx={{ borderRadius: "15px" }}
            >
              {serviceList.map((name) => (
                <MenuItem key={name.name} value={name.id}>
                  {name.service_name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <Typography variant="caption" color="error">
            {errorMessage.serViceName}
          </Typography>
        </Grid>

        {serViceName !== null && (
          <Grid item xs={12} sm={12} md={12} lg={3}>
            <FormControl sx={{ width: "100%" }}>
              <Typography sx={{ fontWeight: 600 }}>
                Sub Service <span style={{ color: "red" }}>*</span>
              </Typography>
              <Select
              size="small"
                value={subserviceName}
                onChange={handleSubServiceChange}
                MenuProps={MenuProps}
                sx={{ borderRadius: "15px" }}
              >
                {subServiceList.map((name) => (
                  <MenuItem key={name.id} value={name.id}>
                    {name.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <Typography variant="caption" color="error">
              {errorMessage.subserviceName}
            </Typography>
          </Grid>
        )}

        <Grid item xs={12} md={12} lg={12} sm={12} textAlign="end">
          <Button
            variant="contained"
            endIcon={<AddCircleIcon />}
            style={{ borderRadius: "25px", fontWeight: 600 }}
            onClick={handleAdd}
          >
            Add
          </Button>
        </Grid>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <Box
            sx={{
              height: "100%",
              width: "100%",
              "& .header-cell": {
                backgroundColor: "#abf7b1",
                fontWeight: "bold",
                borderRight: "0.1px solid #C3C3C3",
              },
            }}
          >
            <DataGrid
              rows={representativeData}
              columns={columns}
              autoHeight
              slots={{
                noRowsOverlay: CustomNoRowsOverlay,
              }}
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 25,
                  },
                },
              }}
              sx={{ marginTop: "20px" }}
              pageSizeOptions={[25, 50, 100]}
            />
          </Box>
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12} textAlign="end">
          <Button
            variant="contained"
            color="secondary"
            style={{ borderRadius: "25px", fontWeight: 600 }}
            startIcon={<AddCircleIcon />}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
