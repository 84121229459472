import { useTheme } from '@mui/material/styles';
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Button,
    Checkbox,
    Divider,
    FormControl,
    FormControlLabel,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Radio,
    RadioGroup,
    Select,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TextField,
    Typography
} from '@mui/material';

// project imports
import SubCard from '../../../../components/ui-component/card/SubCard'; 
import Chip from '../../../../components/ui-component/chip/Chip'; 
import { gridSpacing } from '../../../../store/constant'; 

// assets
import CalendarTodayTwoToneIcon from '@mui/icons-material/CalendarTodayTwoTone';
import DeleteTwoToneIcon from '@mui/icons-material/DeleteTwoTone';
import EmailTwoToneIcon from '@mui/icons-material/EmailTwoTone';
import PhoneAndroidTwoToneIcon from '@mui/icons-material/PhoneAndroidTwoTone';
import { fDate } from '../../../../../utils/formatTime'; 
import { useEffect, useState } from 'react';
import {
    ExpandMore as ExpandMoreIcon,
    Add as AddIcon,
    Remove as RemoveIcon,
  } from '@mui/icons-material';
import { useLocation } from 'react-router-dom';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const sxDivider = {
    borderColor: 'primary.light'
};

const detailsIconSX = {
    width: 15,
    height: 15,
    verticalAlign: 'text-top',
    mr: 0.5,
    mt: 0.25
};



const AddPackage = () => {
    //console.log(confirmationDetails)
    //const studentInfo = confirmationDetails || {};
    const  userId  = localStorage.getItem('userId')
    const theme = useTheme();
    const location = useLocation();
    const [studentInfo, setStudentInfo] = useState([])
    const [selectedTestType, setSelectedTestType] = useState(null)
    const [packages, setPackages] = useState([])
    const [installments, setInstallments] = useState([]);
    const [selectedPackage, setSelectedPackage] = useState(null)
    const [paymentOption, setPaymentOption] = useState('fullPayment');
    const [offerDiscount, setOfferDiscount] = useState(false);
    const [discountCode, setDiscountCode] = useState('');
    const [discount, setDiscount] = useState(0);
    const [remarks, setRemarks] = useState('');
    const [installmentDetails, setInstallmentDetails] = useState([
      { amount: 0, date: '' },
    ]);
    const [payableInstallments, setPayableInstallments] = useState('')
    const [paymentMode, setPaymentMode] = useState('cash');
    const [paymentStatus, setPaymentStatus] = useState('paid');

    useEffect(() => {
        if (location.state) {
          const { studentDetail } = location.state;
          setStudentInfo(studentDetail)
          setSelectedTestType(studentDetail.package_id?.test_type)
        }
      }, [location]);

      console.log(studentInfo)

      useEffect(() => {
        if (selectedTestType) {
          // Fetch packages based on the selected test type
          fetch(`${API_ENDPOINT}route/packagebytesttype/${selectedTestType}`)
            .then((response) => response.json())
            .then((data) => {
              setPackages(data);
              console.log(data)
            })
            .catch((error) => {
              console.error('Error fetching packages:', error);
            });
        }
      }, [selectedTestType]);

      const handlePackageChange = (event) => {
        const selectedPackageId = event.target.value;
        const selectedPackage = packages.find((pack) => pack.id === selectedPackageId);
      
        // Set the selected package and installments
        setSelectedPackage(selectedPackage);
        setInstallments(Array.from({ length: selectedPackage.payable_installment }, (_, index) => index + 1));
      };
      
       console.log(selectedPackage)

       const handlePayableInstallmentsChange = (event) => {
        setPayableInstallments(event.target.value);
      };


  const handlePaymentModeChange = (event) => {
    setPaymentMode(event.target.value);
  };
  
    const handlePaymentOptionChange = (event) => {
      setPaymentOption(event.target.value);
    };

    const handlePaymentStatusChange = (event) => {
      setPaymentStatus(event.target.value);
    };
    
     
  
      useEffect(() => {
        if (paymentMode === 'cash') {
          setPaymentStatus('paid');
        } else if (paymentMode === 'online') {
          setPaymentStatus('pending');
        }
      }, [paymentMode]);

    const handleRemarksChange = (event) => {
        setRemarks(event.target.value);
      };
  
    const handleOfferDiscountChange = (event) => {
      setOfferDiscount(event.target.checked);
    };
  
    const handleDiscountChange = (event) => {
      setDiscount(parseFloat(event.target.value) || 0);
    };

    const handleDiscountCodeChange = (event) => {
        setDiscountCode(event.target.value);
      };
  
    const handleAddInstallment = () => {
      setInstallmentDetails([...installmentDetails, { amount: 0, date: '' }]);
    };
  
    const handleRemoveInstallment = (index) => {
      const updatedInstallments = [...installmentDetails];
      updatedInstallments.splice(index, 1);
      setInstallmentDetails(updatedInstallments);
    };
  
    const handleInstallmentChange = (index, field, value) => {
      const updatedInstallments = [...installmentDetails];
      updatedInstallments[index][field] = value;
      setInstallmentDetails(updatedInstallments);
    };
  
    // Calculate total amount based on payment option, discount, and installment details
    const calculateTotalAmount = () => {
      const baseAmount = selectedPackage?.original_price || 0; // Update with your base amount
      const discountAmount = (baseAmount * discount) / 100;
      const discountedAmount = baseAmount - discountAmount;
  
      if (paymentOption === 'fullPayment') {
        return discountedAmount;
      } else {
        const installmentAmount = installmentDetails.reduce(
          (total, installment) => total + parseFloat(installment.amount),
          0
        );
        return installmentAmount;
      }
    };
  
    // Calculate amount to be paid after discount and installment
    const calculateAmountToBePaid = () => {
      const baseAmount = selectedPackage?.original_price || 0; // Update with your base amount
      const discountAmount = (baseAmount * discount) / 100;
      const discountedAmount = baseAmount - discountAmount;
    
      if (paymentOption === 'fullPayment') {
        return offerDiscount ? selectedPackage?.discounted_price : discountedAmount;
      } else {
        const installmentAmount = installmentDetails.reduce(
          (total, installment) => total + parseFloat(installment.amount),
          0
        );
        return offerDiscount ? selectedPackage?.discounted_price : discountedAmount - installmentAmount;
      }
    };
  
    const isInstallmentAvailable = () => {
      const installmentType = studentInfo.installments || 0;
      return installmentType > 1;
    };
  
    const handleProceedToPayment = () => {

      if (!studentInfo) {
        alert('Please select student');
        return;
      }

      if ( !selectedPackage ) {
        alert('Please select package');
        return;
      }

      if ( !payableInstallments) {
        alert('Please select payable installments');
        return;
      }
      const updatedEnrollmentDetails = {
        student_id: studentInfo.id,
        class_id: studentInfo.class_id.id,
        payment_mode: paymentMode,
        discount: offerDiscount ? 1 : 0,
        end_date: studentInfo.end_date,
        installment: parseInt(payableInstallments),
        package_id: selectedPackage.id,
        payment_status: paymentStatus,
        price: selectedPackage.discounted_price,
        user_id: userId
      };

    console.log(updatedEnrollmentDetails)
    
      // Make a POST request to enroll the student
      fetch(`${API_ENDPOINT}payment`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(updatedEnrollmentDetails),
      })
        .then((response) => {
          if (response.ok) {
            // Handle success, e.g., show a success message
            console.log('Student enrolled successfully');
            alert('Payment Details sent successfully')
          } else {
            // Handle failure, e.g., show an error message
            console.error('Failed to enroll student');
            alert('Error sending payment details')
          }
        })
        .catch((error) => {
          console.error('Error enrolling student:', error);
          alert('Error sending payment details')
        })

        //setPaymentStatus('paid');
        
      };

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item xs={12}>
                <SubCard title="Student Details" secondary={<Typography variant="subtitle1">{fDate(Date.now())}</Typography>}>
                <Box m={2}>
                    <Grid container spacing={gridSpacing}>
                  
                        <Grid item xs={12}>
                            <Grid container spacing={3}>
                                <Grid item>
                                    <Typography variant="body2">
                                        <CalendarTodayTwoToneIcon sx={detailsIconSX} /> {studentInfo?.first_name} {studentInfo?.last_name}
                                         
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        <PhoneAndroidTwoToneIcon sx={detailsIconSX} /> {studentInfo?.mobile}
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Typography variant="body2">
                                        <EmailTwoToneIcon sx={detailsIconSX} /> {studentInfo?.email}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider sx={sxDivider} />
                        </Grid>
                       
                        <Grid item xs={12}>
                            <Grid container spacing={gridSpacing}>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Stack spacing={2}>
                                        <Typography variant="h4">Class Details</Typography>
                                        <Stack spacing={1}>
                                           
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant="subtitle1">Test Type:</Typography>
                                                <Typography variant="body2">{studentInfo?.test_type_id?.name}</Typography>
                                            </Stack>
                                            
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant="subtitle1">Class Name :</Typography>
                                                <Typography variant="body2">{studentInfo?.class_id?.class_name}</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant="subtitle1">Branch Details :</Typography>
                                                <Typography variant="body2">{studentInfo?.branch_id?.branch_name}</Typography>
                                            </Stack>
                                           
                                        </Stack>
                                    </Stack>
                                </Grid>
                               
                               
                            </Grid>
                        </Grid>
                        

                        <Grid item xs={12}>
                            <Divider sx={sxDivider} />
                        </Grid>
                      </Grid> 
                      </Box>
                </SubCard>
            </Grid>
            

            <Grid item xs={12}>
                        <SubCard title="Select Package">
                            <Box m={2}>
                            <Grid container spacing={gridSpacing}>
                            <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>Select Package</InputLabel>
                            <Select
                                name="package"
                                value={selectedPackage?.id || null}
                                onChange={handlePackageChange}
                                required
                            >
                                {packages.map((pack) => (
                                <MenuItem key={pack.id} value={pack.id}>
                                    {pack.package_name}
                                </MenuItem>
                                ))}
                            </Select>
                            </FormControl>
                        
                        </Grid>

                        <Grid item xs={6}>
                        <FormControl fullWidth>
                            <InputLabel>Payable Installments</InputLabel>
                            <Select
                                name="payable_installments"
                                value={payableInstallments}
                                onChange={handlePayableInstallmentsChange}
                                required
                            >
                                    {installments.map((installment) => (
                                    <MenuItem key={installment} value={installment}>
                                      {installment}
                                    </MenuItem>
                                  ))}
                            </Select>
                            </FormControl>
                        
                        </Grid>
                                </Grid>
                                </Box>
                                </SubCard>
                                </Grid>

           
      <Grid item xs={12}>
        <SubCard title="Payment" content={false}>
        <Box m={2}>
          <Grid container spacing={3}>
            <Grid item xs={12}>
              <RadioGroup
                row
                aria-label="paymentOption"
                name="paymentOption"
                value={paymentOption}
                onChange={handlePaymentOptionChange}
              >
                <FormControlLabel
                  value="fullPayment"
                  control={<Radio />}
                  label="Full Payment"
                />
                <FormControlLabel
                  value="installments"
                  control={<Radio />}
                  label="Installments"
                  disabled={!isInstallmentAvailable()}
                />
              </RadioGroup>
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Total Amount: ${calculateTotalAmount()}</Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="h4">Discount</Typography>
              <Stack direction="row" spacing={2} alignItems="center">
                <Typography>Apply Discount</Typography>
                <Checkbox
                  checked={offerDiscount}
                  onChange={handleOfferDiscountChange}
                />
              </Stack>
            </Grid>
          {/*}  {offerDiscount && (
              <>
                <Grid item xs={12} md={6}>
                  <TextField
                    select
                    fullWidth
                    label="Select Discount Code"
                    variant="outlined"
                    value={discountCode}
                    onChange={handleDiscountCodeChange}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  <TextField
                    fullWidth
                    label="Remarks"
                    variant="outlined"
                    value={remarks}
                    onChange={handleRemarksChange}
                  />
                </Grid>
              </>
          )}*/}

            {paymentOption === 'installments' && (
              <Grid item xs={12}>
                <Typography variant="h6">Installment Details</Typography>
                {installmentDetails.map((installment, index) => (
                  <Accordion key={index}>
                    <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                      <Typography>Installment {index + 1}</Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <Grid container spacing={2}>
                        <Grid item xs={6}>
                          <TextField
                            type="number"
                            label="Amount"
                            variant="outlined"
                            value={installment.amount}
                            onChange={(e) =>
                              handleInstallmentChange(index, 'amount', e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            type="date"
                            label="Date"
                            variant="outlined"
                            value={installment.date}
                            onChange={(e) =>
                              handleInstallmentChange(index, 'date', e.target.value)
                            }
                          />
                        </Grid>
                        <Grid item xs={2}>
                          <IconButton
                            onClick={() => handleRemoveInstallment(index)}
                          >
                            <RemoveIcon />
                          </IconButton>
                        </Grid>
                      </Grid>
                    </AccordionDetails>
                  </Accordion>
                ))}
                <IconButton onClick={handleAddInstallment}>
                  <AddIcon />
                </IconButton>
              </Grid>
            )}

            <Grid item xs={12}>
              <Typography variant="h4">Payment Mode</Typography>
              <RadioGroup
                row
                aria-label="paymentMode"
                name="paymentMode"
                value={paymentMode}
                onChange={handlePaymentModeChange}
              >
                <FormControlLabel
                  value="cash"
                  control={<Radio />}
                  label="Cash"
                />
                <FormControlLabel
                  value="online"
                  control={<Radio />}
                  label="Online Payment"
                />
              </RadioGroup>
            </Grid>

            <Grid item xs={12}>
              <Typography variant="h4">Amount to be Paid: ${calculateAmountToBePaid()}</Typography>
            </Grid>

            <Grid item xs={12}>
              <FormControl fullWidth>
                <InputLabel id="payment-mode-label">Payment Status</InputLabel>
                <Select
                  labelId="payment-mode-label"
                  id="payment-mode-select"
                  value={paymentStatus}
                  onChange={handlePaymentModeChange}
                >
                  <MenuItem value="pending">Pending</MenuItem>
                  <MenuItem value="paid">Paid</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            
          </Grid>
          </Box>
        </SubCard>
      </Grid>

      <Grid container justifyContent="right" spacing={0} mt={2}>
                            <Grid item>
                            {paymentMode === "cash" ? (
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={handleProceedToPayment}
                            >
                                Submit
                            </Button>
                            ) : (
                            <Button
                                variant="contained"
                                color="secondary"
                                size="large"
                                onClick={handleProceedToPayment}
                            >
                                Send Payment Details
                            </Button>
                            )}
                                </Grid>
                                </Grid>

        </Grid>
    );
};

export default AddPackage;