import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import EditMeetingForm from "./EditMeetingForm";
import CustomPopup from "../../../components/CustomPopup";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function MeetingDataCard({ data,selectedbutton }) {
  const userId = localStorage.getItem("userId");

  const userRole = localStorage.getItem("userRole");
  const [editPopup, setEditPop] = useState(false);
  const [deletePopup, setDeletePopup] = useState(false);
  const [selectedData, setSelectedData] = useState(null);
  const [cancelId, setCancelId] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleopenEdit = (data) => {
    setEditPop(true);
    setSelectedData(data);
  };

  const handlecloseEdit = () => {
    setEditPop(false);
  };
  const handleopenDelet = (id) => {
    setDeletePopup(true);
    setCancelId(id);
  };

  const handleCloseDelet = () => {
    setDeletePopup(false);
  };

  // cancel Meeting

  const handleCancelMeeting = () => {
    fetch(`${API_ENDPOINT}empmeeting/${cancelId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        meeting_status: "cancelled",
        user_id: parseInt(userId),
      }),
    })
      .then((response) => {
        if (response.ok) {
          showSnackbar("Meeting Cancelled Successfully.");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else if (response.status === 401) {
          showSnackbar("Unknown User", "error");
        } else if (response.status === 403) {
          showSnackbar("Unathorized User", "error");
        } else {
          console.error("Error: Unknown Error");
          showSnackbar("Error: Unknown Error", "error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showSnackbar("Error: Unknown Error", "error");
      });
  };

  return (
    <>
      {data.length === 0 ? (
        <Typography variant="h4" textAlign="center" width="100%">
          No Meeting Available
        </Typography>
      ) : (
        data.map((item) => {
          // Time formatting logic
          let [hours, minutes] = item.from_time.split(":").map(Number);
          const period = hours >= 12 ? "PM" : "AM";
          hours = hours % 12 || 12;
          const fromTime = `${hours}:${minutes
            .toString()
            .padStart(2, "0")} ${period}`;

          let [hours1, minutes1] = item.to_time.split(":").map(Number);
          const period1 = hours1 >= 12 ? "PM" : "AM";
          hours1 = hours1 % 12 || 12;
          const toTime = `${hours1}:${minutes1
            .toString()
            .padStart(2, "0")} ${period1}`;

          return (
            <Grid item xs={6} sm={6} md={6} lg={4} key={item.id}>
              <Card sx={{ borderRadius: "15px" }} variant="outlined">
                <CardContent>
                  <Typography sx={{ fontSize: 24, fontWeight: 600 }}>
                    {item.meeting_title}
                  </Typography>

                  <Box sx={{ display: "flex", flexDirection: "row" }} mt={1}>
                    {" "}
                    <Typography
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "15px" }}>
                        {" "}
                        <img src="/Calendar.png" width="100%" />
                      </Box>

                      <Typography ml={1} sx={{ fontSize: "16px" }}>
                        {" "}
                        {item.meeting_date}
                      </Typography>
                    </Typography>
                    <Typography
                      ml={4}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                      }}
                    >
                      <Box sx={{ width: "15px" }}>
                        {" "}
                        <img src="/time.png" width="100%" />
                      </Box>

                      <Typography ml={1} sx={{ fontSize: "16px" }}>
                        {" "}
                        {fromTime} - {toTime}
                      </Typography>
                    </Typography>
                  </Box>

                  <Typography
                    mt={1}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ width: "17px" }}>
                      {" "}
                      <img src="/user (1).png" width="100%" />
                    </Box>

                    <Typography ml={1} sx={{ fontSize: "16px" }}>
                      {" "}
                      {item.no_of_participant} Participants
                    </Typography>
                  </Typography>

                  <Typography
                    sx={{ fontSize: 16 }}
                    mt={1}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Box sx={{ width: "17px" }}>
                      {" "}
                      <img src="/icons8-event-accepted-tentatively-48.png" width="100%" />
                    </Box>

                    <Typography ml={1}>{item?.meeting_agenda}</Typography>
                  </Typography>
                  <Typography
                    color="text.secondary"
                    mt={1}
                    sx={{ fontSize: 14 }}
                  >
                    {item?.additional_detail}
                  </Typography>
                </CardContent>
                {(userRole === "admin" &&
                  item?.created_by?.id === parseInt(userId)) && selectedbutton==="upcoming"  && (
                    <CardActions>
                      <Button
                        variant="contained"
                        color="secondary"
                        onClick={() => handleopenEdit(item)}
                      >
                        Edit
                      </Button>
                      <Button
                        variant="contained"
                        color="error"
                        onClick={() => handleopenDelet(item?.id)}
                      >
                        Cancel
                      </Button>
                    </CardActions>
                  )}
              </Card>
            </Grid>
          );
        })
      )}

      <CustomPopup
        open={editPopup}
        onClose={handlecloseEdit}
        component={
          <EditMeetingForm
            onClose={handlecloseEdit}
            selectedData={selectedData}
          />
        }
        title="Edit Meeting"
        maxWidth="sm"
        showDivider={true}
      />

      <Dialog
        open={deletePopup}
        onClose={handleCloseDelet}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogContent>
          <DialogContentText>
            <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
              {" "}
              Do you want to cancel meeting ?
            </Typography>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={handleCancelMeeting}
          >
            Yes
          </Button>
          <Button variant="contained" color="error" onClick={handleCloseDelet}>
            No
          </Button>
        </DialogActions>
      </Dialog>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
