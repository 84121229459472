

// material-ui
import { useTheme } from '@mui/material/styles';
import { ButtonBase, Tooltip } from '@mui/material';

// third-party
import { CSVLink } from 'react-csv';


import SaveIcon from '@mui/icons-material/Save';

// ==============================|| CSV Export ||============================== //

export const CSVExport = ({ data, filename, headers }) => {
    const theme = useTheme();

    return (
        <Tooltip title="CSV Export" placement="left">
            <ButtonBase sx={{ mt: 0.5 }}>
                <CSVLink data={data} filename={filename} headers={headers}>
                    <SaveIcon color={theme.palette.secondary.main} aria-label="Export CSV File" sx={{ fontSize: 32 }}/>
                </CSVLink>
            </ButtonBase>
        </Tooltip>
    );
};
