// Author : Ayesha Shaikh
// Date : 30 july 2024

import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  Grid,
  Paper,
  styled,
  TextField,
  Typography,
} from "@mui/material";
import EmailIcon from "@mui/icons-material/Email";
import React, { useEffect, useState } from "react";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import MainCard from "../../../components/Maincard";

import { DataGrid } from "@mui/x-data-grid";
import WalkInReportFilter from "./WalkInReportFilter";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import CustomPopup from "../../../components/CustomPopup";
import NoenquiryForm from "../Events/NoenquiryForm";
import MaskTaskAssign from "../Events/MaskTaskAssign";
import { fDate } from "../../../utils/formatTime";
import { CSVExport } from "../../components/ui-component/table/CSVExport";
import NoeMailForm from "../Events/NoMailForm";
import BulkEmailPopup from "../Events/BulkEmail/BulkEmailPopup";
import BulkMailform from "../Events/BulkEmail/BulkMailform";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function WalkInReport() {
  const userId = localStorage.getItem("userId");
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(true);
  const [value, setvalue] = useState(null);
  const [walkInsData, setWalkInsData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [openMask, setOpenMask] = useState(false);
  const [selectedid, setSelectedid] = useState([]);
  const [selectedEnquiryId, setSelectedEnquiryids] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);

  const [isDownloadAccess, setIsDownloadAccess] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [OpenEmailPopup, setOpenEmailPopup] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 100,
  });

  const [visibleRowIds, setVisibleRowIds] = useState([]); // Store current page's row IDs
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [prevPageSize, setPrevPageSize] = useState(pagination.pageSize);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleOpenmask = () => {
    setOpenMask(true);
  };

  const handleClosemask = () => {
    setOpenMask(false);
  };

  const handleOpenEmail = () => {
    setOpenEmailPopup(true);
  };

  const handleCloseEmail = () => {
    setOpenEmailPopup(false);
  };

  useEffect(() => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}reports/getwalkinreport/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
          if (data.length === 0) {
            showSnackbar("No data Available", "error");
            setLoading(false);
          } else {
            const sortedData = data.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            setWalkInsData(sortedData);
            setOriginalData(sortedData);
            setFilteredData(sortedData);
            setLoading(false);
          }
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch (error) {
        navigate("/page500");
      }
    };

    fetchData();
  }, []);

  // Upadate current page ids when pagination is chnages
  useEffect(() => {
    console.log("pagination", pagination, selectedid);
    setPrevPageSize(pagination.pageSize);

    // new pagesize is less than prev page size then deselct all selected ids first
    if (pagination.pageSize !== prevPageSize) {
      setSelectedid([]);
      return;
    }

    // Update visible row IDs when pagination changes
    const currentPageRows = originalData.slice(
      pagination.page * pagination.pageSize,
      (pagination.page + 1) * pagination.pageSize
    );
    const visibleIds = currentPageRows.map((row) => row.id);
    setVisibleRowIds(visibleIds);

    // If there are no visible rows, set SelectAllChecked to false
    if (visibleIds.length === 0) {
      setIsSelectAllChecked(false);
      setIsIndeterminate(false);
      return;
    }

    // Check if all visible rows are selected
    const allVisibleSelected = visibleIds.every((id) =>
      selectedid.includes(id)
    );
    const someVisibleSelected = visibleIds.some((id) =>
      selectedid.includes(id)
    );

    setIsSelectAllChecked(allVisibleSelected);
    setIsIndeterminate(someVisibleSelected && !allVisibleSelected);
  }, [pagination, originalData, selectedid]);

  // HandleSelectAllCheckBox
  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setIsSelectAllChecked(checked);

    if (checked) {
      // Select all visible row IDs
      setSelectedid((prevSelectedIds) => [
        ...new Set([...prevSelectedIds, ...visibleRowIds]),
      ]);
    } else {
      // Deselect all visible row IDs
      setSelectedid((prevSelectedIds) =>
        prevSelectedIds.filter((id) => !visibleRowIds.includes(id))
      );
    }
  };

  // Individual CheckBox
  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;

    setSelectedid((prevSelectedIds) => {
      if (checked) {
        const newSelected = [...prevSelectedIds, id];
        // Check if all visible rows are selected after this checkbox is checked
        const allVisibleSelected = visibleRowIds.every((rowId) =>
          newSelected.includes(rowId)
        );
        setIsSelectAllChecked(allVisibleSelected);
        setIsIndeterminate(!allVisibleSelected);

        return newSelected;
      } else {
        // Deselect the row ID
        const newSelected = prevSelectedIds.filter((rowId) => rowId !== id);
        setIsSelectAllChecked(false);
        setIsIndeterminate(
          visibleRowIds.some((rowId) => newSelected.includes(rowId))
        );

        return newSelected;
      }
    });
  };

  // Select EnquiryIds
  useEffect(() => {
    if (selectedid.length > 0) {
      console.log("taskIds", selectedid);
      const enquiryIds = originalData.filter((id) =>
        selectedid.includes(id?.id)
      );
      const selectedEnquiryids = enquiryIds.map((i) => i.enquiry_id?.id);
      setSelectedEnquiryids(selectedEnquiryids);
    }
  }, [selectedid]);

  const columns = [
    {
      field: "selection",
      sortable: false,
      renderHeader: (params) => (
        <Checkbox
          size="small"
          checked={isSelectAllChecked}
          indeterminate={isIndeterminate}
          onChange={handleSelectAllChange}
        />
      ),
      headerClassName: "header-cell",
      width: 70,
      renderCell: (params) => {
        const isChecked = selectedid.includes(params.row?.id);
        return (
          <Checkbox
            size="small"
            checked={isChecked}
            onChange={(event) => handleCheckboxChange(event, params.row?.id)}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography
          style={{ color: "#2196F3", cursor: "pointer" }}
          onClick={() =>
            window.open(
              `/enquirydetails/${params?.row?.enquiry_id?.id}`,
              "_blank"
            )
          }
        >
          {params.row?.enquiry_id?.first_name}{" "}
          {params.row?.enquiry_id?.last_name}
        </Typography>
      ),
      headerAlign: "center",
      align: "center",
    },

    {
      field: "phone",
      headerName: "Phone",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography> {params.row?.enquiry_id?.mobile} </Typography>
      ),
      headerAlign: "center",
      align: "center",
    },

    {
      field: "walkin_type",
      headerName: "Walkin Type",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography> {params.row?.type_of_walking} </Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "walk_in_status",
      headerName: "Walkin Staus",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>{params.row?.walk_in_status}</Typography>
      ),
      headerAlign: "center",
      align: "center",
    },

    {
      field: "walk_in_on",
      headerName: "Walkin Date",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography> {params.row?.appointment_date}</Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "branch",
      headerName: "Branch",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>{params.row?.branch_visited_id?.branch_name}</Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "sub_service",
      headerName: "Sub Service",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>{params.row?.sub_service_id?.name}</Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "prospective_level",
      headerName: "Prospective Level",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>
          {params.row?.enquiry_id?.prospective_level_id?.prospective_level}
        </Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "stage",
      headerName: "Stage",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>{params.row?.enquiry_id?.stage_id?.stage_name}</Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "lead_source",
      headerName: "Lead Source",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>
          {params.row?.enquiry_id?.lead_source_type_id?.name}
        </Typography>
      ),
      headerAlign: "center",
      align: "center",
    },

    {
      field: "reason",
      headerName: "Reason For Walkin",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>{params.row?.event_reason_id?.reason_name}</Typography>
      ),
      headerAlign: "center",
      align: "center",
    },

    {
      field: "register_by",
      headerName: "Walk_in Register By",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>
          {params.row?.created_by?.first_name}{" "}
          {params.row?.created_by?.last_name}
        </Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "reference_by",
      headerName: "Walk_in Reference By",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>{params.row?.reference_walkin_id?.name} </Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "assin_to",
      headerName: "Assign to",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>
          {params.row?.assign_to?.first_name} {params.row?.assign_to?.last_name}
        </Typography>
      ),
      headerAlign: "center",
      align: "center",
    },

    {
      field: "walk_in_event",
      headerName: "Walkin Event",
      width: 450,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>{params.row?.event_id?.campaign_name}</Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "last_folloup",
      headerName: "Last Follwup",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>{params.row?.enquiry_id?.last_follow_up_date}</Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "date",
      headerName: "Next Due Date",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>{params.row?.enquiry_id?.next_follow_up_date}</Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }

  //handleSearch
  const handleSearch = (e) => {
    const value = e.target.value;
    setvalue(e.target.value);

    let filteredData1 = walkInsData.filter((data) =>
      `${data?.enquiry_id?.first_name} ${data?.enquiry_id?.last_name}`
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(value.toLowerCase().replace(/\s+/g, ""))
    );

    setOriginalData(filteredData1);
  };

  const TotalWalkins = walkInsData.length;
  const totalBookDemo = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "book demo"
      ? total + 1
      : total;
  }, 0);

  const totalQualifying = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "qualifying"
      ? total + 1
      : total;
  }, 0);

  const totalAssignment = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "assignment"
      ? total + 1
      : total;
  }, 0);

  const totalActivecounselling = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "active counselling"
      ? total + 1
      : total;
  }, 0);

  const totalDemocompleted = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "demo completed"
      ? total + 1
      : total;
  }, 0);

  const totalEnrolled = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "enrolled"
      ? total + 1
      : total;
  }, 0);

  const totalTraining = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "training"
      ? total + 1
      : total;
  }, 0);

  const totalTrainingcompleted = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.stage_id?.stage_name === "training completed"
      ? total + 1
      : total;
  }, 0);

  const totalSuperProspective = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.prospective_level_id?.prospective_level ===
      "Super Prospective"
      ? total + 1
      : total;
  }, 0);

  const totalHot = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.prospective_level_id?.prospective_level === "Hot"
      ? total + 1
      : total;
  }, 0);

  const totalWarm = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.prospective_level_id?.prospective_level === "Warm"
      ? total + 1
      : total;
  }, 0);

  const totalContactInFuture = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.prospective_level_id?.prospective_level ===
      "Contact In Future"
      ? total + 1
      : total;
  }, 0);

  const totalRecycled = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.prospective_level_id?.prospective_level ===
      "Recycled"
      ? total + 1
      : total;
  }, 0);

  const totalNewlead = walkInsData.reduce((total, item) => {
    return item.enquiry_id?.prospective_level_id?.prospective_level ===
      "New lead"
      ? total + 1
      : total;
  }, 0);

  useEffect(() => {
    fetch(`${API_ENDPOINT}route/downloadaccess/${userId}`)
      .then((response) => {
        if (response.ok) {
          setIsDownloadAccess(true);
        } else {
          setIsDownloadAccess(false);
        }
      })
      .catch((err) => {
        setIsDownloadAccess(false);
      });
  }, [userId]);

  useEffect(() => {
    const exportData = originalData.map((row) => ({
      name: row.enquiry_id?.last_name
        ? `${row.enquiry_id.first_name} ${row.enquiry_id.last_name}`
        : row.enquiry_id.first_name,
      mobile: row.enquiry_id.mobile,
      walk_in_status: row?.walk_in_status,
      walk_in_type: row?.type_of_walking,
      branch_visited: row.branch_visited_id?.branch_name || "",
      registered_by: row?.created_by?.first_name,
      walkin_date: fDate(row?.appointment_date),
      time: row?.appointment_time,
      reason_of_visit: row?.event_reason_id?.reason_name,
      event_name: row?.event_id?.campaign_name || "",
      prospective_level:
        row.enquiry_id?.prospective_level_id?.prospective_level || "",
      sub_service: row.sub_service_id?.name || "",
      assign: row.assign_to?.first_name || "",
      lead_source_type: row.enquiry_id?.lead_source_type_id?.name || "",
      reference_id: row?.reference_walkin_id?.first_name || "",
      stage: row.enquiry_id?.stage_id?.stage_name || "",
    }));
    setExportData(exportData);
  }, [originalData]);

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6} sm={12} md={6}>
          <Paper
            sx={{
              width: "200px",
              fontSize: "24px",
              fontWeight: 600,
              height: "50px",
              borderRadius: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Walk-in Data
          </Paper>
        </Grid>
        <Grid item xs={12} lg={6} sm={12} md={6} textAlign="end">
          <Button
            onClick={handleToggle}
            style={{
              borderRadius: "25px",
              background: "white",
              color: "black",
            }}
          >
            <FilterAltIcon fontSize="medium" color="orange" />
            <Typography variant="h4" marginLeft={1}>
              Filter
            </Typography>
            {open === false ? (
              <KeyboardArrowDownIcon fontSize="small" />
            ) : (
              <KeyboardArrowUpIcon fontSize="small" />
            )}
          </Button>

          <Button
            variant="contained"
            style={{ borderRadius: "24px", marginLeft: "10px" }}
            onClick={handleOpenmask}
          >
            Mass Task Assign{" "}
          </Button>
          <Button
            variant="contained"
            style={{ borderRadius: "24px", marginLeft: "10px" }}
            startIcon={<EmailIcon />}
            color="secondary"
            onClick={handleOpenEmail}
          >
            Send Email{" "}
          </Button>

          {isDownloadAccess && (
            <CSVExport data={exportData} filename="walkin-report.csv" />
          )}
        </Grid>
        {open && (
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <WalkInReportFilter
              originalData={originalData}
              setWalkInsData={setWalkInsData}
              setLoading={setLoading}
              setOriginalData={setOriginalData}
              filteredData={filteredData}
            />
          </Grid>
        )}

        <Grid item xs={12} md={12} sm={12} lg={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} md={12} sm={12} lg={12}>
                <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
                  Walk-in Report
                </Typography>
              </Grid>

              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item>
                    <Button
                      style={{
                        background: "#edebfb",
                        color: "#983bc4",
                        borderRadius: "25px",
                        fontWeight: 500,
                        fontSize: "16px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      Total Walkins :{TotalWalkins}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      style={{
                        background: "#d1ecf1",
                        color: "#447a84",
                        borderRadius: "25px",
                        fontWeight: 500,
                        fontSize: "16px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      Qualifying : {totalQualifying}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      style={{
                        background: "#d1ecf1",
                        color: "#447a84",
                        borderRadius: "25px",
                        fontWeight: 500,
                        fontSize: "16px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      Book Demo : {totalBookDemo}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      style={{
                        background: "#d1ecf1",
                        color: "#447a84",
                        borderRadius: "25px",
                        fontWeight: 500,
                        fontSize: "16px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      Assignment :{totalAssignment}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      style={{
                        background: "#d1ecf1",
                        color: "#447a84",
                        borderRadius: "25px",
                        fontWeight: 500,
                        fontSize: "16px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      Active Counselling :{totalActivecounselling}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      style={{
                        background: "#d1ecf1",
                        color: "#447a84",
                        borderRadius: "25px",
                        fontWeight: 500,
                        fontSize: "16px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      Demo Completed:{totalDemocompleted}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      style={{
                        background: "#d1ecf1",
                        color: "#447a84",
                        borderRadius: "25px",
                        fontWeight: 500,
                        fontSize: "16px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      Enrolled : {totalEnrolled}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      style={{
                        background: "#d1ecf1",
                        color: "#447a84",
                        borderRadius: "25px",
                        fontWeight: 500,
                        fontSize: "16px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      Training : {totalTraining}
                    </Button>
                  </Grid>

                  <Grid item>
                    <Button
                      style={{
                        background: "#d1ecf1",
                        color: "#447a84",
                        borderRadius: "25px",
                        fontWeight: 500,
                        fontSize: "16px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                      }}
                    >
                      Training Completed :{totalTrainingcompleted}
                    </Button>
                  </Grid>
                </Grid>
              </Grid>

              <Grid item>
                <Button
                  style={{
                    background: "#f5e0fc",
                    color: "#000000",
                    borderRadius: "25px",
                    fontWeight: 500,
                    fontSize: "16px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  Super Prospective : {totalSuperProspective}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  style={{
                    background: "#f5e0fc",
                    color: "#000000",
                    borderRadius: "25px",
                    fontWeight: 500,
                    fontSize: "16px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  Hot: {totalHot}
                </Button>
              </Grid>

              <Grid item>
                <Button
                  style={{
                    background: "#f5e0fc",
                    color: "#000000",
                    borderRadius: "25px",
                    fontWeight: 500,
                    fontSize: "16px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  Warm:{totalWarm}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  style={{
                    background: "#f5e0fc",
                    color: "#000000",
                    borderRadius: "25px",
                    fontWeight: 500,
                    fontSize: "16px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  Contact In Future:{totalContactInFuture}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  style={{
                    background: "#f5e0fc",
                    color: "#000000",
                    borderRadius: "25px",
                    fontWeight: 500,
                    fontSize: "16px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  Recycled:{totalRecycled}
                </Button>
              </Grid>
              <Grid item>
                <Button
                  style={{
                    background: "#f5e0fc",
                    color: "#000000",
                    borderRadius: "25px",
                    fontWeight: 500,
                    fontSize: "16px",
                    paddingLeft: "15px",
                    paddingRight: "15px",
                  }}
                >
                  New lead:{totalNewlead}
                </Button>
              </Grid>

              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Box>
                    <Button
                      style={{ borderRadius: "25px" }}
                      variant="contained"
                      size="small"
                    >
                      {" "}
                      Selected Items : {selectedid.length}{" "}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600 }}>
                      Search:{"  "}
                    </Typography>
                    <TextField
                      // value={value}
                      onChange={handleSearch}
                      size="small"
                      InputProps={{ style: { borderRadius: "15px" } }}
                    />
                  </Box>
                </Box>
              </Grid>

              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    "& .header-cell": {
                      backgroundColor: "#abf7b1",
                      fontWeight: 600,
                      borderRight: "0.1px solid #C3C3C3",
                    },
                  }}
                >
                  <DataGrid
                    rows={originalData}
                    columns={columns}
                    autoHeight
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    pageSize={pagination.pageSize}
                    onPaginationModelChange={(model) => setPagination(model)}
                    sx={{ marginTop: "20px" }}
                    pageSizeOptions={[10, 25, 50, 100]}
                  />
                </Box>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      {selectedid.length === 0 ? (
        <CustomPopup
          open={openMask}
          onClose={handleClosemask}
          component={<NoenquiryForm onClose={handleClosemask} />}
          maxWidth="xs"
        />
      ) : (
        <CustomPopup
          open={openMask}
          onClose={handleClosemask}
          component={
            <MaskTaskAssign
              onClose={handleClosemask}
              selectedId={selectedEnquiryId}
              setSelectedIds={setSelectedEnquiryids}
            />
          }
          title="Mass Task Assign"
          maxWidth="md"
          showDivider={true}
        />
      )}

      {selectedid.length === 0 ? (
        <CustomPopup
          open={OpenEmailPopup}
          onClose={handleCloseEmail}
          component={<NoeMailForm onClose={handleCloseEmail} />}
          maxWidth="xs"
        />
      ) : (
        <BulkEmailPopup
          open={OpenEmailPopup}
          handelclose={handleCloseEmail}
          component={
            <BulkMailform
              handelclose={handleCloseEmail}
              selectedId={selectedEnquiryId}
            />
          }
          title="Send Bulk Emails"
          maxWidth="md"
          showDivider={true}
        />
      )}
    </>
  );
}
