// Author : Ayesha Shaikh
//  Date : 29 Aug 2024

import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import { DataGrid } from "@mui/x-data-grid";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import DeclinedPaymentFilter from "./DeclinedPaymentFilter";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function DeclinedPayment() {
  const userId = localStorage.getItem("userId");
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const [declinedPaymentData, setDeclinedPaymentData] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [originalData, setOriginalData] = useState([]);
  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [openUploadDepositDialog, setOpenUploadDepositDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);
  const [selectedId, setSelectedId] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [openRows, setOpenRows] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  //   Fetch Decline Payment Data
  useEffect(() => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
    
    };
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}route/fechdeclinedpayments/${userId}`,{headers}
        );
        if (response.ok) {
          const data = await response.json();

          const sortedData = data.sort(
            (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
          );

          setDeclinedPaymentData(sortedData);
          setOriginalData(sortedData);
          setIsLoading(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }}
        catch{
          navigate("/page500");
        }
    };

    fetchData();
  }, [userId]);

  //   Cancel Payment

  const handleCancelPayment = async (id) => {
    const formdata = {
      provesional_payment_id: id,
      user_id: parseInt(userId),
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route/canceldeclinedpayment`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        }
      );

      if (response.ok) {
        showSnackbar("Payment Cancelled Successfully");
        const data = declinedPaymentData.filter((item) => {
          return item.id != id;
        });
        setDeclinedPaymentData(data);
      } else if (response.status === 400) {
        showSnackbar("Provesional payment id not found ", "error");
      } else if (response.status === 401) {
        showSnackbar("Unathorized User", "error");
      } else {
        showSnackbar("Error Posting Data", "error");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      showSnackbar("Error Posting Data", "error");
    }
  };

  // Change File
  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  //   Upload Payment Screenshot

  const handleOpenUploadDialog = (id) => {
    setOpenUploadDialog(true);
    setSelectedId(id);
  };

  const handleCloseUploadDialog = () => {
    setOpenUploadDialog(false);
    setSelectedFile(null);
  };

  const handleAddUploadPaymentScrrenshot = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("user_id", userId);
      formData.append("provesional_payment_id", selectedId);

      console.log(formData);

      try {
        const response = await fetch(
          `${API_ENDPOINT}route/reuploadpaymentscreenshot`,
          {
            method: "POST",

            body: formData,
          }
        );

        if (response.ok) {
          showSnackbar("Payment screenshot uploaded successfully");

          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else if (response.status === 400) {
          showSnackbar("Provesional payment id not found ", "error");
        } else if (response.status === 401) {
          showSnackbar("Unathorized User", "error");
        } else {
          showSnackbar("Error Posting Data", "error");
        }
      } catch (error) {
        console.error("Error posting data:", error);
        showSnackbar("Error Posting Data", "error");
      }
    }
  };

  //   Upload Deposit Slip

  const handleOpenUploadDepositDialog = (id) => {
    setOpenUploadDepositDialog(true);
    setSelectedId(id);
  };

  const handleCloseUploadDepositDialog = () => {
    setOpenUploadDepositDialog(false);
    setSelectedFile(null);
  };

  const handleAddUploadPaymentDeposit = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("user_id", userId);
      formData.append("provesional_payment_id", selectedId);

      console.log(formData);

      try {
        const response = await fetch(
          `${API_ENDPOINT}route/reuploaddepositslip`,
          {
            method: "POST",

            body: formData,
          }
        );

        if (response.ok) {
          showSnackbar("Deposit slip uploaded successfully");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else if (response.status === 400) {
          showSnackbar("Provesional payment id not found ", "error");
        } else if (response.status === 401) {
          showSnackbar("Unathorized User", "error");
        } else {
          showSnackbar("Error Posting Data", "error");
        }
      } catch (error) {
        console.error("Error posting data:", error);
        showSnackbar("Error Posting Data", "error");
      }
    }
  };

  // pagination
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (rowId) => {
    setOpenRows((prevOpenRows) =>
      prevOpenRows.includes(rowId)
        ? prevOpenRows.filter((id) => id !== rowId)
        : [...prevOpenRows, rowId]
    );
  };
  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DeclinedPaymentFilter
            setPayments={setDeclinedPaymentData}
            originalData={originalData}
            isLoading={isLoading}
            showSnackbar={showSnackbar}
            setIsLoading={setIsLoading}
          />
        </Grid>

        <Grid item xs={12}>
          <MainCard>
            <TableContainer
              component={Paper}
              sx={{ borderRadius: "15px", padding: "10px" }}
            >
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Enquiry Details
                    </TableCell>

                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Payment Date
                    </TableCell>

                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                    Payment Mode
                    </TableCell>

                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Status
                    </TableCell>

                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Declined By
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Date
                    </TableCell>

                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Remarks
                    </TableCell>

                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      Action
                    </TableCell>
                  </TableRow>
                </TableHead>

                <TableBody>
                  {declinedPaymentData.length === 0 ? (
                    <Grid
                      item
                      xs={12}
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      <Typography variant="h5">No data available</Typography>
                    </Grid>
                  ) : (
                    declinedPaymentData
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((row, index) => (
                        <>
                          <TableRow
                            key={index}
                            style={{
                              backgroundColor:
                                index % 2 === 0 ? "#f0f0f0" : "inherit",
                              width: "6%",
                            }}
                          >
                            <TableCell
                              sx={{
                                color: "#000",
                                fontFamily: "Poppins",
                                fontSize: "16px",
                                fontStyle: "normal",
                                fontWeight: 600,
                                cursor: "pointer",
                              }}
                              onClick={() => handleRowClick(row.id)}
                            >
                              {" "}
                              View{" "}
                            </TableCell>

                            <TableCell>{row?.payment_date}</TableCell>
                            <TableCell>{row?.payment_mode_id?.payment_mode}</TableCell>
                           
                            <TableCell>
                              {row?.collected_status === "declined" && (
                                <Chip label="Declined" color="error" />
                              )}
                            </TableCell>

                            <TableCell>
                              {row?.declined_by?.first_name}{" "}
                              {row?.declined_by?.last_name}
                            </TableCell>
                            <TableCell>{row?.declined_date}</TableCell>
                            <TableCell>{row?.declined_remarks}</TableCell>
                            <TableCell
                              style={{ display: "flex", flexDirection: "row" }}
                            >
                              {row?.created_by?.id === parseInt(userId) && (
                                <>
                                  <Button
                                    variant="contained"
                                    color="error"
                                    onClick={() => handleCancelPayment(row?.id)}
                                  >
                                    Cancel Payment
                                  </Button>
                                  {row?.payment_mode_id?.payment_mode ===
                                  "Cash" ? (
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      style={{ marginLeft: "10px" }}
                                      onClick={() =>
                                        handleOpenUploadDepositDialog(row?.id)
                                      }
                                    >
                                      Upload New Deposit Slip
                                    </Button>
                                  ) : (
                                    <Button
                                      variant="contained"
                                      color="secondary"
                                      style={{ marginLeft: "10px" }}
                                      onClick={() =>
                                        handleOpenUploadDialog(
                                          row?.id,
                                          "Upload New Payment Screenshot"
                                        )
                                      }
                                    >
                                      Upload New Payment Screenshot
                                    </Button>
                                  )}
                                </>
                              )}
                            </TableCell>
                          </TableRow>
                          {openRows.includes(row.id) && (
                            <TableRow>
                              <TableCell colSpan={8}>
                                <Table>
                                  <TableBody>
                                    <TableRow>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle1"
                                          sx={{ fontWeight: "bold" }}
                                        >
                                          Enquiry Details
                                        </Typography>
                                        <Typography
                                          sx={{
                                            color: "blue",
                                            fontFamily: "Poppins",
                                            fontSize: "16px",
                                            fontStyle: "normal",
                                            fontWeight: 600,
                                            cursor: "pointer",
                                            "&:hover": {
                                              textDecoration: "none",
                                            },
                                          }}
                                          onClick={() =>
                                            window.open(
                                              `/enquirydetails/${row?.enquiry_id?.id}`,
                                              "_blank"
                                            )
                                          }
                                        >
                                          {row.enquiry_id?.first_name}{" "}
                                          {row.enquiry_id?.last_name}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle1"
                                          sx={{ fontWeight: "bold" }}
                                        >
                                          Mobile
                                        </Typography>
                                        <Typography>
                                          {row.enquiry_id?.mobile}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle1"
                                          sx={{ fontWeight: "bold" }}
                                        >
                                          Amount
                                        </Typography>
                                        <Typography>
                                          {row.amount_paid}
                                        </Typography>
                                      </TableCell>
                                      <TableCell>
                                        <Typography
                                          variant="subtitle1"
                                          sx={{ fontWeight: "bold" }}
                                        >
                                          Collected By
                                        </Typography>
                                        <Typography>
                                          {row.created_by?.first_name}{" "}
                                          {row.created_by?.last_name}
                                        </Typography>
                                      </TableCell>

                                      <TableCell>
                                        <Typography
                                          variant="subtitle1"
                                          sx={{ fontWeight: "bold" }}
                                        >
                                          Branch
                                        </Typography>
                                        <Typography>
                                          {
                                            row.enquiry_id.branch_id
                                              ?.branch_name
                                          }
                                        </Typography>
                                      </TableCell>
                                    </TableRow>
                                  </TableBody>
                                </Table>
                              </TableCell>
                            </TableRow>
                          )}
                        </>
                      ))
                  )}
                </TableBody>
              </Table>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25]}
                component="div"
                count={declinedPaymentData.length}
                rowsPerPage={rowsPerPage}
                page={page}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
              />
            </TableContainer>
          </MainCard>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      {/* Upload Payment Screenshot */}
      <Dialog open={openUploadDialog} onClose={handleCloseUploadDialog}>
        <DialogTitle variant="h4"> Upload New Payment Screenshot </DialogTitle>
        <DialogContent>
          <TextField type="file" onChange={handleFileChange} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseUploadDialog}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button
            onClick={handleAddUploadPaymentScrrenshot}
            variant="contained"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      {/* Upload Deposit Slip */}

      <Dialog
        open={openUploadDepositDialog}
        onClose={handleCloseUploadDepositDialog}
      >
        <DialogTitle variant="h4"> Upload New Deposit Slip </DialogTitle>
        <DialogContent>
          <TextField type="file" onChange={handleFileChange} />
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCloseUploadDepositDialog}
            variant="contained"
            color="error"
          >
            Cancel
          </Button>
          <Button onClick={handleAddUploadPaymentDeposit} variant="contained">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
