import { Backdrop, Box, Button, Chip, CircularProgress, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { fDate } from '../../../../utils/formatTime';
import { useNavigate } from 'react-router-dom';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function StudentTable({ students, setStudent,studentsForCurrentPage,isLoading}) {
  const userId = localStorage.getItem('userId');


  const navigate = useNavigate();
  const handleViewMoreClick = (studentId, studentDetail) => {
    navigate(`/view-student/${studentId}`, { state: { studentDetail } });
  };

  const handleViewMore = (studentId, studentDetail) => {

 

    const url = `/view-student/${studentId}`;
    const newState = { studentDetail };

    // Open link in new tab
    const newTab = window.open(url, '_blank');

    // If new tab opened successfully, navigate with state data
    if (newTab) {
        newTab.onload = () => {
            newTab.history.replaceState(newState, '', url);
        };
    } else {
        // If new tab was blocked by browser, navigate with state data in current tab
        navigate(url, { state: newState });
    }
};

 
 









  return (
  <>
  <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
      <Stack direction='row' spacing={1}>
        <Typography variant="h4" color="secondary" fontWeight={800} marginRight={2}>Fetching</Typography>
        <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
   <TableContainer >
 
   {(students.length === 0 && !isLoading)? (
        <Box sx={{textAlign:'center' , fontSize:'18px'}}>
           <Typography variant="subtitle1">
                No students found. Please contact admin.
              </Typography>
        </Box>
      ) : (
    <Table>
      <TableHead >
        <TableRow >
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 }}>Student Name</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 ,}}>Enrollment No</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 ,}}>Enrollment Date</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Branch</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Test Type</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Status</TableCell>
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Action</TableCell>
        </TableRow>
      </TableHead>
      <TableBody >
      {studentsForCurrentPage.map((studentDetail) => (
          <TableRow   key={studentDetail.id}  >
            <TableCell >{studentDetail.enquiry_id?.first_name} {studentDetail.enquiry_id?.last_name}</TableCell>
            <TableCell >{studentDetail.lead_no}</TableCell>
            <TableCell>{fDate(studentDetail.joining_date)}</TableCell>
          <TableCell>{studentDetail?.branch_id?.branch_name}</TableCell>
          <TableCell>  {studentDetail?.enquiry_id?.test_type_id?.name}</TableCell>
          <TableCell> {studentDetail.status_of_student === 'active' ? (
                <Chip label={studentDetail.status_of_student} color='secondary' size='small'/>
              ) : (
                <Chip label={studentDetail.status_of_student} color='error' size='small'/>
              )}</TableCell>
<TableCell>
  <Box>
<Button  variant='contained' color='secondary'
                        style={{ color: 'black'}}
                        onClick={() => handleViewMore(studentDetail.id, studentDetail)}
                        >View More</Button> </Box>
      </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
      )}
  
  </TableContainer>
  
  </>
  )
}


