import { Box, Typography, Chip, Button } from '@mui/material'
import React from 'react'

function Header({ title, tag, action1, action2 }) {
  return (
   <Box display="flex" justifyContent="space-between" p={2}>
    <Box display="flex" >
    <Typography
        variant='h5'
        fontWeight="bold"
        sx={{ m: "0 0 5px 0" }}
        color="#171A1FFF"
    >
        {title}
    </Typography>
    <Chip label={tag} />
    </Box>

    <Box display="flex" >
    <Button variant="outlined">{action1}</Button> 
    <Button variant="contained">{action2}</Button> 
    </Box>
   </Box>
  )
}

export default Header