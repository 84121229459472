import React, { useEffect, useState } from "react";

import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import styled from "@emotion/styled";
import axios from "axios";
import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  border: `2px solid #2196F3`,
  borderRadius: "12px",
}));

function AddIncomingCall() {
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    reset,
  } = useForm();
  const [role, setRole] = useState(userRole);
  const [branches, setBranches] = useState([]);
  const [taskItems, setTaskItems] = useState([]);
  const [testtypes, setTestTypes] = useState([]);
  const [users, setUsers] = useState([]);
  const [nearest_branch, setNearestBranch] = useState(null);
  const [pinCodeData, setPinCodeData] = useState({});
  const [pincodeError, setPincodeError] = useState("");
  const [leadSources, setLeadSources] = useState([]);
  const [subLeadSources, setSubLeadSources] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [services, setServices] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const [sourceTypes, setSourceTypes] = useState([]);
  const [subSourceTypes, setSubSourceTypes] = useState([]);
  const [purposeOfTest, setPurposeOfTest] = useState([]);
  const [leadTestExperience, setLeadTestExperience] = useState([]);
  const [examStatuses, setExamStatus] = useState([]);
  const [proficiencyLevel, setProficiencyLevel] = useState([]);
  const [prospectiveLevel, setProspectiveLevel] = useState([]);
  const [levelOfInterest, setLevelOfInterest] = useState([]);
  const [targetCountries, setTargetCountries] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const [isSavingLoading, setIsSavingLoading] = useState(false);

  const [selectedCountry, setSelectedCountry] = useState(101);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedCity, setSelectedCity] = useState(null);
  const [selectedService, setSelectedService] = useState(1);
  const [selectedSourceType, setSelectedSourceType] = useState(null);
  const [state, setState] = useState(null);

  const examStatus = watch("exam_status_id");
  const testBooking = watch("test_taken");
  const deliveryMode = watch("preferred_delivery_mode");

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setValue("country_id", event.target.value);
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setValue("state_id", event.target.value);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setValue("city_id", event.target.value);
  };

  const handleServiceChange = (event) => {
    setSelectedService(event.target.value);
    setValue("service_id", event.target.value);
  };

  const handleSourceTypeChange = (event) => {
    setSelectedSourceType(event.target.value);
    setValue("lead_source_type_id", event.target.value);
  };

  const handleExamStatusChange = (value) => {
    setValue("exam_status_id", value);
  };

  const handleTestBookingStatusChange = (value) => {
    setValue("test_taken", value);
  };

  const handlePinCodeChange = (event) => {
    const newPinCode = event.target.value;
    if (newPinCode.length === 6) {
      setValue("pincode_id", newPinCode);
      fetchPinCodeData(newPinCode);
    } else {
      setPincodeError("Invalid pin code 3");
      //setNearestBranch(null)
      //setError('pincode', { type: 'manual', message: 'Invalid pin code 4' });
      /*reset({
                    state: '',
                    city: '',
                    nearest_branch: null
                })*/
      setSelectedState(null);
      setSelectedCity(null);
      setValue("pincode_id", null);
      setValue("state_id", "");
      setValue("city_id", "");
      // setValue('nearest_branch', null);
    }
  };

  const fetchPinCodeData = async (pincode) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}oldzipcode/pincode/${pincode}`
      );
      //setPinCodeData(response.data);
      if (response.status === 200) {
        const postOfficeData = response.data; // Get the first PostOffice object
        setPinCodeData(postOfficeData);
        console.log(postOfficeData);
        if (postOfficeData) {
          // Update the State and City fields with the data from the API
          setPincodeError("");
          setSelectedState(parseInt(postOfficeData.state_id));
          setValue("state_id", parseInt(postOfficeData.state_id));

          setSelectedCity(parseInt(postOfficeData.city_id));
          setValue("city_id", parseInt(postOfficeData.city_id));
          // setValue('nearest_branch', postOfficeData?.branch_detail[0]?.id);
        }
      } else {
        console.log("Invalid pincode");
        console.log("API error");
        //alert('error')
        setPincodeError("Invalid pin code 1");
        //setNearestBranch(null)
        setError("pincode", { type: "manual", message: "Invalid pin code 2" });
        /* reset({
                        state: '',
                        city: '',
                        nearest_branch: null
                    })*/
        setValue("pincode_id", null);
        setValue("state_id", "");
        setValue("city_id", "");
        setSelectedState(null);
        setSelectedCity(null);
        //setValue('nearest_branch', null);
      }
    } catch (error) {
      console.error("Error fetching pin code data:", error);
      showSnackbar("Error fetching pin code data", "error");
      setPinCodeData(null);
    }
  };

  const onSubmit = (data) => {
    // Make the API request here, assuming you are using the fetch function
    setIsSavingLoading(true);

    data.user_id = userId;

    console.log(data);

    fetch(`${API_ENDPOINT}advisordashboard/addenquiry`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json", // Replace with the appropriate content type
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          // API call succeeded, show success message
          //alert('Enquiry sent successfully');
          setIsSavingLoading(false);
          showSnackbar("Enquiry sent successfully", "success");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
          // navigate('/enquiries')
        } else {
          // API call failed, show error message
          //alert('Error submitting enquiry. Please try again.');
          setIsSavingLoading(false);
          showSnackbar("Error submitting enquiry. Please try again.", "error");
        }
      })
      .catch((error) => {
        // Handle network errors or other issues
        console.error("Error:", error);
        setIsSavingLoading(false);
        showSnackbar("An error occurred. Please try again later.", "error");
        //alert('An error occurred. Please try again later.');
      });
  };

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}branch`)
      .then((response) => response.json())
      .then((data) => {
        setBranches(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route/getalltesttype`)
      .then((response) => response.json())
      .then((data) => {
        setTestTypes(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching testtypes:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route/getalluser`)
      .then((response) => response.json())
      .then((data) => {
        let filteredData = data;

        if (role === "advisor") {
          // If the role is advisor, filter the list to show only the logged-in advisor
          filteredData = data.filter((user) => user.id === parseInt(userId));
        }

        const sortedData = filteredData.sort((a, b) => {
          const nameA = (a.first_name + " " + a.last_name).toUpperCase();
          const nameB = (b.first_name + " " + b.last_name).toUpperCase();
          return nameA.localeCompare(nameB);
        });
        setUsers(sortedData);
        const defaultOwner = data.find(
          (owner) => owner.id === parseInt(userId)
        );
        if (defaultOwner) {
          setValue("task_owner", defaultOwner.id);
          setValue("assign_to", defaultOwner.id);
        }
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route2.0/typeoftaskroles/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setTaskItems(data);
      })
      .catch((error) => {
        console.error("Error fetching task items:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}country`)
      .then((response) => response.json())
      .then((data) => {
        const sortedData = [...data].sort(
          (a, b) => a.phone_code - b.phone_code
        );
        const uniqueArray = sortedData.filter(
          (obj, index, self) =>
            index === self.findIndex((t) => t.phone_code === obj.phone_code)
        );
        setCountriesList(data);
        setCountries(uniqueArray);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching country:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      fetch(`${API_ENDPOINT}oldstate`)
        .then((response) => response.json())
        .then((data) => {
          const filteredState = data.filter(
            (state) => state.country_id === selectedCountry
          );
          setStates(filteredState);
          console.log(filteredState);
        })
        .catch((error) => {
          console.error("Error fetching state:", error);
        });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry && selectedState) {
      fetch(`${API_ENDPOINT}oldcity`)
        .then((response) => response.json())
        .then((data) => {
          const filteredCity = data.filter(
            (city) =>
              city.country === selectedCountry && city.state === selectedState
          );
          setCities(filteredCity);
          console.log(filteredCity);
        })
        .catch((error) => {
          console.error("Error fetching city:", error);
        });
    }
  }, [selectedCountry, selectedState]);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}service`)
      .then((response) => response.json())
      .then((data) => {
        setServices(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedService) {
      fetch(`${API_ENDPOINT}subservice`)
        .then((response) => response.json())
        .then((data) => {
          const filteredSubServices = data.filter(
            (sub) => sub.service_id === selectedService
          );
          setSubServices(filteredSubServices);
          console.log(filteredSubServices);
        })
        .catch((error) => {
          console.error("Error fetching sub services:", error);
        });
    }
  }, [selectedService]);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}purposeoftest`)
      .then((response) => response.json())
      .then((data) => {
        setPurposeOfTest(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching purpose of test:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}leadtestexperience`)
      .then((response) => response.json())
      .then((data) => {
        setLeadTestExperience(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching test experience:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}examstatus`)
      .then((response) => response.json())
      .then((data) => {
        setExamStatus(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching exam status:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}levelofinterest`)
      .then((response) => response.json())
      .then((data) => {
        setLevelOfInterest(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching level of interest:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}proficiencylevel`)
      .then((response) => response.json())
      .then((data) => {
        setProficiencyLevel(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching proficiency level:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_ENDPOINT}leadsource`)
      .then((response) => response.json())
      .then((data) => {
        const filteredLeadSources = data.filter((sub) => sub.is_active === 1);
        //setSubSourceTypes(filteredLeadSources);
        setLeadSources(filteredLeadSources);
      })
      .catch((error) => {
        console.error("Error fetching lead sources:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedSourceType) {
      fetch(`${API_ENDPOINT}subleadsource`)
        .then((response) => response.json())
        .then((data) => {
          const filteredLeadSources = data.filter(
            (sub) => sub.lead_source_type_id === selectedSourceType
          );
          setSubLeadSources(filteredLeadSources);
        })
        .catch((error) => {
          console.error("Error fetching sub lead sources:", error);
        });
    }
  }, [selectedSourceType]);

  return (
    <>
      {/* Header */}
      <Box mt={2} mb={2} ml={2}>
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
          color="#171A1FFF"
        >
          Add Incoming Call
        </Typography>
      </Box>

      {/* Body */}

      <Grid container spacing="4">
        <Grid item xs={12} md={12} lg={12}>
          <Box container spacing="2">
            <Stack direction="column" spacing={2}>
              <Item>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      First Name
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="first_name"
                      control={control}
                      rules={{ required: "First Name is required" }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          error={Boolean(errors.first_name)}
                          //helperText={errors.first_name && 'First name is required'}
                        />
                      )}
                    />
                    {errors.first_name && (
                      <span style={{ color: "red" }}>
                        {errors.first_name.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Last Name
                    </InputLabel>
                    <Controller
                      name="last_name"
                      control={control}
                      //rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          error={Boolean(errors.last_name)}
                          // helperText={errors.last_name && 'Last name is required'}
                        />
                      )}
                    />
                    {errors.last_name && (
                      <span style={{ color: "red" }}>
                        {errors.last_name.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3} container>
                    <Grid item xs={4} md={4} lg={4}>
                      <InputLabel
                        style={{ color: "black", position: "relative" }}
                      >
                        ISD Code
                        <span
                          style={{
                            color: "red",
                            position: "absolute",
                            marginLeft: "4px",
                            fontSize: "1.5em",
                          }}
                        >
                          *
                        </span>
                      </InputLabel>
                      <Controller
                        name="mobile_country_code"
                        control={control}
                        defaultValue={91}
                        rules={{ required: "Country code is required" }}
                        render={({ field }) => (
                          <TextField
                            select
                            {...field}
                            InputProps={{
                              style: { borderRadius: "12px" },
                            }}
                            fullWidth
                            defaultValue={91}
                            error={Boolean(errors.mobile_country_code)}
                            //helperText={errors.country && 'Country is required'}
                          >
                            {countries.map((country) => (
                              <MenuItem
                                key={country.phone_code}
                                value={country.phone_code}
                              >
                                {country.phone_code}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {errors.mobile_country_code && (
                        <span style={{ color: "red" }}>
                          {errors.mobile_country_code.message}
                        </span>
                      )}
                    </Grid>

                    <Grid item xs={8} md={8} lg={8}>
                      <InputLabel
                        style={{ color: "black", position: "relative" }}
                      >
                        Mobile
                        <span
                          style={{
                            color: "red",
                            position: "absolute",
                            marginLeft: "4px",
                            fontSize: "1.5em",
                          }}
                        >
                          *
                        </span>
                      </InputLabel>
                      <Controller
                        name="mobile"
                        control={control}
                        //defaultValue='+91'
                        rules={{
                          required: "Contact number is required",
                          pattern: {
                            value: /^[1-9][0-9]{9}$/,
                            message: "Invalid mobile number.",
                          },
                        }}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            InputProps={{
                              style: { borderRadius: "12px" },
                            }}
                            error={!!errors.mobile}
                            //helperText={errors.mobile?.message}
                            fullWidth
                          />
                        )}
                      ></Controller>
                      {errors.mobile && (
                        <span style={{ color: "red" }}>
                          {errors.mobile.message}
                        </span>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>Email</InputLabel>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          // label="Email"
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          variant="outlined"
                          // helperText={errors.email}
                          fullWidth
                        />
                      )}
                    ></Controller>
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      Service Name
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="service_id"
                      control={control}
                      rules={{ required: "Service name is required" }}
                      defaultValue={1}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                          value={selectedService}
                          onChange={handleServiceChange}
                          // defaultValue='test prep'
                          error={Boolean(errors.service_id)}
                          //helperText={errors.service_name}
                        >
                          {services.map((service) => (
                            <MenuItem key={service.id} value={service.id}>
                              {service.service_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.service_id && (
                      <span style={{ color: "red" }}>
                        {errors.service_id.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      Sub Service
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="sub_service_id"
                      control={control}
                      rules={{ required: "Sub Service is required" }}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                          // defaultValue='test prep'
                          error={Boolean(errors.sub_service_id)}
                          //helperText={errors.service_name}
                        >
                          {subServices.map((service) => (
                            <MenuItem key={service.id} value={service.id}>
                              {service.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.sub_service_id && (
                      <span style={{ color: "red" }}>
                        {errors.sub_service_id.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      Country of Residence
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="country_id"
                      control={control}
                      defaultValue={101}
                      rules={{ required: "Country is required" }}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          defaultValue={101}
                          error={Boolean(errors.country_id)}
                          //helperText={errors.country && 'Country is required'}
                        >
                          {countriesList.map((country) => (
                            <MenuItem key={country.id} value={country.id}>
                              {country.country_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.country_id && (
                      <span style={{ color: "red" }}>
                        {errors.country_id.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>Pincode</InputLabel>
                    <Controller
                      name="pincode_id"
                      control={control}
                      //rules={{ required: "Pin code is required", pattern: { value: /^\d{6}$/, message: "Invalid pin code" } }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                          error={Boolean(errors.pincode_id)}
                          onChange={handlePinCodeChange}
                        />
                      )}
                    />
                    {errors.pincode_id && (
                      <span className="error-message" style={{ color: "red" }}>
                        {errors.pincode_id.message}
                      </span>
                    )}
                    {pincodeError && (
                      <span className="error-message" style={{ color: "red" }}>
                        {pincodeError}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>State</InputLabel>
                    <Controller
                      name="state_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                          value={selectedState}
                          onChange={handleStateChange}
                          // defaultValue={pinCodeData?.state}
                          error={Boolean(errors.state_id)}
                          //helperText={errors.state}
                        >
                          {states.map((state) => (
                            <MenuItem key={state.id} value={state.id}>
                              {state.state_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>City</InputLabel>
                    <Controller
                      name="city_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                          value={selectedCity}
                          onChange={handleCityChange}
                          //defaultValue="IN"
                          error={Boolean(errors.city_id)}
                          // helperText={errors.city }
                        >
                          {cities.map((city) => (
                            <MenuItem key={city.id} value={city.id}>
                              {city.city_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Nearest Branch
                    </InputLabel>
                    <Controller
                      name="nearest_branch_id"
                      control={control}
                      //rules={{ required: "Branch name is required" }}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          //value={nearest_branch}
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                          error={Boolean(errors.nearest_branch_id)}
                          // onChange={(event) => setNearestBranch(event.target.value)}
                          // helperText={errors.nearest_branch && 'Branch Name is required'}
                        >
                          {branches.map((branch) => (
                            <MenuItem key={branch.id} value={branch.id}>
                              {branch.branch_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.nearest_branch_id && (
                      <span style={{ color: "red" }}>
                        {errors.nearest_branch_id.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Date of Birth
                    </InputLabel>
                    <Controller
                      name="dob"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          type="date"
                          InputProps={{
                            style: { borderRadius: "12px" },
                            inputProps: { max: "2005-12-31" },
                          }}
                          error={Boolean(errors.dob)}
                          // helperText={errors.dob && 'Date of Birth is required'}
                        />
                      )}
                    />
                    {errors.dob && (
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          top: 0,
                          right: -8,
                        }}
                      >
                        *
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Lead Source Type
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="lead_source_type_id"
                      control={control}
                      rules={{ required: "Lead Source is required" }}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                          error={Boolean(errors.lead_source_type_id)}
                          /*onChange={e => {
                                            field.onChange(e);
                                            handleLeadSourceTypeChange(e.target.value);
                                          }}*/
                          value={selectedSourceType}
                          onChange={handleSourceTypeChange}
                          // helperText={errors.lead_source_type}
                        >
                          {leadSources.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                              {type.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.lead_source_type_id && (
                      <span style={{ color: "red" }}>
                        {errors.lead_source_type_id.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Sub Lead Source Type
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="sub_lead_source_type_id"
                      control={control}
                      rules={{ required: "Sub Lead Source is required" }}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                          error={Boolean(errors.sub_lead_source_type_id)}
                        >
                          {subLeadSources.map((subSource) => (
                            <MenuItem key={subSource.id} value={subSource.id}>
                              {subSource.source_type}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.sub_lead_source_type_id && (
                      <span style={{ color: "red" }}>
                        {errors.sub_lead_source_type_id.message}
                      </span>
                    )}
                  </Grid>
                </Grid>
              </Item>

              <Item>
                <Grid container spacing={4}>
                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      Exam Status
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="exam_status_id"
                      control={control}
                      rules={{ required: "Field is required" }}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                          error={Boolean(errors.exam_status_id)}
                          //helperText={errors.exam_status}
                          onChange={(e) =>
                            handleExamStatusChange(e.target.value)
                          }
                        >
                          {examStatuses.map((status) => (
                            <MenuItem key={status.id} value={status.id}>
                              {status.exam_status_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.exam_status_id && (
                      <span style={{ color: "red" }}>
                        {errors.exam_status_id.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Tentative / Date of Exam
                    </InputLabel>
                    <Controller
                      name="tentative_test_date"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          type="date"
                          InputProps={{
                            style: { borderRadius: "12px" },
                            inputProps: {
                              min: new Date().toISOString().split("T")[0],
                            },
                          }}
                          error={Boolean(errors.date_of_exam)}
                          //helperText={errors.date_of_exam && 'Date of Exam is required'}
                        />
                      )}
                    />
                    {errors.date_of_exam && (
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          top: 0,
                          right: -8,
                        }}
                      >
                        *
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Assumptive Proficiency Level
                    </InputLabel>
                    <Controller
                      name="assumptive_proficiency_level_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                          error={Boolean(errors.proficiency_level)}
                          //helperText={errors.proficiency_level}
                        >
                          {proficiencyLevel.map((status) => (
                            <MenuItem key={status.id} value={status.id}>
                              {status.english_proficiency}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Level of Interest
                    </InputLabel>
                    <Controller
                      name="level_of_interest_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                          error={Boolean(errors.level_of_interest_id)}
                          // helperText={errors.proficiency_level_of_interest}
                        >
                          {levelOfInterest.map((status) => (
                            <MenuItem key={status.id} value={status.id}>
                              {status.level_of_interest}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Assign To
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="assign_to"
                      control={control}
                      rules={{ required: "Field is required" }}
                      render={({ field }) => (
                        <Autocomplete
                          {...field}
                          disableClearable
                          disablePortal
                          value={
                            users.find((owner) => owner.id === field.value) ||
                            null
                          }
                          options={users}
                          getOptionLabel={(option) =>
                            `${option.first_name ? option.first_name : ""} ${
                              option.last_name ? option.last_name : ""
                            }`
                          }
                          onChange={(_, value) =>
                            field.onChange(value ? value.id : null)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                style: { borderRadius: "12px" },
                              }}
                              fullWidth
                              error={Boolean(errors.assign_to)}
                              helperText={errors.assign_to?.message}
                            />
                          )}
                        />
                      )}
                    />
                    {errors.assign_to && (
                      <span style={{ color: "red" }}>
                        {errors.assign_to.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={12} md={12} lg={12}>
                    <InputLabel style={{ color: "black" }}>
                      Add a Note
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="notes"
                      control={control}
                      //rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          multiline
                          rows={5}
                          fullWidth
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          error={Boolean(errors.notes)}
                          // helperText={errors.notes && 'Notes is required'}
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Type of Task
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="type_of_task"
                      control={control}
                      rules={{ required: "Field is required" }}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                          error={Boolean(errors.type_of_task)}
                          //helperText={errors.type_of_task}
                        >
                          {taskItems.map((item) => (
                            <MenuItem key={item.id} value={item.id}>
                              {item.name_of_task_type}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.type_of_task && (
                      <span style={{ color: "red" }}>
                        {errors.type_of_task.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Due Date
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="task_due_date"
                      control={control}
                      rules={{ required: "Field is required" }}
                      render={({ field }) => (
                        <TextField
                          type="date"
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            inputProps: {
                              min: new Date().toISOString().split("T")[0],
                            },
                          }}
                          fullWidth
                          error={Boolean(errors.task_due_date)}
                          // helperText={errors.task_due_date}
                        ></TextField>
                      )}
                    />
                    {errors.task_due_date && (
                      <span style={{ color: "red" }}>
                        {errors.task_due_date.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Due Time
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="task_due_time"
                      control={control}
                      rules={{ required: "Field is required" }}
                      render={({ field }) => (
                        <TextField
                          type="time"
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                          error={Boolean(errors.task_due_time)}
                          //helperText={errors.task_due_time}
                        ></TextField>
                      )}
                    />
                    {errors.task_due_time && (
                      <span style={{ color: "red" }}>
                        {errors.task_due_time.message}
                      </span>
                    )}
                  </Grid>
                  <Grid item xs={6} md={6} lg={3}>
                    <InputLabel required style={{ color: "black" }}>
                      Task Owner
                    </InputLabel>
                    <Controller
                      name="task_owner"
                      control={control}
                      // defaultValue={parseInt(userId) || null}
                      rules={{ required: "Field is required" }}
                      render={({ field }) => (
                        <Autocomplete
                          disableClearable
                          disablePortal
                          {...field}
                          value={
                            users.find((owner) => owner.id === field.value) ||
                            null
                          }
                          options={users}
                          getOptionLabel={(option) =>
                            `${option.first_name ? option.first_name : ""} ${
                              option.last_name ? option.last_name : ""
                            }`
                          }
                          onChange={(_, value) =>
                            field.onChange(value ? value.id : null)
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              InputProps={{
                                ...params.InputProps,
                                style: { borderRadius: "12px" },
                              }}
                              fullWidth
                              error={Boolean(errors.task_owner)}
                              helperText={errors.task_owner?.message}
                            />
                          )}
                        />
                      )}
                    />
                  </Grid>
                </Grid>

                <Box mt={2} display="flex" justifyContent="right">
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSubmit(onSubmit)}
                  >
                    Save
                  </Button>
                </Box>
              </Item>
            </Stack>
          </Box>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <Backdrop open={isSavingLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Saving Details
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
}

export default AddIncomingCall;
