import React from "react";
import { useState } from "react";
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Box,
  Backdrop,
  Typography,
  CircularProgress,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Alert,
} from "@mui/material";
import SubCard from "../../components/ui-component/card/SubCard";
//import XLSX from 'xlsx';
import * as XLSX from "xlsx";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const AddBulkTaskForm = () => {
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [uploadedFile, setUploadedFile] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [alertMessage, setAlertMessage] = useState("");
  const [alertSeverity, setAlertSeverity] = useState("success");

  const [errors, setErrors] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleDownloadDemo = () => {
    const templateData = [
      [
        "mobile",
        "service_id",
        "sub_service_id",
        "type_of_task",
        "follow_up_reason",
        "notes",
        "task_owner",
      ],
    ];
    const ws = XLSX.utils.aoa_to_sheet(templateData);
    const wb = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wb, ws, "Template");
    XLSX.writeFile(wb, "ImportTaskTemplate.xlsx");
  };

  const handleUpload = (e) => {
    const file = e.target.files[0];

    if (file) {
      setUploadedFile(file);

      const reader = new FileReader();
      reader.onload = (event) => {
        //const data = new Uint8Array(event.target.result);
        //const workbook = XLSX.read(data, { type: 'array' });

        //const arrayBuffer = new Uint8Array(event.target.result);
        //const blob = new Blob([arrayBuffer], { type: file.type });
        //const workbook = XLSX.read(blob, { type: 'blob' });

        const arrayBuffer = event.target.result;

        const workbook = XLSX.read(arrayBuffer, { type: "array" });

        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];

        // Assuming the structure is different: 'First Name', 'Last Name', 'Email', 'Mobile', 'Whatsapp', 'Gender', 'Pincode'
        const headers = [
          "mobile",
          "service_id",
          "sub_service_id",
          "type_of_task",
          "follow_up_reason",
          "notes",
          "task_owner",
        ];
        const uploadedData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

        // Check if the uploaded data has at least one element
        if (uploadedData && uploadedData.length > 0) {
          // Check if the headers are valid
          const hasValidHeaders = headers.every((header) =>
            uploadedData[0]?.includes(header)
          );

          if (hasValidHeaders) {
            // Handle the uploaded data as needed
            console.log("Uploaded Data:", uploadedData);
            //showSnackbar('File Ready for Upload', 'success');
          } else {
            console.error(
              "Invalid file structure. Headers do not match the expected structure."
            );
            showSnackbar(
              "Invalid file structure. Headers do not match the expected structure.",
              "error"
            );
          }
        } else {
          console.error("Uploaded file is empty or has an invalid structure.");
          showSnackbar(
            "Uploaded file is empty or has an invalid structure.",
            "error"
          );
        }
      };

      reader.readAsArrayBuffer(file);
    }
  };

  const handleAddUpload = async () => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    if (uploadedFile) {
      setIsLoading(true);
      const formData = new FormData();
      formData.append("file", uploadedFile);
      formData.append("user_id", userId);

      try {
        const response = await fetch(`${API_ENDPOINT}upload/tasks`, {
          method: "POST",
          headers,
          body: formData,
        });

        const result = await response.json();

        if (response.status === 200) {
          console.log("Upload successful:", result);
          showSnackbar(
            `Upload successful. Created Entries: ${result.created} Unuploaded Entries: ${result.duplicate}`,
            "success"
          );

          setAlertMessage(
            `Upload successful. Created Entries: ${result.created}, Duplicate Entries: ${result.duplicate}`
          );
          setAlertSeverity("success");

          setErrors(result.errors);
          setUploadedFile(null);
          setIsLoading(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch (error) {
        navigate("/page500");
      }
    }
  };

  return (
    <div>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Uploading
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <SubCard title="Import Tasks">
        <Box m={2}>
          <div style={{ marginTop: "16px" }}>
            <Button
              variant="contained"
              color="secondary"
              onClick={handleDownloadDemo}
            >
              Download Demo Excel
            </Button>
          </div>

          <div style={{ marginTop: "16px" }}>
            <input type="file" onChange={handleUpload} />
            <Button
              sx={{ mt: "10px" }}
              variant="contained"
              color="secondary"
              onClick={handleAddUpload}
            >
              Upload
            </Button>
          </div>

          {alertMessage && (
            <Alert severity={alertSeverity} sx={{ mt: 2 }}>
              {alertMessage}
            </Alert>
          )}

          {errors.length > 0 && (
            <TableContainer component={Paper} sx={{ mt: 2 }}>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell>Row</TableCell>
                    <TableCell>Mobile</TableCell>
                    <TableCell>Error</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {errors.map((error, index) => (
                    <TableRow key={index}>
                      <TableCell>{error.row}</TableCell>
                      <TableCell>{error.mobile}</TableCell>
                      <TableCell>{error.error}</TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          )}
        </Box>
      </SubCard>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  );
};

export default AddBulkTaskForm;
