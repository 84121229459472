import { Box, Button, useTheme } from '@mui/material'
import React from 'react'

function ButtonComponent( {path, title}) {
    const theme = useTheme()
  return (
    <Box marginRight={2}>
    <Button     
      variant="rounded"
      sx={{
        background: theme.palette.secondary.light,
        color: theme.palette.secondary.dark,
        '&:hover': {
          background: theme.palette.secondary.dark,
          color: theme.palette.secondary.light
        },
       borderRadius: '12px', 
       overflow: 'hidden' 
      }}
      onClick={path}
      color="inherit"
    >
      {title}
    </Button>
    </Box>
  )
}

export default ButtonComponent