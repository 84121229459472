import {
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { fDate } from "../../../utils/formatTime";
import AccountsCard from "../../components/ui-component/card/account/AccountsCard";
import { Link, useNavigate } from "react-router-dom";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import MainCard from "../../components/ui-component/card/MainCard";
import CollapsibleForm from "./Paymentfilter";
import { useGetTokenOrLogout } from "../../../utils/token";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function ViewPaidPaymentsAccounts() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();

  const userId = localStorage.getItem("userId");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [cashTransactionCount, setCashTransactionCount] = useState(null);
  const [cashRevenue, setCashRevenue] = useState(null);
  const [onlineTransactionCount, setOnlineTransactionCount] = useState(null);
  const [onlineRevenue, setOnlineRevenue] = useState(null);
  const [totalTransactionCount, setTotalTransactionCount] = useState(null);
  const [totalRevenue, setTotalRevenue] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterCriteria, setFilterCriteria] = useState(null);

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleApplyFilter = (criteria) => {
    setFilterCriteria(criteria);
  };
  const handleResetFilter = () => {
    setFilterCriteria(null); // Reset filter criteria
    // setRows(originalRows); // Reset table data to original rows
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleAction = (row, action) => {
    // Implement the logic for each action
    if (action === "changeToPaid") {
      // Change status to "Paid"
      const updatedData = payments.map((item) =>
        item === row ? { ...item, status: "Paid" } : item
      );
      setPayments(updatedData);
    } else if (action === "generateReceipt") {
      // Implement logic for generating a receipt (you can open a dialog, etc.)
      // console.log(`Generate receipt for ${row.customer.name}`);
    }
  };

  const handleUpdateStatus = (paymentId, newStatus) => {
    // Update the status locally in the state
    const updatedPayments = payments.map((payment) =>
      payment.paymentId === paymentId
        ? { ...payment, payment_status: newStatus }
        : payment
    );
    setPayments(updatedPayments);

    // Send a request to the server to update the status
    fetch(`${API_ENDPOINT}route/editprovesional/${paymentId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({ payment_status: newStatus, user_id: userId }),
    })
      .then((response) => {
        if (response.ok) {
          showSnackbar("Payment status updated successfully", "success");
        } else {
          showSnackbar("Failed to update payment status", "error");
          throw new Error("Failed to update payment status");
        }
        // Handle successful response if needed
      })
      .catch((error) => {
        console.error("Error updating payment status:", error);
        showSnackbar("Error updating payment status", "error");
        // Revert the status locally if the server update fails
        setPayments(payments);
      });
  };




  useEffect(() => {
    setIsLoading(true);
     // Fetch expenses data
     const token = getTokenOrLogout();
     if (!token) return;
     let headers = { 
       Authorization: `Bearer ${token}`,
      
     };
 
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getpaidpayment/${userId}`,{headers});
        if (response.ok) {
          const data = await response.json();
          const activePayments = data.filter((item) => item.is_active === 1);

          // Sort the filtered data by payment_date
          const sortedData = activePayments.sort(
            (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
          );
  
          let filteredData = [...sortedData];
          if (filterCriteria) {
            filteredData = filteredData.filter((payment) => {
              let passesFilter = true;
              if (filterCriteria.name.length > 0) {
                const fullName = `${payment.enquiry_id?.first_name} ${payment.enquiry_id?.last_name}`
                  .toLowerCase()
                  .replace(/\s+/g, ""); // remove space between name
                const filterString = filterCriteria.name
                  .toLowerCase()
                  .replace(/\s+/g, ""); // remove space between name
                passesFilter = passesFilter && fullName.includes(filterString);
              }
  
              if (filterCriteria.mobile.length > 0) {
                passesFilter =
                  passesFilter &&
                  payment.enquiry_id?.mobile.includes(filterCriteria.mobile);
              }
  
              if (filterCriteria.newBranch.length > 0) {
                passesFilter =
                  passesFilter &&
                  passesFilter &&
                  filterCriteria.newBranch.includes(
                    payment?.enquiry_id?.branch_id?.id
                  );
              }
              if (filterCriteria.newPaymentName.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newPaymentName.includes(
                    payment?.payment_mode_id?.payment_mode
                  );
              }
  
              if (
                filterCriteria.amountFrom !== "" &&
                filterCriteria.amountTo !== ""
              ) {
                passesFilter =
                  passesFilter &&
                  parseFloat(payment.amount) >=
                    parseFloat(filterCriteria.amountFrom) &&
                  parseFloat(payment.amount) <=
                    parseFloat(filterCriteria.amountTo);
              }
  
              if (filterCriteria.startDate && filterCriteria.endDate) {
                const startDate = filterCriteria.startDate;
                const endDate = filterCriteria.endDate;
                const paymentDate = new Date(payment.payment_date);
                const formattedDate = paymentDate.toISOString().slice(0, 10);
                passesFilter =
                  passesFilter &&
                  formattedDate >= startDate &&
                  formattedDate <= endDate;
              }
              return passesFilter;
            });
          }
          console.log(sortedData);
          setPayments(filteredData);
  
          // Calculate counts and revenue for each payment mode
          const cashData = filteredData.filter(
            (item) => item.payment_mode_id?.payment_mode === "Cash"
          );
          const onlineData = filteredData.filter(
            (item) => item.payment_mode_id?.payment_mode === "Online payment"
          );
  
          const totalTransactionCount = filteredData.length;
          const totalRevenue = filteredData.reduce(
            (sum, item) => sum + item.amount_paid,
            0
          );
  
          const cashTransactionCount = cashData.length;
          const cashRevenue = cashData.reduce(
            (sum, item) => sum + item.amount_paid,
            0
          );
  
          const onlineTransactionCount = onlineData.length;
          const onlineRevenue = onlineData.reduce(
            (sum, item) => sum + item.amount_paid,
            0
          );
  
          // Update state with counts and revenue
          setCashTransactionCount(cashTransactionCount);
          setCashRevenue(cashRevenue);
  
          setOnlineTransactionCount(onlineTransactionCount);
          setOnlineRevenue(onlineRevenue);
  
          setTotalTransactionCount(totalTransactionCount);
          setTotalRevenue(totalRevenue);
  
          setIsLoading(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }}catch (error) {
          console.error("Error fetching data:", error);
          navigate("/page500");
        }
    };

    fetchData();
  }, [filterCriteria, userId]);


  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <MainCard>
            <Stack
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
              alignItems="center"
              mb={1}
            >
              <Typography
                sx={{
                  fontFamily: "Poppins",
                  marginLeft: "5px",
                  fontSize: "24px",
                  fontWeight: 600,
                }}
              >
                Paid Payments
              </Typography>

              <FilterListOutlinedIcon
                sx={{ width: "100px" }}
                onClick={handleToggle}
                fontSize="medium"
              />
            </Stack>

            <CollapsibleForm
              onApplyFilter={handleApplyFilter}
              onResetFilter={handleResetFilter}
              open={open}
              setOpen={setOpen}
              handleToggle={handleToggle}
            />
          </MainCard>
        </Grid>
        <Grid item xs={12} lg={4} md={6} sm={6}>
          <AccountsCard
            text1="Total Paid Transactions"
            transactions={totalTransactionCount}
            text2="Total Paid Revenue"
            revenue={totalRevenue}
          />
        </Grid>

        <Grid item xs={12} lg={4} md={6} sm={6}>
          <AccountsCard
            text1="Cash Paid Transactions"
            transactions={cashTransactionCount}
            text2="Cash Paid Revenue"
            revenue={cashRevenue}
          />
        </Grid>

        <Grid item xs={12} lg={4} md={6} sm={6}>
          <AccountsCard
            text1="Online Paid Transactions"
            transactions={onlineTransactionCount}
            text2="Online Paid Revenue"
            revenue={onlineRevenue}
          />
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{ borderRadius: "15px", padding: "10px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Customer Details
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Branch
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Payment Mode
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Date
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Amount
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.length === 0 ? (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">No data available</Typography>
              </Grid>
            ) : (
              payments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f0f0f0" : "inherit",
                      width: "6%",
                    }}
                  >
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      {`${
                        row?.enquiry_id?.first_name
                          ? row?.enquiry_id?.first_name
                          : ""
                      } ${
                        row?.enquiry_id?.last_name
                          ? row?.enquiry_id?.last_name
                          : ""
                      }`}{" "}
                      <br />{" "}
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          color: "#364152",
                        }}
                      >
                        {row.enquiry_id?.mobile}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {row?.enquiry_id?.branch_id?.branch_name}
                    </TableCell>
                    <TableCell>{row?.payment_mode_id?.payment_mode}</TableCell>
                    <TableCell>{fDate(row?.payment_date)}</TableCell>
                    <TableCell>{row?.amount_paid}</TableCell>
                    <TableCell>{row?.payment_status}</TableCell>
                    <TableCell>
                      {row?.payment_status === "pending" && (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() => handleUpdateStatus(row, "paid")}
                        >
                          Change to Paid
                        </Button>
                      )}
                      {row?.payment_status === "paid" &&
                        row?.receipt_id !== null && (
                          <Link
                            to={`/receipt/${row?.receipt_id?.ref_number}`}
                            target="_blank"
                          >
                            <Button
                              variant="contained"
                              color="secondary"
                              //onClick={() => handleGenerateReceipt(row?.receipt_id)}
                            >
                              Generate Receipt
                            </Button>
                          </Link>
                        )}
                      {/* Add additional buttons as needed */}
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={payments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>
    </>
  );
}

export default ViewPaidPaymentsAccounts;
