import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    useMediaQuery,
    useTheme,
    IconButton,
    Box,
  } from "@mui/material";
  
  import CloseIcon from "@mui/icons-material/Close";
export default function Searchpopup({open,onClose,component,title}) {
  return (
  <>
   <Dialog
      onClose={onClose}
      open={open}
      scroll="body"
      maxWidth="lg"
      fullWidth
      sx={{ borderRadius: "50px" }}
    >
        <Box sx={{background:'#E1EBEE'}}>
      <DialogTitle
        variant="h3"
        color='primary'
        sx={{ fontSize: "20px", fontWeight: "700" , }}
      >
        {title}

        <IconButton
          edge="end"
        
          onClick={onClose}
          aria-label="close"
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{component}</DialogContent>
      <DialogActions>
      
      </DialogActions>
      </Box>
    </Dialog>
  </>
  )
}
