// Author : Ayesha Shaikh
//  Date : 08 Oct 2024

import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";

export default function EmployeeEnquiryTransfer({
  selectedId,
  setRows,
  onClose,
  rows,
  setOriginalRows,
  setSelectedIds,
}) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const [createdbylist, setCreatedbylist] = useState([]);
  const [createdBy, setCreatedby] = useState(null);

  const [isLoading, setIsLoading] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(` ${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setCreatedbylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handelcreatedby = (event, value) => {
    // Check if "Select All" is selected
    setCreatedby(value);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    const formdata = {
      user_id: parseInt(userId),
      enquiry_id: selectedId,
      new_assign_to: createdBy?.id,
    };

    fetch(`${API_ENDPOINT}route/transferenquiryandtask`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formdata),
    })
      .then((response) => {
        if (response.ok) {
          setIsLoading(false);

          showSnackbar("All Enquiry Successfully Transferred!!");

          const data = rows.filter((item) => !selectedId.includes(item.id));
          setRows(data);
          setOriginalRows(data);

          setTimeout(() => {
            onClose();
          }, 1000);

          setTimeout(() => {
            setSelectedIds([]);
          }, 1200);
        } else if (response.status === 401) {
          console.error("Error: Not Found");
          showSnackbar("Unknown user", "error");
          setIsLoading(false);
        } else if (response.status === 403) {
          showSnackbar("unauthorized user", "error");
          setIsLoading(false);
        } else if (response.status === 404) {
          console.error("could't find any enquiry");
          showSnackbar("could't find any enquiry", "error");
          setIsLoading(false);
        } else {
          console.error("Error: Unknown Error");
          showSnackbar("Error", "error");
          setIsLoading(false);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showSnackbar("Error", "error");
        setIsLoading(false);
      });
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Transferring Enquiries
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
            Enquiry Owner
          </Typography>

          <Autocomplete
            value={createdBy}
            size="small"
            onChange={
              handelcreatedby // Passing only the value
            }
            options={createdbylist}
            getOptionLabel={(option) =>
              `${option.first_name ? option.first_name : ""} ${
                option.last_name ? option.last_name : ""
              }`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { borderRadius: "15px" }, // Add borderRadius here
                }}
              />
            )}
          />
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12} textAlign="end">
          <Button variant="contained" onClick={handleSubmit}>
            {" "}
            Submit
          </Button>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
