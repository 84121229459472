import { Box, Button, Grid, Typography, useMediaQuery } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom';


export default function Page403() {
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    
 
  return (
    <div>

    <Box className="box"sx={{ height: '100vh', width: '100%', position: 'relative',display:'flex', justifyContent:'center',alignItems:'center' }} >
    <Box
           className="rectangle"
           sx={{
             background: 'linear-gradient(248deg, #FFF6F0 -41.57%, rgba(255, 246, 240, 0.00) 97.88%)',
             height: '100%',
             position: 'absolute',
             top: 0,
             left: 0,
             width: '100%',
             zIndex: -1,
           }}
         />
   
         <Grid container justifyContent='center' flexDirection='column' textAlign='center' >

         <Typography sx={{ height: isSmallScreen ? '250px' : '400px', textAlign:'center' }}>
     <img src='/download.svg' alt='description' style={{ height: '100%', objectFit: 'cover' }} />
   </Typography>
   
   <Typography variant='h2' sx={{color:'#CF0C08',fontFamily:'Poppins',fontSize:'30px',fontStyle:'normal',fontWeight:600}}>403 : Access Forbidden.</Typography>
       <Typography sx={{textAlign:'center',color:'#8A8A8A',fontFamily:'Poppins',fontSize:'20px',fontStyle:'normal',fontWeight:400}} >You do not have permissions to view this page using credentials that you supplied. <br/>  Please go back to the Home page.</Typography>
      
      <Link to='/login'>
      <Button variant="contained" style={{fontSize:'18px',borderRadius:'12px' , background:'#019E6F', marginTop:'12px'}}>Go To Dashboard</Button>
      </Link>
         </Grid>
         
   </Box>
   
       </div>
  )
}
