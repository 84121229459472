import React, { useEffect, useState } from 'react';
import ClassCard from '../../views/trainer/classes/ClassCard';
import { Grid, CircularProgress, Typography, Box } from '@mui/material';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function TrainerClasses() {
  const trainerId = localStorage.getItem('trainerId');
  const [classes, setClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetch(`${API_ENDPOINT}trainerdashboard/myclasses/${trainerId}`)
      .then((res) => res.json())
      .then((data) => {
        setClasses(data);
        setIsLoading(false);
        
      })
      .catch((error) => {
        console.error('Error fetching classes:', error);
        setIsLoading(false);
      });
  }, [trainerId]);

  return (
    <div>
      {isLoading ? (
        <Box display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh">
        <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {classes.length === 0 ? (
            // Display a message when no classes are found
            <Box textAlign="center" width="100%" mt={5}>
              <Typography variant="subtitle1">
                No classes found. Please contact admin.
              </Typography>
            </Box>
          ) : (
            // Map and display class cards when data is available
            classes.map((classe) => (
              <Grid item xs={12} md={6} lg={4} key={classe.id}>
                <ClassCard
                  classId={classe.id}
                  className={classe.name}
                  slotsLeft={classe.seat_availability}
                  moduleName={classe.module_id.name}
                  startDate={classe.start_date}
                  endDate={classe.end_date}
                  startTime={classe.start_time}
                  endTime={classe.end_time}
                  trainerName={classe.trainer_id.full_name}
                  deliveryMode={classe.mode_of_delivery}
                  classCapacity={classe.capacity}
                  path='/trainer/class'
                />
              </Grid>
            ))
          )}
        </Grid>
      )}
    </div>
  );
}

export default TrainerClasses;


