import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../../utils/token";

export default function AdvisorClassFilter({  originalData,setIsLoading ,
  setClasses}) {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const getTokenOrLogout = useGetTokenOrLogout();
    const navigate = useNavigate();
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

    const showSnackbar = (message, severity) => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setSnackbarOpen(true);
    };
  
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
    const [open, setOpen] = useState(false);
  
    const handleToggle = () => {
      setOpen(!open);
    };
  
    const [classList, setClassList] = useState([]);
    const [Classname, setClassname] = useState([]);
    const [TrainerList, setTrainerList] = useState([]);
    const [trainerName, setTrainerName] = useState([]);
    const [branchlist, setBranchlist] = useState([]);
    const [branch, setBranch] = useState([]);
    const [modes, setModes] = useState([]);
    const [testTypeList, setTestTypeList] =useState([])
    const [testTypeName, setTestTypeName] =useState([])
    const [createfrom, setCreatedfrom] = useState("");
    const [createdto, setCreatedto] = useState("");

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_ENDPOINT}class`);
          if (response.ok) {
            const data = await response.json();
            setClassList(data);
          } else {
            throw new Error(`Request failed with status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }, []);
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_ENDPOINT}trainer/`);
          if (response.ok) {
            const data = await response.json();
            setTrainerList(data);
          } else {
            throw new Error(`Request failed with status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }, []);

    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_ENDPOINT}branch`);
          if (response.ok) {
            const data = await response.json();
  
            setBranchlist(data);
          } else {
            throw new Error(`Request failed with status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }, []);

      
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_ENDPOINT}route/getalltesttype`);
          if (response.ok) {
            const data = await response.json();
  
            setTestTypeList(data);
          } else {
            throw new Error(`Request failed with status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }, []);

    const ModesList = [
      { id: 0, name: "Online", value: "online" },
      { id: 1, name: "Offline", value: "offline" },
    ];
  
    const allclassList=[{name:"Select All"},...classList]
    const handelClasschange = (event,value) => {
   
      if (value.some((option) => option.name === "Select All")) {
        setClassname(classList);
      } else {
        setClassname(value);
      }
  
   
    };
  
  
  
    const allTrainerList=[{first_name:"Select All"},...TrainerList]
    const handelTrainerName = (event,value) => {
      if (value.some((option) => option.first_name === "Select All")) {
        setTrainerName(TrainerList);
      } else {
        setTrainerName(value);
      }
    };
  

  
    const allBranchList=[{branch_name:"Select All"},...branchlist]
    const handelbranchchange = (event,value) => {
      if (value.some((option) => option.branch_name === "Select All")) {
        setBranch(branchlist);
      } else {
        setBranch(value);
      }
    };


      const allTestTypeList=[{name:"Select All"},...testTypeList]
      const handelTestTypechange = (event,value) => {
        if (value.some((option) => option.name === "Select All")) {
          setTestTypeName(testTypeList);
        } else {
            setTestTypeName(value);
        }
      };

 
    const handleModeChange = (event,value) => {
      setModes(value);
    };
  
    const handlecratedfrom = (e) => {
      setCreatedfrom(e.target.value);
    };
  
    const handlecreatedto = (e) => {
      setCreatedto(e.target.value);
    };
  
    const newClassName=Classname.map((i)=>i.id)
    const newTrainerName =trainerName.map((i)=>i.id)
    const newBranch=branch.map((i)=>i.id)
    const newModes =modes.map((i)=>i.value)
   const newTestType=testTypeName.map((i)=>i.id)

    const handleApplyFilter = async(event) => {
      

        // Handle form submission logic here
        setIsLoading(true);
        const token = getTokenOrLogout();
        if (!token) return;
        let headers = { 
          Authorization: `Bearer ${token}`,
          "Content-Type": "application/json" // Make sure to keep the content type
        };
        const requestData = {
          test_type_id: newTestType,
          trainer_id:newTrainerName,
          branch_id:newBranch,
          mode_of_delivery:newModes,
          class_name:newClassName

        };
    
        try {
          const response = await fetch(`${API_ENDPOINT}route2.0/classsearchfilter`, {
            method: "POST",
            headers,
            body: JSON.stringify(requestData),
          });
    
          if (response.ok) {
          
            const result = await response.json();
         setClasses(result);
         setIsLoading(false);
          } else if (response.status === 404) {
            navigate("/page404");
          } else if (response.status === 401) {
            navigate("/page401");
          } else if (response.status === 403) {
            navigate("/page403");
          } else if (response.status === 440) {
            navigate("/page440");
          } else {
            navigate("/page500");
          }}catch (error) {
            console.error("Error fetching data:", error);
            navigate("/page500");
          }
      
    };

    const resetfilter = () => {
    
 
      setClassname([]);
      setTrainerName([]);
      setBranch([]);
      setCreatedfrom("");
      setCreatedto("");
      setModes([]);
      setTestTypeName([])
      setClasses(originalData)
    }
  return (
    <div>
      <MainCard>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              marginLeft: "5px",
              fontSize: "24px",
              fontWeight: 700,
            }}
          >
          All Classes
          </Typography>

          <FilterListOutlinedIcon onClick={handleToggle} fontSize="medium" />
        </Stack>

        <Collapse in={open} sx={{ marginTop: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3} sm={6} md={6}>
              <FormControl sx={{ mb: 1, width: "100%" }}>
                <Typography sx={{ fontWeight: 600 }}>Class Details</Typography>
              

<Autocomplete
                size="small"
                value={Classname}
                multiple
                onChange={handelClasschange}
                options={allclassList}
                getOptionLabel={(option) =>
                 option.name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
              </FormControl>
            </Grid>

            <Grid item xs={12} lg={3} sm={6} md={6}>
         
                <Typography sx={{ fontWeight: 600 }}>
                  Trainer Details
                </Typography>
             
        <Autocomplete
                size="small"
                value={trainerName}
                multiple
                onChange={handelTrainerName}
                options={allTrainerList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={3} md={6} sm={6}>
            
                <Typography sx={{ fontWeight: 600 }}>Branch</Typography>

              
             
             <Autocomplete
                size="small"
                value={branch}
                multiple
                onChange={handelbranchchange}
                options={allBranchList}
                getOptionLabel={(option) =>
                 option.branch_name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={3} md={6} sm={6}>
            
            <Typography sx={{ fontWeight: 600 }}>Test Type</Typography>

          
         
         <Autocomplete
            size="small"
            value={testTypeName}
            multiple
            onChange={handelTestTypechange}
            options={allTestTypeList}
            getOptionLabel={(option) =>
             option.name
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { borderRadius: "15px" }, // Add borderRadius here
                }}
              />
            )}
          />
        </Grid>

            <Grid item xs={6} lg={3} md={6} sm={6}>
         
                <Typography sx={{ fontWeight: 600 }}>
                  Mode Of Delivery
                </Typography>
               
<Autocomplete
                size="small"
                value={modes}
                multiple
                onChange={handleModeChange}
                options={ModesList}
                getOptionLabel={(option) =>
                  option.name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
             </Grid>

            {/* <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600 }}>
                {" "}
                Created From Date{" "}
              </Typography>
              <TextField
                value={createfrom}
                onChange={handlecratedfrom}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
                type="date"
              />
            </Grid>

            <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600 }}>Created To Date </Typography>
              <TextField
                value={createdto}
                onChange={handlecreatedto}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
                type="date"
              />
            </Grid> */}

            <Grid item xs={12} lg={12} sm={12} md={12} textAlign='end' >
              <Button
                variant="contained"
                color="error"
                style={{
                  width: "150px",
                  borderRadius: "24px",
                  fontWeight: 500,
                  fontSize: "14px",
                  marginRight: "8px",
                }}
                onClick={resetfilter}
              >
                Reset Filter
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{
                  width: "150px",
                  borderRadius: "24px",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
                onClick={handleApplyFilter}
              >
                Apply Filter
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </MainCard>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </div>
  )
}
