import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Button,
  FormHelperText,
} from '@mui/material';
import CustomSnackbar from '../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

const CreateClassDialog = ({ open, onClose, onSubmit, room_Name, roomId, branchId, updateRoomSchedule, room_Teaching_mode }) => {
  const adminId = localStorage.getItem('adminId');
  const  userId  = localStorage.getItem('userId')
  const idbranch = branchId
  const idroom = roomId
  const [testTypes, setTestTypes] = useState([])
  const [modules, setModules] = useState([])
  const [trainers, setTrainers] = useState([])
  const [branches, setBranches] = useState([])
  const [className, setClassName] = useState('');
  const [roomName, setRoomName] = useState(room_Name);
  const [testType, setTestType] = useState(null);
  const [module, setModule] = useState('');
  const [trainer, setTrainer] = useState('');
  const [deliveryMode, setDeliveryMode] = useState();
  const [branchLocation, setBranchLocation] = useState('');
  // const [startDate, setStartDate] = useState('');
  // const [endDate, setEndDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [capacity, setCapacity] = useState(null);
  const [zoomLink, setZoomLink] = useState(null);

  const [errors, setErrors] = useState({
    roomName: '',
    testType: '',
    trainer: '',
    zoomLink: '',
    startDate: '',
    endDate: '',
    startTime: '',
    endTime: '',
    capacity: '',
});

  const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    
        const showSnackbar = (message, severity) => {
            setSnackbarMessage(message);
            setSnackbarSeverity(severity);
            setSnackbarOpen(true);
        };

  const handleModuleChange = (event) => {
    setModule(event.target.value);
  };

  useEffect(() => {
    const fetchTestTypes = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}admindashboard/gettesttype`);
        const data = await response.json();
        
        setTestTypes(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchModules = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}admindashboard/getmodule`);
        const data = await response.json();
        setModules(data);
      } catch (error) {
        console.error(error);
      }
    };

    const fetchTrainers = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/trainers/${idbranch}`);
        
        if (response.status === 403) {
          // Handle 403 error by setting trainers to an empty array
          setTrainers([]);
          console.error('Forbidden - 403 Error');
          return;
        }
    
        const data = await response.json();
        setTrainers(data);
      } catch (error) {
        console.error('Error fetching trainers:', error);
      }
    };
    

    const fetchBranches = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}admindashboard/getbranch`);
        const data = await response.json();
        setBranches(data);
      } catch (error) {
        console.error(error);
      }
    };

    fetchTestTypes();
    fetchModules();
    fetchTrainers();
    fetchBranches();
  }, []);

  const validateForm = () => {
    let isValid = true;
    const newErrors = { ...errors };

    // Validate roomName
    if (!roomName) {
        newErrors.roomName = 'Room Assigned is required';
        isValid = false;
    } else {
        newErrors.roomName = '';
    }

    // Validate testType
    if (!testType) {
        newErrors.testType = 'Test Type is required';
        isValid = false;
    } else {
        newErrors.testType = '';
    }

    // Validate trainer
    if (!trainer) {
        newErrors.trainer = 'Trainer is required';
        isValid = false;
    } else {
        newErrors.trainer = '';
    }

    if (!startTime) {
      newErrors.startTime = 'Start time is required';
      isValid = false;
  } else {
      newErrors.startTime = '';
  }

  if (!endTime) {
    newErrors.endTime = 'End Time is required';
    isValid = false;
} else {
    newErrors.endTime = '';
}

    // Validate Zoom link for online classes
    if (room_Teaching_mode === 'online' && !zoomLink) {
        newErrors.zoomLink = 'Zoom Link is required for online classes';
        isValid = false;
    } else {
        newErrors.zoomLink = '';
    }

    

    setErrors(newErrors);

    return isValid;
};


  const handleSubmit = () => {
    // Perform form validation here if needed

    if (!validateForm()) {
      // If form is not valid, do not proceed with submission
      return;
  }
   

    const newClass = {
     // name:className,
      test_type_id: parseInt(testType),
     // module_id: parseInt(module),
      trainer_id: parseInt(trainer),
      //mode_of_delivery: deliveryMode,
      branch_id: parseInt(idbranch),
      room_id: idroom,
      //start_date: startDate,
     // end_date: endDate,
      start_time: startTime,
      end_time: endTime,
      zoom_link: zoomLink,
     // capacity: parseInt(capacity),
      user_id: parseInt(userId)
      //creator_id: parseInt(adminId)
    };

    //onSubmit(newClass);

    console.log(newClass)

    fetch(`${API_ENDPOINT}class`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(newClass),
    })
      /*.then(response => response.json())
      .then(data => {
        // Handle the response from the API if needed
        console.log('Class added:', data);
        alert('Class created successfully')
      })*/
      .then((response) => {
        if (response.ok) {
          
          showSnackbar('Class created successfully', 'success');
          updateRoomSchedule(newClass);
         // alert('Class created successfully')
          onClose();
          setClassName('')
          setTestType('')
          setModule('')
          setTrainer('')
          setDeliveryMode('')
          setBranchLocation('')
        //  setStartDate('')
          //setEndDate('')
          setStartTime('')
          setEndTime('')
          setCapacity(null)
          setZoomLink(null)

          setErrors({
            roomName: '',
            testType: '',
            trainer: '',
            zoomLink: '',
            startDate: '',
            endDate: '',
            startTime: '',
            endTime: '',
            capacity: '',
        });
          
        } else if(response.status === 409) {
          
            console.log('Error:', response)
           // alert('Error creating class')
            showSnackbar('Error creating class: Duplicate class name in the same room', 'error');
         
        }else {
          console.log('Error:', response)
         // alert('Error creating class')
          showSnackbar('Error creating class', 'error');
        }
      })
      .catch(error => {
        // Handle any errors that occurred during the fetch request
        console.error('Error adding class:', error);
       // alert('Error creating class')
       showSnackbar('Error creating class', 'error');
      });

      
    
  };

  const handleCancel = () => {
    setErrors({
        roomName: '',
        testType: '',
        trainer: '',
        zoomLink: '',
        //startDate: '',
       // endDate: '',
        startTime: '',
        endTime: '',
        capacity: '',
    });

    

    onClose();
};

  return (
    <>
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant='h3'>Create a New Class/Slot</DialogTitle>
      <DialogContent>
       {/*} <TextField
          label="Class Name"
          fullWidth
          value={className}
          onChange={(e) => setClassName(e.target.value)}
          margin="normal"
  /> */}
        <TextField
          label="Room Assigned"
          fullWidth
          value={roomName}
         // onChange={(e) => setRoomName(e.target.value)}
          margin="normal"
       />
        <FormControl fullWidth margin="normal">
          <InputLabel>Test Type</InputLabel>
          <Select
            value={testType}
            onChange={(e) => setTestType(e.target.value)}
          >
            {testTypes.map((testType) => (
          <MenuItem key={testType.id} value={testType.id}>
            {testType.name}
          </MenuItem>
        ))}
          </Select>
          <FormHelperText sx={{ color: 'red'}}>{errors.testType}</FormHelperText>
        </FormControl>
     {/*}   <FormControl fullWidth margin="normal">
          <InputLabel>Modules</InputLabel>
          <Select
            //multiple
            value={module}
            onChange={handleModuleChange}
          >
            {modules.map((module) => (
          <MenuItem key={module.id} value={module.id}>
            {module.name}
          </MenuItem>
        ))}
            
          </Select>
        </FormControl>*/}

        <FormControl fullWidth margin="normal">
          <InputLabel>Trainer</InputLabel>
          <Select
            value={trainer}
            onChange={(e) => setTrainer(e.target.value)}
          >
            {trainers.map((trainer) => (
          <MenuItem key={trainer.id} value={trainer.id}>
            {trainer.first_name}
          </MenuItem>
        ))}
          </Select>
          <FormHelperText sx={{ color: 'red'}}>{errors.trainer}</FormHelperText>
           </FormControl>
        {/*} <FormControl fullWidth margin="normal">
          <InputLabel>Delivery Mode</InputLabel>
          <Select
            value={deliveryMode}
            onChange={(e) => setDeliveryMode(e.target.value)}
          >
            <MenuItem value="online">Online</MenuItem>
            <MenuItem value="offline">Offline</MenuItem>
          </Select>
        </FormControl>
       {/*} {deliveryMode === 'offline' && (
          <FormControl fullWidth margin="normal">
          <InputLabel>Branch Location</InputLabel>
          <Select
            value={branchLocation}
            onChange={(e) => setBranchLocation(e.target.value)}
          >
            {branches.map((branch) => (
          <MenuItem key={branch.id} value={branch.id}>
            {branch.branch_name}
          </MenuItem>
        ))}
          </Select>
        </FormControl>
            )} 
        <TextField
          label="Start Date"
          fullWidth
          type="date"
          value={startDate}
          onChange={(e) => setStartDate(e.target.value)}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          error={Boolean(errors.startDate)}
          helperText={errors.startDate}
        />
        <TextField
          label="End Date"
          fullWidth
          type="date"
          value={endDate}
          onChange={(e) => setEndDate(e.target.value)}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          error={Boolean(errors.endDate)}
          helperText={errors.endDate}
        />*/}
        <TextField
          label="Slot Start Time"
          fullWidth
          type="time"
          value={startTime}
          onChange={(e) => setStartTime(e.target.value)}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          error={Boolean(errors.startTime)}
          helperText={errors.startTime}
        />
        <TextField
          label="Slot End Time"
          fullWidth
          type="time"
          value={endTime}
          onChange={(e) => setEndTime(e.target.value)}
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          error={Boolean(errors.endTime)}
          helperText={errors.endTime}
        />

        {room_Teaching_mode === 'online' && (
                        <TextField
                            label="Zoom Link"
                            fullWidth
                            value={zoomLink}
                            onChange={(e) => setZoomLink(e.target.value)}
                            margin="normal"
                            error={Boolean(errors.zoomLink)}
                            helperText={errors.zoomLink}
                        />
                    )}

       {/*} <TextField
          label="Capacity"
          fullWidth
          type="number"
          value={capacity}
          onChange={(e) => setCapacity(e.target.value)}
          margin="normal"
        />*/}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCancel} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="primary">
          Create Class
        </Button>
      </DialogActions>
    </Dialog>

      <CustomSnackbar
      open={snackbarOpen}
      onClose={() => setSnackbarOpen(false)}
      message={snackbarMessage}
      severity={snackbarSeverity}
      />
</>
  );
};

export default CreateClassDialog;
