import React, { useEffect, useState } from "react";
import MainCard from "../../newTestPrep/components/ui-component/card/MainCard";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Box,
  CircularProgress,
  Typography,
  Divider,
  Grid,
  Stack,
  Pagination,
} from "@mui/material";
import { fDate } from "../../utils/formatTime";
import { useNavigate } from "react-router-dom";
import TicketFilter from "./TicketFilter";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function Allticket() {
  // Sample data for demonstration
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const [ticketdata, setticketdata] = useState([]);
  const [loading, setLoading] = useState(true); // Track loading state
  const [currentPage, setCurrentPage] = useState(1);
  const [ticketsPerPage] = useState(7);
  const [open, setOpen] = useState(false);
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [snackbarOpen, setSnackbarOpen] =useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    console.log(message)
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleToggle = () => {
    setOpen(!open);
  };
  const handleApplyFilter = (criteria) => {
    setFilterCriteria(criteria);
  };
  const handleResetFilter = () => {
    setFilterCriteria(null); // Reset filter criteria
    // setRows(originalRows); // Reset table data to original rows
  };
  useEffect(() => {
    setLoading(true);
    fetch(`${API_ENDPOINT}route/fetchtickets/${userId}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 403) {
          // Handle 403 error by showing Snackbar
          showSnackbar('No Ticket Found', 'error');
          setLoading(false);
          return; // No need to continue further
        } else if (response.status === 401) {
          navigate('/');
          return showSnackbar('Unknown user:', 'error');
        } else if (response.status === 404) {
          navigate('/page404');
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .then((data) => {
        if (!data) return; // Stop further execution if data is not present
        const activeTickets = data.filter((ticket) => ticket.is_active === 1);
        const sortedData = activeTickets.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        let filteredData = [...sortedData];

          if (filterCriteria) {
            filteredData = filteredData.filter((ticket) => {
              let passesFilter = true;
              if (filterCriteria.tickettype) {
                const ticketName = ticket?.type_of_ticket.toLowerCase();

                const filterString = filterCriteria.tickettype.toLowerCase();

                passesFilter =
                  passesFilter && ticketName.includes(filterString);
              }

              if (filterCriteria.dateFrom && filterCriteria.dateTo) {
                const startDate = filterCriteria.dateFrom;
                const endDate = filterCriteria.dateTo;
                const createddate = new Date(ticket?.createdAt);
                const formattedDate = createddate.toISOString().slice(0, 10);
                console.log("formated date is", createddate);
                passesFilter =
                  passesFilter &&
                  formattedDate >= startDate &&
                  formattedDate <= endDate;
              }
              if (filterCriteria.urgency.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.urgency.includes(ticket?.ticket_priority);
              }

              if (filterCriteria.status.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.status.includes(ticket?.status);
              }

              if (filterCriteria.createdBy.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.createdBy.includes(
                    ticket.created_by?.first_name
                  );
              }
              return passesFilter;
            });
          }
          setticketdata(filteredData);
        setLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
        setLoading(false);
        navigate('/page404');
      });
  }, [filterCriteria,userId]);

  console.log(filterCriteria);
  const handelclick = (ticketId) => {
    navigate(`/allticket/${ticketId}`);
  };

  const handleStatusChange = async (ticketId) => {
    try {
      const response = await fetch(`${API_ENDPOINT}ticket/${ticketId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: "resolving",
          user_id: userId,
        }),
      });

      if (response.ok) {
        // Update the ticket status locally
        const updatedTickets = ticketdata.map((ticket) => {
          if (ticket.id === ticketId) {
            return { ...ticket, status: "resolving" };
          }
          return ticket;
        });
        setticketdata(updatedTickets);
        console.log(`Ticket ${ticketId} status changed to Resolving`);
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error updating ticket status:", error);
    }
  };

  const handlePageChange = (event, pageNumber) => {
    setCurrentPage(pageNumber);
};


  const indexOfLastTicket = currentPage * ticketsPerPage;
  const indexOfFirstTicket = indexOfLastTicket - ticketsPerPage;
  const currentTickets = ticketdata.slice(
    indexOfFirstTicket,
    indexOfLastTicket
  );

  
  return (
    <>
    <Box sx={{width:'100%'}}>
      <MainCard>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              marginLeft: "5px",
              fontSize: "24px",
              fontWeight: 700,
            }}
          >
            All Tickets
          </Typography>

          <FilterListOutlinedIcon
            sx={{ width: "100px" }}
            onClick={handleToggle}
            fontSize="medium"
          />
        </Stack>
        {/* <Grid item xs={12} md={12} sm={12} lg={12}> */}
        <TicketFilter
          onApplyFilter={handleApplyFilter}
          onResetFilter={handleResetFilter}
          open={open}
          setOpen={setOpen}
          handleToggle={handleToggle}
        />
        {/* </Grid> */}
      </MainCard>

      <MainCard sx={{ marginTop: "15px" }}>
        <TableContainer>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                  }}
                >
                  Type of Ticket
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                  }}
                >
                  Created By
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                  }}
                >
                  Created At
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                  }}
                >
                  Description
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                  }}
                >
                  Urgency
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                  }}
                >
                  Status
                </TableCell>
                <TableCell
                  sx={{
                    color: "#000",
                    fontFamily: "Poppins",
                    fontSize: "18px",
                    fontStyle: "normal",
                    fontWeight: 600,
                  }}
                >
                  Actions
                </TableCell>
              </TableRow>
            </TableHead>

            <TableBody>
              {loading ? ( // Conditionally render loader if loading is true
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
             height:'100vh',
         
                   
                  }}
                >
                  <CircularProgress />
                </Box>
              ) : (
                currentTickets.map((ticket, index) => (
                  <TableRow key={ticket.id}>
                    <TableCell>{ticket.type_of_ticket}</TableCell>
                    <TableCell>{ticket.created_by?.first_name} </TableCell>
                    <TableCell>{fDate(ticket.createdAt)}</TableCell>
                    <TableCell>
                      {ticket.issue_description} {ticket.feedback_description}
                    </TableCell>
                    <TableCell>
                      {ticket.ticket_priority === 1 ? "Yes" : "No"}
                    </TableCell>
                    <TableCell>{ticket.status}</TableCell>
                    <TableCell>
                      <Grid
                        container
                        spacing={2}
                        flexDirection="row"
                        alignItems="center"
                      >
                        <Grid item>
                          {" "}
                          <Button
                            variant="contained"
                            style={{ background: "#00C766", color: "black" }}
                            onClick={() => handelclick(ticket.id)}
                          >
                            View
                          </Button>
                        </Grid>
                        {ticket.status !== "resolving" && ticket.status !== "completed" && (
      <Grid item>
        <Button
          variant="contained"
          style={{
            background: "#00C766",
            color: "black",
            marginLeft: "10px",
          }}
          onClick={() => handleStatusChange(ticket.id)}
        >
          Change to Resolving
        </Button>
      </Grid>
    )}
                      </Grid>
                    </TableCell>
                  </TableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>

        {/* Pagination controls */}
        <Box
          sx={{
            display: "flex",
            justifyContent: "flex-end",
            marginTop: "20px",
            alignItems: "center",
          }}
        >
       
          <Pagination 
                              count={Math.ceil(ticketdata.length / ticketsPerPage)}
                            color="secondary"
                             page={currentPage}
                             onChange={handlePageChange}
                             />
        </Box>
      </MainCard>
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
