import React, { useEffect, useState } from 'react'
import Typography from '@mui/material/Typography'
import CardContent from '@mui/material/CardContent'
import { styled, useTheme } from '@mui/material/styles'
import { Avatar, Box, Button, Chip, Grid } from '@mui/material'
import EmailIcon from '@mui/icons-material/Email';
import { useSelector } from 'react-redux'
import { Link, useNavigate } from 'react-router-dom'
import { gridSpacing } from '../../../newTestPrep/store/constant'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useGetTokenOrLogout } from '../../../utils/token'
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

  const Rectangle163 = styled("Card")({
    backgroundColor: `rgba(255, 255, 255, 1)`,
    boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.05)`,
    borderRadius: `34px`,
    //width: `488px`,
    //height: `282px`,
    //position: `absolute`,
    width: '100%', // Set the width to 90% of the parent container
    height: '100%', // Automatically adjust the height based on content
    margin: '0 auto', // Center the card horizontally
    padding: '16px',
    //left: `0px`,
   // top: `0px`,
  });

  const Card = styled("div")({
    backgroundColor: `rgba(255, 255, 255, 1)`,
    boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.05)`,
    borderRadius: `34px`,
    width: '100%', // Set the width to 90% of the parent container
    height: '100%', // Automatically adjust the height based on content
    margin: '0 auto', // Center the card horizontally
    padding: '10px',
  });

  const Icon = styled("img")({
    display: `flex`,
    //position: `absolute`,
    //isolation: `isolate`,
    flexDirection: `row`,
    justifyContent: `flex-start`,
    alignItems: `flex-start`,
    padding: `0px`,
    boxSizing: `border-box`,
    width: `18px`,
    height: `18px`,
  });

  /*function stringAvatar(name) {
    return {
      sx: {
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }*/

  

function AdvisorProfileCard() {
    //const { advisorId } = useSelector((state) => state.user);
    const navigate=useNavigate()
    const getTokenOrLogout = useGetTokenOrLogout();
    const userId = localStorage.getItem('userId');
    const userRole = localStorage.getItem('userRole');
  const [ advisorData, setAdvisorData] = useState({})

  console.log(userId)
   

  useEffect(() => {
    const token = getTokenOrLogout();
      if (!token) return;
      let headers = { 
        Authorization: `Bearer ${token}`,
      
      };
  
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}employee/${userId}`,{headers});
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
  
          setAdvisorData(data);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
  } catch (error) {
   navigate("/page500");
  }
    };
  
    fetchData();
  }, []);
  console.log(advisorData);

  const handleEdit = () => {
    const newState = { employeedetails: advisorData };
    localStorage.setItem("employeeState", JSON.stringify(newState));

    const url = `/view-profile/${advisorData?.id}`;
    const newTab = window.open(url, "_blank");

    if (!newTab) {
      navigate(url, { state: newState });
    }
  };

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
};

  const stringAvatar = (data) => {
    if (data.image && data.image.type === 'Buffer' && data.image.data.length > 0) {
        const dataArray = data.image.data; // Get the array of numbers from the image data
        const uint8Array = new Uint8Array(dataArray); // Create a Uint8Array from the array of numbers
        const imageBlob = new Blob([uint8Array], { type: 'image/jpeg' }); // Create a Blob from the Uint8Array
        const imageUrl = URL.createObjectURL(imageBlob); 

      return {
        src: imageUrl,
       /* sx: {
          width: 129,
          height: 129,
          backgroundColor: 'green'
        },*/
      };
    } else {
      return {
       /* sx: {
          width: 129,
          height: 129,
          backgroundColor: 'green',
        },*/
        //children: `${data.full_name?.split(' ')[0][0]}${data.full_name?.split(' ')[1][0]}`,
      };
    }
  };

  /*if (advisorData.length === 0) {
    // If advisorData is still an empty array, show a loading or placeholder state
    return <div>No data available</div>
  }*/

  const { first_name, last_name, email, image, branch, mobile, role} = advisorData || {};
  return (
    
    <Card >
    <CardContent>
        <Grid container spacing={gridSpacing}>
        {/*<Grid item xs={4}>
        <Box>
    <Avatar {...stringAvatar(advisorData)} sx={{height: '70px', width: '70px', backgroundColor: 'rgba(219, 246, 255, 1)'}}/>
    </Box>
  </Grid>*/}
    <Grid item xs={12}>
    <Box  >
      <Typography variant='h3'>
      {first_name ? capitalizeFirstLetter(first_name) : ''}{' '}
      {last_name ? capitalizeFirstLetter(last_name) : ''}{' '}
      <Chip label={userRole || ''} color="primary" />
      </Typography>
        <Box mt={2} sx={{display: 'flex'}}>
            <Icon alt='email-icon' sx={{height: '25px', width: '25px'}} src={'/wetransfer_add-enquiry-svg_2023-07-25_1047/mail.svg'}></Icon>
      <Typography variant='h5' sx={{ letterSpacing: '0.25px', overflowWrap: 'break-word', wordWrap: 'break-word', marginLeft: '8px' }}>
        {email || ''}
      </Typography>
      </Box>
      <Box sx={{display: 'flex'}} mt={2}>
          <Icon alt='call-icon' margin={2} sx={{height: '25px', width: '25px'}} src={'/wetransfer_add-enquiry-svg_2023-07-25_1047/Phone.svg'}></Icon>
      <Typography variant='h5' sx={{ letterSpacing: '0.25px', marginLeft: '8px' }}>
        {mobile || ''}
      </Typography>
      </Box>
      <Box sx={{display: 'flex'}} mt={2}>
            <Icon alt='location-icon' sx={{height: '25px', width: '25px'}} src={'/wetransfer_add-enquiry-svg_2023-07-25_1047/Location.svg'}></Icon>
      <Typography variant='h5' sx={{ letterSpacing: '0.25px', marginLeft: '8px' }}>
        { branch?.branch_name || ''}
      </Typography>
      </Box>
      
      <Grid item xs={12} textAlign='end'>
        <Button variant='contained'  onClick={handleEdit}>Edit My Profile</Button>
      </Grid>
      </Box>
      </Grid>
      </Grid>
    </CardContent>
  </Card>
  )
}

export default AdvisorProfileCard