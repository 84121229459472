import React from 'react'
import ProfileCard from '../centeradmin/ProfileCard'
import AdvisorWidget from '../../../../views/advisor/dashboard/AdvisorWidget'
import AdvisorWidget2 from '../../../../views/advisor/dashboard/AdvisorWidget2'
import AdvisorCalendarDashboard from '../../../../views/advisor/dashboard/AdvisorCalendarDashboard'
import { Grid } from '@mui/material'
import { gridSpacing } from '../../../store/constant'

function DeveloperDashboard() {
  return (
    <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={12} lg={8}>
          <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={12} lg={6}>
      <ProfileCard />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={6}>
      <AdvisorWidget 
        bgColor='rgba(219, 246, 255, 1)'
        color='rgba(0, 193, 236, 1)'
        number={0}
        text='New Enquiries'
      />
      </Grid>

      <Grid item xs={6}>
      <AdvisorWidget
        bgColor='rgba(255, 248, 233, 1)'
        color='rgba(255, 176, 0, 1)'
        number={0}
        text='Demos Scheduled'
      />
      </Grid>

      <Grid item xs={6}>
      <AdvisorWidget 
        bgColor='rgba(255, 243, 235, 1)'
        color='rgba(255, 122, 0, 1)'
        number={0}
        text='Enrolled Students'
      />
      </Grid> 

      <Grid item xs={6}>
      <AdvisorWidget 
        bgColor='rgba(239, 243, 255, 1)'
        color='rgba(23, 81, 255, 1)'
        number={0}
        text='Tasks Assigned'
      />
      </Grid>
      </Grid>
      </Grid>

      {/*<Grid container spacing={3}> */}
      <Grid item xs={12} md={6}>
          <AdvisorWidget2 
          bgColor='rgba(239, 243, 255, 1)'
          color='rgba(23, 81, 255, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Classes.png'
          title='Enquiries'
          body='See my Enquiries'
          path='/enquiries'
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <AdvisorWidget2 
          bgColor='rgba(219, 246, 255, 1)'
          color='rgba(0, 193, 236, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Demos.png'
          title='Tasks'
          body='See tasks'
          path='/all-tasks'
          />
        </Grid>


        <Grid item xs={12} md={12}>
          <AdvisorWidget2 
          bgColor='rgba(255, 248, 233, 1)'
          color='rgba(255, 176, 0, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Students.png'
          title='View Tickets'
          body='View all tickets'
          path='/allticket'
          />
        </Grid> 

        

        {/*</Grid>*/}

        </Grid>

      </Grid>

      <Grid item xs={12} md={12} lg={4}>
       <AdvisorCalendarDashboard /> 
      </Grid>

      </Grid>
  )
}

export default DeveloperDashboard