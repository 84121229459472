// Author : Ayesha Shaikh
// Date : 30 july 2024

import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function WalkInReportFilter({
  setWalkInsData,
  setLoading,
  originalData,
  setOriginalData,
  filteredData,
}) {
  const userRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();

  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);

  const [walkinTypeList, setWalkinTypeList] = useState([
    { id: 1, name: "Event", value: "event" },
    { id: 2, name: "Normal", value: "normal" },
  ]);

  const [fromDate, setFromdate] = useState("");
  const [toDate, setToDate] = useState("");
  const [walkInType, setWalkinType] = useState([]);
  const [selectedType, setSelectedType] = useState([]);
  const [subserviceList, setSubserviceList] = useState([]);
  const [subService, setSubservice] = useState([]);
  const [UserList, setUserList] = useState([]);
  const [assignTo, setAssignTo] = useState([]);
  const [appoinmentBy, setAppoinmentBy] = useState([]);
  const [createdBy, setCreatedBY] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [eventName, setEventName] = useState([]);
  const [referenceByList, setReferenceByList] = useState([]);
  const [referenceBy, setReferenceBy] = useState([]);

  const [walkInStusList, setWalkinStatuslist] = useState([
    { id: 1, name: "Direct", value: "direct" },
    { id: 2, name: "Indirect", value: "indirect" },
    { id: 3, name: "Existing", value: "existing" },
  ]);

  const [walkinStatus, setWalkInstatus] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Fetch Branch
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setBranchlist(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUserList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setSubserviceList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}campaign/event/withname`);
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          const filterData = sortedData.filter(
            (i) => i.campaign_type_id?.id === 1
          );
          setEventList(filterData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  // Fetch reference bY Api

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}referencewalkin`);
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);

          setReferenceByList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleFromDateChange = (e) => {
    setFromdate(e.target.value);
  };

  const handleToDateChange = (e) => {
    setToDate(e.target.value);
  };

  const allBranchlist = [{ branch_name: "Select All" }, ...branchlist];

  const handlebranchChange = (event, value) => {
    console.log("branch_name", value);
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

  const handlewalkinStatusChange = (event, value) => {
    setWalkInstatus(value);
  };

  const handlewalkInTypeChange = (event, value) => {
    const id = value.map((i) => i.id);
    setWalkinType(value);
    setSelectedType(id);
  };

  const allsubseriviceslist = [{ name: "Select All" }, ...subserviceList];
  const handleSubserviceChange = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      setSubservice(subserviceList);
    } else {
      setSubservice(value);
    }
  };

  const allUserList = [{ first_name: "Select All" }, ...UserList];

  const handleAssignToChange = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setAssignTo(UserList);
    } else {
      setAssignTo(value);
    }
  };

  const handleCretedbyChange = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setCreatedBY(UserList);
    } else {
      setCreatedBY(value);
    }
  };

  const allCampaignNAme = [{ campaign_name: "Select All" }, ...eventList];
  const handleEventNameChange = (event, value) => {
    if (value.some((option) => option.campaign_name === "Select All")) {
      setEventName(eventList);
    } else {
      setEventName(value);
    }
  };

  const allReferenceName = [{ name: "Select All" }, ...referenceByList];
  const handleReferenceChange = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      setReferenceBy(referenceByList);
    } else {
      setReferenceBy(value);
    }
  };

  const handleSubmit = async (event) => {
    setLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const requestData = {
      from_date: fromDate || null,
      to_date: toDate || null,
      user_id: parseInt(userId),
      walk_in_status: walkinStatus.map((i) => i.value),
      type_of_walking: walkInType.map((i) => i.value),
      sub_service_id: subService.map((i) => i.id),
      branch_id: branch.map((i) => i.id),
      event_id: eventName.map((i) => i.id),
      created_by: createdBy.map((i) => i.id),
      assign_to: assignTo.map((i) => i.id),
      reference_walkin_id: referenceBy.map((i) => i.id),
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}reports/walkinreportfilter`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        const sortedData = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setWalkInsData(sortedData);
        setOriginalData(sortedData);
        setLoading(false);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      navigate("/page500");
    }
  };

  const resetFilter = () => {
    setWalkInsData(filteredData);
    setOriginalData(filteredData);
    setFromdate("");
    setToDate("");
    setBranch([]);
    setWalkInstatus([]);
    setWalkinType([]);
    setSubservice([]);
    setAssignTo([]);
    setCreatedBY([]);
    setEventName([]);
    setSelectedType([]);
  };

  return (
    <>
      <MainCard>
        <Grid container spacing={2}>
          <Grid item xs={12} md={12} sm={12} lg={12}>
            <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
              Filter Walk-in Data
            </Typography>
          </Grid>
          <Grid item xs={6} lg={2} sm={4} md={3}>
            <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
              From Date
            </Typography>
            <TextField
              value={fromDate}
              onChange={handleFromDateChange}
              size="small"
              fullWidth
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
            />
          </Grid>

          <Grid item xs={6} lg={2} sm={4} md={3}>
            <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
              To Date
            </Typography>
            <TextField
              value={toDate}
              onChange={handleToDateChange}
              size="small"
              fullWidth
              type="date"
              InputProps={{
                style: { borderRadius: "15px" },
              }}
            />
          </Grid>

          <Grid item xs={12} lg={4} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
              Branch{" "}
            </Typography>
            <Autocomplete
              size="small"
              value={branch}
              multiple
              onChange={handlebranchChange}
              options={allBranchlist}
              getOptionLabel={(option) => option.branch_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} lg={4} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
              Walk-in Status{" "}
            </Typography>
            <Autocomplete
              size="small"
              value={walkinStatus}
              multiple
              onChange={handlewalkinStatusChange}
              options={walkInStusList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={4} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
              Walk-in Type{" "}
            </Typography>
            <Autocomplete
              size="small"
              value={walkInType}
              multiple
              onChange={handlewalkInTypeChange}
              options={walkinTypeList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>
          {selectedType.includes(1) && (
            <Grid item xs={12} lg={4} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Events{" "}
              </Typography>
              <Autocomplete
                size="small"
                value={eventName}
                multiple
                onChange={handleEventNameChange}
                options={allCampaignNAme}
                getOptionLabel={(option) => option.campaign_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>
          )}

          <Grid item xs={12} lg={4} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
              Sub Service{" "}
            </Typography>
            <Autocomplete
              size="small"
              value={subService}
              multiple
              onChange={handleSubserviceChange}
              options={allsubseriviceslist}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} lg={4} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
              Assign To{" "}
            </Typography>
            <Autocomplete
              size="small"
              value={assignTo}
              multiple
              onChange={handleAssignToChange}
              options={allUserList}
              getOptionLabel={(option) =>
                `${option.first_name ? option.first_name : ""} ${
                  option.last_name ? option.last_name : ""
                }`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          {(userRole === "center_admin" || userRole === "data_entry") && (
            <Grid item xs={12} lg={4} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Registered by{" "}
              </Typography>
              <Autocomplete
                size="small"
                value={createdBy}
                multiple
                onChange={handleCretedbyChange}
                options={allUserList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>
          )}

          {/* Refrence By */}

          <Grid item xs={12} lg={4} sm={6} md={6}>
            <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
              Reference by{" "}
            </Typography>
            <Autocomplete
              size="small"
              value={referenceBy}
              multiple
              onChange={handleReferenceChange}
              options={allReferenceName}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={12} lg={12} sm={12} textAlign="end">
            <Button
              variant="contained"
              sx={{ borderRadius: "25px" }}
              startIcon={<SearchIcon />}
              onClick={handleSubmit}
            >
              Search
            </Button>
            <Button
              variant="contained"
              color="error"
              sx={{ marginLeft: "10px", borderRadius: "25px" }}
              startIcon={<ClearIcon />}
              onClick={resetFilter}
            >
              Clear{" "}
            </Button>
          </Grid>
        </Grid>
      </MainCard>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
