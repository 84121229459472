import React, { useEffect, useState } from 'react'
import ProfileCard from '../centeradmin/ProfileCard'
import AdvisorWidget from '../../../../views/advisor/dashboard/AdvisorWidget'
import AdvisorWidget2 from '../../../../views/advisor/dashboard/AdvisorWidget2'
import AdvisorCalendarDashboard from '../../../../views/advisor/dashboard/AdvisorCalendarDashboard'
import { Grid } from '@mui/material'
import { gridSpacing } from '../../../store/constant'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function DataEntryDashboard() {
  const userId = localStorage.getItem('userId');
  const [ isLoading, setIsLoading] = useState(false)
  const [ totalTask, setTotalTask] = useState(0)
  const [ totalEnquiries, setTotalEnquiries] = useState(0)
  const [ totalDemos, setTotalDemos] = useState(0)
  const [ totalStudents, setTotalStudents] = useState(0)

  const fetchAllValues = async (userId) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/dashboarddetail/${userId}`);
      const data = await response.json();

      const { enrolled_student, demo_scheduled, new_enquiries, task_assigned } = data;
      console.log(data)
      setTotalDemos(demo_scheduled)
      setTotalEnquiries(new_enquiries);
      setTotalTask(task_assigned);
      setTotalStudents(enrolled_student)
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };

    useEffect(() => {
      fetchAllValues(userId)
    }, [userId]); 
  return (
    <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={12} lg={8}>
          <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={12} lg={6}>
      <ProfileCard />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={6}>
      <AdvisorWidget 
        bgColor='rgba(219, 246, 255, 1)'
        color='rgba(0, 193, 236, 1)'
        number={totalEnquiries}
        text='New Enquiries'
      />
      </Grid>

      <Grid item xs={6}>
      <AdvisorWidget
        bgColor='rgba(255, 248, 233, 1)'
        color='rgba(255, 176, 0, 1)'
        number={totalDemos}
        text='Demos Scheduled'
      />
      </Grid>

      <Grid item xs={6}>
      <AdvisorWidget 
        bgColor='rgba(255, 243, 235, 1)'
        color='rgba(255, 122, 0, 1)'
        number={totalStudents}
        text='Enrolled Students'
      />
      </Grid> 
      <Grid item xs={6}>
      <AdvisorWidget 
        bgColor='rgba(239, 243, 255, 1)'
        color='rgba(23, 81, 255, 1)'
        number={totalTask}
        text='Tasks Assigned'
      />
      </Grid>
      </Grid>
      </Grid>

      {/*<Grid container spacing={3}> */}
      <Grid item xs={12} md={6}>
          <AdvisorWidget2 
          bgColor='rgba(239, 243, 255, 1)'
          color='rgba(23, 81, 255, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Classes.png'
          title='Enquiries'
          body='See my Enquiries'
          path='/enquiries'
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <AdvisorWidget2 
          bgColor='rgba(219, 246, 255, 1)'
          color='rgba(0, 193, 236, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Demos.png'
          title='Tasks'
          body='See tasks'
          path='/all-tasks'
          />
        </Grid>


        <Grid item xs={12} md={12}>
          <AdvisorWidget2 
          bgColor='rgba(255, 248, 233, 1)'
          color='rgba(255, 176, 0, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Students.png'
          title='Import Leads'
          body='Import leads'
          path='/addbulkenquiries'
          />
        </Grid> 

        

        {/*</Grid>*/}

        </Grid>

      </Grid>

      <Grid item xs={12} md={12} lg={4}>
       <AdvisorCalendarDashboard /> 
      </Grid>

      </Grid>
  )
}

export default DataEntryDashboard