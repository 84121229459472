import { Box, Grid, Stack, Typography, useTheme } from '@mui/material';
import React from 'react'
import styled from 'styled-components';
import MainCard from '../MainCard';

export default function AccountsCard({text1, transactions, text2, revenue}) {
    const theme = useTheme()
    const StyledGlobalBox = styled(Box)`
   
    border-radius:50%;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    img {
      max-width: 100%;
      max-height: 100%;
    }
  `;
  return (
  <>
   
    <MainCard style={{ background: theme.palette.secondary.light }}>
       <Stack spacing={1}>
      {/* <Stack display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'> */}
      <Typography sx={{ color: '#151D48', fontFamily: 'Poppins', fontSize: '20px', fontStyle: 'normal', fontWeight: 600 }}>
                  {text1}
        </Typography>
        <Typography sx={{ color: '#151D48', fontFamily: 'Poppins', fontSize: '24px', fontStyle: 'normal', fontWeight: 600 }}>
                  {transactions || 0}
        </Typography>
        <Typography sx={{ color: '#151D48', fontFamily: 'Poppins', fontSize: '20px', fontStyle: 'normal', fontWeight: 600 }}>
                  {text2}
        </Typography>
        <Typography sx={{ color: '#151D48', fontFamily: 'Poppins', fontSize: '24px', fontStyle: 'normal', fontWeight: 600 }}>
                  ₹ {revenue || 0}
        </Typography>
      {/* </Stack> */}
      <Stack>
      
     
     <Typography sx={{color:'#4079ED' , fontFamily:'Poppins' ,  fontSize:'12px' , fontStyle:'normal' , fontWeight:500}}></Typography>
      </Stack>
      </Stack>
    </MainCard>
  

  </>
  )
}
