import { AppBar, Box, Button, Card, Grid, IconButton, Stack, TableCell, TableRow, Toolbar, Typography, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import AccessTimeFilledIcon from '@mui/icons-material/AccessTimeFilled';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import { PersonPinCircleOutlined } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import AddBranch from './AddBranch';
import CustomSnackbar from '../../components/ui-component/snackbar/CustomSnackbar';
import AddBranchAdmin from './AddBranchAdmin';
import Person3Icon from '@mui/icons-material/Person3';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT





function BranchesPage() {
    const userRole = localStorage.getItem('userRole');
    const userId = localStorage.getItem('userId');
    const token = localStorage.getItem("token");
    const navigate = useNavigate()
    const [branches, setBranches] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [addDialogOpen, setAddDialogOpen] = useState(false)
    const [addAdminDialogOpen, setAddAdminDialogOpen] = useState(false)
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    const [selectedBranchId, setSelectedBranchId] = useState(null);
    const [selectedBranchName, setSelectedBranchName] = useState('')

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };


    const fetchBranchData = () => {
        setIsLoading(true)
        let headers={"Authorization": `Bearer ${token}`}

         fetch(`${API_ENDPOINT}route/getbranchdetail/${userId}`,{headers})
             .then(response => response.json())
             .then(data => {
                 setBranches(data);
                 setIsLoading(false);
             })
             .catch(error => {
                 console.error('Error fetching branch data:', error);
                 setIsLoading(false);
             });
     };
 
     useEffect(() => {
         fetchBranchData();
     }, []); 

     const handleAssignAdminClick =(branchId, branchName) => {
        
        console.log('Assigning admin for branch:', branchId);
        setSelectedBranchId(branchId);
        setSelectedBranchName(branchName)
        setAddAdminDialogOpen(true);
       
    }

    const handleViewBranchData = (branchId, branchName, branchAdmin) => {
        console.log(branchId, branchName, branchAdmin)
        navigate(`/branch-data/${branchId}`, {
            state: {
                branchName,
                branchAdmin,
              },
        })
    }

    

     const handleAddBranch = (data) => {
        console.log(data)
        const branchData = {
            pincode_id: parseInt(data.pincode_id),
            country_id: data.country_id,
            state_id: data.state_id,
            city_id: data.city_id,
            branch_name: data.name,
            address: `${data.building} ${data.floorHouse}`,
            nearest_landmark: data?.landmark || null,
            location_link: data.location_link,
            user_id: parseInt(userId)
        }

        console.log(branchData)
          fetch(`${API_ENDPOINT}branch`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(branchData),
          })
          .then((response) => {
            if(response.status === 201) {
               showSnackbar('Branch created successfully', 'success');
               window.location.reload()
            } else {
              console.log('Error:', response)
              showSnackbar('Error creating Branch', 'error');
            }
          })
            .catch((error) => {
              console.error('Error adding branch:', error);
              showSnackbar('Error creating Branch', 'error');
            });
        setAddDialogOpen(false);
      };
 
  return (
    <>
    <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12}>
    
     <Box mb={2}>
    <AppBar position="static" color='secondary'>
        <Toolbar>
            <Stack direction='row' spacing={2}>
            <Typography variant='h4'>User Role : {userRole}</Typography>
            <Typography variant='h4'> </Typography>
            </Stack>
        </Toolbar>
    </AppBar>
    </Box>
    </Grid>
    {userRole === 'center_admin' &&  (
    <Grid item xs={12} md={12} lg={12}>
    <Box mb={2}>
    <Stack direction="row" spacing={2}> 
        <Button variant='contained' color='secondary' onClick={() => setAddDialogOpen(true)}>Add Branch</Button>
        
        </Stack>
    </Box>
    </Grid>
    )}
    </Grid>
    
    <Grid container spacing={2}>
        {branches.length === 0 ? (
            
                <Grid item xs={12} sx={{alignItems: 'center'}}>
            <Typography variant='h4' >No branches. Please add Branch</Typography>
            </Grid>
          
        ) : (
            
    (branches.map((slot) => (
        <>
       
                       <Grid item xs={6} md={6} lg={4}>
                   <Card variant="outlined"
                   sx={{
                     borderColor: 'black', // Replace 'your_border_color' with the color you want
                     borderWidth: '2px', // Adjust the border width as needed
                     borderRadius: '10px'
                   }}>
                        <Box margin={5}>
                       <Grid container spacing={2}>
                           <Grid item xs={12}>
                               <Stack direction='column' spacing={2}>
                               <Stack direction='row' spacing={2}>
                                <PersonPinCircleOutlined />
                                   <Typography fontWeight={800}>{slot.branch_name} </Typography>
                                   </Stack>
               
                                   <Stack direction='row' spacing={2}>
                                   <LocationOnIcon />
                                   <Typography fontWeight={600}>{slot.address}</Typography>
                                   </Stack>

                                   <Stack direction='row' spacing={2}>
                                    <Person3Icon />
                                   <Typography fontWeight={600}>{slot.admin_id?.first_name || 'No admin assigned'}</Typography>
                                   </Stack>
                                   
                                   <Grid item xs={12}>
                                   <Stack direction='row' spacing={2}>
                                    <Link to={`/branch-data/${slot.id}`}>
                                           <Button variant='contained' color='error' onClick={() => handleViewBranchData(slot.id, slot.branch_name, slot.admin_id?.first_name)}>View Branch Data</Button>
                                           </Link>
                                           {userRole === 'center_admin' && slot.admin_id === null && (
                                        <Button
                                            variant='contained'
                                            color='secondary'
                                            onClick={() => handleAssignAdminClick(slot.id, slot.branch_name)}
                                        >
                                            Assign Branch Admin
                                        </Button>
                                    )}
                                    </Stack>
                                       </Grid>
                                      
                               </Stack>
                           </Grid>
                       </Grid>
                       </Box>
                   </Card>
                   </Grid>
                   </>
                 )))
        )}
                 </Grid>

                 <AddBranch
            open={addDialogOpen}
            onClose={() => setAddDialogOpen(false)}
            onAdd={handleAddBranch}
            />

            <AddBranchAdmin 
                open={addAdminDialogOpen}
                onClose={() => setAddAdminDialogOpen(false)}
                branchId={selectedBranchId}
                branchName={selectedBranchName}
                
            />

            <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
               
    </>
    
  )
}

export default BranchesPage