import PropTypes from 'prop-types';

// material-ui
import {
    Box,
    Button,
    CardContent,
    Chip,
    Collapse,
    Divider,
    Grid,
    IconButton,
    LinearProgress,
    List,
    ListItemButton,
    ListItemIcon,
    ListItemSecondaryAction,
    ListItemText,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableRow,
    Typography
} from '@mui/material';

// project imports

import Avatar from '../../../../components/ui-component/avatar/Avatar'; 
import SubCard from '../../../../components/ui-component/card/SubCard'; 
import { gridSpacing } from '../../../../store/constant'; 

// assets
//import { IconEdit } from '@tabler/icons-react';
import PhonelinkRingTwoToneIcon from '@mui/icons-material/PhonelinkRingTwoTone';
import PinDropTwoToneIcon from '@mui/icons-material/PinDropTwoTone';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import DateRangeIcon from '@mui/icons-material/DateRange';
import LoginIcon from '@mui/icons-material/Login';
import VpnKeyIcon from '@mui/icons-material/VpnKey';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { useEffect, useState } from 'react';
import { fDate } from '../../../../../utils/formatTime';
import StudentFormInfo from './StudentForm';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

// progress
function LinearProgressWithLabel({ value, ...others }) {
    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <Box
                sx={{
                    width: '100%',
                    mr: 1
                }}
            >
                <LinearProgress value={value} {...others} />
            </Box>
            <Box sx={{ minWidth: 35 }}>
                <Typography variant="body2" color="textSecondary">{`${Math.round(value)}%`}</Typography>
            </Box>
        </Box>
    );
}

LinearProgressWithLabel.propTypes = {
    value: PropTypes.number
};



const StudentProfile = ( {studentDetail}) => {
    const userId = localStorage.getItem('userId');
    const [userDetails, setUserDetails] = useState(null)

    const [studentDetailsExpanded, setStudentDetailsExpanded] = useState(true);
  const [classDetailsExpanded, setClassDetailsExpanded] = useState(false);

  const handleStudentDetailsToggle = () => {
    setStudentDetailsExpanded(!studentDetailsExpanded);
  };

  const handleClassDetailsToggle = () => {
    setClassDetailsExpanded(!classDetailsExpanded);
  };

  const fetchData = async () => {
    if (!studentDetail) return;

    const requestData = {
      user_id: parseInt(userId, 10),
      enquiry_id: studentDetail?.enquiry_id?.id,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route/showpassword`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          // add any other headers you need
        },
        body: JSON.stringify(requestData),
      });

      if (!response.ok) {
        throw new Error('Network response was not ok');
      }

      const result = await response.json();
      setUserDetails(result);
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };

  useEffect(() => {
    fetchData();
  }, [studentDetail]);

  
    

    return (
        <Grid container spacing={gridSpacing}>
            <Grid item lg={4} xs={12}>
                <SubCard
                    title={
                        <Grid container spacing={2} alignItems="center">
                            <Grid item>
                                <Avatar color="secondary" alt="User 1"  />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography align="left" variant="h4">
                                    {studentDetail?.enquiry_id?.first_name}  {studentDetail?.enquiry_id?.last_name}
                                </Typography>
                                <Typography align="left" variant="subtitle2">
                                {studentDetail?.lead_no}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Chip size="small" label= {studentDetail?.status_of_student} color="secondary" />
                            </Grid>
                        </Grid>
                    }
                >
                    <List component="nav" aria-label="main mailbox folders">
                        <ListItemButton>
                            <ListItemIcon>
                                <MailTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="subtitle1">Email</Typography>} />
                            <ListItemSecondaryAction>
                                <Typography variant="subtitle2" align="right">
                                {studentDetail?.enquiry_id?.email}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                            <ListItemIcon>
                                <PhonelinkRingTwoToneIcon sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="subtitle1">Phone</Typography>} />
                            <ListItemSecondaryAction>
                                <Typography variant="subtitle2" align="right">
                                {studentDetail?.enquiry_id?.mobile}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        <Divider />
                        <ListItemButton>
                            <ListItemIcon>
                                <DateRangeIcon sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="subtitle1">Joining Date</Typography>} />
                            <ListItemSecondaryAction>
                                <Typography variant="subtitle2" align="right">
                                {fDate(studentDetail?.joining_date)}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton>

                        <Divider />
                        {userDetails && (
                            <>
                        <ListItemButton>
                            <ListItemIcon>
                                <LoginIcon sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="subtitle1">Username</Typography>} />
                            <ListItemSecondaryAction>
                                <Typography variant="subtitle2" align="right">
                                    {userDetails.email || studentDetail?.enquiry_id?.mobile}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton>

                        <Divider />
                        <ListItemButton>
                            <ListItemIcon>
                                <VpnKeyIcon sx={{ fontSize: '1.3rem' }} />
                            </ListItemIcon>
                            <ListItemText primary={<Typography variant="subtitle1">Password</Typography>} />
                            <ListItemSecondaryAction>
                                <Typography variant="subtitle2" align="right">
                                    {userDetails.password}
                                </Typography>
                            </ListItemSecondaryAction>
                        </ListItemButton>
                        </>
                        )}
                    </List>
                   
                </SubCard>
            </Grid>
            <Grid item lg={8} xs={12}>
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <SubCard
                            title="Student Personal Details"
                            secondary={
                                <IconButton onClick={handleStudentDetailsToggle}>
                                    <ExpandMoreIcon />
                                </IconButton>
                            }
                        >
                             <Collapse in={studentDetailsExpanded}>
                           <StudentFormInfo studentDetail={studentDetail.enquiry_id} />
                           </Collapse>
                        </SubCard>
                    </Grid>
                   {/*} <Grid item xs={12}>
                        <SubCard title="Class and Package Details"
                            secondary= {
                                <IconButton onClick={handleClassDetailsToggle}>
                                    <ExpandMoreIcon />
                                </IconButton>
                            }>
                                 <Collapse in={classDetailsExpanded}>
                            <Grid container spacing={2}>
                                <Grid item xs={12}>
                                    <Grid container>
                                        <Grid item xs={12} sm={4}>
                                            <Typography variant="h4">Class Name</Typography>
                                            
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Typography variant="subtitle1"> {studentDetail?.class_id?.class_name}</Typography>
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                    <Grid item xs={12} sm={4}>
                                            <Typography variant="h4">Package </Typography>
                                            
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Typography variant="subtitle1">{studentDetail?.package_id?.package_name}</Typography>
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                    <Grid item xs={12} sm={4}>
                                            <Typography variant="h4">Total Days</Typography>
                                            
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Typography variant="subtitle1">{studentDetail?.package_id?.duration}</Typography>
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>

                                <Grid item xs={12} sx={{ display: { xs: 'block', sm: 'none' } }}>
                                    <Divider />
                                </Grid>
                                <Grid item xs={12}>
                                    <Grid container>
                                    <Grid item xs={12} sm={4}>
                                            <Typography variant="h4">Remaining Days</Typography>
                                            
                                        </Grid>
                                        <Grid item xs={12} sm={8}>
                                            <Typography variant="subtitle1">{studentDetail?.remaining_class}</Typography>
                                            
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                            </Collapse>
                        </SubCard>
                        </Grid>*/}
                    
                   
                </Grid>
            </Grid>
        </Grid>
    );
};

export default StudentProfile;