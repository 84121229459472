import { Box, Button, Drawer, IconButton, InputLabel, MenuItem, Select, styled, TextField, Typography } from '@mui/material';
import { ChevronLeft } from '@mui/icons-material';
//import PriceSlider from './PriceSlider';
//import { useValue } from '../../context/ContextProvider';

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  ...theme.mixins.toolbar,
}));

const SideBarFilter = ({ isOpen,
    setIsOpen,
    filterClassName,
    setFilterClassName,
    filterTrainer,
    setFilterTrainer,
    filterDeliveryMode,
    setFilterDeliveryMode,
    handleApplyFilter,
    handleResetFilter, }) => {
  //const { containerRef } = useValue();
  return (
    <Drawer variant="persistent" hideBackdrop={true} open={isOpen} anchor="right">
      <DrawerHeader>
      <Typography variant="h6">Filter Classes</Typography>
        <IconButton onClick={() => setIsOpen(false)}>
          <ChevronLeft fontSize="large" />
        </IconButton>
      </DrawerHeader>
      <Box sx={{ width: 240, p: 3 }}>
      <TextField
          label="Class Name"
          variant="outlined"
          fullWidth
          value={filterClassName}
          onChange={(e) => setFilterClassName(e.target.value)}
          margin="normal"
        />

        <TextField
          label="Trainer"
          variant="outlined"
          fullWidth
          value={filterTrainer}
          onChange={(e) => setFilterTrainer(e.target.value)}
          margin="normal"
        />
        <InputLabel>Delivery Mode</InputLabel>
        <Select
          label="Delivery Mode"
          variant="outlined"
          fullWidth
          value={filterDeliveryMode}
          onChange={(e) => setFilterDeliveryMode(e.target.value)}
          margin="normal"
        >
          <MenuItem value="online">Online</MenuItem>
          <MenuItem value="offline">Offline</MenuItem>
        </Select>

        <Box mt={2} display="flex" justifyContent="space-between">
          <Button variant="outlined" color="primary" onClick={handleResetFilter}>
            Reset
          </Button>
          <Button variant="contained" color="primary" onClick={handleApplyFilter}>
            Apply
          </Button>
        </Box>
        
      </Box>
    </Drawer>
  );
};

export default SideBarFilter;