import React from 'react';
import { DataGrid } from '@mui/x-data-grid';

const columns = [
  { field: 'id', headerName: 'ID', width: 100 },
  { field: 'testCategory', headerName: 'Test Category', width: 200 },
  { field: 'course', headerName: 'Course', width: 200 },
  { field: 'module', headerName: 'Module', width: 200 },
  { field: 'description', headerName: 'Description', width: 300 },
  {
    field: 'action',
    headerName: 'Action',
    width: 150,
    renderCell: (params) => (
      <button
        onClick={() => {
          // Add your action logic here
          alert(`Clicked action for row ${params.id}`);
        }}
      >
        View
      </button>
    ),
  },
];

const rows = [
  { id: 1, testCategory: 'IELTS', course: 'Academic IELTS', module: 'Reading', description: 'Description A' },
  { id: 2, testCategory: 'IELTS', course: 'Academic IELTS', module: 'Listening', description: 'Description B' },
  { id: 3, testCategory: 'IELTS', course: 'Academic IELTS', module: 'Reading', description: 'Description C' },
  // Add more rows as needed
];

function StudyMaterialPage() {
  return (
    
    <div style={{ height: 400, width: '100%' }}>
      <DataGrid
        rows={rows}
        columns={columns}
        pageSize={5} // You can adjust the number of rows per page
        checkboxSelection
      />
    </div>
  );
}

export default StudyMaterialPage;
