import React from 'react'
import {
    Document,
    Page,
    PDFViewer,
    StyleSheet,
    Text,
    View,
  } from "@react-pdf/renderer";

export default function AdvisorCreatedTask({taskcreateddata,styles}) {
  return (
   <>
   <Text
                  style={{
                    color: "green",
                    fontWeight: 600,
                    fontSize: "15px",
                    marginTop: "25px",
                  }}
                >
                  Created Task
                </Text>
    {taskcreateddata.length === 0 ? (
                  <Text
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: "bold",
                      fontSize: 12,
                      textAlign: "center",
                    }}
                  >
                   No Created Tasks
                  </Text>
                ) : (
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <Text style={styles.tableHeaderCell}>Task Name</Text>
                      <Text style={styles.tableHeaderCell}>Total Created</Text>
                    </View>
                    {taskcreateddata.map((task, index) => (
                      <View style={styles.tableRow} key={index}>
                        <Text style={styles.tableCell}>
                          {task.type_of_task}
                        </Text>
                        <Text style={styles.tableCell}>{task.totalTasks}</Text>
                      </View>
                    ))}
                  </View>
                )}
   </>
  )
}
