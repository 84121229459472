import React, { useEffect, useState } from "react";
import {
  Grid,
  TextField,
  InputLabel,
  Typography,
  Button,
  MenuItem,
  Autocomplete,
} from "@mui/material";
import { DatePicker } from "@mui/lab";
import dayjs from "dayjs";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;

function EmployeeLeaveRequestForm({ onClose, anotherEmployeeRequest }) {
  console.log("Entering EmployeeLeaveRequestForm", anotherEmployeeRequest);
  const userId = localStorage.getItem("userId");
  const [typeOfLeave, setTypeOfLeave] = useState("");
  const [typeOfLeaveDay, setTypeOfLeaveDay] = useState(null);
  const [leaveDateFrom, setLeaveDateFrom] = useState(null);
  const [leaveDateTo, setLeaveDateTo] = useState(null);
  const [leaveTimeFrom, setLeaveTimeFrom] = useState(null);
  const [leaveTimeTo, setLeaveTimeTo] = useState(null);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [additionalNote, setAdditionalNote] = useState(null);
  const [employee, setEmployee] = useState(null);
  const [errors, setErrors] = useState({});

  const [leaveTypes, setLeaveTypes] = useState([]);
  const [userList, setUserList] = useState([]);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleTypeOfLeaveChange = (event) => {
    setTypeOfLeave(event.target.value);
  };

  const handleTypeOfLeaveDayChange = (event) => {
    setTypeOfLeaveDay(event.target.value);
  };

  const handleLeaveDateFromChange = (event) => {
    const date = event.target.value;
    setLeaveDateFrom(date);
    calculateNumberOfDays(date, leaveDateTo);
  };

  const handleLeaveDateToChange = (event) => {
    const date = event.target.value;
    setLeaveDateTo(date);
    calculateNumberOfDays(leaveDateFrom, date);
  };

  const handleFromTime = (e) => {
    setLeaveTimeFrom(e.target.value);
  };

  const handleToTime = (e) => {
    setLeaveTimeTo(e.target.value);
  };
  const handleEmployeeChange = (event) => {
    setEmployee(event.target.value);
  };

  const handleAdditionalNoteChange = (event) => {
    setAdditionalNote(event.target.value);
  };

  const calculateNumberOfDays = (fromDate, toDate) => {
    if (fromDate && toDate) {
      let start = dayjs(fromDate);
      const end = dayjs(toDate);
      let days = 0;

      while (start <= end) {
        // If the day is not Sunday (0), count it
        if (start.day() !== 0) {
          days++;
        }
        start = start.add(1, "day");
      }

      setNumberOfDays(days);
    } else {
      setNumberOfDays(0);
    }
  };

  const handleSubmit = () => {
    const newErrors = {};

    if (!typeOfLeave) newErrors.typeOfLeave = true;
    if (!typeOfLeaveDay) newErrors.typeOfLeaveDay = true;
    if (!leaveDateFrom) newErrors.leaveDateFrom = true;
    if (!leaveDateTo) newErrors.leaveDateTo = true;
    if (anotherEmployeeRequest === true && !employee) newErrors.employee = true;
    if (typeOfLeaveDay !== "full" && !leaveTimeFrom)
      newErrors.leaveTimeFrom = true;
    if (typeOfLeaveDay !== "full" && !leaveTimeTo) newErrors.leaveTimeTo = true;

    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    // Handle form submission
    console.log({
      typeOfLeave,
      typeOfLeaveDay,
      leaveDateFrom,
      leaveDateTo,
      numberOfDays,
    });

    const formdata = {
      emp_user_id:
        anotherEmployeeRequest === true ? employee : parseInt(userId),
      from_date: leaveDateFrom,
      to_date: leaveDateTo,
      from_time: leaveTimeFrom,
      to_time: leaveTimeTo,
      leave_type_id: typeOfLeave,
      leave_day_type: typeOfLeaveDay,
      additional_note: additionalNote,
      user_id: parseInt(userId),
    };

    fetch(`${API_ENDPOINT}empleave`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formdata),
    })
      .then((response) => {
        if (response.ok) {
          showSnackbar("Leave request added successfully.");
          setTimeout(() => {
            window.location.reload();
          }, 2000);
        } else if (response.status === 400) {
          showSnackbar("Error", "error");
        } else {
          console.error("Error: Unknown Error");
          showSnackbar("Error: Unknown Error", "error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showSnackbar("Error: Unknown Error", "error");
      });
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}empleavetype`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setLeaveTypes(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    const fetchUsersData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUserList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    fetchUsersData();
  }, []);

  console.log(leaveTimeFrom);

  return (
    <>
      <Grid container spacing={2}>
        {anotherEmployeeRequest === true && (
          <Grid item xs={12} md={6} lg={6}>
            <InputLabel style={{ color: "black", position: "relative" }}>
              Employee Name
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1.5em",
                }}
              >
                *
              </span>
            </InputLabel>

            <TextField
              select
              size="small"
              value={employee}
              onChange={handleEmployeeChange}
              InputProps={{ style: { borderRadius: "12px" } }}
              fullWidth
            >
              {userList.map((item) => (
                <MenuItem value={item.id}>
                  {item.first_name} {item.last_name ? item.last_name : ""}
                </MenuItem>
              ))}
            </TextField>
            {errors.employee && (
              <Typography color="error" variant="caption">
                Employee is required
              </Typography>
            )}
          </Grid>
        )}
        <Grid item xs={12} md={6} lg={6} sm={6}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            Type of Leave
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.5em",
              }}
            >
              *
            </span>
          </InputLabel>
          <TextField
            select
            size="small"
            value={typeOfLeave}
            onChange={handleTypeOfLeaveChange}
            InputProps={{ style: { borderRadius: "12px" } }}
            fullWidth
          >
            {leaveTypes.map((item) => (
              <MenuItem value={item.id}>{item.leave_type}</MenuItem>
            ))}
          </TextField>
          {errors.typeOfLeave && (
            <Typography color="error" variant="caption">
              Type of leave is required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={6} sm={6}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            Leave Date From
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.5em",
              }}
            >
              *
            </span>
          </InputLabel>
          <TextField
            type="date"
            size="small"
            value={leaveDateFrom}
            onChange={handleLeaveDateFromChange}
            InputProps={{ style: { borderRadius: "12px" } }}
            fullWidth
          />
          {errors.leaveDateFrom && (
            <Typography color="error" variant="caption">
              Leave date from is required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={6} sm={6}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            Leave Date To
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.5em",
              }}
            >
              *
            </span>
          </InputLabel>
          <TextField
            type="date"
            size="small"
            value={leaveDateTo}
            onChange={handleLeaveDateToChange}
            InputProps={{ style: { borderRadius: "12px" } }}
            fullWidth
          />
          {errors.leaveDateTo && (
            <Typography color="error" variant="caption">
              Leave date to is required
            </Typography>
          )}
        </Grid>

        {numberOfDays <= 1 ? (
          <>
            <Grid item xs={12} md={6} lg={6} sm={6}>
              <InputLabel style={{ color: "black", position: "relative" }}>
                Type of Leave Day
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <TextField
                select
                size="small"
                value={typeOfLeaveDay}
                onChange={handleTypeOfLeaveDayChange}
                InputProps={{ style: { borderRadius: "12px" } }}
                fullWidth
              >
                <MenuItem value="full">Full Day</MenuItem>
                <MenuItem value="first_half">First Half Day</MenuItem>
                <MenuItem value="second_half">Second Half Day</MenuItem>
                <MenuItem value="short_morning">Short Leave - Morning</MenuItem>
                <MenuItem value="short_evening">Short Leave - Evening</MenuItem>
              </TextField>
              {errors.typeOfLeaveDay && (
                <Typography color="error" variant="caption">
                  Type of leave is required
                </Typography>
              )}
            </Grid>
          </>
        ) : (
          <>
            <Grid item xs={12} md={6} lg={6} sm={6}>
              <InputLabel style={{ color: "black", position: "relative" }}>
                Type of Leave Day
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <TextField
                select
                size="small"
                value={typeOfLeaveDay}
                onChange={handleTypeOfLeaveDayChange}
                InputProps={{ style: { borderRadius: "12px" } }}
                fullWidth
              >
                <MenuItem value="full">Full Day</MenuItem>
              </TextField>
              {errors.typeOfLeaveDay && (
                <Typography color="error" variant="caption">
                  Type of leave is required
                </Typography>
              )}
            </Grid>
          </>
        )}

        {typeOfLeaveDay !== "full" && typeOfLeaveDay !== null && (
          <>
            <Grid item xs={12} md={6} lg={6} sm={6}>
              <InputLabel style={{ color: "black", position: "relative" }}>
                From Time
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <TextField
                value={leaveTimeFrom}
                onChange={handleFromTime}
                fullWidth
                type="time"
                size="small"
                InputProps={{ style: { borderRadius: "12px" } }}
              />
              {errors.leaveTimeFrom && (
                <Typography color="error" variant="caption">
                  Leave Time from is required
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={6} lg={6} sm={6}>
              <InputLabel style={{ color: "black", position: "relative" }}>
                To Time
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <TextField
                value={leaveTimeTo}
                onChange={handleToTime}
                fullWidth
                type="time"
                size="small"
                InputProps={{ style: { borderRadius: "12px" } }}
              />
              {errors.leaveTimeTo && (
                <Typography color="error" variant="caption">
                  Leave Time To is required
                </Typography>
              )}
            </Grid>
          </>
        )}

        <Grid item xs={12} md={12} lg={12} sm={12}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            Additional Note
          </InputLabel>
          <TextField
            size="small"
            value={additionalNote}
            onChange={handleAdditionalNoteChange}
            InputProps={{ style: { borderRadius: "12px" } }}
            fullWidth
            multiline
            rows={4}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12} sm={12}>
          <Typography variant="h5" mt={2}>
            Number of Leave Days: {numberOfDays}
          </Typography>
        </Grid>

        <Grid item xs={12} md={12} lg={12} sm={12}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSubmit}
            style={{ marginTop: "20px" }}
          >
            Apply for Leave
          </Button>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default EmployeeLeaveRequestForm;
