//  Author : Ayesha Shaikh
//  Date : 23 Aug 2024

import {
  Autocomplete,
  Box,
  Grid,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Button from "@mui/material/Button";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function RegistrationForm({ showSnackbar, mobile }) {
  const userId = localStorage.getItem("userId");
  const [firstName, setFirstname] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [countryCodeList, setCountryCodeList] = useState([]);
  const [countryCode, setCountryCode] = useState("91");

  const [Email, setEmail] = useState(null);
  const [branchList, setBranchList] = useState([]);
  const [branch, setBranch] = useState(null);
  const [showotp, setshowotp] = useState(false);
  const [otp, setOtp] = useState(null);

  const [seconds, setSeconds] = useState(0);
  const [isPlaying, setisPlaying] = useState(false);
  const [showReset, setShowReset] = useState(false);
  const [errors, setErrors] = useState(false);
  const navigate = useNavigate();
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 4;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
      },
    },
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}country`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setCountryCodeList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setBranchList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleFirstNameChange = (e) => {
    setFirstname(e.target.value);
    setErrors({ ...errors, firstName: !e.target.value });
  };

  const handleLastNameChange = (e) => {
    setLastName(e.target.value);
    setErrors({ ...errors, lastName: !e.target.value });
  };

  const handleCountryCodeChange = (e) => {
    setCountryCode(e.target.value);
    setErrors({ ...errors, countryCode: !e.target.value });
  };

  const handleEmailChange = (e) => {
    setEmail(e.target.value);
    setErrors({ ...errors, Email: !e.target.value });
  };

  const handleBranchChange = (event, value) => {
    setBranch(value);
    setErrors({ ...errors, branch: !value });
  };

  const phoneRegex = /^[1-9][0-9]{9}$/;
  const emailRegex = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;

  // Generate OTP
  const handleGenrateOtp = async () => {
    if (!firstName || !Email || !countryCode || !mobile || !branch) {
      setErrors({
        firstName: !firstName,
        Email: !Email || !emailRegex.test(Email),

        mobile: !mobile || !phoneRegex.test(mobile),
        countryCode: !countryCode,
        branch: !branch,
      });

      return;
    }

    const payload = {
      mobile: mobile || null,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/generateotp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status == 200 || response.ok) {
        showSnackbar("OTP send  successfully");
        setshowotp(true);
        setSeconds(300);
        setisPlaying(true);
        setShowReset(false);
      } else if (response.status === 400) {
        showSnackbar("Enter Valid Mobile Number", "error");
      } else {
        showSnackbar("Error send OTP", "error");
      }
    } catch (error) {
      showSnackbar("Error send OTP", "error");
    }
  };

  const handleOtpChange = (e) => {
    setOtp(e.target.value);
    setErrors({ ...errors, otp: !e.target.value });
  };

  // register now
  const handleSubmitData = async () => {
    if (!firstName || !Email || !countryCode || !mobile || !branch || !otp) {
      setErrors({
        firstName: !firstName,
        Email: !Email || !emailRegex.test(Email),

        mobile: !mobile || !phoneRegex.test(mobile),
        countryCode: !countryCode,
        branch: !branch,
        otp: !otp,
      });

      return;
    }

    const payload = {
      user_id: parseInt(userId),
      first_name: firstName || null,
      last_name: lastName || null,
      mobile_country_code: countryCode || null,
      mobile: mobile || null,
      email: Email || null,
      branch_id: branch || null,
      otp: otp,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/registrationdesk`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.ok) {
        const data = await response.json();
        console.log("Response data:", data);
        showSnackbar("Enquiry created successfully");
        setTimeout(() => {
          window.location.reload();
        }, 2000);
      } else if (response.status === 401) {
        showSnackbar("Unknown User", "error");
      } else if (response.status === 402) {
        showSnackbar("Please enter mobile number", "error");
      } else if (response.status === 409) {
        showSnackbar("Enquiry already exist ", "error");
      } else if (response.status === 406) {
        showSnackbar("Invalid OTP ", "error");
      } else if (response.status === 410) {
        showSnackbar("OTP Expired ", "error");
      } else {
        showSnackbar("Error Posting Data", "error");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      showSnackbar("Error Posting Data", "error");
    }
  };

  // Countdown Timer
  console.log("isPlaying", isPlaying);

  useEffect(() => {
    if (!isPlaying && seconds <= 0) {
      return;
    }

    const timer = setInterval(() => {
      setSeconds((prevSeconds) => {
        if (prevSeconds <= 1) {
          // Stop the timer when seconds reach 0
          setisPlaying(false);
          setShowReset(true);
          return 0;
        }
        return prevSeconds - 1;
      });
    }, 1000);

    // Clean up the timer
    return () => clearInterval(timer);
  }, [isPlaying, seconds]);

  const formatTime = (timeInSeconds) => {
    const minutes = Math.floor(timeInSeconds / 60)
      .toString()
      .padStart(2, 0);
    const seconds = (timeInSeconds % 60).toString().padStart(2, 0);
    return `${minutes}:${seconds}`;
  };

  console.log("seconds", seconds, isPlaying);
  return (
    <>
      <Grid container spacing={2} textAlign="center">
        <Grid item xs={12}>
          {" "}
          <img src="/Siec Logo.png" />
        </Grid>

        <Grid item xs={12}>
          <Typography textAlign="left" sx={{ fontWeight: 600 }}>
            First Name{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <TextField
            value={firstName}
            onChange={handleFirstNameChange}
            fullWidth
            size="small"
            InputProps={{ style: { borderRadius: "15px" } }}
            error={errors.firstName}
            helperText={errors.firstName && "First Name is required"}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography textAlign="left" sx={{ fontWeight: 600 }}>
            Last Name
          </Typography>
          <TextField
            value={lastName}
            onChange={handleLastNameChange}
            fullWidth
            size="small"
            InputProps={{ style: { borderRadius: "15px" } }}
          />
        </Grid>

        <Grid item xs={12}>
          <Typography textAlign="left" sx={{ fontWeight: 600 }}>
            Mobile No
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <Grid container>
            <Grid item xs={1.6}>
              <Select
                fullWidth
                value={countryCode}
                onChange={handleCountryCodeChange}
                style={{ borderRadius: "15px" }}
                size="small"
                MenuProps={MenuProps}
              >
                {countryCodeList.map((code) => (
                  <MenuItem value={code.phone_code}>
                    +{code.phone_code}
                  </MenuItem>
                ))}
              </Select>
            </Grid>

            <Grid item xs={10.4}>
              <TextField
                value={mobile}
                fullWidth
                size="small"
                type="number"
                InputProps={{ style: { borderRadius: "15px" } }}
                error={errors.mobile}
                helperText={errors.mobile && "Valid Mobile is required"}
                slotProps={{
                  input: {
                    readOnly: true,
                  },
                }}
              />
            </Grid>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Typography textAlign="left" sx={{ fontWeight: 600 }}>
            Email
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <TextField
            value={Email}
            onChange={handleEmailChange}
            fullWidth
            size="small"
            InputProps={{ style: { borderRadius: "15px" } }}
            error={errors.Email}
            helperText={errors.Email && "Valid Email is required"}
          />
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12} textAlign="left">
          <Typography textAlign="left" sx={{ fontWeight: 600 }}>
            Nearest Branch{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>

          <Autocomplete
            size="small"
            value={branch}
            disablePortal
            aria-required
            onChange={handleBranchChange}
            options={branchList}
            getOptionLabel={(option) => option.branch_name}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { borderRadius: "15px" }, // Add borderRadius here
                }}
                error={errors.branch}
                helperText={errors.branch && "Branch is required"}
              />
            )}
          />
        </Grid>

        {showotp && (
          <Grid item xs={12}>
            <Typography textAlign="left" sx={{ fontWeight: 600 }}>
              OTP
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1em",
                }}
              >
                *
              </span>
            </Typography>
            <TextField
              type="number"
              value={otp}
              onChange={handleOtpChange}
              fullWidth
              size="small"
              InputProps={{ style: { borderRadius: "15px" } }}
              error={errors.otp}
              helperText={errors.otp && "OTP is required"}
            />
          </Grid>
        )}

        <Grid item xs={12}>
          <Button
            variant="contained"
            style={{ fontSize: "14px" }}
            onClick={handleGenrateOtp}
            color="success"
            disabled={showotp}
          >
            Generate OTP
          </Button>

          <Button
            variant="contained"
            color="secondary"
            style={{ fontSize: "14px", marginLeft: "30px" }}
            disabled={otp === null || otp === ""}
            onClick={handleSubmitData}
          >
            Register Now
          </Button>
        </Grid>

        {/* Coundown Timer */}
        {seconds > 0 && (
          <Grid item xs={12}>
            <Box
              sx={{
                background: "#d6ecf3",
                padding: "10px",
                display: "flex",
                alignItems: "center",
              }}
            >
              {" "}
              <InfoOutlinedIcon style={{ color: "#1c96c5" }} />
              <Typography ml={1}>
                Your OTP has been sent. Please enter it within the next{" "}
                <span style={{ color: "red", fontWeight: 600 }}>
                  {formatTime(seconds)}
                </span>{" "}
                minutes.
              </Typography>
            </Box>
          </Grid>
        )}

        {showReset && (
          <Grid item xs={12}>
            <Typography sx={{ fontSize: "18px" }}>
              Didn't receive OTP?{" "}
              <span
                style={{
                  color: "blue",
                  marginLeft: "5px",
                  fontWeight: 500,
                  cursor: "pointer",
                }}
                onClick={handleGenrateOtp}
              >
                Resend OTP
              </span>
            </Typography>

         
          </Grid>
        )}
      </Grid>
    </>
  );
}
