import React, { useEffect, useState } from "react";
import { DataGrid } from "@mui/x-data-grid";
import { fDate } from "../../../utils/formatTime";
import {
  Box,
  Chip,
  Button,
  CircularProgress,
  Grid,
  Stack,
  Backdrop,
  Typography,
  TextField,
  Checkbox,
} from "@mui/material";
import { Person as PersonIcon } from "@mui/icons-material";
//import EnquiriesWidget from './EnquiriesWidget';
import { AddCircle } from "@mui/icons-material";
import "../../advisor/leadpages/enquiryDataGrid.css";
import { Link, useNavigate } from "react-router-dom";
import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import EnquaryFilter from "../../../newTestPrep/components/Filter/EnquaryFilter";
import { CSVExport } from "../../../newTestPrep/components/ui-component/table/CSVExport";
import ViewTasks from "../../../newTestPrep/pages/enquiry/tasks/ViewTasks";
import CustomPopup from "../../../components/CustomPopup";
import NoEnquiry from "./NoEnquiry";
import EnquiryTransfer from "./EnquiryTransfer";
import MaskTaskAssign from "../../../newTestPrep/pages/Events/MaskTaskAssign";
import NoenquiryForm from "../../../newTestPrep/pages/Events/NoenquiryForm";
import EmailIcon from "@mui/icons-material/Email";
import BulkEmailPopup from "../../../newTestPrep/pages/Events/BulkEmail/BulkEmailPopup";
import BulkMailform from "../../../newTestPrep/pages/Events/BulkEmail/BulkMailform";
import NoeMailForm from "../../../newTestPrep/pages/Events/NoMailForm";
import EnquiryMaskTaskAssign from "./EnquiryMassTaskAssign";
import { useGetTokenOrLogout } from "../../../utils/token";
import MainCard from "../../../newTestPrep/components/ui-component/card/MainCard";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const ViewEnquiriesDataGrid = () => {
  //const { advisorId } = useSelector((state) => state.user)
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const [originalRows, setOriginalRows] = useState([]);
  const [rows, setRows] = useState([]);
  const [exportData, setExportData] = useState([]);

  const [isDownloadAccess, setIsDownloadAccess] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  //const [filteredEnquiries, setFilteredEnquiries] = useState(enquiriesData);
  const [selectedIds, setSelectedIds] = useState([]);
  const [openMask, setOpenMask] = useState(false);
  const [openAssignTask, setOpenAssignTask] = useState(false);
  const [OpenEmailPopup, setOpenEmailPopup] = useState(false);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [originaEnquiryData, setOriginalEnquirydata] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 100,
  });

  const [visibleRowIds, setVisibleRowIds] = useState([]); // Store current page's row IDs
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [prevPageSize, setPrevPageSize] = useState(pagination.pageSize);
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleClosemask = () => {
    setOpenMask(false);
  };

  const handleOpenmask = () => {
    setOpenMask(true);
  };

  const handleOpenEmail = () => {
    setOpenEmailPopup(true);
  };

  const handleCloseEmail = () => {
    setOpenEmailPopup(false);
  };
  const handleCloseTask = () => {
    setOpenAssignTask(false);
  };

  const handleOpenTask = () => {
    setOpenAssignTask(true);
  };

  // Upadate current page ids when pagination is chnages
  useEffect(() => {
    console.log("pagination", pagination, selectedIds);
    setPrevPageSize(pagination.pageSize);

    // new pagesize is less than prev page size then deselct all selected ids first
    if (pagination.pageSize !== prevPageSize) {
      setSelectedIds([]);
      return;
    }

    // Update visible row IDs when pagination changes
    const currentPageRows = rows.slice(
      pagination.page * pagination.pageSize,
      (pagination.page + 1) * pagination.pageSize
    );
    const visibleIds = currentPageRows.map((row) => row.id);
    setVisibleRowIds(visibleIds);

    // If there are no visible rows, set SelectAllChecked to false
    if (visibleIds.length === 0) {
      setIsSelectAllChecked(false);
      setIsIndeterminate(false);
      return;
    }

    // Check if all visible rows are selected
    const allVisibleSelected = visibleIds.every((id) =>
      selectedIds.includes(id)
    );
    const someVisibleSelected = visibleIds.some((id) =>
      selectedIds.includes(id)
    );

    setIsSelectAllChecked(allVisibleSelected);
    setIsIndeterminate(someVisibleSelected && !allVisibleSelected);
  }, [pagination, rows, selectedIds]);

  // HandleSelectAllCheckBox
  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setIsSelectAllChecked(checked);

    if (checked) {
      // Select all visible row IDs
      setSelectedIds((prevSelectedIds) => [
        ...new Set([...prevSelectedIds, ...visibleRowIds]),
      ]);
    } else {
      // Deselect all visible row IDs
      setSelectedIds((prevSelectedIds) =>
        prevSelectedIds.filter((id) => !visibleRowIds.includes(id))
      );
    }
  };

  // Individual CheckBox
  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;

    setSelectedIds((prevSelectedIds) => {
      if (checked) {
        const newSelected = [...prevSelectedIds, id];
        // Check if all visible rows are selected after this checkbox is checked
        const allVisibleSelected = visibleRowIds.every((rowId) =>
          newSelected.includes(rowId)
        );
        setIsSelectAllChecked(allVisibleSelected);
        setIsIndeterminate(!allVisibleSelected);

        return newSelected;
      } else {
        // Deselect the row ID
        const newSelected = prevSelectedIds.filter((rowId) => rowId !== id);
        setIsSelectAllChecked(false);
        setIsIndeterminate(
          visibleRowIds.some((rowId) => newSelected.includes(rowId))
        );

        return newSelected;
      }
    });
  };

  const handleChipClick = (status) => {
    if (status === "All") {
      setRows(originalRows);
    } else {
      const filtered = originalRows.filter(
        (enquiry) => enquiry.status === status
      );
      setRows(filtered);
    }
  };

  /*const handleViewMore = (row) => {
    // Handle the "View More" action here
    // console.log('View More:', row);

    navigate(`/enquirydetails/${row.id}`, {
      state: { enquiryDetails: row }
    });
  };*/

  const handleViewMore = (row) => {
    // Handle the "View More" action here
    // console.log('View More:', row);
    console.log("rowdata", row?.enq_personal_detail_id);

    const url = `/enquiry-summary/${row?.enq_personal_detail_id}`;
    const newState = { enquiryDetails: row };
    console.log("rowdata", newState);
    // Open link in new tab
    const newTab = window.open(url, "_blank");

    // If new tab opened successfully, navigate with state data
    if (newTab) {
      newTab.onload = () => {
        newTab.history.replaceState(newState, "", url);
      };
    } else {
      // If new tab was blocked by browser, navigate with state data in current tab
      navigate(url, { state: newState });
    }
  };

  const handleAddNewEnquiry = () => {
    navigate(`/addenquirynew`);
  };

  const handleAddBulkEnquiry = () => {
    navigate(`/addbulkenquiries`);
  };

  // console.log(rows)

  console.log(originalRows);

  // const totalRows = rows.length; // Total number of rows in the table

  // Check if the select all checkbox should be indeterminate (some rows are selected but not all)

  const columns = [
    {
      field: "selection",
      sortable: false,
      renderHeader: (params) => (
        <Checkbox
          size="small"
          checked={isSelectAllChecked}
          indeterminate={isIndeterminate}
          onChange={handleSelectAllChange}
        />
      ),
      headerClassName: "header-cell",
      width: 70,
      renderCell: (params) => {
        const isChecked = selectedIds.includes(params.row.id);
        return (
          <Checkbox
            size="small"
            checked={isChecked}
            onChange={(event) => handleCheckboxChange(event, params.row?.id)}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Enquiry Name",
      width: 200,
      valueFormatter: (params) =>
        `${params.value?.first_name} ${params.value?.last_name}` || "",
      renderCell: (params) => {
        // console.log('Cell Renderer - params.row:', params.row);
        return (
          <div>
            <Button size="small" onClick={() => handleViewMore(params.row)}>
              <PersonIcon /> {params.row.first_name} {params.row.last_name}
            </Button>
          </div>
        );
      },
    },

    {
      field: "mobile",
      headerName: "Mobile",
      width: 150,
      headerClassName: "bold-header",
      renderCell: (params) => (
        <div>
          {params.row?.mobile_country_code}

          {userRole === "advisor" || userRole === "trainer"
            ? params.row?.mobile.replace(/.(?=.{4,}$)/g, "*")
            : params.row?.mobile}
        </div>
      ),
    },

    {
      field: "email",
      headerName: "email",
      width: 250,
      headerClassName: "bold-header",
      renderCell: (params) => (
        <div>
          {userRole === "advisor" || userRole === "trainer"
            ? "**********"
            : params.row?.email}
        </div>
      ),
    },
    {
      field: "connected_call",
      headerName: "Cont.",
      width: 50,
      headerClassName: "bold-header",
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "not_connected_call",
      headerName: "N-Cont",
      width: 70,
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "total_open_task",
      headerName: "O.T",
      width: 50,
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "enquiry_date",
      headerName: "Enq Date",
      width: 100,
      renderCell: (params) => <div>{fDate(params.value)}</div>,
    },
    {
      field: "last_follow_up_date",
      headerName: "Last FollowUp ",
      width: 100,
      renderCell: (params) => <div>{fDate(params.value)}</div>,
    },
    {
      field: "next_follow_up_date",
      headerName: "Next FollowUp ",
      width: 100,
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "prospective_level_id",
      headerName: "Prospective Level",
      width: 100,
      valueFormatter: (params) => params.value?.prospective_level || "",
    },
    {
      field: "recyclereson",
      headerName: "Recycle Reason",
      width: 200,
      renderCell: (params) => (
        <div>{params.row?.recycle_reason_id?.recycle_reason}</div>
      ),
    },
    {
      field: "nearest_branch_id",
      headerName: "Nearest Branch",
      width: 100,
      renderCell: (params) => (
        <div>{params.row.nearest_branch_id?.branch_name}</div>
      ),
    },
    {
      field: "sub_service_id",
      headerName: "Sub Service",
      width: 150,
      valueFormatter: (params) => params.value?.name || "",
    },

    {
      field: "campaign",
      headerName: "Campaign",
      width: 350,
      renderCell: (params) => (
        <div>{params.row?.campaign_id?.campaign_name}</div>
      ),
    },

    {
      field: "assign_to",
      headerName: "P.Advisor",
      width: 100,
      renderCell: (params) => <div>{params.row.assign_to?.first_name}</div>,
    },
    {
      field: "co_assign_to",
      headerName: "Co-Assignee",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.row.co_assignee?.first_name}{" "}
          {params.row.co_assignee?.last_name}
        </div>
      ),
    },
    {
      field: "lead_source_type_id",
      headerName: "LS",
      width: 100,
      valueFormatter: (params) => params.value?.name || "",
    },
    {
      field: "sub_lead_source_type_id",
      headerName: "SLS",
      width: 100,
      valueFormatter: (params) => params.value?.source_type || "",
    },

    {
      field: "stage_id",
      headerName: "Stage",
      width: 100,
      valueFormatter: (params) => params.value?.stage_name || "",
    },
    {
      field: "enrolled_date",
      headerName: "Enrolled date",
      width: 100,
      renderCell: (params) => (
        <div>{params.row?.enrolled_detail?.enrollment_date} </div>
      ),
    },

    {
      field: "enrolled_by",
      headerName: "Enrolled By",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.row.enrolled_detail?.created_by?.first_name}{" "}
          {params.row.enrolled_detail?.created_by?.last_name}
        </div>
      ),
    },
  ];
  const boldHeaderCellStyle = {
    fontWeight: "bold", // Apply bold font weight to header cells
  };

  useEffect(() => {
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}admindashboard/allfreshenquiries/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();
          if (data.length === 0) {
            showSnackbar("No data Available",'error');
            setIsLoading(false);
          } else {
            const sortedData = data.sort(
              (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
            );

            setOriginalEnquirydata(sortedData);
            setOriginalRows(sortedData);
            setRows(sortedData);
            setIsLoading(false);
          }
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId]);

  useEffect(() => {
    fetch(`${API_ENDPOINT}route/downloadaccess/${userId}`)
      .then((response) => {
        if (response.ok) {
          setIsDownloadAccess(true);
        } else {
          setIsDownloadAccess(false);
        }
      })
      .catch((err) => {
        setIsDownloadAccess(false);
      });
  }, [userId]);

  useEffect(() => {
    const exportData = rows.map((row) => ({
      name: row.last_name
        ? `${row.first_name} ${row.last_name}`
        : row.first_name,
      mobile: row.mobile,
      contactable: row.connected_call || "",
      non_contactable: row.not_connected_call || "",
      open_tasks: row.total_open_task || "",
      nearest_branch: row.nearest_branch_id?.branch_name || "",
      email: row.email,
      enquiry_date: fDate(row.enquiry_date),
      prospective_level: row.prospective_level_id?.prospective_level || "",
      sub_service: row.sub_service_id?.name || "",
      assign: row.assign_to?.first_name || "",
      campaign_id: row.campaign_id?.name || "",
      lead_source_type: row.lead_source_type_id?.name || "",
      stage: row.stage_id?.stage_name || "",
    }));
    setExportData(exportData);
  }, [rows]);

  // Filter By Name
  const handlenamefilter = (e) => {
    const value = e.target.value;
    let filteredData1 = originalRows.filter((data) =>
      `${data?.first_name} ${data?.last_name}`
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(value.toLowerCase().replace(/\s+/g, ""))
    );

    setRows(filteredData1);
  };

  return (
    <div style={{ width: "100%", height: "100%" }}>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Fetching
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
      
      <Box display="flex">
        <Button variant="contained" onClick={handleAddNewEnquiry}>
          Add New Enquiry
        </Button>
      </Box>
      <Stack direction="row" justifyContent="space-between">
        <Box mt={2} marginBottom={2}>
          {/* <EnquiriesWidget enquiriesData={rows} /> */}
          <Chip
            label={`Total Enquiries: ${originalRows.length}`}
            onClick={() => handleChipClick("All")}
          />
          <Chip
            label={`New Leads: ${
              rows.filter((enquiry) => enquiry.status === "new lead").length
            }`}
            color="info"
            onClick={() => handleChipClick("new lead")}
          />
          <Chip
            label={`In Progress: ${
              rows.filter((enquiry) => enquiry.status === "in progress").length
            }`}
            color="secondary"
            onClick={() => handleChipClick("in progress")}
          />
          <Chip
            label={`Enrolled: ${
              rows.filter((enquiry) => enquiry.status === "Enrolled").length
            }`}
            color="primary"
            onClick={() => handleChipClick("Enrolled")}
          />
          <Chip
            label={`Not Interested: ${
              rows.filter((enquiry) => enquiry.status === "Not Interested")
                .length
            }`}
            color="error"
            onClick={() => handleChipClick("Not Interested")}
          />
        </Box>

        <Box display="flex">
          <Box>
            {(userRole === "center_admin" || userRole === "data_entry") && (
              <Button variant="contained" onClick={() => handleOpenmask()}>
                Mass Enquiry Transfer
              </Button>
            )}
            <Button
              variant="contained"
              style={{ marginLeft: "10px" }}
              startIcon={<EmailIcon />}
              color="secondary"
              onClick={handleOpenEmail}
            >
              Send Email{" "}
            </Button>
          </Box>

          {userRole === "data_entry" && (
            <Box sx={{ marginLeft: "10px" }}>
              <Button
                color="secondary"
                variant="contained"
                onClick={() => handleOpenTask()}
              >
                Mass Task Assign
              </Button>
            </Box>
          )}

          <Box sx={{ marginLeft: "10px" }}>
            <ViewTasks />
          </Box>

          <Box sx={{ marginLeft: "10px" }}>
            <EnquaryFilter
              setOriginalRows={setOriginalRows}
              setRows={setRows}
              originalRows={originalRows}
              setIsLoading={setIsLoading}
              originaEnquiryData={originaEnquiryData}
            />
          </Box>

          {isDownloadAccess && (
            <Box sx={{ marginLeft: "10px" }}>
              <CSVExport data={exportData} filename="enquiries.csv" />
            </Box>
          )}
        </Box>
      </Stack>
      

      <Box m={2}/>

      {/* Filter inputs */}
      <MainCard>
      <Grid container>
        <Grid item xs={6}>
          <Button
            style={{ borderRadius: "25px" }}
            variant="contained"
            size="small"
          >
            {" "}
            Selected Items : {selectedIds.length}{" "}
          </Button>
        </Grid>

        <Grid
          item
          xs={6}
          container
          direction="row"
          alignItems="center"
          justifyContent="end"
        >
          <Typography sx={{ fontWeight: 600 }}>Search:</Typography>
          <TextField
            size="small"
            onChange={handlenamefilter}
            InputProps={{ style: { borderRadius: "14px", width: "250px" } }}
          />
        </Grid>
      </Grid>
      <DataGrid
        rows={rows}
        columns={columns}
        autoHeight
        // pageSize={5}
        defaultDensity="compact"
        // initialState={{
        //   pagination: {
        //     paginationModel: {
        //       pageSize: 100,
        //     },
        //   },
        // }}

        pageSize={pagination.pageSize}
        onPaginationModelChange={(model) => setPagination(model)}
        sx={{ marginTop: "20px" }}
        pageSizeOptions={[10, 25, 50, 100]}
        components={{
          NoRowsOverlay: () => (
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                height: "100%",
              }}
            >
              <p>No Enquiries available</p>
            </div>
          ),
        }}
      />


      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      {selectedIds.length === 0 ? (
        <CustomPopup
          open={openMask}
          onClose={handleClosemask}
          component={<NoEnquiry onClose={handleClosemask} />}
          maxWidth="xs"
        />
      ) : (
        <CustomPopup
          open={openMask}
          onClose={handleClosemask}
          component={
            <EnquiryTransfer
              onClose={handleClosemask}
              selectedId={selectedIds}
              setRows={setRows}
              rows={rows}
              setOriginalRows={setOriginalRows}
              setSelectedIds={setSelectedIds}
              setIsSelectAllChecked={setIsSelectAllChecked}
            />
          }
          title="Enquiry Transfer"
          maxWidth="sm"
          showDivider={true}
        />
      )}

      {selectedIds.length === 0 ? (
        <CustomPopup
          open={openAssignTask}
          onClose={handleCloseTask}
          component={<NoenquiryForm onClose={handleCloseTask} />}
          maxWidth="xs"
        />
      ) : (
        <CustomPopup
          open={openAssignTask}
          onClose={handleCloseTask}
          component={
            <EnquiryMaskTaskAssign
              onClose={handleCloseTask}
              selectedId={selectedIds}
              setSelectedIds={setSelectedIds}
              setRows={setRows}
              rows={rows}
              setOriginalRows={setOriginalRows}
            />
          }
          title="Task Assign"
          maxWidth="md"
          showDivider={true}
        />
      )}

      {selectedIds.length === 0 ? (
        <CustomPopup
          open={OpenEmailPopup}
          onClose={handleCloseEmail}
          component={<NoeMailForm onClose={handleCloseEmail} />}
          maxWidth="xs"
        />
      ) : (
        <BulkEmailPopup
          open={OpenEmailPopup}
          handelclose={handleCloseEmail}
          component={
            <BulkMailform
              handelclose={handleCloseEmail}
              selectedId={selectedIds}
            />
          }
          title="Send Bulk Emails"
          maxWidth="md"
          showDivider={true}
        />
      )}
       </MainCard>
    </div>
  );
};

export default ViewEnquiriesDataGrid;
