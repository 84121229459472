import { Button, Grid, TextField, Typography } from "@mui/material";
import JoditEditor from "jodit-react";
import React, { useEffect, useRef, useState } from "react";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";

export default function EditTemplateForm({ onClose, selecteddata }) {
  console.log(selecteddata, "selecteddata");
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const [name, setName] = useState(null);
  const [subject, setSubject] = useState(null);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const [errors, setErrors] = useState({
    name: false,
    subject: false,
    content: false,
  });
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const config = {
    readonly: false,
    toolbarAdaptive: false,
    buttons: [
      "undo",
      "redo",
      "font",
      "fontsize",
      "bold",
      "italic",
      "underline",
      "eraser",
      "brush",
      "link",
      "ul",
      "ol",
      "indent",
      "outdent",
      "left",
      "fullsize",
      "source",
    ],
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };

  const handleNamechange = (e) => {
    setName(e.target.value);
    setErrors({ ...errors, name: !e.target.value });
  };
  const handleSubjectChange = (e) => {
    setSubject(e.target.value);
    setErrors({ ...errors, subject: !e.target.value });
  };
  const handleContentChange = (newContent) => {
    setContent(newContent);
    setErrors({ ...errors, content: !newContent });
  };

  useEffect(() => {
    if (selecteddata && selecteddata?.template_title) {
      setName(selecteddata?.template_title); // Set initial test type from selected data
    }
    if (selecteddata && selecteddata?.email_subject) {
      setSubject(selecteddata?.email_subject); // Set initial test type from selected data
    }
    if (selecteddata && selecteddata?.description) {
      setContent(selecteddata?.description); // Set initial test type from selected data
    }
  }, [selecteddata]);

  const handleSubmit = () => {
    if (!name || !subject || !content) {
      setErrors({
        name: !name,
        subject: !subject,
        content: !content,
      });
      showSnackbar("Please fill out all fields", "error");
      return;
    }
    const formdata = {
      user_id: parseInt(userId),

      template_title: name || null,
      email_subject: subject || null,
      description: content || null,
    };

    fetch(`${API_ENDPOINT}emailtemplate/${selecteddata.id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formdata),
    })
      .then((response) => {
        if (response.ok) {
          showSnackbar("Template Updated Successfully.");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          console.error("Error: Unknown Error");
          showSnackbar("Error: Unknown Error", "error");
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        showSnackbar("Error: Unknown Error", "error");
      });
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} lg={12} md={12}>
          <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
            {" "}
            Template Name <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            fullWidth
            value={name}
            onChange={handleNamechange}
            InputProps={{
              style: { borderRadius: "15px" },
            }}
          />
          {errors.name && (
            <Typography color="error" variant="caption">
              Template name is required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} sm={12} lg={12} md={12}>
          <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
            {" "}
            Template Subject <span style={{ color: "red" }}>*</span>
          </Typography>
          <TextField
            fullWidth
            value={subject}
            onChange={handleSubjectChange}
            InputProps={{
              style: { borderRadius: "15px" },
            }}
          />
          {errors.subject && (
            <Typography color="error" variant="caption">
              Template subject is required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} lg={12} sm={12} md={12}>
          <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
            {" "}
            Template Body <span style={{ color: "red" }}>*</span>
          </Typography>
          <JoditEditor
            fullWidth
            ref={editor}
            value={content}
            config={config}
            tabIndex={1} // tabIndex of textarea
            onBlur={handleContentChange} // preferred to use only this option to update the content for performance reasons
            onChange={(newContent) => {}}
          />
          {errors.content && (
            <Typography color="error" variant="caption">
              Template Body is required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} lg={12} sm={12} md={12} textAlign="end">
          <Button
            variant="contained"
            color="secondary"
            style={{ borderRadius: "25px", fontSize: "15px", fontWeight: 600 }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
