import React, { useEffect, useState } from "react";
import MainCard from "../../components/ui-component/card/MainCard";
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Backdrop,
  Box,
  CardContent,
  Checkbox,
  CircularProgress,
  Collapse,
  Fab,
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";
import { fDate } from "../../../utils/formatTime";
import Chip from "../../components/ui-component/chip/Chip";
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterListTwoTone";
import PrintIcon from "@mui/icons-material/PrintTwoTone";
import FileCopyIcon from "@mui/icons-material/FileCopyTwoTone";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/AddTwoTone";
import EditIcon from "@mui/icons-material/Edit";
import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined";
import PackageForm from "./PackageEditForm";
import PackagesFilter from "./PackagesFilter";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function PackagesList() {
  const getTokenOrLogout = useGetTokenOrLogout(); 
  const userRole = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const theme = useTheme();
  const [packages, setPackages] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  const [open, setOpen] = useState(false);
  const handleToggle = () => {
    setOpen(!open);
  };

  const handleApplyFilter = (criteria) => {
    setFilterCriteria(criteria);
  };
  const handleResetFilter = () => {
    setFilterCriteria(null);
    setPackages(originalData);
  };

  const [isFormOpen, setFormOpen] = useState(false);
  const [selectedPackage, setSelectedPackage] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      const token = getTokenOrLogout();
      if (!token) return; 

      let headers = { Authorization: `Bearer ${token}` };
      try {
        setIsLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}route2.0/getpackages/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.updatedAt) - new Date(a.updatedAt)
          );

          let filteredData = [...sortedData];
          console.log(filteredData);
          if (filterCriteria) {
            filteredData = filteredData.filter((packageData) => {
              let passesFilter = true;

              if (filterCriteria && filterCriteria.packageName) {
                const Name = packageData?.package_name
                  ? packageData.package_name.toLowerCase()
                  : ""; // Check if name is null or undefined

                const filterString = filterCriteria.packageName.toLowerCase();

                passesFilter = passesFilter && Name.includes(filterString);
              }

              if (filterCriteria && filterCriteria.duration) {
                const filterString =
                  parseInt(filterCriteria.duration) === packageData?.duration;
                console.log(filterString);
                passesFilter = passesFilter && filterString;
              }

              if (filterCriteria.newTestType.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newTestType.includes(
                    packageData.test_type?.id
                  );
              }

              if (filterCriteria.newBranch.length > 0) {
                console.log("newBranch", packageData?.branch_id);
                passesFilter =
                  passesFilter &&
                  packageData?.branch_id?.some((branchId) =>
                    filterCriteria.newBranch.includes(branchId)
                  );
              }

              if (filterCriteria.newStatus.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newStatus.includes(packageData?.status);
              }

              return passesFilter;
            });
          }
          console.log(data);
          setPackages(filteredData);
          setOriginalData(data);
          setIsLoading(false);
        } else if (response.status === 401) {
          setIsLoading(false);
          navigate("/page401");
        } else if (response.status === 403) {
          setIsLoading(false);
          navigate("/page403");
        } else if (response.status === 440) {
          setIsLoading(false);
          navigate("/page440");
        } else {
          setIsLoading(false);
          navigate("/page500");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId, filterCriteria]);

  const handleOpenForm = (packageData) => {
    setSelectedPackage(packageData);
    setFormOpen(true);
  };

  const handleCloseForm = () => {
    setSelectedPackage(null);
    setFormOpen(false);
  };

  const handleFormSubmit = () => {
    // Implement logic to handle form submission
    // You may want to update the data or make an API call
    handleCloseForm();
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  console.log(filterCriteria);

  return (
    <>
      <MainCard title="Packages List" content={false}>
        <CardContent>
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={2}
          >
            <Grid item xs={12} sm={6}></Grid>
            <Grid item xs={12} sm={6} sx={{ textAlign: "right" }}>
              <Tooltip title="Filter">
                <IconButton size="large" onClick={handleToggle}>
                  <FilterListIcon />
                </IconButton>
              </Tooltip>

              {/* product add & dialog */}
              {(userRole === "center_admin" || userRole === "developer") && (
                <Tooltip title="Add Product">
                  <Fab
                    color="primary"
                    size="small"
                    sx={{
                      boxShadow: "none",
                      ml: 1,
                      width: 32,
                      height: 32,
                      minHeight: 32,
                    }}
                  >
                    <AddIcon
                      fontSize="small"
                      onClick={() => handleOpenForm(null)}
                    />
                  </Fab>
                </Tooltip>
              )}
            </Grid>
          </Grid>
          <PackagesFilter
            open={open}
            onApplyFilter={handleApplyFilter}
            onResetFilter={handleResetFilter}
          />
          <PackageForm
            open={isFormOpen}
            handleClose={handleCloseForm}
            handleSubmit={handleFormSubmit}
            formData={selectedPackage}
          />
        </CardContent>

        {/* table */}
        <TableContainer>
          <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
            <TableHead>
              <TableRow>
                <TableCell sx={{ pl: 3 }} />
                <TableCell>Package Name</TableCell>
                <TableCell align="center">Duration (In session)</TableCell>
                <TableCell align="center">Test Type</TableCell>
                <TableCell align="center">Branch </TableCell>
                <TableCell align="center">Original Price</TableCell>
                <TableCell align="center">Discounted Price</TableCell>
                <TableCell align="center">Payable Installments</TableCell>
                <TableCell align="center">Status</TableCell>
                <TableCell align="center">Actions</TableCell>
              </TableRow>
            </TableHead>

            {isLoading ? (
              <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
                <Stack direction="row" spacing={1}>
                  <Typography
                    variant="h4"
                    color="secondary"
                    fontWeight={800}
                    marginRight={2}
                  >
                    Fetching
                  </Typography>
                  <CircularProgress color="primary" />
                </Stack>
              </Backdrop>
            ) : packages.length === 0 ? (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">No data available</Typography>
              </Grid>
            ) : (
              <TableBody>
                {packages
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => (
                    <TableRow
                      hover
                      role="checkbox"
                      // aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      // selected={isItemSelected}
                    >
                      <TableCell padding="checkbox" sx={{ pl: 3 }}>
                        <Checkbox
                          color="primary"
                          // checked={isItemSelected}
                          /* inputProps={{
                                                    'aria-labelledby': labelId
                                                }}*/
                        />
                      </TableCell>
                      <TableCell
                        component="th"
                        // id={labelId}
                        scope="row"
                        //onClick={(event) => handleClick(event, row.packageName)}
                        sx={{ cursor: "pointer" }}
                      >
                        <Typography
                          variant="subtitle1"
                          sx={{
                            color:
                              theme.palette.mode === "dark"
                                ? "grey.600"
                                : "grey.900",
                          }}
                        >
                          {" "}
                          {row.package_name}{" "}
                        </Typography>
                      </TableCell>
                      <TableCell align="center">{row.duration}</TableCell>
                      <TableCell align="center">
                        {row.test_type?.name}
                      </TableCell>
                      <TableCell align="center">
                        {row.branch_id.join(" , ")}
                      </TableCell>
                      <TableCell align="center">{row.original_price}</TableCell>
                      <TableCell align="center">
                        {row.discounted_price}
                      </TableCell>
                      <TableCell align="center">
                        {row.payable_installment}
                      </TableCell>
                      <TableCell align="center">
                        {row.status === "active" && (
                          <Chip
                            label="Active"
                            size="small"
                            chipcolor="primary"
                          />
                        )}
                        {row.status === "inActive" && (
                          <Chip
                            label="Inactive"
                            size="small"
                            chipcolor="error"
                          />
                        )}
                      </TableCell>
                      <TableCell align="center" sx={{ pr: 3 }}>
                        {(userRole === "center_admin" ||
                          userRole === "developer") && (
                          <IconButton
                            color="primary"
                            size="large"
                            aria-label="edit"
                            onClick={() => handleOpenForm(row)}
                          >
                            <Tooltip title="Edit">
                              <EditIcon fontSize="small" />
                            </Tooltip>
                          </IconButton>
                        )}
                      </TableCell>
                    </TableRow>
                  ))}
              </TableBody>
            )}
          </Table>
        </TableContainer>

        {/* table pagination */}
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={packages.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </MainCard>
    </>
  );
}

export default PackagesList;
