import React, { useEffect, useState } from 'react'
import { Backdrop, Box, CircularProgress, Grid, Paper, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography, useTheme } from '@mui/material';
import { fDate } from '../../../utils/formatTime';
import { useLocation, useNavigate } from 'react-router-dom';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

export default function ActiveStudentTable() {
    const theme = useTheme()
    const location = useLocation()
    const navigate = useNavigate()
    const classDetails = location.state?.classDetails || {};
    const userId = localStorage.getItem('userId');
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [data, setData] = useState([])
  const [isLoading, setIsLoading] = useState(true);
  const [activeStudentsData, setActiveStudentsData] = useState([])
  const [classId, setClassId] = useState(classDetails?.id || null)

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleViewMore = (studentId) => {
    window.open(`/view-student/${studentId}`, '_blank');
  };
  

  const getActiveStudentsData = () => {
    setIsLoading(true);

    const requestData = {
      user_id: userId,
      class_id: classId
    }

    fetch(`${API_ENDPOINT}route/studentbyclass`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(requestData)
    })
    .then(response => response.json())
    .then((data) => {
      console.log(data)
      setActiveStudentsData(data);
      setIsLoading(false);
     
    })
    .catch((error) => {
      console.error('Error fetching daily data:', error);
      setIsLoading(false);
    })
  }

  useEffect(() => {
    getActiveStudentsData();
  }, [])


  return (
  <>
  <Grid container spacing={2}>
      
  <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
          
            <Grid item xs={12}>
        <Typography sx={{ color: '#000000', fontFamily: '"Poppins-Medium", Helvetica', fontSize: '24px'}} fontWeight={500}>
               View Active Students
                </Typography>
                <Typography sx={{ color: '#000000', fontFamily: '"Poppins-Medium", Helvetica', fontSize: '18px'}} fontWeight={600}>
               Class: {classDetails?.name}
                </Typography>
                <Stack direction= 'row' spacing={3}>
                <Typography sx={{ color: '#000000', fontFamily: '"Poppins-Medium", Helvetica', fontSize: '18px'}} fontWeight={600}>
               Trainer: {classDetails?.trainer_id?.first_name} {classDetails?.trainer_id?.last_name}
                </Typography>
                <Typography sx={{ color: '#000000', fontFamily: '"Poppins-Medium", Helvetica', fontSize: '18px'}} fontWeight={600}>
               Branch: {classDetails?.branch_id?.branch_name}
                </Typography>
                </Stack>
      </Grid>
        <Grid item xs={12}>
   <TableContainer component={Paper} sx={{borderRadius:'15px' ,padding:'10px' }}>
    <Table>
      <TableHead >
        <TableRow >
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 }}>Student Name</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 ,}}>Enrollment No</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Start Date</TableCell>
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 }}>Active Total Sessions</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600,}}>Remaining Sessions</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody >
      {(activeStudentsData.length === 0 && !isLoading) ? (
                                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant='h4'>No data available</Typography>
                                </Grid>
                            ) : (
      activeStudentsData.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
          <TableRow key={index}  style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'inherit' ,width: '6%' }}>
             <TableCell
                        component="th"
                        // id={labelId}
                        align="left"
                        scope="row"
                        sx={{ cursor: "pointer" }}
                      >
                        <Typography
                          //variant="subtitle1"
                          sx={{
                            color:
                              theme.palette.mode === "dark"
                                ? "grey.600"
                                : "grey.900",
                          }}
                          component="span"
                          variant="button"
                          style={{ cursor: "pointer", color: "blue" }}
                          onClick={() => handleViewMore(row?.student_id)}
                        >
                          {row?.student_name}
                        </Typography>
                      </TableCell>
            <TableCell >{row?.enrollment_number}</TableCell>
            <TableCell>{fDate(row?.enrollment_date)}</TableCell>
            <TableCell >{row?.total_session}</TableCell>
            <TableCell>{row?.remaining_sessions}</TableCell>
          </TableRow>
        )))}
      </TableBody>
    </Table>
 
    <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={activeStudentsData.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
  </TableContainer>
  </Grid>

 
  </Grid>
    
  </>
  )
}