import { Grid, Typography } from '@mui/material'
import React from 'react'

export default function Head() {
  return (
    <Grid item xs={12} md={12} lg={12} container justifyContent="flex-start" >
       
   
    
    <Typography sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'32px' , fontStyle:'normal' , fontWeight:600}}>Lead Conversion Report</Typography>
    </Grid>
  )
}
