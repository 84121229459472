import {
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  Grid,
  InputLabel,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../components/Maincard";
import { DataGrid } from "@mui/x-data-grid";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InfoCard from "../../newTestPrep/pages/Events/InfoCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import EmailIcon from "@mui/icons-material/Email";
import { fDate, fDateTime } from "../../utils/formatTime";
import CustomPopup from "../../components/CustomPopup";
import NoenquiryForm from "../../newTestPrep/pages/Events/NoenquiryForm";
import MaskTaskAssign from "../../newTestPrep/pages/Events/MaskTaskAssign";
import BulkEmailPopup from "../../newTestPrep/pages/Events/BulkEmail/BulkEmailPopup";
import BulkMailform from "../../newTestPrep/pages/Events/BulkEmail/BulkMailform";
import NoeMailForm from "../../newTestPrep/pages/Events/NoMailForm";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import CampaignReportFilter from "./CampaignReportFilter";
import { CSVExport } from "../../newTestPrep/components/ui-component/table/CSVExport";
import { useGetTokenOrLogout } from "../../utils/token";

export default function CampaignReport() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");

  const getTokenOrLogout = useGetTokenOrLogout();

  const [open, setOpen] = useState(true);

  const [openSumReport, setSumReporrt] = useState(true);

  const [campaignLoading, setCampaignLoading] = useState(false);

  const [campaignreportData, setCampaignreportData] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [campaignRowData, setcampaignRowData] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [selectedid, setSelectedid] = useState([]);
  const [selectedEnquiryId, setSelectedEnquiryids] = useState([]);
  const [openMask, setOpenMask] = useState(false);
  const [OpenEmailPopup, setOpenEmailPopup] = useState(false);

  const [isDownloadAccess, setIsDownloadAccess] = useState(false);
  const [exportData, setExportData] = useState([]);
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 100,
  });

  const [visibleRowIds, setVisibleRowIds] = useState([]); // Store current page's row IDs
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [prevPageSize, setPrevPageSize] = useState(pagination.pageSize);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const navigate = useNavigate();

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleTogglesumReport = () => {
    setSumReporrt(!openSumReport);
  };

  // Upadate current page ids when pagination is chnages
  useEffect(() => {
    console.log("pagination", pagination, selectedid);
    setPrevPageSize(pagination.pageSize);

    // new pagesize is less than prev page size then deselct all selected ids first
    if (pagination.pageSize !== prevPageSize) {
      setSelectedid([]);
      return;
    }

    // Update visible row IDs when pagination changes
    const currentPageRows = campaignreportData.slice(
      pagination.page * pagination.pageSize,
      (pagination.page + 1) * pagination.pageSize
    );
    const visibleIds = currentPageRows.map((row) => row.id);
    setVisibleRowIds(visibleIds);

    // If there are no visible rows, set SelectAllChecked to false
    if (visibleIds.length === 0) {
      setIsSelectAllChecked(false);
      setIsIndeterminate(false);
      return;
    }

    // Check if all visible rows are selected
    const allVisibleSelected = visibleIds.every((id) =>
      selectedid.includes(id)
    );
    const someVisibleSelected = visibleIds.some((id) =>
      selectedid.includes(id)
    );

    setIsSelectAllChecked(allVisibleSelected);
    setIsIndeterminate(someVisibleSelected && !allVisibleSelected);
  }, [pagination, campaignreportData, selectedid]);

  // HandleSelectAllCheckBox
  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setIsSelectAllChecked(checked);

    if (checked) {
      // Select all visible row IDs
      setSelectedid((prevSelectedIds) => [
        ...new Set([...prevSelectedIds, ...visibleRowIds]),
      ]);
    } else {
      // Deselect all visible row IDs
      setSelectedid((prevSelectedIds) =>
        prevSelectedIds.filter((id) => !visibleRowIds.includes(id))
      );
    }
  };

  // Individual CheckBox
  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;

    setSelectedid((prevSelectedIds) => {
      if (checked) {
        const newSelected = [...prevSelectedIds, id];
        // Check if all visible rows are selected after this checkbox is checked
        const allVisibleSelected = visibleRowIds.every((rowId) =>
          newSelected.includes(rowId)
        );
        setIsSelectAllChecked(allVisibleSelected);
        setIsIndeterminate(!allVisibleSelected);

        return newSelected;
      } else {
        // Deselect the row ID
        const newSelected = prevSelectedIds.filter((rowId) => rowId !== id);
        setIsSelectAllChecked(false);
        setIsIndeterminate(
          visibleRowIds.some((rowId) => newSelected.includes(rowId))
        );

        return newSelected;
      }
    });
  };

  // Select EnquiryIds
  useEffect(() => {
    if (selectedid.length > 0) {
      console.log("taskIds", selectedid);
      const enquiryIds = campaignreportData.filter((id) =>
        selectedid.includes(id?.id)
      );
      const selectedEnquiryids = enquiryIds.map((i) => i.enquiry_id?.id);
      setSelectedEnquiryids(selectedEnquiryids);
    }
  }, [selectedid]);

  const columns = [
    {
      field: "selection",
      sortable: false,
      renderHeader: (params) => (
        <Checkbox
          size="small"
          checked={isSelectAllChecked}
          indeterminate={isIndeterminate}
          onChange={handleSelectAllChange}
        />
      ),
      headerClassName: "header-cell",
      width: 70,
      renderCell: (params) => {
        const isChecked = selectedid.includes(params.row?.id);
        return (
          <Checkbox
            size="small"
            checked={isChecked}
            onChange={(event) => handleCheckboxChange(event, params.row?.id)}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Name",

      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div
          style={{ color: "#2196F3", cursor: "pointer" }}
          onClick={() =>
            window.open(
              `/enquirydetails/${params?.row?.enquiry_id?.id}`,
              "_blank"
            )
          }
        >
          {params.row?.enquiry_id?.first_name}{" "}
          {params.row?.enquiry_id?.last_name}
        </div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.enquiry_id?.mobile}</div>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "contact",
      headerName: "Contact",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.connected_call}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "nonContact",
      headerName: "Non Contact",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.not_connected_call}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "openTask",
      headerName: "Open Task",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.total_open_task}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "leadOwner",
      headerName: "Lead Owner",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params.row?.assign_to?.first_name} {params.row?.assign_to?.last_name}
        </div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "campaign",
      headerName: "Campaign",
      width: 450,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div> {params.row?.campaign_id?.campaign_name}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "branch",
      headerName: "Branch",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.branch_id?.branch_name}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "service",
      headerName: "Service",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.service_id?.service_name}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "subService",
      headerName: "Sub Service",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.sub_service_id?.name}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "stage",
      headerName: "Stage",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.stage_id?.stage_name}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params.row?.enquiry_id?.status_id === 1 ? (
            <Chip color="secondary" label="active" />
          ) : params.row?.enquiry_id?.status_id === 0 ? (
            <Chip color="error" label="inactive" />
          ) : (
            ""
          )}
        </div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "firstcountry",
      headerName: "First Country of Interest",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => <div></div>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "leadSource",
      headerName: "Lead Source",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.lead_source_type_id?.name}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "state",
      headerName: "State Name",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.state_id?.state_name}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "cityName",
      headerName: "City Name",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.city_id?.city_name}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdAt",
      headerName: "Created At",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDateTime(params.row?.createdAt)}</div>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params.row?.created_by?.first_name}{" "}
          {params.row?.created_by?.last_name}
        </div>
      ),
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "registrationStatus",
    //   headerName: "Registration Status",
    //   width:150,
    //   headerClassName: "header-cell",
    //   renderCell: (params) => <div></div>,
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   field: "visit",
    //   headerName: "Visit Status",
    //   width:150,
    //   headerClassName: "header-cell",
    //   renderCell: (params) => <div></div>,
    //   headerAlign: "center",
    //   align: "center",
    // },
    {
      field: "nearestBranch",
      headerName: "Nearest Branch",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.enquiry_id?.nearest_branch_id?.branch_name}</div>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "campaignCountry",
      headerName: "Campaign Country",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => <div></div>,
      headerAlign: "center",
      align: "center",
    },
    // {
    //   field: "intake",
    //   headerName: "Intake",
    //   width:150,
    //   headerClassName: "header-cell",
    //   renderCell: (params) => <div></div>,
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   field: "coAssignee",
    //   headerName: "Co-Assignee",
    //   width:150,
    //   headerClassName: "header-cell",
    //   renderCell: (params) => <div></div>,
    //   headerAlign: "center",
    //   align: "center",
    // },
    // {
    //   field: "permanentAssignee",
    //   headerName: "Permanent Assignee",
    //   width:150,
    //   headerClassName: "header-cell",
    //   renderCell: (params) => <div></div>,
    //   headerAlign: "center",
    //   align: "center",
    // },
  ];
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }

  console.log("campaignreportData", campaignreportData);
  console.log("campaignreportData", originalData);
  useEffect(() => {
    setCampaignLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    fetch(`${API_ENDPOINT}route2.0/gettodayenquiry/${userId}`, { headers })
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      })
      .then((data) => {
        if (!data) return; // Stop further execution if data is not present

        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setCampaignreportData(sortedData);
        setOriginalData(sortedData);
        setcampaignRowData(sortedData);
        setCampaignLoading(false);
      })
      .catch((error) => {
        navigate("/page500");
      });
  }, [userId]);

  const totalConnectedCalls = campaignreportData.reduce((total, item) => {
    return item?.enquiry_id?.connected_call !== 0 ? total + 1 : total;
  }, 0);

  const totalNotConnectedCalls = campaignreportData.reduce((total, item) => {
    return item?.enquiry_id?.not_connected_call !== 0 ? total + 1 : total;
  }, 0);

  const totalOpenTask = campaignreportData.reduce((total, item) => {
    return item?.enquiry_id?.total_open_task !== 0 ? total + 1 : total;
  }, 0);

  const totalLeads = campaignRowData.length;
  console.log("leadsn", totalLeads);

  const totalNewlead = campaignreportData.reduce((total, item) => {
    return item?.enquiry_id?.prospective_level_id?.prospective_level ===
      "New lead"
      ? total + 1
      : total;
  }, 0);

  const handleOpenmask = () => {
    setOpenMask(true);
  };

  const handleClosemask = () => {
    setOpenMask(false);
  };

  const handleOpenEmail = () => {
    setOpenEmailPopup(true);
  };

  const handleCloseEmail = () => {
    setOpenEmailPopup(false);
  };

  useEffect(() => {
    fetch(`${API_ENDPOINT}route/downloadaccess/${userId}`)
      .then((response) => {
        if (response.ok) {
          setIsDownloadAccess(true);
        } else {
          setIsDownloadAccess(false);
        }
      })
      .catch((err) => {
        setIsDownloadAccess(false);
      });
  }, [userId]);

  useEffect(() => {
    const exportData = campaignreportData.map((row) => ({
      name: row.enquiry_id?.last_name
        ? `${row.enquiry_id.first_name} ${row.enquiry_id.last_name}`
        : row.enquiry_id.first_name,
      mobile: row.enquiry_id.mobile,
      contactable: row.enquiry_id.connected_call || "",
      non_contactable: row.enquiry_id.not_connected_call || "",
      open_tasks: row.enquiry_id.total_open_task || "",
      nearest_branch: row.enquiry_id?.nearest_branch_id?.branch_name || "",
      enquiry_date: fDate(row.enquiry_id?.enquiry_date),
      last_followup_date: fDate(row.enquiry_id?.last_follow_up_date),
      next_followup_date: fDate(row.enquiry_id?.next_follow_up_date),
      campaign_name: row.campaign_id?.campaign_name,
      prospective_level:
        row.enquiry_id?.prospective_level_id?.prospective_level || "",
      sub_service: row.enquiry_id?.sub_service_id?.name || "",
      assign: row.enquiry_id?.assign_to?.first_name || "",
      lead_source_type: row.enquiry_id?.lead_source_type_id?.name || "",
      sub_lead_source_type:
        row.enquiry_id?.sub_lead_source_type_id?.source_type || "",
      stage: row.enquiry_id?.stage_id?.stage_name || "",
    }));
    setExportData(exportData);
  }, [campaignreportData]);

  // Search By Name
  const handleSearchByName = (e) => {
    const value = e.target.value;
    let filteredData1 = campaignRowData.filter((data) =>
      `${data?.enquiry_id?.first_name} ${data?.enquiry_id?.last_name}`
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(value.toLowerCase().replace(/\s+/g, ""))
    );
    setCampaignreportData(filteredData1);
  };

  return (
    <>
      <Backdrop open={campaignLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container mb={2} spacing={2}>
        <Grid item xs={12} lg={6} sm={6} md={6}>
          <Paper
            sx={{
              height: "50px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "24px",
              width: "250px",
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
              Campaign Report
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12} lg={6} sm={12} md={12} textAlign="end">
          <Button
            onClick={handleToggle}
            style={{
              borderRadius: "25px",
              background: "white",
              color: "black",
            }}
          >
            <FilterAltIcon fontSize="medium" color="orange" />
            <Typography variant="h4" marginLeft={1}>
              Filter
            </Typography>
            {open === false ? (
              <KeyboardArrowDownIcon fontSize="small" />
            ) : (
              <KeyboardArrowUpIcon fontSize="small" />
            )}
          </Button>

          <Button
            variant="contained"
            style={{ borderRadius: "24px", marginLeft: "10px" }}
            onClick={handleOpenmask}
          >
            Mass Task Assign{" "}
          </Button>

          <Button
            variant="contained"
            style={{ borderRadius: "24px", marginLeft: "10px" }}
            startIcon={<EmailIcon />}
            color="secondary"
            onClick={handleOpenEmail}
          >
            Send Email{" "}
          </Button>

          {isDownloadAccess && (
            <CSVExport data={exportData} filename="campaign-report.csv" />
          )}
        </Grid>

        <Grid item lg={12}>
          <CampaignReportFilter
            open={open}
            setCampaignLoading={setCampaignLoading}
            campaignLoading={campaignLoading}
            setCampaignreportData={setCampaignreportData}
            originalData={originalData}
            setcampaignRowData={setcampaignRowData}
          />
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12}>
          <MainCard>
            <Grid container>
              <Grid item xs={12} md={12} lg={12} sm={12} textAlign="end">
                <Button
                  variant="outlined"
                  onClick={handleTogglesumReport}
                  style={{
                    color: "black",
                    border: "1px solid #e0e0e0",
                    borderRadius: "25px",
                  }}
                >
                  <FilterAltIcon fontSize="medium" color="orange" />
                  <Typography
                    sx={{ fontSize: "14px", fontWeight: 600 }}
                    marginLeft={1}
                  >
                    Summarized Reports
                  </Typography>
                  {openSumReport === false ? (
                    <KeyboardArrowDownIcon fontSize="small" />
                  ) : (
                    <KeyboardArrowUpIcon fontSize="small" />
                  )}
                </Button>
              </Grid>
              <Grid item xs={12} md={12} lg={12} sm={12}>
                <Collapse in={openSumReport} fullWidth>
                  <Grid container spacing={2} mt={2}>
                    <Grid item>
                      <Button
                        style={{
                          background: "#edebfb",
                          color: "#000000",
                          borderRadius: "25px",
                          fontWeight: 500,
                          fontSize: "16px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                      >
                        Total Leads : {totalLeads}
                      </Button>
                    </Grid>

                    {/* <Grid item>
                      <Button
                        style={{
                          background: "#d1ecf1",
                          color: "#000000",
                          borderRadius: "25px",
                          fontWeight: 500,
                          fontSize: "16px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                      >
                        New Leads : {totalNewlead}
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        style={{
                          background: "#d1f2e0",
                          color: "#000000",
                          borderRadius: "25px",
                          fontWeight: 500,
                          fontSize: "16px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                      >
                        Duplicates Leads : 0
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        style={{
                          background: "#f5e0fc",
                          color: "#000000",
                          borderRadius: "25px",
                          fontWeight: 500,
                          fontSize: "16px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                      >
                        Contacted : {totalConnectedCalls || 0}
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        style={{
                          background: "#fbe3d9",
                          color: "#000000",
                          borderRadius: "25px",
                          fontWeight: 500,
                          fontSize: "16px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                      >
                        Non Contacted : {totalNotConnectedCalls}
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        style={{
                          background: "#fbd9d9",
                          color: "#000000",
                          borderRadius: "25px",
                          fontWeight: 500,
                          fontSize: "16px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                      >
                        Not-Attempted : 0
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        style={{
                          background: "#dfd9fb",
                          color: "#000000",
                          borderRadius: "25px",
                          fontWeight: 500,
                          fontSize: "16px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                      >
                        Open Task : {totalOpenTask || 0}
                      </Button>
                    </Grid> */}

                    {/* <Grid item>
                      <Button
                        style={{
                          background: "#fbedd9",
                          color: "#000000",
                          borderRadius: "25px",
                          fontWeight: 500,
                          fontSize: "16px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                      >
                        Registered And Confirmed : 0
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        style={{
                          background: "#fbedd9",
                          color: "#000000",
                          borderRadius: "25px",
                          fontWeight: 500,
                          fontSize: "16px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                      >
                        Registered And Not Confirmed : 0
                      </Button>
                    </Grid>

                    <Grid item>
                      <Button
                        style={{
                          background: "#fbedd9",
                          color: "#000000",
                          borderRadius: "25px",
                          fontWeight: 500,
                          fontSize: "16px",
                          paddingLeft: "15px",
                          paddingRight: "15px",
                        }}
                      >
                        Not Registered : 0
                      </Button>
                    </Grid> */}
                  </Grid>
                </Collapse>
              </Grid>

              {/* Search by name */}

              {/* <Grid item xs={12} lg={12} sm={12} md={12} textAlign="end" mt={2}>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "end",
                  }}
                >
                  <Typography sx={{ fontWeight: 600, fontSize: "16px" }}>
                    Search:{"  "}
                  </Typography>
                  <TextField
                    // value={value}
                    onChange={handleSearchByName}
                    size="small"
                    InputProps={{ style: { borderRadius: "15px" } }}
                  />
                </Box>
              </Grid> */}
              <Grid item xs={12}>
                <Box display="flex" justifyContent="space-between" mt={2}>
                  <Box>
                    <Button
                      style={{ borderRadius: "25px" }}
                      variant="contained"
                      size="small"
                    >
                      {" "}
                      Selected Items : {selectedid.length}{" "}
                    </Button>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                    }}
                  >
                    <Typography sx={{ fontWeight: 600 }}>
                      Search:{"  "}
                    </Typography>
                    <TextField
                      // value={value}
                      onChange={handleSearchByName}
                      size="small"
                      InputProps={{ style: { borderRadius: "15px" } }}
                    />
                  </Box>
                </Box>
              </Grid>

              <Box
                sx={{
                  height: "100%",
                  width: "100%",
                  "& .header-cell": {
                    backgroundColor: "#abf7b1",
                    fontWeight: 600,
                    borderRight: "0.1px solid #C3C3C3",
                  },
                }}
              >
                <DataGrid
                  rows={campaignreportData}
                  columns={columns}
                  autoHeight
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  pageSize={pagination.pageSize}
                  onPaginationModelChange={(model) => setPagination(model)}
                  sx={{ marginTop: "20px" }}
                  pageSizeOptions={[10, 25, 50, 100]}
                />
              </Box>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>

      {selectedid.length === 0 ? (
        <CustomPopup
          open={openMask}
          onClose={handleClosemask}
          component={<NoenquiryForm onClose={handleClosemask} />}
          maxWidth="xs"
        />
      ) : (
        <CustomPopup
          open={openMask}
          onClose={handleClosemask}
          component={
            <MaskTaskAssign
              onClose={handleClosemask}
              selectedId={selectedEnquiryId}
              setSelectedIds={setSelectedEnquiryids}
            />
          }
          title="Mass Task Assign"
          maxWidth="md"
          showDivider={true}
        />
      )}

      {selectedid.length === 0 ? (
        <CustomPopup
          open={OpenEmailPopup}
          onClose={handleCloseEmail}
          component={<NoeMailForm onClose={handleCloseEmail} />}
          maxWidth="xs"
        />
      ) : (
        <BulkEmailPopup
          open={OpenEmailPopup}
          handelclose={handleCloseEmail}
          component={
            <BulkMailform
              handelclose={handleCloseEmail}
              selectedId={selectedEnquiryId}
            />
          }
          title="Send Bulk Emails"
          maxWidth="md"
          showDivider={true}
        />
      )}

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
