import { Backdrop, Box, Button, CircularProgress, Grid, Paper, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomPopup from "../../../components/CustomPopup";
import AddMeetingForm from "./AddMeetingForm";
import MeetingDataCard from "./MeetingDataCard";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useGetTokenOrLogout } from "../../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EmployeeMeeting() {
  const navigate = useNavigate();
  
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedbutton, setSelectedButton] = useState("upcoming");
  const [MeetingData, setMeetingData] = useState([]);

  const [isloading, setisLoading] = useState(false);


  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleChange = (criteria) => {
    setSelectedButton(criteria);
  };
  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  useEffect(() => {
    setisLoading(true);
    
const token = getTokenOrLogout();
if (!token) return;
let headers = { 
  Authorization: `Bearer ${token}`,
 
};
    fetch(`${API_ENDPOINT}route2.0/getmeeting/${userId}`,{headers})
      .then((response) => {
        if (response.ok) {
          return response.json();
        }  else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      })
      .then((data) => {
        if (!data) return; // Stop further execution if data is not present

        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        console.log("sortedData", sortedData);
        setMeetingData(sortedData);
        setisLoading(false);
      })
      .catch((error) => {
        navigate("/page500");
      });
  }, [userId]);

  const upcommingMeeting = MeetingData.filter(
    (i) => i.meeting_status === "upcoming"
  );

  const pastMeeting = MeetingData.filter(
    (i) => i.meeting_status === "completed"
  );

  const cancelMeeting = MeetingData.filter(
    (i) => i.meeting_status === "cancelled"
  );

  return (
    <>
    <Backdrop open={isloading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="secondary" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={6} lg={6} sm={6} md={6}>
          <Paper
            sx={{
              width: "200px",
              fontSize: "24px",
              fontWeight: 600,
              height: "50px",
              borderRadius: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <Box width={30} mr={1}>    <img src="/icons8-calendar-48.png" width="100%" /></Box>
            Meeting
          </Paper>
        </Grid>
        {userRole === "admin" && (
          <Grid item xs={6} lg={6} sm={6} md={6} textAlign="end">
            <Button
              variant="contained"
              style={{ borderRadius: "25px" }}
              onClick={handleOpenPopup}
            >
              Add Meeting
            </Button>
          </Grid>
        )}

        <Grid item xs={12} lg={12} sm={12} md={12}>
          <Box
            sx={{
              background: "#e5e5e5",
              borderRadius: "10px",
              width: "300px",
              display: "flex",
              justifyContent: "center",
              padding: "10px",
            }}
          >
            <Button
              onClick={() => handleChange("upcoming")}
              style={{
                color: selectedbutton === "upcoming" ? "black" : "#767676",
                background: selectedbutton === "upcoming" ? "white" : "",
                fontWeight: 600,
              }}
            >
              Upcoming
            </Button>
            <Button
              onClick={() => handleChange("completed")}
              style={{
                color: selectedbutton === "completed" ? "black" : "#767676",
                background: selectedbutton === "completed" ? "white" : "",
                fontWeight: 600,
              }}
            >
              Past
            </Button>
            <Button
              onClick={() => handleChange("cancelled")}
              style={{
                color: selectedbutton === "cancelled" ? "black" : "#767676",
                background: selectedbutton === "cancelled" ? "white" : "",
                fontWeight: 600,
              }}
            >
              Cancelled
            </Button>
          </Box>
        </Grid>

 <MeetingDataCard
          data={
            selectedbutton === "upcoming"
              ? upcommingMeeting
              : selectedbutton === "completed"
              ? pastMeeting
              : cancelMeeting
          }
          selectedbutton={selectedbutton}
        />
     
      </Grid>

      <CustomPopup
        open={openPopup}
        onClose={handleClosePopup}
        component={<AddMeetingForm onClose={handleClosePopup} />}
        title="Add Meeting"
        maxWidth="sm"
        showDivider={true}
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
