import React from 'react'
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    useMediaQuery,
    useTheme,
    IconButton,
    Box,
    Divider,
  } from "@mui/material";
  import CloseIcon from "@mui/icons-material/Close";
export default function CustomPopup({open,onClose,title,component, maxWidth, showDivider}) {
  return (
   <>
      <Dialog
      onClose={onClose}
      open={open}
      scroll="body"
      maxWidth={maxWidth}
      fullWidth

    >
     
      <DialogTitle
        variant="h3"
      
        sx={{ fontSize: "20px", fontWeight: "700" , }}
      >
    
{title}
        <IconButton
          edge="end"
        
          onClick={onClose}
          aria-label="close"
          sx={{ position: "absolute", right: 15, top: 2 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      {showDivider && <Divider />}
      <DialogContent>{component}</DialogContent>
      <DialogActions>
      
      </DialogActions>
    
    </Dialog>
   </>
  )
}
