import React, { useState, useEffect } from "react";
import { DataGrid } from "@mui/x-data-grid";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Typography,
} from "@mui/material";
import { fDate } from "../../../utils/formatTime";
import Items from "../../../newTestPrep/pages/enquiry/NotesEnquiryItems";
import AddItem from "../../../newTestPrep/pages/enquiry/AddNote";
import ExpandLessIcon from "@mui/icons-material/ExpandLess";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function ViewNotesDataGrid({
  isDialogOpen,
  openDialog,
  closeDialog,
  notesData,
  studentId,
  userId,
  enquiryPersonalId,
  studentInfo,
}) {
  const [notes, setNotes] = useState([]);
  const [filteredNotes, setFilteredNotes] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [notes2, setNotes2] = useState(notesData);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const tasksPerPage = 10;
  const [globalFilter, setGlobalFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("all");
  const [showAllNotes, setShowAllNotes] = useState(false);

  console.log("filteredNotes",filteredNotes)
  console.log("notesis", notes)
  const columns = [
    {
      field: "note",
      headerName: "Note",
      flex: 2,
      filterable: true,
    },
    {
      field: "advisor_id",
      headerName: "Created By",
      flex: 1,
      filterable: true,
    },
    {
      field: "createdAt",
      headerName: "Created At ",
      flex: 1,
      filterable: true,
      renderCell: (params) => <div>{fDate(params.value)}</div>,
    },
  ];

  /*useEffect(() => {
    setNotes(notesData)
  }, []);*/

  // useEffect(() => {
  //   // Define a function to fetch the note data
  //   const fetchNoteData = () => {
  //     fetch(`${API_ENDPOINT}advisordashboard/notebystudent/${studentId}`)
  //       .then(response => response.json())
  //       .then(data => {
  //         data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
  //         console.log(data);
  //         setNotes(data);
  //         setIsLoading(false);
  //       })
  //       .catch(error => {
  //         console.error('Error fetching note items:', error);
  //         setIsLoading(false);
  //       });
  //   };

  //   // Fetch data initially when the component mounts
  //   fetchNoteData();

  //   // Set up an interval to fetch data every 10 seconds
  //   /*const intervalId = setInterval(fetchNoteData, 10000);

  //   // Clean up the interval when the component unmounts
  //   return () => clearInterval(intervalId);*/
  // }, [studentId]);

  // Fetch Note Summary
  const fetchNotesData = async () => {
    const requestData = {
      user_id: parseInt(userId),
      enq_personal_detail_id: parseInt(enquiryPersonalId),
    };
    try {
      setIsLoading(true);
      const response = await fetch(
        `${API_ENDPOINT}route2.0/getenqbypersonaldetail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        const noteslist = result?.note_list;
        setNotes(noteslist);
        const filterdNotes = noteslist.filter(
          (note) =>
            note.enquiry_id?.sub_service_id?.id ===
            studentInfo?.sub_service_id?.id
        );
        setFilteredNotes(filterdNotes);
        console.log("filterdNotes", filterdNotes);
        setIsLoading(false);
      } else if (response.status === 401) {
        setIsLoading(false);
      } else if (response.status === 400) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);

      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchNotesData();
  }, []);

  console.log("notelist", notes);

  const toggleNotes = () => {
    setShowAllNotes((prevShowAllNotes) => !prevShowAllNotes); // Toggle between showing all notes and first note
  };

  // ... rest of your code ...

  return (
    // <div>
    //   {isLoading ? (
    //     <Box display="flex"
    //     justifyContent="center"
    //     alignItems="center"
    //     minHeight="100vh">
    //     <CircularProgress />
    //     </Box>
    //   ) : (

    //     <>
    //   <AddItem studentId={studentId} />
    //   {notes.length === 0 ? (
    //     <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
    //     <Typography variant='h5' marginTop={2}>No notes available</Typography>
    //     </Grid>
    //   ) : (
    //     notes?.map((note) => <Items key={note.id} item={note} />)
    //   )}

    //   </>
    //   )}
    //   </div>

    <div>
      {isLoading ? (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          // minHeight="100vh"
        >
          <CircularProgress />
        </Box>
      ) : (
        <>
          <AddItem studentId={studentId} />
          {notes.length === 0 ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5" marginTop={2}>
                No notes available
              </Typography>
            </Grid>
          ) : (
            <Box  mt={1}>
              {
                showAllNotes
                  ? notes.map((note) => (
                  
              <Items key={note?.id} item={note} />
       
                  )) 
                  : filteredNotes.map((note) => (
                      <Items key={note?.id} item={note} />
                    )) 
              }

              {notes.length > 0 && (
                <Grid item xs={12} textAlign="end">
                  <Button
                    color="primary"
                    onClick={toggleNotes}
                    endIcon={
                      showAllNotes ? <ExpandLessIcon /> : <ExpandMoreIcon />
                    }
                  >
                    {showAllNotes ? "Show Less Notes" : "Show All Notes"}{" "}
                    {/* Toggle button text */}
                  </Button>
                </Grid>
              )}
            </Box>
          )}
        </>
      )}
    </div>
  );
}

export default ViewNotesDataGrid;
