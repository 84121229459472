import { Box, Divider, Grid, Stack, Typography, useMediaQuery, useTheme } from '@mui/material';
import React from 'react'
import AuthWrapper1 from '../authentication/AuthWrapper1';
import AuthCardWrapper from '../authentication/AuthCardWrapper';
import Logo from '../../components/ui-component/Logo';
import RegistrationForm from './CenterRegistraion';
import { Link } from 'react-router-dom';
import MainCard from '../../components/ui-component/card/MainCard';
import AppBarComponent from '../landing/appbar/AppBar';

function RegistrationPage() {
    const theme = useTheme();
    //const { isLoggedIn } = useAuth();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
    <AppBarComponent />
    <AuthWrapper1>
      <Grid container direction="column" justifyContent="flex-end" sx={{ minHeight: '100vh' }}>
        <Grid item xs={12}>
          <Grid container justifyContent="center" alignItems="center" sx={{ minHeight: 'calc(100vh - 68px)' }}>
            <Grid item sx={{ m: { xs: 1, sm: 3 }, mb: 0 }}>
            <MainCard
    sx={{
      maxWidth: { xs: 400, lg: 800 },
      margin: { xs: 2.5, md: 3 },
      '& > *': {
        flexGrow: 1,
        flexBasis: '50%'
      }
    }}
    content={false}
    
  >
    <Box sx={{ p: { xs: 2, sm: 3, xl: 5 } }}>
                <Grid container spacing={2} alignItems="center" justifyContent="center">
                  <Grid item sx={{ mb: 3 }}>
                    <Link style={{textDecoration: 'none' }} to="#">
                      <Logo />
                    </Link>
                  </Grid>
                  <Grid item xs={12}>
                    <Grid container direction={matchDownSM ? 'column-reverse' : 'row'} alignItems="center" justifyContent="center">
                      <Grid item>
                        <Stack alignItems="center" justifyContent="center" spacing={1}>
                          <Typography color={theme.palette.secondary.main} gutterBottom variant={matchDownSM ? 'h3' : 'h2'}>
                            Welcome on Board
                          </Typography>
                          <Typography variant="caption" fontSize="16px" textAlign={matchDownSM ? 'center' : 'inherit'}>
                        To complete the registration process, please fill this form
                          </Typography>
                        </Stack>
                      </Grid>
                    </Grid>
                  </Grid>
                  <Grid item xs={12}>
                    <RegistrationForm />
                  </Grid>
                 
                  </Grid>
                  </Box>
                  </MainCard>
                  </Grid>
                  </Grid>
                  </Grid>
                  </Grid>
                  </AuthWrapper1>
                  </>
                  
  )
}

export default RegistrationPage