import React from 'react'
import {
   
    Typography,
   
  } from "@mui/material";
export default function PackagesAll({selectedpackages}) {
  return (
    <>
     <Typography variant='h5'>{selectedpackages.join(' , ')}</Typography>
    </>
  )
}
