import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";

export default function ApproveOnlinePaymentFilter({
  setPayments,
  isLoading,
  showSnackbar,
  setIsLoading,
  originalData,
}) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [open, setOpen] = useState(false);

  const handleToggle = () => {
    setOpen(!open);
  };
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [name, setName] = useState("");
  const [amount, setAmount] = useState("");
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [fromdate, setfromdate] = useState("");
  const [enddate, setenddate] = useState("");
  const [createdbylist, setCreatedbylist] = useState([]);
  const [initiatedBy, setInitiatedBy] = useState([]);
  const [linkId, setLinkId] = useState("");
  const [status, setStatus] = useState([]);
  const [paymentFrom, setPaymentFrom] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allbranchList=[{branch_name:"Select All"},...branchlist]

  const handelbranchchange = (event,value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

  const paymentinitiatedfromlist = [
    {
      id: 1,
      name: "Student Panel",
      value: "student panel",
    },
    {
      id: 2,
      name: "Crm",
      value: "crm",
    },
  ];

  const handlepaymentinitiated = (event,value) => {
    setPaymentFrom(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setCreatedbylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allcreatedByList=[{first_name:"Select All"},...createdbylist]
  const handelcretedby = (event,value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setInitiatedBy(createdbylist);
    } else {
      setInitiatedBy(value);
    }
  };

  const handleNamechange = (e) => {
    setName(e.target.value);
  };

  const handleAmountChange = (e) => {
    setAmount(e.target.value);
  };

  const handleFromdateChange = (e) => {
    setfromdate(e.target.value);
  };

  const handleTodateChange = (e) => {
    setenddate(e.target.value);
  };

  const handleLinkIdChange = (e) => {
    setLinkId(e.target.value);
  };

  const statuslist = [
    { id: 1, name: "Generated", value: "received" },
    {
      id: 2,
      name: "Confirmed",
      value: "confirmed",
    },
    { id: 3, name: "Deposited", value: "deposited" },
  ];

 
  const handlestatuschange = (event,value) => {
    setStatus(value);
  };

  const newBranch=branch.map((i)=>i.id)
  const newInitiatedBy=initiatedBy.map((i)=>i.id)
  const newstatus=status.map((i)=>i.value)
  const newPaymentFrom=paymentFrom.map((i)=>i.value)
  const handleApplyFilter = async (event) => {
    event.preventDefault();
    // Handle form submission logic here
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json" // Make sure to keep the content type
    };

    setIsLoading(true);
    const requestData = {
      payment_mode_id: [2],

      collected_by: newInitiatedBy,
      collected_status: newstatus,
      from_date: fromdate || null,
      to_date: enddate || null,
      user_id: parseInt(userId),

      is_partial_payment: [],
      payment_status: [],
      class_id: [],
      is_class_extension: [],
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/provisionalpaymentfilter`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        const sortedData = result.sort(
          (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
        );
        // const filterdData = sortedData.filter(
        //   (item) =>
        //     item.collected_status === "deposited" ||
        //     item.collected_status === "received"
        // );
        setPayments(sortedData);
        setIsLoading(false);
      }else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }}
        catch{
          navigate("/page500");
        }
  };

  const resetfilter = () => {
    setPayments(originalData)
    setName("");
    setBranch([]);
    setfromdate("");
    setenddate("");
    setInitiatedBy([]);
    setStatus([]);
    setAmount("");
    setLinkId("");
    setPaymentFrom([]);
  };
  return (
    <>
      <MainCard>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              marginLeft: "5px",
              fontSize: "24px",
              fontWeight: 700,
            }}
          >
            Approve Online Payments
          </Typography>

          <FilterListOutlinedIcon onClick={handleToggle} fontSize="medium" />
        </Stack>
        <Collapse in={open}>
          <Grid container mt={2} spacing={2}>
            {/* <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Enquiry Name</Typography>
              <TextField
              size="small"
                value={name}
                onChange={handleNamechange}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
              />
            </Grid> */}

            {/* <Grid item xs={12} lg={3} md={6} sm={6}>
           
           <Typography sx={{ fontWeight: 600 }}>Branch</Typography>

          
     
     <Autocomplete
           size="small"
           fullWidth
           value={branch}
           multiple
           onChange={handelbranchchange}
           options={allbranchList}
           getOptionLabel={(option) =>
            option.branch_name
           }
           renderInput={(params) => (
             <TextField
               {...params}
               InputProps={{
                 ...params.InputProps,
                 style: { borderRadius: "15px" }, // Add borderRadius here
               }}
             />
           )}
         />
       </Grid> */}

            <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600 }}>Payment From Date</Typography>
              <TextField
                  size="small"
                value={fromdate}
                onChange={handleFromdateChange}
                type="date"
                fullWidth
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
              />
            </Grid>

            <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600 }}>Payment To Date</Typography>
              <TextField
                  size="small"
                value={enddate}
                onChange={handleTodateChange}
                type="date"
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "15px",
                  },
                }}
              />
            </Grid>

            {/* <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>
                Razorpay Link / Payment ID
              </Typography>
              <TextField
                  size="small"
                value={linkId}
                onChange={handleLinkIdChange}
                fullWidth
                InputProps={{
                  style: {
                    borderRadius: "15px",
                  },
                }}
              />
            </Grid> */}

            {/* <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Amount Collected</Typography>

              <TextField
                  size="small"
                value={amount}
                onChange={handleAmountChange}
                fullWidth
                type="number"
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
              />
            </Grid> */}

            {/* <Grid item xs={12} lg={3} sm={6} md={6}>
        
        <Typography sx={{ fontWeight: 600 }}>Payment Initiated From </Typography>
   
    <Autocomplete
        size="small"
        fullWidth
        value={paymentFrom}
        multiple
        onChange={handlepaymentinitiated}
        options={paymentinitiatedfromlist}
        getOptionLabel={(option) =>
     option.name
        }
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { borderRadius: "15px" }, // Add borderRadius here
            }}
          />
        )}
      />
    </Grid> */}


            <Grid item xs={12} lg={3} sm={6} md={6}>
        
        <Typography sx={{ fontWeight: 600 }}>Initiated By </Typography>
   
    <Autocomplete
        size="small"
        fullWidth
        value={initiatedBy}
        multiple
        onChange={handelcretedby}
        options={allcreatedByList}
        getOptionLabel={(option) =>
        `${option.first_name ? option.first_name : ""} ${
            option.last_name ? option.last_name : ""
          }`
        }
        renderInput={(params) => (
          <TextField
            {...params}
            InputProps={{
              ...params.InputProps,
              style: { borderRadius: "15px" }, // Add borderRadius here
            }}
          />
        )}
      />
    </Grid>

            <Grid item xs={12} lg={3} md={6} sm={6}>
           
           <Typography sx={{ fontWeight: 600 }}>Status</Typography>
           
        <Autocomplete
           size="small"
           fullWidth
           value={status}
           multiple
           onChange={handlestatuschange}
           options={statuslist}
           getOptionLabel={(option) =>
            option.name
           }
           renderInput={(params) => (
             <TextField
               {...params}
               InputProps={{
                 ...params.InputProps,
                 style: { borderRadius: "15px" }, // Add borderRadius here
               }}
             />
           )}
         />
       </Grid>
            <Grid item xs={12} lg={12} textAlign='end'>
              <Button
                variant="contained"
                color="error"
                style={{
                  borderRadius: "24px",
                  fontWeight: 500,
                  fontSize: "14px",
                  marginRight: "8px",
                }}
                onClick={resetfilter}
              >
                Reset Filter
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{
                  borderRadius: "24px",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
                onClick={handleApplyFilter}
              >
                Apply Filter
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </MainCard>
    </>
  );
}
