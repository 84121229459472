import React from 'react'
import SubCard from '../../../components/ui-component/card/SubCard'
import { Card, Grid, Typography, useTheme } from '@mui/material'

function ClassCardEnroll({classData}) {
    const theme = useTheme()
  return (
    <Card
    sx={{
        padding: '16px',
        background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
        border: '1px solid',
        borderColor: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[100],
        '&:hover': {
            border: `1px solid${theme.palette.primary.main}`
        }
    }}
>
    <Grid container spacing={2}>
        <Grid item xs={12}>
            <Grid container spacing={2}>
                
                <Grid item xs zeroMinWidth>
                    <Typography
                        variant="h4"
                        component="div"
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center' }}
                    >
                        {classData.trainerName}
                    </Typography>

                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center' }}
                    >
                        {classData.slot}
                    </Typography>

                    <Typography
                        variant="h4"
                        component="div"
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center' }}
                    >
                        {classData.testType}
                    </Typography>

                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center' }}
                    >
                        {classData.deliveryMode}
                    </Typography>

                    <Typography
                        variant="h5"
                        component="div"
                        sx={{ overflow: 'hidden', textOverflow: 'ellipsis', whiteSpace: 'nowrap', display: 'flex', justifyContent: 'center' }}
                    >
                        {classData.branch}
                    </Typography>
                    
                </Grid>
               
            </Grid>
        </Grid>
        </Grid>
        </Card>
  )
}

export default ClassCardEnroll