import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { fDate } from "../../../utils/formatTime";
import CustomPopup from "../../../components/CustomPopup";
import EmployeeLeaveRequestForm from "./EmployeeLeaveRequestForm";
import EmployeeViewForm from "./EmployeeViewForm";
import EmployeeLeaveFilter from "./EmployeeLeaveFilter";
import { useGetTokenOrLogout } from "../../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function EmployeeLeaveRequests() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [isLoading, setIsLoading] = useState(false);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [originalData, setOriginalData] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [openLeaveRequestPopup, setAddLeaveRequestPopup] = useState(false);
  const [openViewRequestPopup, setViewRequestPopup] = useState(false);
  const [requestDetails, setRequestDetails] = useState(null);
  const [anotherEmployeeRequest, setAnotherEmployeeRequest] = useState(false);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleOpenLeaveRequestPopup = (bool) => {
    setAddLeaveRequestPopup(true);
    setAnotherEmployeeRequest(bool);
  };

  const handleCloseLeaveRequestPopup = () => {
    setAddLeaveRequestPopup(false);
    setAnotherEmployeeRequest(false);
  };

  const handleOpenViewRequestPopup = (row) => {
    setViewRequestPopup(true);
    setRequestDetails(row);
  };

  const handleCloseViewRequestPopup = () => {
    setViewRequestPopup(false);
    setRequestDetails(null);
  };

 


  useEffect(() => {
    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/getempleaverequest/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();

          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
  
          let filteredData = [...sortedData];
          if (filterCriteria) {
            filteredData = filteredData.filter((leave) => {
              let passesFilter = true;
  
              if (filterCriteria.newCreatedby.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newCreatedby.includes(leave?.emp_user_id?.id);
              }
  
              if (filterCriteria.newStatus.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newStatus.includes(leave?.leave_status);
              }
  
              if (filterCriteria.newBranch.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newBranch.includes(
                    leave?.created_by?.branch_name
                  );
              }
  
              if (filterCriteria.newApplicationType.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newApplicationType.includes(
                    leave?.leave_day_type
                  );
              }
  
              if (filterCriteria.newLeaveType.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newLeaveType.includes(leave?.leave_type_id?.id);
              }
  
              // filter for requested date
              if (filterCriteria.requestedFrom && filterCriteria.requestedTo) {
                const startDate = filterCriteria.requestedFrom;
                const endDate = filterCriteria.requestedTo;
                const createddate = new Date(leave?.createdAt);
                const formattedDate = createddate.toISOString().slice(0, 10);
                console.log("formated date is", formattedDate);
                passesFilter =
                  passesFilter &&
                  formattedDate >= startDate &&
                  formattedDate <= endDate;
              }
  
              // filter for start and end date
              if (filterCriteria.leaveFrom && filterCriteria.leaveTo) {
                const startDate = filterCriteria.leaveFrom;
                const endDate = filterCriteria.leaveTo;
                const leaveStartDate = leave?.from_date;
                const leaveEnddate = leave?.to_date;
                passesFilter =
                  passesFilter &&
                  leaveStartDate >= startDate &&
                  leaveStartDate <= startDate &&
                  leaveEnddate >= endDate &&
                  leaveEnddate <= endDate;
              }
  
              //Filter for Leave from date Only
              if (filterCriteria.leaveStartfrom && filterCriteria.leaveStartTo) {
                const startDate = filterCriteria.leaveStartfrom;
                const endDate = filterCriteria.leaveStartTo;
                const createddate = leave?.from_date;
             
      
                passesFilter =
                  passesFilter &&
                  createddate >= startDate &&
                  createddate <= endDate;
              }
  
              // filter for Leave to Only
              if (filterCriteria.leaveEndfrom && filterCriteria.leaveEndTo) {
                const startDate = filterCriteria.leaveEndfrom;
                const endDate = filterCriteria.leaveEndTo;
                const createddate = leave?.to_date;
             
      
                passesFilter =
                  passesFilter &&
                  createddate >= startDate &&
                  createddate <= endDate;
              }
  
              return passesFilter;
            });
          }
  
          setLeaveRequests(filteredData);
          setOriginalData(sortedData);
          console.log(sortedData);
          setIsLoading(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch {
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId,filterCriteria]);



  const handleApplyFilter = (criteria) => {
    setFilterCriteria(criteria);
  };
  const handleResetFilter = () => {
    setFilterCriteria(null);
    setLeaveRequests(originalData);
  };
  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <EmployeeLeaveFilter
            onApplyFilter={handleApplyFilter}
            onResetFilter={handleResetFilter}
          />
        </Grid>

        <Grid item xs={12} lg={12} sm={12} md={12} textAlign="end">
          <Button
            variant="contained"
            sx={{
              fontFamily: "Poppins",
              marginLeft: "5px",
              fontSize: "16px",
              fontWeight: 700,
              borderRadius: "15px",
            }}
            onClick={() => {
              handleOpenLeaveRequestPopup(false);
            }}
          >
            Add Leave Request
          </Button>
          {userRole === "admin" && (
            <Button
              variant="contained"
              sx={{
                fontFamily: "Poppins",
                marginLeft: "5px",
                fontSize: "16px",
                fontWeight: 700,
                borderRadius: "15px",
              }}
              onClick={() => {
                handleOpenLeaveRequestPopup(true);
              }}
            >
              Add Another Employee Request
            </Button>
          )}
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{ borderRadius: "30px", padding: "10px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Employee Details
              </TableCell>

              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Status
              </TableCell>

              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Branch
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Application Type
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Type of leave
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Leave From Date
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Leave To Date
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Request Date
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {leaveRequests.length === 0 ? (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">No data available</Typography>
              </Grid>
            ) : (
              leaveRequests
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f0f0f0" : "inherit",
                      width: "6%",
                    }}
                  >
                    <TableCell
                      sx={{
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                      }}
                    >
                      {`${
                        row?.emp_user_id?.first_name
                          ? row?.emp_user_id?.first_name
                          : ""
                      } ${
                        row?.emp_user_id?.last_name
                          ? row?.emp_user_id?.last_name
                          : ""
                      }`}{" "}
                      <br />
                      {row.emp_user_id?.role && (
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            color: "#364152",
                          }}
                        >
                          {row.emp_user_id?.role}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.leave_status === "accepted" && (
                        <Chip label="Approved" color="secondary" />
                      )}
                      {row?.leave_status === "denied" && (
                        <Chip label="Denied" color="primary" />
                      )}
                      {row?.leave_status === "pending" && (
                        <Chip label="Pending" color="warning" />
                      )}
                      {row?.leave_status === "cancelled" && (
                        <Chip label="Cancelled" color="error" />
                      )}
                    </TableCell>
                    <TableCell>{row?.emp_user_id?.branch_name}</TableCell>
                    <TableCell>
                      {row?.leave_day_type === "full" && (
                        <Chip label="Full Day" color="warning" />
                      )}
                      {row?.leave_day_type === "first_half" && (
                        <Chip label="First Half" color="warning" />
                      )}
                      {row?.leave_day_type === "second_half" && (
                        <Chip label="Second Half" color="warning" />
                      )}
                      {row?.leave_day_type === "short_morning" && (
                        <Chip label="Short Leave - Morning" color="warning" />
                      )}
                      {row?.leave_day_type === "short_evening" && (
                        <Chip label="Short Leave - Evening" color="warning" />
                      )}
                    </TableCell>
                    <TableCell>{row?.leave_type_id?.leave_type}</TableCell>
                    <TableCell>{fDate(row?.from_date)}</TableCell>
                    <TableCell>{fDate(row?.to_date)}</TableCell>
                    <TableCell>{fDate(row?.createdAt)}</TableCell>

                    <TableCell>
                      <Button
                        color="secondary"
                        variant="contained"
                        onClick={() => handleOpenViewRequestPopup(row)}
                      >
                        View Request
                      </Button>
                    </TableCell>

                    <TableCell></TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={leaveRequests.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <CustomPopup
        open={openLeaveRequestPopup}
        onClose={handleCloseLeaveRequestPopup}
        component={
          <EmployeeLeaveRequestForm
            onClose={handleCloseLeaveRequestPopup}
            anotherEmployeeRequest={anotherEmployeeRequest}
          />
        }
        title="Add Leave Request"
        maxWidth="sm"
        showDivider={true}
      />

      <CustomPopup
        open={openViewRequestPopup}
        onClose={handleCloseViewRequestPopup}
        component={
          <EmployeeViewForm
            onClose={handleCloseViewRequestPopup}
            data={requestDetails}
          />
        }
        title="View Leave Request"
        maxWidth="sm"
        showDivider={true}
      />
    </>
  );
}

export default EmployeeLeaveRequests;
