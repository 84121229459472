import { Box, Grid, Stack, Typography } from '@mui/material'
import React from 'react'
import MainCard from '../../components/Maincard';
import styled from 'styled-components';

export default function LeadWidget({ title, backgroundColor, iconBackgroundColor, value, lineImage, iconImage }) {
    const StyledGlobalBox = styled(Box)`
   
    border-radius: 10px;
    width: 44px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    img {
      max-width: 100%;
      max-height: 100%;
    }
  `;
  return (
   <>
   <Grid item xs={6} md={6} lg={3}>
      <MainCard style={{ background: backgroundColor }}>
        <Stack display='flex' flexDirection='column'>
          <Stack display='flex' alignItems='center' flexDirection='row'>
            <StyledGlobalBox style={{ background: iconBackgroundColor , marginRight:'5px'}}>
              <img src={iconImage} alt={title}  />
            </StyledGlobalBox>
            <Typography sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '26px', fontStyle: 'normal', fontWeight: 400 }}>
              {title}
            </Typography>
          </Stack>
          <Typography sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '98px', fontStyle: 'normal', fontWeight: 500 }}>
                {value < 10 ? `0${value}` : value}
              </Typography>
          <img src={lineImage} width='120px' alt={`${title} Line`} />
        </Stack>
      </MainCard>
    </Grid>
   </>
  )
}
