import { Box, Card, IconButton } from '@mui/material'
import React from 'react'
import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight';
import styled from '@emotion/styled';
import { Link } from 'react-router-dom';

const CardWidget = styled("Card") (({ bgColor, color }) => ({
    backgroundColor: bgColor,
    border: `1px solid ${color}`,
    boxSizing: `border-box`,
    borderRadius: `22px`,
    width: '100%', // Set the width to 90% of the parent container
    height: '100%', // Automatically adjust the height based on content
    margin: '0 auto', // Center the card horizontally
    padding: '16px',
    //width: `225px`,
    //height: `126px`,
    //padding: '16px',
    //position: `absolute`,
    //left: `0px`,
    //top: `0px`,
    display: 'flex',
  //flexDirection: 'column',
  //justifyContent: 'center',
  //alignItems: 'center',
  }));

  const Image = styled("img")(({ customStyle }) => ({
    height: `98px`,
    width: `98px`,
    objectFit: `cover`,
    ...(customStyle || {}), // Apply the custom style if provided
  }));
  

  const Title = styled("div")({
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    fontSynthesis: `none`,
    color: `rgba(0, 0, 0, 1)`,
    fontStyle: `normal`,
    fontFamily: `Poppins`,
    fontWeight: `600`,
    fontSize: `28px`,
    letterSpacing: `0px`,
    lineHeight: `105.50000667572021%`,
    textDecoration: `none`,
    textTransform: `none`,
    //position: `absolute`,
    //left: `99px`,
    //top: `9px`,
  });

  const Body = styled("div")({
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    fontSynthesis: `none`,
    color: `rgba(83, 83, 83, 1)`,
    fontStyle: `normal`,
    fontFamily: `Poppins`,
    fontWeight: `400`,
    fontSize: `14px`,
    letterSpacing: `0px`,
    textDecoration: `none`,
    textTransform: `none`,
   // position: `absolute`,
    //left: `0px`,
    //top: `0px`,
  });
  

function AdvisorWidget2({bgColor, color, image, title, body, imageStyle, path}) {
  return (
    <CardWidget bgColor={bgColor} color={color}>
   <Box container spacing={2} display='flex' alignItems="center" justifyContent="center" height="100%">
    <Box>
    <Image alt='Image' src={image} customStyle={imageStyle}/>
   </Box>
   <Box ml={2}>
    <Title>{title}</Title>
    <Box display='flex'>
    <Body>{body}</Body>
    <Link to={path}>
    <IconButton size="small" color="primary">
        <ArrowCircleRightIcon fontSize="inherit" />
    </IconButton>
    </Link>
    </Box>
   </Box>
   </Box>
   </CardWidget>
  )
}

export default AdvisorWidget2