
import { styled, useTheme } from '@mui/material/styles';
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Stack, Typography, Dialog, DialogTitle, DialogContent, DialogActions, Button, useMediaQuery, IconButton, Backdrop, CircularProgress, DialogContentText } from '@mui/material';
import { tableCellClasses } from '@mui/material/TableCell';
import CloseIcon from '@mui/icons-material/Close';

// project imports
import MainCard from '../../newTestPrep/components/ui-component/card/MainCard'; 
//import SecondaryAction from 'ui-component/cards/CardSecondaryAction';
import { CSVExport } from '../../newTestPrep/components/ui-component/table/CSVExport'; 
import { useEffect, useState } from 'react';
import SpecificTaskPage from '../../section/tasks/SpecificTaskPage';
import CancelIcon from '@mui/icons-material/Cancel';
//import { header } from 

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

// styles
const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        fontSize: 16,
        borderRight: `1px solid ${theme.palette.common.white}`,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 16,
        borderBottom: `1px solid ${theme.palette.divider}`
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-of-type td, &:last-of-type th': {
        border: 0
    }
}));


export default function CustomizedTables( { data, reportType, onClose, filterData}) {
    const userId = localStorage.getItem('userId');

    const [selectedRowId, setSelectedRowId] = useState(null);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [filter, setFilter] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  console.log(filter)

  const theme = useTheme()
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  const handleOpenDialog = (id, filter) => {
    setSelectedRowId(id);
    setFilter(filter)
    setDialogOpen(true);
  };

  const handleCloseDialog = () => {
    setSelectedRowId(null);
    setDialogOpen(false);
    setFetchedData(null);
  };

  const handleCloseBothDialog = () => {
    setDialogOpen(false);
    onClose()
  }

  useEffect(() => {
   
    if (selectedRowId !== null && filter !== null) {

        const requestData = {
            user_id: userId,
            type_of_task: selectedRowId,
            filter: filterData.filterType, 
            to_date: filterData.fromDate, 
            from_date: filterData.toDate,
            filter_for: filterData.filterFor,
            task_status: filter

        }
        setIsLoading(true);
      // Fetch data using selectedRowId and set it in the state
      console.log(selectedRowId)
      console.log(requestData)
      fetch(`${API_ENDPOINT}route/specifictask`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(requestData),
      })
      .then((response) => {
        if (response.status === 200) {
            setIsLoading(false);
          return response.json();
        } else if (response.status === 403) {
            setIsLoading(false);
          throw new Error("Couldn't find any slot for this test type, try next day!!");
        } else if (response.status === 404) {
            setIsLoading(false);
          throw new Error('Cannot return data, 404');
        } else {
            setIsLoading(false);
          throw new Error('Unexpected error occurred');
        }
        
      })
        .then((data) => {

          setFetchedData(data);

           /* const overdueTasks = data.filter((task) => task.status === 'overdue');
        const completedTasks = data.filter((task) => task.status === 0);
        const notStartedTasks = data.filter((task) => task.status === 1);

        if ( filter === 'overdue'){
            setFetchedData(data);
        } else if ( filter === 'notStarted'){
            setFetchedData(notStartedTasks);
        } else if ( filter === 'completed'){
            setFetchedData(completedTasks)
        } else {
            setFetchedData([])
        }*/

        setIsLoading(false);

          //setFetchedData(data);
          //console.log(data)
        })
        .catch((error) => {
          console.error('Error fetching data:', error);
          setIsLoading(false);
          setFetchedData([])
        });
    }
  }, [selectedRowId]);
    console.log(data)

    console.log(fetchedData);
    return (
        <>
      {/*}  <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>Fetching</Typography>
        <CircularProgress color="primary" />
    </Backdrop>*/}
        <MainCard
            content={false}
            title={reportType === 'today' ? "Daily Task Report" : "Monthly Task Report"}
            secondary={
                <Stack direction="row" spacing={2} alignItems="center">
                    <CSVExport data={data} filename="table-customized.csv"  />
                    
                </Stack>
            }
        >
            <TableContainer>
            {data.length === 0 && !isLoading ? (
          <Typography variant="h4" color="textSecondary" align="center" alignContent='center'>
            No data found.
          </Typography>
        ) : (
                <Table sx={{ minWidth: 320 }} aria-label="customized table">
                    <TableHead>
                        <TableRow>
                            <StyledTableCell sx={{ pl: 3 }}>Task Subject</StyledTableCell>
                            <StyledTableCell align='center'>Overdue</StyledTableCell>
                            <StyledTableCell align='center'>Not Started</StyledTableCell>
                            <StyledTableCell align='center'>Completed</StyledTableCell>
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {data.map((row) => (
                            <StyledTableRow hover key={row.name}>
                                <StyledTableCell sx={{ pl: 3 }} component="th" scope="row">
                                    {row.task_subject}
                                </StyledTableCell>
                                <StyledTableCell align='center'>
                                <Typography
                                    component="span"
                                    variant="button"
                                    style={{ cursor: 'pointer', color: 'blue' }}
                                    onClick={() => handleOpenDialog(row.id_of_task_type, 'overdue')}
                                    >
                                    {row.over_due}
                                    </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                <Typography
                                    component="span"
                                    variant="button"
                                    style={{ cursor: 'pointer', color: 'blue' }}
                                    onClick={() => handleOpenDialog(row.id_of_task_type, 'notstarted')}
                                    >
                                    {row.not_started}
                                    </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                <Typography
                                    component="span"
                                    variant="button"
                                    style={{ cursor: 'pointer', color: 'blue' }}
                                    onClick={() => handleOpenDialog(row.id_of_task_type, 'completed')}
                                    >
                                    {row.completed}
                                    </Typography>
                                    </StyledTableCell>
                                
                                
                            </StyledTableRow>
                        ))}
                    </TableBody>
                </Table>
                )}
            </TableContainer>

            
        </MainCard>

                <Dialog onClose={handleCloseDialog} open={dialogOpen} maxWidth='lg' fullWidth minWidth='md' sx={{ borderRadius: '50px'}}>
                <DialogTitle variant='h3'>
                My Tasks
                <IconButton
                edge="end"
                color="inherit"
                onClick={handleCloseDialog}
                aria-label="close"
                sx={{ position: 'absolute', right: 8, top: 8 }}
                >
                <CloseIcon />
                </IconButton>
                </DialogTitle>
                <DialogContent>
                  { isLoading && (
                    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <CircularProgress color="primary" />
                    </div>
                  )}
                {!isLoading && fetchedData && fetchedData.length > 0 && (
                <SpecificTaskPage tasks={fetchedData} onClose={handleCloseBothDialog}/>
                )}
                {!isLoading && fetchedData && fetchedData.length === 0 && (
                    <DialogContentText id="alert-dialog-description" style={{ textAlign: 'center' }}>
                  <CancelIcon color='error' style={{ fontSize: 60 }} />
                      <br />
                      No Data Available
                      
                    </DialogContentText>
                )}
                </DialogContent>
                <DialogActions>
                <Button onClick={handleCloseDialog} color="secondary">
                Close
                </Button>
                </DialogActions>
                </Dialog>
                </>
    );
}