import { Grid } from '@mui/material'
import React from 'react'
import { gridSpacing } from '../../../store/constant'
import AdvisorWidget from '../../../../views/advisor/dashboard/AdvisorWidget'
import AdvisorWidget2 from '../../../../views/advisor/dashboard/AdvisorWidget2'
import AdvisorCalendarDashboard from '../../../../views/advisor/dashboard/AdvisorCalendarDashboard'
import ProfileCard from '../centeradmin/ProfileCard'

function AccountantDashboard() {
  return (
    <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={12} lg={8}>
          <Grid container spacing={gridSpacing}>
        <Grid item xs={12} md={12} lg={6}>
      <ProfileCard />
      </Grid>
      <Grid item xs={12} md={12} lg={6}>
      <Grid container spacing={gridSpacing}>
        <Grid item xs={6}>
      <AdvisorWidget 
        bgColor='rgba(219, 246, 255, 1)'
        color='rgba(0, 193, 236, 1)'
        number={0}
        text='New Enquiries'
      />
      </Grid>

      <Grid item xs={6}>
      <AdvisorWidget
        bgColor='rgba(255, 248, 233, 1)'
        color='rgba(255, 176, 0, 1)'
        number={0}
        text='Refund Requests'
      />
      </Grid>

      <Grid item xs={6}>
      <AdvisorWidget 
        bgColor='rgba(255, 243, 235, 1)'
        color='rgba(255, 122, 0, 1)'
        number={0}
        text='Pending Payments'
      />
      </Grid> 

      <Grid item xs={6}>
      <AdvisorWidget 
        bgColor='rgba(239, 243, 255, 1)'
        color='rgba(23, 81, 255, 1)'
        number={0}
        text='Payments'
      />
      </Grid>
      </Grid>
      </Grid>

      {/*<Grid container spacing={3}> */}
      <Grid item xs={12} md={6}>
          <AdvisorWidget2 
          bgColor='rgba(239, 243, 255, 1)'
          color='rgba(23, 81, 255, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Classes.png'
          title='Provisional Payments'
          body='See my pending payments'
          path=''
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <AdvisorWidget2 
          bgColor='rgba(219, 246, 255, 1)'
          color='rgba(0, 193, 236, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Demos.png'
          title='Paid Payments'
          body='See paid payments'
          path=''
          />
        </Grid>


        <Grid item xs={12} md={6}>
          <AdvisorWidget2 
          bgColor='rgba(255, 248, 233, 1)'
          color='rgba(255, 176, 0, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Students.png'
          title='Expenses'
          body='view expenses'
          path='/expenses'
          />
        </Grid> 

        <Grid item xs={12} md={6}>
          <AdvisorWidget2 
          bgColor='rgba(239, 243, 255, 1)'
          color='rgba(23, 81, 255, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Daily-report.png'
          title='Refunds'
          body='See my refund requests'
          path=''
          />
        </Grid>
        

        {/*</Grid>*/}

        </Grid>

      </Grid>

      <Grid item xs={12} md={12} lg={4}>
       <AdvisorCalendarDashboard /> 
      </Grid>

      </Grid>
  )
}

export default AccountantDashboard