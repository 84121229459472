import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react'
import MainCard from '../../components/Maincard';
import styled from 'styled-components';

export default function AdminRevenueCard({ background, iconBackground, iconSrc, label, value, text}) {
    const StyledGlobalBox = styled(Box)`
   
    border-radius:50%;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    img {
      max-width: 100%;
      max-height: 100%;
    }
  `;

//const lgvalue= length===5?2.3 : 3
  return (
  <>
    <MainCard style={{ background: background }}>
       <Stack spacing={1}>
      {/* <Stack display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'> */}
        <StyledGlobalBox style={{ background: iconBackground }}>
          <img src={iconSrc} />
        </StyledGlobalBox>
        <Typography sx={{ color: '#151D48', fontFamily: 'Poppins', fontSize: '30px', fontStyle: 'normal', fontWeight: 600 }}>
        {label === 'New Student Enrolled' || label ==='Total No. of Center' || label === 'New Enrollment'? (
                value
              ) : (
                <>
                  ₹ {value}
                  
                </>
              )}
        </Typography>
      {/* </Stack> */}
      <Stack>
      <Typography>{label}</Typography>
     <Typography sx={{color:'#4079ED' , fontFamily:'Poppins' ,  fontSize:'12px' , fontStyle:'normal' , fontWeight:500}}>{text}</Typography>
      </Stack>
      </Stack>
    </MainCard>
  
 

  </>
  )
}
