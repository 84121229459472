import PropTypes from "prop-types";
import * as React from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  CardContent,
  Checkbox,
  CircularProgress,
  DialogActions,
  Grid,
  IconButton,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel,
  TextField,
  Toolbar,
  Tooltip,
  Typography,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  DialogContentText,
  Backdrop,
  Stack,
} from "@mui/material";
import AdapterDateFns from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker } from "@mui/material";
import { visuallyHidden } from "@mui/utils";

// project imports
import Chip from "../../components/ui-component/chip/Chip";
import MainCard from "../../components/ui-component/card/MainCard";
//import { useDispatch, useSelector } from 'store';
//import { getCustomers } from 'store/slices/customer';

// assets
import DeleteIcon from "@mui/icons-material/Delete";
import FilterListIcon from "@mui/icons-material/FilterListTwoTone";
import PrintIcon from "@mui/icons-material/PrintTwoTone";
import FileCopyIcon from "@mui/icons-material/FileCopyTwoTone";
import SearchIcon from "@mui/icons-material/Search";
import VisibilityTwoToneIcon from "@mui/icons-material/VisibilityTwoTone";
import EditTwoToneIcon from "@mui/icons-material/EditTwoTone";
import CancelTwoToneIcon from "@mui/icons-material/CancelTwoTone";
import CheckCircleTwoToneIcon from "@mui/icons-material/CheckCircleTwoTone";
import DoneTwoToneIcon from "@mui/icons-material/DoneTwoTone";
import { fDate } from "../../../utils/formatTime";
import EvaluationQuestionsDialog from "./EvaluationDialog";
import ConfirmationDialog from "../../components/ui-component/card/ConfirmationDialog";
import FilterListTwoToneIcon from "@mui/icons-material/FilterListTwoTone";
import ClearIcon from "@mui/icons-material/Clear";
// import { DemoItem } from '@mui/x-date-pickers/internals/demo';
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
// import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
// import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import Alert from "@mui/material/Alert";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import EvaluationPDF from "./EvaluationPDF";
import { useNavigate } from "react-router-dom";
import CancelIcon from "@mui/icons-material/Cancel";
import DemoFilter from "./DemoFilter";
import { useGetTokenOrLogout } from "../../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const DemosTable = () => {
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const navigate = useNavigate();
  const theme = useTheme();
  // const dispatch = useDispatch();

  const [order, setOrder] = React.useState("asc");
  const [orderBy, setOrderBy] = React.useState("calories");
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [search, setSearch] = React.useState("");
  // const [rows, setRows] = React.useState([]);
  const [cleared, setCleared] = React.useState(false);
  const [demos, setDemos] = React.useState([]);
  const [originalDemo, setOriginalDemo] = React.useState([]);
  const [isLoading, setIsLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedDemo, setSelectedDemo] = React.useState(null);
  const [cancellationReason, setCancellationReason] = React.useState("");
  const [dialogAction, setDialogAction] = React.useState("view");
  const [openEvaluationDialog, setOpenEvaluationDialog] = React.useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [openEvaluationPdfDialog, setOpenEvaluationPdfDialog] =
    React.useState(false);
  const [evaluationData, setEvaluationData] = React.useState({});
  const [filterCriteria, setFilterCriteria] = React.useState(null);
  const [isFilterAccordionExpanded, setFilterAccordionExpanded] =
    React.useState(false);

  const [filters, setFilters] = React.useState({
    // studentName: '',
    trainer: "",
    fromDate: "",
    toDate: "",
    deliveryMode: "",
    branch: "",
    status: "",
  });

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleFilterChange = (field, value) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: value,
    }));
  };

  const handleFilterAccordionChange = () => {
    setFilterAccordionExpanded((prevExpanded) => !prevExpanded);
  };

  const filteredDemos = demos.filter((demo) => {
    const fromDate = filters.fromDate ? new Date(filters.fromDate) : null;
    const toDate = filters.toDate ? new Date(filters.toDate) : null;

    const demoDate = new Date(demo.available_demo_date);

    const matchesFilter =
      // demo.student_name?.toLowerCase().includes(filters.studentName.toLowerCase()) &&
      demo.trainer_id?.first_name
        ?.toLowerCase()
        .includes(filters.trainer.toLowerCase()) &&
      (!fromDate || demoDate >= fromDate) &&
      (!toDate || demoDate <= toDate) &&
      (filters.deliveryMode
        ? demo.delivery_mode === filters.deliveryMode
        : true) &&
      (filters.branch
        ? demo.branch_id?.branch_name === filters.branch
        : true) &&
      (filters.status ? demo.status === filters.status : true);
    // ... (other filter conditions)

    return matchesFilter;
  });

  const handleClearDateFilter = (field) => {
    setFilters((prevFilters) => ({
      ...prevFilters,
      [field]: null,
    }));
  };

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleCompleteRequest = (evaluationAnswers) => {
    // Perform actions for completing the request
    // Include evaluation answers in the requestData or perform additional logic
    console.log("Evaluation Answers:", evaluationAnswers);
    console.log(selectedDemo);
    const demoId = selectedDemo.id;

    handlePostData(evaluationAnswers, demoId);
    updateDemoStatus(demoId, "completed", "");
  };

  const handlePostData = async (evaluationAnswers, demoId) => {
  
    let headers = {
     
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    try {
      const response = await fetch(`${API_ENDPOINT}studentstatus`, {
        method: "POST",
        headers,
        body: JSON.stringify({
          ...evaluationAnswers,
          user_id: userId,
          demo_id: demoId,
        }),
      });

      if (response.ok) {
        // Handle successful response
        console.log("Data posted successfully");
        showSnackbar("Evaluation posted successfully", "success");
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      showSnackbar("Failed to post evaluation", "error");
      navigate("/page500");
      return;
    }
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const startIndex = page * rowsPerPage;
  const endIndex = startIndex + rowsPerPage;
  const paginatedDemos = demos.slice(startIndex, endIndex);
  console.log(paginatedDemos);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event?.target.value, 10));
    setPage(0);
  };

  // open small popup
  const handleViewClick = async (demo, action) => {
    setOpenDialog(true);
    setSelectedDemo(demo);
    setDialogAction(action);


    if (action === "view" && demo.evaluation_id) {
      try {
        const response = await fetch(
          `${API_ENDPOINT}studentstatus/${demo.evaluation_id}`
      
        );
        if (response.ok) {
          const data = await response.json();

          setEvaluationData(data);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/page500");
      }
    } else {
      // If evaluation_id is null, set an empty array
      setEvaluationData({});
    }
  };

  console.log("evaluationData", evaluationData);

  const handleConfirmDemo = (demoId) => {
    updateDemoStatus(demoId, "confirmed", "");

    setOpenDialog(false);
  };

  const handleCancelRequest = (demoId) => {
    updateDemoStatus(demoId, "cancelled", cancellationReason);
    // setOpenDialog(false);
    setCancellationReason("");
  };

  const handleConfirmRequest = () => {
    setOpenDialog(false);
    setOpenEvaluationDialog(true);
  };

  const handleConfirmAction = (demoId) => {
    updateDemoStatus(demoId, "missed", "");
    setConfirmDialogOpen(false);
  };

  const updateDemoStatus = async (demoId, newStatus, cancellationReason) => {
 
    let headers = {
    
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    try {
      const response = await fetch(`${API_ENDPOINT}demo/${demoId}`, {
        method: "PUT",
        headers,
        body: JSON.stringify({
          status: newStatus,
          cancel_reason: cancellationReason,
          user_id: parseInt(userId),
        }),
      });

      if (response.ok) {
        const demoIndex = demos.findIndex((demo) => demo.id === demoId);

        if (demoIndex !== -1) {
          // Update the status of the demo in the state
          setDemos((prevDemos) => {
            const updatedDemos = [...prevDemos];
            updatedDemos[demoIndex] = {
              ...updatedDemos[demoIndex],
              status: newStatus,
            };
            return updatedDemos;
          });

          showSnackbar("Demo status updated successfully", "success");
          setTimeout(() => {
            window.location.reload();
          }, 1500);
        } else {
          console.error("Demo not found in state");
          showSnackbar("Failed to update demo status", "error");
        }
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      console.error("Error updating demo status:", error);
      showSnackbar("Failed to update demo status", "error");
      navigate("/page500");
    }
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - demos.length) : 0;

  React.useEffect(() => {
    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { Authorization: `Bearer ${token}` };
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getdemo/${userId}`, {
          headers,
        });
        if (response.ok) {
          const data = await response.json();

          const sortedDemos = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setDemos(sortedDemos);
          setOriginalDemo(sortedDemos);
          setIsLoading(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId]);

  const handleViewMore = (row) => {
    // Handle the "View More" action here
    // console.log('View More:', row);

    const url = `/demodetails/${row.id}`;
    const newState = { demodetails: row };

    // Open link in new tab
    const newTab = window.open(url, "_blank");

    // If new tab opened successfully, navigate with state data
    if (newTab) {
      newTab.onload = () => {
        newTab.history.replaceState(newState, "", url);
      };
    } else {
      // If new tab was blocked by browser, navigate with state data in current tab
      navigate(url, { state: newState });
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <DemoFilter
            demos={demos}
            setDemos={setDemos}
            isLoading={isLoading}
            setIsLoading={setIsLoading}
            showSnackbar={showSnackbar}
            originalDemo={originalDemo}
          />
        </Grid>
        <Grid item xs={12}>
          <MainCard content={false}>
            <TableContainer>
              <Table sx={{ minWidth: 750 }} aria-labelledby="tableTitle">
                <TableHead>
                  <TableRow>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      Enquiry Name
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      Trainer
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      Date
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      Slot
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      Delivery Mode
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      Demo Created By
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "#000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        textAlign: "center",
                      }}
                    >
                      Status
                    </TableCell>
                    {(userRole === "admin" || userRole === "trainer") && (
                      <TableCell
                        sx={{
                          color: "#000",
                          fontFamily: "Poppins",
                          fontSize: "18px",
                          fontStyle: "normal",
                          fontWeight: 600,
                          textAlign: "center",
                        }}
                      >
                        Actions
                      </TableCell>
                    )}
                  </TableRow>
                </TableHead>

                {isLoading ? (
                  <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
                    <Stack direction="row" spacing={1}>
                      <Typography
                        variant="h4"
                        color="secondary"
                        fontWeight={800}
                        marginRight={2}
                      >
                        Fetching
                      </Typography>
                      <CircularProgress color="primary" />
                    </Stack>
                  </Backdrop>
                ) : paginatedDemos.length === 0 ? (
                  <Grid
                    item
                    xs={12}
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                    }}
                  >
                    <Typography variant="h5">No data available</Typography>
                  </Grid>
                ) : (
                  paginatedDemos.map((row, index) => (
                    <>
                      <TableBody>
                        <TableRow
                          hover
                          role="checkbox"
                          // aria-checked={isItemSelected}
                          tabIndex={-1}
                          key={index}
                          // selected={isItemSelected}
                          style={{
                            backgroundColor:
                              index % 2 === 0 ? "#f0f0f0" : "inherit",
                          }}
                        >
                          <TableCell
                            component="th"
                            // id={labelId}
                            align="center"
                            scope="row"
                            onClick={(event) =>
                              handleClick(event, row.student_name)
                            }
                            sx={{ cursor: "pointer" }}
                          >
                            <Typography
                              //variant="subtitle1"
                              sx={{
                                color:
                                  theme.palette.mode === "dark"
                                    ? "grey.600"
                                    : "grey.900",
                              }}
                              component="span"
                              variant="button"
                              style={{ cursor: "pointer", color: "blue" }}
                              onClick={() => handleViewMore(row)}
                            >
                              {row.enquiry_id?.first_name}{" "}
                              {row.enquiry_id?.last_name}
                            </Typography>
                          </TableCell>
                          <TableCell align="center">
                            {row.trainer_id?.first_name}
                          </TableCell>
                          <TableCell align="center">
                            {fDate(row.available_demo_date)}
                          </TableCell>
                          <TableCell align="center">{row.slot}</TableCell>
                          <TableCell align="center">
                            <Typography
                              variant="subtitle1"
                              sx={{
                                color:
                                  theme.palette.mode === "dark"
                                    ? "grey.600"
                                    : "grey.900",
                              }}
                            >
                              {" "}
                              {row.delivery_mode}{" "}
                            </Typography>
                            <Typography variant="caption">
                              {" "}
                              {row.branch_id?.branch_name}{" "}
                            </Typography>
                          </TableCell>

                          <TableCell align="center">
                            {row.created_by?.first_name}
                          </TableCell>

                          <TableCell align="center">
                            {row.status === "completed" && (
                              <Chip
                                label="Completed"
                                size="small"
                                chipcolor="secondary"
                              />
                            )}
                            {row.status === "pending" && (
                              <Chip
                                label="Pending"
                                size="small"
                                chipcolor="orange"
                              />
                            )}
                            {row.status === "confirmed" && (
                              <Chip
                                label="Confirmed"
                                size="small"
                                chipcolor="primary"
                              />
                            )}
                            {row.status === "missed" && (
                              <Chip
                                label="Missed"
                                size="small"
                                chipcolor="error"
                              />
                            )}
                            {row.status === "cancelled" && (
                              <Chip
                                label="Cancelled"
                                size="small"
                                chipcolor="error"
                              />
                            )}
                          </TableCell>

                          {(userRole === "admin" || userRole === "trainer") && (
                            <TableCell align="center" sx={{ pr: 3 }}>
                              {row?.status === "pending" && (
                                <>
                                  <IconButton
                                    color="primary"
                                    size="large"
                                    aria-label="view"
                                    onClick={() => handleViewClick(row, "view")}
                                  >
                                    <Tooltip title="View Demo">
                                      <VisibilityTwoToneIcon
                                        sx={{ fontSize: "1.3rem" }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                  {/* <IconButton
                                color="secondary"
                                size="large"
                                aria-label="confirm"
                                onClick={() => handleViewClick(row, "confirm")}
                              >
                                <Tooltip title="Confirm Demo">
                                  <CheckCircleTwoToneIcon
                                    sx={{ fontSize: "1.3rem" }}
                                  />
                                </Tooltip>
                              </IconButton>
                              <IconButton
                                color="error"
                                size="large"
                                aria-label="cancel"
                                onClick={() => handleViewClick(row, "cancel")}
                              >
                                <Tooltip title="Cancel Demo">
                                  <CancelTwoToneIcon
                                    sx={{ fontSize: "1.3rem" }}
                                  />
                                </Tooltip>
                              </IconButton> */}
                                </>
                              )}
                              {row?.status === "confirmed" && (
                                <>
                                  <IconButton
                                    color="primary"
                                    size="large"
                                    aria-label="view"
                                    onClick={() => handleViewClick(row, "view")}
                                  >
                                    <Tooltip title="View Demo">
                                      <VisibilityTwoToneIcon
                                        sx={{ fontSize: "1.3rem" }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                  <IconButton
                                    color="secondary"
                                    size="large"
                                    aria-label="view"
                                    onClick={() => handleViewClick(row, "done")}
                                  >
                                    <Tooltip title="Mark Demo">
                                      <DoneTwoToneIcon
                                        sx={{ fontSize: "1.3rem" }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                  <IconButton
                                    color="error"
                                    size="large"
                                    aria-label="cancel"
                                    onClick={() =>
                                      handleViewClick(row, "cancel")
                                    }
                                  >
                                    <Tooltip title="Cancel Demo">
                                      <CancelTwoToneIcon
                                        sx={{ fontSize: "1.3rem" }}
                                      />
                                    </Tooltip>
                                  </IconButton>
                                </>
                              )}
                              {(row?.status === "completed" ||
                                row.status === "missed" ||
                                row.status === "cancelled") && (
                                <IconButton
                                  color="primary"
                                  size="large"
                                  aria-label="view"
                                  onClick={() => handleViewClick(row, "view")}
                                >
                                  <Tooltip title="View Demo">
                                    <VisibilityTwoToneIcon
                                      sx={{ fontSize: "1.3rem" }}
                                    />
                                  </Tooltip>
                                </IconButton>
                              )}
                            </TableCell>
                          )}
                        </TableRow>

                        {emptyRows > 0 && (
                          <TableRow
                            style={{
                              height: 53 * emptyRows,
                            }}
                          >
                            <TableCell colSpan={6} />
                          </TableRow>
                        )}
                      </TableBody>
                    </>
                  ))
                )}
              </Table>
            </TableContainer>

            {/* table pagination */}
            <TablePagination
              rowsPerPageOptions={[10, 25, 50]}
              component="div"
              count={demos.length}
              rowsPerPage={rowsPerPage}
              page={page}
              onPageChange={handleChangePage}
              onRowsPerPageChange={handleChangeRowsPerPage}
            />
          </MainCard>
        </Grid>
      </Grid>

      {selectedDemo && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={openDialog}
          onClose={() => setOpenDialog(false)}
          sx={{
            "&>div:nth-of-type(3)": {
              "&>div": {
                maxWidth: 400,
              },
            },
          }}
        >
          <DialogTitle variant="h4">Demo Details</DialogTitle>
          <DialogContent>
            {selectedDemo && (
              <div>
                <Grid container spacing={2}>
                  <Grid item xs={12}>
                    <Typography variant="h5" component="div">
                      Student Name
                    </Typography>
                    <Typography
                      variant="subtitle"
                      fontWeight={700}
                      fontStyle="bold"
                    >
                      {selectedDemo?.enquiry_id?.first_name}{" "}
                      {selectedDemo?.enquiry_id?.last_name}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h5" component="div">
                      Trainer
                    </Typography>
                    <Typography
                      variant="subtitle"
                      fontWeight={700}
                      fontStyle="bold"
                    >
                      {selectedDemo.trainer_id?.first_name}{" "}
                      {selectedDemo.trainer_id?.last_name}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h5" component="div">
                      Date and Slot
                    </Typography>
                    <Typography
                      variant="subtitle"
                      fontWeight={700}
                      fontStyle="bold"
                    >
                      {fDate(selectedDemo.available_demo_date)}{" "}
                      {selectedDemo.slot}
                    </Typography>
                  </Grid>

                  <Grid item xs={12}>
                    <Typography variant="h5" component="div">
                      Location
                    </Typography>
                    <Typography
                      variant="subtitle"
                      fontWeight={700}
                      fontStyle="bold"
                    >
                      {selectedDemo.delivery_mode}{" "}
                      {selectedDemo.branch_id?.branch_name}
                    </Typography>
                  </Grid>
                  {selectedDemo?.note && (
                    <Grid item xs={12}>
                      <Typography variant="h5" component="div">
                        Note
                      </Typography>
                      <Typography
                        variant="subtitle"
                        fontWeight={700}
                        fontStyle="bold"
                      >
                        {selectedDemo?.note}
                      </Typography>
                    </Grid>
                  )}

                  {selectedDemo?.cancel_reason && (
                    <Grid item xs={12}>
                      <Typography variant="h5" component="div">
                        Cancellation Reason
                      </Typography>
                      <Typography
                        variant="subtitle"
                        fontWeight={700}
                        fontStyle="bold"
                      >
                        {selectedDemo?.cancel_reason}
                      </Typography>
                    </Grid>
                  )}
                  {selectedDemo?.reschedule_reason && (
                    <Grid item xs={12}>
                      <Typography variant="h5" component="div">
                        Reschedule reason
                      </Typography>
                      <Typography
                        variant="subtitle"
                        fontWeight={700}
                        fontStyle="bold"
                      >
                        {selectedDemo?.reschedule_reason}
                      </Typography>
                    </Grid>
                  )}
                </Grid>
              </div>
            )}
            {selectedDemo?.status === "completed" && (
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => {
                    setOpenDialog(false);
                    setOpenEvaluationPdfDialog(true);
                  }}
                >
                  View Evaluation
                </Button>
              </Box>
            )}
            {dialogAction === "confirm" && (
              <Box mt={2}>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() => handleConfirmDemo(selectedDemo.id)}
                >
                  Confirm Demo
                </Button>
              </Box>
            )}

            {dialogAction === "done" && (
              <div>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleConfirmRequest}
                  >
                    Mark Complete
                  </Button>

                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => {
                      setOpenDialog(false);
                      setConfirmDialogOpen(true);
                    }}
                  >
                    Mark Missed
                  </Button>
                </Box>
              </div>
            )}

            {dialogAction === "cancel" && (
              <div>
                <Box m={2}>
                  <TextField
                    label="Reason for Cancellation"
                    variant="outlined"
                    rows={10}
                    fullWidth
                    value={cancellationReason}
                    onChange={(e) => setCancellationReason(e.target.value)}
                  />
                </Box>
                <Box mt={2}>
                  <Button
                    variant="contained"
                    color="error"
                    onClick={() => handleCancelRequest(selectedDemo.id)}
                  >
                    Cancel Request
                  </Button>
                </Box>
              </div>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenDialog(false)}
              color="error"
              variant="contained"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <EvaluationQuestionsDialog
        selectedDemo={selectedDemo}
        open={openEvaluationDialog}
        onClose={() => setOpenEvaluationDialog(false)}
        onSubmit={(answers) => handleCompleteRequest(answers)}
      />

      <ConfirmationDialog
        open={isConfirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
        onConfirm={() => handleConfirmAction(selectedDemo.id)}
        message={`Are you sure you want to mark ${selectedDemo?.enquiry_id?.first_name} demo missed?`}
      />

      {openEvaluationPdfDialog && (
        <Dialog
          fullWidth
          maxWidth="md"
          open={openEvaluationPdfDialog}
          onClose={() => setOpenEvaluationPdfDialog(false)}
        >
          <DialogTitle variant="h4">Evaluation PDF</DialogTitle>
          <DialogContent>
            {Object.keys(evaluationData).length > 0 ? (
              <EvaluationPDF
                demoId={selectedDemo.id}
                studentName={selectedDemo?.enquiry_id?.first_name}
                trainerName={selectedDemo?.trainer_id.first_name}
                evaluationData={evaluationData}
              />
            ) : (
              <DialogContentText
                id="alert-dialog-description"
                style={{ textAlign: "center" }}
              >
                <CancelIcon color="error" style={{ fontSize: 60 }} />
                <br />
                No Evaluation Data Available
              </DialogContentText>
            )}
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => setOpenEvaluationPdfDialog(false)}
              color="error"
              variant="contained"
            >
              Close
            </Button>
          </DialogActions>
        </Dialog>
      )}

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default DemosTable;
