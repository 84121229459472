
import React, { useEffect, useState } from 'react'
import { Alert, Box, Button, Checkbox, Chip, Drawer, FormControlLabel, FormGroup, Grid, InputBase, Snackbar, Stack, TextareaAutosize, Typography } from "@mui/material";
import CustomSnackbar from '../../newTestPrep/components/ui-component/snackbar/CustomSnackbar';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EditticketDrawer({ open, onClose,ticket,setticketdata }) {
    const userId = localStorage.getItem("userId");
    const [showSection, setShowSection] = useState(null);
    const [issueDescription, setIssueDescription] = useState("");
    const [feedbackDescription, setfeedbackDescription] = useState("");
    const [isUrgent, setIsUrgent] = useState(false);
    const [issueDescriptionError, setIssueDescriptionError] = useState(false);
    const [feedbackDescriptionError, setFeedbackDescriptionError] = useState(
      false
    );
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");

    const showSnackbar = (message, severity) => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setSnackbarOpen(true);

  };

    useEffect(() => {
        if (ticket) {
            setShowSection(ticket.type_of_ticket || null)
            setIssueDescription(ticket.issue_description || null);
            setfeedbackDescription(ticket.feedback_description || null);
            setIsUrgent(ticket.ticket_priority || false);
        }
    }, [ticket]);


 
    
      const handleSubmit = async () => {
        const id = ticket.id
        if (ticket.type_of_ticket === "issue" && !issueDescription) {
          setIssueDescriptionError(true);
          return;
        }
        if (ticket.type_of_ticket === "feedback" && !feedbackDescription) {
          setFeedbackDescriptionError(true);
          return;
        }
    
        const formData = {
          type_of_ticket: showSection,
          // screenshot_1: null, // You can add logic to handle file uploads here
          // screenshot_2: null, // You can add logic to handle file uploads here
          issue_description: issueDescription || null,
    
          feedback_description: feedbackDescription || null,
          ticket_priority: isUrgent ? true : false,
    
          user_id: parseInt(userId),
        };
        try {
          const response = await fetch(`${API_ENDPOINT}ticket/${id}`, {
            method: "PUT",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify(formData),
          });
    
          if (response.ok) {
            showSnackbar('Ticket edited successfully', 'success');
            setticketdata(prevData => prevData.map(item => item.id === id ? {...item, ...formData} : item));

            setTimeout(() => {
              onClose();
            }, 2000);
            // setShowSection(null);
            setIssueDescription("");
            setfeedbackDescription("");
            setIsUrgent(false);
            setIssueDescriptionError(false);
            setFeedbackDescriptionError(false);
            // Handle success
            console.log("Ticket submitted successfully!");
          } else {
            // Handle errors
            showSnackbar('Failed to edit ticket', 'error')
            console.error("Failed to submit ticket");
          }
        } catch (error) {
          console.error("Error:", error);
          showSnackbar('An error occurred while editing the ticket', 'error')
        }
      };
    
      const handleCloseSnackbar = () => {
        setSnackbarOpen(false);
      };
  
  return (
    <Drawer  anchor="right" open={open} onClose={onClose}>
    <Stack width='500px' >
      
    <Stack display='flex' alignItems='center' justifyContent='center'>
        <img src="\think.png" width='50px' />
                  
        
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "24px",
                      fontWeight: 600,
                    }}
                  >
                    Edit Ticket
                  </Typography>
            
                <Stack display='flex' flexDirection='row' >
                <Chip
                  onDelete={() => {}}
                  deleteIcon={<Checkbox   checked={showSection === "issue"} disabled='true' />}
                  label="Report An Issue"
                  clickable
                  color="primary"
                  style={{
                    backgroundColor: "#ffebee",
                    color: "#b71c1c",
                    width: "200px",
                    height: "40px",
                    fontSize: "14px",
                    fontWeight: 600,
                    borderRadius: "14px",
                  }}
                />
         
        
           
                <Chip
                  onDelete={() => {}}
                  deleteIcon={<Checkbox   checked={showSection === "feedback"} disabled='true'/>}
                  label="Provide Feedback"
                  clickable
                  color="primary"
                  style={{
                    backgroundColor: "#e8f5e9",
                    color: "#1b5e20",
                    width: "200px",
                    height: "40px",
                    fontSize: "14px",
                    fontWeight: 600,
                    borderRadius: "14px",
                    marginLeft:'7px'
                  }}
                />
           </Stack>
        
        </Stack>
    
              {showSection === "feedback" && (
              <>
              <Stack m={2} mt={4}>
         
                  <Typography
                    sx={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: 500 }}
                  >
                    We are pleased by your feedback. Kindly Specify:*
                  </Typography>
              
        
                  <TextareaAutosize
                    aria-label="empty textarea"
                    style={{
                      // width: "100%",
                      minHeight: "100px",
                      borderRadius: "14px",
                      padding:'10px',
                      marginTop:'10px'
                    }}
                    value={feedbackDescription}
                    onChange={(e) => {
                      setfeedbackDescription(e.target.value);
                      setFeedbackDescriptionError(false); // Reset the error when typing
                    }}
                  />
                  {feedbackDescriptionError && (
                    <Typography variant="caption" style={{ color: "red" }}>
                      Please specify the Feedback....
                    </Typography>
                  )}
            
        <Box sx={{ display: 'flex', justifyContent:'flex-end', margin:'10px', padding:'10px', position: 'absolute', bottom: 24, right: 0, }}>
          <Button
          variant="outlined"
            style={{
              // background: '#019E6F',
              borderColor:'green',
              color: 'black',
              fontSize: '18px',
              fontWeight: 500,
              borderRadius: '10px',
              height: '40px',
              marginRight:'10px'
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            style={{
              background: '#019E6F',
              color: 'white',
              fontSize: '18px',
              fontWeight: 500,
              borderRadius: '10px',
              height: '40px',
            }}
            onClick={handleSubmit}
          >
          Submit Ticket
          </Button>
        </Box>
        </Stack>
              </>
            )}
        
         
            {showSection === "issue" && (
              <>
          <Stack m={2} mt={4}>
                  <Typography
                    sx={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: 400 }}
                  >
                    We Apologies for the inconvenience. Kindly specify your issue:*
           </Typography>
        
                  <TextareaAutosize
                    aria-label="empty textarea"
                    style={{
                      width: "100%",
                      minHeight: "100px",
                      borderRadius: "14px",
                      padding:'10px'
                    }}
                    value={issueDescription}
                    onChange={(e) => {
                      setIssueDescription(e.target.value);
                      setIssueDescriptionError(false);
                    }}
                    required
                  />
                  {issueDescriptionError && (
                    <Typography variant="caption" style={{ color: "red" }}>
                      Please specify the issue description...
                    </Typography>
                  )}
            
        
                  <Typography
                    sx={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: 400, mt:'14px' }}
                  >
                    Is It Urgent :
                  </Typography>
                  <FormGroup style={{ display: "flex", flexDirection: "row" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isUrgent}
                          onChange={(e) => setIsUrgent(e.target.checked)}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!isUrgent}
                          onChange={(e) => setIsUrgent(!e.target.checked)}
                        />
                      }
                      label="No"
                    />
                  </FormGroup>
        
        
        
                  {/* <Typography
                    sx={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: 400 , marginTop:'14px'}}
                  >
                    Kindly Upload concerned attachment:
                  </Typography> */}
            
        
          
                {/* <Stack display='flex' alignItems='center' justifyContent='space-between' flexDirection='row' marginTop='10px'>
                  <InputBase
                    type="file"
                    inputProps={{
                      accept: "image/jpeg, image/jpg, image/png",
                      style: { outline: "none", border: "none" },
                    }}
                    fullWidth
                  />
            
        
           
                  <InputBase
                    type="file"
                    inputProps={{
                      accept: "image/jpeg, image/jpg, image/png",
                      style: { outline: "none", border: "none" },
                    }}
                    fullWidth
                  />
        </Stack> */}
        <Box sx={{ display: 'flex', justifyContent:'flex-end', margin:'10px', padding:'10px' , position: 'absolute', bottom: 24, right: 0,}}>
          <Button
          variant="outlined"
            style={{
              // background: '#019E6F',
              borderColor:'green',
              color: 'black',
              fontSize: '18px',
              fontWeight: 500,
              borderRadius: '10px',
              height: '40px',
              marginRight:'10px'
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            style={{
              background: '#019E6F',
              color: 'white',
              fontSize: '18px',
              fontWeight: 500,
              borderRadius: '10px',
              height: '40px',
            }}
            onClick={handleSubmit}
          >
          Submit Ticket
          </Button>
        </Box>
        </Stack>
        </>
        
              
            )}
        
        <CustomSnackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          severity={snackbarSeverity}
          />
            </Stack>
         </Drawer>
  )
}
