import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  IconButton,
  Grid,
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
  FormControl,
  Select,
  MenuItem,
  Snackbar,
} from "@mui/material";
import CustomSnackbar from "../../../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
export default function ClassextentionForm({ selecteddata, onClose }) {
  const enquiryId = selecteddata?.enquiry_id;
  const clasId = selecteddata.class_id?.id;
  const packageId = selecteddata.package_id?.id;
  const studentId = selecteddata?.student_id;

  const userId = localStorage.getItem("userId");

  console.log("selectedData", selecteddata);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [statusname, setstatusname] = useState("pending");
  const [sessions, SetSessions] = useState(null);
  const [selectedType, setSelectedType] = useState("fullPayment");
  const [paymentmodes, setPaymentModes] = useState([]);
  const [paymentMode, setPaymentMode] = useState(null);
  const [isSubmitDisabled, setIsSubmitDisabled] = useState(true);
  const [classextension, setClassextention] = useState(true);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleChange = (event) => {
    setstatusname(event.target.value);
  };

  const handlesessionchange = (e) => {
    SetSessions(e.target.value);
  };

  const Total_Amount = 350 * sessions;

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}paymentmode`);
        if (response.ok) {
          const data = await response.json();
          const activePayments = data.filter((item) => item.is_active === true);
          console.log(activePayments);
          setPaymentModes(activePayments);

          if (activePayments?.length > 0) {
            setPaymentMode(activePayments[0].id);
          }
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log(paymentmodes);
  const handlePaymentModeChange = (event) => {
    setPaymentMode(event.target.value);
  };

  useEffect(() => {
    // Check if all required fields are filled
    if (sessions && paymentMode && statusname) {
      setIsSubmitDisabled(false);
    } else {
      setIsSubmitDisabled(true);
    }
  }, [sessions, paymentMode, statusname]);

  const handleSubmit = () => {
    const formdata = {
      enquiry_id: enquiryId,
      student_id: studentId,
      class_id: clasId,
      package_id: packageId,
      number_of_extended_sessions: parseInt(sessions),
      total_amount: Total_Amount,
       payment_type:selectedType,
      payment_mode_id: paymentMode,
      payment_status: statusname,
      is_class_extension: classextension,
      user_id: parseInt(userId),
    };

    fetch(`${API_ENDPOINT}stdclassextension`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formdata),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Class extend Successfully");

          showSnackbar("Class extend Successfully");

          setTimeout(() => {
            onClose();
          }, 1500);
        } else {
          console.error("Error to extend Class");
          // Handle other errors
          showSnackbar("Error to extend Class", "error");
          setTimeout(() => {
            onClose();
          }, 1500);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        setTimeout(() => {
          onClose();
        }, 1500);
      });
  };
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 800, fontFamily: "Poppins" }}
          >
            How many session to extend
          </Typography>
          <TextField
            fullWidth
            value={sessions}
            onChange={handlesessionchange}
            sx={{ mb: 1, width: "100%" }}
            type="number"
            InputProps={{
              style: { borderRadius: "15px" },
            }}
          />
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 800, fontFamily: "Poppins" }}
          >
            Total Amount To Be Paid : {Total_Amount} Rs
          </Typography>
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 800, fontFamily: "Poppins" }}
          >
            Payment Type
          </Typography>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={selectedType} // Controlled by state
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <FormControlLabel
                value="fullPayment"
                control={<Radio />}
                label="Full Payment"
              />
            </RadioGroup>
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 800, fontFamily: "Poppins" }}
          >
            Payment mode
          </Typography>
          <FormControl>
            <RadioGroup
              row
              aria-labelledby="demo-row-radio-buttons-group-label"
              name="row-radio-buttons-group"
              value={paymentMode}
              onChange={handlePaymentModeChange}
            >
              {paymentmodes.map((mode, index) => (
                <FormControlLabel
                  key={mode.id}
                  value={mode.id}
                  control={<Radio />}
                  label={mode.payment_mode}
                />
              ))}
            </RadioGroup>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <FormControl fullWidth>
            <Typography
              sx={{ fontSize: "16px", fontWeight: 800, fontFamily: "Poppins" }}
            >
              Payment Status
            </Typography>
            <Select
              value={statusname}
              style={{ borderRadius: "15px" }}
              onChange={handleChange}
            >
              <MenuItem value="pending">Pending</MenuItem>
            </Select>
          </FormControl>
        </Grid>
        <Grid item xs={12} lg={12} textAlign="center">
          <Button
            variant="contained"
            color="secondary"
            disabled={isSubmitDisabled}
            onClick={handleSubmit}
          >
            Submit Form
          </Button>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
