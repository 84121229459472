import { Box, Grid, Stack, Typography } from '@mui/material';
import React from 'react'
import MainCard from '../../components/Maincard';
import styled from 'styled-components';

export default function RevenueCard({data,length}) {
    const StyledGlobalBox = styled(Box)`
   
    border-radius:50%;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    img {
      max-width: 100%;
      max-height: 100%;
    }
  `;

const lgvalue= length===5?2.3 : 3
  return (
  <>
  <Grid item xs={9} lg={lgvalue} md={6} sm={6} >
   
    <MainCard style={{ background: data.background }}>
       <Stack spacing={1}>
      {/* <Stack display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'> */}
        <StyledGlobalBox style={{ background: data.iconBackground }}>
          <img src={data.iconSrc} />
        </StyledGlobalBox>
        <Typography sx={{ color: '#151D48', fontFamily: 'Poppins', fontSize: '30px', fontStyle: 'normal', fontWeight: 600 }}>
        {data.label === 'New Student Enrolled' || data.label ==='Total No. of Center' || data.label === 'New Enrollment'? (
                data.value
              ) : (
                <>
                  ₹ {data.value}
                  k
                </>
              )}
        </Typography>
      {/* </Stack> */}
      <Stack>
      <Typography>{data.label}</Typography>
     <Typography sx={{color:'#4079ED' , fontFamily:'Poppins' ,  fontSize:'12px' , fontStyle:'normal' , fontWeight:500}}>{data.text}</Typography>
      </Stack>
      </Stack>
    </MainCard>
  
  </Grid>

  </>
  )
}
