// Author : Ayesha Shaikg
//  Date : 02 aug 2024

import { useTheme } from "@emotion/react";
import {
  Autocomplete,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
  MenuItem,
  Radio,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React from "react";

export default function GreGmatSatComp({
  handleCriteria,
  formData,
  handleChange,
  classType,
  trainerName,
  loading,
  handleChangeTrainer,
  trainerSlots,
  setSelectedClassData,
  trainerList,
  handleBookDemo,
  ButtonName,
  rescheduleReason,
  rescheduleReasons,
  setRescheduleReason,
}) {
  const theme = useTheme();
  return (
    <>
      <Grid item xs={12} lg={12}>
        <Button
          onClick={() => handleCriteria("date")}
          style={{
            color: classType === "date" ? "white" : "black",
            background: classType === "date" ? "#2196F3" : "",
            border: classType !== "date" ? "0.3px solid #A6A6A6" : "",
          }}
        >
          Search Demo Dates
        </Button>

        <Button
          onClick={() => handleCriteria("manual")}
          style={{
            marginLeft: "10px",
            color: classType === "manual" ? "white" : "black",
            background: classType === "manual" ? "#2196F3" : "",
            border: classType !== "manual" ? "0.3px solid #A6A6A6" : "",
          }}
        >
          Manual Demo Time
        </Button>
      </Grid>

      {classType === "date" ? (
        <>
          {trainerSlots.length !== 0 && (
            <Grid item xs={12}>
              <Typography variant="h4">
                Demo Slots Available for {formData.demo_date}
              </Typography>
            </Grid>
          )}

          <Grid item xs={12} textAlign="center">
            {loading && <CircularProgress />}
          </Grid>

          <Grid
            item
            xs={12}
            sx={{
              display: "flex !important",
              flexDirection: "column !important",
              marginBottom: "1rem",
              marginTop: "1rem",
              width: "100%",
            }}
          >
            {trainerSlots.length === 0 ? (
              <Typography textAlign="center">
                No slots found for the selected criteria.
              </Typography>
            ) : (
              <Grid container spacing={2}>
                {trainerSlots.map((slot) => (
                  <Grid item xs={6} key={slot.id} lg={4} md={4} sm={6}>
                    <Card
                      sx={{
                        padding: "16px",
                        background:
                          theme.palette.mode === "dark"
                            ? theme.palette.dark.main
                            : theme.palette.grey[50],
                        border: "1px solid",
                        borderColor:
                          theme.palette.mode === "dark"
                            ? theme.palette.dark.main
                            : theme.palette.grey[100],
                        "&:hover": {
                          border: `1px solid${theme.palette.primary.main}`,
                        },
                      }}
                    >
                      <CardContent>
                        <Typography variant="h4">
                          Class Name: {slot?.class_name}
                        </Typography>
                        <Typography variant="h4">
                          Trainer: {slot.trainer_id?.first_name}
                        </Typography>
                        <Typography variant="body2">
                          Slot: {slot.slots}
                        </Typography>
                        <FormControlLabel
                          control={
                            <Radio
                              checked={formData.selectedSlot === slot.class_id}
                              onChange={() => {
                                handleChange({
                                  target: {
                                    name: "selectedSlot",
                                    value: slot.class_id,
                                  },
                                });

                                const selectedClassData = {
                                  slot: slot.slots,
                                  trainer_id: slot.trainer_id.id,
                                };
                                setSelectedClassData(selectedClassData);
                              }}
                              value={slot.class_id}
                              name="selectedSlot"
                            />
                          }
                          label="Select"
                        />
                      </CardContent>
                    </Card>
                  </Grid>
                ))}
              </Grid>
            )}
          </Grid>
        </>
      ) : (
        <>
          <Grid item xs={12}>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={4}>
                <Typography>
                  Select Trainer{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1em",
                    }}
                  >
                    *
                  </span>
                </Typography>

                <Autocomplete
                  size="small"
                  disablePortal
                  value={trainerName}
                  onChange={handleChangeTrainer}
                  options={trainerList}
                  getOptionLabel={(option) =>
                    `${option.first_name ? option.first_name : ""} ${
                      option.last_name ? option.last_name : ""
                    }`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={12} lg={4}>
                <Typography>
                  Start Time{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1em",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <TextField
                  value={formData.fromTime}
                  name="fromTime"
                  onChange={handleChange}
                  size="small"
                  fullWidth
                  type="time"
                />
              </Grid>

              <Grid item xs={12} lg={4}>
                <Typography>
                  Duration{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1em",
                    }}
                  >
                    *
                  </span>
                </Typography>

                <Select
                  fullWidth
                  size="small"
                  name="duration"
                  onChange={handleChange}
                  value={formData.duration}
                >
                  <MenuItem value={30}>30 Minutes</MenuItem>
                  <MenuItem value={60}>60 Minutes</MenuItem>
                  <MenuItem value={90}>90 Minutes</MenuItem>
                </Select>
              </Grid>
            </Grid>
          </Grid>
        </>
      )}

      {ButtonName === "Reschedule Demo" && (
        <Grid item xs={12} lg={12}>
          <FormControl fullWidth>
            <Typography>
              Reason for reschedule{" "}
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1em",
                }}
              >
                *
              </span>
            </Typography>
            <Select
              size="small"
              name="rescheduleReason"
              value={rescheduleReason}
              onChange={(event) => setRescheduleReason(event.target.value)}
            >
              {rescheduleReasons.map((item) => (
                <MenuItem key={item.id} value={item.id}>
                  {item.reschedule_reason}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
      )}

      {/* Additional note */}
      <Grid item xs={12}>
        <Typography>Note (Optional)</Typography>
        <TextField
          name="note"
          placeholder="Type Anything..."
          variant="outlined"
          fullWidth
          value={formData.note}
          onChange={handleChange}
          multiline
          rows={4}
        />
      </Grid>

      {/* book demo button base on date or manual  */}
      {classType === "date" ? (
        <Grid item xs={12} textAlign="end">
          <Button
            variant="contained"
            color="primary"
            onClick={handleBookDemo}
            disabled={!formData.selectedSlot}
          >
            {ButtonName}
          </Button>
        </Grid>
      ) : (
        <Grid item xs={12} textAlign="end">
          <Button
            variant="contained"
            color="primary"
            onClick={handleBookDemo}
            disabled={
              formData.fromTime === null ||
              formData.duration === null ||
              trainerName === null
            }
          >
            {ButtonName}
          </Button>
        </Grid>
      )}
    </>
  );
}
