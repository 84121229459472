import {
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import MainCard from "../../../components/Maincard";
import CollapsibleForm from "../accounts/Paymentfilter";
import AccountsCard from "../../components/ui-component/card/account/AccountsCard";
import { fDate } from "../../../utils/formatTime";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import CashCollectionFilter from "./CashCollectionFilter";
import { useGetTokenOrLogout } from "../../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const PATH_URL = process.env.REACT_APP_API_UPLOADS;
function CashCollections() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();


  const userId = localStorage.getItem("userId");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [cashTransactionCount, setCashTransactionCount] = useState(null);
  const [cashRevenue, setCashRevenue] = useState(null);
  const [onlineTransactionCount, setOnlineTransactionCount] = useState(null);
  const [onlineRevenue, setOnlineRevenue] = useState(null);
  const [totalTransactionCount, setTotalTransactionCount] = useState(null);
  const [totalRevenue, setTotalRevenue] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [selectedRows, setSelectedRows] = useState([]);

  const [originalData, setOriginalData] = useState([]);
  const handleRowSelect = (row, isSelected) => {
    if (isSelected) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(
        selectedRows.filter((selectedRow) => selectedRow !== row)
      );
    }
  };

  const [openUploadDialog, setOpenUploadDialog] = useState(false);
  const [selectedFile, setSelectedFile] = useState(null);

  const handleFileChange = (event) => {
    setSelectedFile(event.target.files[0]);
  };

  const handleUpload = () => {
    // Implement your upload logic here using selectedFile
    // For example, you can send the file to your server using an API call
    console.log(selectedFile);
    // Close the dialog after uploading
    setOpenUploadDialog(false);
  };

  const handleAddUpload = async () => {
    if (selectedFile) {
      const formData = new FormData();
      formData.append("file", selectedFile);
      formData.append("user_id", userId);

      // Check if selectedRows is not empty before appending it
      if (selectedRows.length > 0) {
        selectedRows.forEach((row) => {
          formData.append("provesional_id[]", row); // Append each selected row to the array
        });
      } else {
        // Handle case where selectedRows is empty (optional)
        console.log("No rows selected for upload.");
        showSnackbar("No rows selected for upload", "error");
        return;
      }

      try {
        const response = await fetch(
          `${API_ENDPOINT}uploaddepositslip/updatedepositslip`,
          {
            method: "POST",
            body: formData,
          }
        );

        const result = await response.json();

        if (response.status === 201) {
          console.log("Upload successful:");
          showSnackbar("Upload successful.", "success");

          setOpenUploadDialog(false);

          // Filter out uploaded rows from the payments array
          const updatedPayments = payments.filter(
            (payment) => !selectedRows.includes(payment.id)
          );
          setPayments(updatedPayments);

          // Clear selectedRows state after successful upload
          setSelectedRows([]);
        } else {
          console.error("Upload failed. Server returned:", response.statusText);
          showSnackbar(
            `Upload stopped. Server returned:,${response.statusText}`,
            "error"
          );
        }
      } catch (error) {
        console.error("Error uploading file:", error);
        console.log("Server response:");
        showSnackbar("Error uploading file", "error");
      }
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);

    const fetchData = async () => {
      const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
     
    };

      try {
        let url = "";
        // if (!filterCriteria) {
        //   url = `${API_ENDPOINT}route2.0/cashpayments/${userId}`;
        // } else {
        //   url = `${API_ENDPOINT}route2.0/fetchcashpayment/${userId}`;
        // }

        url = `${API_ENDPOINT}route2.0/cashpayments/${userId}`;
        const response = await fetch(url,{headers});
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }

        const data = await response.json();
        if(data.length===0){
          showSnackbar("Could'nt find any Cash Payments for past 30 days", 'error')
          setIsLoading(false);
        }
        else{
          const sortedData = data.sort(
            (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
          );
  
          setPayments(sortedData);
       
          setOriginalData(sortedData);
          setIsLoading(false);
        }

        const sortedData = data.sort(
          (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
        );

        setPayments(sortedData);
        setIsLoading(false);
        setOriginalData(sortedData);
      } catch (error) {
        if (error.message.includes("401")) {
          navigate("/page401");
        } else if (error.message.includes("403")) {
          navigate("/page403");
        } else if (error.message.includes("404")) {
          navigate("/page404");
        }  else if (error.message.includes("440")) {
          navigate("/page440");
        } 
        else {
          navigate("/page500");
        }
        setIsLoading(false);
      }
    };

    fetchData();
  }, [userId]);

  // View deposite Slip
  const handleViewDepositSlip = (filePath) => {
    window.open(`${PATH_URL}depositSlip/${filePath}`, "_blank");
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <CashCollectionFilter
            setPayments={setPayments}
            originalData={originalData}
            isLoading={isLoading}
            showSnackbar={showSnackbar}
            setIsLoading={setIsLoading}
          />
        </Grid>

        <Grid item xs={12} lg={12} sm={12}>
          <Button
            variant="contained"
            sx={{ borderRadius: "15px", padding: "10px" }}
            onClick={() => {
              if (selectedRows.length === 0) {
                // Show popup message for empty selectedRows
                showSnackbar(
                  "Please select at least one cash payment.",
                  "error"
                );
              } else {
                setOpenUploadDialog(true);
              }
            }}
          >
            Mass Upload
          </Button>
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{ borderRadius: "15px", padding: "10px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell></TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Enquiry Details
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Branch
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Date
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Amount Collected
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Collected By
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.length === 0 ? (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">No data available</Typography>
              </Grid>
            ) : (
              payments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f0f0f0" : "inherit",
                      width: "6%",
                    }}
                  >
                    <TableCell>
                      <Checkbox
                        checked={selectedRows.includes(row.id)}
                        disabled={row?.collected_status !== "received"}
                        onChange={(event) =>
                          handleRowSelect(row.id, event.target.checked)
                        }
                      />
                    </TableCell>
                    <TableCell
                      sx={{
                        color: "blue",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "none",
                        },
                      }}
                      onClick={() =>
                        window.open(
                          `/enquirydetails/${row.enquiry_id.id}`,
                          "_blank"
                        )
                      }
                    >
                      {`${
                        row?.enquiry_id?.first_name
                          ? row?.enquiry_id?.first_name
                          : ""
                      } ${
                        row?.enquiry_id?.last_name
                          ? row?.enquiry_id?.last_name
                          : ""
                      }`}{" "}
                      <br />{" "}
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          color: "#364152",
                        }}
                      >
                        {row.enquiry_id?.mobile}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {row?.enquiry_id?.branch_id?.branch_name}
                    </TableCell>
                    <TableCell>{fDate(row?.payment_date)}</TableCell>
                    <TableCell>{row?.amount_paid}</TableCell>
                    <TableCell>{row?.created_by?.first_name}</TableCell>
                    <TableCell>
                      {row?.collected_status === "received" && (
                        <Chip label="Received" color="error" />
                      )}
                      {row?.collected_status === "deposited" && (
                        <Chip label="Deposited" color="warning" />
                      )}
                      {row?.collected_status === "confirmed" && (
                        <Chip label="Confirmed" color="primary" />
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.collected_status === "received" && (
                        <Button
                          variant="contained"
                          color="error"
                          onClick={() => {
                            setSelectedRows((prevSelectedRows) => {
                              // Check if the ID is already in the selectedRows array
                              if (!prevSelectedRows.includes(row.id)) {
                                // If not, add the ID to the array
                                return [...prevSelectedRows, row.id];
                              }
                              // If the ID is already selected, return the current array without changes
                              return prevSelectedRows;
                            });
                            setOpenUploadDialog(true);
                          }}
                        >
                          Upload Deposit Slip
                        </Button>
                      )}

                      {row?.collected_status === "deposited" && (
                        <Button
                          variant="contained"
                          color="secondary"
                          onClick={() =>
                            handleViewDepositSlip(
                              row?.deposit_slip_id?.file_uploaded
                            )
                          }
                        >
                          View Deposit Slip
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={payments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      <Dialog
        open={openUploadDialog}
        onClose={() => setOpenUploadDialog(false)}
      >
        <DialogTitle variant="h4">Upload Deposit Slip</DialogTitle>
        <DialogContent>
          <TextField type="file" onChange={handleFileChange} />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpenUploadDialog(false)}>Cancel</Button>
          <Button onClick={handleAddUpload} variant="contained">
            Upload
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CashCollections;
