import { Backdrop, Box, Button, Chip, CircularProgress, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import CustomSnackbar from '../../components/ui-component/snackbar/CustomSnackbar';
import { useNavigate } from 'react-router-dom';
import { fDate } from '../../../utils/formatTime';
import { useGetTokenOrLogout } from '../../../utils/token';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function StudentLeaveRequests() {
    const navigate = useNavigate();
    const getTokenOrLogout = useGetTokenOrLogout();

  const userId = localStorage.getItem("userId");
  const [isLoading, setIsLoading] = useState(false);
  const [leaveRequests, setLeaveRequests] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

 

  const handleUpdateStatus = (leaveId, newStatus) => {
    // Update the status locally in the state
    const updatedPayments = leaveRequests.map((leave) =>
      leave.id === leaveId ? { ...leave, leave_status: newStatus } : leave
    );
    setLeaveRequests(updatedPayments);

    // Send a request to the server to update the status
    fetch(`${API_ENDPOINT}route2.0/updateleaverequest/${leaveId}`, {
      method: 'PUT',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ leave_status: newStatus, user_id: parseInt(userId) }),
    })
      .then((response) => {
        if (response.ok) {
          showSnackbar('Leave status updated successfully', 'success');
          // Remove the row from state if the status is updated successfully
         // const updatedPayments = leaveRequests.filter(payment => payment.id !== leaveId);
         // setLeaveRequests(updatedPayments);

        } else {
          showSnackbar('Failed to update leave status', 'error');
          throw new Error('Failed to update leave status');
        }
        // Handle successful response if needed
      })
      .catch((error) => {
        console.error('Error updating leave status:', error);
        showSnackbar('Error updating leave status', 'error');
        // Revert the status locally if the server update fails
        setLeaveRequests(leaveRequests);
      });
  };



  useEffect(() => {
    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/showleaverequest/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();

          const sortedData = data.sort(
                    (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
                  );
                  setLeaveRequests(sortedData);
                  console.log(sortedData);
                  setIsLoading(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch {
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId]);
  return (
    <>
    <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
      <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
        Fetching
      </Typography>
      <CircularProgress color="primary" />
    </Backdrop>

    <Grid container spacing={2} mb={2}>
      <Grid item xs={12} lg={12} sm={12} md={12}>
      <Typography
            sx={{
              fontFamily: "Poppins",
              marginLeft: "5px",
              fontSize: "24px",
              fontWeight: 700,
            }}
          >
            Student Leave Requests
          </Typography>
      </Grid>
    </Grid>

    <TableContainer
      component={Paper}
      sx={{ borderRadius: "15px", padding: "10px" }}
    >
      <Table>
        <TableHead>
          <TableRow>
            <TableCell
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              Enquiry Details
            </TableCell>
            <TableCell
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              Class Details
            </TableCell>
            <TableCell
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              Branch
            </TableCell>
            <TableCell
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              Number of Days
            </TableCell>
            <TableCell
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              Leave From Date
            </TableCell>
            <TableCell
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
             Leave To Date
            </TableCell>
            <TableCell
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              Request Date
            </TableCell>
            <TableCell
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              Status
            </TableCell>
            <TableCell
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              Action
            </TableCell>
            <TableCell
              sx={{
                color: "#000",
                fontFamily: "Poppins",
                fontSize: "18px",
                fontStyle: "normal",
                fontWeight: 600,
              }}
            >
              Approved/Denied By
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {leaveRequests.length === 0 ? (
            <Grid
              item
              xs={12}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Typography variant="h5">No data available</Typography>
            </Grid>
          ) : (
            leaveRequests
              .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
              .map((row, index) => (
                <TableRow
                  key={index}
                  style={{
                    backgroundColor: index % 2 === 0 ? "#f0f0f0" : "inherit",
                    width: "6%",
                  }}
                >
                  <TableCell
                    sx={{
                      
                      fontFamily: "Poppins",
                      fontSize: "16px",
                      fontStyle: "normal",
                      fontWeight: 600,
                     
                    }}
                  >
                    {`${
                      row?.enquiry_id?.first_name
                        ? row?.enquiry_id?.first_name
                        : ""
                    } ${
                      row?.enquiry_id?.last_name
                        ? row?.enquiry_id?.last_name
                        : ""
                    }`}{" "}
                    <br />
                    {row.enquiry_id?.mobile && (
                      <Typography
                        sx={{
                          fontFamily: "Poppins",
                          fontSize: "14px",
                          fontStyle: "normal",
                          fontWeight: 400,
                          color: "#364152",
                        }}
                      >
                        {row.enquiry_id?.mobile}
                      </Typography>
                    )}
                  </TableCell>
                  <TableCell>{row?.class_id?.name}</TableCell>
                  <TableCell>{row?.related_branch?.branch_name}</TableCell>
                  <TableCell>{row?.no_of_days}</TableCell>
                  <TableCell>{fDate(row?.leave_date_from)}</TableCell>
                  <TableCell>{fDate(row?.leave_date_to)}</TableCell>
                  <TableCell>{fDate(row?.createdAt)}</TableCell>
                  <TableCell>
                    {row?.leave_status === 'accepted' && (
                        <Chip label="Accepted" color="secondary" />
                    )}
                    {row?.leave_status === 'denied' && (
                        <Chip label="Denied" color="primary" />
                    )}
                    {row?.leave_status === 'pending' && (
                        <Chip label="Pending" color="warning" />
                    )}
                    {row?.leave_status === 'cancelled' && (
                        <Chip label="Cancelled" color="error" />
                    )}
                    </TableCell>
                    <TableCell>
                    {row?.leave_status === 'pending' && (
                        <>
                        <Button color='secondary' variant="contained" onClick={() => handleUpdateStatus(row.id, 'accepted')}>Approve Request</Button>
                        <Box m={2} />
                        <Button color='error' variant="contained" onClick={() => handleUpdateStatus(row.id, 'denied')}>Deny Request</Button>
                        </>
                    )}
                    </TableCell>
                  <TableCell>{row?.approved_by?.first_name}</TableCell>
                 
                  <TableCell>
                    
                  </TableCell>
                  <TableCell></TableCell>
                </TableRow>
              ))
          )}
        </TableBody>
      </Table>
      <TablePagination
        rowsPerPageOptions={[5, 10, 25]}
        component="div"
        count={leaveRequests.length}
        rowsPerPage={rowsPerPage}
        page={page}
        onPageChange={handleChangePage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </TableContainer>

    
    <CustomSnackbar
      open={snackbarOpen}
      onClose={() => setSnackbarOpen(false)}
      message={snackbarMessage}
      severity={snackbarSeverity}
    />
  </>
  )
}

export default StudentLeaveRequests