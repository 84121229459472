import React, { useEffect, useState } from "react";
import MainCard from "../../components/ui-component/card/MainCard";
import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
  Typography,
} from "@mui/material";
import SubCard from "../../components/ui-component/card/SubCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import PhonelinkRingTwoToneIcon from "@mui/icons-material/PhonelinkRingTwoTone";
import PinDropTwoToneIcon from "@mui/icons-material/PinDropTwoTone";
import MailTwoToneIcon from "@mui/icons-material/MailTwoTone";
import DateRangeIcon from "@mui/icons-material/DateRange";
import LoginIcon from "@mui/icons-material/Login";
import VpnKeyIcon from "@mui/icons-material/VpnKey";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import DoubleArrowIcon from "@mui/icons-material/DoubleArrow";
import { fDate } from "../../../utils/formatTime";
import EvaluationQuestionsDialog from "./EvaluationDialog";
import ConfirmationDialog from "../../components/ui-component/card/ConfirmationDialog";
import EvaluationPDF from "./EvaluationPDF";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import CancelIcon from "@mui/icons-material/Cancel";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function DemoDetails() {
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const { demoId } = useParams();
  const location = useLocation();
  const navigate = useNavigate();
  const demoDetails = location.state?.demoDetails || null;
  //const leadId = leadDetails.id;
  const [demoData, setDemoData] = useState(demoDetails);

  const [isLoading, setIsLoading] = React.useState(false);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [selectedDemo, setSelectedDemo] = React.useState(null);
  const [cancellationReason, setCancellationReason] = React.useState("");
  const [dialogAction, setDialogAction] = React.useState("view");
  const [openEvaluationDialog, setOpenEvaluationDialog] = React.useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = React.useState(false);
  const [openEvaluationPdfDialog, setOpenEvaluationPdfDialog] =
    React.useState(false);
  const [evaluationData, setEvaluationData] = React.useState({});
  const [openCancelDialog, setOpenCancelDialog] = useState(false);

  const [studentDetailsExpanded, setStudentDetailsExpanded] = useState(false);

  const handleStudentDetailsToggle = () => {
    setStudentDetailsExpanded(!studentDetailsExpanded);
  };

  console.log(demoData);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const fetchDemoData = async (demoId) => {
    const apiUrl = `${API_ENDPOINT}demo/${demoId}`; // Adjust the API endpoint as needed

    try {
      const response = await fetch(apiUrl);

      if (!response.ok) {
        // Handle non-successful responses (e.g., 404 Not Found, 500 Internal Server Error)
        throw new Error(`Error: ${response.status} - ${response.statusText}`);
      }

      const data = await response.json();
      return data;
    } catch (error) {
      console.error("Error fetching data:", error);
      throw new Error("Failed to fetch data");
    }
  };

  useEffect(() => {
    const fetchDemoInfo = async () => {
      setIsLoading(true);
      try {
        // Replace 'fetchEnquiryData' with your actual API function
        const data = await fetchDemoData(demoId); // Assuming fetchEnquiryData takes a student ID as an argument
        setDemoData(data);
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching demo data:", error);
        setIsLoading(false);
        // Handle error as needed
        navigate("/page404");
      }
    };

    /* if (!demoData && demoId) {
              fetchDemoInfo();
            }*/
    fetchDemoInfo();
  }, [demoId]);

  const handleCompleteRequest = (evaluationAnswers) => {
    // Perform actions for completing the request
    // Include evaluation answers in the requestData or perform additional logic
    console.log("Evaluation Answers:", evaluationAnswers);
    console.log(selectedDemo);
    const demoId = demoData.id;

    handlePostData(evaluationAnswers, demoId);
    updateDemoStatus(demoId, "completed", "");
  };

  const handlePostData = async (evaluationAnswers, demoId) => {
    try {
      const response = await fetch(`${API_ENDPOINT}studentstatus`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          ...evaluationAnswers,
          user_id: userId,
          demo_id: demoId,
        }),
      });

      if (response.ok) {
        // Handle successful response
        console.log("Data posted successfully");
        showSnackbar("Evaluation posted successfully", "success");
      } else {
        // Handle error response
        console.error("Failed to post data");
        showSnackbar("Failed to post evaluation", "error");
        return;
      }
    } catch (error) {
      console.error("Error posting data:", error);
      showSnackbar("Failed to post evaluation", "error");
      return;
    }
  };

  const handleViewClick = (demo) => {
    // setOpenDialog(true);
    // setSelectedDemo(demo);
    //setDialogAction(action);

    if (demo.evaluation_id) {
      setIsLoading(true);
      fetch(`${API_ENDPOINT}studentstatus/${demo.evaluation_id}`)
        .then((response) => {
          if (response.ok) {
            return response.json();
          } else {
            console.error("Failed to fetch evaluation data");
            return [];
          }
        })
        .then((evaluationData) => {
          setEvaluationData(evaluationData);
          setIsLoading(false);
          setOpenEvaluationPdfDialog(true);
        })
        .catch((error) => {
          console.error("Error fetching evaluation data:", error);
          setEvaluationData({});
          setIsLoading(false);
          setOpenEvaluationPdfDialog(true);
        });
    } else {
      // If evaluation_id is null, set an empty array
      setEvaluationData({});
      setOpenEvaluationPdfDialog(true);
    }
  };

  const handleConfirmDemo = (demoId) => {
    updateDemoStatus(demoId, "confirmed", "");
    //console.log(`Cancellation Reason: ${reason}`);

    // setOpenDialog(false);
  };

  const handleCancelRequest = () => {
    setOpenCancelDialog(true);
  };

  const handleCloseCancelDialog = () => {
    setOpenCancelDialog(false);
    setCancellationReason("");
  };

  const handleConfirmCancel = (demoId) => {
    // Perform the cancellation logic using cancellationReason
    // You can call an API or update the state as needed
    console.log("Cancellation Reason:", cancellationReason);
    updateDemoStatus(demoId, "cancelled", cancellationReason);
    setOpenCancelDialog(false);
    setCancellationReason("");
  };

  const handleConfirmRequest = () => {
    setOpenDialog(false);
    setOpenEvaluationDialog(true);
  };

  const handleConfirmAction = (demoId) => {
    updateDemoStatus(demoId, "missed", "");
  };

  const updateDemoStatus = async (demoId, newStatus, cancellationReason) => {
    try {
      const response = await fetch(`${API_ENDPOINT}demo/${demoId}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          status: newStatus,
          cancel_reason: cancellationReason,
          user_id: userId,
        }),
      });

      if (response.ok) {
        showSnackbar("Demo status updated successfully", "success");

        setDemoData((prevDemoData) => ({
          ...prevDemoData,
          status: newStatus,
        }));

        setTimeout(() => {
          window.location.reload();
        }, 1000);
      } else {
        console.error("Failed to update demo status");
        showSnackbar("Failed to update demo status", "error");
      }
    } catch (error) {
      console.error("Error updating demo status:", error);
      showSnackbar("Failed to update demo status", "error");
    }
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <MainCard>
        <Box m={2} />

        <Box m={2} width="100%" />
        <Grid container spacing={2}>
          {/* Left section */}
          <Grid
            item
            xs={12}
            md={
              demoData?.test_type_id?.name === "GRE" ||
              demoData?.test_type_id?.name === "GMAT" ||
              demoData?.test_type_id?.name === "SAT"
                ? 12
                : 6
            }
            lg={
              demoData?.test_type_id?.name === "GRE" ||
              demoData?.test_type_id?.name === "GMAT" ||
              demoData?.test_type_id?.name === "SAT"
                ? 12
                : 5
            }
            xl={
              demoData?.test_type_id?.name === "GRE" ||
              demoData?.test_type_id?.name === "GMAT" ||
              demoData?.test_type_id?.name === "SAT"
                ? 12
                : 6
            }
          >
            <Typography variant="h3" fontWeight={700} fontStyle="bold" mb={2}>
              Demo Details
            </Typography>
            <SubCard
              title={
                <Grid container spacing={2} alignItems="center">
                  <Grid item>
                    <Avatar color="secondary" alt="User 1" />
                  </Grid>
                  <Grid item xs zeroMinWidth>
                    <Typography align="left" variant="h4">
                      {demoData?.enquiry_id?.first_name}{" "}
                      {demoData?.enquiry_id?.last_name}
                    </Typography>
                    <Typography align="left" variant="subtitle2"></Typography>
                  </Grid>
                  <Grid item>
                    <Chip
                      size="small"
                      label={demoData?.status}
                      color="secondary"
                    />
                  </Grid>
                </Grid>
              }
            >
              <List component="nav" aria-label="main mailbox folders">
                <ListItemButton>
                  <ListItemIcon>
                    <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">Trainer Name</Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Typography variant="subtitle2" align="right">
                      {demoData?.trainer_id?.first_name}{" "}
                      {demoData?.trainer_id?.last_name}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItemButton>
                <Divider />
                <ListItemButton>
                  <ListItemIcon>
                    <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">Test Type</Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Typography variant="subtitle2" align="right">
                      {demoData?.test_type_id?.name}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItemButton>
                <Divider />

                {(demoData?.test_type_id?.name === "IELTS" ||
                  demoData?.test_type_id?.name === "PTE") && (
                  <>
                    <ListItemButton>
                      <ListItemIcon>
                        <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">
                            Sub Test Type
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="subtitle2" align="right">
                          {demoData?.sub_test_type_id?.name}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItemButton>
                    <Divider />
                  </>
                )}

                {demoData?.test_type_id?.name === "IELTS" && (
                  <>
                    <ListItemButton>
                      <ListItemIcon>
                        <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">
                            Test Delivery Format
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="subtitle2" align="right">
                          {demoData?.test_delivery_format_id?.name}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItemButton>
                    <Divider />
                  </>
                )}
                <ListItemButton>
                  <ListItemIcon>
                    <DateRangeIcon sx={{ fontSize: "1.3rem" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">
                        Demo Date and Slot
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Typography variant="subtitle2" align="right">
                      {fDate(demoData?.available_demo_date)} {demoData?.slot}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItemButton>

                <Divider />
                <ListItemButton>
                  <ListItemIcon>
                    <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                  </ListItemIcon>
                  <ListItemText
                    primary={
                      <Typography variant="subtitle1">
                        Delivery Mode and Branch
                      </Typography>
                    }
                  />
                  <ListItemSecondaryAction>
                    <Typography variant="subtitle2" align="right">
                      {demoData?.delivery_mode}{" "}
                      {demoData?.branch_id?.branch_name}
                    </Typography>
                  </ListItemSecondaryAction>
                </ListItemButton>

                {(demoData?.test_type_id?.name === "GRE" ||
                  demoData?.test_type_id?.name === "GMAT" ||
                  demoData?.test_type_id?.name === "SAT") && (
                  <>
                    {demoData?.cancel_reason && (
                      <>
                        <Divider />
                        <ListItemButton>
                          <ListItemIcon>
                            <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="subtitle1">
                                Cancel Reason
                              </Typography>
                            }
                          />
                          <ListItemSecondaryAction>
                            <Typography variant="subtitle2" align="right">
                              {demoData?.cancel_reason}
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItemButton>
                      </>
                    )}
                    {demoData?.reschedule_reason && (
                      <>
                        <Divider />
                        <ListItemButton>
                          <ListItemIcon>
                            <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                          </ListItemIcon>
                          <ListItemText
                            primary={
                              <Typography variant="subtitle1">
                                Reschedule Reason
                              </Typography>
                            }
                          />
                          <ListItemSecondaryAction>
                            <Typography variant="subtitle2" align="right">
                              {demoData?.reschedule_reason}
                            </Typography>
                          </ListItemSecondaryAction>
                        </ListItemButton>
                      </>
                    )}

                    <Divider />

                    <ListItemButton>
                      <ListItemIcon>
                        <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">Note</Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="subtitle2" align="right">
                          {" "}
                          {demoData?.note}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItemButton>
                  </>
                )}
              </List>
            </SubCard>
          </Grid>

          {/* Right Section */}
          {demoData?.test_type_id?.name === "GRE" ||
          demoData?.test_type_id?.name === "GMAT" ||
          demoData?.test_type_id?.name === "SAT" ? (
            " "
          ) : (
            <Grid item xs={12} md={6} lg={7} xl={6}>
              <Typography variant="h3" fontWeight={700} fontStyle="bold" mb={2}>
                Additional Details
              </Typography>
              <SubCard>
                <List component="nav" aria-label="main mailbox folders">
                  <ListItemButton>
                    <ListItemIcon>
                      <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1">
                          Student Taken Exam Before
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Typography variant="subtitle2" align="right">
                        {demoData?.pre_demo_detail_id?.exam_already_given}{" "}
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                  <Divider />

                  {demoData?.pre_demo_detail_id?.exam_already_given ===
                  "YES" ? (
                    <>
                      <ListItemButton>
                        <ListItemIcon>
                          <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              Overall Band Score
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Typography variant="subtitle2" align="right">
                            {demoData?.pre_demo_detail_id?.score}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                      <Divider />
                    </>
                  ) : (
                    <>
                      <ListItemButton>
                        <ListItemIcon>
                          <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              Target Overall Band Score
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Typography variant="subtitle2" align="right">
                            {demoData?.pre_demo_detail_id?.band_score_required}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                      <Divider />
                    </>
                  )}
                  <ListItemButton>
                    <ListItemIcon>
                      <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1">
                          Student Book The Exam
                        </Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Typography variant="subtitle2" align="right">
                        {demoData?.pre_demo_detail_id?.is_exam_booked}
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                  <Divider />

                  {demoData?.pre_demo_detail_id?.is_exam_booked === "YES" ? (
                    <>
                      <ListItemButton>
                        <ListItemIcon>
                          <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              Date Of Exam
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Typography variant="subtitle2" align="right">
                            {fDate(
                              demoData?.pre_demo_detail_id?.tentative_date
                            )}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                      <Divider />
                    </>
                  ) : (
                    <>
                      <ListItemButton>
                        <ListItemIcon>
                          <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              Tentative Date Of Exam
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Typography variant="subtitle2" align="right">
                            {fDate(
                              demoData?.pre_demo_detail_id?.tentative_date
                            )}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                      <Divider />
                    </>
                  )}

                  <ListItemButton>
                    <ListItemIcon>
                      <DateRangeIcon sx={{ fontSize: "1.3rem" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1">Preparation</Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Typography variant="subtitle2" align="right">
                        {demoData?.pre_demo_detail_id?.preparation
                          ? demoData?.pre_demo_detail_id?.preparation.toUpperCase()
                          : null}
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItemButton>

                  {demoData?.pre_demo_detail_id?.preparation ===
                    "other institute" && (
                    <ListItemButton>
                      <ListItemIcon>
                        <DateRangeIcon sx={{ fontSize: "1.3rem" }} />
                      </ListItemIcon>
                      <ListItemText
                        primary={
                          <Typography variant="subtitle1">
                            Institute name
                          </Typography>
                        }
                      />
                      <ListItemSecondaryAction>
                        <Typography variant="subtitle2" align="right">
                          {demoData?.pre_demo_detail_id.institute_name}
                        </Typography>
                      </ListItemSecondaryAction>
                    </ListItemButton>
                  )}

                  {demoData?.cancel_reason && (
                    <>
                      <Divider />
                      <ListItemButton>
                        <ListItemIcon>
                          <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              Cancel Reason
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Typography variant="subtitle2" align="right">
                            {demoData?.cancel_reason}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    </>
                  )}
                  {demoData?.reschedule_reason && (
                    <>
                      <Divider />
                      <ListItemButton>
                        <ListItemIcon>
                          <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                        </ListItemIcon>
                        <ListItemText
                          primary={
                            <Typography variant="subtitle1">
                              Reschedule Reason
                            </Typography>
                          }
                        />
                        <ListItemSecondaryAction>
                          <Typography variant="subtitle2" align="right">
                            {demoData?.reschedule_reason}
                          </Typography>
                        </ListItemSecondaryAction>
                      </ListItemButton>
                    </>
                  )}

                  <Divider />

                  <ListItemButton>
                    <ListItemIcon>
                      <DoubleArrowIcon sx={{ fontSize: "1.3rem" }} />
                    </ListItemIcon>
                    <ListItemText
                      primary={
                        <Typography variant="subtitle1">Note</Typography>
                      }
                    />
                    <ListItemSecondaryAction>
                      <Typography variant="subtitle2" align="right">
                        {demoData?.note}
                      </Typography>
                    </ListItemSecondaryAction>
                  </ListItemButton>
                </List>
              </SubCard>
            </Grid>
          )}

          {(userRole === "admin" || userRole === "trainer") && (
            <Box display="flex">
              {demoData?.status === "completed" && (
                <Box m={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => {
                      // setOpenDialog(false)
                      handleViewClick(demoData);
                    }}
                  >
                    View Evaluation
                  </Button>
                </Box>
              )}
              {demoData?.status === "pending" && (
                <Box m={2}>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleConfirmDemo(demoData.id)}
                  >
                    Confirm Demo
                  </Button>
                </Box>
              )}

              {demoData?.status === "confirmed" && (
                <div>
                  <Box m={2}>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleConfirmRequest}
                      //onClick={handleCancelRequest}
                    >
                      Mark Complete
                    </Button>

                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => {
                        setOpenDialog(false);
                        setConfirmDialogOpen(true);
                      }}
                    >
                      Mark Missed
                    </Button>
                  </Box>
                </div>
              )}

              {demoData?.status === "pending" && (
                <div>
                  <Box m={2}>
                    <Button
                      variant="contained"
                      color="error"
                      onClick={() => handleCancelRequest()}
                    >
                      Cancel Request
                    </Button>
                  </Box>
                </div>
              )}
            </Box>
          )}
        </Grid>

        <EvaluationQuestionsDialog
          selectedDemo={selectedDemo}
          open={openEvaluationDialog}
          onClose={() => setOpenEvaluationDialog(false)}
          onSubmit={(answers) => handleCompleteRequest(answers)}
        />

        <ConfirmationDialog
          open={isConfirmDialogOpen}
          onClose={() => setConfirmDialogOpen(false)}
          onConfirm={() => handleConfirmAction(demoData.id)}
          message={`Are you sure you want to mark ${demoData?.enquiry_id?.first_name} demo missed?`}
        />

        <Dialog
          open={openCancelDialog}
          onClose={handleCloseCancelDialog}
          maxWidth="md"
          fullWidth
        >
          <DialogTitle variant="h3">Reason for Cancellation</DialogTitle>
          <DialogContent>
            <TextField
              fullWidth
              multiline
              rows={4}
              label="Enter reason here"
              variant="outlined"
              value={cancellationReason}
              onChange={(e) => setCancellationReason(e.target.value)}
              error={!cancellationReason}
              helperText={
                !cancellationReason ? "Cancellation reason is required" : ""
              }
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseCancelDialog} color="primary">
              Cancel
            </Button>
            <Button
              onClick={() => handleConfirmCancel(demoData.id)}
              color="error"
              variant="contained"
            >
              Confirm Cancellation
            </Button>
          </DialogActions>
        </Dialog>

        {openEvaluationPdfDialog && (
          <Dialog
            fullWidth
            maxWidth="md"
            open={openEvaluationPdfDialog}
            onClose={() => setOpenEvaluationPdfDialog(false)}
          >
            <DialogTitle variant="h4">Evaluation PDF</DialogTitle>
            {Object.keys(evaluationData).length > 0 ? (
              <EvaluationPDF
                demoId={demoData.id}
                studentName={demoData?.enquiry_id?.first_name}
                trainerName={demoData?.trainer_id.first_name}
                evaluationData={evaluationData}
              />
            ) : (
              <DialogContentText
                id="alert-dialog-description"
                style={{ textAlign: "center" }}
              >
                <CancelIcon color="error" style={{ fontSize: 60 }} />
                <br />
                No Evaluation Data Available
              </DialogContentText>
            )}
            <DialogActions>
              <Button
                onClick={() => setOpenEvaluationPdfDialog(false)}
                color="error" variant="contained"
              >
                Close
              </Button>
            </DialogActions>
          </Dialog>
        )}

        <CustomSnackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </MainCard>
    </>
  );
}

export default DemoDetails;
