import React, { useState, useEffect } from 'react';
import { PDFViewer, Document, Page, Text, View, StyleSheet, Font, Image} from '@react-pdf/renderer';
import axios from 'axios';
import EvaluationTable from './EvaluationTable';

Font.register({
  family: 'Open Sans',
  fonts: [
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf' },
    { src: 'https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf', fontWeight: 600 }
  ]
})

const EvaluationPDF = ({evaluationData, demoId, studentName, trainerName}) => {
    
  /*const [evaluationData, setEvaluationData] = useState(null);

  useEffect(() => {
    // Fetch data from the API
    axios.get('your_api_endpoint')
      .then(response => setEvaluationData(response.data))
      .catch(error => console.error('Error fetching data:', error));
  }, []);*/

  const styles = StyleSheet.create({
    page: {
        fontFamily: 'Helvetica',
        fontSize: 11,
        paddingTop: 30,
        paddingLeft:60,
        paddingRight:60,
        lineHeight: 1.5,
        flexDirection: 'column',
    }, 
    section: {
        margin: 10,
        padding: 10,
        flexGrow: 1,
      },
    logo: {
        width: 170,
       
        marginLeft: 'auto',
        marginRight: 'auto'
    },
    title: {
        marginTop: 20,
        paddingBottom: 3,
      fontFamily: 'Open Sans',
        textAlign:'center',
        fontWeight:"Bold",
        fontSize:'20px'
    },
    subtitle:{fontFamily: 'Open Sans',fontSize:'14px',fontWeight:'normal'},
    text:{flexDirection:'row', alignItems:'center',fontFamily:"Open Sans", marginTop:10},
    maintext:{fontWeight:'bold', fontSize:'14px' ,}
  });

  const generatePDF = () => {
    // Create a PDF document
    const pdfDoc = (
      <Document>
        <Page size="A4" style={styles.page}>
       <View style={styles.logo}>
        <Image src='/Siec Logo.png' style={{width:'100%', }}/>
       </View>
            <Text style={styles.title}>Evaluation Score</Text>
<View style={{marginBottom:'20px'}}>
  <View style={styles.text}>
  <Text style={styles.subtitle}>Student Name </Text>
            <Text style={styles.maintext}>: {studentName} </Text></View>
           
            <View style={styles.text}>
  <Text style={styles.subtitle}>Trainer Name </Text>
            <Text style={styles.maintext}>: {trainerName} </Text></View>
           
           
            </View>

            <EvaluationTable evaluationData={evaluationData} />
            {/* Add other evaluation data or components as needed */}
         
        </Page>
      </Document>
    );

    return pdfDoc;
  };

  return (
    <div>
      <PDFViewer width="1000px" height="600px">
        {generatePDF()}
      </PDFViewer>
    </div>
  );
};

export default EvaluationPDF;
