// Author : Ayesha Shaikh
// Date : 30 July 2024

import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import {
  Autocomplete,
  Box,
  Button,
  Grid,
  MenuItem,
  Paper,
  Select,
  Stack,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";

import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import ArrowDropUpIcon from "@mui/icons-material/ArrowDropUp";
import { addDays, format } from "date-fns";

import DownloadIcon from "@mui/icons-material/Download";
import CustomPopup from "../../../components/CustomPopup";
import SendTrainerReport from "./SendTrainerReport";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function TrainerReport({
  setTrainerDemoLoading,
  showSnackbar,
  setIsLoading,
  setClassLoading,
}) {
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [openDemo, setOpenDemo] = useState(false);
  const [OpenTrainerReport, setOpenTrainerReport] = useState(false);
  const [alltraines, setAllTrainers] = useState([]);
  const [trainerName, setTrainerName] = useState(null);
  const [filterfor, setFilterFor] = useState("today");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setTodate] = useState("");
  const [demoData, setDemoData] = useState([]);
  const [taskData, setTaskData] = useState([]);
  const [openTask, setOpenTask] = useState(false);
  const [typeofTask, setTypeofTask] = useState("created");
  const [taskcreateddata, setTaskCreatedData] = useState([]);
  const [completedTaskData, setCompletedTaskData] = useState([]);
  const [classOpen, setClassOpen] = useState(false);
  const [classData, setClassData] = useState([]);
  const [openReportForm, setOpenReportForm] = useState(false);
  const [errors, setErrors] = useState({
    trainerName: false,
  });

  const selectedData = { filterfor, fromDate, toDate, trainerName };
  //trainer Report Open and Close

  const handleTrainerReportToggle = () => {
    setOpenTrainerReport(!OpenTrainerReport);
  };

  console.log(
    classData.map((classes) => {
      return classes[classes.test_name];
    })
  );

  //fetch all trainers
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route2.0/gettrainers/${userId}`
        );
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setAllTrainers(sortedData);
          console.log(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [userId]);

  const handleTrainerNameChange = (event, value) => {
    setTrainerName(value);
    setErrors({ ...errors, trainerName: !value });
  };

  const handleChangeFilterfor = (e) => {
    setFilterFor(e.target.value);
    setFromDate(null);
    setTodate(null);
  };

  const handleFromdateChange = (e) => {
    setFromDate(e.target.value);

    if (toDate && new Date(toDate) > addDays(new Date(e.target.value), 30)) {
      setTodate("");
    }
  };

  const maxToDate = fromDate
    ? format(addDays(new Date(fromDate), 30), "yyyy-MM-dd")
    : " ";
  console.log("maxToDate", maxToDate);

  const handleTodateChange = (e) => {
    setTodate(e.target.value);
  };

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: "20px",
    "& .MuiTableCell-head": {
      backgroundColor: "#abf7b1",
      fontWeight: 600,
      padding: "8px 16px",

      borderRight: "0.1px solid #C3C3C3",
      borderLeft: "0.1px solid #C3C3C3",
      color: "#000",
    },
    "& .MuiTableCell-body": {
      fontSize: "14px",
      fontWeight: 600,
      borderRight: "0.1px solid #C3C3C3",
      borderLeft: "0.1px solid #C3C3C3",
      align: "center",
      padding: "8px 16px",
      "&:nth-of-type(n+2)": {
        color: "blue",
      },
    },
  }));

  const handleSearchAll = () => {
    if (!openDemo) {
      handleSubmitDemo();
    } else if (!openTask) {
      handleSubmitTask();
    } else if (!classOpen) {
      handleSubmitClass();
    }
  };
  const handleClearAll = () => {
    setFilterFor("today");
    setFromDate(null);
    setTodate(null);
    setTrainerName(null);
    handleCloseDemo();
    handlecloseClass();
    handleClose();
  };

  const handleViewreport = (id) => {
    if (
      !trainerName &&
      (userRole === "admin" ||
        userRole === "center_admin" ||
        userRole === "data_entry")
    ) {
      setErrors({ trainerName: !trainerName });
      return;
    }
    const url = `/work-report-generate`;
    window.open(url, "_blank");
    localStorage.setItem("details", JSON.stringify(selectedData));
  };

  //Demo Details
  const handleOpenDemo = () => {
    setOpenDemo(true);
  };

  const handleCloseDemo = () => {
    setOpenDemo(false);
    setDemoData([]);
  };

  const handleSubmitDemo = async () => {
    if (
      !trainerName &&
      (userRole === "admin" ||
        userRole === "center_admin" ||
        userRole === "data_entry")
    ) {
      setErrors({ trainerName: !trainerName });
      return;
    }
    setTrainerDemoLoading(true);
    const requestData = {
      user_id: parseInt(userId),
      filter: filterfor,
      from_date: fromDate || null,
      to_date: toDate || null,
      report_for: trainerName?.user_id || null,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}reports/gettrainerdemoreport`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        const demos = result.demos.map((i) => i);

        setDemoData(demos);

        setTrainerDemoLoading(false);
        handleOpenDemo();
      } else if (response.status === 400) {
        showSnackbar("Invalid filter date range", "error");

        setTrainerDemoLoading(false);
      } else if (response.status === 404) {
        setTrainerDemoLoading(false);
        showSnackbar("User not found", "error");
      } else if (response.status === 403) {
        setTrainerDemoLoading(false);
        showSnackbar("Unknown User", "error");
      } else if (response.status === 500) {
        setTrainerDemoLoading(false);
        showSnackbar("Failed to fetch data", "error");
      } else {
        setTrainerDemoLoading(false);
        showSnackbar("Error", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      setTrainerDemoLoading(false);
      showSnackbar("Error", "error");
    }
  };

  //  Task Details
  const handleopen = () => {
    setOpenTask(true);
  };

  const handleClose = () => {
    setOpenTask(false);

    setCompletedTaskData([]);
    setTaskCreatedData([]);
  };

  // function for created and completed button , set the type accordingly
  const handlechangeTask = (criteria) => {
    setTypeofTask(criteria);
  };

  //task created column data
  const columns = [
    { field: "type_of_task", headerName: "Tasks" },
    { field: "totalTasks", headerName: "Created" },
  ];

  //task  completed column data
  const columns1 = [
    { field: "type_of_task", headerName: "Tasks" },
    { field: "totalTasks", headerName: "Completed" },
    { field: "contactableTasks", headerName: "Contactable" },
    { field: "nonContactableTasks", headerName: "Uncontactable" },
  ];

  const taskrows =
    typeofTask === "created" ? taskcreateddata : completedTaskData;
  const taskcolumnsToUse = typeofTask === "created" ? columns : columns1;

  const handleSubmitTask = async (event) => {
    // Handle form submission logic here
    if (
      !trainerName &&
      (userRole === "admin" ||
        userRole === "center_admin" ||
        userRole === "data_entry")
    ) {
      setErrors({ trainerName: !trainerName });
      return;
    }
    setIsLoading(true);

    const requestData = {
      user_id: parseInt(userId),
      filter: filterfor,
      from_date: fromDate || null,
      to_date: toDate || null,
      report_for: trainerName?.user_id || null,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}reports/gettaskdetailsreport`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        console.log(result);
        setTaskData(result);
        const created = result.created?.map((i) => i);
        const completed = result.completed.map((i) => i);
        setTaskCreatedData(created);
        setCompletedTaskData(completed);

        setIsLoading(false);
        handleopen();
      } else if (response.status === 400) {
        showSnackbar("Invalid filter date range", "error");

        setIsLoading(false);
      } else if (response.status === 404) {
        setIsLoading(false);
        showSnackbar("User not found", "error");
      } else if (response.status === 403) {
        setIsLoading(false);
        showSnackbar("Unknown User", "error");
      } else if (response.status === 500) {
        setIsLoading(false);
        showSnackbar("Failed to fetch data", "error");
      } else {
        setIsLoading(false);
        showSnackbar("Error", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      setIsLoading(false);
      showSnackbar("Error", "error");
    }
  };

  //Class Details
  const handleOpenClass = () => {
    setClassOpen(true);
  };

  const handlecloseClass = () => {
    setClassOpen(false);
    setClassData([]);
  };

  const handleSubmitClass = async () => {
    if (
      !trainerName &&
      (userRole === "admin" ||
        userRole === "center_admin" ||
        userRole === "data_entry")
    ) {
      setErrors({ trainerName: !trainerName });
      return;
    }
    setClassLoading(true);
    const requestData = {
      user_id: parseInt(userId),
      filter: filterfor,
      from_date: fromDate || null,
      to_date: toDate || null,
      report_for: trainerName?.user_id || null,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}reports/gettrainerclassreport`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        setClassData(result);

        setClassLoading(false);
        handleOpenClass();
      } else if (response.status === 400) {
        showSnackbar("Invalid filter date range", "error");

        setClassLoading(false);
      } else if (response.status === 401) {
        setClassLoading(false);
        showSnackbar("User not found", "error");
      } else if (response.status === 403) {
        setClassLoading(false);
        showSnackbar("Unknown User", "error");
      } else if (response.status === 404) {
        setClassLoading(false);
        showSnackbar("Couldn't Fount Data!!", "error");
      } else if (response.status === 500) {
        setClassLoading(false);
        showSnackbar("Failed to fetch data", "error");
      } else {
        setClassLoading(false);
        showSnackbar("Error", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      setClassLoading(false);
      showSnackbar("Error", "error");
    }
  };

  // Pagination for class Details

  const [page, setPage] = useState({});
  const [rowsPerPage, setRowsPerPage] = useState({});

  useEffect(() => {
    // Initialize pagination state once on mount
    const initPage = {};
    const initRowsPerPage = {};

    classData.forEach((testCategory) => {
      const testName = testCategory.test_name;
      initPage[testName] = {};
      initRowsPerPage[testName] = {};

      testCategory.detail.forEach((_, index) => {
        initPage[testName][index] = 0; // Start on the first page
        initRowsPerPage[testName][index] = 5; // Default rows per page
      });
    });

    setPage(initPage);
    setRowsPerPage(initRowsPerPage);
  }, [classData]);

  const handleChangePage = (event, newPage, testName, datasetIndex) => {
    setPage((prevPage) => ({
      ...prevPage,
      [testName]: { ...prevPage[testName], [datasetIndex]: newPage },
    }));
  };

  const handleChangeRowsPerPage = (event, testName, datasetIndex) => {
    const newRowsPerPage = parseInt(event.target.value, 10);
    setRowsPerPage((prevRows) => ({
      ...prevRows,
      [testName]: { ...prevRows[testName], [datasetIndex]: newRowsPerPage },
    }));
    setPage((prevPage) => ({
      ...prevPage,
      [testName]: { ...prevPage[testName], [datasetIndex]: 0 }, // Reset to the first page
    }));
  };

  const handleOpenreportform = () => {
    if (
      !trainerName &&
      (userRole === "admin" ||
        userRole === "center_admin" ||
        userRole === "data_entry")
    ) {
      setErrors({ trainerName: !trainerName });
      return;
    }
    setOpenReportForm(true);
  };

  const handleClosereportform = () => {
    setOpenReportForm(false);
  };

  return (
    <>
      <Paper
        elevation={3}
        style={{
          background: "#fffdf7",
          padding: "20px",
          borderRadius: "15px",
        }}
      >
        <Box style={{ marginTop: "-30px" }}>
          <img src="https://sieceducation.in/images/spring.png" width="50%" />

          <img src="https://sieceducation.in/images/spring.png" width="50%" />
        </Box>

        <Grid container spacing={4}>
          <Grid item xs={9} lg={9} sm={9} md={9} mt={4}>
            <Box
              sx={{
                display: "flex",
                direction: "row",
                alignItems: "center",
              }}
            >
              <img src="https://sieceducation.in/images/pin.png" width="2%" />
              <Typography
                sx={{
                  fontWeight: 600,
                  fontSize: "20px",
                  color: "#445377",
                }}
                ml={1}
              >
                {userRole === "admin" ||
                userRole === "center_admin" ||
                userRole === "data_entry"
                  ? " Trainer Work Report"
                  : "My Work Diary"}
              </Typography>
            </Box>
          </Grid>

          <Grid item xs={3} lg={3} sm={3} md={3} mt={4} textAlign="end">
            {!OpenTrainerReport ? (
              <KeyboardArrowDownIcon
                onClick={handleTrainerReportToggle}
                style={{ cursor: "pointer" }}
              />
            ) : (
              <KeyboardArrowUpIcon
                onClick={handleTrainerReportToggle}
                style={{ cursor: "pointer" }}
              />
            )}
          </Grid>

          {OpenTrainerReport && (
            <>
              {(userRole === "center_admin" ||
                userRole === "data_entry" ||
                userRole === "admin") && (
                <Grid item xs={12} lg={2.5} md={6} sm={6}>
                  <Typography sx={{ fontWeight: 600 }}>
                    Select Report For
                  </Typography>
                  <Autocomplete
                    size="small"
                    value={trainerName}
                    onChange={handleTrainerNameChange}
                    options={alltraines}
                    getOptionLabel={(option) =>
                      `${option.first_name ? option.first_name : ""} ${
                        option.last_name ? option.last_name : ""
                      }`
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                  {errors.trainerName && (
                    <Typography color="error" variant="caption">
                      Trainer Name required
                    </Typography>
                  )}
                </Grid>
              )}

              <Grid item xs={12} lg={2} md={3} sm={3}>
                <Typography sx={{ fontWeight: 600 }}>Select For</Typography>
                <Select
                  value={filterfor}
                  onChange={handleChangeFilterfor}
                  fullWidth
                  style={{ borderRadius: "15px" }}
                  size="small"
                >
                  <MenuItem value="today">Today</MenuItem>
                  <MenuItem value="yesterday">Yesterday</MenuItem>

                  <MenuItem value="custom">Custom</MenuItem>
                </Select>
              </Grid>

              {filterfor === "custom" && (
                <>
                  <Grid item xs={12} lg={2} md={3} sm={3}>
                    <Typography sx={{ fontWeight: 600 }}>From Date</Typography>
                    <TextField
                      value={fromDate}
                      onChange={handleFromdateChange}
                      fullWidth
                      type="date"
                      size="small"
                      InputProps={{ style: { borderRadius: "15px" } }}
                    />
                  </Grid>
                  <Grid item xs={12} lg={2} md={3} sm={3}>
                    <Typography sx={{ fontWeight: 600 }}>To Date</Typography>
                    <TextField
                      value={toDate}
                      onChange={handleTodateChange}
                      fullWidth
                      type="date"
                      size="small"
                      InputProps={{
                        style: { borderRadius: "15px" },
                        inputProps: { max: maxToDate },
                      }}
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={12} xl={2.5} md={3.5} sm={6} lg={2.8}>
                <Typography sx={{ fontWeight: 600, visibility: "hidden" }}>
                  for spacing
                </Typography>
                <Button
                  variant="contained"
                  style={{ borderRadius: "25px" }}
                  startIcon={<SearchIcon />}
                  onClick={handleSearchAll}
                >
                  Search
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ marginLeft: "10px", borderRadius: "25px" }}
                  startIcon={<ClearIcon />}
                  onClick={handleClearAll}
                >
                  Clear{" "}
                </Button>
              </Grid>

              <Grid
                item
                xs={12}
                md={
                  filterfor === "custom" && userRole === "trainer"
                    ? 8
                    : filterfor === "custom" && userRole !== "trainer"
                    ? 12
                    : filterfor !== "custom" && userRole === "trainer"
                    ? 5
                    : filterfor !== "custom" && userRole !== "trainer" ? 8 :8
                }
                lg={
                  filterfor === "custom" && userRole === "trainer"
                    ? 12
                    : filterfor === "custom" && userRole !== "trainer"
                    ? 12
                    : filterfor !== "custom" && userRole === "trainer"
                    ? 5
                    : filterfor !== "custom" && userRole !== "trainer" ? 4.4 :8
                }
                xl={
                  filterfor === "custom" && userRole === "trainer"
                    ? 3.5
                    : filterfor === "custom" && userRole !== "trainer"
                    ? 12
                    : filterfor !== "custom" && userRole === "trainer"
                    ? 7
                    : 5
                }
                sm={6}
                textAlign="end"
              >
                <Typography sx={{ fontWeight: 600, visibility: "hidden" }}>
                  for spacing
                </Typography>

                <Button
                  variant="contained"
                  color="secondary"
                  sx={{
                    marginLeft: "10px",
                    borderRadius: "25px",
                    fontWeight: 600,
                  }}
                  startIcon={<DownloadIcon />}
                  onClick={handleViewreport}
                >
                  Download Report{" "}
                </Button>
                <Button
                  variant="contained"
                  sx={{
                    marginLeft: "10px",
                    borderRadius: "25px",
                    fontWeight: 600,
                  }}
                  endIcon={<img src="/icons8-send-64.png" width="20px" />}
                  onClick={() => handleOpenreportform()}
                >
                  Send Report{" "}
                </Button>
              </Grid>

              {/* Demo Details */}
              <Grid item xs={12} lg={12} sm={12} md={12}>
                <MainCard style={{ border: "0.5px solid #e7e3e3" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={9} md={9} sm={9} lg={9}>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          openDemo ? handleCloseDemo() : handleSubmitDemo()
                        }
                      >
                        Demo Details
                      </Typography>
                    </Grid>

                    <Grid item xs={3} md={3} sm={3} lg={3} textAlign="end">
                      {openDemo ? (
                        <ArrowDropUpIcon
                          onClick={() => handleCloseDemo()}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ArrowDropDownIcon
                          onClick={() => handleSubmitDemo()}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </Grid>
                    {openDemo && (
                      <Grid item xs={12} lg={12} sm={12} md={12}>
                        {demoData.length === 0 ? (
                          <Typography
                            sx={{
                              textAlign: "center",
                              fontSize: "14px",
                              fontWeight: 600,
                            }}
                          >
                            No Demo Data Available
                          </Typography>
                        ) : (
                          <StyledTableContainer component={Paper}>
                            <Table aria-label="simple table">
                              <TableHead>
                                <TableRow>
                                  <TableCell>Class Name</TableCell>
                                  <TableCell>Total Demos</TableCell>
                                  <TableCell>Demo Missed</TableCell>
                                  <TableCell>Demo Completed</TableCell>
                                  <TableCell>Demo Evaluated</TableCell>
                                </TableRow>
                              </TableHead>
                              <TableBody>
                                {demoData?.map((row) => (
                                  <TableRow key={row.id}>
                                    <TableCell component="th" scope="row">
                                      {row?.className}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row?.totalDemos}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row?.missedDemos}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row.completedDemos}
                                    </TableCell>
                                    <TableCell component="th" scope="row">
                                      {row.evaluatedDemos}
                                    </TableCell>
                                  </TableRow>
                                ))}
                              </TableBody>
                            </Table>
                          </StyledTableContainer>
                        )}
                      </Grid>
                    )}
                  </Grid>
                </MainCard>
              </Grid>

              {/* Task Details */}
              <Grid item xs={12} lg={12} sm={12} md={12}>
                <MainCard style={{ border: "0.5px solid #e7e3e3" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={9} md={9} sm={9} lg={9}>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          openTask ? handleClose() : handleSubmitTask()
                        }
                      >
                        Task Details
                      </Typography>
                    </Grid>
                    <Grid item xs={3} md={3} sm={3} lg={3} textAlign="end">
                      {openTask ? (
                        <ArrowDropUpIcon
                          onClick={() => handleClose()}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ArrowDropDownIcon
                          onClick={() => handleSubmitTask()}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </Grid>

                    {openTask && (
                      <>
                        <Grid item xs={12} md={12} sm={12} lg={12}>
                          <Button
                            style={{
                              borderRadius: "25px",
                              background:
                                typeofTask === "created" ? "#8ed994" : "",
                              color:
                                typeofTask === "created"
                                  ? "#2a603b"
                                  : "#7e7d7d",
                              fontWeight: 500,
                              fontSize: "16px",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              border:
                                typeofTask === "completed"
                                  ? "1px solid #7e7d7d"
                                  : "",
                            }}
                            onClick={() => handlechangeTask("created")}
                          >
                            Created Task
                          </Button>
                          <Button
                            style={{
                              marginLeft: "10px",
                              borderRadius: "25px",
                              background:
                                typeofTask === "completed" ? "#8ed994" : "",
                              color:
                                typeofTask === "completed"
                                  ? "#2a603b"
                                  : "#7e7d7d",
                              fontWeight: 500,
                              fontSize: "16px",
                              paddingLeft: "20px",
                              paddingRight: "20px",
                              border:
                                typeofTask === "created"
                                  ? "1px solid #7e7d7d"
                                  : "",
                            }}
                            onClick={() => handlechangeTask("completed")}
                          >
                            Completed Task
                          </Button>
                        </Grid>

                        <Grid item xs={12} lg={12} sm={12} md={12}>
                          {taskcreateddata.length === 0 ||
                          completedTaskData.length === 0 ? (
                            <Typography
                              sx={{
                                fontSize: "14px",
                                fontWeight: 600,
                                textAlign: "center",
                              }}
                            >
                              No Task Data Available
                            </Typography>
                          ) : (
                            <StyledTableContainer component={Paper}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    {taskcolumnsToUse.map((column) => (
                                      <TableCell
                                        key={column.field}
                                        align="center"
                                      >
                                        {column.headerName}
                                      </TableCell>
                                    ))}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {taskrows.map((row, index) => (
                                    <TableRow key={index}>
                                      {taskcolumnsToUse.map((column) => (
                                        <TableCell
                                          key={column.field}
                                          align="center"
                                        >
                                          <Typography
                                            sx={{
                                              fontSize: "14px",
                                              fontWeight: 600,
                                            }}
                                          >
                                            {row[column.field]}
                                          </Typography>
                                        </TableCell>
                                      ))}
                                    </TableRow>
                                  ))}
                                </TableBody>
                              </Table>
                            </StyledTableContainer>
                          )}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </MainCard>
              </Grid>

              {/* Class Details */}
              <Grid item xs={12} lg={12} sm={12} md={12}>
                <MainCard style={{ border: "0.5px solid #e7e3e3" }}>
                  <Grid container spacing={2}>
                    <Grid item xs={9} md={9} sm={9} lg={9}>
                      <Typography
                        sx={{
                          fontSize: "18px",
                          fontWeight: 600,
                          cursor: "pointer",
                        }}
                        onClick={() =>
                          classOpen ? handlecloseClass() : handleSubmitClass()
                        }
                      >
                        Class Details
                      </Typography>
                    </Grid>
                    <Grid item xs={3} md={3} sm={3} lg={3} textAlign="end">
                      {classOpen ? (
                        <ArrowDropUpIcon
                          onClick={() => handlecloseClass()}
                          style={{ cursor: "pointer" }}
                        />
                      ) : (
                        <ArrowDropDownIcon
                          onClick={() => handleSubmitClass()}
                          style={{ cursor: "pointer" }}
                        />
                      )}
                    </Grid>
                    {classOpen && (
                      <>
                        <Grid item xs={12} lg={12} sm={12} md={12}>
                          {classData.map((testCategory, index) => {
                            const testName = testCategory.test_name;
                            const tests = testCategory.detail;

                            return (
                              <React.Fragment key={index}>
                                <Typography
                                  sx={{
                                    fontSize: "18px",
                                    fontWeight: 600,
                                    marginTop: 2,
                                  }}
                                >
                                  {testName}
                                </Typography>
                                {tests.map((test, datasetIndex) => {
                                  const currentPage =
                                    page[testName]?.[datasetIndex] || 0;
                                  const currentRowsPerPage =
                                    rowsPerPage[testName]?.[datasetIndex] || 5;

                                  const paginatedData = test.student_info.slice(
                                    currentPage * currentRowsPerPage,
                                    currentPage * currentRowsPerPage +
                                      currentRowsPerPage
                                  );

                                  return (
                                    <>
                                      <Stack direction="row" spacing={3} mt={2}>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          Class Name{" "}
                                          <strong>: {test?.name} </strong>
                                        </Typography>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          Branch{" "}
                                          <strong>
                                            : {test?.branch_id?.branch_name}
                                          </strong>
                                        </Typography>
                                        <Typography sx={{ fontSize: "16px" }}>
                                          Capacity{" "}
                                          <strong>: {test?.capacity}</strong>
                                        </Typography>
                                      </Stack>
                                      <StyledTableContainer
                                        component={Paper}
                                        key={datasetIndex}
                                        sx={{ marginTop: 2 }}
                                      >
                                        <Table
                                          aria-label={`${test.name} table`}
                                        >
                                          <TableHead>
                                            <TableRow>
                                              <TableCell>Date</TableCell>
                                              <TableCell>
                                                Enrolled Students
                                              </TableCell>
                                              <TableCell>
                                                Present Students
                                              </TableCell>
                                              <TableCell>
                                                Absent Students
                                              </TableCell>
                                              <TableCell>
                                                Leave Students
                                              </TableCell>
                                              <TableCell>
                                                Attendance Not Marked
                                              </TableCell>
                                            </TableRow>
                                          </TableHead>
                                          <TableBody>
                                            {paginatedData.map((info, j) => {
                                              const isSunday =
                                                new Date(info.date).getDay() ===
                                                0;
                                              return (
                                                <TableRow key={j}>
                                                  <TableCell
                                                    style={{
                                                      backgroundColor: isSunday
                                                        ? "#eeb4b8"
                                                        : "",
                                                    }}
                                                  >
                                                    {info.date}
                                                  </TableCell>
                                                  <TableCell
                                                    style={{
                                                      backgroundColor: isSunday
                                                        ? "#eeb4b8"
                                                        : "",
                                                      color: isSunday
                                                        ? "black"
                                                        : "",
                                                    }}
                                                  >
                                                    {info.enrolled}
                                                  </TableCell>
                                                  <TableCell
                                                    style={{
                                                      backgroundColor: isSunday
                                                        ? "#eeb4b8"
                                                        : "",
                                                      color: isSunday
                                                        ? "black"
                                                        : "",
                                                    }}
                                                  >
                                                    {info.present}
                                                  </TableCell>
                                                  <TableCell
                                                    style={{
                                                      backgroundColor: isSunday
                                                        ? "#eeb4b8"
                                                        : "",
                                                      color: isSunday
                                                        ? "black"
                                                        : "",
                                                    }}
                                                  >
                                                    {info.absent}
                                                  </TableCell>
                                                  <TableCell
                                                    style={{
                                                      backgroundColor: isSunday
                                                        ? "#eeb4b8"
                                                        : "",
                                                      color: isSunday
                                                        ? "black"
                                                        : "",
                                                    }}
                                                  >
                                                    {info.leave}
                                                  </TableCell>
                                                  <TableCell
                                                    style={{
                                                      backgroundColor: isSunday
                                                        ? "#eeb4b8"
                                                        : "",
                                                      color: isSunday
                                                        ? "black"
                                                        : "",
                                                    }}
                                                  >
                                                    {info.not_marked}
                                                  </TableCell>
                                                </TableRow>
                                              );
                                            })}
                                          </TableBody>
                                        </Table>
                                        <TablePagination
                                          component="div"
                                          count={test.student_info.length}
                                          rowsPerPage={currentRowsPerPage}
                                          page={currentPage}
                                          onPageChange={(event, newPage) =>
                                            handleChangePage(
                                              event,
                                              newPage,
                                              testName,
                                              datasetIndex
                                            )
                                          }
                                          onRowsPerPageChange={(event) =>
                                            handleChangeRowsPerPage(
                                              event,
                                              testName,
                                              datasetIndex
                                            )
                                          }
                                          rowsPerPageOptions={[5, 10, 20, 30]}
                                        />
                                      </StyledTableContainer>
                                    </>
                                  );
                                })}
                              </React.Fragment>
                            );
                          })}
                        </Grid>
                      </>
                    )}
                  </Grid>
                </MainCard>
              </Grid>
            </>
          )}
        </Grid>
      </Paper>
      <CustomPopup
        open={openReportForm}
        onClose={handleClosereportform}
        component={
          <SendTrainerReport
            onClose={handleClosereportform}
            showSnackbar={showSnackbar}
            selectedData={selectedData}
          />
        }
        title="Send Report"
        maxWidth="sm"
        showDivider={true}
      />
    </>
  );
}
