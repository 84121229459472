import React from 'react';
import PropTypes from 'prop-types';
import {
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  styled,
  useRadioGroup,
} from '@mui/material';

const StyledFormControlLabel = styled((props) => (
  <FormControlLabel {...props} />
))(({ theme, checked }) => ({
    '& .MuiFormControlLabel-label': {
        color: checked ? '#019E6F' : 'inherit',
      },
  
      '& .Mui-checked': {
        color: '#019E6F', // Set the color for the radio button circle when checked
      },
}));

function MyFormControlLabel(props) {
  const radioGroup = useRadioGroup();

  let checked = false;

  if (radioGroup) {
    checked = radioGroup.value === props.value;
  }

  return <StyledFormControlLabel checked={checked} {...props} />;
}

MyFormControlLabel.propTypes = {
  value: PropTypes.any,
};

export default function RevenueButton() {
  return (
    <FormControl>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
      >
        <MyFormControlLabel value="Daily" control={<Radio />} label="Daily" />
        <MyFormControlLabel value="Weekly" control={<Radio />} label="Weekly" />
        <MyFormControlLabel value="Monthly" control={<Radio />} label="Monthly" />
      </RadioGroup>
    </FormControl>
  );
}
