import React, { useEffect, useState } from 'react';
import { Grid, CircularProgress, Typography, Box, IconButton, Button } from '@mui/material';
import ClassCard from '../../../views/trainer/classes/ClassCard';
import { Filter, Menu } from '@mui/icons-material';
import SideBarFilter from './SideBarFilter';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import CreateClassDialog from './AddClass';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function AdminClasses() {
  const trainerId = localStorage.getItem('trainerId');
  const [classes, setClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  // State variables for filters
  const [filterClassName, setFilterClassName] = useState('');
  const [filterTrainer, setFilterTrainer] = useState('');
  const [filterDeliveryMode, setFilterDeliveryMode] = useState('');


  useEffect(() => {
    fetch(`${API_ENDPOINT}admindashboard/allclasses`)
      .then((res) => res.json())
      .then((data) => {
        setClasses(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching classes:', error);
        setIsLoading(false);
      });
  }, []);

console.log(classes)

const applyFilters = () => {
    // Implement your filter logic here based on filterClassName, filterTrainer, and filterDeliveryMode
    const filtered = classes.filter((classe) => {
      // Check if the properties you want to filter on are not null or undefined
      const classNameMatch = filterClassName ? classe.name.includes(filterClassName) : true;
      const trainerMatch = filterTrainer ? classe.trainer_id.full_name.includes(filterTrainer) : true;
      const deliveryModeMatch = filterDeliveryMode ? classe.mode_of_delivery.includes(filterDeliveryMode) : true;
  
      return classNameMatch && trainerMatch && deliveryModeMatch;
    });
  
    // Update classes with filtered data
    setClasses(filtered);
  };

  // Reset filters and fetch all classes
  const resetFilters = () => {
    setFilterClassName('');
    setFilterTrainer('');
    setFilterDeliveryMode('');
    fetch(`${API_ENDPOINT}admindashboard/allclasses`)
      .then((res) => res.json())
      .then((data) => {
        setClasses(data);
      })
      .catch((error) => {
        console.error('Error fetching classes:', error);
      });
  };

  const handleCreateClass = (newClass) => {
    
  };

  return (
    <>
        <Box justifyContent='space-evenly' sx={{ mr: 1 , mb: 2, mt: 2}}>
              <IconButton
                size="small"
                color="inherit"
                onClick={() => setIsOpen(true)}
              >
                <FilterAltIcon size="large"/> Filter
              </IconButton>

              <Button
                variant="contained"
                color="primary"
                onClick={() => setIsCreateDialogOpen(true)}
            >
                Create Class
            </Button>
            </Box>

            

            <SideBarFilter
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                filterClassName={filterClassName}
                setFilterClassName={setFilterClassName}
                filterTrainer={filterTrainer}
                setFilterTrainer={setFilterTrainer}
                filterDeliveryMode={filterDeliveryMode}
                setFilterDeliveryMode={setFilterDeliveryMode}
                handleApplyFilter={applyFilters}
                handleResetFilter={resetFilters}
            />
   
    <div>
      {isLoading ? (
        <Box display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh">
        <CircularProgress />
        </Box>
      ) : (
        <Grid container spacing={2}>
          {classes.length === 0 ? (
            // Display a message when no classes are found
            <Box textAlign="center" width="100%" mt={20}>
              <Typography variant="subtitle1">
                No classes found. Please contact admin.
              </Typography>
            </Box>
          ) : (
            // Map and display class cards when data is available
            classes.map((classe) => (
              <Grid item xs={12} md={6} lg={4} key={classe.id}>
                <ClassCard
                  classId={classe.id}
                  className={classe.name}
                  slotsLeft={classe.seat_availability}
                  moduleName={classe.module_id.name}
                  startDate={classe.start_date}
                  endDate={classe.end_date}
                  startTime={classe.start_time}
                  endTime={classe.end_time}
                 trainerName={classe.trainer_id?.full_name}
                  deliveryMode={classe.mode_of_delivery}
                  classCapacity={classe.capacity}
                  path='/admin/class'
                />
              </Grid>
            ))
          )}
        </Grid>
      )}
    </div>
    <CreateClassDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        onSubmit={handleCreateClass}
      />
   
    </>
  );
}

export default AdminClasses;