// Author : Ayesha Shaikh
// Created on 02 sept 2024

import { Backdrop, Typography } from "@mui/material";
import {
  Document,
  Font,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function TrainerReportPdf() {
  const userId = localStorage.getItem("userId");
  const [error, setError] = useState(null);

  const styles = StyleSheet.create({
    document: { padding: 10 },
    page: {
      fontFamily: "Open Sans",

      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    table: {
      marginTop: 10,
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: 10,
    },
    tableRow: { flexDirection: "row" },
    tableCell: {
      padding: 5,
      fontSize: 12,
      textAlign: "center",
      flex: 1,
      border: "0.5px solid black",
    },

    tableHeaderCell: {
      fontFamily: "Open Sans",
      padding: 5,
      fontSize: 12,
      textAlign: "center",
      fontWeight: "bold",
      flex: 1,
      border: "0.5px solid black",

      backgroundColor: "#7ff2ba",
      color: "#002e17",
    },
    tableCell1: {
      padding: 5,
      fontSize: 12,
      textAlign: "center",
      flex: 3,
      border: "0.5px solid black",
    },
    tableHeaderCell1: {
      fontFamily: "Open Sans",
      padding: 5,
      fontSize: 12,
      textAlign: "center",
      fontWeight: "bold",
      flex: 3,
      border: "0.5px solid black",

      backgroundColor: "#7ff2ba",
      color: "#002e17",
    },

    tableHeader: { flexDirection: "row" },
    text: {
      fontSize: 17,
    },
    name: {
      marginTop: 10,
      flexDirection: "row",
      alignItems: "center",
    },
    title: {
      fontFamily: "Open Sans",
      fontWeight: "normal",
      fontSize: 14,
    },
    subtitle: {
      fontFamily: "Open Sans",
      fontWeight: "bold",
      fontSize: 14,
    },
    subtitle1: {
      fontFamily: "Open Sans",

      fontSize: 14,
      borderRadius: "50%",

      paddingLeft: "10px",
      paddingRight: "10px",
      backgroundColor: "#7ff2ba",
    },
  });

  const [details, setDetails] = useState({});
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [demoData,setDemodata] =useState([])
  const [taskcreateddata, setTaskCreatedData] = useState([]);
  const [completedData, setCompletedData] = useState([]);
  const[classData,setClassData] =useState([])
  const [userDetails, setUserDetails] =useState([])

  // Retrieve the state from localStorage && Post data
  useEffect(() => {
    const storedClassDetails = localStorage.getItem("details");
    console.log(JSON.parse(storedClassDetails));
    const details = JSON.parse(storedClassDetails);
    console.log(details)
    if (storedClassDetails) {
      setDetails(JSON.parse([storedClassDetails]));
    }

    setLoading(true);
    const requestData = {
        user_id: parseInt(userId),
      filter: details?.filterfor,
      from_date: details?.fromDate ,
      to_date: details?.toDate ,
      report_for: details?.trainerName?.user_id 
    
    };

    // Fetch data using selectedRowId and set it in the state

    fetch(`${API_ENDPOINT}reports/trainergeneratereport`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (response.status === 200) {
          // setTaskLoading(false);
          return response.json();
        } else if (response.status === 403) {
          setLoading(false);
          setError("unknown user");
        } else if (response.status === 404) {
          setLoading(false);
          setError("user not found");
        } else if (response.status === 400) {
          setLoading(false);
          setError("invalid filter date range");
        } else {
          setError("Server Error");
          setLoading(false);
        }
      })
      .then((result) => {
      
        //  const created = result.created.flatMap((i) =>( i))
         console.log("result",result)
        // const completed = result.completed.map((i) => i);
        
    setUserDetails(result[0])
         setTaskCreatedData(result[2].created);
         setCompletedData(result[2].completed);
        setDemodata(result[1].demos)
       setClassData(result[3].classes)
        setData(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        setError("Error fetching data");
      });

  },[]);







  return (
    <>
      {loading ? (
        <Backdrop
          open={loading}
          style={{ zIndex: 9999 }}
          backgroundColor="white"
        >
          <Typography
            variant="h2"
            color="error"
            fontWeight={800}
            marginRight={2}
          >
            Generating Report
          </Typography>
          <img
            src="/Hourglass1.gif"
            alt="Error"
            style={{ width: "200px", height: "200px" }}
          />
        </Backdrop>
      ) : error && !loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src="/browser.png"
            alt="Error"
            style={{ width: "200px", height: "200px" }}
          />
          <Typography variant="h3">{error}</Typography>
          <Typography variant="h5">Please try again later</Typography>
        </div>
      ) : (
        <>
          <PDFViewer style={{ width: "100%", height: "100vh" }}>
            <Document style={styles.document}>
              <Page size="A4" style={styles.page}>
                <View>
                  <Text>Dear Seniors,</Text>
                  <Text>Greeting !!</Text>
                  <View style={styles.name}>
                    <Text style={styles.title}>
                      Please refer the work report dated :{" "}
                    </Text>
                    <Text style={styles.subtitle}>
                      {userDetails?.report_date_range}
                    </Text>
                  </View>

                  <View style={styles.name}>
                    <Text style={styles.title}>Name : </Text>
                    <Text style={styles.subtitle}>
                      {userDetails?.first_name} {userDetails?.last_name}
                    
                    </Text>
                    <Text style={styles.subtitle1}>{userDetails?.role}</Text>
                  </View>
                </View>

                {/* Demo details */}
                <View style={{ marginTop: "20px" }}>
                  <Text style={styles.text}>Demo Details</Text>
                  <Text
                    style={{
                      color: "brown",
                      fontWeight: 600,
                      marginTop: "10px",
                      fontSize: "15px",
                    }}
                  ></Text>

                  {demoData.length === 0 ? (
                    <Text
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "bold",
                        fontSize: 12,
                        textAlign: "center",
                      }}
                    >
                      No Demo Data
                    </Text>
                  ) : (
                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        <Text style={styles.tableHeaderCell1}>Class Name</Text>
                        <Text style={styles.tableHeaderCell}>Total Demos</Text>
                        <Text style={styles.tableHeaderCell}>Demo Missed</Text>
                        <Text style={styles.tableHeaderCell}>
                          Demo Completed
                        </Text>
                        <Text style={styles.tableHeaderCell}>
                          Demo Evaluated
                        </Text>
                      </View>
                      {demoData.map((task, index) => (
                        <View style={styles.tableRow} key={index}>
                          <Text style={styles.tableCell1}>
                            {task.className}
                          </Text>
                          <Text style={styles.tableCell}>
                            {task.totalDemos}
                          </Text>
                          <Text style={styles.tableCell}>
                            {task.missedDemos}
                          </Text>
                          <Text style={styles.tableCell}>
                            {task.completedDemos}
                          </Text>
                          <Text style={styles.tableCell}>
                            {task.evaluatedDemos}
                          </Text>
                        </View>
                      ))}
                    </View>
                  )}
                </View>

                {/* Task Details */}
                <View style={{ marginTop: "30px" }}>
                  <Text style={styles.text}>Task Details</Text>
                  <Text
                    style={{
                      color: "brown",
                      fontWeight: 600,
                      marginTop: "25px",
                      fontSize: "15px",
                    }}
                  >
                    Closed Task
                  </Text>
                  {completedData.length === 0 ? (
                    <Text
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "bold",
                        fontSize: 12,
                        textAlign: "center",
                      }}
                    >
                      No Closed Tasks
                    </Text>
                  ) : (
                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        <Text style={styles.tableHeaderCell1}>Task Name</Text>
                        <Text style={styles.tableHeaderCell}>Total Closed</Text>
                        <Text style={styles.tableHeaderCell}>
                          Completed (connected / Attended / Visited / Accepeted)
                        </Text>
                        <Text style={styles.tableHeaderCell}>
                          Incompleted (Not Connected / Not Attended / Not
                          Visited / Not Accepted )
                        </Text>
                      </View>
                      {completedData.map((task, index) => (
                        <View style={styles.tableRow} key={index}>
                          <Text style={styles.tableCell1}>
                            {task?.type_of_task}
                          </Text>
                          <Text style={styles.tableCell}>
                            {task?.totalTasks}
                          </Text>
                          <Text style={styles.tableCell}>
                            {task?.contactableTasks}
                          </Text>
                          <Text style={styles.tableCell}>
                            {task?.nonContactableTasks}
                          </Text>
                        </View>
                      ))}
                    </View>
                  )}

                  <Text
                    style={{
                      color: "green",
                      fontWeight: 600,
                      fontSize: "15px",
                      marginTop: "25px",
                    }}
                  >
                    Created Task
                  </Text>

                  {taskcreateddata.length === 0 ? (
                    <Text
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "bold",
                        fontSize: 12,
                        textAlign: "center",
                      }}
                    >
                      No Created Tasks
                    </Text>
                  ) : (
                    <View style={styles.table}>
                      <View style={styles.tableRow}>
                        <Text style={styles.tableHeaderCell}>Task Name</Text>
                        <Text style={styles.tableHeaderCell}>
                          Total Created
                        </Text>
                      </View>
                      {taskcreateddata.map((task, index) => (
                        <View style={styles.tableRow} key={index}>
                          <Text style={styles.tableCell}>
                            {task?.type_of_task}
                          </Text>
                          <Text style={styles.tableCell}>
                            {task?.totalTasks}
                          </Text>
                        </View>
                      ))}
                    </View>
                  )}
                </View>

          
               
              </Page>

      {/* Class Details */}
              <Page size="A4" style={styles.page}>
              <View style={{ marginTop: "30px" }}>
                  <Text style={styles.text}>Class Details</Text>
                  {classData.length === 0 ? (
                    <Text
                      style={{
                        fontFamily: "Open Sans",
                        fontWeight: "bold",
                        fontSize: 12,
                        textAlign: "center",
                      }}
                    >
                      No Class Details
                    </Text>
                  ) : (
                    <View style={{ marginTop: "20px" }}>
                      {classData.map((test, index) => {
                        return (
                          <React.Fragment key={index}>
                            <Text style={{ marginTop: "20px" , fontSize:'16px' , fontWeight:500 }}>
                              {test.test_name}
                            </Text>
                            {test?.detail?.map((testCategory) => {
                              return (
                                <View>
                                  <View style={{ marginTop: "20px" }}>
                                    <View
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Text style={{ fontSize: "14px" }}>
                                        Class Name{" "}
                                      </Text>
                                      <Text
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        : {testCategory.name}
                                      </Text>
                                    </View>

                                    <View
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Text style={{ fontSize: "14px" }}>
                                        Branch{" "}
                                      </Text>
                                      <Text
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        : {testCategory.branch_id.branch_name}
                                      </Text>
                                    </View>

                                    <View
                                      style={{
                                        display: "flex",
                                        alignItems: "center",
                                        flexDirection: "row",
                                      }}
                                    >
                                      <Text style={{ fontSize: "14px" }}>
                                        Capacity{" "}
                                      </Text>
                                      <Text
                                        style={{
                                          fontSize: "14px",
                                          fontWeight: 600,
                                        }}
                                      >
                                        : {testCategory.capacity}
                                      </Text>
                                    </View>
                                  </View>

                                  <View style={{ marginTop: "15px" }}>
                                    <View style={styles.table}>
                                      <View style={styles.tableRow}>
                                        <Text style={styles.tableHeaderCell}>
                                          Date
                                        </Text>
                                        <Text style={styles.tableHeaderCell}>
                                          Enrolled Students
                                        </Text>
                                        <Text style={styles.tableHeaderCell}>
                                          Present Students
                                        </Text>
                                        <Text style={styles.tableHeaderCell}>
                                          Absent Students
                                        </Text>
                                        <Text style={styles.tableHeaderCell}>
                                          Leave{" "}
                                        </Text>
                                        <Text style={styles.tableHeaderCell}>
                                          Attendance Not Marked
                                        </Text>
                                      </View>
                                      {testCategory.student_info.map(
                                        (task, index) => {
                                          const isSunday =
                                            new Date(task.date).getDay() === 0;
                                          return (
                                            <View
                                              style={styles.tableRow}
                                              key={index}
                                            >
                                              <Text
                                                style={{
                                                  backgroundColor: isSunday
                                                    ? "#eeb4b8"
                                                    : "",
                                                  ...styles.tableCell,
                                                }}
                                              >
                                                {task.date}
                                              </Text>
                                              <Text
                                                style={{
                                                  backgroundColor: isSunday
                                                    ? "#eeb4b8"
                                                    : "",
                                                  ...styles.tableCell,
                                                }}
                                              >
                                                {task.enrolled}
                                              </Text>
                                              <Text
                                                style={{
                                                  backgroundColor: isSunday
                                                    ? "#eeb4b8"
                                                    : "",
                                                  ...styles.tableCell,
                                                }}
                                              >
                                                {task.present}
                                              </Text>
                                              <Text
                                                style={{
                                                  backgroundColor: isSunday
                                                    ? "#eeb4b8"
                                                    : "",
                                                  ...styles.tableCell,
                                                }}
                                              >
                                                {task.absent}
                                              </Text>
                                              <Text
                                                style={{
                                                  backgroundColor: isSunday
                                                    ? "#eeb4b8"
                                                    : "",
                                                  ...styles.tableCell,
                                                }}
                                              >
                                                {task.leave}
                                              </Text>
                                              <Text
                                                style={{
                                                  backgroundColor: isSunday
                                                    ? "#eeb4b8"
                                                    : "",
                                                  ...styles.tableCell,
                                                }}
                                              >
                                                {task.not_marked}
                                              </Text>
                                            </View>
                                          );
                                        }
                                      )}
                                    </View>
                                  </View>
                                </View>
                              );
                            })}
                          </React.Fragment>
                        );
                      })}
                    </View>
                  )}
                </View>
              </Page>
            </Document>
          </PDFViewer>
        </>
      )}
    </>
  );
}
