import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import {
  Autocomplete,
  Button,
  Collapse,
  Grid,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";
export default function RecycleRequestFilter({ setRecycleData, originalData,setLoading,showSnackbar,setSearchData }) {
  const userId = localStorage.getItem("userId");
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();

  const [open, setOpen] = useState(false);
  const [name, setName] = useState("");
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [service, setservice] = useState([]);
  const [subserviceList, setSubserviceList] = useState([]);
  const [subservicename, setSubserviceName] = useState([]);
  const [leadSourceList, setLeadSourceList] =useState([])
  const [leadSourceName, setLeadSourceName] =useState([])
  const [subleadSourceList, setsubLeadSourceList] =useState([])
  const [subleadSourceName, setsubLeadSourceName] =useState([])
  const [stagesList, setStagesList] = useState([]);
  const [staegename, setStageName] = useState([]);
  const [recycleByList, setRecycleByList] = useState([]);
  const [recycleByName, setRecycleByName] = useState([]);
  const [recycledReasonlist, setRecycleReasonList] =useState([])
  const [recycleReasonName, setRecycleReasonName] =useState([])
  const [recycledTo, setRecycledTo] = useState([]);
  const [fromDate, setfromDate] = useState(null);
  const [toDate, setTodate] = useState(null);
  const [status, setStaus] = useState([]);

  const handleToggle = () => {
    setOpen(!open);
  };

  const handleNamechange = (e) => {
    setName(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  const allbranchList = [{ branch_name: "Select All" }, ...branchlist];

  const handelbranchchange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}service`);
        if (response.ok) {
          const data = await response.json();
          setServiceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.log("error fetching data", error);
      }
    };
    fetchData();
  }, []);

  const handleservicechange = (event, value) => {
    setservice(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
          setSubserviceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.log("Error Fetching data", error);
      }
    };
    fetchData();
  }, []);

  
  const allsubserviceList = [{ name: "Select All" }, ...subserviceList];
  const handlesubserviceChange = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      setSubserviceName(subserviceList);
    } else {
      setSubserviceName(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}leadsource`);
        if (response.ok) {
          const data = await response.json();
          setLeadSourceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.log("Error Fetching data", error);
      }
    };
    fetchData();
  }, []);


  
  const allleadSourceList = [{ name: "Select All" }, ...leadSourceList];
  const handleLeadSourceChange = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      setLeadSourceName(leadSourceList);
    } else {
      setLeadSourceName(value);
    }
  };


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subleadsource`);
        if (response.ok) {
          const data = await response.json();
          setsubLeadSourceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.log("Error Fetching data", error);
      }
    };
    fetchData();
  }, []);


  const allSubleadSourceList = [{ source_type: "Select All" }, ...subleadSourceList];
  const handleSubLeadSourceChange = (event, value) => {
    if (value.some((option) => option.source_type === "Select All")) {
      setsubLeadSourceName(subleadSourceList);
    } else {
      setsubLeadSourceName(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}stage`);
        if (response.ok) {
          const data = await response.json();
          setStagesList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.log("Error Fetching Data:", error);
      }
    };
    fetchData();
  }, []);

  const allStages = [{ stage_name: "Select All" }, ...stagesList];
  const handlestageChange = (event, value) => {
    if (value.some((option) => option.stage_name === "Select All")) {
      setStageName(stagesList);
    } else {
      setStageName(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setRecycleByList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const allrecycleBy = [{ first_name: "Select All" }, ...recycleByList];
  const handlechangeRecycleBy = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setRecycleByName(recycleByList);
    } else {
      setRecycleByName(value);
    }
  };


   // Recycle Reason
   useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}recyclereason`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setRecycleReasonList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allrecyclereasonList = [{ recycle_reason: "Select All" }, ...recycledReasonlist];
  const handlechangeRecyclereason = (event, value) => {
    if (value.some((option) => option.recycle_reason === "Select All")) {
      setRecycleReasonName(recycledReasonlist);
    } else {
      setRecycleReasonName(value);
    }
  };

  const handlechangeRecycleTo = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setRecycledTo(recycleByList);
    } else {
      setRecycledTo(value);
    }
  };

  const handleFromdateChange = (e) => {
    setfromDate(e.target.value);
  };

  const handleTodateChange = (e) => {
    setTodate(e.target.value);
  };

  const [statusList, setStatusList] = useState([
    { id: 1, name: "Pending", value: "pending" },
    { id: 2, name: "Accepted", value: "accepted" },
    { id: 3, name: "Cancelled", value: "cancelled" },
  ]);

  const handleStatusChange = (event, value) => {
    setStaus(value);
  };
  const handleApplyFilter = async (event) => {
    setLoading(true)

    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json" // Make sure to keep the content type
    };
    const requestData = {
      requested_from_date: fromDate || null,
      requested_to_date: toDate || null,
      recycle_by: recycleByName.map((i) => i.id),
       branch_id: branch.map((i) => i.id),
      // service_id: service.map((i) => i.id),
       sub_service_id: subservicename.map((i) => i.id),
        lead_source_type_id:leadSourceName.map((i) => i.id),
        sub_lead_source_type_id:subleadSourceName.map((i) => i.id),
       stage_id: staegename.map((i) => i.id),
       request_reason_id :recycleReasonName.map((i) => i.id),
      status: status.map((i) => i.value),
      // request_to: recycledTo.map((i) => i.id),
      user_id: parseInt(userId),
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/recyclefilter`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        setLoading(false)
       
        setRecycleData(result);
        setSearchData(result)
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }}
        catch{
          navigate("/page500");
        }
  };

  const handleResetFilter = () => {
    setfromDate("");
    setTodate("");
    setName("");
    setBranch([]);
    setservice([]);
    setSubserviceName([]);
    setStageName([]);
    setRecycleByName([]);
    setStaus([]);
    setRecycledTo([])
    setLeadSourceName([])
    setsubLeadSourceName([])
    setRecycleData(originalData);
    setSearchData(originalData)
    setRecycleReasonName([])
  };
  return (
    <>
      <MainCard>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              marginLeft: "5px",
              fontSize: "24px",
              fontWeight: 700,
            }}
          >
            Recycle Request
          </Typography>
          <FilterListOutlinedIcon fontSize="medium" onClick={handleToggle} />
        </Stack>

        <Collapse in={open} sx={{ marginTop: "10px" }}>
          <Grid container spacing={2}>
            {/* <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600 }}>Enquiry Name</Typography>
              <TextField
                size="small"
                value={name}
                onChange={handleNamechange}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
              />
            </Grid> */}

            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Branch</Typography>

              <Autocomplete
                size="small"
                fullWidth
                value={branch}
                multiple
                onChange={handelbranchchange}
                options={allbranchList}
                getOptionLabel={(option) => option.branch_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            {/* <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Service</Typography>

              <Autocomplete
                size="small"
                fullWidth
                value={service}
                multiple
                onChange={handleservicechange}
                options={serviceList}
                getOptionLabel={(option) => option.service_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid> */}

            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Sub Service</Typography>

              <Autocomplete
                size="small"
                fullWidth
                value={subservicename}
                multiple
                onChange={handlesubserviceChange}
                options={allsubserviceList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Lead Sorce</Typography>

              <Autocomplete
                size="small"
                fullWidth
                value={leadSourceName}
                multiple
                onChange={handleLeadSourceChange}
                options={allleadSourceList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Sub Lead Sorce</Typography>

              <Autocomplete
                size="small"
                fullWidth
                value={subleadSourceName}
                multiple
                onChange={handleSubLeadSourceChange}
                options={allSubleadSourceList}
                getOptionLabel={(option) => option.source_type}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Stages</Typography>

              <Autocomplete
                size="small"
                fullWidth
                value={staegename}
                multiple
                onChange={handlestageChange}
                options={allStages}
                getOptionLabel={(option) => option.stage_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Recycle By</Typography>

              <Autocomplete
                size="small"
                fullWidth
                value={recycleByName}
                multiple
                onChange={handlechangeRecycleBy}
                options={allrecycleBy}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Recycle Reasons</Typography>

              <Autocomplete
                size="small"
                fullWidth
                value={recycleReasonName}
                multiple
                onChange={handlechangeRecyclereason}
                options={allrecyclereasonList}
                getOptionLabel={(option) =>option.recycle_reason
                  
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Status</Typography>

              <Autocomplete
                size="small"
                fullWidth
                value={status}
                multiple
                onChange={handleStatusChange}
                options={statusList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>
                Request From Date
              </Typography>
              <TextField
                size="small"
                value={fromDate}
                type="date"
                onChange={handleFromdateChange}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
              />
            </Grid>
            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Request To Date</Typography>
              <TextField
                size="small"
                value={toDate}
                type="date"
                onChange={handleTodateChange}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
              />
            </Grid>

         

            <Grid item xs={12} lg={12} md={12} sm={12} textAlign="end">
              <Button
                variant="contained"
                color="error"
                style={{
                  borderRadius: "24px",
                  fontWeight: 500,
                  fontSize: "14px",
                  marginRight: "8px",
                }}
                onClick={handleResetFilter}
              >
                Reset Filter
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{
                  borderRadius: "24px",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
                onClick={handleApplyFilter}
              >
                Apply Filter
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </MainCard>
    </>
  );
}
