// Author :Ayesha Shaikh
// Date : 6 Aug 2024

import {
  Box,
  Chip,
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";

export default function ApproveOnlinePaymentInstallation({ data }) {
  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: "20px",
    "& .MuiTableCell-head": {
      backgroundColor: "#abf7b1",
      fontWeight: 600,
      padding: "8px 16px",

      borderRight: "0.1px solid #C3C3C3",
      borderLeft: "0.1px solid #C3C3C3",
      color: "#000",
       textAlign:'center'
    },
    "& .MuiTableCell-body": {
      fontSize: "14px",
      fontWeight: 600,
      borderRight: "0.1px solid #C3C3C3",
      borderLeft: "0.1px solid #C3C3C3",
      // align: "center",
      padding: "8px 8px",
      textAlign:'center'
      
    },

  }));
  return (
    <Box sx={{ maxWidth: "100%" }}>
      <Typography sx={{ fontSize: "16px" }}>
        {" "}
        Name : <strong>{data?.enquiry_name?.first_name} {data?.enquiry_name?.last_name}</strong>
      </Typography>
      <Typography sx={{ fontSize: "16px" }} mt={1}>
        {" "}
        Package : <strong>{data?.package_name} </strong>
      </Typography>
      <Typography sx={{ fontSize: "16px" }} mt={1}>
        Amount Of Package : <strong>₹ {data?.amount_of_package} </strong>
      </Typography>
      <Typography sx={{ fontSize: "16px" }} mt={1}>
        Amount To Be Paid : <strong>₹ {data?.amount_to_paid}</strong>
      </Typography>
      <Typography sx={{ fontSize: "16px" }} mt={1}>
        Discount Amount : <strong>₹ {data?.discount_amount} </strong>
      </Typography>
      <StyledTableContainer
        component={Paper}
        style={{ maxWidth: "100%", overflow: "auto" }}
      >
        <Table aria-label="simple table" stickyHeader style={{ minWidth: 700 }}>
          <TableHead>
            <TableRow>
            <TableCell style={{ minWidth: 80 }}>Sr. No</TableCell>
              <TableCell style={{ minWidth: 100 }}>Amount Collected</TableCell>
              <TableCell style={{ minWidth: 100 }}>Installment</TableCell>
              <TableCell style={{ minWidth: 100 }}>Payment Mode</TableCell>
              <TableCell style={{ minWidth: 130 }}>Status</TableCell>
              <TableCell style={{ minWidth: 100 }}>Payment Date</TableCell>
              <TableCell style={{ minWidth: 100 }}>Created By</TableCell>
              <TableCell style={{ minWidth: 100 }}>Approved By</TableCell>
              <TableCell style={{ minWidth: 100 }}>Approved Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.payment?.map((row) => (
              <TableRow key={row.id}>
                 <TableCell component="th" scope="row">{row?.sl_no}</TableCell>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{textAlign: 'center'}}
                >{row?.amount_collected} /-</TableCell>
                 
                <TableCell component="th" scope="row">{row?.installment}</TableCell>
                <TableCell component="th" scope="row">{row?.payment_mode?.payment_mode}</TableCell>
                <TableCell component="th" scope="row">
                  {row?.status === "pending" && (
                    <Chip label="Pending" sx={{background:'#FFAC1C'}}/>
                  )}
                  {row?.status === "confirmed" && (
                    <Chip label="Approved" color="secondary" />
                  )}
                   {row?.status === "failed" && (
                    <Chip label="Failed " color="error" />
                  )}
                    {row?.status === "cancelled" && (
                    <Chip label="Cancelled  " color="error" />
                  )}
                </TableCell>
                <TableCell component="th" scope="row">{row?.payment_date}</TableCell>
                <TableCell component="th" scope="row">{row?.collected_by?.first_name} {row?.collected_by?.last_name}</TableCell>
                <TableCell component="th" scope="row">{row?.approved_by?.first_name} {row?.approved_by?.last_name}</TableCell>
                <TableCell component="th" scope="row">{row?.approved_date} </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </Box>
  );
}
