import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../components/Maincard";
import SearchIcon from "@mui/icons-material/Search";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CustomPopup from "../../components/CustomPopup";
import RepresentativeForm from "./RepresentativeForm";
import ClearIcon from "@mui/icons-material/Clear";
import { DataGrid } from "@mui/x-data-grid";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../utils/token";
export default function EventRoadmap() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const noOptionsText = "Loading...";
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const [eventName, setEventName] = useState(null);
  const [eventList, setEventList] = useState([]);
  const [openPopup, setOpenPopup] = useState(false);
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
const [eventLoading,setEventLoading]=useState(false)
  
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  
  useEffect(() => {
    const fetchData = async () => {
setEventLoading(true)
      try {
        const response = await fetch(`${API_ENDPOINT}campaign/event/withname`);
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
          const filteredcampaign = data.filter(
            (campaign) => campaign.campaign_type_id?.id === 1
          );
          setEventList(filteredcampaign);
setEventLoading(false)
          console.log("data1", filteredcampaign);
        } else {
          setEventLoading(false)
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        setEventLoading(false)
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleEventChange = (event,value) => {
    setEventName(value);
  };
  console.log("event",eventName)

  const handleClosePopup = () => {
    setOpenPopup(false);
  };

  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const columns = [
    {
      field: "event",
      headerName: "Event",
      width: 450,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div> {params.row?.event_id?.campaign_name}</div>,
    },
    {
      field: "user",
      headerName: "User",
      width: 170,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          {params?.row?.counsellor_id?.first_name}{" "}
          {params?.row?.counsellor_id?.last_name}{" "}
        </div>
      ),
    },
    {
      field: "deskType",
      headerName: "Desk Type",
      width: 170,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div> {params.row?.registration_desk}</div>,
    },
    {
      field: "deskNumber",
      headerName: "Desk Number",
      width: 170,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div> {params.row?.desk_number}</div>,
    },
    {
      field: "service",
      headerName: "Service",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div> {params.row?.service_id?.service_name}</div>
      ),
    },
    {
      field: "subService",
      headerName: "Sub Service",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div> {params.row?.sub_service_id?.name}</div>,
    },
    {
      field: "country",
      headerName: "Country",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div> </div>,
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }

  const handleReset = () => {
    setEventName(null);
    setEventData([]);
  };

  const fetchData = async () => {
    try {
      setLoading(true);
      const response = await fetch(
        `${API_ENDPOINT}route2.0/fetcheventroadmap/${eventName.id}`
      );

      // Check if the response is successful (status code 200-299)
      if (response.ok) {
        const result = await response.json();
        const sortedData = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setEventData(sortedData);
        setLoading(false);
      } else if (response.status === 403) {
        showSnackbar("No Found Any Data", "error");
        setLoading(false);
      } else {
        setLoading(false);
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error.message);
      setLoading(false);
    }
  };

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} md={4} sm={6} lg={2.5}>
          <Paper
            sx={{
              height: "43px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "24px",
            }}
          >
            <Stack
              sx={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
              }}
            >
              <Box height="35px">
                <img src="/event roadmap.png" height="100%" />
              </Box>
              <Typography sx={{ fontSize: "20px", fontWeight: 600 }} ml={2}>
                Event Road Map
              </Typography>
            </Stack>
          </Paper>
        </Grid>
        <Grid item xs={12} md={8} sm={6} lg={9} textAlign="end">
          <Button
            variant="contained"
            startIcon={<AddCircleOutlineIcon />}
            onClick={() => handleOpenPopup()}
            style={{ borderRadius: "25px" }}
          >
            Add New Representative
          </Button>
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={6} md={8} sm={8}>
                <FormControl sx={{ width: "100%" }}>
                  <Typography sx={{ fontWeight: 600, fontSize: "18px" }}>
                    Event
                  </Typography>
                  {/* <Select
                    value={eventName}
                    onChange={handleEventChange}
                    MenuProps={MenuProps}
                    sx={{ borderRadius: "15px" }}
                  >
                    {eventList.map((name) => (
                      <MenuItem key={name.id} value={name.id}>
                        {name.campaign_name}
                      </MenuItem>
                    ))}
                  </Select> */}
                   <Autocomplete
                size="small"
                value={eventName}
                noOptionsText={
                    eventLoading && eventList.length === 0
                      ? noOptionsText
                      : ""
                  }
                onChange={handleEventChange}
                options={eventList}
                getOptionLabel={(option) =>
                 option.campaign_name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                      endAdornment: (
                        <>
                          {eventLoading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />

                </FormControl>
              </Grid>

              <Grid item xs={12} lg={6} md={4} sm={4}>
                <Typography
                  sx={{
                    fontWeight: 600,
                    visibility: "hidden",
                    fontSize: "18px",
                  }}
                >
                  Event
                </Typography>
                <Stack
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                  }}
                >
                  <Button
                    variant="contained"
                    color="secondary"
                    style={{
                      fontWeight: 500,
                      fontSize: "15px",
                      borderRadius: "25px",
                    }}
                    onClick={fetchData}
                  >
                    {<SearchIcon sx={{ fontSize: 20 }} />} Search
                  </Button>

                  <Button
                    variant="contained"
                    color="error"
                    style={{
                      fontWeight: 500,
                      fontSize: "15px",
                      borderRadius: "25px",
                      marginLeft: "10px",
                    }}
                    onClick={handleReset}
                  >
                    {<ClearIcon sx={{ fontSize: 20 }} />} Clear
                  </Button>
                </Stack>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <MainCard>
            <Box
              sx={{
                height: "100%",
                width: "100%",
                "& .header-cell": {
                  backgroundColor: "#abf7b1",
                  fontWeight: "bold",
                  borderRight: "0.1px solid #C3C3C3",
                },
              }}
            >
              <DataGrid
                rows={eventData}
                columns={columns}
                autoHeight
                slots={{
                  noRowsOverlay: CustomNoRowsOverlay,
                }}
                initialState={{
                  pagination: {
                    paginationModel: {
                      pageSize: 25,
                    },
                  },
                }}
                sx={{ marginTop: "20px" }}
                pageSizeOptions={[25, 50, 100]}
              />
            </Box>
          </MainCard>
        </Grid>
      </Grid>

      <CustomPopup
        open={openPopup}
        onClose={handleClosePopup}
        title="Add New Representative"
        maxWidth="lg"
        showDivider="true"
        component={<RepresentativeForm onClose={handleClosePopup} />}
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
