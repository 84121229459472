import { Box, Button, Typography } from '@mui/material'
import React from 'react'

export default function NoEnquiry({ onClose }) {
    const handleClose = () => {
        onClose();
      };

  return (
    <Box
    sx={{
      display: "flex",
      alignItems: "center",
      flexDirection: "column",
      justifyContent: "center",
    }}
  >
    <Box sx={{ width: "80px", height: "80px" }}>
          <img src="/search.png" width="100%" height="100%" />
        </Box>
    <Typography
      mt={2.5}
      sx={{ fontSize: "30px", fontWeight: 600 }}
      color="#6E6C6C"
    >
      No Enquiry
    </Typography>
    <Typography
      mt={2}
      sx={{ fontSize: "20px", fontWeight: 600 }}
      color="#6E6C6C"
    >
      {" "}
      Please select atleast 1 enquiry for{" "}
    </Typography>
    <Typography sx={{ fontSize: "20px", fontWeight: 600 }} color="#6E6C6C">
      {" "}
      Transferring{" "}
    </Typography>
    <Button
      sx={{
        marginTop: "20px",
        borderRadius: "20px",
        fontWeight: 600,
        fontSize: "18px",
      }}
      onClick={handleClose}
      variant="contained"
    >
      OK
    </Button>
  </Box>
  )
}
