import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Container, Grid, Typography } from '@mui/material';

const RequestDemoForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    // Handle form submission logic here
  };

  return (
    <Container maxWidth="xs">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h4" align="center">
              Request a Demo
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="name"
              control={control}
              defaultValue=""
              render={({ field }) => <TextField {...field} label="Your Name" fullWidth />}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="businessName"
              control={control}
              defaultValue=""
              render={({ field }) => <TextField {...field} label="Business Name" fullWidth />}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="phoneNumber"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Phone Number"
                  type="tel"
                  fullWidth
                  error={!!errors.phoneNumber}
                  helperText={errors.phoneNumber && 'Please enter a valid phone number'}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="email"
              control={control}
              defaultValue=""
              render={({ field }) => (
                <TextField
                  {...field}
                  label="Email"
                  type="email"
                  fullWidth
                  error={!!errors.email}
                  helperText={errors.email && 'Please enter a valid email address'}
                />
              )}
            />
          </Grid>
          <Grid item xs={12}>
            <Button type="submit" variant="contained" color="error" fullWidth>
              Request a demo
            </Button>
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default RequestDemoForm;
