import { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Alert,
  Box,
  Button,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  OutlinedInput,
  Stack,
  TextField,
  Typography,
  useMediaQuery
} from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import useScriptRef from '../../../hooks/useScriptRef';
import { useNavigate } from 'react-router-dom';
import { setEmail, setPassword, login } from '../../../../redux/features/auth/authSlice';
import { setUser, updateUserRole } from '../../../../redux/features/user/userSlice';
import CustomSnackbar from '../../../components/ui-component/snackbar/CustomSnackbar';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const LoginForm = ({ ...others }) => {
  const theme = useTheme();
  const scriptedRef = useScriptRef();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  const customization = useSelector((state) => state.customization);
  const [checked, setChecked] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [errorMessage, setErrorMessage] = useState('')
  const [errorStatus, setErrorStatus] = useState(false)
  const [rememberMe, setRememberMe] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
  
      const showSnackbar = (message, severity) => {
          setSnackbarMessage(message);
          setSnackbarSeverity(severity);
          setSnackbarOpen(true);
      };

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRef = useRef();

  const userRole = localStorage.getItem('userRole');
  const { email, password } = useSelector((state) => state.auth);

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      // Make the API request to authenticate the user
      const response = await fetch(`${API_ENDPOINT}user/login`, {
        method: 'POST',
        body: JSON.stringify({ email, password }),
        headers: {
          'Content-Type': 'application/json',
        },
      })

      if (response.ok) {
        const data = await response.json();
        const { first_name, last_name, email, mobile, user_role, advisor_id, admin_id, trainer_id, token, permission, id } = data;
        dispatch(
          setUser({ first_name, last_name, email, mobile, user_role, advisor_id, token, admin_id, trainer_id, permission, id })
        );
        dispatch(login(data));
        

        //console.log(user_role)
       // navigate('/dashboard');

        if (permission && permission.length >= 2) {
          // If user has 2 or more permissions, navigate to /role
          navigate('/role', { state: { permission } });
        } else {
          // If not, navigate to /dashboard
          localStorage.setItem('userRole', user_role); 
          dispatch(
            updateUserRole(user_role)
          );
          navigate('/dashboard');
        }
        
      /* if (user_role === 'admin') {
          navigate('/dashboard');
        } else if (user_role === 'advisor') {
          navigate('/dashboard');
        } else if (user_role === 'trainer') {
          navigate('/dashboard');
        } else {
          console.log('Unknown role. Contact the administrator.');
        }*/
      } else {
         if(response.status === 401) {
        //setErrorStatus(true)
        showSnackbar('Password is incorrect. Please try correct password.', 'error');
        //setErrorMessage('Wrong UserName or Password')
      } else if(response.status === 403) {
        showSnackbar('User does not exist', 'error');
      } 
      else if(response.status === 404) {
        showSnackbar('User with this email not found', 'error');
      }else if(response.status === 410) {
        showSnackbar('Your account is inactive. Please contact admin', 'error');
      }
       else {
       // setErrorStatus(true)
        showSnackbar('Server error', 'error');
        //setErrorMessage('Network Error')
      }
    } 
  }catch (error) {
    console.error('An unexpected error occurred:', error);
    showSnackbar('An unexpected error occurred. Please try again later.', 'error');
  }
};
  

  const handleClickShowPassword = () => {
    setShowPassword(!showPassword);
  };

  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };

  return (
    <>
          <form onSubmit={handleLogin}>
            <FormControl fullWidth>
              <InputLabel htmlFor="outlined-adornment-email-login">Email Address / Username</InputLabel>
              <OutlinedInput
                id="outlined-adornment-email-login"
                type="email"
                inputRef={userRef}
                value={email}
                onChange={(e) => dispatch(setEmail(e.target.value))}
                name="email"
                label="Email Address / Username"
                inputProps={{
                
                }}
                sx={{ borderRadius: '10px' }}
              />
             
            </FormControl>

            <Box mt={2}>

            <FormControl fullWidth >
              <InputLabel htmlFor="outlined-adornment-password-login">Password</InputLabel>
              <OutlinedInput
                id="outlined-adornment-password-login"
                type={showPassword ? 'text' : 'password'}
                value={password}
                onChange={(e) => dispatch(setPassword(e.target.value))}
                name="password"
                /*endAdornment={
                  <InputAdornment position="end">
                    <IconButton
                      aria-label="toggle password visibility"
                      onClick={handleClickShowPassword}
                      onMouseDown={handleMouseDownPassword}
                      edge="end"
                      size="large"
                    >
                      {showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }*/
                label="Password"
                inputProps={{}}
                sx={{ borderRadius: '10px' ,}}
              />
            </FormControl>
            </Box>

            {errorStatus && (
                <Box mt={2}>
                <Alert severity="error">{errorMessage}</Alert>
                </Box>
            )}

          {/*}  <Stack direction="row" alignItems="center" justifyContent="space-between" spacing={1}>
              <FormControlLabel
                control={
                  <Checkbox checked={checked} onChange={(event) => setChecked(event.target.checked)} name="checked" color="primary" />
                }
                label="Remember me"
              />
              </Stack>*/}
            

            <Box sx={{ mt: 4 }}>
              
                <Button fullWidth size="large" type="submit" variant="contained" style={{background:'#019E6F'}} onClick={() => handleLogin}>
                  Sign in
                </Button>
             
            </Box>
          </form>
          <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                severity={snackbarSeverity}
                />
     
    </>
  );
};

export default LoginForm;