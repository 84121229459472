import React, { useState } from 'react'
import MainCard from '../../newTestPrep/components/ui-component/card/MainCard';
import { Box, Stack, Typography } from '@mui/material';
import ReactApexChart from 'react-apexcharts';

function BranchBubbleChart() {
    const [chartData, setChartData] = useState({
        series: [],
        options: {
          chart: {
            type: 'donut',
          },
          plotOptions: {
            pie: {
              donut: {
                size: '30%', // Adjust the size to control the width of the slices
              },
            },
          },
        //   labels: percentages,
          colors: ['#019E6F', '#FABA3A', '#FF8C4D'],
          responsive: [{
            breakpoint: 480,
            options: {
              chart: {
                width: 400
              },
            }
          }],
          tooltip: {
            enabled: false,
          },
          legend: {
            show: false,
          },
        },
      });
  return (
    <div>
        <MainCard>
        <Stack display='flex'  flexDirection='column' justifyContent='center' alignItems='center'>
        <Typography
            sx={{
                fontFamily: 'Poppins',
                fontSize: '24px',
                fontWeight: 600,
                lineHeight: '36px',
              }}
        >Branch Performance</Typography>
        </Stack>
        <Stack display='flex'  flexDirection='column' justifyContent='center' alignItems='center'>

        {chartData.series.length > 0 ? (
          <ReactApexChart options={chartData.options} series={chartData.series} type="donut" width='280px' />
        ) : (
          <Typography
            sx={{
              fontFamily: 'Poppins',
              fontSize: '16px',
              fontWeight: 600,
              lineHeight: '24px',
              textAlign: 'center',
              color: '#6B7280',
            }}
          >No data available</Typography>
        )}
        </Stack>

        <Stack display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' mb={1}>
                <Stack>
                  <Box sx={{ bgcolor: '#01BE85', width: 10, height: 10, borderRadius:'50%' }}/>
                </Stack>
                <Stack display='flex' flexDirection='row' alignItems='center' width='100%'  justifyContent='space-between' marginLeft={1}>
                  <Typography>Lead to Demo Conversion </Typography>
                  
                </Stack>
                </Stack>

                <Stack display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' mb={1}>
                <Stack>
                  <Box sx={{ bgcolor: '#FABA3A', width: 10, height: 10, borderRadius:'50%' }}/>
                </Stack>
                <Stack display='flex' flexDirection='row' alignItems='center' width='100%'  justifyContent='space-between' marginLeft={1}>
                  <Typography>Demo to Enrollment Conversion </Typography>
                  
                </Stack>
                </Stack>

                <Stack display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                <Stack>
                  <Box sx={{ bgcolor: '#FF8C4C', width: 10, height: 10, borderRadius:'50%' }}/>
                </Stack>
                <Stack display='flex' flexDirection='row' alignItems='center' width='100%'  justifyContent='space-between' marginLeft={1}>
                  <Typography>Lead to Enrollment Conversion </Typography>
                  
                </Stack>
                </Stack>
        </MainCard>
    </div>
  )
}

export default BranchBubbleChart