import { useDispatch, useSelector } from 'react-redux';
import { removeToken, selectToken } from '../redux/features/token/tokenSlice';
import { useNavigate } from 'react-router-dom';

export const getToken = () => {
    //const token = useSelector(selectToken);
    return localStorage.getItem('token');
  };

  // Custom hook for getting token and handling logout
export const useGetTokenOrLogout = () => {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const tokenFromRedux = useSelector(selectToken);
  
    const getTokenOrLogout = () => {
      // Use token from Redux or localStorage
      const tokenToUse =  localStorage.getItem('token') || tokenFromRedux ;
  
      if (!tokenToUse) {
        dispatch(removeToken()); 
        navigate('/login'); 
        return null;
      }
  
      return tokenToUse;
    };
  
    return getTokenOrLogout;
  };