// Author: Ayesha Shaikh
// Date : 10 aug 2024

import {
  AppBar,
  Box,
  Button,
  Chip,
  Grid,
  Paper,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MainCard from "../../../components/Maincard";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import DashboardIcon from "@mui/icons-material/Dashboard";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function ScanQr() {
  const location = useLocation();
  const scanData = location.state?.scanData;
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();

  const [selectEdevent, setselectedEvent] = useState(null);
  const [mobile, setMobile] = useState(scanData?.mobile);
  const [uniCode, setUniqueCode] = useState(scanData?.qrcode);
  const [selectedChips, setSelectedChips] = useState(null);
  const [selectedButton, setSelectedButton] = useState("phone");

  // snackbar
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // select phone or  code button
  const handleButtoncriteria = (criteria) => {
    setSelectedButton(criteria);
  };

  // select event
  const handleChipClick = (campaignName) => {
    const selectedevent = scanData?.events.find(
      (event) => event.id === campaignName
    );
    // Toggle selection: if the chip is already selected, deselect it
    if (selectedChips === campaignName) {
      setSelectedChips(null);
      setselectedEvent(null);
    } else {
      setSelectedChips(campaignName);
      setselectedEvent(selectedevent);
    }
  };
  console.log(selectedChips);

  // Post Data for mark attendance
  const postData = async () => {
    const payload = {
      user_id: parseInt(userId),
      enquiry_id: scanData?.enquiry_id,
      event_id: selectedChips,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}eventattendance/markattendance`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        showSnackbar("Attendance Marked Successfully");
        setTimeout(() => {
          navigate('/registration-desk')
        }, 3000);
        
      } else if (response.status === 401) {
        showSnackbar("Not a user or user id undefined", "error");
        setTimeout(() => {
          navigate('/login')
        }, 1500);
       
      } else if (response.status === 403) {
        showSnackbar("Please select an event", "error");
      } else if (response.status === 404) {
        showSnackbar("Error marking attendance", "error");
      } else if (response.status === 409) {
        showSnackbar("Attendance for this lead and event has been marked", "error");
      } else {
        showSnackbar("Error Posting Data", "error");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      showSnackbar("Error Posting Data", "error");
    }
  };

 

  const handleAnotherScan = () => {
    navigate("/registration-desk");
  };

  return (
    <>
      <AppBar
        sx={{
          bgcolor: "white",
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 1000,
          boxShadow: " 0px 4px 5px 0px rgba(0, 0, 0, 0.3);",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            {" "}
            <img src="/Siec Logo.png" />
          </Box>
          <Box>
            <Link to="/dashboard">
              <Button
                variant="contained"
                style={{
                  borderRadius: "25px",
                  color: "white",
                  background: "#4ca64c",
                }}
                startIcon={<DashboardIcon />}
              >
                Dashboard
              </Button>
            </Link>
            <Link to="/">
              {" "}
              <Button
                color="error"
                variant="contained"
                style={{ borderRadius: "25px" }}
                startIcon={<img src="/icons8-move-up-64.png" width="20px" />}
              >
                Logout
              </Button>
            </Link>
          </Box>
        </Box>
      </AppBar>
      <Box
        sx={{
          background: "#eef2f6",
          minHeight: {
            xs: "100%",
            sm: "100%",
            md: "100%", // Medium screens
            lg: "100%", // Large screens
            xl: "100vh", // Extra large screens
          },
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
          m={2}
        >
          {/* <Grid
            item
            xs={12}
            md={12}
            sm={12}
            lg={12}
            sx={{ position: "absolute", top: 0, right: 3 }}
          >
            <Button
              color="error"
              variant="contained"
              style={{ borderRadius: "25px" }}
              startIcon={<img src="/icons8-move-up-64.png" width="20px" />}
              onClick={handleLogout}
            >
              Logout
            </Button>
          </Grid> */}
          {/* <Grid item xs={12} md={12} sm={12} lg={12} textAlign="center">
            <Box>
              {" "}
              <img src="/Siec Logo.png" />
            </Box>
          </Grid> */}
          <Grid item xs={12} md={7} sm={10} lg={7}>
            <Paper elevation={3} sx={{ padding: "20px", borderRadius: "15px" }}>
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12} sm={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{
                      borderRadius: "25px",
                      fontWeight: 600,
                      fontSize: "20px",
                      marginBottom: "10px",
                    }}
                    color="secondary"
                    onClick={handleAnotherScan}
                  >
                    {" "}
                    SCAN ANOTHER QR
                  </Button>
                  <Typography
                    style={{
                      fontWeight: 600,
                      fontSize: "34px",
                      color: "black",
                    }}
                    textAlign="center"
                  >
                    Link {scanData?.qrcode} with student
                  </Typography>
                </Grid>
                <Grid item xs={12} lg={12} md={12} sm={12}>
                  <Button
                    style={{
                      borderRadius: "25px",
                      background: selectedButton === "phone" ? "#2196F3" : "",
                      border:
                        selectedButton === "phone" ? "" : "0.5px solid #b2b2b4",
                      color:  selectedButton === "phone" ? "white": "black",
                    }}
                    onClick={() => handleButtoncriteria("phone")}
                  >
                    Phone Number
                  </Button>
                  <Button
                    style={{
                      borderRadius: "25px",
                      marginLeft: "10px",
                      border:
                        selectedButton === "code" ? "" : "0.5px solid #b2b2b4",
                      color:  selectedButton === "code" ? "white": "black",
                      background: selectedButton === "code" ? "#2196F3" : "",
                    }}
                    onClick={() => handleButtoncriteria("code")}
                  >
                    Unique Code
                  </Button>
                </Grid>

                <Grid item xs={12} lg={12} md={12} sm={12}>
                  <Typography
                    sx={{ fontWeight: 600, color: "black", fontSize: "16px" }}
                  >
                    {selectedButton === "phone"
                      ? " Enter Phone Number"
                      : "Enter Unique Code"}
                  </Typography>
                  <TextField
                    fullWidth
                    type={selectedButton === "phone" ? "number" : ""}
                    value={selectedButton === "phone" ? mobile : uniCode}
                    InputProps={{
                      style: {
                        borderRadius: "25px",
                        marginTop: "10px",
                        height: "40px",
                      },
                    }}
                  />
                </Grid>

                <Grid item xs={12} lg={12} md={12} sm={12}>
                  <Box
                    sx={{
                      heigth: "40px",
                      borderRadius: "25px",
                      background: "#fdede9",
                      padding: "10px",
                      display: "flex",
                      flexDirection: "row",
                      alignItems: "center",
                    }}
                  >
                    <Typography
                      sx={{ color: "red", fontWeight: 600, fontSize: "18px" }}
                    >
                      Name :
                    </Typography>
                    <Typography
                      sx={{ color: "red", fontWeight: 500, fontSize: "18px" }}
                      ml={1}
                    >
                      {scanData?.enquiry_name}
                    </Typography>
                  </Box>
                </Grid>

                {/* event list */}
                <Grid item xs={12} lg={12} md={12} sm={12}>
                  <Typography
                    sx={{ fontWeight: 600, color: "black", fontSize: "16px" }}
                  >
                    Select Event Name
                  </Typography>
                  {scanData?.events?.map((event) => (
                    <Chip
                      label={event.campaign_name}
                      variant="outlined"
                      sx={{
                        marginLeft: "10px",
                        marginTop: "10px",
                        fontSize: "10px",

                        fontWeight: 600,
                        backgroundColor:
                          selectedChips === event.id ? "green" : "transparent", // Set background to green if selected
                        color: selectedChips === event.id ? "white" : "black",
                      }}
                      onClick={() => handleChipClick(event.id)}
                    />
                  ))}
                </Grid>

                {/* selected event name */}

                {selectEdevent !== null && (
                  <Grid item xs={12} lg={12} md={12} sm={12}>
                    <Typography
                      sx={{ fontWeight: 600, color: "black", fontSize: "16px" }}
                    >
                      Selected Event Name
                    </Typography>

                    <Chip
                      variant="outlined"
                      label={selectEdevent?.campaign_name}
                      sx={{
                        marginTop: "10px",
                        color: "black",
                        fontSize: "10px",

                        fontWeight: 600,
                      }}
                    />
                  </Grid>
                )}

                <Grid item xs={12} lg={12} md={12} sm={12}>
                  <Button
                    variant="contained"
                    fullWidth
                    style={{
                      borderRadius: "25px",
                      fontWeight: 600,
                      marginBottom: "10px",
                      fontSize: "20px",
                    }}
                    color="secondary"
                    onClick={postData}
                    disabled={selectedChips === null}
                  >
                    {" "}
                    Mark Attendance
                  </Button>
                </Grid>
              </Grid>
            </Paper>
          </Grid>
        </Grid>
      </Box>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
