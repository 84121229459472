import { Button, FormControl, FormControlLabel, FormLabel, Grid, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@mui/material'
import React, { useState } from 'react'

function DropOutRequest() {
    const [refundDialogOpen, setRefundDialogOpen] = useState(false);
      const [refundType, setRefundType] = useState('full');
      const [refundAmount, setRefundAmount] = useState('');
      const [refundReason, setRefundReason] = useState('');
      const [dropoutReason, setDropoutReason] = useState('');
      const [otherReason, setOtherReason] = useState('');
      const [offerRefund, setOfferRefund] = useState(false)

  return (
         <>
         <Grid container spacing={4}>
    <Grid item xs={12} md={12}>
      <InputLabel id="dropout-reason-label">Drop Out Reason</InputLabel>
      <Select
        labelId="dropout-reason-label"
        id="dropout-reason"
        fullWidth
        value={dropoutReason}
        onChange={(e) => setDropoutReason(e.target.value)}
      >
        <MenuItem value="product-defect">Unavailable</MenuItem>
        <MenuItem value="change-mind">Change of Mind</MenuItem>
        <MenuItem value="other">Other</MenuItem>
      </Select>
    </Grid>

    {dropoutReason === 'other' && (
      <Grid item xs={12} md={12}>
        <TextField
          label="Other Reason"
          fullWidth
          value={otherReason}
          onChange={(e) => setOtherReason(e.target.value)}
        />
      </Grid>
    )}

<Grid item xs={12} md={12}>
        <TextField
          label="Note"
          fullWidth
          multiline={true}
          value={otherReason}
          onChange={(e) => setOtherReason(e.target.value)}
        />
      </Grid>

    <Grid item xs={12} md={12}>
      <FormControl component="fieldset">
        <FormLabel component="legend">Offer Refund?</FormLabel>
        <RadioGroup
          aria-label="offer-refund"
          name="offer-refund"
          value={offerRefund ? 'true' : 'false'}
          onChange={(e) => setOfferRefund(e.target.value === 'true')}
        >
          <FormControlLabel value="true" control={<Radio />} label="Yes" />
          <FormControlLabel value="false" control={<Radio />} label="No" />
          </RadioGroup>
      </FormControl>
    </Grid>

            { offerRefund === true && (
                <>
            <Grid item xs={12} md={12}>
          <FormControl component="fieldset">
          <FormLabel component="legend">Refund type</FormLabel>
            <RadioGroup
              aria-label="refund-type"
              name="refund-type"
              value={refundType}
              onChange={(e) => setRefundType(e.target.value)}
            >
              <FormControlLabel
                value="full"
                control={<Radio />}
                label="Full Refund"
              />
              <FormControlLabel
                value="partial"
                control={<Radio />}
                label="Partial Refund"
              />
            </RadioGroup>
          </FormControl>
          </Grid>
         
          {refundType === 'partial' && (
           
             <Grid item xs={12} md={12}>
              <TextField
                label="Refund Amount (INR)"
                type="number"
                fullWidth
                value={refundAmount}
                onChange={(e) => setRefundAmount(e.target.value)}
              />
              </Grid>
           
          )}
          
          <Grid item xs={12} md={12}>
          <InputLabel id="refund-reason-label">Refund Reason</InputLabel>
          <Select
            labelId="refund-reason-label"
            id="refund-reason"
            fullWidth
            value={refundReason}
            onChange={(e) => setRefundReason(e.target.value)}
          >
            <MenuItem value="product-defect">Unavailable</MenuItem>
            <MenuItem value="change-mind">Change of Mind</MenuItem>
          </Select>
          </Grid>
          </>
          )}

          <Button sx={{ m: 2}} color='secondary' variant='contained'>Send Request To Admin</Button>
          </Grid>
    </>
  )
}

export default DropOutRequest