// Author : Ayesha Shaikh
//  Date : 03 Oct 2024

import {
  Autocomplete,
  Button,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EmployeeFilter({loading, setLoading,originalData,showSnackbar,  setEmployeeData,
    setSearchData,}) {
        const userId = localStorage.getItem("userId");
  const [name, setName] = useState([]);
  const [nameList, setNameList] = useState([]);
  const [branchList, setBranchList] = useState([]);
  const [branch, setBranch] = useState([]);
  const [reportingManager, setReportingManager] = useState([]);
  const [reportingManagerList, setReportingManagerList] = useState([]);
  const [roleList, setRoleList] = useState([]);
  const [role, setRole] = useState([]);
  const [statusList, setStatusList] = useState([{id:1,name:"Active"},{id:0,name:"InActive"}]);
  const [status, setStatus] = useState([]);
  const [fromdate, setFromdate] = useState("");
  const [todate, settodate] = useState("");

  // Fetch Employee Name
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}employee/get/empname`);
        if (response.ok) {
          const data = await response.json();
          setNameList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  // Fetch Branch Data
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          setBranchList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}role`);
        if (response.ok) {
          const data = await response.json();
          const filterdData = data.filter(
            (i) => i.role_name !== "center_admin"
          );
          setRoleList(filterdData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  const allBranchList = [{ branch_name: "Select All" }, ...branchList];
  const handleBranchChange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchList);
    } else setBranch(value);
  };

  const allEmployeeList = [{ first_name: "Select All" }, ...nameList];
  const handleemployeeChange = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setName(nameList);
    } else setName(value);
  };

  const allRoleList = [{ role_name: "Select All" }, ...roleList];
  const handlroleChange = (event, value) => {
    if (value.some((option) => option.role_name === "Select All")) {
      setRole(roleList);
    } else setRole(value);
  };


  const handleStatusChange=(event, value)=>{
setStatus(value)
  }


  const handleApplyFilter = async(event) => {
      

    // Handle form submission logic here
    setLoading(true);

    const requestData = {
    user_id:parseInt(userId),
        from_date:fromdate || null,
        to_date: todate ||  null,
        branch_id:branch.map((i)=>i.id),
        is_active:status.map((i)=>i.id),
        role_id:role.map((i)=>i.id),
        filter_for : name.map((i)=>i.user_id)

    };

    try {
      const response = await fetch(`${API_ENDPOINT}employee/filterforemp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
      
        const result = await response.json();
        const sortedData = result.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
    setEmployeeData(sortedData)
    setSearchData(sortedData)
        setLoading(false);
      }  else if (response.status === 404) {
        setEmployeeData([])
        setSearchData([])
        setLoading(false);
      
      }
     
      else   {
     showSnackbar("Internal server error" , 'error')
     setLoading(false);

      }

    } catch (error) {
      showSnackbar("Internal server error" , 'error')
      console.error("Error:", error);
      setLoading(false);
    }
  
};

const handleResetFilter=()=>{
    setEmployeeData(originalData)
    setSearchData(originalData)
    setName([])
    setBranch([])
    setRole([])
    setFromdate("")
    settodate("")
    setRole([])

}

  return (
    <>
      <MainCard>
        <Grid container spacing={2}>
          <Grid item xs={12} md={3} >
            <Typography> Employee Name</Typography>
            <Autocomplete
              size="small"
              disablePortal
              multiple
              value={name}
              onChange={handleemployeeChange}
              options={allEmployeeList}
              getOptionLabel={(option) =>
                `${option.first_name ? option.first_name : " "} ${
                  option.last_name ? option.last_name : " "
                }`
              }
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "14px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography> Branch</Typography>

            <Autocomplete
              size="small"
              disablePortal
              multiple
              value={branch}
              onChange={handleBranchChange}
              options={allBranchList}
              getOptionLabel={(option) => option.branch_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "14px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          {/* <Grid item xs={12} md={3}>
            <Typography> Branch Manager</Typography>
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography> Reporting Manager</Typography>
          </Grid> */}

          <Grid item xs={12} md={3}>
            <Typography> Role</Typography>

            <Autocomplete
              size="small"
              disablePortal
              multiple
              value={role}
              onChange={handlroleChange}
              options={allRoleList}
              getOptionLabel={(option) => option.role_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "14px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography> Created From date</Typography>
            <TextField
             fullWidth
            type="date"
              size="small"
              value={fromdate}
              onChange={(e) => setFromdate(e.target.value)}
              InputProps={{ style: { borderRadius: "14px",  height: "37px", } }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography> Created To date</Typography>
            <TextField
            fullWidth
            type="date"
              size="small"
              value={todate}
              onChange={(e) => settodate(e.target.value)}
              InputProps={{ style: { borderRadius: "14px" ,  height: "37px",} }}
            />
          </Grid>

          <Grid item xs={12} md={3}>
            <Typography> Status</Typography>

            <Autocomplete
              size="small"
              disablePortal
              multiple
              value={status}
              onChange={handleStatusChange}
              options={statusList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "14px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} container spacing={2} >
            <Grid item>
              <Button size="small" variant="contained" style={{ borderRadius: "25px" }} onClick={handleApplyFilter}>
                Apply Filter
              </Button>
            </Grid>

            <Grid item>
              <Button
              size="small"
                variant="contained"
                color="error"
                style={{ borderRadius: "25px" }}
                onClick={handleResetFilter}
              >
                Reset Filter
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
    </>
  );
}
