// Author: Ayesha Shaikh
// Date : 13aug 2024

import { AppBar, Backdrop, Box, Button, CircularProgress, Grid, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import { MuiOtpInput } from "mui-one-time-password-input";
import { Html5QrcodeScanner, Html5QrcodeScanType } from "html5-qrcode";
import "./Qrstyle.css";
import DashboardIcon from "@mui/icons-material/Dashboard";
import StudentInQueTable from "./StudentInQueTable";
import { Link, useLocation, useNavigate } from "react-router-dom";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EnglishTestMasterDesk() {
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [otp, setOtp] = React.useState("");
  const [scanData, setScanData] = useState([]);
  const [error, setError] = useState(false);
  const location = useLocation();
  const { data } = location.state || {};
  const eventAndDeskData = data;
  const eventid = eventAndDeskData?.eventId;
  const deskid = eventAndDeskData?.deskId;
  const enquiry_id = scanData?.enquiry_id;
  const event_counselling_status =
    scanData?.event_counselling_status || eventAndDeskData?.counsellingStatus;

  const [loading, setLoading] = useState(false);
  const [studentQuedata, setStudentQuedata] = useState([]);

  console.log("studentQueeData", enquiry_id);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleChange = (newValue) => {
    setOtp(newValue);
  };

  // Qr Scan
  function onScanSuccess(decodedText, decodedResult) {
    // handle the scanned code as you like, for example:
    console.log(`Code matched = ${decodedText}`, decodedResult);
    postData(userId, eventid, decodedText);
  }

  function onScanFailure(error) {
    // handle scan failure, usually better to ignore and keep scanning.
    // for example:
    console.warn(`Code scan error = ${error}`);
  }

  useEffect(() => {
    const html5QrcodeScanner = new Html5QrcodeScanner(
      "reader",
      { fps: 10, qrbox: { width: 250, height: 250 } },
      false
    );
    html5QrcodeScanner.render(onScanSuccess, onScanFailure);

    // Cleanup function to stop the scanner when the component unmounts
    return () => {
      html5QrcodeScanner.clear();
    };
  }, []);

  // Post Data
  const postData = async (userId, eventId, qrcode) => {
    const payload = {
      user_id: parseInt(userId),
      event_id: eventId || null,
      qrcode: qrcode,
    };

    try {
      setLoading(true)
      const response = await fetch(
        `${API_ENDPOINT}eventattendance/startcounselling`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Response data:", data);
        setScanData(data);
        setLoading(false)
      } else if (response.status === 403) {
        showSnackbar(
          "No qr , qr undefined Please enter a unicode 6 alphanumeric code",
          "error"
        );
        setLoading(false)
      } else if (response.status === 401) {
        showSnackbar("User not Authorized", "error");
        setLoading(false)
      } else if (response.status === 405) {
        showSnackbar(
          "Please mark event attendance first for this enquiry",
          "error"
        );
        setLoading(false)
      } else if (response.status === 404) {
        showSnackbar("QR code or enquiry not found", "error");
      } else if (response.status === 404) {
        showSnackbar(" no enquiry found with this unique code", "error");
        setLoading(false)
      }
       else {
        showSnackbar("Error Posting Data", "error");
        setLoading(false)
      }
    } catch (error) {
      console.error("Error posting data:", error);
      showSnackbar("Error Posting Data", "error");
      setLoading(false)
    }
  };

  const handleSubmitdataByUniqueCode = () => {
    const validateOtp = /[A-Z]/.test(otp);

    if (validateOtp === false) {
      setError(true);
      return;
    }

    postData(userId, eventid, otp);
  };

  //  navigate to student information page
  const selectedData = {
    eventid,
    deskid,
    enquiry_id,
    event_counselling_status,
  };

  const handlenavigate = () => {
    navigate(`/student-information/${enquiry_id}`, {
      state: { data: selectedData },
    });
  };

 
  // fetch QuetableData

  useEffect(() => {
    const requestData = {
      user_id: parseInt(userId),

      event_id: eventid,
      event_desk_id: deskid,
    };
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}eventattendance/fetchcounsellingqueue`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // add any other headers you need
            },
            body: JSON.stringify(requestData),
          }
        );

        if (response.ok) {
          const result = await response.json();
          setStudentQuedata(result);
          setLoading(false);
        } else if (response.status === 401) {
          showSnackbar("Unauthorized User", "error");
          setLoading(false);
        } else if (response.status === 403) {
          showSnackbar("Missing event id or Event desk id", "error");
          setLoading(false);
        } else {
          setLoading(false);
          showSnackbar("Error fetching data:", "error");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        showSnackbar("Error fetching data:", "error");
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  return (
    <>
      <AppBar
        sx={{
          bgcolor: "white",
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 1000,
          boxShadow: " 0px 4px 5px 0px rgba(0, 0, 0, 0.3);",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            {" "}
            <img src="/Siec Logo.png" />
          </Box>
          <Box>
            <Link to="/dashboard">
              <Button
                variant="contained"
                style={{
                  borderRadius: "25px",
                  color: "white",
                  background: "#4ca64c",
                }}
                startIcon={<DashboardIcon />}
              >
                Dashboard
              </Button>
            </Link>
            <Link to="/">
              {" "}
              <Button
                color="error"
                variant="contained"
                style={{ borderRadius: "25px" }}
                startIcon={<img src="/icons8-move-up-64.png" width="20px" />}
              >
                Logout
              </Button>
            </Link>
          </Box>
        </Box>
      </AppBar>

      
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fething
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Box
        sx={{
          backgroundImage: "url(/istockphoto-1277862772-612x612.jpg)",
          backgroundSize: "cover",
          backgroundPosition: "center",
          width: "100%",
          minHeight: {
            xs: "100%",
            sm: "100%",
            md: "100%", // Medium screens
            lg: "100%", // Large screens
            xl: "100vh", // Extra large screens
          }, // Adjust height as needed
          zIndex: 1,
          position: "relative",
        }}
        mt={10}
      >
        <Grid
          container
          spacing={2}
          sx={{
            display: "flex",
            alignItems: "center",
            textAlign: "center",
            justifyContent: "center",
            zIndex: 1,
          }}
        >
          <Grid item xs={12} md={12} lg={12} sm={12} mt={2}>
            <Typography
              sx={{ fontSize: "28px", fontWeight: 600, color: "blue" }}
            >
              English Test Masters Desk
            </Typography>
          </Grid>

          <Grid item xs={12} md={12} lg={12} sm={12}>
            <Grid
              container
              spacing={2}
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Grid item xs={12} md={5} lg={4.2} sm={6} mt={5}>
                <MainCard>
                  <Grid container spacing={2}>
                    <Grid item xs={12} lg={12} sm={12} md={12}>
                      <Typography
                        sx={{ fontSize: "22px", fontWeight: 500, color: "red" }}
                      >
                        Welcome
                      </Typography>
                    </Grid>
                    <Grid item xs={12} lg={12} sm={12} md={12}>
                      <Typography
                        sx={{ fontSize: "18px", fontWeight: 600 }}
                        color="green"
                      >
                        Enter Student Unique Code
                      </Typography>
                    </Grid>

                    <Grid item xs={12} lg={12} sm={12} md={12}>
                      <MuiOtpInput
                        sx={{
                          "& .MuiInputBase-root": {
                            // Targeting the input container
                            borderRadius: "8px", // Adjust the border radius as needed
                          },
                          "& .MuiInputBase-input": {
                            // Targeting the input fields
                            borderRadius: "14px",
                            height: "10px ", // Adjust the border radius as needed
                          },
                        }}
                        value={otp}
                        onChange={handleChange}
                        length={6}
                      />
                      {error && (
                        <Typography color="error">
                          Only accept uppercase letter
                        </Typography>
                      )}
                    </Grid>

                    <Grid item xs={12} lg={12} sm={12} md={12}>
                      <Button
                        variant="contained"
                        style={{
                          borderRadius: "25px",
                          color: "white",
                          fontWeight: 600,
                          background: "green",
                          fontSize: "16px",
                        }}
                        endIcon={<img src="/icons8-send-64.png" width="20px" />}
                        onClick={handleSubmitdataByUniqueCode}
                      >
                        Submit
                      </Button>
                    </Grid>
                  </Grid>
                </MainCard>
              </Grid>

              <Grid item xs={12} md={5} lg={4.2} sm={6} mt={5}>
                <MainCard>
                  <Box id="reader" style={{ width: "100%" }}></Box>
                </MainCard>
              </Grid>
              {scanData.length !== 0 && (
                <>
                  {/*  Spacing Purpose */}
                  <Grid
                    item
                    xs={12}
                    md={5}
                    lg={4.2}
                    sm={6}
                    sx={{ visibility: "hidden" }}
                  >
                    <MainCard></MainCard>
                  </Grid>

                  <Grid item xs={12} md={5} lg={4} sm={6}>
                    <MainCard>
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                          justifyContent: "space-between",
                        }}
                      >
                        <Box>
                          <Typography
                            sx={{
                              textAlign: "left",
                              color: "red",
                              fontSize: "18px",
                            }}
                          >
                            Name <strong>: {scanData?.enquiry_name}</strong>
                          </Typography>
                          {scanData?.event_counselling_status === "ended" && (
                            <Typography
                              sx={{
                                textAlign: "left",
                                color: "red",
                                fontSize: "18px",
                              }}
                            >
                              Counselling Ended
                            </Typography>
                          )}
                        </Box>
                        {scanData?.event_counselling_status ===
                          "not_started" && (
                          <Button
                            color="error"
                            variant="contained"
                            endIcon={<ArrowForwardIcon />}
                            onClick={handlenavigate}
                          >
                            Start Counselling
                          </Button>
                        )}

                        {scanData?.event_counselling_status === "ongoing" && (
                          <Button
                            color="error"
                            variant="contained"
                            endIcon={<ArrowForwardIcon />}
                          >
                            Countinue Counselling
                          </Button>
                        )}
                        {scanData?.event_counselling_status === "ended" && (
                          <Button
                            color="error"
                            variant="contained"
                            endIcon={<ArrowForwardIcon />}
                            onClick={handlenavigate}
                          >
                            Start Another Counselling Session
                          </Button>
                        )}
                      </Box>
                    </MainCard>
                  </Grid>
                </>
              )}
            </Grid>
          </Grid>

          <Grid item xs={12} md={10} lg={8} sm={12} mt={2}>
            <MainCard>
              <StudentInQueTable
                data={studentQuedata}
                handlenavigate={handlenavigate}
                event_counselling_status={event_counselling_status}
              />
            </MainCard>
          </Grid>
        </Grid>
      </Box>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
