import React from 'react'
import {
   
    Typography,
   
  } from "@mui/material";
export default function Branches({selectedbranhes}) {
  return (
    <Typography variant='h5'>{selectedbranhes.join(' , ')}</Typography>
  )
}
