import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material'
import React from 'react'
import { Button } from 'react-scroll'
import CloseIcon from '@mui/icons-material/Close';
export default function ExpensePopup({ open, handleClose, title, content, actions }) {
  return (
    <>
     <Dialog open={open} onClose={handleClose}  >
      <DialogTitle>{title}
      <IconButton aria-label="close" onClick={handleClose} sx={{ position: 'absolute', right: 8, top: 8, color: 'inherit' }}>
            <CloseIcon />
          </IconButton>
      </DialogTitle>
      <DialogContent>
        <DialogContentText>{content}</DialogContentText>
      </DialogContent>
      <DialogActions>
        
      </DialogActions>
    </Dialog>
    </>
  )
}
