import React, { useState } from 'react'
import MainCard from '../../../../components/ui-component/card/MainCard'; 
import { Box, Button, CircularProgress, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { CSVExport } from '../../../../components/ui-component/table/CSVExport'; 
import CustomSnackbar from '../../../../components/ui-component/snackbar/CustomSnackbar'; 
import CreatePopup from '../../../../../components/dialog/CreatePopup';
import AddRoomForm from '../rooms/AddRoom';
import RoomScheduleDialog from '../RoomSchedule';
import AddTrainer from './AddTrainer';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT


function Row({ row, onEdit }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleViewSchedule = () => {
        setOpen(true);
      };

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={{ pl: 3 }}>
                    
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.first_name} {row.last_name}
                </TableCell>
                <TableCell >{row.email}</TableCell>
                <TableCell >{row?.mobile}</TableCell>
                <TableCell>
                {row?.trainer_expertise?.map((expertise, index) => (
                    <div key={index}>{expertise}</div>
                ))}
                </TableCell>
                <TableCell >{row.gender}</TableCell>
                <TableCell >{row.status}</TableCell>
                <TableCell >
                <Button
                                    color="secondary"
                                    variant='contained'
                                    size='small'
                                    sx={{
                                        color: theme.palette.dark,
                                        borderColor: theme.palette.orange.main,
                                        '&:hover ': { background: theme.palette.orange.light }
                                    }}
                                    onClick={() => onEdit(row)} 
                                >  
                                    Edit
                                </Button>
                </TableCell>
                
            </TableRow>
                               
                                   
                              
           
        </>
    );
}

function TrainerTable({ trainers, branchId, branchName }) {
    const userId = localStorage.getItem('userId');
    const [rows, setRows] = useState(trainers)
    const [isLoading, setIsLoading] = useState(false)
    const [isAddRoomPopupOpen, setIsAddRoomPopupOpen] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
   
    const [editTrainerInfo, setEditTrainerInfo] = useState(null);

    console.log(rows)
  const handleEditTrainer = (trainerInfo) => {
    setEditTrainerInfo(trainerInfo);
    handleAddRoomClick(); // Open the AddTrainer dialog
  };

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };

    const handleAddRoomClick = () => {
        setIsAddRoomPopupOpen(true);
      };
    
      const handleAddRoomClose = () => {
        setIsAddRoomPopupOpen(false);
        setEditTrainerInfo(null);
      };

      const handleAddEmployee = (u) => {
        console.log(editTrainerInfo)

        u.branch_id = branchId
        u.user_id = userId

        const editMode = !!editTrainerInfo;

        const trainerId = editTrainerInfo?.id

        console.log(u)
    
        const apiUrl = editMode
            ? `${API_ENDPOINT}trainer/${trainerId}`
            : `${API_ENDPOINT}trainer`;
    
        fetch(apiUrl, {
            method: editMode ? 'PUT' : 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(u),
        })
            .then((response) => {
                if (response.status === 200 || response.status==201) {
                    showSnackbar(editMode ? 'Trainer updated successfully' : 'Trainer created successfully', 'success');
                    if (editMode) {
                        // If in edit mode, update the specific row
                        setRows((prevRows) =>
                          prevRows.map((row) =>
                            row.id === editTrainerInfo.id ? u : row
                          )
                        );
                      } else {
                        
                        setRows((prevRows) => [...prevRows, u]);
                      }
                     
                      setEditTrainerInfo(null);
                      window.location.reload()
                } else if (response.status === 409) {
                    showSnackbar('Trainer with Email Id Exists', 'error');
                   // setProfile({});
                } else {
                    console.log('Error:', response);
                    showSnackbar(`Error ${editMode ? 'updating' : 'creating'} trainer`, 'error');
                }
            })
            .catch((error) => {
                console.error(`Error ${editMode ? 'updating' : 'creating'} trainer`, error);
                showSnackbar(`Error ${editMode ? 'updating' : 'creating'} trainer`, 'error');
            });
        }
  return (
    <>
    <MainCard
        content={false}
        title="Trainers"
        secondary={
            <Stack direction="row" spacing={2} alignItems="center">
                <CSVExport data={rows} filename="basic-table.csv"  />
                <Button variant='contained' onClick={handleAddRoomClick}> Add Trainer</Button>
            </Stack>
        }
    >
        {/* table */}
        
        <TableContainer>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ pl: 3, backgroundColor: '#90ee90' }}></TableCell>
                        <TableCell sx={{ backgroundColor: '#90ee90' }}>Name</TableCell>
                        <TableCell sx={{ backgroundColor: '#90ee90' }}>Email</TableCell>
                        <TableCell sx={{ backgroundColor: '#90ee90' }}>Phone</TableCell>
                        <TableCell sx={{ backgroundColor: '#90ee90' }}>Expertise</TableCell>
                        <TableCell sx={{ backgroundColor: '#90ee90' }}>Gender</TableCell>
                        <TableCell sx={{ backgroundColor: '#90ee90' }}>Status</TableCell>
                        <TableCell sx={{ backgroundColor: '#90ee90' }} >Action</TableCell>
                       
                    </TableRow>
                </TableHead>
                <TableBody>

                {rows.length === 0 ? (
                <TableRow>
                  <TableCell sx={{ pl: 3 }} />
                  <TableCell colSpan={4} align="center">
                    <Typography variant='h4'>No data available</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((room, index) => (
                  <Row key={index} row={room} onEdit={handleEditTrainer}/>
                ))
              )}

                  </TableBody>
            </Table>
        </TableContainer>
    </MainCard> 

    <CreatePopup 
        open={isAddRoomPopupOpen} 
        onClose={handleAddRoomClose}
        component={<AddTrainer onSubmit={handleAddEmployee} branchId={branchId} branchName={branchName} editMode={!!editTrainerInfo} trainerInfo={editTrainerInfo} />}
        >
      </CreatePopup> 

      <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
    </>
  )
}

export default TrainerTable