import React from 'react';
import DemoHistoryItem from './DemoHistoryItem';
import { CircularProgress, Grid, Typography } from '@mui/material';


function DemoHistoryList({ demoHistory,loading }) {
    console.log(demoHistory);
  return (
    <>
    {loading ? (
      <Grid container justifyContent="center" alignItems="center">
        <CircularProgress />
      </Grid>
    ) : (
      <>
        {demoHistory.length === 0 ? (
          <Grid item xs={12} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
            <Typography variant='h5'>No demo history available</Typography>
          </Grid>
        ) : (
          demoHistory.map((item, index) => (
            <DemoHistoryItem key={index} item={item} />
          ))
        )}
      </>
    )}
  </>
  
  );
}

export default DemoHistoryList;
