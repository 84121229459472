import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Alert,
    Autocomplete,
    Avatar,
    Button,
    Divider,
    FormControl,
    FormHelperText,
    Grid,
    IconButton,
    InputAdornment,
    InputLabel,
    MenuItem,
    OutlinedInput,
    Select,
    Switch,
    TextField,
    Typography,
    useScrollTrigger
} from '@mui/material';

// third-party
import PerfectScrollbar from 'react-perfect-scrollbar';

// project imports
import SubCard from '../../components/ui-component/card/SubCard';
import { gridSpacing } from '../../store/constant';
import User1 from '../../../assets/image/avatar-11.png';

// assets
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import { Controller, useForm } from 'react-hook-form';

//const avatarImage = require.context('assets/image', true);
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

const jobTypes = [
    { label: 'Trainer', id: 1 },
    { label: 'Advisor', id: 2 },

];

// sticky edit card

function ElevationScroll({ children, window }) {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 230,
        target: window || undefined
    });

    return React.cloneElement(children, {
        style: {
            position: trigger ? 'fixed' : 'relative',
            top: trigger ? 83 : 0,
            width: trigger ? 318 : '100%'
        }
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.node,
    window: PropTypes.object
};

// ==============================|| CONTACT CARD/LIST USER EDIT ||============================== //

const UserEdit = ({ user, onCancel, onSave, ...others }) => {
    console.log(user)
    const theme = useTheme();

    // save user to local state to update details and submit letter
    const [profile, setProfile] = useState(user);
    const [branches, setBranches] =useState([])
    const [testTypes, setTestTypes] = useState([])

    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        branch_id: '',
        user_role: '',
      });
    
      const [showAlert, setShowAlert] = useState(false);
    
      const validateForm = () => {
        let isValid = true;
        const newErrors = { first_name: '', last_name: '' };
    
        if (!profile.first_name.trim()) {
          newErrors.first_name = 'First Name is required';
          isValid = false;
        }
    
        if (!profile.last_name.trim()) {
          newErrors.last_name = 'Last Name is required';
          isValid = false;
        }

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!profile.email.trim() || !emailRegex.test(profile.email.trim())) {
      newErrors.email = 'Invalid Email address';
      isValid = false;
    }

    // Phone number validation with country code
    const phoneRegex = /^\+[0-9]{1,4}[0-9]{3,14}$/;
    if (!profile.mobile.trim() || !phoneRegex.test(profile.mobile.trim())) {
      newErrors.mobile = 'Invalid Phone number with country code ie +91....';
      isValid = false;
    }

    if (!profile.branch_id) {
        newErrors.branch_id = 'Branch is required';
        isValid = false;
      }
  
      if (!profile.user_role) {
        newErrors.user_role = 'Role is required';
        isValid = false;
      }
    
        setErrors(newErrors);
        setShowAlert(!isValid); // Show alert if the form is not valid
        return isValid;
      };

      const handleSubmit = () => {
        if (validateForm()) {
          onSave(profile);
        } else {
          // Form has errors, handle accordingly
        }
      };

    useEffect(() => {
        setProfile(user);
    }, [user]);

    let avatarProfile = User1;
    if (profile) {
       // avatarProfile = profile.avatar && avatarImage(`./${profile.avatar}`);
    }

    const handleRoleChange = (e) => {
        setProfile({ ...profile, user_role: e.target.value });
      };

      const handleGenderChange = (e) => {
        setProfile({ ...profile, gender: e.target.value });
      };

      const handleAdminSwitch = (e) => {
        setProfile({ ...profile, isAdmin: e.target.checked });
      };

      useEffect(() => {
        // Fetch menu items using the fetch function
        fetch(`${API_ENDPOINT}branch`)
          .then(response => response.json())
          .then(data => {
            setBranches(data);
            console.log(data)
          })
          .catch(error => {
            console.error('Error fetching branches:', error);
          });
      }, [])

      useEffect(() => {
        // Fetch menu items using the fetch function
        fetch(`${API_ENDPOINT}advisordashboard/gettesttype`)
          .then(response => response.json())
          .then(data => {
            setTestTypes(data);
            console.log(data)
          })
          .catch(error => {
            console.error('Error fetching testtypes:', error);
          });
      }, []);

    return (
        <ElevationScroll {...others}>
            <SubCard
                sx={{
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                    width: '100%',
                    maxWidth: 342
                }}
                content={false}
            >
                <PerfectScrollbar style={{ height: 'calc(100vh - 83px)', overflowX: 'hidden' }}>
                    <Grid container spacing={gridSpacing} sx={{ p: 3 }}>
                        <Grid item xs={12}>
                            <Grid container alignItems="center" spacing={1}>
                                <Grid item>
                                    <Avatar alt="User 3" src='./src/assets/image/avatar-11.png' sx={{ width: 64, height: 64 }} />
                                </Grid>
                                <Grid item xs zeroMinWidth>
                                    <Grid container spacing={1}>
                                        <Grid item xs={12}>
                                            <label htmlFor="containedButtonFile">
                                                <input
                                                    accept="image/*"
                                                    style={{
                                                        opacity: 0,
                                                        position: 'fixed',
                                                        zIndex: 1,
                                                        padding: 0.5,
                                                        cursor: 'pointer'
                                                    }}
                                                    id="containedButtonFile"
                                                    multiple
                                                    type="file"
                                                />
                                                <Button variant="outlined" size="small" startIcon={<UploadTwoToneIcon />}>
                                                    Upload
                                                </Button>
                                            </label>
                                        </Grid>
                                        <Grid item xs={12}>
                                            <Typography variant="caption">Image size should be 125kb Max.</Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                                <Grid item>
                                    <IconButton onClick={() => onCancel(profile)} size="large">
                                        <HighlightOffTwoToneIcon />
                                    </IconButton>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={12}>
                            <Divider />
                        </Grid>

                        {showAlert && (
        <Alert severity="error" onClose={() => setShowAlert(false)}>
          Please fill in all required fields.
        </Alert>
      )}

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>First Name</InputLabel>
                                
                                <OutlinedInput
                                    
                                    value={profile.first_name}
                                    onChange={(e) => setProfile({ ...profile, first_name: e.target.value })}
                                    type="text"
                                    label="FirstName"
                                   
                                />
                                <FormHelperText style={{ color: 'red'}}>{errors.first_name}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Last Name</InputLabel>
                                <OutlinedInput
                                    value={profile.last_name}
                                    onChange={(e) => setProfile({ ...profile, last_name: e.target.value })}
                                    type="text"
                                    label="Last Name"
                            
                                />
                                <FormHelperText style={{ color: 'red'}}>{errors.last_name}</FormHelperText>
                            </FormControl>
                        </Grid>

                        
                       
                        <Grid item xs={12}>
    <FormControl fullWidth>
        <InputLabel>Email</InputLabel>
            <OutlinedInput
                value={profile.email}
               onChange={(e) => setProfile({ ...profile, email: e.target.value })}
                type="email"
                label="Email"
                startAdornment={
                    <InputAdornment position="start">
                        <MailTwoToneIcon />
                    </InputAdornment>
                }
            />
        <FormHelperText style={{ color: 'red' }}>{errors.email}</FormHelperText>
    </FormControl>
</Grid>
                        
                        
                       
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Phone Number</InputLabel>
                                <OutlinedInput
                                    value={profile.mobile}
                                    onChange={(e) => {
                                        setProfile({ ...profile, mobile: e.target.value });
                                    }}
                                    type="mobile"
                                    label="Phone Number"
                                    startAdornment={
                                        <InputAdornment position="start">
                                            <CallTwoToneIcon />
                                        </InputAdornment>
                                    }
                                />
                                <FormHelperText style={{ color: 'red'}}>{errors.mobile}</FormHelperText>
                            </FormControl>
                        </Grid>
                        
                        <Grid item xs={12}>
                        <InputLabel >DOB</InputLabel>
                            <FormControl fullWidth>
                            
                                <OutlinedInput
                                    value={profile.dob}
                                    type="date"
                                    label="DOB"
                                    InputProps={{
                                        inputProps: { max: '2005-12-31' },
                                    }}
                                    onChange={(e) => setProfile({ ...profile, dob: e.target.value })}
                                />
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                        <FormControl fullWidth>
                        <InputLabel>Gender</InputLabel>
                        <Select
                            value={profile.gender}
                            onChange={handleGenderChange}
                            label="Gender"
                            startAdornment={
                            <InputAdornment position="start">
                                {/* You can add an icon here if needed */}
                            </InputAdornment>
                            }
                        >
                            <MenuItem value="Male">Male</MenuItem>
                            <MenuItem value="Female">Female</MenuItem>
                        </Select>
                        </FormControl>
                    </Grid>



                        <Grid item xs={12}>
                            <FormControl fullWidth>
                                <InputLabel>Branch</InputLabel>
                                <Select
                                    value={profile.branch_id?.id}
                                    onChange={(e) => setProfile({ ...profile, branch_id: e.target.value })}
                                    type="text"
                                    label="Role"
                                >
                                    {branches.map((branch) => (
                                        <MenuItem key={branch.id} value={branch.id}>{branch.branch_name} </MenuItem>
                                    ))}
                                </Select>
                                <FormHelperText style={{ color: 'red'}}>{errors.branch}</FormHelperText>
                            </FormControl>
                        </Grid>

                        <Grid item xs={12}>
                            <FormControl fullWidth>
                            <InputLabel>Role</InputLabel>
                            <Select
                                //multiple
                                value={profile.user_role}
                                onChange={handleRoleChange}
                                label="Role"
                            >
                                <MenuItem value="trainer">Trainer</MenuItem>
                                <MenuItem value="advisor">Advisor</MenuItem>
                               
                            </Select>
                            <FormHelperText style={{ color: 'red'}}>{errors.user_role}</FormHelperText>
                            </FormControl>

                        </Grid>

                        {profile.user_role === 'trainer' && (
                        <React.Fragment>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                            <InputLabel>Expertise</InputLabel>
                            <Select
                                label="Expertise"
                                value={profile.trainer_expertise}
                                onChange={(e) => setProfile({ ...profile, trainer_expertise: e.target.value })}
                            >
                                {testTypes.map((testtype) => (
                                        <MenuItem key={testtype.id} value={testtype.id}>{testtype.name} </MenuItem>
                                    ))}
                            </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                            <InputLabel>Highest Qualification</InputLabel>
                            <Select
                                label="Highest Education"
                                value={profile.highest_qualification}
                                onChange={(e) =>
                                setProfile({ ...profile, highest_qualification: e.target.value })
                                }
                                >
                                <MenuItem value="Diploma">Diploma</MenuItem>
                                <MenuItem value="Undergraduate">Undergraduate</MenuItem>
                                <MenuItem value="Masters">Masters</MenuItem>
                                </Select>
                            
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                            <TextField
                                label="Work Experience in years"
                                value={profile.work_experience}
                                onChange={(e) =>
                                setProfile({ ...profile, work_experience: e.target.value })
                                }
                            />
                            </FormControl>
                        </Grid>
                        </React.Fragment>
                    )}
                        <Grid item xs={12}>
                            <Grid container spacing={1}>
                                <Grid item xs={6}>
                                    <Button 
                                        variant="contained" 
                                        fullWidth 
                                        //onClick={() => onSave(profile)}>
                                        onClick={handleSubmit}>
                                        Save
                                    </Button>
                                </Grid>
                                <Grid item xs={6}>
                                    <Button variant="outlined" fullWidth onClick={() => onCancel(profile)}>
                                        Cancel
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </PerfectScrollbar>
            </SubCard>
        </ElevationScroll>
    );
};

UserEdit.propTypes = {
    user: PropTypes.object,
    onCancel: PropTypes.func,
    onSave: PropTypes.func
};

export default UserEdit;