// Author: Ayesha Shaikh
// Date : 13 aug 2024

import { BorderBottom, BorderColor } from "@mui/icons-material";
import {
  AppBar,
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  Paper,
  Radio,
  RadioGroup,
  Stack,
  Step,
  StepButton,
  StepConnector,
  StepContent,
  StepLabel,
  Stepper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useRef, useState } from "react";
import MainCard from "../../../components/Maincard";
import JoditEditor from "jodit-react";
import { DataGrid } from "@mui/x-data-grid";
import { Link, useLocation, useNavigate } from "react-router-dom";
import DashboardIcon from "@mui/icons-material/Dashboard";
import ViewServiceDetails from "./ViewServiceDetails";
import EditServiceDetails from "./EditServiceDetails";
import ViewExamDetails from "./ViewExamDetails";
import EditExamDetetails from "./EditExamDetetails";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import GradLynkService from "./GradLynkService";
import { fDateTime, fTimestamp } from "../../../utils/formatTime";
import parse from "html-react-parser";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const CustomStepIcon = (props) => {
  const { active, completed, className } = props;

  return (
    <div
      style={{
        width: 24,
        height: 24,
        borderRadius: "50%",
        backgroundColor: active ? "#2196F3" : "#ccc", // Change to blue when active or completed
      }}
    />
  );
};

export default function StudentInformation() {
  const userId = localStorage.getItem("userId");
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);
  const editor = useRef(null);
  const [content, setContent] = useState("");
  const location = useLocation();
  const payloadData = location.state?.data;
  const deskId = payloadData?.deskid;
  const eventId = payloadData?.eventid;

  const counsellingStatus = payloadData?.event_counselling_status;

  console.log(payloadData);
  const [openservice, setOpenSerVice] = useState(true);
  const [openexam, setOpenExam] = useState(true);
  const [studentdata, setStudentData] = useState([]);
  const [openGradlynk, setOpenGradlynk] = useState(false);
  const [notesData, setNodeaData] = useState([]);
  const [activeStep, setActiveStep] = React.useState(null);
  const [profileAnalysis, setProFileAnalysis] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handleToggle = () => {
    setOpenSerVice(!openservice);
  };
  const handleCloseServise = () => {
    setOpenSerVice(false);
  };

  const handleToggleExam = () => {
    setOpenExam(!openexam);
  };

  const handleToggleGrydlynk = () => {
    setOpenGradlynk(!openGradlynk);
  };

  const config = {
    readonly: false,
    toolbarAdaptive: false,
    buttons: [
      "undo",
      "redo",
      "font",
      "fontsize",
      "bold",
      "italic",
      "underline",
      "source",
    ],
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };
  // const columns = [
  //   {
  //     field: "notes",
  //     headerName: "Notes",
  //    width:200,
  //     headerClassName: "header-cell",
  //     renderCell: (params) => <div>{params?.row?.note}</div>,
  //     headerAlign: "center",
  //     align: "center",
  //   },
  //   {
  //     field: "createdAt",
  //     headerName: "Created At",
  //     width:200,
  //     headerClassName: "header-cell",
  //     renderCell: (params) => <div>{fDateTime (params?.row?.createdAt)}</div>,
  //     headerAlign: "center",
  //     align: "center",
  //   },
  // ];
  const rows = [];

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: "20px",
    "& .MuiTableCell-head": {
      backgroundColor: "#abf7b1",
      fontWeight: 600,
      padding: "8px 16px",

      borderRight: "0.1px solid #C3C3C3",
      borderLeft: "0.1px solid #C3C3C3",
      color: "#000",
    },
    "& .MuiTableCell-body": {
      fontSize: "14px",
      fontWeight: 600,
      borderRight: "0.1px solid #C3C3C3",
      borderLeft: "0.1px solid #C3C3C3",
      align: "center",
      padding: "8px 16px",
    },
  }));

  const steps = ["0", "1", "2", "3", "4", "5"];

  // Fetch Student information data

  useEffect(() => {
    const requestData = {
      user_id: parseInt(userId),
      enquiry_id: payloadData?.enquiry_id,
      event_id: payloadData?.eventid,
      event_desk_id: payloadData?.deskid,
      event_counselling_status: payloadData?.event_counselling_status,
    };
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}eventattendance/fetchcounsellingdetails`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
              // add any other headers you need
            },
            body: JSON.stringify(requestData),
          }
        );

        if (response.ok) {
          const result = await response.json();
          setStudentData(result);
          setLoading(false);
        } else if (response.status === 401) {
          showSnackbar("Unauthorized User", "error");
          setLoading(false);
        } else {
          setLoading(false);
          showSnackbar("Error fetching data:", "error");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        showSnackbar("Error fetching data:", "error");
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  //  fetch  notes Table Data

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}eventattendance/fetchcounsellingnotes`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              user_id: parseInt(userId),
              enquiry_id: payloadData?.enquiry_id,
              event_id: payloadData?.eventid
            }),
          }
        );
        if (response.ok) {
          const data = await response.json();
          setNodeaData(data?.notes);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  console.log(activeStep);

  // Navigate to Qr
  const queData = { eventId, deskId, counsellingStatus };
  console.log(queData);
  const handleNavigateToQr = () => {
    navigate("/scanqr-counsellingdesk", { state: { data: queData } });
  };

  // Profile Analysis
  const handleProfileAnalysis = (event) => {
    setProFileAnalysis(event.target.value);
  };

  console.log("profileAnalysis", profileAnalysis);
  //  End Counselling

  const handleEndCounselling = async () => {
    try {
      const response = await fetch(
        `${API_ENDPOINT}eventattendance/endcounselling`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            user_id: parseInt(userId),
            enquiry_id: payloadData?.enquiry_id,
            event_counselling_id: studentdata?.counsellingId,
            notes: content,
            profile_analysis: activeStep,
            ready_to_apply: Boolean(profileAnalysis),
          }),
        }
      );

      if (response.ok) {
        console.log("Data saved successfully");
        showSnackbar(" Counselling End Successfully");
         setTimeout(() => {
          handleNavigateToQr()
         }, 2000);
      } else if (response.status === 401) {
        showSnackbar(" No user id", "error");
      } else if (response.status === 403) {
        showSnackbar("Plese add event counselling id ", "error");
      } else {
        console.error("Failed to save data", response.status);
        showSnackbar("server error", "error");
      }
    } catch (error) {
      console.error("Error saving data:", error);
      showSnackbar("Server error", "error");
    }
  };

  function stripHtmlTags(str) {
    return str?.replace(/<\/?[^>]+(>|$)/g, "");
  }


  return (
    <>
      <AppBar
        sx={{
          bgcolor: "white",
          position: "fixed",
          top: 0,
          width: "100%",
          zIndex: 1000,
          boxShadow: " 0px 4px 5px 0px rgba(0, 0, 0, 0.3);",
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Box>
            {" "}
            <img src="/Siec Logo.png" />
          </Box>

          <Box>
            <Link to="/dashboard">
              <Button
                variant="contained"
                style={{
                  borderRadius: "25px",
                  color: "white",
                  // background: "#4ca64c",
                }}
                startIcon={<DashboardIcon />}
              >
                Dashboard
              </Button>
            </Link>{" "}
            <Button
              variant="contained"
              style={{
                borderRadius: "25px",
                color: "white",
                background: "#4ca64c",
              }}
              startIcon={<img src="/icons8-scan-48.png" width="20px" />}
              onClick={handleNavigateToQr}
            >
              Scan Qr
            </Button>
            <Link to="/">
              {" "}
              <Button
                color="error"
                variant="contained"
                style={{ borderRadius: "25px" }}
                startIcon={<img src="/icons8-move-up-64.png" width="20px" />}
              >
                Logout
              </Button>
            </Link>
          </Box>
        </Box>
      </AppBar>

      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fething
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Box
        sx={{
          background: "#eef2f6",
          width: "100%",
          minHeight: {
            xs: "100%",
            sm: "100%",
            md: "100%", // Medium screens
            lg: "100%", // Large screens
            xl: "100vh", // Extra large screens
          },
          display: "flex",
        }}
        mt={3}
      >
        <Box m={10}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              <Stack
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  marginLeft: "20px",
                }}
              >
                {/* <PersonOutlineOutlinedIcon
                sx={{ marginRight: "5px", color: "#A03BBF" }}
              /> */}

                <Box
                  style={{
                    // background: "#F3DEFA",
                    padding: "10px",
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    border: "1px solid #0096FF",
                    borderBottom: 0,
                    borderColor: "primary.main",
                  }}
                >
                  <Box
                    sx={{ height: "30px", width: "30px", marginRight: "8px" }}
                  >
                    <img src="/man.png" height="100%" width="100%" />
                  </Box>
                  <Typography
                    sx={{ fontSize: "15px", fontWeight: 500, color: "#0096FF" }}
                  >
                    {studentdata?.userDetails?.first_name}{" "}
                    {studentdata?.userDetails?.last_name}
                  </Typography>
                </Box>

               {/*} <Box
                  style={{
                    background: "#DAA06D",
                    padding: "10px",
                    borderTopLeftRadius: "12px",
                    borderTopRightRadius: "12px",
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    marginLeft: "5px",
                  }}
                >
                  <Typography
                    sx={{ fontSize: "15px", fontWeight: 500, color: "#880808" }}
                  >
                    Category : Not Defiend
                  </Typography>
                </Box>*/}
              </Stack>
              <Paper sx={{ borderRadius: "15px", height: "30px" }}></Paper>
            </Grid>

            <Grid item xs={12} md={12} sm={12} lg={12}>
              <Grid container spacing={2}>
                {/* Service Details */}
              {/*}  <Grid item xs={12} md={12} sm={12} lg={12}>
                  <MainCard>
                    <Stack
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        marginBottom: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          marginBottom: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            height: "30px",
                            width: "30px",
                            marginRight: "8px",
                          }}
                        >
                          <img src="/resume.png" height="100%" width="100%" />
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "#254e78",
                          }}
                        >
                          SERVICE DETAILS
                        </Typography>
                      </Stack>
                      {openservice ? (
                        <Box
                          sx={{
                            height: "20px",
                            width: "20px",
                            marginRight: "8px",
                            cursor: "pointer",
                          }}
                          onClick={handleToggle}
                        >
                          <img src="/pencil.png" height="100%" width="100%" />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            height: "20px",
                            width: "20px",
                            marginRight: "8px",
                            cursor: "pointer",
                          }}
                          onClick={handleToggle}
                        >
                          <img
                            src="/icons8-view-32 (1).png"
                            height="100%"
                            width="100%"
                          />
                        </Box>
                      )}
                    </Stack>
                    <Divider />
                    {openservice ? (
                      <ViewServiceDetails data={studentdata?.user} />
                    ) : (
                      <EditServiceDetails data={studentdata?.user} />
                    )}
                  </MainCard>
                </Grid>

              
                <Grid item xs={12} md={12} sm={12} lg={12}>
                  <MainCard>
                    <Stack
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        marginBottom: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                          marginBottom: "10px",
                        }}
                      >
                        <Box
                          sx={{
                            height: "30px",
                            width: "30px",
                            marginRight: "8px",
                          }}
                        >
                          <img src="/file.png" height="100%" width="100%" />
                        </Box>
                        <Typography
                          sx={{
                            fontSize: "16px",
                            fontWeight: 600,
                            color: "#254e78",
                          }}
                        >
                          EXAM REGISTRATION DETAILS
                        </Typography>
                      </Stack>
                      {openexam ? (
                        <Box
                          sx={{
                            height: "20px",
                            width: "20px",
                            marginRight: "8px",
                            cursor: "pointer",
                          }}
                          onClick={handleToggleExam}
                        >
                          <img src="/pencil.png" height="100%" width="100%" />
                        </Box>
                      ) : (
                        <Box
                          sx={{
                            height: "20px",
                            width: "20px",
                            marginRight: "8px",
                            cursor: "pointer",
                          }}
                          onClick={handleToggleExam}
                        >
                          <img
                            src="/icons8-view-32 (1).png"
                            height="100%"
                            width="100%"
                          />
                        </Box>
                      )}
                    </Stack>
                    <Divider />
                    {openexam ? (
                      <ViewExamDetails data={studentdata?.user} />
                    ) : (
                      <EditExamDetetails data={studentdata?.user} />
                    )}
                    <Box style={{ textAlign: "end" }}>
                      <Button
                        color="error"
                        variant="contained"
                        sx={{ marginTop: "10px" }}
                        onClick={handleToggleGrydlynk}
                      >
                        Interested in gradlynk service
                      </Button>
                    </Box>{" "}
                  </MainCard>
                </Grid>*/}

                {/* Gradlynk Service */}
                <Grid item xs={12} md={12} sm={12} lg={12}>
                <Box style={{ textAlign: "end" }}>
                      <Button
                        color="error"
                        variant="contained"
                        sx={{ marginTop: "10px" }}
                        onClick={handleToggleGrydlynk}
                      >
                        Interested in gradlynk service
                      </Button>
                    </Box>
                    </Grid>

                {openGradlynk && (
                  <Grid item xs={12} md={12} sm={12} lg={12}>
                    <MainCard>
                      <GradLynkService data={studentdata?.userDetails} onClose={handleToggleGrydlynk} />
                    </MainCard>
                  </Grid>
                )}
              
            

            {/* Right Side */}
            <Grid item xs={12} md={12} sm={12} lg={12}>
              <MainCard>
                <Grid container spacing={2}>
                  <Grid item xs={12} lg={12} md={12} sm={12}>
                    <Stack
                      sx={{
                        display: "flex",
                        alignItems: "center",
                        flexDirection: "row",
                        marginBottom: "10px",
                      }}
                    >
                      <Box
                        sx={{
                          height: "30px",
                          width: "30px",
                          marginRight: "8px",
                        }}
                      >
                        <img src="/observe.png" height="100%" width="100%" />
                      </Box>
                      <Typography
                        sx={{
                          fontSize: "16px",
                          fontWeight: 600,
                          color: "#254e78",
                        }}
                      >
                        OBSERVATION
                      </Typography>
                    </Stack>
                    <Divider sx={{ marginTop: "10PX" }} />
                  </Grid>
                  <Grid item xs={12} md={6} lg={6} sm={6}>
                    <Typography variant="h4">Notes</Typography>
                    <JoditEditor
                      ref={editor}
                      value={content}
                      config={config}
                      tabIndex={1} // tabIndex of textarea
                      onBlur={(newContent) => setContent(newContent)} // preferred to use only this option to update the content for performance reasons
                      onChange={(newContent) => {}}
                    />
                  </Grid>

                  {/* Notes Table */}
                  <Grid item xs={12} sm={12} lg={6} md={6}>
                    <Box
                      sx={{
                        height: "100%",
                        width: "100%",
                        "& .header-cell": {
                          backgroundColor: "#abf7b1",
                          fontWeight: 600,
                          borderRight: "0.1px solid #C3C3C3",
                        },
                      }}
                    >
                      <StyledTableContainer
                        component={Paper}
                        style={{ maxHeight: "300px" }}
                      >
                        <Table aria-label="simple table" stickyHeader>
                          <TableHead>
                            <TableRow>
                              <TableCell style={{ width: "200px" }}>
                                Notes
                              </TableCell>
                              <TableCell>Created At</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {notesData.map((row) => (
                              row.notes && (
                              <TableRow key={row.id}>
                                <TableCell component="th" scope="row">
                                  {stripHtmlTags(row?.notes)}
                                </TableCell>
                                <TableCell component="th" scope="row">
                                  {fDateTime(row?.createdAt)}
                                </TableCell>
                              </TableRow>
                              )
                            ))}
                          </TableBody>
                        </Table>
                      </StyledTableContainer>
                    </Box>
                  </Grid>
                  {/* Profile Analysys */}
                  <Grid item xs={12} lg={6} sm={12} md={6} mt={2}>
                    <Typography variant="h4">Profile Analysis</Typography>
                    <Stack
                      sx={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                      mt={2}
                    >
                      <Typography sx={{ fontWeight: 600 }} color="error">
                        1 - Not eligigible
                      </Typography>
                      <Typography sx={{ fontWeight: 600 }} color="secondary">
                        5 - Ready to apply
                      </Typography>
                    </Stack>
                    <Box sx={{ width: "100%" }} mt={2}>
                      <Stepper
                        activeStep={activeStep}
                        alternativeLabel
                        nonLinear
                        connector={<StepConnector />}
                      >
                        {steps.map((label, index) => (
                          <Step key={label}>
                            <StepButton
                              onClick={handleStep(index)}
                              icon={
                                <CustomStepIcon
                                  active={activeStep === index}
                                  completed={activeStep > index}
                                />
                              }
                            >
                              {label}
                            </StepButton>
                          </Step>
                        ))}
                      </Stepper>
                    </Box>
                  </Grid>

                  <Grid item xs={12} lg={4} sm={12} md={5} ml={3} mt={2}>
                    <Typography variant="h4">Ready To Apply</Typography>
                    <FormControl sx={{ width: "100%" }}>
                      <RadioGroup
                        aria-labelledby="demo-radio-buttons-group-label"
                        row
                        name="row-radio-buttons-group"
                        value={profileAnalysis}
                        onChange={handleProfileAnalysis}
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} lg={12} md={12} sm={12} textAlign="end">
                    <Button
                      color="error"
                      variant="contained"
                      endIcon={<ArrowForwardIcon />}
                      onClick={handleEndCounselling}
                    >
                      End Counselling
                    </Button>
                  </Grid>
                </Grid>
              </MainCard>
            </Grid>
          </Grid>
          </Grid>
          </Grid>
        </Box>
      </Box>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
