import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material'
import React from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';


export default function BulkTemplatepopup( {open,
    handelopentemplate,
    handelclosetemplatepopup,
    component }) {
    
  return (
<Dialog onClose={  handelclosetemplatepopup} open={open} maxWidth='md' fullWidth>
  
      <DialogTitle  sx={{fontWeight:600, fontSize:'22px'}}>Select Template
      
      <IconButton aria-label="close" onClick={ handelclosetemplatepopup} sx={{position:'absolute', right:0,top:0 }}>
              <CloseOutlinedIcon />
            </IconButton>
      </DialogTitle>
      <Divider/>
      <DialogContent>{component}</DialogContent>
    
    </Dialog>
  )
}
