import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  email: '',
  password: '',
  response: null,
  isAuthenticated: false,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setEmail: (state, action) => {
      state.email = action.payload;
    },
    setPassword: (state, action) => {
      state.password = action.payload;
    },
    setResponse: (state, action) => {
      state.response = action.payload;
    },
    login: (state, action) => {
        state.response = action.payload;
        state.isAuthenticated = true;
      },
      logout: (state) => {
        state.email = '';
        state.password = '';
        state.response = null;
        state.isAuthenticated = false;
      },
  },
});

export const { setEmail, setPassword, setResponse, login, logout } = authSlice.actions;

export default authSlice.reducer;