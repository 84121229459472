import { Box, Button, Checkbox, FormControlLabel, Grid, InputLabel, MenuItem, Stack, TextField, Typography } from '@mui/material';

// project imports
import Avatar from '../../../../components/ui-component/avatar/Avatar'; 
import { gridSpacing } from '../../../../store/constant'; 

// assets

import ErrorTwoToneIcon from '@mui/icons-material/ErrorTwoTone';
import { Controller, useForm } from 'react-hook-form';
import { City, Country, State } from 'country-state-city';
import { useEffect, useState } from 'react';
import { Watch } from '@mui/icons-material';
import axios from 'axios';
import CustomSnackbar from '../../../../components/ui-component/snackbar/CustomSnackbar'; 

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

const StudentFormInfo = ({studentDetail, onSubmitSuccess, onNext}) => {
    const enquiryId = studentDetail?.id;

    const {
        control,
        formState: { errors },
        watch,
        setValue,
        setError,
        handleSubmit
      } = useForm();

      
      const [studentInfo, setStudentInfo] = useState(studentDetail || {});

      console.log(studentDetail)
      console.log(studentInfo)

      useEffect(() => {
        setStudentInfo(studentDetail);
      }, [studentDetail])


      const [isWhatsappChecked, setIsWhatsappChecked] = useState(false);
      const [maritalStatus, setMaritalStatus] = useState([]);
      const [countries, setCountries] = useState([]);
        const [states, setStates] = useState([]);
        const [cities, setCities] = useState([])
      const [branches, setBranches] = useState([]);
      const [nearest_branch, setNearestBranch] = useState(null);
      const [branchId, setBranchId] = useState(studentDetail?.nearest_branch_id?.id || null);
      const [pincodeError, setPincodeError] = useState('');
      const [pinCodeData, setPinCodeData] = useState({});
      const [snackbarOpen, setSnackbarOpen] = useState(false);
      const [snackbarMessage, setSnackbarMessage] = useState('');
      const [snackbarSeverity, setSnackbarSeverity] = useState('success');

      const [changes, setChanges] = useState({});
      
          const showSnackbar = (message, severity) => {
              setSnackbarMessage(message);
              setSnackbarSeverity(severity);
              setSnackbarOpen(true);
          };

        const [selectedCountry, setSelectedCountry] = useState(studentDetail?.country_id || 101);
      const [selectedState, setSelectedState] = useState(studentDetail?.state_id || null);
      const [selectedCity, setSelectedCity] = useState(studentDetail?.city_id || null);


        const handleWhatsappCheck = () => {
            setIsWhatsappChecked((prevIsChecked) => !prevIsChecked);
        };

        const handleCountryChange = (event) => {
            setSelectedCountry(event.target.value);
            setValue('country_id', event.target.value);
            setStudentInfo({ ...studentInfo, country_id: event.target.value })
          };
    
          const handleStateChange = (event) => {
            setSelectedState(event.target.value);
            setValue('state_id', event.target.value);
            setStudentInfo({ ...studentInfo, state_id: event.target.value })
          };
    
          const handleCityChange = (event) => {
            setSelectedCity(event.target.value);
            setValue('city_id', event.target.value);
            setStudentInfo({ ...studentInfo, city_id: event.target.value })
          };

          const handleFieldChange = (fieldName, value) => {
            // Update the changes object
            setChanges((prevChanges) => ({
              ...prevChanges,
              [fieldName]: value,
            }));
          };

          const handlePinCodeChange = (event) => {
            const newPinCode = event.target.value;
            setStudentInfo({ ...studentInfo, pincode: newPinCode });
          };
        
          const fetchPinCodeData = async (pincode) => {
            try {
              const response = await axios.get(`${API_ENDPOINT}route/detailbypincode/${pincode}`);
              if (response.status === 200) {
                return response.data;
              } else {
                console.log('Invalid pincode');
                setPincodeError('Invalid pin code');
                setNearestBranch(null);
                return null;
              }
            } catch (error) {
              console.error('Error fetching pin code data:', error);
              setPincodeError('Error fetching pinCode data');
              setNearestBranch(null);
              return null;
            }
          };
        
          useEffect(() => {
            const fetchData = async () => {
              try {
                const pinCodeData = await fetchPinCodeData(studentInfo.pincode);
               
                if (pinCodeData) {
                  setPincodeError('');
                  
                  setNearestBranch(pinCodeData.branch_detail[0].id);
                 
                  setBranchId(pinCodeData.branch_detail[0].id);
               
                  setStudentInfo((prevStudentInfo) => ({
                    ...prevStudentInfo,
                    state: pinCodeData.state,
                    city: pinCodeData.city,
                    nearest_branch: pinCodeData?.branch_detail[0].id,
                  }));
                 
                  setChanges((prevStudentInfo) => ({
                    ...prevStudentInfo,
                    pincode: studentInfo.pincode,
                    state: pinCodeData.state,
                    city: pinCodeData.city,
                    nearest_branch: pinCodeData?.branch_detail[0].id,
                  }));
                  
                } else {
                  setPincodeError('Invalid pin code');
                  setNearestBranch(null);
                  // handle other state resets if needed
                }
              } catch (error) {
                console.error('Error fetching pin code data:', error);
                setPincodeError('Error fetching pin code data');
                setNearestBranch(null);
                showSnackbar('Error fetching pin code data', 'error');
                // handle other state resets if needed
              }
            };
        
            if (studentInfo.pincode?.length === 6) {
              fetchData();
            }
          }, [studentInfo?.pincode]);

        

          const handleSaveStudentInfo = async () => {
   
               updateSectionData(changes, 'student_info');
   
               setChanges({});
             };
           
            //put api request for updating information with save
             const updateSectionData = (sectionData, sectionName) => {
               console.log(enquiryId)
               console.log(sectionData)
   
               
           
               fetch(`${API_ENDPOINT}lmenquirycrm/${enquiryId}`, {
                 method: 'PUT', 
                 headers: {
                   'Content-Type': 'application/json',
                   
                 },
                 body: JSON.stringify(sectionData),
               })
                 .then((response) => {
                   console.log(response)
                   if (response.ok) {
                     // Data updated successfully
                     
                     console.log(`Data updated for ${sectionName}`);
                     showSnackbar(`Data updated for ${sectionName}`, 'success');
                     //alert('Data updated successfully')
                   } else {
                     // Handle errors here
                     console.error(`Error updating data for ${sectionName}`);
                     showSnackbar(`Error updating data for ${sectionName}`, 'error');
                     //alert('Error updating data')
                   }
                 })
                 .catch((error) => {
                   console.error('API request error:', error);
                   showSnackbar('Network Error', 'error');
                   //alert('Network Error')
                 });
             };

          useEffect(() => {
            // Fetch menu items using the fetch function
            fetch(`${API_ENDPOINT}branch`)
              .then(response => response.json())
              .then(data => {
                setBranches(data);
                console.log(data)
              })
              .catch(error => {
                console.error('Error fetching branches:', error);
              });
          }, []);

          useEffect(() => {
            // Fetch menu items using the fetch function
            fetch(`${API_ENDPOINT}maritalstatuses`)
              .then(response => response.json())
              .then(data => {
                setMaritalStatus(data);
                console.log(data)
              })
              .catch(error => {
                console.error('Error fetching maritalstatus:', error);
              });
          }, []);

          useEffect(() => {
            // Fetch menu items using the fetch function
            fetch(`${API_ENDPOINT}country`)
              .then(response => response.json())
              .then(data => {
                setCountries(data);
                console.log(data)
              })
              .catch(error => {
                console.error('Error fetching country:', error);
              });
          }, []);

          useEffect(() => {
             if (selectedCountry) {
            fetch(`${API_ENDPOINT}oldstate`)
                .then(response => response.json())
                .then(data => {

                const filteredState = data.filter(state => state.country_id === selectedCountry)
                setStates(filteredState);
                console.log(filteredState);
                })
                .catch(error => {
                console.error('Error fetching state:', error);
                });
            }
          }, [selectedCountry]);

          useEffect(() => {
            if(selectedCountry && selectedState) {
            fetch(`${API_ENDPOINT}oldcity`)
              .then(response => response.json())
              .then(data => {

                const filteredCity = data.filter(city => city.country === selectedCountry && city.state === selectedState)
                setCities(filteredCity);
                console.log(filteredCity)
              })
              .catch(error => {
                console.error('Error fetching city:', error);
              })
            }
          }, [selectedCountry, selectedState]);

         

    return (
        <Box sx={{ m: 1.5 }}> 
        { studentInfo && (

    <Grid container spacing={gridSpacing}>
        <Grid item xs={12}>
            <Grid container spacing={2} alignItems="center">
                <Grid item>
                    <Avatar alt="User 1" sx={{ height: 80, width: 80 }} />
                </Grid>
                <Grid item sm zeroMinWidth>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Stack direction="row" spacing={2} alignItems="center">
                                <input accept="image/*" style={{ display: 'none' }} id="contained-button-file" multiple type="file" />
                            </Stack>
                        </Grid>
                        <Grid item xs={12}>
                            <Typography variant="caption">
                                <ErrorTwoToneIcon sx={{ height: 16, width: 16, mr: 1, verticalAlign: 'text-bottom' }} />
                                Image size Limit should be 125kb Max.
                            </Typography>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
       
        
               

                <Grid item xs={6} md={4} lg={3}>
                <InputLabel style={{ color: 'black', position: 'relative' }}>
                    First Name
                    <span style={{ color: 'red', position: 'absolute', marginLeft: '4px', fontSize: '1.5em' }}>*</span>
                    </InputLabel>
                <Controller
                  name='first_name'
                  control={control}
                  //defaultValue={ enquiryData?.first_name || '' }
                  //rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      value={studentInfo.first_name || ''}
                    onChange={(e) => {
                      setStudentInfo({ ...studentInfo, first_name: e.target.value })
                      handleFieldChange('first_name', e.target.value)
                    }}
                      InputProps={{
                        style: { borderRadius: '12px' },
                      }}
                      error={Boolean(errors.first_name)}
                      helperText={errors.first_name && 'First name is required'}
                    />
                  )}
                />
                </Grid>

                
                 <Grid item xs={6} md={4} lg={3}>
                 <InputLabel style={{ color: 'black' }}>Last Name</InputLabel>
                <Controller
                  name='last_name'
                  control={control}
                  //rules={{ required: true }}
                  //defaultValue={ enquiryData?.last_name || '' }
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      value={studentInfo.last_name || ''}
                      onChange={(e) => {
                        setStudentInfo({ ...studentInfo, last_name: e.target.value })
                        handleFieldChange('last_name', e.target.value)
                      }}
                      InputProps={{
                        style: { borderRadius: '12px' },
                      }}
                      error={Boolean(errors.last_name)}
                      helperText={errors.last_name && 'Last name is required'}
                    />
                  )}
                />
              </Grid>
              <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: 'black' }} >Gender</InputLabel>
                <Controller
                  name='gender'
                  control={control}
                  //defaultValue={ enquiryData?.gender || '' }
                  render={({ field }) => (
                    <TextField
                      select
                      {...field}
                      InputProps={{
                        style: { borderRadius: '12px' },
                      }}
                      fullWidth
                      value={studentInfo.gender || ''}
                    onChange={(e) => {
                      setStudentInfo({ ...studentInfo, gender: e.target.value })
                      handleFieldChange('gender', e.target.value)
                    }}
                      error={Boolean(errors.gender)}
                      helperText={errors.gender && 'Gender is required'}
                    >
                      <MenuItem value='male'>Male</MenuItem>
                      <MenuItem value='female'>Female</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: 'black' }}>
                Date of Birth
                </InputLabel>
                <Controller
                    name='dob'
                    control={control}
                   // defaultValue={ enquiryData?.dob || '' }
                    render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        value={studentInfo.dob || ''}
                        onChange={(e) => {
                          setStudentInfo({ ...studentInfo, dob: e.target.value })
                          handleFieldChange('dob', e.target.value)
                        }}
                        type='date'
                        InputProps={{
                          style: { borderRadius: '12px' },
                          inputProps: { max: '2005-12-31' },
                      }}
                        error={Boolean(errors.dob)}
                        helperText={errors.dob && 'Date of Birth is required'}
                    />
                    )}
                />
                {errors.dob && (
                    <span style={{ color: 'red', position: 'absolute', top: 0, right: -8 }}>
                    *
                    </span>
                )}
              </Grid>

              <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: 'black' }} >Marital Status</InputLabel>
                <Controller
                  name='marital_status'
                  control={control}
                  render={({ field }) => (
                    <TextField
                      select
                      {...field}
                      value={studentInfo.marital_status || ''}
                        onChange={(e) => {
                          setStudentInfo({ ...studentInfo, marital_status: e.target.value })
                          handleFieldChange('marital_status', e.target.value)
                        }}
                      InputProps={{
                        style: { borderRadius: '12px' },
                      }}
                      fullWidth
                      error={!!errors.marital_status}
                      //helperText={errors.gender && 'Gender is required'}
                    >
                      {maritalStatus.map((maritalstatus) => (
                            <MenuItem key={maritalstatus.id} value={maritalstatus.id}>{maritalstatus.maritial_type}</MenuItem>
                        ))}
                    </TextField>
                  )}
                />
              </Grid>
             


            
              
                            <Grid item xs={6} md={4} lg={4} container>

                            <Grid item xs={4} md={4} lg={4}>
                            <InputLabel style={{ color: 'black', position: 'relative' }}>
                                ISD Code
                                <span style={{ color: 'red', position: 'absolute', marginLeft: '4px', fontSize: '1.5em' }}>*</span>
                                </InputLabel>
                                    <Controller
                                    name='mobile_country_code'
                                    control={control}
                                    //defaultValue={91}
                                    //rules={{ required: "Country code is required" }}
                                    render={({ field }) => (
                                        <TextField
                                        select
                                        {...field}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                        value={studentInfo.mobile_country_code}
                                        onChange={(e) => {
                                          setStudentInfo({ ...studentInfo, mobile_country_code: e.target.value })
                                          handleFieldChange('mobile_country_code', e.target.value)
                                        }}
                                        error={Boolean(errors.mobile_country_code)}
                                        //helperText={errors.country && 'Country is required'}
                                        >
                                        {countries.map((country) => (
                                            <MenuItem key={country.phone_code} value={country.phone_code}>{country.phone_code}</MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                    />
                                    {errors.mobile_country_code && (
                                        <span style={{ color: 'red'}}>
                                        {errors.mobile_country_code.message} 
                                        </span>
                                    )}
                                </Grid>
                            
                                <Grid item xs={8} md={8} lg={8}>
                                <InputLabel style={{ color: 'black', position: 'relative' }}>
                                    Mobile
                                    <span style={{ color: 'red', position: 'absolute', marginLeft: '4px', fontSize: '1.5em' }}>*</span>
                                    </InputLabel>
                            <Controller 
                                name="mobile"
                                control={control}
                                //defaultValue='+91'
                                /*rules={{ 
                                required: "Contact number is required",
                                pattern: {
                                    value: /^[1-9][0-9]{9}$/,
                                    message: "Invalid mobile number."
                                }
                                }}*/
                                render={({ field }) => (
                                <TextField
                                    {...field}
                                    value={studentInfo.mobile}
                                        onChange={(e) => {
                                          setStudentInfo({ ...studentInfo, mobile: e.target.value })
                                          handleFieldChange('mobile', e.target.value)
                                        }}
                                    InputProps={{
                                        style: { borderRadius: '12px' },
                                        }}
                                    error={!!errors.mobile}
                                    //helperText={errors.mobile?.message}
                                    fullWidth
                                />
                                )}
                            ></Controller>
                            {errors.mobile && (
                                    <span style={{ color: 'red'}}>
                                    {errors.mobile.message} 
                                    </span>
                                )}
                            </Grid>
                            </Grid>

                            
                            <Grid item xs={6} md={4} lg={4} container>
                            <Grid item xs={4} md={4} lg={4}>
                            <InputLabel style={{ color: 'black', position: 'relative' }}>
                                ISD Code
                                <span style={{ color: 'red', position: 'absolute', marginLeft: '4px', fontSize: '1.5em' }}>*</span>
                                </InputLabel>
                                    <Controller
                                    name='whatsapp_country_code'
                                    control={control}
                                    //defaultValue={91}
                                    //rules={{ required: "Country code is required" }}
                                    render={({ field }) => (
                                        <TextField
                                        select
                                        {...field}
                                        value={studentInfo.whatsapp_country_code}
                                        onChange={(e) => {
                                          setStudentInfo({ ...studentInfo, whatsapp_country_code: e.target.value })
                                          handleFieldChange('whatsapp_country_code', e.target.value)
                                        }}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                       // defaultValue={91}
                                        error={Boolean(errors.whatsapp_country_code)}
                                        >
                                        {countries.map((country) => (
                                            <MenuItem key={country.phone_code} value={country.phone_code}>{country.phone_code}</MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                    />
                                    {errors.whatsapp_country_code && (
                                        <span style={{ color: 'red'}}>
                                        {errors.whatsapp_country_code.message} 
                                        </span>
                                    )}
                                </Grid>
                                <Grid item xs={8} md={8} lg={8}>
                                <InputLabel style={{ color: 'black', position: 'relative' }}>
                                Whatsapp Number
                                <span style={{ color: 'red', position: 'absolute', marginLeft: '4px', fontSize: '1.5em' }}>*</span>
                                </InputLabel>
                                <Controller 
                                name="whatsapp_number"
                                control={control}
                               /* rules={{ 
                                    pattern: {
                                        required: "Whatsapp number is required",
                                        value: /^[1-9][0-9]{9}$/,
                                        message: "Invalid whatsapp number."
                                    }
                                    }}*/
                                render={({ field }) => (
                                <TextField
                                    {...field}
                                    value={studentInfo.whatsapp_number}
                                    onChange={(e) => {
                                      setStudentInfo({ ...studentInfo, whatsapp_number: e.target.value })
                                      handleFieldChange('whatsapp_number', e.target.value)
                                    }}
                                    InputProps={{
                                        style: { borderRadius: '12px' },
                                        }}
                                    error={!!errors.whatsapp_number}
                                   // helperText={errors.whatsapp_number?.message}
                                    fullWidth
                                />
                                )}
                            ></Controller>
                            {errors.whatsapp_number && (
                                    <span style={{ color: 'red'}}>
                                    {errors.whatsapp_number.message} 
                                    </span>
                                )}
                            </Grid>
                            </Grid>
                          

                           

                            <Grid item xs={6} md={4} lg={4}>
                            <InputLabel style={{ color: 'black', position: 'relative' }}>
                                Email
                                <span style={{ color: 'red', position: 'absolute', marginLeft: '4px', fontSize: '1.5em' }}>*</span>
                                </InputLabel>
                            <Controller
                                name="email"
                                control={control}
                               /* rules={{ 
                                    required: "Email is required", 
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "Invalid email address",
                                    },
                                }}*/
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                        value={studentInfo.email}
                                        onChange={(e) => {
                                          setStudentInfo({ ...studentInfo, email: e.target.value })
                                          handleFieldChange('email', e.target.value)
                                        }}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                            }}
                                        variant="outlined"
                                        error={!!errors.email}
                                        fullWidth
                                        />
                                )}
                                ></Controller>
                                {errors.email && (
                                    <span style={{ color: 'red'}}>
                                    {errors.email.message} 
                                    </span>
                                )}
                            </Grid>


                            <Grid item xs={6} md={4} lg={3}>
                            <InputLabel style={{ color: 'black', position: 'relative' }}>
                                Country of Residence
                                <span style={{ color: 'red', position: 'absolute', marginLeft: '4px', fontSize: '1.5em' }}>*</span>
                                </InputLabel>
                                    <Controller
                                    name='country_id'
                                    control={control}
                                    defaultValue={101}
                                    rules={{ required: "Country is required" }}
                                    render={({ field }) => (
                                        <TextField
                                        select
                                        {...field}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                        value={selectedCountry}
                                        onChange={handleCountryChange} 
                                       // defaultValue={101}
                                        error={Boolean(errors.country_id)}
                                        //helperText={errors.country && 'Country is required'}
                                        >
                                       {countries.map((country) => (
                                            <MenuItem key={country.id} value={country.id}>{country.country_name}</MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                    />
                                    {errors.country_id && (
                                        <span style={{ color: 'red'}}>
                                        {errors.country_id.message} 
                                        </span>
                                    )}
                                </Grid>

                                <Grid item xs={6} md={4} lg={3}>
                                    <InputLabel style={{ color: 'black' }}>Pincode</InputLabel>
                                    <Controller
                                        name='pincode_id'
                                        control={control}
                                        //rules={{ required: "Pin code is required", pattern: { value: /^\d{6}$/, message: "Invalid pin code" } }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                InputProps={{
                                                    style: { borderRadius: '12px' },
                                                }}
                                                fullWidth
                                               error={Boolean(errors.pincode_id)}
                                               // onChange={handlePinCodeChange}
                                                value={studentInfo.pincode_id || null}
                                                onChange={(e) => {
                                                    setStudentInfo({ ...studentInfo, pincode_id: e.target.value });
                                                    handlePinCodeChange(e);
                                                    
                                                }}
                                            />
                                        )}
                                    />
                                    {errors.pincode_id && (
                                        <span className="error-message" style={{ color: 'red' }}>
                                            {errors.pincode_id.message}
                                        </span>
                                    )}
                                    {pincodeError && <span className="error-message" style={{ color: 'red' }}>{pincodeError}</span>}

                                </Grid>

                                <Grid item xs={6} md={4} lg={3}>
                                <InputLabel style={{ color: 'black' }} >State</InputLabel>
                                    <Controller
                                    name='state_id'
                                    control={control}
                                    render={({ field }) => (
                                        <TextField
                                        select
                                        {...field}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                        value={selectedState}
                                        onChange={handleStateChange}
                                       // defaultValue={pinCodeData?.state}
                                        error={Boolean(errors.state_id)}
                                        //helperText={errors.state}
                                        >
                                        {states.map((state) => (
                                            <MenuItem key={state.id} value={state.id}>
                                                {state.state_name}
                                            </MenuItem>
                                            ))
                                       }
                                        </TextField>
                                    )}
                                    />
                                </Grid>

                                <Grid item xs={6} md={4} lg={3}>
                                <InputLabel style={{ color: 'black' }} >City</InputLabel>
                                    <Controller
                                    name='city_id'
                                    control={control}
                                    
                                    render={({ field }) => (
                                        <TextField
                                        select
                                        {...field}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                        value={selectedCity}
                                        onChange={handleCityChange}
                                        //defaultValue="IN"
                                        error={Boolean(errors.city_id)}
                                       // helperText={errors.city }
                                        >
                                         {cities.map((city) => (
                                            <MenuItem key={city.id} value={city.id}>{city.city_name}</MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                    />
                                </Grid>

                                <Grid item xs={6} md={4} lg={3}>
                                <InputLabel style={{ color: 'black' }} >Nearest Branch</InputLabel>
                                    <Controller
                                    name='nearest_branch_id'
                                    control={control}
                                    //rules={{ required: "Branch name is required" }}
                                    render={({ field }) => (
                                        <TextField
                                        select
                                        {...field}
                                        value={nearest_branch}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                       
                                        error={Boolean(errors.nearest_branch_id)}
                                        onChange={(e) => {
                                          setNearestBranch(e.target.value)
                                          setStudentInfo({ ...studentInfo, nearest_branch_id: e.target.value })
                                          handleFieldChange('nearest_branch_id', e.target.value)
                                        }}
                                       //onChange={(event) => setNearestBranch(event.target.value)}
                                       // helperText={errors.nearest_branch && 'Branch Name is required'}
                                        >
                                       {branches.map((branch) => (
                                        <MenuItem key={branch.id} value={branch.id}>{branch.branch_name}</MenuItem>
                                       ))}
                                        </TextField>
                                    )}
                                    />
                                    {errors.nearest_branch_id && (
                                        <span style={{ color: 'red'}}>
                                        {errors.nearest_branch_id.message}
                                        </span>
                                    )}
                                </Grid>

       {/*} <Grid item xs={6} md={4} lg={3}>
                <InputLabel required style={{ color: 'black' }}>First Name</InputLabel>
                <Controller
                  name='first_name'
                  control={control}
                  defaultValue={ studentInfo?.first_name || '' }
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                     // value={field.value}
                      value={studentInfo.first_name || ''}
                   // onChange={(e) => setStudentInfo({ ...studentInfo, first_name: e.target.value })}
                      InputProps={{
                        style: { borderRadius: '12px' },
                      }}
                      error={Boolean(errors.first_name)}
                      helperText={errors.first_name && 'First name is required'}
                    />
                  )}
                />
                </Grid>

       

        <Grid item xs={6} md={4} lg={3}>
                 <InputLabel required style={{ color: 'black' }}>Last Name</InputLabel>
                <Controller
                  name='last_name'
                  control={control}
                  //rules={{ required: true }}
                  defaultValue={studentInfo?.last_name || ''}
                 rules={{ required: 'Last name is required' }}
                  render={({ field }) => (
                    
                    <TextField
                      {...field}
                      fullWidth
                      //value={studentInfo.last_name || ''}
                      //onChange={(e) => setStudentInfo({ ...studentInfo, last_name: e.target.value })}
                      InputProps={{
                        style: { borderRadius: '12px' },
                      }}
                      error={!!errors.last_name}
                      //helperText={errors.last_name && 'Last name is required'}
                    />
                  )}
                />
                {errors.last_name && (
                <span style={{ color: 'red'}}>
                {errors.last_name.message}
                </span>
            )}
              </Grid>
              <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: 'black' }} >Gender</InputLabel>
                <Controller
                  name='gender'
                  control={control}
                  defaultValue={studentInfo?.gender || ''}
                  render={({ field }) => (
                    <TextField
                      select
                      {...field}
                      InputProps={{
                        style: { borderRadius: '12px' },
                      }}
                      fullWidth
                      //value={studentInfo.gender || ''}
                     // onChange={(e) => setStudentInfo({ ...studentInfo, gender: e.target.value })}
                      error={!!errors.gender}
                      //helperText={errors.gender && 'Gender is required'}
                    >
                      <MenuItem value='male'>Male</MenuItem>
                      <MenuItem value='female'>Female</MenuItem>
                    </TextField>
                  )}
                />
              </Grid>

              <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: 'black' }}>
                Date of Birth
                </InputLabel>
                <Controller
                    name='dob'
                    control={control}
                    defaultValue={studentInfo?.dob || ''}
                    render={({ field }) => (
                    <TextField
                        {...field}
                        fullWidth
                        type='date'
                        InputProps={{
                        style: { borderRadius: '12px' },
                        }}
                        //value={studentInfo.dob || ''}
                        //onChange={(e) => setStudentInfo({ ...studentInfo, dob: e.target.value })}
                        error={!!errors.dob}
                        //helperText={errors.dob && 'Date of Birth is required'}
                    />
                    )}
                />
                {errors.dob && (
                    <span style={{ color: 'red', position: 'absolute', top: 0, right: -8 }}>
                    *
                    </span>
                )}
              </Grid>

              <Grid item xs={6} md={4} lg={4}>
                            <InputLabel required style={{ color: 'black' }}>Mobile Number</InputLabel>
                            <Controller 
                                name="mobile"
                                control={control}
                                //defaultValue='+91'
                                defaultValue={studentInfo?.mobile || '+91'}
                                rules={{ 
                                required: "Contact number is required",
                                pattern: {
                                    value: /^[+]\d{1,3}\d{8,10}$/, 
                                    message: "Invalid contact number format. Please enter in the format: '+{country code}{contact number}'"
                                }
                                }}
                                render={({ field }) => (
                                <TextField
                                    {...field}
                                    InputProps={{
                                        style: { borderRadius: '12px' },
                                        }}
                                    error={!!errors.mobile}
                                    //helperText={errors.mobile?.message}
                                    fullWidth
                                    //value={studentInfo.mobile || ''}
                                    //onChange={(e) => setStudentInfo({ ...studentInfo, mobile: e.target.value })}
                                />
                                )}
                            ></Controller>
                            {errors.mobile && (
                                    <span style={{ color: 'red'}}>
                                    {errors.mobile.message} 
                                    </span>
                                )}
                                 
                            </Grid>

                           
                            <Grid item xs={6} md={4} lg={4}>
                            
                                <InputLabel required style={{ color: 'black', flex: 1 }}>
                                WhatsApp Number
                                </InputLabel>
                                <Controller 
                                name="whatsapp_number"
                                control={control}
                                //defaultValue='+91'
                                defaultValue={studentInfo?.whatsapp_number || ''}
                                render={({ field }) => (
                                <TextField
                                    {...field}
                                    InputProps={{
                                        style: { borderRadius: '12px' },
                                        }}
                                    error={!!errors.whatsapp_number}
                                   // helperText={errors.whatsapp_number?.message}
                                    fullWidth
                                   // value={studentInfo.whatsapp_number || ''}
                                   // onChange={(e) => setStudentInfo({ ...studentInfo, whatsapp_number: e.target.value })}
                                />
                                )}
                            ></Controller>
                            </Grid>
                            
                            <Grid item xs={6} md={4} lg={4}>
                            <InputLabel required style={{ color: 'black' }}>Email</InputLabel>
                            <Controller
                                name="email"
                                control={control}
                                defaultValue={studentInfo?.email || ''}
                                rules={{ 
                                    required: "Email is required", 
                                    pattern: {
                                        value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                                        message: "Invalid email address",
                                    },
                                }}
                                render={({ field }) => (
                                    <TextField
                                        {...field}
                                       // label="Email"
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                            }}
                                        variant="outlined"
                                        error={!!errors.email}
                                       // helperText={errors.email}
                                        fullWidth
                                        //value={studentInfo.email || ''}
                                       // onChange={(e) => setStudentInfo({ ...studentInfo, email: e.target.value })}
                                        />
                                )}
                                ></Controller>
                                {errors.email && (
                                    <span style={{ color: 'red'}}>
                                    {errors.email.message} 
                                    </span>
                                )}
                            </Grid>

                            <Grid item xs={6} md={4} lg={3}>
                                <InputLabel required style={{ color: 'black' }} >Country</InputLabel>
                                    <Controller
                                    name='country'
                                    control={control}
                                    defaultValue='IN'
                                    //defaultValue="IN"
                                    rules={{ required: "Country is required" }}
                                    render={({ field }) => (
                                        <TextField
                                        select
                                        {...field}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                        //defaultValue="IN"
                                        error={Boolean(errors.country)}
                                        //helperText={errors.country && 'Country is required'}
                                        >
                                        {Country.getAllCountries().map((country) => (
                                            <MenuItem key={country.isoCode} value={country.isoCode}>{country.name}</MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                    />
                                    {errors.country && (
                                        <span style={{ color: 'red'}}>
                                        {errors.country.message} 
                                        </span>
                                    )}
                                </Grid>

                                <Grid item xs={6} md={4} lg={3}>
                                    <InputLabel required style={{ color: 'black' }}>Pincode</InputLabel>
                                    <Controller
                                        name='pincode'
                                        control={control}
                                        defaultValue={studentInfo?.pincode || null}
                                        //rules={{ required: "Pin code is required", pattern: { value: /^\d{6}$/, message: "Invalid pin code" } }}
                                        render={({ field }) => (
                                            <TextField
                                                {...field}
                                                InputProps={{
                                                    style: { borderRadius: '12px' },
                                                }}
                                                fullWidth
                                               // value={studentInfo.pincode || null}
                                               // onChange={(e) => setStudentInfo({ ...studentInfo, pincode: e.target.value })}
                                               error={Boolean(errors.pincode)}
                                                onChange={handlePinCodeChange}
                                            />
                                        )}
                                    />
                                    {errors.pincode && (
                                        <span className="error-message" style={{ color: 'red' }}>
                                            {errors.pincode.message}
                                        </span>
                                    )}
                                    {pincodeError && <span className="error-message" style={{ color: 'red' }}>{pincodeError}</span>}

                                </Grid>

                                <Grid item xs={6} md={4} lg={3}>
                                <InputLabel style={{ color: 'black' }} >State</InputLabel>
                                    <Controller
                                    name='state'
                                    control={control}
                                    defaultValue={studentInfo?.state|| ''}
                                    render={({ field }) => (
                                        <TextField
                                        //select
                                        {...field}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                       // defaultValue={pinCodeData?.state}
                                        error={Boolean(errors.state)}
                                        //helperText={errors.state}
                                        >
                                        {States.map((state) => (
                                            <MenuItem key={state.isoCode} value={state.isoCode}>
                                                {state.name}
                                            </MenuItem>
                                            ))
                                       }
                                        </TextField>
                                    )}
                                    />
                                </Grid>

                                <Grid item xs={6} md={4} lg={3}>
                                <InputLabel style={{ color: 'black' }} >City</InputLabel>
                                    <Controller
                                    name='city'
                                    control={control}
                                    defaultValue={studentInfo?.city || ''}
                                    render={({ field }) => (
                                        <TextField
                                       // select
                                        {...field}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                        //defaultValue="IN"
                                        error={Boolean(errors.city)}
                                       // helperText={errors.city }
                                        >
                                         {Cities.map((city) => (
                                            <MenuItem key={city.name} value={city.name}>{city.name}</MenuItem>
                                            ))}
                                        </TextField>
                                    )}
                                    />
                                </Grid>

                                <Grid item xs={6} md={4} lg={3}>
                                <InputLabel required style={{ color: 'black' }} >Nearest Branch</InputLabel>
                                    <Controller
                                    name='nearest_branch'
                                    control={control}
                                    defaultValue={studentInfo?.nearest_branch?.id || null}
                                    //rules={{ required: "Branch name is required" }}
                                    render={({ field }) => (
                                        <TextField
                                        select
                                        {...field}
                                       value={nearest_branch}
                                        InputProps={{
                                            style: { borderRadius: '12px' },
                                        }}
                                        fullWidth
                                       
                                        error={Boolean(errors.nearest_branch)}
                                        onChange={(event) => setNearestBranch(event.target.value)}
                                       // helperText={errors.nearest_branch && 'Branch Name is required'}
                                        >
                                       {branches.map((branch) => (
                                        <MenuItem key={branch.id} value={branch.id}>{branch.branch_name}</MenuItem>
                                       ))}
                                        </TextField>
                                    )}
                                    />
                                    {errors.nearest_branch && (
                                        <span style={{ color: 'red'}}>
                                        {errors.nearest_branch.message}
                                        </span> 
                                    )}
                                    </Grid>*/}

                                <Grid container justifyContent="right" spacing={0} mt={2}>
                            <Grid item>
                                <Button
                                    variant="contained"
                                    color="secondary"
                                    size="large"
                                    type='submit' 
                                    onClick={handleSaveStudentInfo}
                                >
                                    Save
                                </Button>
                                </Grid>
                                </Grid>
                               

                                <CustomSnackbar
                                    open={snackbarOpen}
                                    onClose={() => setSnackbarOpen(false)}
                                    message={snackbarMessage}
                                    severity={snackbarSeverity}
                                    />
                     </Grid>
                     )}
                   </Box>
            )};

export default StudentFormInfo;