import PropTypes from 'prop-types';
import { cloneElement, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Avatar, Button, Chip, Divider, Grid, IconButton, Typography, useScrollTrigger } from '@mui/material';

// project imports
import SubCard from '../../components/ui-component/card/SubCard';
import { gridSpacing } from '../../store/constant';

// assets
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import PinDropTwoToneIcon from '@mui/icons-material/PinDropTwoTone';
import CakeTwoToneIcon from '@mui/icons-material/CakeTwoTone';
import InfoTwoToneIcon from '@mui/icons-material/InfoTwoTone';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import NotInterestedTwoToneIcon from '@mui/icons-material/NotInterestedTwoTone';
import { fDate } from '../../../utils/formatTime';
import CustomSnackbar from '../../components/ui-component/snackbar/CustomSnackbar';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

//const avatarImage = require.context('assets/image', true);

// sticky details card

function ElevationScroll({ children, window }) {
    const trigger = useScrollTrigger({
        disableHysteresis: true,
        threshold: 130,
        target: window || undefined
    });

    return cloneElement(children, {
        style: {
            position: trigger ? 'fixed' : 'relative',
            top: trigger ? 83 : 0,
            width: trigger ? 318 : '100%'
        }
    });
}

ElevationScroll.propTypes = {
    children: PropTypes.node,
    window: PropTypes.object
};

// ==============================|| CONTACT CARD/LIST USER DETAILS ||============================== //

const UserDetails = ({ user, onClose, onEditClick, onUpdateUserStatus, onChangeStatusClick, ...others }) => {
    const  userId  = localStorage.getItem('userId')
    const theme = useTheme();
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    
        const showSnackbar = (message, severity) => {
            setSnackbarMessage(message);
            setSnackbarSeverity(severity);
            setSnackbarOpen(true);
        };

    //const avatarProfile = user.avatar && avatarImage(`./${user.avatar}`);

    const handleStatusChange = async () => {
        const user_id = parseInt(userId);
        const id = user.user_id;
        
        try {
            const currentStatus = user.status;
            const newStatus = currentStatus === 'active' ? 'inactive' : 'active';
            
            await fetch(`${API_ENDPOINT}route/updateemployee/${id}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                   
                },
                body: JSON.stringify({
                   
                    status: newStatus,
                    user_id: user_id
                }),
            });
            onUpdateUserStatus(id, newStatus);
            showSnackbar('Status changed successfully', 'success');
            console.log('Status changed successfully');
        } catch (error) {
            console.error('Error changing status:', error);
            showSnackbar('Error changing status', 'error');
        }
    };

    return (
        <ElevationScroll {...others}>
            <SubCard
                sx={{
                    background: theme.palette.mode === 'dark' ? theme.palette.dark.main : theme.palette.grey[50],
                    width: '100%',
                    maxWidth: 342
                }}
            >
                <Grid container spacing={gridSpacing}>
                    <Grid item xs={12}>
                        <Grid container alignItems="center" spacing={1}>
                            <Grid item>
                                <Avatar alt={user.name} src='./assets/image/avatar-11.png' sx={{ width: 64, height: 64 }} />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h5" component="div" sx={{ fontSize: '1rem' }}>
                                            {user.first_name} {user.last_name}
                                        </Typography>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <Chip
                                            label={user.status}
                                            sx={{
                                                color: theme.palette.primary.main,
                                                bgcolor:
                                                    theme.palette.mode === 'dark' ? theme.palette.dark.dark : theme.palette.primary.light
                                            }}
                                        />
                                    </Grid>
                                </Grid>
                            </Grid>
                            <Grid item>
                                <IconButton onClick={onClose} size="large">
                                    <HighlightOffTwoToneIcon />
                                </IconButton>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item xs={6}>
                                <Button variant="outlined" fullWidth startIcon={<ChatBubbleTwoToneIcon />} onClick={onEditClick}>
                                    Edit
                                </Button>
                            </Grid>
                            <Grid item xs={6}>
                                <Button 
                                    variant="outlined" 
                                    color="secondary" 
                                    fullWidth 
                                    startIcon={<NotInterestedTwoToneIcon />}
                                    onClick={() => {
                                        handleStatusChange();
                                        onChangeStatusClick();
                                    }}
                                    >
                                    Change Status
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Divider />
                    </Grid>
                    
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <WorkTwoToneIcon sx={{ verticalAlign: 'sub', fontSize: '1.125rem', mr: 0.625 }} />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography variant="body2">{user.user_role}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <MailTwoToneIcon sx={{ verticalAlign: 'sub', fontSize: '1.125rem', mr: 0.625 }} />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography variant="body2" sx={{ mb: 0.625 }}>
                                    {user.email}
                                    
                                </Typography>
                               
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <CallTwoToneIcon sx={{ verticalAlign: 'sub', fontSize: '1.125rem', mr: 0.625 }} />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography variant="body2" sx={{ mb: 0.625 }}>
                                    {user.mobile}
                                   
                                </Typography>
                                
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <PinDropTwoToneIcon sx={{ verticalAlign: 'sub', fontSize: '1.125rem', mr: 0.625 }} />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography variant="body2">{user.branch_id?.branch_name ||  'Not Found'}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <CakeTwoToneIcon sx={{ verticalAlign: 'sub', fontSize: '1.125rem', mr: 0.625 }} />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography variant="body2">{fDate(user.dob)}</Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={12}>
                        <Grid container spacing={1}>
                            <Grid item>
                                <InfoTwoToneIcon sx={{ verticalAlign: 'sub', fontSize: '1.125rem', mr: 0.625 }} />
                            </Grid>
                            <Grid item xs zeroMinWidth>
                                <Typography variant="body2" sx={{ mb: 0.625 }}>
                                    {user.gender}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>

                <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                severity={snackbarSeverity}
                />
            </SubCard>
        </ElevationScroll>
    );
};

UserDetails.propTypes = {
    user: PropTypes.object,
    onClose: PropTypes.func,
    onEditClick: PropTypes.func
};

export default UserDetails;