import {
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  Collapse,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import InfoCard from "./InfoCard";
import EventFilter from "./EventFilter";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { fDate } from "../../../utils/formatTime";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import {
  format,
  subMonths,
  addMonths,
  isSameMonth,
  getMonth,
  getYear,
} from "date-fns";
import { useGetTokenOrLogout } from "../../../utils/token";

export default function UserWiseReport({ open }) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const getTokenOrLogout = useGetTokenOrLogout();
  const [eventData, setEventData] = useState([]);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [eventList, setEventList] = useState([]);
  const [eventname, setEventName] = useState([]);
  const [eventloading, setEventLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const [isPastSelected, setIsPastSelected] = useState(false);

  const currentDate = new Date();
  const [selectedMonthYear, setSelectedMonthYear] = useState(
    format(currentDate, "MMMM,yyyy")
  );

  console.log(selectedMonthYear);
  const dates = [
    subMonths(currentDate, 2),
    subMonths(currentDate, 1),
    currentDate,
    addMonths(currentDate, 1),
    addMonths(currentDate, 2),
  ];

  const noOptionsText = "Loading...";

  useEffect(() => {
    // Send current month by default when component mounts
    handleSubmitDurationdata(currentDate);
  }, []);

  const handleSubmitDurationdata = async (date) => {
    setEventName([]);
    console.log(format(date, "MMMM,yyyy"));
    setSelectedMonthYear(format(date, "MMMM,yyyy"));

    setIsPastSelected(false);

    setEventLoading(true);

    const requestData = {
      filter: format(date, "MMMM,yyyy"),
    };

    try {
      const response = await fetch(`${API_ENDPOINT}campaign/eventbymonth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        const sortedData = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setEventList(sortedData);
        setEventLoading(false);
      } else if (response.status === 404) {
        setEventList([]);
        setEventLoading(false);
      } else {
        setEventLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setEventLoading(false);
    }
  };

  const handleSubmitpastdata = async (date) => {
    setEventName([]);
    setIsPastSelected(true);
    setSelectedMonthYear("");

    setEventLoading(true);

    const requestData = {
      filter: "past",
    };

    try {
      const response = await fetch(`${API_ENDPOINT}campaign/eventbymonth`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        const sortedData = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setEventList(sortedData);
        setEventLoading(false);
      } else if (response.status === 404) {
        setEventList([]);

        setEventLoading(false);
      } else {
        setEventLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setEventLoading(false);
    }
  };
  const allcampaignList = [
    ...(eventList.length > 1 ? [{ campaign_name: "Select All" }] : []),
    ...eventList,
  ];

  const handelEventChange = (event, value) => {
    if (value.some((option) => option.campaign_name === "Select All")) {
      setEventName(eventList);
    } else {
      setEventName(value);
    }
  };
  const columns = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div>{params.row?.user_name} </div>,
    },

    {
      field: "registerdAndConfirmed",
      headerName: "Registerd And Confirmed",
      width: 200,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>{params.row?.registered_and_confirmed} </div>
      ),
    },
    {
      field: "registerdAndNotConfirmed",
      headerName: "Registered And Not Confirmed",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>{params.row?.registered_and_not_confirmed} </div>
      ),
    },
    {
      field: "notRegisterd",
      headerName: "Not Registered",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div> {params.row?.not_registered}</div>,
    },
    {
      field: "totalRegistration",
      headerName: "Total Registration",
      width: 200,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div>{params.row?.total_registration} </div>,
    },
    {
      field: "totalAttendedRegisterdStudent",
      headerName: "Total Attended (Registered Student)",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div>{params.row?.attended_registered} </div>,
    },
    {
      field: "attendedNotRegistredStudent",
      headerName: "Attended (Not Registered Student)",
      width: 250,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div>{params.row?.attended_not_registered} </div>,
    },
    {
      field: "total attended",
      headerName: "Total Attended",
      width: 200,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div>{params.row?.total_attended}</div>,
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Handle form submission logic here
    setLoading(true);

    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json" // Make sure to keep the content type
    };
    const requestData = {
      event: eventname.map((i) => i.id),
      user_id:parseInt(userId)
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/eventuserwisereport`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        setEventData(result);
        setLoading(false);
      } 
      else if (response.status === 404) {
             navigate("/page404");
           } else if (response.status === 401) {
             navigate("/page401");
           } else if (response.status === 403) {
             navigate("/page403");
           } else if (response.status === 440) {
             navigate("/page440");
           } else {
             navigate("/page500");
           }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
      navigate("/page500");
    }
  };

  const handleReset = () => {
    setEventName([]);
    setEventData([]);
  };

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid item lg={12} sm={12} md={12} xs={12}>
        <Collapse in={open} fullWidth>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Typography
                  sx={{
                    color: "#2E4069",
                    fontSize: "19px",
                    fontWeight: 600,
                    marginTop: "10px",
                  }}
                >
                  Service
                  <span
                    style={{
                      color: "red",
                      fontSize: "20px",
                      marginLeft: "3px",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Chip
                  avatar={<Avatar alt="Natacha" src="/test (1).png" />}
                  label="Test Preparation"
                  variant="outlined"
                  sx={{ marginTop: "10px", fontSize: "14px", fontWeight: 600 }}
                />
              </Grid>

              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Typography
                  sx={{ color: "#2E4069", fontSize: "19px", fontWeight: 600 }}
                >
                  Duration
                  <span
                    style={{
                      color: "red",
                      fontSize: "20px",
                      marginLeft: "3px",
                    }}
                  >
                    *
                  </span>
                </Typography>

                <Button
                  variant="outlined"
                  onClick={handleSubmitpastdata}
                  style={{
                    borderRadius: "25px",
                    color: "black",
                    margin: "5px",
                    border: isPastSelected
                      ? "2px solid green"
                      : "2px solid #888888",

                    fontWeight: 600,
                    color: isPastSelected ? "green" : "black",
                  }}
                >
                  Past Event
                </Button>

                {dates.map((date, index) => {
                  const formattedDate = format(date, "MMMM,yyyy");
                  const isSelected = formattedDate === selectedMonthYear;
                  console.log("selected", isSelected);
                  return (
                    <Button
                      key={index}
                      onClick={() => handleSubmitDurationdata(date)}
                      style={{
                        margin: "5px",
                        borderRadius: "25px",

                        border: isSelected
                          ? "2px solid green"
                          : "2px solid #888888",
                        color: isSelected ? "green" : "black",
                        fontWeight: 600,
                      }}
                    >
                      {format(date, "MMMM yyyy")}
                    </Button>
                  );
                })}
              </Grid>

              <Grid item xs={12} lg={6} md={6} sm={6}>
                <Typography sx={{ fontWeight: 600 }}>Event</Typography>

                <Autocomplete
                  size="small"
                  noOptionsText={
                    loading && allcampaignList.length === 0
                      ? noOptionsText
                      : "Please Select Above Filter First"
                  }
                  multiple
                  value={eventname}
                  onChange={handelEventChange}
                  options={allcampaignList}
                  getOptionLabel={(option) => option.campaign_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "15px" }, // Add borderRadius here

                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} md={12} lg={12} sm={12} textAlign="end">
                <Button
                  variant="contained"
                  sx={{ borderRadius: "25px" }}
                  onClick={handleSubmit}
                  startIcon={<SearchIcon />}
                >
                  Get User Wise Event Report{" "}
                </Button>
                <Button
                  variant="contained"
                  color="error"
                  sx={{ marginLeft: "10px", borderRadius: "25px" }}
                  onClick={handleReset}
                  startIcon={<ClearIcon />}
                >
                  Clear Report{" "}
                </Button>
              </Grid>
            </Grid>
          </MainCard>
        </Collapse>
      </Grid>

      <Grid item xs={12} md={12} sm={12} lg={12}>
        <MainCard>
          <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
            Event User Wise Registration Report
          </Typography>

          <div style={{ width: "100%", marginTop: "10px" }}>
            <style>
              {`
                .header-cell {
                    background-color: #ABF7B1; /* Set your desired background color */
                    border-right: 1px solid #010301;
                }
                `}
            </style>
          </div>
          <DataGrid
            rows={eventData}
            columns={columns}
            autoHeight
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            sx={{ marginTop: "20px" }}
            pageSizeOptions={[25, 50, 100]}
          />
        </MainCard>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
