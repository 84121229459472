import React from 'react'
import StudentDataGrid from '../../views/trainer/classes/StudentDataGrid'
import TrainerTab from './ClassTab';

function ClassInfo() {
   /* const { classId } = useParams();

    useEffect(() => {
        fetch(`${API_ENDPOINT}trainerdashboard/viewclass/${classId}`)
          .then((res) => res.json())
          .then((data) => {
            setRows(data);
            setIsLoading(false);
          })
          .catch((error) => {
            console.error('Error fetching classes:', error);
            setIsLoading(false);
          });
      }, [classId]); */
  return (
    <>
    <TrainerTab />
   {/*} <StudentDataGrid /> */}
    </>
  )
}

export default ClassInfo