import { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Button, CircularProgress, FormControl, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select, Typography } from '@mui/material';

// project imports
import UserDetails from '../EmployeeDetails';
import UserEdit from '../EmployeeEdit';
import EmployeeCard from '../../../components/ui-component/card/EmployeeCard';
import MainCard from '../../../components/ui-component/card/MainCard';
import { gridSpacing } from '../../../store/constant';

import SearchIcon from '@mui/icons-material/Search';

import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import CustomSnackbar from '../../../components/ui-component/snackbar/CustomSnackbar';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

const EmployeeCardPage = () => {
    const  userId  = localStorage.getItem('userId')
    const theme = useTheme();
    const [employees, setEmployees] = useState([])
    const [filteredEmployees, setFilteredEmployees] = useState([]);
    const [user, setUser] = useState({});
    const [data, setData] = useState({});
    const [searchQuery, setSearchQuery] = useState('');
    const [branches, setBranches] = useState([]);

    const [filterRole, setFilterRole] = useState('');
  const [filterBranch, setFilterBranch] = useState('');

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    
        const showSnackbar = (message, severity) => {
            setSnackbarMessage(message);
            setSnackbarSeverity(severity);
            setSnackbarOpen(true);
        };

        useEffect(() => {
            // Fetch menu items using the fetch function
            fetch(`${API_ENDPOINT}branch`)
              .then(response => response.json())
              .then(data => {
                setBranches(data);
                console.log(data)
              })
              .catch(error => {
                console.error('Error fetching branches:', error);
              });
          }, [])

    useEffect(() => {
        setIsLoading(true)
        // Fetch employee data
        fetch(`${API_ENDPOINT}admindashboard/getemployee`)
          .then((response) => response.json())
          .then((data) => {
            // Update the rows state with the fetched data
            console.log(data)
            setEmployees(data);
            setIsLoading(false)
          })
          .catch((error) => {
            console.error('Error fetching employee data:', error);
            setIsLoading(false)
          });
      }, []);

      
      const handleAddEmployee = (u) => {
        
    const isEditing = !!u.user_id;

    u.user_id = parseInt(userId);

    if (isEditing) {
      const { email, ...updatedUser } = u;
      u = updatedUser;
  }

  console.log(u)

    const apiUrl = isEditing
        ? `${API_ENDPOINT}route/updateemployee/${u.user_id}`
        : `${API_ENDPOINT}admindashboard/createemployee`;

    fetch(apiUrl, {
        method: isEditing ? 'PUT' : 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(u),
    })
        .then((response) => {
            if (response.status === 200) {
                showSnackbar(isEditing ? 'Employee updated successfully' : 'Employee created successfully', 'success');
                setUser({});
                /*setTimeout(() => {
                    window.location.reload();
                }, 3000);*/
            } else if (response.status === 409) {
                showSnackbar('Employee with Email Id Exists', 'error');
                setUser({});
            } else {
                console.log('Error:', response);
                showSnackbar(`Error ${isEditing ? 'updating' : 'creating'} employee`, 'error');
            }
        })
        .catch((error) => {
            console.error(`Error ${isEditing ? 'updating' : 'creating'} employee:`, error);
            showSnackbar(`Error ${isEditing ? 'updating' : 'creating'} employee`, 'error');
        });
};

      

      useEffect(() => {
        // Filter employees based on the search query, role, and branch
        setFilteredEmployees(
          employees.filter(
            (employee) =>
              (employee.first_name?.toLowerCase()?.includes(searchQuery.toLowerCase()) || false) &&
              (filterRole ? employee.user_role === filterRole : true) &&
              (filterBranch ? employee.branch_id?.branch_name === filterBranch : true)
          )
        );
      }, [searchQuery, employees, filterRole, filterBranch]);
    
      const handleRoleFilterChange = (event) => {
        setFilterRole(event.target.value);
      };
    
      const handleBranchFilterChange = (event) => {
        setFilterBranch(event.target.value);
      };
    

    // handle new user insert action
    const [userDetails, setUserDetails] = useState(false);
    const [userEdit, setUserEdit] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const handleOnAdd = () => {
        setUser({
            first_name: '',
            last_name: '',
            user_role: '',
            email: '',
            mobile:'',
            branch_id: null,
           // image: User1,
            //status: '',
            gender: '',
            dob: '',
            trainer_expertise: '',
            highest_qualification: '',
            work_experience: null
        });
        setUserDetails(false);
        setUserEdit(true);  
    };


    const handleUpdateUserStatus = (userId, newStatus) => {
      // Update the user's status in the local state
      setEmployees((prevUsers) =>
          prevUsers.map((user) =>
              user.id === userId ? { ...user, status: newStatus } : user
          )
      );
  };

    

    return (
        <>
        <MainCard title="Employee Cards">
            <Grid container spacing={gridSpacing}>
                <Grid
                    className="block"
                    item
                    xs
                    zeroMinWidth
                    sx={{ display: userDetails || userEdit ? { xs: 'none', md: 'flex' } : 'flex' }}
                >
                    <Grid container alignItems="center" spacing={gridSpacing}>
                        
                        <Grid item xs={12} lg={4} zeroMinWidth>
                            <OutlinedInput
                                id="input-search-card-style1"
                                placeholder="Search Employee By Name"
                                fullWidth
                                startAdornment={
                                    <InputAdornment position="start">
                                        <SearchIcon stroke={1.5} size="16px" />
                                    </InputAdornment>
                                }
                                value={searchQuery}
                                onChange={(e) => setSearchQuery(e.target.value)}
                            />
                        </Grid>

                        <Grid item xs={12} lg={6}>   
            <Grid container direction="row" spacing={2}>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <FormControl fullWidth>
                  <InputLabel>Filter by Role</InputLabel>
                  <Select value={filterRole} onChange={handleRoleFilterChange}>
                    <MenuItem value="">All Roles</MenuItem>
                    <MenuItem value="trainer">Trainer</MenuItem>
                    <MenuItem value="advisor">Advisor</MenuItem>
                    {/* Add more roles as needed */}
                  </Select>
                </FormControl>
              </Grid>
              <Grid item xs={12} md={6} lg={6} xl={6}>
                <FormControl fullWidth>
                  <InputLabel>Filter by Branch</InputLabel>
                  <Select value={filterBranch} onChange={handleBranchFilterChange}>
                    <MenuItem value="">All Branches</MenuItem>
                    {branches.map((branch) => (
                      <MenuItem key={branch.id} value={branch.branch_name}>
                        {branch.branch_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
          </Grid>

                        <Grid item xs={12} lg={2}>
                            <Button variant="contained" size="large" startIcon={<AddCircleOutlineOutlinedIcon />} onClick={handleOnAdd}>
                                Add
                            </Button>
                        </Grid>
                        
                        
                        {isLoading ? (
                            <Grid item xs={12} style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress color="primary" />
                          </Grid>
                        ) : (

                            filteredEmployees.length === 0 ? (
                                <Grid item xs={12} style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant='h5'>No employees available</Typography>
                                </Grid>
                            ) : (
                            <>
                                <Grid item xs={12}>
                                    <Grid container direction="row" spacing={gridSpacing}>
                                        {filteredEmployees.map((userRow, i) => (
                                            <Grid item xs={12} md={userEdit ? 12 : 6} lg={userEdit ? 6 : 4} xl={3} key={i}>
                                                <EmployeeCard
                                                    avatar={userRow.avatar}
                                                    first_name={userRow.first_name}
                                                    last_name={userRow.last_name}
                                                    role={userRow.user_role}
                                                    email={userRow.email}
                                                    contact={userRow.mobile ||  'Not Found'}
                                                    location={userRow.branch_id?.branch_name ||  'Not Found'}
                                                    onActive={() => {
                                                        setUser(userRow);
                                                        setUserDetails(true);
                                                        setUserEdit(false);
                                                    }}
                                                />
                                            </Grid>
                                        ))}
                                    </Grid>
                                </Grid>
                                </>  
                            )
                        )} 
                       
                       </Grid>
                </Grid>
                


                {userDetails && (
                    <Grid item sx={{ width: 342, margin: { xs: '0 auto', md: 'initial' } }}>
                        <UserDetails
                            user={user}
                            onEditClick={() => {
                                setUserDetails(false);
                                setUserEdit(true);
                            }}
                            onChangeStatusClick={() => console.log('Change Status Clicked')}
                            onUpdateUserStatus={handleUpdateUserStatus}
                            onClose={() => {
                                setUserDetails(false);
                                setUserEdit(false);
                            }}
                        />
                    </Grid>
                )}

                {userEdit && (
                    <Grid item sx={{ width: 342, margin: { xs: '0 auto', md: 'initial' } }}>
                        <UserEdit
                            user={user}
                            onSave={(u) => {
                                if (u.id) setUserDetails(true);
                                setUserEdit(false);
                               // modifyUser(u);
                               handleAddEmployee(u)
                            }}
                            onCancel={(u) => {
                                if (u.id) setUserDetails(true);
                                setUserEdit(false);
                            }}
                        />
                    </Grid>
                )}
           

                <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                severity={snackbarSeverity}
                />
            </Grid>
        </MainCard>
        </>
    );
};

export default EmployeeCardPage;