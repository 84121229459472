import React, { useEffect, useState } from "react";

// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Box,
  Button,
  Grid,
  IconButton,
  InputAdornment,
  Menu,
  MenuItem,
  OutlinedInput,
  Pagination,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
  Tooltip,
  Typography,
} from "@mui/material";

// project imports

import { gridSpacing } from "../../../store/constant";

// assets
import SearchIcon from "@mui/icons-material/Search";
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded";
import MainCard from "../../../components/ui-component/card/MainCard";
import StudentListCard from "./StudentListCard";

import FilterAltIcon from "@mui/icons-material/FilterAlt";

import SideBarFilter from "../../../../pages/adminPages/classes/SideBarFilter";

import { CSVExport } from "../../../components/ui-component/table/CSVExport";

import ViewListIcon from "@mui/icons-material/ViewList";
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import ViewQuiltIcon from "@mui/icons-material/ViewQuilt";
import StudentTable from "./StudentTable";
import StudentFilter from "./studentDetails/StudentFilter";
import { string } from "prop-types";
import { useGetTokenOrLogout } from "../../../../utils/token";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../../components/ui-component/snackbar/CustomSnackbar";

// ==============================|| USER LIST STYLE 2 ||============================== //

const StudentsListPage = () => {
  const getTokenOrLogout = useGetTokenOrLogout();

  const navigate = useNavigate();
  const theme = useTheme();
  const userId = localStorage.getItem("userId");
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [anchorEl, setAnchorEl] = React.useState(null);

  const [name, setName] = useState([]);
  const [enrollmentNo, setEnrollmentNo] = useState("");
  const [branch, setBranch] = useState([]);
  const [enrollmentDateFrom, setEnrollmentDateFrom] = useState("");
  const [enrollmentDateTo, setEnrollmentDateTo] = useState("");
  const [branchofEnquiry, setBranchOfEnquiry] = useState([]);

  const [statusname, setstatusname] = useState([]);
  const [examoption, setexamoption] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedRowsPerPage, setSelectedRowsPerPage] = useState(6);
  const [filterddata, setFilteredData] = useState([]);
  const [students, setStudents] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [OriginalStudentData, setOriginalStudentData] = useState([]);
  const [searchvalue, setSearchValue] = useState("");

  const itemsPerPage = 5;
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleRowsPerPageChange = (value) => {
    setSelectedRowsPerPage(value);
    handleClose();
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handlePageChange = (event, value) => {
    setCurrentPage(value);
  };
  const totalPages = Math.ceil(students.length / selectedRowsPerPage);

  // Toggle betweeen table and list view

  const [view, setView] = useState("list"); // Default view is 'list'

  const handleListClick = (views) => {
    setView(views);
    if (views !== view) {
      setStudents([]);
      setOriginalStudentData([]);
      setName("");
      setEnrollmentNo("");
      setEnrollmentDateFrom("");
      setEnrollmentDateTo("");
      setstatusname([]);
      setBranch([]);
      setexamoption([]);
      setSearchValue("");
    }
  };

  useEffect(() => {
    setIsLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { Authorization: `Bearer ${token}` };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route/newstudentinfo/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();

          const sortedStudents = data.sort(
            (a, b) => new Date(b.joining_date) - new Date(a.joining_date)
          );

          setStudents(sortedStudents);
          setOriginalStudentData(sortedStudents);
          setFilteredData(sortedStudents);
          setIsLoading(false);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        }
        else {
          navigate("/page500");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId, view]);

  const startIndex = (currentPage - 1) * selectedRowsPerPage;
  const endIndex = startIndex + selectedRowsPerPage;

  // Slice the array to get the students for the current page
  const studentsForCurrentPage = students.slice(startIndex, endIndex);

  const handlenamefilter = (e) => {
    const value = e.target.value;
    setSearchValue(e.target.value);
    let filteredData1 = filterddata.filter((data) =>
      `${data?.enquiry_id?.first_name} ${data?.enquiry_id?.last_name}`
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(value.toLowerCase().replace(/\s+/g, ""))
    );

    setStudents(filteredData1);
  };
  return (
    <>
      <MainCard
        title={
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            spacing={gridSpacing}
          >
            <Grid item>
              <Typography variant="h3">Students List</Typography>
            </Grid>
            <Grid item>
              <ToggleButtonGroup value={view} exclusive>
                <Tooltip title="Card View">
                  <ToggleButton
                    aria-label="list"
                    onClick={() => handleListClick("list")}
                    sx={{
                      bgcolor:
                        view === "list"
                          ? theme.palette.secondary.main
                          : "transparent",
                      "&:hover": {
                        bgcolor:
                          view === "list"
                            ? theme.palette.secondary.dark
                            : theme.palette.action.hover,
                      },
                    }}
                  >
                    <ViewQuiltIcon />
                  </ToggleButton>
                </Tooltip>
                <Tooltip title="List View">
                  <ToggleButton
                    aria-label="quilt"
                    onClick={() => handleListClick("quilt")}
                    sx={{
                      bgcolor:
                        view === "quilt"
                          ? theme.palette.secondary.main
                          : "transparent",
                      "&:hover": {
                        bgcolor:
                          view === "quilt"
                            ? theme.palette.secondary.dark
                            : theme.palette.action.hover,
                      },
                    }}
                  >
                    <ViewListIcon />
                  </ToggleButton>
                </Tooltip>
              </ToggleButtonGroup>

              {/* <StudentListCard/> */}
            </Grid>
            <Grid item>
              <Box
                textAlign="end"
                display="flex"
                alignItems="center"
                justifyContent="end"
              >
                <TextField
                  placeholder="search by student name..."
                  size="small"
                  value={searchvalue}
                  onChange={handlenamefilter}
                  InputProps={{ style: { borderRadius: "14px" } }}
                />

                <IconButton
                  size="small"
                  color="inherit"
                  onClick={() => setIsOpen(true)}
                >
                  <FilterAltIcon size="large" /> Filter
                </IconButton>
              </Box>
            </Grid>
          </Grid>
        }
      >
        <StudentFilter
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          name={name}
          setName={setName}
          enrollmentNo={enrollmentNo}
          setEnrollmentNo={setEnrollmentNo}
          enrollmentDateFrom={enrollmentDateFrom}
          setEnrollmentDateFrom={setEnrollmentDateFrom}
          enrollmentDateTo={enrollmentDateTo}
          setEnrollmentDateTo={setEnrollmentDateTo}
          statusname={statusname}
          setstatusname={setstatusname}
          branch={branch}
          setBranch={setBranch}
          examoption={examoption}
          setexamoption={setexamoption}
          filterddata={filterddata}
          setFilteredData={setFilteredData}
          branchofEnquiry={branchofEnquiry}
          setBranchOfEnquiry={setBranchOfEnquiry}
          setOriginalStudentData={setOriginalStudentData}
          OriginalStudentData={OriginalStudentData}
          setStudents={setStudents}
          setIsLoading={setIsLoading}
        />

        {view === "list" ? (
          <StudentListCard
            studentsForCurrentPage={studentsForCurrentPage}
            itemsPerPage={itemsPerPage}
            selectedRowsPerPage={selectedRowsPerPage}
            currentPage={currentPage}
            students={students}
            setStudents={setStudents}
            isLoading={isLoading}
          />
        ) : (
          <StudentTable
            studentsForCurrentPage={studentsForCurrentPage}
            itemsPerPage={itemsPerPage}
            selectedRowsPerPage={selectedRowsPerPage}
            currentPage={currentPage}
            students={students}
            setStudents={setStudents}
            isLoading={isLoading}
          />
        )}
        <Grid item xs={12} sx={{ mt: 1.75 }}>
          <Grid container justifyContent="space-between" spacing={gridSpacing}>
            <Grid item>
              <Pagination
                count={totalPages}
                color="secondary"
                page={currentPage}
                onChange={handlePageChange}
              />
            </Grid>
            <Grid item>
              <Button
                variant="text"
                size="large"
                sx={{ color: theme.palette.grey[900] }}
                color="secondary"
                endIcon={<ExpandMoreRoundedIcon />}
                onClick={handleClick}
              >
                {selectedRowsPerPage} Rows
              </Button>
              <Menu
                id="menu-user-list-style2"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
                variant="selectedMenu"
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
                transformOrigin={{
                  vertical: "bottom",
                  horizontal: "right",
                }}
              >
                <MenuItem onClick={() => handleRowsPerPageChange(6)}>
                  {" "}
                  6 Rows
                </MenuItem>
                <MenuItem onClick={() => handleRowsPerPageChange(12)}>
                  {" "}
                  12 Rows
                </MenuItem>
                <MenuItem onClick={() => handleRowsPerPageChange(20)}>
                  {" "}
                  20 Rows{" "}
                </MenuItem>
              </Menu>
            </Grid>
          </Grid>
        </Grid>
      </MainCard>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
};

export default StudentsListPage;
