import React, { useEffect, useState } from 'react'
import MainCard from '../../components/ui-component/card/MainCard'
import { Card, CircularProgress, Grid, InputAdornment, OutlinedInput, Typography, useTheme } from '@mui/material'
import ButtonComponent from '../../../components/button/ButtonComponent'
import AddCircleOutlineOutlinedIcon from '@mui/icons-material/AddCircleOutlineOutlined';
import SearchIcon from '@mui/icons-material/Search';
import { gridSpacing } from '../../store/constant';
import FollowerCard from '../../components/ui-component/card/TestTypeCard';
import SubCard from '../../components/ui-component/card/SubCard';
import AddTestTypeDialog from './AddTestType';
import ConfirmationDialog from '../../components/ui-component/card/ConfirmationDialog';
import CustomSnackbar from '../../components/ui-component/snackbar/CustomSnackbar';
import TestTypeCard from '../../components/ui-component/card/TestTypeCard';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function TestType({title}) {
    const theme = useTheme()
    //const testtypes = ['IELTS', 'PTE']
    const userId = localStorage.getItem('userId')

    const [testTypes, setTestTypes] = useState([])
    const [isLoading, setIsLoading] = useState(false)
    const [searchInput, setSearchInput] = useState('');
    const [isAddDialogOpen, setAddDialogOpen] = useState(false);
    const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
    const [selectedTestType, setSelectedTestType] = useState(null);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

    async function updateTestTypeStatus(testTypeId, newStatus) {
        console.log(newStatus)
        try {
          const response = await fetch(`${API_ENDPOINT}testtype/${testTypeId}`, {
            method: 'PUT',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ is_active: newStatus, user_id: userId }),
          });
          console.log(response)

          if (response.ok) {
            // Status update was successful
           //alert('Status Updated')
           console.log(response)
           showSnackbar('Test type status updated successfully', 'success');

          } else {
            // Handle errors, e.g., show a notification or log the error
            console.error('Failed to update test type status:', response.statusText);
            showSnackbar('Error updating status', 'error');
            
          }
        } catch (error) {
          console.error('Error updating test type status:', error);
          showSnackbar('Error updating status', 'error');
        }
      }
      
    const handleAddTestType = (name) => {
        const newName = name.toUpperCase()
        if (!name) {
            return;
          }
          fetch(`${API_ENDPOINT}testtype`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({ name: newName, user_id: userId }),
          })
          .then((response) => {
            if(response.status === 201) {
                showSnackbar('Test Type created successfully', 'success');
                window.location.reload()
            } else {
              console.log('Error:', response)
              showSnackbar('Error creating Test Type', 'error');
            }
          })
            .catch((error) => {
              console.error('Error adding test type:', error);
              showSnackbar('Error creating Test Type', 'error');
            });
        setAddDialogOpen(false);
      };

      const handleConfirmAction = () => {
         updateTestTypeStatus(selectedTestType.id, selectedTestType.is_active === 0 ? 1 : 0)
        setConfirmDialogOpen(false);
      };
    

useEffect(() => {
    // Fetch menu items using the fetch function
    setIsLoading(true)
    fetch(`${API_ENDPOINT}route/getalltesttype`)
      .then(response => response.json())
      .then(data => {
        setTestTypes(data);
        setIsLoading(false);
        console.log(data)
      })
      .catch(error => {
        console.error('Error fetching testtypes:', error);
        setIsLoading(false);
      });
  }, []);
  return (
    <MainCard title="Test Type">
    <Grid container spacing={gridSpacing}>
            <Grid container alignItems="center" spacing={gridSpacing}>
                <Grid item xs zeroMinWidth>
                    <OutlinedInput
                        id="input-search-card-style1"
                        placeholder="Search Test Type"
                        fullWidth
                        startAdornment={
                            <InputAdornment position="start">
                                <SearchIcon stroke={1.5} size="16px" />
                            </InputAdornment>
                        }
                        value={searchInput}
                        onChange={(e) => setSearchInput(e.target.value)}
                    />
                </Grid>
                <Grid item>
                    <ButtonComponent variant="contained" size="large" startIcon={<AddCircleOutlineOutlinedIcon />} title='Add' path={() => setAddDialogOpen(true)}>
                    </ButtonComponent>
                </Grid>
                </Grid>
            
                {isLoading ? (
                             <Grid item xs={12} style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                             <CircularProgress color="primary" />
                           </Grid>
                        ) : (
                           
                            testTypes.length === 0 ? (
                                <Grid item xs={12} style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <Typography variant='h4'>No test types available</Typography>
                              </Grid>
                                

                            ) : (
                                <>
                                {testTypes
                                    .filter((testtype) =>
                                        testtype.name.toLowerCase().includes(searchInput.toLowerCase())
                                    ).map((testtype) => (
                                <Grid item xs={6} md={4} lg={3}>
                                        <SubCard >
                                            <TestTypeCard 
                                                {...testtype}
                                                onActivate={() => {
                                                    setSelectedTestType(testtype);
                                                    setConfirmDialogOpen(true);
                                                  }}
                                                  onDeactivate={() => {
                                                    setSelectedTestType(testtype);
                                                    setConfirmDialogOpen(true);
                                                  }}
                                                 />
                                        </SubCard>
                                    </Grid>
                                     ))}
                                <ConfirmationDialog
                                open={isConfirmDialogOpen}
                                onClose={() => setConfirmDialogOpen(false)}
                                onConfirm={handleConfirmAction}
                                message={`Are you sure you want to make ${
                                selectedTestType?.is_active === 0 ? 'active' : 'inactive'
                                }?`}
                                />

                                </>
                            )
                        )}
            </Grid>

            <AddTestTypeDialog
                    open={isAddDialogOpen}
                    onClose={() => setAddDialogOpen(false)}
                    onAdd={handleAddTestType}
                />

            <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
        
        </MainCard>
  )
}

export default TestType