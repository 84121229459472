import React, { useEffect, useState } from 'react'
//import { useSelector } from 'react-redux'
import { Box, CircularProgress, Grid } from '@mui/material'
import AdvisorProfileCard from '../../views/advisor/dashboard/AdvisorProfileCard'
import AdvisorWidget from '../../views/advisor/dashboard/AdvisorWidget'
import AdvisorWidget2 from '../../views/advisor/dashboard/AdvisorWidget2'
import AdvisorCalendarDashboard from '../../views/advisor/dashboard/AdvisorCalendarDashboard'
import AdvisorNotifications from '../../views/advisor/dashboard/AdvisorNotifications'
import AdvisorReminderTasks from '../../views/advisor/dashboard/AdvisorReminderTasks'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function TrainerDashboard() {
  const trainerId = localStorage.getItem('trainerId');
  const [ isLoading, setIsLoading] = useState(false)
  const [ trainerData, setTrainerData] = useState([])
  const [ trainerNotification, setTrainerNotification] = useState([])
  const [ trainerReminders, setTrainerReminders] = useState([])
  const [ totalCall, setTotalCall] = useState(0)
  const [ totalTask, setTotalTask] = useState(0)
  const [ totalNewEnquiry, setTotalNewEnquiry] = useState(0)


  const fetchTrainerNotifications = async (trainerId) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_ENDPOINT}trainerdashboard/trainernotification/${trainerId}`);
      const data = await response.json();
      setTrainerNotification(data)
        console.log(data);
        setIsLoading(false);
    } catch (error) {
      console.log('Error:', error);
      setIsLoading(false);
    }
  }
  
  const fetchTrainerReminders = async (trainerId) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_ENDPOINT}trainerdashboard/trainertask/${trainerId}`);
      const data = await response.json();
      setTrainerReminders(data)
        console.log(data);
        setIsLoading(false);
    } catch (error) {
      console.log('Error:', error);
      setIsLoading(false);
    }
  }

  const fetchAllValues = async (trainerId) => {
    setIsLoading(true);
    try {
      const response = await fetch(`${API_ENDPOINT}trainerdashboard/getallval/${trainerId}`);
      const data = await response.json();

      // Extract and set the total_call value from the response
      const { total_call, total_task, total_new_enquiry } = data;
      console.log(data)
      setTotalCall(total_call);
      setTotalTask(total_task);
      setTotalNewEnquiry(total_new_enquiry)
      setIsLoading(false);
    } catch (error) {
      console.error('Error:', error);
      setIsLoading(false);
    }
  };
  
    useEffect(() => {
      //fetchTrainerNotifications(trainerId)
     // fetchTrainerReminders(trainerId)
      fetchAllValues(trainerId)
      
    }, [trainerId]); 

  return (
    <>
       
       { isLoading ? (
         <Box display="flex" justifyContent="center" alignItems="center" height="300px">
         <CircularProgress color="primary" />
       </Box>
      ) : (
    
      <Grid container spacing={6}>
        <Grid item={12} md={8}>
          <Grid container spacing={4}>
        <Grid item xs={12} md={6}>
        <AdvisorProfileCard />
      </Grid>
      <Grid item xs={12} md={6}>
      <Grid container spacing={2}>
        <Grid item xs={6}>
      <AdvisorWidget 
        bgColor='rgba(219, 246, 255, 1)'
        color='rgba(0, 193, 236, 1)'
        number={0}
        text='Completed Demos'
      />
      </Grid>

      <Grid item xs={6}>
      <AdvisorWidget
        bgColor='rgba(255, 248, 233, 1)'
        color='rgba(255, 176, 0, 1)'
        number={0}
        text='Assigned Demos'
      />
      </Grid>

      {/*<Grid item xs={6}>
      <AdvisorWidget 
        bgColor='rgba(255, 243, 235, 1)'
        color='rgba(255, 122, 0, 1)'
        number={0}
        text='Scheduled Demos'
      />
      </Grid> */}

      <Grid item xs={12}>
      <AdvisorWidget 
        bgColor='rgba(239, 243, 255, 1)'
        color='rgba(23, 81, 255, 1)'
        number={0}
        text='Tasks Assigned'
      />
      </Grid>
      </Grid>
      </Grid>

      {/*<Grid container spacing={3}> */}
      <Grid item xs={12} md={6}>
          <AdvisorWidget2 
          bgColor='rgba(239, 243, 255, 1)'
          color='rgba(23, 81, 255, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Classes.png'
          title='Demos'
          body='See all demos'
          path='/all-demos'
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <AdvisorWidget2 
          bgColor='rgba(255, 248, 233, 1)'
          color='rgba(255, 176, 0, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Students.png'
          title='Students'
          body='See my students'
          path='/view-students'
          />
        </Grid> 

        <Grid item xs={12} md={6}>
          <AdvisorWidget2 
          bgColor='rgba(219, 246, 255, 1)'
          color='rgba(0, 193, 236, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Demos.png'
          title='Attendance'
          body='Take attendance'
          path='/take-attendance'
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <AdvisorWidget2 
          bgColor='rgba(239, 243, 255, 1)'
          color='rgba(23, 81, 255, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Daily-report.png'
          title='Enquiries'
          body='See my enquiries'
          path='/enquiries'
          />
        </Grid>

       {/* */}

        {/*</Grid>*/}

        </Grid>

      </Grid>

      <Grid item xs={12} md={4}>
       <AdvisorCalendarDashboard /> 
      </Grid>

    
        <Grid item xs={12} md={4}>
          <AdvisorNotifications list={trainerNotification}/>
        </Grid>

        <Grid item xs={12} md={4}>
          <AdvisorReminderTasks list={trainerReminders} />
        </Grid>

        <Grid item xs={12} md={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={12}>
          <AdvisorWidget2 
          bgColor='rgba(239, 243, 255, 1)'
          color='rgba(23, 81, 255, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Rating box.png'
          title='My Rating Box'
          
          />
        </Grid>

        <Grid item xs={6}>
        <AdvisorWidget2 
          bgColor='rgba(226, 255, 240, 1)'
          color='(26, 198, 106, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Daily-report.png'
          title='Daily Report'
          imageStyle={{ height: '50px', width: '50px', objectFit: `cover` }} 
          />
        </Grid>

        <Grid item xs={6}>
        <AdvisorWidget2 
          bgColor='rgba(255, 231, 227, 1)'
          color='rgba(249, 95, 75, 1)'
          image='/wetransfer_add-enquiry-svg_2023-07-25_1047/Monthly-report.png'
          title='Monthly Report'
          imageStyle={{ height: '70px', width: '70px', objectFit: `cover` }} 
          />
        </Grid>

        </Grid>
        </Grid>

      </Grid>
      )}
       </>
  )
}

export default TrainerDashboard