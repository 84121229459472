import { Box, Grid, Stack, Typography, styled } from '@mui/material'
import React from 'react'
import MainCard from '../../components/Maincard'

export default function ActiveStudentCard({data}) {

    const StyledGlobalBox = styled(Box)`
   
    border-radius:50%;
    width: 44px;
    height: 44px;
    display: flex;
    align-items: center;
    justify-content: center;
  
    img {
      max-width: 100%;
      max-height: 100%;
    }
  `;
  return (
 
    <>

<Grid item xs={9} lg={4} md={6} sm={6} >
    <MainCard style={{ background: data.background }}>
       <Stack spacing={5}>
      <Stack display='flex' flexDirection='row' justifyContent='space-between' alignItems='center'>
        <StyledGlobalBox style={{ background: data.iconBackground, marginRight: '5px' }}>
          <img src={data.iconSrc} />
        </StyledGlobalBox>
        <Typography sx={{ color: '#151D48', fontFamily: 'Poppins', fontSize: '44px', fontStyle: 'normal', fontWeight: 600 }}>
          {data.value}
        </Typography>
      </Stack>
      <Stack>
      <Typography>{data.label}</Typography>
      <img src={data.lineSrc} width='100px' />
      </Stack>
      </Stack>
    </MainCard>
  </Grid>

    </>
  )
}
