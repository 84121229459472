import { Box, Button, IconButton, Modal, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField, Typography, styled, useTheme } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { tableCellClasses } from '@mui/material/TableCell';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CloseIcon from '@mui/icons-material/Close';

const StyledTableCell = styled(TableCell)(({ theme }) => ({
    [`&.${tableCellClasses.head}`]: {
        backgroundColor: theme.palette.secondary.main,
        color: theme.palette.common.white,
        fontSize: 12,
        borderRight: `1px solid ${theme.palette.common.white}`,
    },
    [`&.${tableCellClasses.body}`]: {
        fontSize: 12,
        borderBottom: `1px solid ${theme.palette.divider}`
    }
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
        backgroundColor: theme.palette.action.hover
    },
    // hide last border
    '&:last-of-type td, &:last-of-type th': {
        border: 0
    }
}));


function WhatsappMessages({enquiryData}) {
    console.log(enquiryData)
    
    const theme = useTheme()
    const [open, setOpen] = useState(false);
    const [messageBody, setMessageBody] = useState('');
    const [error, setError] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');

    // Function to handle button click
    const handleWhatsAppClick = () => {
        if (!phoneNumber) {
            return;
        }
        const whatsappURL = `https://api.whatsapp.com/send?phone=${encodeURIComponent(phoneNumber)}&text=hi`;
        window.open(whatsappURL, '_blank');
    };

    const handleOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
        setError('');
        setMessageBody('');
    };

    const sendWhatsAppMessage = () => {
       
        if (!messageBody.trim()) {
            setError('Message body cannot be empty');
            return;
        }
        // Implement sending WhatsApp message here
        handleClose();
    };

    useEffect(() => {
       
        if (enquiryData && enquiryData.whatsapp_country_code && enquiryData.whatsapp_number) {
            setPhoneNumber(`${enquiryData.whatsapp_country_code}${enquiryData.whatsapp_number}`);
        }
    }, [enquiryData]);
    
    
  return (
    <>
        <div style={{ display: 'flex', justifyContent: 'flex-end', marginBottom: '5px' }}>
        <Button
            startIcon={<WhatsAppIcon />}
            variant="contained"
            color="secondary"
            onClick={handleWhatsAppClick}
            disabled={enquiryData.is_active===0}
        >
            Send WhatsApp
        </Button>
    </div>
        <TableContainer>
                <Table aria-label="customized table">
                    <TableHead>
                        <TableRow>
                        <StyledTableCell align='center'>Sent By</StyledTableCell>
                            <StyledTableCell align='center'>Message Body</StyledTableCell>
                            <StyledTableCell align='center'>Sent Date</StyledTableCell>
                            <StyledTableCell align='center'>Source</StyledTableCell>
                            
                        </TableRow>
                    </TableHead>
                    <TableBody>
                            <StyledTableRow hover>
                                <StyledTableCell align='center'>
                                    
                                </StyledTableCell>
                                <StyledTableCell align='center'>
                                <Typography>
                                   
                                    </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                <Typography>
                                   
                                    </Typography>
                                    </StyledTableCell>
                                    <StyledTableCell align='center'>
                                <Typography>
                                    </Typography>
                                    </StyledTableCell>
                                
                                
                            </StyledTableRow>
                       
                    </TableBody>
                </Table>
              
            </TableContainer>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)', width: 400, bgcolor: 'background.paper', boxShadow: 24, p: 4 }}>
                    <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', mb: 1 }}>
                        <h2 id="modal-modal-title">Send WhatsApp Message</h2>
                        <IconButton onClick={handleClose} aria-label="close">
                            <CloseIcon />
                        </IconButton>
                    </Box>
                    <TextField
                        label="Message Body"
                        multiline
                        rows={4}
                        fullWidth
                        variant="outlined"
                        margin="normal"
                        value={messageBody}
                        onChange={(e) => setMessageBody(e.target.value)}
                        error={!!error}
                        helperText={error}
                    />
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={sendWhatsAppMessage}
                    >
                        Send
                    </Button>
                </Box>
            </Modal>

    </>
  )
}

export default WhatsappMessages