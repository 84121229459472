import React, { createContext, useState } from 'react';

const EnquiryPopupContext = createContext();

const EnquiryPopupProvider = ({ children }) => {
  const [isEnquiryPopupOpen, setIsEnquiryPopupOpen] = useState(false);

  const handleOpenEnquiryPopup = () => {
    setIsEnquiryPopupOpen(true);
  };

  const handleCloseEnquiryPopup = () => {
    setIsEnquiryPopupOpen(false);
  };

  return (
    <EnquiryPopupContext.Provider
      value={{
        isEnquiryPopupOpen,
        handleOpenEnquiryPopup,
        handleCloseEnquiryPopup,
      }}
    >
      {children}
    </EnquiryPopupContext.Provider>
  );
};

export { EnquiryPopupContext, EnquiryPopupProvider };
