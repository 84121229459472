import { Box, Button, Chip, CircularProgress, Grid, Menu, MenuItem, Stack, TextField, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import MainCard from '../../../components/ui-component/card/MainCard';
import AdminRevenueCard from '../../../../pages/AdminRevenue/AdminRevenueCard';
import BranchBubbleChart from '../../../../pages/BranchPerformance/BranchBubbleChart';
import PerformanceCards from '../../../../pages/BranchPerformance/PerformanceCards';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useGetTokenOrLogout } from '../../../../utils/token';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function AdminNewDashboard() {
  const navigate=useNavigate()
  const getTokenOrLogout = useGetTokenOrLogout();
        const { id } = useParams();
        const location = useLocation();
        


        const branchName = location.state?.branchName || null;
        const userId = localStorage.getItem('userId');
        const token = localStorage.getItem("token");
        const [branchId, setBranchId] = useState(id || null)
        const userRole = localStorage.getItem('userRole');
        const [dailyData, setDailyData] = useState({})
        const [monthlyData, setMonthlyData] = useState({})
        const [branchData, setBranchData] = useState({})
        const [profileData, setProfileData] = useState({})
        const [isLoading, setIsLoading] = useState(false)
        const [isLoading2, setIsLoading2] = useState(false)
      
        const [filterType, setFilterType] = useState('today');
        const [fromDate, setFromDate] = useState(null);
        const [toDate, setToDate] = useState(null);

        const [fromDate2, setFromDate2] = useState(null);
        const [toDate2, setToDate2] = useState(null);

        const [anchorEl2, setAnchorEl2] = useState(null);
      const [selectedOption2, setSelectedOption2] = useState('today');
      const [selectedOptionLabel2, setSelectedOptionLabel2] = useState('Today');
      
        const capitalizeFirstLetter = (str) => {
          return str?.charAt(0).toUpperCase() + str?.slice(1);
      };
      
      
      const [anchorEl, setAnchorEl] = useState(null);
      const [selectedOption, setSelectedOption] = useState('today');
      const [selectedOptionLabel, setSelectedOptionLabel] = useState('Today');
      const [isCenterLoading, setIsCenterLoading]= useState(false)
      const [centerRevenueData, setCenterRevenueData] = useState([])
      
    
        const handleClick = (event) => {
            setAnchorEl(event.currentTarget);
        };
    
        const handleClose = () => {
            setAnchorEl(null);
        };
    
        const handleSelectOption = (option, label) => {
          setSelectedOption(option);
          setSelectedOptionLabel(label);
          setAnchorEl(null); // Close the menu after selecting an option
          setFromDate('')
          setToDate('')
      };
      
      const handleClick2 = (event) => {
        setAnchorEl2(event.currentTarget);
    };

    const handleClose2 = () => {
        setAnchorEl2(null);
    };

    const handleSelectOption2 = (option, label) => {
      setSelectedOption2(option);
      setSelectedOptionLabel2(label);
      setAnchorEl2(null); // Close the menu after selecting an option
      setFromDate2(null)
      setToDate2(null)
  };
            const handleFilterChange = (event) => {
              setFilterType(event.target.value);
            };
          
            const handleFromDateChange = (event) => {
              setFromDate(event.target.value);
            };
          
            const handleToDateChange = (event) => {
              setToDate(event.target.value);
            };
          
            const handleApplyFilter = () => {
              fetchAdminDailyData()
            };

            const handleApplyFilter2 = () => {
              fetchBranchData()
            };
      
            const fetchAdminDailyData = () => {
              setIsLoading(true)

              const requestData = {
                user_id: userId,
                branch_id: branchId,
                filter: selectedOption,
                to_date: toDate,
                from_date: fromDate

              }
               fetch(`${API_ENDPOINT}route/gettodayrevenue`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  "Authorization": `Bearer ${token}`
                },
                body: JSON.stringify(requestData)
               })
                   .then(response => response.json())
                   .then(data => {
                       setDailyData(data);
                       setIsLoading(false);
                   })
                   .catch(error => {
                       console.error('Error fetching daily data:', error);
                       setIsLoading(false);
                   });
           };
      
           const fetchAdminMonthlyData = () => {
            const requestData = {
              user_id: userId,
              branch_id: branchId,
             // filter: selectedOption,
              //to_date: toDate,
              //from_date: fromDate

            }
            setIsLoading(true)
             fetch(`${API_ENDPOINT}route/getenrollrevenue/`, {
              method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                  "Authorization": `Bearer ${token}`

                },
                body: JSON.stringify(requestData)
             })
                 .then(response => response.json())
                 .then(data => {
                     setMonthlyData(data);
                     setIsLoading(false);
                 })
                 .catch(error => {
                     console.error('Error fetching daily data:', error);
                     setIsLoading(false);
                 });
         };
      
         const fetchBranchData = () => {
          const requestData = {
            user_id: userId,
            branch_id: branchId,
            filter: selectedOption2,
            to_date: toDate2,
            from_date: fromDate2

          }
          setIsLoading2(true)
           fetch(`${API_ENDPOINT}route/getfulldatabranch/`, {
            method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                "Authorization": `Bearer ${token}`
              },
              body: JSON.stringify(requestData)
           })
               .then(response => response.json())
               .then(data => {
                   setBranchData(data);
                   setIsLoading2(false);
               })
               .catch(error => {
                   console.error('Error fetching branch data:', error);
                   setIsLoading2(false);
               });
       };
    
       const fetchProfileData = async() => {
           
const token = getTokenOrLogout();
if (!token) return;
let headers = { 
  Authorization: `Bearer ${token}`,
 
};


          
        try {
          const response = await fetch(`${API_ENDPOINT}employee/${userId}`,{headers});
          if (response.ok) {
            const data = await response.json();
            console.log("data", data);
  
            setProfileData(data);
          } 
          else if (response.status === 404) {
                 navigate("/page404");
               } else if (response.status === 401) {
                 navigate("/page401");
               } else if (response.status === 403) {
                 navigate("/page403");
               } else if (response.status === 440) {
                 navigate("/page440");
               } else {
                 navigate("/page500");
               }
        } catch (error) {
          navigate("/page500");
        }
      
    }
  

          const handleEdit = () => {
            const newState = { employeedetails: profileData };
            localStorage.setItem("employeeState", JSON.stringify(newState));
        
            const url = `/view-profile/${profileData?.id}`;
            const newTab = window.open(url, "_blank");
        
            if (!newTab) {
              navigate(url, { state: newState });
            }
          };
      
           useEffect(() => {
            fetchAdminDailyData()
            fetchAdminMonthlyData()
            fetchProfileData()
            fetchBranchData()
           }, [])

          /* useEffect(() => {
            fetchAdminDailyData()
           }, [selectedOption, toDate, fromDate])

           useEffect(() => {
            fetchBranchData()
           }, [selectedOption2, toDate2, fromDate2])*/
      
           const { first_name, last_name, role, branch} = profileData || {};
            
      
        return (
          <Box className="box" sx={{ height: '1086px', width: '100%', position: 'relative' , color:'' }}>
          {/* Gradient Background */}
          <Box
            className="rectangle"
            sx={{
              background: 'linear-gradient(248deg, #FFF6F0 -41.57%, rgba(255, 246, 240, 0.00) 97.88%)',
              height: '100%',
              position: 'absolute',
              top: 0,
              left: 0,
              width: '100%',
              zIndex: -1,
            }}
          />
      
          <Box
            style={{ textAlign: "left" }}
            sx={{
              position: 'absolute',
              // top: '40%',
              // left: '40%',
              // transform: 'translate(-50%, -50%)',
              textAlign: 'center',
              zIndex: 1,
            }}
          >
            
           
            <Grid container spacing={2}>
              <Grid item xs={12} sm={12} lg={5}>
              {userRole === 'admin' ? (
                  <MainCard>
                    <Typography sx={{ color:'#000', fontFamily:'Poppins', fontSize:'20px', fontStyle:'normal', fontWeight:800 }}>
                      Dashboard
                    </Typography>
                    <Typography variant='h4'>
                      {first_name ? capitalizeFirstLetter(first_name) : ''}{' '}
                      {last_name ? capitalizeFirstLetter(last_name) : ''}{' '}
                      <Chip label='Admin' color="primary" /> {' '}
                      Branch: { branch?.branch_name || ''}
                    </Typography>
                    <Grid item xs={12} textAlign='end'>
        <Button variant='contained'   onClick={handleEdit}>Edit My Profile</Button>
      </Grid>
                  </MainCard>
                  ) : userRole === 'center_admin' ? (
                    // Render something else for center admin
                    <div>
                      <MainCard>
                      <Typography sx={{ color:'#000', fontFamily:'Poppins', fontSize:'20px', fontStyle:'normal', fontWeight:800 }}>
                      In View: {branchName} Branch
                    </Typography>
                      </MainCard>
                    </div>
                  ) : 
                  <div>
                      <MainCard>
                      <Typography sx={{ color:'#000', fontFamily:'Poppins', fontSize:'20px', fontStyle:'normal', fontWeight:800 }}>
                      No Data Available
                    </Typography>
                      </MainCard>
                    </div>
                  }
              
              </Grid >
              <Grid item xs={12} sm={6} lg={3.5} md={6}>
      <MainCard sx={{background:'#fcdcb6' }}>
          <Stack display='flex' flexDirection='row' alignItems='center' >
      <img src='/newinroll.png'/>
          <Typography sx={{marginLeft:'10px' , fontFamily:'Poppins', fontSize:'18px', fontWeight:'600'}}>New Enrollment this month :  {isLoading ? <CircularProgress /> : monthlyData?.total_new_enrollment}</Typography>
          </Stack>
      </MainCard>
              </Grid>
              <Grid item xs={12} sm={6} lg={3.5} md={6}>
      <MainCard sx={{background:'#b6fcbd' }}>
          
      <Stack display='flex' flexDirection='row' alignItems='center' >
      <img src='/totalrevenue.png'/>
          <Typography sx={{marginLeft:'10px' , fontFamily:'Poppins', fontSize:'18px', fontWeight:'600'}}>Total Revenue this month: ₹  {isLoading ? <CircularProgress /> : monthlyData?.total_revenue} </Typography>
          </Stack>
      </MainCard>
              </Grid>


              <Grid item xs={12} lg={12} md={12} sm={12} container justifyContent='flex-end'> 
          <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                    variant="outlined"
                    style={{
                        color: "#252C58",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 400,
                        border: "1px solid #252C58",
                        borderRadius: "8px",
                    }}
                    onClick={handleClick}
                    startIcon={<CalendarMonthIcon />}
                >
                   {selectedOptionLabel}
                </Button>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    <MenuItem onClick={() => handleSelectOption('today', 'Today')}>Today</MenuItem>
                    <MenuItem onClick={() => handleSelectOption('1week', 'This Week')}>This Week</MenuItem>
                    <MenuItem onClick={() => handleSelectOption('1month', 'This Month')}>This Month</MenuItem>
                    <MenuItem onClick={() => handleSelectOption('custom', 'Custom')}>Custom</MenuItem>
                </Menu>
                {selectedOption === "custom" && (
                    <div style={{ marginLeft: "10px" }}>
                      <TextField
                        id="date-from"
                        label="From"
                        type="date"
                        defaultValue={fromDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ max: new Date().toISOString().split('T')[0] }}
                        onChange={(e) => setFromDate(e.target.value)}
                        sx={{ marginLeft: 2 }}
                      />
                      <TextField
                        id="date-to"
                        label="To"
                        type="date"
                        defaultValue={toDate}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ max: new Date().toISOString().split('T')[0] }}
                        onChange={(e) => setToDate(e.target.value)}
                        sx={{ marginLeft: 2 }}
                      />
                    </div>
                  )}
                   <Button
                    variant="contained"
                    color='secondary'
                    sx={{ marginLeft: 2, backgroundColor: '#019E6F' }}
                    style={{
                        color: "#fff",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 400,
                        border: "1px solid #252C58",
                        borderRadius: "8px",
                    }}
                    onClick={handleApplyFilter} 
                >
                   Apply Filter
                </Button>
            </div>
        </Grid>
              
            <Grid item xs={12} lg={12} sm={12} md={12}>
                <Grid container spacing={1}>
                <Grid item xs={12} lg={3} sm={6}>
                      <AdminRevenueCard
                          //primary="2"
                          background = '#fcdcb6'
                          iconBackground = '#FF6B47'
                          iconSrc = '/Revenue.svg'
                          value = {isLoading ? <CircularProgress /> : dailyData?.total_revenue}
                          label= 'Total Revenue'
                      />
                  </Grid>
                  <Grid item xs={12} lg={3} sm={6}>
                      <AdminRevenueCard
                          background = '#b6fcbd'
                          iconBackground = '#3CD856'
                          iconSrc = '/Expenses.svg'
                          value = {isLoading ? <CircularProgress /> : dailyData?.total_collected_revenue}
                          label= 'Total Collected Revenue'
                      />
                  </Grid>
                  <Grid item xs={12} lg={3} sm={6}>
                      <AdminRevenueCard
                          background = '#f7c8e5'
                          iconBackground = '#FA5A7D'
                          iconSrc = '/Profit.svg'
                          value = {isLoading ? <CircularProgress /> : dailyData?.total_pending_revenue}
                          label= 'Total Pending Revenue'
                      />
                  </Grid>
                  <Grid item xs={12} lg={3} sm={6}>
                      <AdminRevenueCard
                          background = '#a0b8ff'
                          iconBackground = '#5e43f7'
                          iconSrc = '/Active Student.svg'
                          value = {isLoading ? <CircularProgress /> : dailyData?.total_new_enrollment}
                          label= 'New Enrollment'
                      />
                  </Grid>
                  </Grid>
                  </Grid>

                  <Grid item xs={12} lg={12} md={12} sm={12} container justifyContent='flex-end'> 
          <div style={{ display: "flex", alignItems: "center" }}>
                <Button
                    variant="outlined"
                    style={{
                        color: "#252C58",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 400,
                        border: "1px solid #252C58",
                        borderRadius: "8px",
                    }}
                    onClick={handleClick2}
                    startIcon={<CalendarMonthIcon />}
                >
                   {selectedOptionLabel2}
                </Button>
                <Menu
                    anchorEl={anchorEl2}
                    open={Boolean(anchorEl2)}
                    onClose={handleClose2}
                >
                    <MenuItem onClick={() => handleSelectOption2('today', 'Today')}>Today</MenuItem>
                    <MenuItem onClick={() => handleSelectOption2('1week', 'This Week')}>This Week</MenuItem>
                    <MenuItem onClick={() => handleSelectOption2('1month', 'This Month')}>This Month</MenuItem>
                    <MenuItem onClick={() => handleSelectOption2('custom', 'Custom')}>Custom</MenuItem>
                </Menu>
                {selectedOption2 === "custom" && (
                    <div style={{ marginLeft: "10px" }}>
                      <TextField
                        id="date-from"
                        label="From"
                        type="date"
                        defaultValue={fromDate2}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ max: new Date().toISOString().split('T')[0] }}
                        onChange={(e) => setFromDate2(e.target.value)}
                        sx={{ marginLeft: 2 }}
                      />
                      <TextField
                        id="date-to"
                        label="To"
                        type="date"
                        defaultValue={toDate2}
                        InputLabelProps={{
                          shrink: true,
                        }}
                        inputProps={{ max: new Date().toISOString().split('T')[0] }}
                        onChange={(e) => setToDate2(e.target.value)}
                        sx={{ marginLeft: 2 }}
                      />
                    </div>
                  )}
                  <Button
                    variant="contained"
                    color='secondary'
                    sx={{ marginLeft: 2, backgroundColor: '#019E6F' }}
                    style={{
                        color: "#fff",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontWeight: 400,
                        border: "1px solid #252C58",
                        borderRadius: "8px",
                    }}
                    onClick={handleApplyFilter2} 
                >
                   Apply Filter
                </Button>
            </div>
        </Grid>

                  <Grid item xs={12} lg={4} sm={12} md={12}>
                      <BranchBubbleChart />
                    </Grid>

                    <Grid item xs={12} lg={8} sm={12} md={12}>
                      <Grid container spacing={2}>
                      <Grid item xs={12} lg={4} sm={6} md={4}>
                      <PerformanceCards
                        imgSrc='/branchdashboard/Ellipse 244.svg'
                        text='Lead to Demo Conversion'
                        count= {0}
                       />
                    </Grid>

                    <Grid item xs={12} lg={4} sm={6} md={4}>
                      <PerformanceCards
                        imgSrc='/branchdashboard/Ellipse 245.svg'
                        text='Demo to Enrollment Conversion'
                        count={0}
                       />
                    </Grid>

                    <Grid item xs={12} lg={4} sm={6} md={4}>
                      <PerformanceCards
                        imgSrc='/branchdashboard/Ellipse 246.svg'
                        text='Lead to Enrollment Conversion'
                        count={0}
                       />
                    </Grid>

                    <Grid item xs={12} lg={4} sm={6} md={4}>
                      <PerformanceCards
                         imgSrc='/branchdashboard/Group 1000002862.svg'
                         text='New Enquiries'
                         count={isLoading2 ? <CircularProgress /> : branchData?.new_enquiry}
                       />
                    </Grid>

                    <Grid item xs={12} lg={4} sm={6} md={4}>
                      <PerformanceCards
                         imgSrc='/branchdashboard/Layer_1.svg'
                         text='Demo Scheduled'
                         count={isLoading2 ? <CircularProgress /> : branchData?.demo_schedule}
                       />
                    </Grid>

                    <Grid item xs={12} lg={4} sm={6} md={4}>
                      <PerformanceCards
                         imgSrc='/branchdashboard/Layer_1 (1).svg'
                         text='Active Advisor'
                         count={isLoading2 ? <CircularProgress /> : branchData?.active_advisor}
                       />
                    </Grid>

                    <Grid item xs={12} lg={4} sm={6} md={4}>
                      <PerformanceCards
                          imgSrc='/branchdashboard/Group 1000002781.svg'
                          text='Active Student'
                          count={isLoading2 ? <CircularProgress /> : branchData?.active_student}
                       />
                    </Grid>

                    <Grid item xs={12} lg={4} sm={6} md={4}>
                      <PerformanceCards
                          imgSrc='/branchdashboard/Group 1000002863.svg'
                          text='Active Classes'
                          count={isLoading2 ? <CircularProgress /> : branchData?.active_class}
                         />
                    </Grid>

                    <Grid item xs={12} lg={4} sm={6} md={4}>
                      <PerformanceCards
                        imgSrc='/branchdashboard/Layer_1 (2).svg'
                        text='Active Trainers'
                        count={isLoading2 ? <CircularProgress /> : branchData?.active_trainer}
                       />
                    </Grid>

                    <Grid item xs={12} lg={4} sm={6} md={4}>
                      <PerformanceCards
                        imgSrc='/branchdashboard/Group 1000002864.svg'
                        text='Total room’s avaliable'
                        count={isLoading2 ? <CircularProgress /> : branchData?.room}
                       />
                    </Grid>
                      </Grid>
                      </Grid>
                    
  </Grid>
  </Box>
  </Box>
  )
}

export default AdminNewDashboard