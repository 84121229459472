import { Box, Chip, CircularProgress, Grid, IconButton, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Tooltip, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { fDate } from '../../../../../utils/formatTime';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function StudentPayments2({studentId}) {
    const [payments, setPayments] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    useEffect(() => {
        fetch(`${API_ENDPOINT}route/studentpayment/${studentId}`)
      .then((res) => res.json())
      .then((data) => {
        setPayments(data);
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching student payments:', error);
        setIsLoading(false)
      })
      }, []);
  return (
    <>
     <Typography variant='h4'>Paid Transactions</Typography>

            {isLoading ? (
            <Box display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh">
            <CircularProgress />
            </Box>
            ) : (
      payments.length === 0 ? (
        <Grid
          item
          xs={12}
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Typography variant="h5">No payment history available</Typography>
        </Grid>
      ) : (
        <>
          <TableContainer
            component={Paper}
            sx={{ borderRadius: "15px", padding: "10px" }}
          >
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Class Details
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Package
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Discount
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Pending Amount
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Date
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Payment Mode
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Payment Status
                  </TableCell>
                  <TableCell
                    sx={{
                      color: "#000",
                      fontFamily: "Poppins",
                      fontSize: "18px",
                      fontStyle: "normal",
                      fontWeight: 600,
                    }}
                  >
                    Action
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {payments.map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f0f0f0" : "inherit",
                      width: "6%",
                    }}
                  >
                    <TableCell>{row?.class_id?.name}</TableCell>
                    <TableCell>{row?.package_id?.package_name}</TableCell>
                    <TableCell>{row?.amount_paid}</TableCell>
                    <TableCell>
                      {row?.discount_amount !== null ? row.discount_amount : 0}
                    </TableCell>
                    <TableCell>
                      {row?.pending_amount !== null ? row.pending_amount : 0}
                    </TableCell>
                    <TableCell>{fDate(row?.payment_date)}</TableCell>
                    <TableCell>{row?.payment_mode_id?.payment_mode}</TableCell>
                    <TableCell>
                      <Chip
                        label={row?.payment_status}
                        color={
                          row?.payment_status === "paid" ? "secondary" : "error"
                        }
                      />
                    </TableCell>
                   <TableCell></TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </>
      )
    )}
    </>
  )
}

export default StudentPayments2;