import { Box, Button, Stack, Typography } from '@mui/material'
import React from 'react'
import MainCard from '../../../../../components/Maincard'

export default function SubjectForm({handelclose,sendEmail}) {
    const handlesubmit=()=>{
        sendEmail()
        setTimeout(() => {
            handelclose(); // Call your handleClose function here
          }, 500);
    }
  return (
    

    <>
    <MainCard>  <Typography variant='h4'> Do you want to send this message without a subject ?</Typography>
 
 <Stack sx={{marginTop:'13px' , width:'100%'}} display="flex" flexDirection="row"  alignItems="center" justifyContent='center'  >
    <Button  variant='contained' color='error' onClick={handelclose}>Don't Send</Button>
    <Button variant='contained' color='secondary' style={{marginLeft:'10px'}} onClick={handlesubmit}> Send Anyway</Button>
    </Stack>
    </MainCard>
  
    </>
  )
}
