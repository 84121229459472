import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainCard from "../../components/Maincard";
import {
  Box,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  makeStyles,
  styled,
} from "@mui/material";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import SubCard from "../../newTestPrep/components/ui-component/card/SubCard";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

export default function DiscountDetails() {
  const { id } = useParams();
  console.log(id);
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [showRemarks, setShowRemarks] = useState(false);
  const toggleRemarks = () => {
    setShowRemarks(!showRemarks);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(`${API_ENDPOINT}discoutform/${id}`);
        if (response.ok) {
          const data = await response.json();

          setdata(data);
          setLoading(false);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
          setLoading(false);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);
  console.log(data);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <MainCard>
            <Typography variant="h2" textAlign="center">
              Discount Details
            </Typography>
          </MainCard>
        </Grid>
        {loading ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
              height: "100vh",
            }}
          >
            <CircularProgress />
          </Box>
        ) : !loading && data.length === 0 ? (
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <Typography variant="h3"> No Data</Typography>
          </Box>
        ) : (
          <>
            <Grid
              container
              spacing={2}
              mt={2}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Grid item xs={12} md={4} lg={3} sm={4}>
                <MainCard>
                  <Stack direction="row">
                    <img src="/Coupon.png" />
                    <Stack direction="column" ml={2}>
                      <Typography variant="h4">Name Of Discount</Typography>
                      <Typography variant="h3" mt={1}>
                        {data.name}
                      </Typography>
                    </Stack>
                  </Stack>
                </MainCard>
              </Grid>

              <Grid item xs={12} md={4} lg={3} sm={4}>
                <MainCard>
                  <Stack direction="row">
                    <img src="/Calendar.png" />
                    <Stack direction="column" ml={2}>
                      <Typography variant="h4">Validity Date</Typography>
                      <Typography variant="h3" mt={1}>
                        {data.start_date}  -  {data.end_date}
                      </Typography>
                    </Stack>
                  </Stack>
                </MainCard>
              </Grid>

              <Grid item xs={12} md={4} lg={3} sm={4}>
                <MainCard>
                  <Stack direction="row">
                    <img src="/DiscountName.png" />
                    <Stack direction="column" ml={2}>
                      <Typography variant="h4">Discount Source</Typography>
                      <Typography variant="h3" mt={1}>
                        {data.reason_for_creating_discount}
                      </Typography>
                    </Stack>
                  </Stack>
                </MainCard>
              </Grid>
            </Grid>
            <Grid item xs={12} md={6} lg={4} sm={6}>
              <MainCard>
                <Box textAlign="center">
                  <img src="/branch (2).png" />
                  <Typography variant="h3">Branch </Typography>
                  <Typography variant="h4" mt={2}>
                    {data?.branch ? data.branch.join(" , ") : ""}
                  </Typography>
                </Box>
              </MainCard>
            </Grid>

            <Grid item xs={12} md={6} lg={4} sm={6}>
              <MainCard>
                <Box textAlign="center">
                  <img src="/testtype.png" />
                  <Typography variant="h3">Test Type </Typography>
                  <Typography variant="h4" mt={2}>
                    {data?.test_type ? data.test_type.join(" , ") : ""}
                  </Typography>
                </Box>
              </MainCard>
            </Grid>

            <Grid item xs={12} md={6} lg={4} sm={6}>
              <MainCard>
                <Box textAlign="center">
                  <img src="/Package.png" />
                  <Typography variant="h3">Package / Product </Typography>
                  <Typography variant="h4" mt={2}>
                    {data?.package ? data.package.join(" , ") : ""}
                  </Typography>
                </Box>
              </MainCard>
            </Grid>

            <Grid item xs={12} md={6} lg={7} sm={6}>
              <MainCard>
                <Stack
                  direction="row"
                  display="flex"
                  justifyContent="space-between"
                >
                  <Stack direction="row">
                    <Typography sx={{ fontSize: "16px" }}>
                      Type Of Discount :{" "}
                    </Typography>
                    <Typography variant="h4" ml={1}>
                      {" "}
                      {data.type_of_discount}
                    </Typography>
                  </Stack>

                  <Stack direction="row">
                    {data.type_of_discount === "cash" && (
                      <>
                        <Typography sx={{ fontSize: "16px" }}>
                          Discount  Amount :
                        </Typography>
                        <Typography variant="h4" ml={1}>
                          {" "}
                          {data.discount_amount} Rs
                        </Typography>
                      </>
                    )}
                  </Stack>

                  <Stack direction="row">
                    {data.type_of_discount === "percentage" && (
                      <>
                        <Typography sx={{ fontSize: "16px" }}>
                          Discount Percentage :
                        </Typography>
                        <Typography variant="h4" ml={1}>
                          {" "}
                          {data.discount_percentage} %
                        </Typography>
                      </>
                    )}
                  </Stack>

                  <Stack direction="row">
                    <Typography sx={{ fontSize: "16px" }}>Status :</Typography>
                    <Typography variant="h4" ml={1}>
                      {" "}
                      {data.status}{" "}
                    </Typography>
                  </Stack>
                </Stack>
              </MainCard>
            </Grid>

            <Grid item xs={12} lg={4} md={6} sm={6}>
              <MainCard>
                <Stack
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    flexDirection: "row",

                    alignItems: "center",
                  }}
                >
                  <Typography variant="h4">Remarks</Typography>

                  {showRemarks ? (
                    <KeyboardArrowUpIcon onClick={toggleRemarks} />
                  ) : (
                    <KeyboardArrowDownIcon onClick={toggleRemarks} />
                  )}
                </Stack>

                {showRemarks && (
                  <>
                      {data.remarks ? data.remarks : "Remark not available"}
                  </>
                )}
              </MainCard>
            </Grid>
          </>
        )}
      </Grid>

    </>
  );
}
