import React, { useEffect, useState } from 'react';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Stack,
  useTheme,
  Typography,
} from '@mui/material';
import MainCard from '../../../components/ui-component/card/MainCard';
import { CSVExport } from '../../../components/ui-component/table/CSVExport';
import CustomSnackbar from '../../../components/ui-component/snackbar/CustomSnackbar';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

const RoomScheduleDialog = ({ open, onClose, branchName, roomId, branchId }) => {
    const theme = useTheme()
    const [classScheduleData, setClassScheduleData] = useState([])
    const [isLoading, setIsLoading] = useState(false)

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };
    

  const handleChange = async (event, newValue) => {
   // setValue(newValue);
    setIsLoading(true);
   // setSelectedRoomData([]); 

    //const selectedRoom = branchRooms[newValue];
    const room_Id = roomId; 
    const branch_Id = branchId;

    try {
      // Make a POST request with the roomId
      const response = await fetch(`${API_ENDPOINT}route/slotbyroombranch`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ room_id: room_Id, branch_id: branch_Id}),
      });

      if (response.status === 403) {
        //throw new Error('Network response was not ok');
       // setSelectedRoomData([])
       showSnackbar('No Classes found in this room', 'error');
       setIsLoading(false);

      }

      const data = await response.json();
      // Store the fetched data in state
      setClassScheduleData(data);
      setIsLoading(false);
    } catch (error) {
        if (error.message.includes('401')) {
            showSnackbar('Unauthorized to fetch classes', 'error');
        } else if (error.message.includes('403')) {
          showSnackbar('No Classes found in this room', 'error');
        }  else {
          console.error('Error fetching student data:', error);
          //showSnackbar('Error fetching classes data:', 'error');
          setIsLoading(false);
        }
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    handleChange()
  }, [])

  return (
    <>
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant='h4'>Class Schedule - {branchName}</DialogTitle>
      <DialogContent>

      <MainCard
        content={false}
        title="Room Schedule"
        secondary={
            <Stack direction="row" spacing={2} alignItems="center">
                <CSVExport data={classScheduleData} filename="basic-table.csv"  />
            </Stack>
        }
    >
        <TableContainer>
          <Table>
            <TableHead sx={{
                                        color: theme.palette.dark,
                                        borderColor: theme.palette.orange.main,
                                       // backgroundColor: theme.palette.primary,
                                        '&:hover ': { background: theme.palette.orange.light }
                                    }}>
              <TableRow>
                <TableCell sx={{ backgroundColor: '#90ee90' }}>Slot</TableCell>
                <TableCell sx={{ backgroundColor: '#90ee90' }}>Test Type</TableCell>
                <TableCell sx={{ backgroundColor: '#90ee90' }}>Trainer</TableCell>
                <TableCell sx={{ backgroundColor: '#90ee90' }}>Class Capacity</TableCell>
                <TableCell sx={{ backgroundColor: '#90ee90' }}>Active Students</TableCell>
                <TableCell sx={{ backgroundColor: '#90ee90' }}>Seats Available</TableCell>
               
              </TableRow>
            </TableHead>
            <TableBody>
            {classScheduleData.length === 0 ? (
                <TableRow>
                  <TableCell sx={{ pl: 3 }} />
                  <TableCell colSpan={4} align="center">
                    <Typography variant='h4'>No data available</Typography>
                  </TableCell>
                </TableRow>
              ) : (
         
              classScheduleData.map((row, index) => (
                <TableRow key={index}>
                  <TableCell>{row.start_time} - {row.end_time}</TableCell>
                  <TableCell>{row.test_type_id?.name}</TableCell>
                  <TableCell>{row.trainer_id?.first_name}</TableCell>
                  <TableCell>{row.capacity}</TableCell>
                  <TableCell>{row.enrolled_student !== null ? row.enrolled_student : 0}</TableCell>
                 <TableCell>{row.seat_availability}</TableCell>
                  
                </TableRow>
              )))}
            </TableBody>
          </Table>
        </TableContainer>
        </MainCard>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Close</Button>
      </DialogActions>
    </Dialog>

        <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
        />

</>
  );
};

export default RoomScheduleDialog;
