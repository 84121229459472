// Author : Ayesha Shaikh
//  Date : 17 sep 2024

import {
  Autocomplete,
  Backdrop,
  Button,
  CircularProgress,
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import MainCard from "../../../components/Maincard";
import { useEffect } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import CloseIcon from '@mui/icons-material/Close';
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EditEmployeeProfile() {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 4;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 100,
        overflow: 'auto',
        zIndex: 1300, 
      },
    },
  };
  const userId = localStorage.getItem("userId");
  const userRole=localStorage.getItem('userRole')
  const { id } = useParams();
  const state = JSON.parse(localStorage.getItem("employeeState"));
  console.log("location", state);

  const [firstName, setFirstName] = useState(null);
  const [lastName, setLastName] = useState(null);
  const [codeList, setCodeList] = useState([]);
  const [BranchList, setBranchList] = useState([]);
  const [branchName, setBranchName] = useState(null);

  const [roleList, setRoleList] = useState([]);
  const [role, setrole] = useState([]);
  const [country, setCountry] = useState([]);
  const [countryList, setCountryList] = useState([]);
  const [serviceList, setServiceList] = useState([]);
  const [service, setService] = useState([]);
  const [subServiceList, setSubserviceList] = useState([]);
  const [subService, setSubService] = useState([]);
  const [workBranch, setWorkBranch] = useState([]);
  const [userList, setUserList] = useState([]);
  const [branchManager, setBranchManager] = useState([]);
  const [reportingManagerList, setReportingManagerList] =useState([])
  const [reportingManager, setReportingManager] = useState([]);
  const [reportUser, setReportUser] = useState([]);
  const [reportUser1, setReportUser1] = useState([]);
   const [reportUsers, setReportUsers] = useState([{ id: 1, value: "" }]);

  const [employeeData, setEmployeeData] = useState();
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  console.log(employeeData?.first_name);
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const ccField = reportUser.map((i) => i.email);
  const ccField1 = reportUsers.map((i) => i.value)

 

  const onSubmit = async (data) => {
    console.log("datais",data)
    try {
      const requestData = {
        ...data,
        role: role.map((i) => i.id),
        branch: branchName?.id,
        branch_manager: branchManager?.first_name,
        reporting_manager: reportingManager?.id,
        add_report_cc_user: [...ccField, ...ccField1],
        user_id: parseInt(userId),
      };
      const response = await fetch(`${API_ENDPOINT}employee/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      if (response.ok) {
        showSnackbar("Employee details updated Successfully", "success");

        // setTimeout(() => {
        //  window.location.reload()
        // }, 1500);
      } else if (response.status === 401) {
        showSnackbar("Unknown User", "error");
      } else if (response.status === 404) {
        showSnackbar("Error to Adding Employee", "error");
      } else if (response.status === 401) {
        showSnackbar("Unathorized User", "error");
      } else if (response.status === 409) {
        showSnackbar("Already active user existing with this email", "error");
      } 
      else {
        showSnackbar("Error to Adding Employee", "error");
        throw new Error("Error to Adding Employee");
      }
    } catch (error) {
      showSnackbar("Error to Adding Employee", "error");
      console.error("Error to Adding Employee", error);
    }
  };

  useEffect(() => {
    setLoading(true);
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}employee/${id}`);
        if (response.ok) {
          const data = await response.json();
          setEmployeeData(data);
          setFirstName(data?.first_name);
          setLastName(data?.last_name);
          setLoading(false);
        } else {
          setLoading(false);
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}country`);
        if (response.ok) {
          const data = await response.json();
          setCountryList(data);
          const sortedData = [...data].sort(
            (a, b) => a.phone_code - b.phone_code
          );
          const uniqueArray = sortedData.filter(
            (obj, index, self) =>
              index === self.findIndex((t) => t.phone_code === obj.phone_code)
          );

          setCodeList(uniqueArray);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          setBranchList(data);
          const defaultBranch = data.find(
            (i) => i.id === state?.employeedetails?.branch?.id
          );
          if (defaultBranch) {
            const defaultBranchIds =defaultBranch?.id
            setBranchName(defaultBranch);
            setValue("branch",defaultBranchIds)
          }
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}role`);
        if (response.ok) {
          const data = await response.json();
          const filterdData= data.filter((i)=>i.role_name !=="center_admin")
          setRoleList(filterdData);
          const defaultRole = data.filter(
            (i) => state?.employeedetails?.role.includes(i.role_name)
          );
          console.log(defaultRole);
          if (defaultRole) {
            const defaultRoleids =defaultRole.map((i)=>i.id)
            setrole(defaultRole);
            setValue('role',defaultRoleids)
          }
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}service`);
        if (response.ok) {
          const data = await response.json();
          setServiceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
          setSubserviceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setUserList(data);
          const defaultRole = data.find(
            (i) =>
              i.first_name.toLowerCase() ===
              state?.employeedetails?.branch_manager.toLowerCase()
          );
          console.log("defaultRole", defaultRole);
          if (defaultRole) {
            setBranchManager(defaultRole);
          }

        
          const defaultReporcc = data.find(
            (i) =>
              i.first_name.toLowerCase() ===
              state?.employeedetails?.reporting_manager.toLowerCase()
          );
          console.log("defaultRole", defaultReporcc);
          if (defaultReporcc) {
            setReportingManager(defaultReporcc);
          }

        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}reportingmanager`);
        if (response.ok) {
          const data = await response.json();
          setReportingManagerList(data);
          const defaultReportingManager = data.filter(
            (i) =>
              state?.employeedetails?.reporting_manager.includes(`${i.first_name} ${i.last_name}`)
          );
  
          if (defaultReportingManager.length > 0) {
            setReportingManager(defaultReportingManager);
          }
          console.log("defaultReportingManager", defaultReportingManager);

        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleBranchChange = (event, value) => {
    setBranchName(value);
    setValue("branch", value);
  };

  
  useEffect(() => {
    const value = branchName?.admin_id?.user_id;
    const defaultBranchManager = userList.find((i) => i.id === value);
    setBranchManager(defaultBranchManager);
  }, [branchName]);

  console.log("branchManager", branchName);

  const handleRoleChange = (event, value) => {
    setrole(value);
    setValue("role", value);
  };

  const handleCountryChange = (event, value) => {
    setCountry(value);
  };

  const handleServiceChange = (event, value) => {
    setService(value);
  };

  const handleSubServiceChange = (event, value) => {
    setSubService(value);
  };

  const handleWorkBranchChange = (event, value) => {
    setWorkBranch(value);
  };

  const handleBranchManagerChange = (event, value) => {
    setBranchManager(value);
  };

  const handlereportManagerChange = (event, value) => {
    setReportingManager(value);
  };

  const alluserList = [{ first_name: "Other", email: null }, ...userList];
  const handlereportCCChange = (event, value) => {
    setReportUser(value);
  };

 


  const CustomPaper = (props) => {
    return (
      <Paper
        {...props}
        style={{
          maxHeight: 200,
          overflow: 'auto',
          zIndex: 1500, // Adjust z-index as needed
          // Add any other custom styles here
        }}
      />
    );
  };

// Change add cc filed value
  const selectedccUser = reportUser.map((i) => i.first_name);
  const handlereportCCChange1 = (index,event) => {
  

    const newReportUsers = [...reportUsers];
    newReportUsers[index].value = event.target.value;
    setReportUsers(newReportUsers);
  };

//  Add More cc Field
  const handleAddMore = () => {
    setReportUsers([...reportUsers, { id: reportUsers.length + 1, value: "" }]);
  };

  // Removed TextField  cc
  const handleRemoveField = (index) => {
    const newReportUsers = reportUsers.filter((_, i) => i !== index);
    // Ensure there's always at least one field
    if (newReportUsers.length === 0) {
      setReportUsers([{ id: 1, value: "" }]);
    } else {
      setReportUsers(newReportUsers);
    }
  };


  console.log("reportUsers",ccField1)
  
  console.log("reportUsers",)
  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="secondary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <Paper
            sx={{
              width: "300px",
              fontSize: "22px",
              fontWeight: 600,
              height: "50px",
              borderRadius: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            Edit Employee Details
          </Paper>
        </Grid>
        <Grid item xs={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography
                  sx={{ fontSize: "20px", fontWeight: 600, color: "#e50000" }}
                >
                  Mandatory Information
                </Typography>
              </Grid>

              {/* Employee Name  */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  First Name{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.2em",
                    }}
                  >
                    *
                  </span>
                </Typography>

                <Controller
                  name="first_name"
                  control={control}
                  defaultValue={state?.employeedetails?.first_name}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      {...field}
                      InputProps={{
                        style: { borderRadius: "14px" },
                      }}
                      fullWidth
                      error={errors.first_name}
                      helperText={errors.first_name && "First name is required"}
                    />
                  )}
                />
              </Grid>

              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Last Name{" "}
                </Typography>
                <Controller
                  name="last_name"
                  control={control}
                  defaultValue={state?.employeedetails?.last_name}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      {...field}
                      InputProps={{
                        style: { borderRadius: "14px" },
                      }}
                      fullWidth
                    />
                  )}
                />
              </Grid>

              {/* Email */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Email{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.2em",
                    }}
                  >
                    *
                  </span>
                </Typography>
               

                <Controller
                  name="email"
                  control={control}
                  defaultValue={state?.employeedetails?.email}
                  rules={{
                    required: "Email is required",
                    pattern: {
                      value: /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/,
                      message: "Please enter a valid email address",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      size="small"
                   
                      {...field}
                      InputProps={{  
                        style: { borderRadius: "14px" }, readOnly:true ,
                      }}
                      fullWidth
                      error={errors.email}
                      helperText={errors.email && errors.email.message}
                      sx={
                    {
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "#b2b2b4", // Keep the border color gray on mouseover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#b2b2b4", // Keep the border color gray even on focus
                          },
                        },
                      } }
                    />
                  )}
                />
              </Grid>

              {/* Mobile */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Grid container>
                  <Grid item xs={12} lg={4} md={4} sm={6}>
                    <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                      ISD Code{" "}
                    
                    </Typography>
                    <Controller
                      name="mobile_country_code"
                      control={control}
                      defaultValue={state?.employeedetails?.mobile_country_code}
                      
                      render={({ field }) => (
                        <TextField
                          {...field}
                          select
                          fullWidth
                          size="small"
                          InputProps={{
                            style: {
                              borderRadius: "14px",
                              height: "37px",
                            },
                          }}
                          SelectProps={{
                            MenuProps: {
                              disableScrollLock: true,
                              sx: { maxHeight: "50%" },
                            },
                          }}
                        >
                          {codeList.map((code) => (
                            <MenuItem
                              key={code?.phone_code}
                              value={code?.phone_code}
                            >
                              {code?.phone_code}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    ></Controller>
                  </Grid>
                  <Grid item xs={12} lg={8} md={8} sm={6}>
                    <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                      Mobile No{" "}
                     
                    </Typography>
                    <Controller
                      name="mobile"
                      control={control}
                      defaultValue={state?.employeedetails?.mobile}
                      rules={{
                        // required: "Mobile number is required",
                        pattern: {
                          value: /^[0-9]{10}$/, // Example regex for 10-digit mobile numbers
                          message: "Please enter a valid 10-digit mobile number",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          size="small"
                          InputProps={{
                            style: {
                              borderRadius: "14px",
                              height: "37px",
                            },
                          }}
                          error={!!errors.mobile}
        helperText={errors.mobile ? errors.mobile.message : ""}
                        />
                      )}
                    ></Controller>
                  </Grid>
                </Grid>
              </Grid>

              {/* Gender */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
              <FormControl fullWidth >
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Gender{" "}
               
                </Typography>
                <Controller
                  name="gender"
                  control={control}
                  defaultValue={state?.employeedetails?.gender}
              
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      select
                  
                      InputProps={{
                        style: {
                          borderRadius: "14px",
                          height: "37px",
                        },
                      }}
                    >
                      <MenuItem value="Female">Female</MenuItem>
                      <MenuItem value="Male">Male</MenuItem>
                      <MenuItem value="other">Other</MenuItem>
                    </TextField>
                  )}
                ></Controller> </FormControl>
              </Grid>

              {/*Date of Birth */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Date of Birth{" "}
                  
                </Typography>
                <Controller
                  name="dob"
                  control={control}
                  defaultValue={state?.employeedetails?.dob}
                  rules={{
           
                    validate: (value) => {
                      const today = new Date();
                      const birthDate = new Date(value);
                      const age = today.getFullYear() - birthDate.getFullYear();
                      const monthDiff = today.getMonth() - birthDate.getMonth();
                      const dayDiff = today.getDate() - birthDate.getDate();
                
                      // Check if user is less than 18 years old
                      if (age < 18 || (age === 18 && (monthDiff < 0 || (monthDiff === 0 && dayDiff < 0)))) {
                        return "You must be at least 18 years old";
                      }
                      return true;
                    }
                  }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      type="date"
                      InputProps={{
                        style: {
                          borderRadius: "14px",
                          height: "37px",
                        },
                      }}
                      error={!!errors.dob}
                      helperText={errors.dob ? errors.dob.message : ""}
                    />
                  )}
                >
                  {" "}
                </Controller>
              </Grid>

              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Date of Joining{" "}
                  
                </Typography>
                <Controller
                  name="joining_date"
                  control={control}
                  defaultValue={state?.employeedetails?.joining_date}
               
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      type="date"
                      InputProps={{
                        style: {
                          borderRadius: "14px",
                          height: "37px",
                        },
                      }}
                    />
                  )}
                ></Controller>
              </Grid>

              {/* Designation */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Designation{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.2em",
                    }}
                  >
                    *
                  </span>
                </Typography>

                <Controller
                  name="designation"
                  control={control}
                  defaultValue={state?.employeedetails?.designation}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <TextField
                      {...field}
                      fullWidth
                      size="small"
                      InputProps={{
                        style: {
                          borderRadius: "14px",
                          height: "37px",
                        },
                      }}
                      error={errors.designation}
                      helperText={errors.designation && "Designation is required"}
                    />
                  )}
                ></Controller>
              </Grid>

              {/* Select Role */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
             
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Select Role{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.2em",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Controller
                  name="role"
                  control={control}
                  defaultValue={state?.employeedetails?.role?.id}
                  rules={{ required: true }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      size="small"
                      readOnly={userRole!=="data_entry"}
                      multiple
                      components={{
                        Paper: CustomPaper, // Use your custom Paper component here
                      }}
                      disablePortal
                      value={role}
                      onChange={handleRoleChange}
                      options={roleList}
                      getOptionLabel={(option) => option.role_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "14px" }, // Add borderRadius here
                          }}
                          sx={
                            userRole !== "data_entry" ?{
                            "& .MuiOutlinedInput-root": {
                              "&:hover fieldset": {
                                borderColor: "#b2b2b4", // Keep the border color gray on mouseover
                              },
                              "&.Mui-focused fieldset": {
                                borderColor: "#b2b2b4", // Keep the border color gray even on focus
                              },
                            },
                          } :{}}
                          error={errors.role}
                          helperText={errors.role && "Role is required"}
                        />
                      )}
                    
                    />
                  )}
                ></Controller> 
              </Grid>
              {/* Branch  */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
             
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Branch{" "}
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.2em",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Controller
                  name="branch"
                  control={control}
              
                  rules={{ required: true }}
                  render={({ field }) => (
                <Autocomplete
                {...field}
                  size="small"
                  disablePortal
                  readOnly={userRole!=="data_entry"}
                  value={branchName}
                  onChange={handleBranchChange}
                  options={BranchList}
                  getOptionLabel={(option) => option.branch_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "14px" }, // Add borderRadius here
                      }}
                      sx={
                        userRole !== "data_entry" ?{
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "#b2b2b4", // Keep the border color gray on mouseover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#b2b2b4", // Keep the border color gray even on focus
                          },
                        },
                      } :{}}
                    />
                  )}
                />
                  )}
                  ></Controller>  {errors.branch && (
                    <FormHelperText>Branch is required</FormHelperText>
                  )}
                {errors && (
                  <Typography color="error" variant="caption"></Typography>
                )}
              </Grid>

              {/* Branch  Manager   */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
           
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Branch Manager{" "}
             
                </Typography>

                <Controller
                  name="branch_manager"
                  control={control}
                
               
                  render={({ field }) => (
                <Autocomplete
                {...field}
                  size="small"
                  readOnly={userRole!=="data_entry"}
                  disablePortal
                  value={branchManager}
                  onChange={handleBranchManagerChange}
                  options={userList}
                  getOptionLabel={(option) =>
                    `${option.first_name ? option.first_name : " "} ${
                      option.last_name ? option.last_name : " "
                    }`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "14px" }, // Add borderRadius here
                      }}
                      sx={
                        userRole !== "data_entry" ?{
                        "& .MuiOutlinedInput-root": {
                          "&:hover fieldset": {
                            borderColor: "#b2b2b4", // Keep the border color gray on mouseover
                          },
                          "&.Mui-focused fieldset": {
                            borderColor: "#b2b2b4", // Keep the border color gray even on focus
                          },
                        },
                      } :{}}
                    />
                  )}
                />
                  )} ></Controller> 
              </Grid>

              {/* Reporting  Manager  */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
          
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Reporting Manager{" "}
                  <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1.2em",
                }}
              >
                *
              </span>
              
              
                </Typography>


                <Controller
                  name="reporting_manager"
                  control={control}
                
               
                  render={({ field }) => (
                <Autocomplete
                {...field}
                  size="small"
                  multiple
                  disablePortal
                  value={reportingManager}
                  onChange={handlereportManagerChange}
                  options={reportingManagerList}
                  getOptionLabel={(option) =>
                    `${option.first_name ? option.first_name : " "} ${
                      option.last_name ? option.last_name : " "
                    }`
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "14px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />
                  )}></Controller> 
              </Grid>

              {/* Work On Country */}
              {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Work On Country{" "}
                </Typography>

                <Autocomplete
                  size="small"
                  multiple
                  disablePortal
                  value={country}
                  onChange={handleCountryChange}
                  options={countryList}
                  getOptionLabel={(option) => option.country_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "14px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}

              {/* Work On Service */}
              {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Work On Service{" "}
                </Typography>
               
                <Autocomplete
                  size="small"
                  multiple
                  disablePortal
                  value={service}
                  onChange={handleServiceChange}
                  options={serviceList}
                  getOptionLabel={(option) => option.service_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "14px",  }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}

              {/* Work On SubService */}
              {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Work On SubService{" "}
                </Typography>
                
                <Autocomplete
                  size="small"
                  multiple
                  disablePortal
                  value={subService}
                  onChange={handleSubServiceChange}
                  options={subServiceList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "14px",  }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}

              {/*  Branch Type */}
              {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Branch Type{" "}
                </Typography>
                <TextField
                  fullWidth
                  size="small"
                  InputProps={{
                    style: {
                      borderRadius: "14px",
                    },
                  }}
                />
              </Grid> */}

              {/*Work On Branch */}
              {/* <Grid item xs={12} lg={3} md={3} sm={4}>
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Work On Branch{" "}
                </Typography>
                
                  
                <Autocomplete
                  size="small"
                  multiple
                  disablePortal
                  value={workBranch}
                  onChange={handleWorkBranchChange}
                  options={BranchList}
                  getOptionLabel={(option) => option.branch_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "14px",  }, // Add borderRadius here
                      }}
                    />
                  )}
                />
              </Grid> */}

              {/*Report CC User */}
              <Grid item xs={12} lg={3} md={3} sm={4}>
         
                <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                  Select Report CC User{" "}
                </Typography>

                <Controller
                  name="add_report_cc_user"
                  control={control}
                
               
                  render={({ field }) => (
                <Autocomplete
                {...field}
                  size="small"
                  disablePortal
                  multiple
                  value={reportUser}
                  onChange={handlereportCCChange}
                  options={alluserList}
                  getOptionLabel={(option) =>
                    `${option.first_name ? option.first_name : " "} ${
                      option.last_name ? option.last_name : " "
                    } ${option.email ? `:- ${option.email}` : " "}`
                  }
                  renderOption={(props, option, { selected }) => {
                    const { key, ...optionProps } = props;
                    return (
                      <li key={key} {...optionProps}>
                        {`${option.first_name ? option.first_name : " "} ${
                          option.last_name ? option.last_name : " "
                        } ${option.email ? `:- ${option.email}` : " "}`}
                      </li>
                    );
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "14px" }, // Add borderRadius here
                      }}
                    />
                  )}
                />
                  )}></Controller> 
              </Grid>
              {/* {selectedccUser.includes("Other") && (
                < >
                <Grid item xs={12} lg={3} md={3} sm={4}>
                  <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                    Add Report CC User{" "}
                  </Typography>
                  <Controller
                  name="add_report_cc_user"
                  control={control}
                
               
                  render={({ field }) => (
                  <TextField
                  {...field}
                    fullWidth
                    size="small"
                    value={reportUser1}
                    onChange={handlereportCCChange1}
                    InputProps={{
                      style: {
                        borderRadius: "14px",
                        height: "37px",
                      },
                    }}
                  />
                  )}></Controller>
                </Grid>
                <Grid item xs={12} lg={3} md={3} sm={4}>
                  <Typography sx={{ visibility:'hidden'}}> Spacing Purpose</Typography>
                <Button variant="contained" color="secondary" size="small">Add More</Button>
                </Grid>
                </>
              )} */}

{selectedccUser.includes("Other") &&
      reportUsers.map((reportUser, index) => (
        <React.Fragment key={reportUser.id}>
          <Grid item xs={12} lg={3} md={3} sm={4}>
            <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
              Add Report CC User
            </Typography>
            <Controller
              name={`add_report_cc_user_${reportUser.id}`}
              control={control}
              render={({ field }) => (
                <TextField
                  {...field}
                  fullWidth
                  size="small"
                  value={reportUser.value}
                  onChange={(e) => handlereportCCChange1(index, e)}
                  InputProps={{
                    style: {
                      borderRadius: "14px",
                      height: "37px",
                    },
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          edge="end"
                          onClick={() => handleRemoveField(index)}
                        >
                          <CloseIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  
                />
              )}
            />
          </Grid>
          {index === reportUsers.length - 1 && (
          <Grid item >
            <Typography sx={{ visibility: "hidden" }}>
              Spacing Purpose
            </Typography>
           
              <Button
                variant="contained"
                color="secondary"
                size="small"
                onClick={handleAddMore}
              >
                Add More 
              
              </Button>
          
          </Grid>
            )}
        </React.Fragment>
      ))}
 

              <Grid item xs={12} textAlign="end">
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handleSubmit(onSubmit)}
                >
                  Submit
                </Button>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
