import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";

function CreateLessonPopup({ onClose, open, title, component }) {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down("md"));

  return (
    <Dialog
      onClose={onClose}
      open={open}
      scroll="body"
      maxWidth="lg"
      fullWidth
      sx={{ borderRadius: "50px" }}
    >
      <DialogTitle
        variant="h3"
        textAlign="center"
        sx={{ textAlign: "center", fontSize: "28px", fontWeight: "700" }}
      >
        {title}
        <IconButton
          edge="end"
          color="inherit"
          onClick={onClose}
          aria-label="close"
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{component}</DialogContent>
      <DialogActions>
        {/* <Button variant='contained' color='secondary' onClick={onClose}>Cancel</Button> */}
      </DialogActions>
    </Dialog>
  );
}

export default CreateLessonPopup;
