import { Button, Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'
import { fDate } from '../../../utils/formatTime';

function RefundRequests() {
    const [data, setData] = useState([
        
      ]);
    const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleAction = (row, action) => {
    // Implement the logic for each action
    if (action === 'changeToPaid') {
      // Change status to "Paid"
      const updatedData = data.map((item) =>
        item === row ? { ...item, status: 'Paid' } : item
      );
      setData(updatedData);
    } else if (action === 'generateReceipt') {
      // Implement logic for generating a receipt (you can open a dialog, etc.)
      console.log(`Generate receipt for ${row.customer.name}`);
    }
  };

  return (
    <>
   <TableContainer component={Paper} sx={{borderRadius:'15px' ,padding:'10px' }}>
    <Table>
      <TableHead >
        <TableRow >
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 }}>Customer Details</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 ,}}>Branch</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Request By</TableCell>
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 }}>Refund Type</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600,}}>Date</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Refund Amount</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Refund Reason</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Refund Status</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
      {data.length === 0 ? (
            <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <Typography variant='h5'>No data available</Typography>
            </Grid>
                            ) : (
      data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
          <TableRow key={index}  style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'inherit' ,width: '6%' }}>
            <TableCell>{`${row.customer.name}\n${row.customer.mobile}`}</TableCell>
                  <TableCell>{row.branch}</TableCell>
                  <TableCell>{row.paymentMode}</TableCell>
                  <TableCell>{row.date}</TableCell>
                  <TableCell>{row.amount}</TableCell>
                  <TableCell>{row.status}</TableCell>
                  <TableCell>
                {row.status === 'Pending' && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleAction(row, 'changeToPaid')}
                  >
                    Change to Paid
                  </Button>
                )}
                {row.status === 'Paid' && (
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={() => handleAction(row, 'generateReceipt')}
                  >
                    Generate Receipt
                  </Button>
                )}
                {/* Add additional buttons as needed */}
              </TableCell>
          </TableRow>
        )))}
      </TableBody>
    </Table>
    <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
  </TableContainer>
    
  </>
  )
}

export default RefundRequests