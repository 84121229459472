import {
  ButtonBase,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
  Slide,
  Tooltip,
  Typography,
  useTheme,
} from "@mui/material";

import React, { useState } from "react";

import AssignmentIcon from "@mui/icons-material/Assignment";
import { useNavigate } from "react-router-dom";
import ViewTasksDetails from "./ViewTasksDetails";
import CloseIcon from "@mui/icons-material/Close";
import ViewTaskFilter from "./ViewTaskFilter";

const Transition = React.forwardRef(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

function ViewTasks() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const theme = useTheme();

  const [open, setOpen] = React.useState(false);
  const [filterCriteria, setFilterCriteria] = useState(null);

  const [task, setTask] = useState([]);
  const [createdby, setCreatedby] = useState([]);
  const [stage, setStage] = useState([]);
  const [taskOwner, setTaskowner] = useState([]);
  const [status, setStatus] = useState([]);
  const [state, setState] = useState([]);
  const [city, setCity] = useState([]);
  const [name, setName] = useState("");
  const [createdDatefrom, setCreateddatefrom] = useState(null);
  const [createdDateTo, setCreateddateTo] = useState(null);
  const [duedatefrom, setDuedatefrom] = useState(null);
  const [duedateTo, setDuedateTo] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [selectedChip, setSelectedChip] = useState("notStarted");
  const [isLoading, setIsLoading] = useState(false);
  const [closedby, setClosedby] = useState([]);
  const [closedDatefrom, setCloseddatefrom] = useState(null);
  const [closedDateTo, setCloseddateTo] = useState(null);
  const [originaltaskData, setOriginalTaskData] = useState([]);
const [followupReason,setFollowupReason] =useState([])
const [selectedFolloupId,setSelectedFollowupId] =useState([])
const [prospectiveLevel,setProspectiveLevel] =useState([])
const [RecycleReason, setRecycleReason]=useState([])
const [campaign, setCampaign] =useState([])
const [subservice, setSubservice]=useState([])
const [prospectiveLevelId, setProspectiveLevelId] =useState([])
  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <>
      <Tooltip title="View Tasks" placement="bottom">
        <ButtonBase
          sx={{ mt: 0.5 }}
          color={theme.palette.secondary.main}
          onClick={handleClickOpen}
        >
          <AssignmentIcon aria-label="Tasks" sx={{ fontSize: 32 }} />
        </ButtonBase>
      </Tooltip>

      <Dialog
        fullScreen
        open={open}
        onClose={handleClose}
        TransitionComponent={Transition}
      >
        <DialogTitle
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Typography variant="h3" fontWeight={800}>
            View My Tasks
          </Typography>
          <IconButton
            edge="end"
            color="inherit"
            onClick={handleClose}
            aria-label="close"
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <Divider />
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={8.5} sm={8.5} md={8.5}>
              <ViewTasksDetails
                filterCriteria={filterCriteria}
                setFilterCriteria={setFilterCriteria}
                tasks={tasks}
                setTasks={setTasks}
                selectedChip={selectedChip}
                setSelectedChip={setSelectedChip}
                filteredTasks={filteredTasks}
                setFilteredTasks={setFilteredTasks}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                setOriginalTaskData={setOriginalTaskData}
                setTask={setTask}
                setCreatedby={setCreatedby}
                setTaskowner={setTaskowner}
                setStatus={setStatus}
                setCreateddatefrom={setCreateddatefrom}
                setCreateddateTo={setCreateddateTo}
                setDuedatefrom={setDuedatefrom}
                setDuedateTo={setDuedateTo}
                setClosedby={setClosedby}
                setCloseddatefrom={setCloseddatefrom}
                setCloseddateTo={setCloseddateTo}
                followupReason={followupReason}
                setFollowupReason={setFollowupReason}
                setSelectedFollowupId={setSelectedFollowupId}
                prospectiveLevel={prospectiveLevel}
                setProspectiveLevel={setProspectiveLevel}
                prospectiveLevelId={prospectiveLevelId}
                setProspectiveLevelId={setProspectiveLevelId}
                RecycleReason={RecycleReason}
                setRecycleReason={setRecycleReason}
                campaign={campaign}
                setCampaign={setCampaign}
                subservice={subservice}
                setSubservice={setSubservice}
                stage={stage}
                setStage={setStage}
              />
            </Grid>
            <Divider
              orientation="vertical"
              flexItem
              sx={{ marginLeft: "15px" }}
            />

            <Grid item xs={12} lg={3} sm={3} md={3}>
              <ViewTaskFilter
                task={task}
                setTask={setTask}
                createdby={createdby}
                setCreatedby={setCreatedby}
                stage={stage}
                setStage={setStage}
                taskOwner={taskOwner}
                setTaskowner={setTaskowner}
                status={status}
                setStatus={setStatus}
                state={state}
                setState={setState}
                city={city}
                setCity={setCity}
                name={name}
                setName={setName}
                createdDatefrom={createdDatefrom}
                setCreateddatefrom={setCreateddatefrom}
                createdDateTo={createdDateTo}
                setCreateddateTo={setCreateddateTo}
                duedatefrom={duedatefrom}
                setDuedatefrom={setDuedatefrom}
                duedateTo={duedateTo}
                setDuedateTo={setDuedateTo}
                closedby={closedby}
                setClosedby={setClosedby}
                closedDatefrom={closedDatefrom}
                setCloseddatefrom={setCloseddatefrom}
                closedDateTo={closedDateTo}
                setCloseddateTo={setCloseddateTo}
                isLoading={isLoading}
                setIsLoading={setIsLoading}
                tasks={tasks}
                setTasks={setTasks}
                selectedChip={selectedChip}
                setSelectedChip={setSelectedChip}
                originaltaskData={originaltaskData}
                filteredTasks={filteredTasks}
                setFilteredTasks={setFilteredTasks}
                followupReason={followupReason}
                setFollowupReason={setFollowupReason}
                setSelectedFollowupId={setSelectedFollowupId}
                selectedFolloupId={selectedFolloupId}
                prospectiveLevel={prospectiveLevel}
                setProspectiveLevel={setProspectiveLevel}
                prospectiveLevelId={prospectiveLevelId}
                setProspectiveLevelId={setProspectiveLevelId}
                RecycleReason={RecycleReason}
                setRecycleReason={setRecycleReason}
                campaign={campaign}
                setCampaign={setCampaign}
                subservice={subservice}
                setSubservice={setSubservice}
              />
            </Grid>
          </Grid>
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ViewTasks;
