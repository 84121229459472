import React, { useState } from 'react'
import MainCard from '../../components/Maincard'
import { Button, Checkbox, FormControl, FormControlLabel, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';

function ReportFilter() {
    const [open, setOpen] = useState(false); 
    const [toDateMonthYear, setToDateMonthYear] = useState(null);
    const [toDateDay, setToDateDay] = useState(null);
    const [fromDateMonthYear, setFromDateMonthYear] = useState(null);
    const [fromDateDay, setFromDateDay] = useState(null);
    const [includeDate, setIncludeDate] = useState(false);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [selectedExamType, setSelectedExamType] = useState('');
    const [selectedLeadSource, setSelectedLeadSource] = useState('');
    const [selectedPurposeOfTest, setSelectedPurposeOfTest] = useState('');
    const [selectedAdvisor, setSelectedAdvisor] = useState('');
    const [filterData, setFilterData] = useState({
        branch_id: null,
        test_type_id: null,
        lead_source_type: null,
        purpose_of_test: null,
        advisor_id: null,
        from_date_day: null,
        from_date_month: null,
        from_date_year: null,
        to_date_day: null,
        to_date_month: null,
        to_date_year: null,
    })

    const handleToDateMonthYearChange = (date) => {
        setToDateMonthYear(date);

        const selectedMonth = dayjs(date).month() + 1; // dayjs months are zero-indexed
        const selectedYear = dayjs(date).year();

        setFilterData((prevData) => ({
            ...prevData,
            to_date_month: selectedMonth,
            to_date_year: selectedYear,
        }));
    };

    const handleToDateDayChange = (date) => {
        setToDateDay(date);

        const selectedDay = dayjs(date).date();

        setFilterData((prevData) => ({
            ...prevData,
            to_date_day: selectedDay,
            
        }));
    };

    const handleFromDateMonthYearChange = (date) => {
        setFromDateMonthYear(date);

        // Extracting month and year using dayjs
        const selectedMonth = dayjs(date).month() + 1; 
        const selectedYear = dayjs(date).year();

        setFilterData((prevData) => ({
            ...prevData,
            from_date_month: selectedMonth,
            from_date_year: selectedYear,
        }));
    };

    

    const handleFromDateDayChange = (date) => {
        setFromDateDay(date);

        const selectedDay = dayjs(date).date();

        setFilterData((prevData) => ({
            ...prevData,
            from_date_day: selectedDay,
            
        }));


        console.log('Selected Day:', selectedDay);
    };

    const handleBranchChange = (event) => {
        setSelectedBranch(event.target.value);

        setFilterData((prevData) => ({
            ...prevData,
            branch_id: event.target.value,
            
        }));
    };

    const handleExamTypeChange = (event) => {
        setSelectedExamType(event.target.value);

        setFilterData((prevData) => ({
            ...prevData,
            test_type_id: event.target.value,
            
        }));
    };

    const handleLeadSourceChange = (event) => {
        setSelectedLeadSource(event.target.value);

        setFilterData((prevData) => ({
            ...prevData,
            lead_source_type: event.target.value,
            
        }));
    };

    const handlePurposeOfTestChange = (event) => {
        setSelectedPurposeOfTest(event.target.value);

        setFilterData((prevData) => ({
            ...prevData,
            purpose_of_test: event.target.value,
            
        }));
    };

    const handleAdvisorChange = (event) => {
        setSelectedAdvisor(event.target.value);

        setFilterData((prevData) => ({
            ...prevData,
            advisor_id: event.target.value,
            
        }));
    };

    

    const handleApplyFilter = () => {
        console.log(filterData)
    };

    const handleResetFilter = () => {
        setToDateMonthYear(null);
        setToDateDay(null);
        setFromDateMonthYear(null);
        setFromDateDay(null);
        setIncludeDate(false);
        setSelectedBranch('');
        setSelectedExamType('');
        setSelectedLeadSource('');
        setSelectedPurposeOfTest('');
        setSelectedAdvisor('');
        setFilterData({
            branch_id: null,
            test_type_id: null,
            lead_source_type: null,
            purpose_of_test: null,
            advisor_id: null,
            from_date_day: null,
            from_date_month: null,
            from_date_year: null,
            to_date_day: null,
            to_date_month: null,
            to_date_year: null,
        });
    };



  return (
   <>
        <MainCard>
        <Grid container spacing={2}>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="branch-name">Branch Name</InputLabel>
                            <Select 
                                label="Branch Name" 
                                id="branch-name"
                                value={selectedBranch}
                                onChange={handleBranchChange}
                                >
                                <MenuItem value="exam1">Exam 1</MenuItem>
                                <MenuItem value="exam2">Exam 2</MenuItem>
                                
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="exam-type">Exam Type</InputLabel>
                            <Select 
                                label="Exam Type" 
                                id="exam-type"
                                value={selectedExamType}
                                onChange={handleExamTypeChange}
                                >
                                <MenuItem value="exam1">Exam 1</MenuItem>
                                <MenuItem value="exam2">Exam 2</MenuItem>
                                
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="lead-source">Lead Source</InputLabel>
                            <Select 
                                label="Lead Source" 
                                id="lead-source"
                                value={selectedLeadSource}
                                onChange={handleLeadSourceChange}
                                >
                                <MenuItem value="exam1">Exam 1</MenuItem>
                                <MenuItem value="exam2">Exam 2</MenuItem>
                                
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="purpose-tes">Purpose of Test</InputLabel>
                            <Select 
                                label="Purpose of Test" 
                                id="purpose-test"
                                value={selectedPurposeOfTest}
                                onChange={handlePurposeOfTestChange}
                                >
                                <MenuItem value="exam1">Exam 1</MenuItem>
                                <MenuItem value="exam2">Exam 2</MenuItem>
                                
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4} lg={3}>
                    <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="advisor">P.Advisor</InputLabel>
                            <Select 
                                label="Advisor" 
                                id="advisor"
                                value={selectedAdvisor}
                                onChange={handleAdvisorChange}
                                >
                                <MenuItem value="exam1">Exam 1</MenuItem>
                                <MenuItem value="exam2">Exam 2</MenuItem>
                                
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} sm={6} md={4} lg={3}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={includeDate}
                                    onChange={() => setIncludeDate(!includeDate)}
                                />
                            }
                            label="Include Day"
                        />
                    </Grid>
                    {includeDate && (
                        <>
                            <Grid item xs={12} sm={6} md={4}>
                            <Grid container spacing={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid item xs={8}>
                                    <DatePicker
                                        label="From"
                                        views={['month', 'year']}
                                        fullWidth
                                        value={fromDateMonthYear}
                                        onChange={handleFromDateMonthYearChange}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" />
                                        )}
                                    />
                                    </Grid>
                                    <Grid item xs={4}>
                                    <DatePicker 
                                        label={'"day"'} 
                                        views={['day']}
                                        value={fromDateDay}
                                        onChange={handleFromDateDayChange}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" />
                                        )}
                                         />
                                          </Grid>
                                </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Grid container spacing={2}>
                               
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid item xs={8}>
                                    <DatePicker
                                        label="To"
                                        views={['month', 'year']}
                                        fullWidth
                                        value={toDateMonthYear}
                                        onChange={handleToDateMonthYearChange}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" />
                                        )}
                                    />
                                    </Grid>
                                    <Grid item xs={4}>
                                    <DatePicker 
                                        label={'"day"'} 
                                        views={['day']} 
                                        fullWidth
                                        value={toDateDay}
                                        onChange={handleToDateDayChange}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" />
                                        )}
                                        />
                                    </Grid>
                                </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </>
                    )}

        {!includeDate && (
                        <>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                            <Grid container spacing={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid item xs={12}>
                                    <DatePicker
                                        label="From"
                                        views={['month', 'year']}
                                        fullWidth
                                        value={fromDateMonthYear}
                                        onChange={handleFromDateMonthYearChange}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" />
                                        )}
                                    />
                                    </Grid>
                                   
                                </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4} lg={3}>
                                <Grid container spacing={2}>
                               
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid item xs={12}>
                                    <DatePicker
                                        label="To"
                                        views={['month', 'year']}
                                        fullWidth
                                        value={toDateMonthYear}
                                        onChange={handleToDateMonthYearChange}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" />
                                        )}
                                    />
                                    </Grid>
                                    
                                </LocalizationProvider>
                                </Grid>
                            </Grid>
                        </>
                    )}
                    

                    <Grid item xs={12} sm={6} md={4}>
                        <Button variant="contained" color="primary" onClick={handleApplyFilter}>
                            Apply Filter
                        </Button>
                   
                        <Button variant="outlined" color="secondary" onClick={handleResetFilter}>
                            Reset Filter
                        </Button>
                    </Grid>
                   
                </Grid>
            </MainCard>
   </>
  )
}

export default ReportFilter