import {
  Avatar,
  Backdrop,
  Box,
  Button,
  Card,
  Checkbox,
  Chip,
  CircularProgress,
  Divider,
  FormControlLabel,
  Grid,
  Stack,
  Typography,
  useTheme,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { gridSpacing } from "../../../store/constant";
import MainCard from "../../../components/ui-component/card/MainCard";
import EditIcon from "@mui/icons-material/Edit";
import PaymentsIcon from "@mui/icons-material/Payments";
import AddIcon from "@mui/icons-material/Add";
import { useNavigate } from "react-router-dom";
import { fDate } from "../../../../utils/formatTime";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function StudentListCard({
  students,
  setStudents,
  isLoading,
  studentsForCurrentPage,
}) {
  const userId = localStorage.getItem("userId");

  const theme = useTheme();
  const navigate = useNavigate();

  // const startIndex = (currentPage - 1) * selectedRowsPerPage ;
  // const endIndex = startIndex + selectedRowsPerPage;

  // Slice the array to get the students for the current page
  // const studentsForCurrentPage = students.slice();

  const handleViewMoreClick = (studentId, studentDetail) => {
    navigate(`/view-student/${studentId}`, { state: { studentDetail } });
  };

  const handleViewMore = (studentId, studentDetail) => {
    // Handle the "View More" action here
    // console.log('View More:', row);

    const url = `/view-student/${studentId}`;
    const newState = { studentDetail };

    // Open link in new tab
    const newTab = window.open(url, "_blank");

    // If new tab opened successfully, navigate with state data
    if (newTab) {
      newTab.onload = () => {
        newTab.history.replaceState(newState, "", url);
      };
    } else {
      // If new tab was blocked by browser, navigate with state data in current tab
      navigate(url, { state: newState });
    }
  };

  const handleAddPackage = (studentDetail) => {
    navigate(`/add-package`, { state: { studentDetail } });
  };

  const capitalizeFirstLetter = (str) => {
    return str?.charAt(0).toUpperCase() + str?.slice(1);
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Fetching
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
      <Grid container spacing={gridSpacing}>
        <Grid container spacing={2}>
          {(students.length === 0 && !isLoading) ? (
            // Display a message when no classes are found
            <Box textAlign="center" width="100%" mt={10}>
              <Typography variant="subtitle1">
                No students found. Please contact admin.
              </Typography>
            </Box>
          ) : (
            studentsForCurrentPage.map((studentDetail) => (
              <Grid item xs={12} md={6}>
                <Card
                  key={studentDetail.id}
                  sx={{
                    padding: "16px",
                    background:
                      theme.palette.mode === "dark"
                        ? theme.palette.dark.main
                        : theme.palette.grey[50],
                    border: "1px solid",
                    borderColor:
                      theme.palette.mode === "dark"
                        ? theme.palette.dark.main
                        : theme.palette.grey[100],
                    "&:hover": {
                      border: `1px solid${theme.palette.primary.main}`,
                    },
                  }}
                >
                  <Grid container spacing={2}>
                    <Grid item xs={3}>
                      <Avatar
                        variant="square"
                        src="https://www.shareicon.net/data/512x512/2016/05/24/770139_man_512x512.png"
                        sx={{ height: "100px", width: "100px" }}
                      />
                      <Box>
                        <Button
                          variant="outlined"
                          sx={{ color: "green" }}
                          size="small"
                        >
                          Upload Photo
                        </Button>
                      </Box>
                    </Grid>

                    <Grid item xs={9}>
                      <Grid container spacing={6}>
                        <Grid item xs={8} md={8}>
                          <Typography variant="h4" color="green">
                            {studentDetail.enquiry_id?.first_name
                              ? capitalizeFirstLetter(
                                  studentDetail.enquiry_id?.first_name
                                )
                              : ""}{" "}
                            {studentDetail.enquiry_id?.last_name
                              ? capitalizeFirstLetter(
                                  studentDetail.enquiry_id?.last_name
                                )
                              : ""}{" "}
                            {studentDetail.status_of_student === "active" ? (
                              <Chip
                                label={studentDetail.status_of_student}
                                color="secondary"
                                size="small"
                              />
                            ) : (
                              <Chip
                                label={studentDetail.status_of_student}
                                color="error"
                                size="small"
                              />
                            )}
                          </Typography>

                          <Box
                            display="flex"
                            justifyContent="flex-start"
                            mt={2}
                          >
                            <Box
                              display="flex"
                              justifyContent="flex-start"
                              flexDirection="column"
                            >
                              <Box mr={6}>
                                <Typography variant="h4">
                                  Enrollment No:{" "}
                                </Typography>
                                <Typography variant="h5">
                                  {" "}
                                  {studentDetail.lead_no}
                                </Typography>
                              </Box>
                              <Box mt={3}>
                                <Typography variant="h4">Branch:</Typography>
                                <Typography variant="h5">
                                  {studentDetail?.branch_id?.branch_name}
                                </Typography>
                              </Box>

                              {/* <Box>
               <Typography variant='h4'>Enrollment Date:</Typography>
                <Typography variant='h5'>{fDate(studentDetail.joining_date)}</Typography>
                </Box> */}
                            </Box>

                            <Box
                              display="flex"
                              justifyContent="flex-start"
                              flexDirection="column"
                            >
                              <Box>
                                <Typography variant="h4">
                                  Enrollment Date:
                                </Typography>
                                <Typography variant="h5">
                                  {fDate(studentDetail.joining_date)}
                                </Typography>
                              </Box>

                              <Box mt={3}>
                                <Typography variant="h4">Test Type:</Typography>
                                <Typography variant="h5">
                                  {studentDetail?.enquiry_id?.test_type_id?.name}
                                </Typography>
                              </Box>
                            </Box>
                          </Box>

                          {/*} <Box display='flex' justifyContent='flex-start'>
                <Box mr={7}>
               <Typography variant='h4'>Trainer name:</Typography>
                <Typography variant='h5'>
                {studentDetail.class_id?.trainer_id?.first_name ? capitalizeFirstLetter(studentDetail.class_id?.trainer_id?.first_name) : ''}{' '}
                {studentDetail.class_id?.trainer_id?.last_name ? capitalizeFirstLetter(studentDetail.class_id?.trainer_id?.last_name) : ''} </Typography>
                </Box>

                <Box>
               <Typography variant='h4'>Class:</Typography>
                <Typography variant='h5'>{studentDetail.class_id?.class_name}</Typography>
                </Box>
                </Box>

                <Box display='flex' justifyContent='flex-start'>
                <Box mr={6}>
               <Typography variant='h4'>Delivery mode:</Typography>
                <Typography variant='h5'>{studentDetail.class_id?.mode_of_delivery}</Typography>
              </Box>

                <Box>
               <Typography variant='h4'>Branch:</Typography>
                <Typography variant='h5'>{studentDetail.class_id?.branch_id?.branch_name}</Typography>
              </Box>*/}

                          <Box mt={2}>
                            <Button
                              variant="outlined"
                              sx={{ color: "green" }}
                              onClick={() =>
                                handleViewMore(studentDetail.id, studentDetail)
                              }
                            >
                              View More
                            </Button>
                          </Box>
                        </Grid>

                        <Grid item xs={4} md={4}>
                          <Stack direction="column">
                            <Box>
                              <Button
                                sx={{ color: "green" }}
                                startIcon={<EditIcon />}
                                onClick={() =>
                                  handleViewMore(
                                    studentDetail.id,
                                    studentDetail
                                  )
                                }
                              >
                                Edit
                              </Button>
                            </Box>

                            {/*} <Box>
                   <Button 
                        sx={{ color: 'green'}} 
                        startIcon={<AddIcon />}
                        onClick={() => handleAddPackage(studentDetail)}
                        >Add Package</Button>
            </Box>*/}

                            <Box>
                              <Button
                                sx={{ color: "green" }}
                                startIcon={<PaymentsIcon />}
                                onClick={() =>
                                  handleViewMore(
                                    studentDetail.id,
                                    studentDetail
                                  )
                                }
                              >
                                Payments
                              </Button>
                            </Box>

                            <Box>
                              <FormControlLabel
                                sx={{ color: "green" }}
                                control={
                                  <Checkbox disabled sx={{ color: "green" }} />
                                }
                                label="Enable Online Test Tab"
                              />
                            </Box>
                          </Stack>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Card>
              </Grid>
            ))
          )}
        </Grid>
      </Grid>
    </>
  );
}

export default StudentListCard;