import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import { Box, Button, Chip, CircularProgress, Collapse, Dialog, DialogActions, DialogContent, DialogTitle, Grid, IconButton, TextField, Typography, styled } from '@mui/material';
import MainCard from '../../components/ui-component/card/MainCard';
import NavCollapse from '../../layout/MainLayout/Sidebar/menuList/NavCollapse';
import FilterListIcon from '@mui/icons-material/FilterList';
import { fDate } from '../../../utils/formatTime';
import { useNavigate } from 'react-router-dom';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;


const PaymentsDataGrid = () => {
    const userId = localStorage.getItem('userId');
    const navigate = useNavigate()
    const [isFilterOpen, setFilterOpen] = useState(false);
    const [open, setOpen] = useState(false);
    const [payments, setPayments] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [dialogPayments, setDialogPayments] = useState([]);

    const handleClose = () => {
      setOpen(false);
    };
  
    const handleViewPayments = (studentId) => {
      console.log(studentId);
      setOpen(true);
      setIsLoading(true);

      fetch(`${API_ENDPOINT}route/payment/${studentId}`)
    .then((res) => res.json())
    .then((data) => {
      setDialogPayments(data);
      setIsLoading(false)
    })
    .catch((error) => {
      console.error('Error fetching student payments:', error);
      setIsLoading(false)
    })
    };
  

    const toggleFilterSection = () => {
      setFilterOpen(!isFilterOpen);
    };

  const columns = [
    { field: 'enrollID', 
    headerName: 'Enrollment Id', 
    flex: 1, 
    headerClassName: 'super-app-theme--header',
    renderCell: (params) => 
       <div>
       {params.row.student_id?.lead_no} 
       </div>
         },
    { field: 'studentName', 
    headerName: 'Student Name', 
    flex: 1,
    headerClassName: 'super-app-theme--header',
    renderCell: (params) => 
    <div>
    {params.row.student_id?.first_name} {params.row.student_id?.last_name}
  </div>
   },
   { field: 'class_id', 
   headerName: 'Class', 
   flex: 3, 
   headerClassName: 'super-app-theme--header',
   renderCell: (params) => 
      <div>
      {params.row.class_id?.class_name} 
      </div>
        },
        { field: 'package_id', 
        headerName: 'Package', 
        flex: 1, 
        headerClassName: 'super-app-theme--header',
        renderCell: (params) => 
      <div>
      {params.row.package_id?.package_name} 
    </div>
       },
    { field: 'installment', headerName: 'Installments Payable', flex: 1 },
    { field: 'amount', headerName: 'Amount Paid', flex: 1 },
    { field: 'createdAt', 
    headerName: 'Payment Date', 
    flex: 1, 
    headerClassName: 'super-app-theme--header',
    renderCell: (params) => 
      <div>
      {fDate(params.value)} 
    </div>
   },
    {
      field: 'payment_status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value === 'paid' ? 'Paid' : 'Pending'}
          color={params.value === 'paid' ? 'secondary' : 'error'}
        />
      ),
    },
    {
      field: 'viewPayments',
      headerName: 'Action',
      flex: 1.5,
      renderCell: (params) => (
        <Button
          variant="contained"
          color="secondary"
          onClick={() => handleViewPayments(params.row.student_id?.id)}
        >
          View Payments
        </Button>
      ),
    },
  ];

  const columnsPaidTransactions = [
    { field: 'createdAt', 
    headerName: 'Payment Date', 
    flex: 1, 
    headerClassName: 'super-app-theme--header',
    renderCell: (params) => 
      <div>
      {fDate(params.value)} 
    </div>
   },
    { field: 'amount', headerName: 'Amount', flex: 1 },
    { field: 'payment_mode', headerName: 'Payment Mode', flex: 1 },
    {
      field: 'payment_status',
      headerName: 'Status',
      flex: 1,
      renderCell: (params) => (
        <Chip
          label={params.value === 'paid' ? 'Paid' : 'Pending'}
          color={params.value === 'paid' ? 'secondary' : 'error'}
        />
      ),
    },
  ];

  const columnsBalanceDetails = [
    { field: 'dateDue', headerName: 'Date Due', flex: 1 },
    { field: 'amount', headerName: 'Amount', flex: 1 },
    { field: 'status', headerName: 'Status', flex: 1 },
  ];

  const StyledGridOverlay = styled('div')(({ theme }) => ({
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    height: '100%',
    '& .ant-empty-img-1': {
      fill: theme.palette.mode === 'light' ? '#aeb8c2' : '#262626',
    },
    '& .ant-empty-img-2': {
      fill: theme.palette.mode === 'light' ? '#f5f5f7' : '#595959',
    },
    '& .ant-empty-img-3': {
      fill: theme.palette.mode === 'light' ? '#dce0e6' : '#434343',
    },
    '& .ant-empty-img-4': {
      fill: theme.palette.mode === 'light' ? '#fff' : '#1c1c1c',
    },
    '& .ant-empty-img-5': {
      fillOpacity: theme.palette.mode === 'light' ? '0.8' : '0.08',
      fill: theme.palette.mode === 'light' ? '#f5f5f5' : '#fff',
    },
  }));
  
  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <svg
          style={{ flexShrink: 0 }}
          width="240"
          height="200"
          viewBox="0 0 184 152"
          aria-hidden
          focusable="false"
        >
          <g fill="none" fillRule="evenodd">
            <g transform="translate(24 31.67)">
              <ellipse
                className="ant-empty-img-5"
                cx="67.797"
                cy="106.89"
                rx="67.797"
                ry="12.668"
              />
              <path
                className="ant-empty-img-1"
                d="M122.034 69.674L98.109 40.229c-1.148-1.386-2.826-2.225-4.593-2.225h-51.44c-1.766 0-3.444.839-4.592 2.225L13.56 69.674v15.383h108.475V69.674z"
              />
              <path
                className="ant-empty-img-2"
                d="M33.83 0h67.933a4 4 0 0 1 4 4v93.344a4 4 0 0 1-4 4H33.83a4 4 0 0 1-4-4V4a4 4 0 0 1 4-4z"
              />
              <path
                className="ant-empty-img-3"
                d="M42.678 9.953h50.237a2 2 0 0 1 2 2V36.91a2 2 0 0 1-2 2H42.678a2 2 0 0 1-2-2V11.953a2 2 0 0 1 2-2zM42.94 49.767h49.713a2.262 2.262 0 1 1 0 4.524H42.94a2.262 2.262 0 0 1 0-4.524zM42.94 61.53h49.713a2.262 2.262 0 1 1 0 4.525H42.94a2.262 2.262 0 0 1 0-4.525zM121.813 105.032c-.775 3.071-3.497 5.36-6.735 5.36H20.515c-3.238 0-5.96-2.29-6.734-5.36a7.309 7.309 0 0 1-.222-1.79V69.675h26.318c2.907 0 5.25 2.448 5.25 5.42v.04c0 2.971 2.37 5.37 5.277 5.37h34.785c2.907 0 5.277-2.421 5.277-5.393V75.1c0-2.972 2.343-5.426 5.25-5.426h26.318v33.569c0 .617-.077 1.216-.221 1.789z"
              />
            </g>
            <path
              className="ant-empty-img-3"
              d="M149.121 33.292l-6.83 2.65a1 1 0 0 1-1.317-1.23l1.937-6.207c-2.589-2.944-4.109-6.534-4.109-10.408C138.802 8.102 148.92 0 161.402 0 173.881 0 184 8.102 184 18.097c0 9.995-10.118 18.097-22.599 18.097-4.528 0-8.744-1.066-12.28-2.902z"
            />
            <g className="ant-empty-img-4" transform="translate(149.65 15.383)">
              <ellipse cx="20.654" cy="3.167" rx="2.849" ry="2.815" />
              <path d="M5.698 5.63H0L2.898.704zM9.259.704h4.985V5.63H9.259z" />
            </g>
          </g>
        </svg>
        <Box sx={{ mt: 1 }}>No Data</Box>
      </StyledGridOverlay>
    );
  }

  useEffect(() => {
    fetch(`${API_ENDPOINT}payment/byuser/${userId}`)
      .then((res) => res.json())
      .then((data) => {
        const sortedPayments = data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        
        setPayments(sortedPayments);
       // setStudents(data);
        setIsLoading(false);
      })
      .catch((error) => {
        console.error('Error fetching payments:', error);
       // showSnackbar('Error fetching enquiries demos:', 'error');
        navigate('/page404')
        setIsLoading(false)
      })
  }, []);

  
  return (
    <>
                <Grid container spacing={3}>

            <Grid item xs={12}>
            <MainCard >
                <Box display='flex' justifyContent='space-between'>
                    <Box>
                <Typography variant="h3">Student Payments</Typography>
                </Box>
                <Box>
            <IconButton onClick={toggleFilterSection}>
            <FilterListIcon />
            </IconButton>
            </Box>
            </Box>
            <Collapse in={isFilterOpen}>
            <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
            label="Enroll ID"
            variant="outlined"
           // value={filters.enrollId}
            //onChange={handleFilterChange('enrollId')}
            fullWidth
            />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
            label="Student Name"
            variant="outlined"
            //value={filters.studentName}
           // onChange={handleFilterChange('studentName')}
            fullWidth
            />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
            label="Class Name"
            variant="outlined"
            //value={filters.className}
            //onChange={handleFilterChange('className')}
            fullWidth
            />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
            label="Package Name"
            variant="outlined"
           // value={filters.trainerName}
           // onChange={handleFilterChange('trainerName')}
            fullWidth
            />
            </Grid>
            <Grid item xs={12} sm={6} md={4} lg={3}>
            <TextField
            label="Status"
            variant="outlined"
           // value={filters.status}
           // onChange={handleFilterChange('status')}
            fullWidth
            />
            </Grid>
            <Grid item xs={12}>
            <Button variant="contained" color="secondary" >
            Apply Filters
            </Button>

            <Button variant="outlined" color="secondary" >
                Reset Filters
                </Button>
            </Grid>
            </Grid>
            </Collapse>
            </MainCard>

</Grid>

      {isLoading ? (
        <Box display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh">
        <CircularProgress />
        </Box>
      ) : (
       
          payments.length === 0 ? (
            // Display a message when no classes are found
            <Box textAlign="center" width="100%" mt={10}>
              <Typography variant="subtitle1">
                No payments found.
              </Typography>
            </Box>
          ) : (
<Grid item xs={12}>
    <div style={{ height: 'auto', width: '100%' }}>
      <DataGrid
        rows={payments}
        columns={columns}
        pageSize={5}
        rowsPerPageOptions={[5, 10, 20]}
        slots={{ 
            noRowsOverlay: CustomNoRowsOverlay,
            toolbar: GridToolbar,
         }}
         slotProps={{
            toolbar: {
                exportButton: {
                  csvOptions: {
                    fileName: 'attendance_report', 
                  },
                },
              },
         }}
        sx={{ '--DataGrid-overlayHeight': '300px' ,
            backgroundColor: 'rgba(255,255,255)',
            boxShadow: 2,
            border: 2,
            borderColor: 'primary.light',
            '& .MuiDataGrid-cell:hover': {
              color: 'primary.main',
            },
          }}
      />
    </div>
    </Grid>
          )
      )}
    
    </Grid>

    <Dialog open={open} onClose={handleClose} maxWidth="lg" fullWidth>
        <DialogTitle variant='h3'>Student Payments Details</DialogTitle>
        <DialogContent>
         
          <Typography variant='h4'>Paid Transactions</Typography>

          {isLoading ? (
        <Box display="flex"
        justifyContent="center"
        alignItems="center"
        minHeight="100vh">
        <CircularProgress />
        </Box>
      ) : (
       
          dialogPayments.length === 0 ? (
            // Display a message when no classes are found
            <Box textAlign="center" width="100%" mt={10}>
              <Typography variant="subtitle1">
                No payments found.
              </Typography>
            </Box>
          ) : (
          <div style={{ marginBottom: 20 }}>
            <DataGrid
              rows={dialogPayments}
              columns={columnsPaidTransactions}
              pageSize={5}
              autoHeight
              rowsPerPageOptions={[5, 10, 20]}
              slots={{ toolbar: GridToolbar }}
            />
          </div>
          )
      )}
          {/* DataGrid for balance details
          <Typography variant='h4'>Balance</Typography>
          <div >
            <DataGrid
              rows={dummyBalanceData}
              columns={columnsBalanceDetails}
              pageSize={5}
              autoHeight
              rowsPerPageOptions={[5, 10, 20]}
              slots={{ toolbar: GridToolbar }}
            />
          </div> */}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PaymentsDataGrid;
