import React, { useState } from 'react'
import CustomSnackbar from '../../newTestPrep/components/ui-component/snackbar/CustomSnackbar';
import { Button, Grid, TextField, Typography } from '@mui/material';

export default function RestartDiscount({selectedid,discountData , setDiscountdata, onClose}) {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT; 
    const userId = localStorage.getItem("userId");
    const userRole = localStorage.getItem("userRole");
 
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
    const [endDateError, setEndDateError] = useState('');
 const [enddate,setEnddate] =useState('')
    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };
    const handleRestart = () => {
        // Assuming discountId is passed as an argument to the function
        if (!enddate) {
            setEndDateError("End Date is required");
            return; // Exit function if end date is not selected
        } else {
            setEndDateError(""); // Clear the error message if end date is selected
        }

        let status;
        if (userRole === "admin" || userRole==="center_admin") {
          status = "accepted";
        } else if (userRole === "advisor" || userRole==="trainer") {
          status = "pending";
        } else {
          // Handle other roles if needed
          return; // Exit function for roles other than admin and advisor
        }
        fetch(`${API_ENDPOINT}discoutform/${selectedid}`, {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            status: status,
            user_id: parseInt(userId),
            end_date:enddate,
          }),
        })
          .then((response) => {
            if (response.ok) {
              // Handle success
              showSnackbar("Discount Restart successfully", "success");
              const updatedDiscountData = discountData.map((discount) => {
                if (discount.id === selectedid) {
                  return { ...discount, status: status, end_date:enddate };
                }
                return discount;
              });
              setDiscountdata(updatedDiscountData);
              setTimeout(() => {
                // Close the popup here
                onClose();
              }, 1500);
              // Here you might want to update the UI accordingly, for example, removing the cancelled discount from the state
            } else {
              // Handle error responses
              showSnackbar("Unauthorised user", "error");
              setTimeout(() => {
                // Close the popup here
                onClose();
              }, 1500);
            }
          })
          .catch((error) => {
            console.error("Error restarting  discount:", error);
            showSnackbar("Failed to restart discount", "error");
            setTimeout(() => {
                // Close the popup here
                onClose();
              }, 1500);
          });
      };

      const handleDatechange=(e)=>{
        setEnddate(e.target.value)
        setEndDateError("");
      }
  return (
    <>
    <Grid container spacing={2}>
       <Grid item xs={12} lg={12}>
          <Typography sx={{ fontWeight: 600 }}>
            End Date <span style={{ color: "red", fontSize: "18px" }}>*</span>
          </Typography>
          <TextField
              value={enddate}
              onChange={handleDatechange}
                InputProps={{
                  style: { borderRadius: "14px" },
                }}
                type="date"
                fullWidth
                
              />
            
            {endDateError && (
                        <Typography variant="body2" color="error">
                            {endDateError}
                        </Typography>
                    )}
        </Grid>

        <Grid item xs={12} lg={12} sx={{ textAlign: "end" }}>
          <Button
            variant="contained"
            color="error"
            style={{
              width: "120px",
              borderRadius: "14px",
              fontWeight: 500,
              fontSize: "16px",
              marginRight: "8px",
            }}
             onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{
              width: "120px",
              borderRadius: "14px",
              fontWeight: 500,
              fontSize: "16px",
            }}
            onClick={handleRestart}
          >
            Submit
          </Button>
        </Grid>
        </Grid>
    <CustomSnackbar
    open={snackbarOpen}
    onClose={() => setSnackbarOpen(false)}
    message={snackbarMessage}
    severity={snackbarSeverity}
  />
  </>
  )
}
