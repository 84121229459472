import { useDispatch, useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';

// material-ui
import { styled, useTheme } from '@mui/material/styles';
import { AppBar, Box, CssBaseline, Toolbar, useMediaQuery } from '@mui/material';

// project imports
import Breadcrumbs from '../../components/ui-component/breadcrumbs/Breadcrumbs';
import Header from './Header';
import Sidebar from './Sidebar';
//import Customization from '../Customization';
import navigation from 'menu-items';
import { drawerWidth } from '../../store/constant';
import { SET_MENU } from '../../store/actions';
import MenuIcon from '@mui/icons-material/Menu';
import DragHandleIcon from '@mui/icons-material/DragHandle';
import { useEffect, useState } from 'react';
import PersistentNotification from '../../../components/notification/PersistentNotification';
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT


// styles
const Main = styled('main', { shouldForwardProp: (prop) => prop !== 'open' })(({ theme, open }) => ({
  ...theme.typography.mainContent,
  borderBottomLeftRadius: 0,
  borderBottomRightRadius: 0,
  transition: theme.transitions.create(
    'margin',
    open
      ? {
          easing: theme.transitions.easing.easeOut,
          duration: theme.transitions.duration.enteringScreen
        }
      : {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen
        }
  ),
  [theme.breakpoints.up('md')]: {
    marginLeft: open ? 0 : -(drawerWidth - 20),
    width: `calc(100% - ${drawerWidth}px)`
  },
  [theme.breakpoints.down('md')]: {
    marginLeft: '20px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px'
  },
  [theme.breakpoints.down('sm')]: {
    marginLeft: '10px',
    width: `calc(100% - ${drawerWidth}px)`,
    padding: '16px',
    marginRight: '10px'
  }
}));


// ==============================|| MAIN LAYOUT ||============================== //

const MainLayout = ( {children, socket}) => {
  const userId = localStorage.getItem('userId');
  const theme = useTheme();
  const matchDownMd = useMediaQuery(theme.breakpoints.down('md'));
  // Handle left drawer
  const leftDrawerOpened = useSelector((state) => state.customization.opened);
  const dispatch = useDispatch();
  const handleLeftDrawerToggle = () => {
    dispatch({ type: SET_MENU, opened: !leftDrawerOpened });
  };

  console.log(leftDrawerOpened)

  const [showPersistentNotification, setShowPersistentNotification] = useState(false);
  const [priorityNotifications, setPriorityNotifications] = useState([]);

  const fetchPriorityNotifications = async () => {
    try {
      const response = await fetch( `${API_ENDPOINT}notification/notificationbyuser/${userId}`);
      const data = await response.json();

       // Filter priority notifications based on the 'notification_priority' field
    const priorityNotifications = data.filter(notification => notification.notification_priority);

    return priorityNotifications;
    } catch (error) {
      console.error('Error fetching priority notifications:', error);
      return []; // Return an empty array or handle the error as needed
    }
  };

  useEffect(() => {
    // Fetch priority notifications when the component mounts
    fetchPriorityNotifications()
      .then((data) => setPriorityNotifications(data))
      .catch((error) => console.error('Error fetching priority notifications:', error));
  }, []);

  // Check if priorityNotifications is not an empty array
    const hasPriorityNotifications = priorityNotifications.length > 0;

  // Show the persistent notification if a matching priority notification is found
  useEffect(() => {
    if (hasPriorityNotifications) {
      setShowPersistentNotification(true);
    }
  }, [hasPriorityNotifications]);

  const handleMarkRead = () => {
    // Logic to mark notification as read
    setShowPersistentNotification(false); // Hide the persistent notification
  };
  

  return (
    
    <Box sx={{ display: 'flex' }}>
      <CssBaseline />
      {/* header */}
      <AppBar
        enableColorOnDark
        
        position="fixed"
        color="inherit"
        elevation={0}
        sx={{
          bgcolor: theme.palette.background.default,
          transition: leftDrawerOpened ? theme.transitions.create('width') : 'none',
            height: 75
        }}
      >
        <Toolbar>
          <Header handleLeftDrawerToggle={handleLeftDrawerToggle} socket={socket} />
        </Toolbar>
      </AppBar>

      {/* drawer */}
      <Sidebar drawerOpen={!matchDownMd ? leftDrawerOpened : !leftDrawerOpened} drawerToggle={handleLeftDrawerToggle} />

      {/* main content */}
     {/*} <Main theme={theme} open={leftDrawerOpened}>
        {/* breadcrumb 
        <Breadcrumbs separator={MenuIcon}  icon title rightAlign />
        <Outlet />
      </Main> */}

        <Main theme={theme} open={leftDrawerOpened}>
        {/* breadcrumb */}
        <Breadcrumbs separator={DragHandleIcon} navigation={navigation} icon title rightAlign />
        <PersistentNotification
                  message="You have an unread priority notification"
                  open={showPersistentNotification}
                  handleClose={() => setShowPersistentNotification(false)}
                  handleMarkRead={handleMarkRead}
                />
       {children}
      </Main>

      
     
    </Box>
   
  );
};

export default MainLayout;