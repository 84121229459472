


// PackageCard.js

import React from 'react';
import PropTypes from 'prop-types';
import { Button, Grid, Stack, Typography } from '@mui/material';
import MainCard from '../../components/Maincard';

const PackageCard = (props) => {
  return (

     <>
         
          <Stack spacing={1}>
            <Typography sx={{ color: '#777', fontFamily: 'Poppins', fontSize: '16px', fontStyle: 'normal', fontWeight: 400 }}>
              {props.subtitle}
            </Typography>
            <Stack display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
              <Stack display='flex' flexDirection='row' alignContent='center'>
                <img src='/PackagePrice.svg' />
                <Typography sx={{ color: '#5C5C5C', fontFamily: 'Poppins', fontSize: '12px', fontStyle: 'normal', fontWeight: 400 }}>
                  Price {props.price}
                </Typography>
              </Stack>
              <Button style={{ width: '100px', height: '25px', borderRadius: '7px', background: '#019E6F', color: '#FFF', fontFamily: 'Poppins', fontSize: '12px', fontStyle: 'normal', fontWeight: 400 }}>
                {props.purchases} Purchases
              </Button>
            </Stack>
         
          </Stack>
        
          </>

  );
};


export default PackageCard;

 
