import { Box, Button, Dialog, DialogContent, Grid, Typography } from '@mui/material';
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from "styled-components";
import RequestDemoForm from '../../register/RequestDemo';

const Hero = () => {
  const navigate = useNavigate()
  const [openDialog, setOpenDialog] = useState(false);

  const handleGetStarted = () => {
    navigate('/center-registration')
  }

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

 

  return (
    <Box sx={{height:'100%'}}
    >
    <Grid container spacing={2}>
        <Grid item xs={12}>
       
                <Grid container spacing={2}>
                    <Grid item xs={12} md={6} lg={7}>
                    <Box
                display="flex"
                flexDirection="column"
                alignItems='flex-start'
                justifyContent="center"
                height="100%"
                padding='100px'
              >
                        <Typography   sx={{color:'#000' , fontFamily:'Poppins' , fontSize:{xl:'50px' , lg:'45px'} , fontStyle:'normal', fontWeight:600}}>
                        Automated with the
best CRM for your coaching
                        </Typography>
                        <Typography mt={5} sx={{color:'#000' , fontFamily:'Poppins' ,  fontSize: {
          // xs: '10px',  // for extra-small screens
          // sm: '25px',  // for small screens
          // md: '30px',  // for medium screens
          lg: '22px',  // for large screens screen is above 90
          xl: '30px',  // for extra-large screens screen is 50 to 90%
        }, fontStyle:'normal' , fontWeight:400}} >If you are a coaching centre teaching any subject around India, you have
        a space to accommodate students, both online and offline, SIEC
        Test Masters is the best crm to automate simple functions
        and bring lead generation to your centre.</Typography>

                        <Box display='flex' mt={10} >
                        <Button variant='outlined' style={{borderColor:'#000000', color:'#000000',width:'200px', borderRadius:'10px', fontWeight:500,fontSize:'20px'}} onClick={handleOpenDialog}> Request Demo</Button>
                            <Box m={2} />
                     
                            <Button variant='contained' style={{width:'200px',borderRadius:'10px',fontWeight:500,fontSize:'20px', background:'#019E6F'}} onClick={handleGetStarted}>Get Started</Button>
                            </Box>
                        </Box>
                    </Grid>

                   
                <Grid item xs={12} md={6} lg={5} mt={4} >
           
             <Box width='550px'>
              <img src='/crm-page 1.png' style={{width:'100%'}}></img>
              </Box>
          

            </Grid>

    


                </Grid>
            
           
           
        </Grid>

        <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogContent>
          <RequestDemoForm />
        </DialogContent>
      </Dialog>
    </Grid>
    </Box>
  )
}

const HeroWrapper = styled.div`
  background: url('/landing.jpg') center/cover no-repeat;
  height: '100%';

  .container{
    .hero-content{
      background-color: var(--clr-white);
      max-width: 400px;
      width: 100%;
     
      margin-top: 10;
     
      padding: 20px;

      h1{
        font-size: 32px;
        margin-bottom: 5px;
        white-space: nowrap;
        color: var(--clr-white);
      }
      p{
        font-size: 20px;
        color: white
      }
    }
  }
`;

export default Hero