// Author : Ayesha Shaikh
//  Date : 03 Oct 2024

import {
  Box,
  Button,
  Checkbox,
  Divider,
  Grid,
  styled,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { fDate } from "../../../utils/formatTime";
import EmployeeEnquaryFilter from "./EmployeeEnquiryFilter";
import { Person as PersonIcon } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";
export default function EmployeeEnquiryData({
  enquiriData,
  setEnquiryData,
  enquiryOriginalData,
  setLoading,
  selectedId,
  selectedtransferIds,
  setSelectedTransferIds,
  isSelectAllChecked,
  setIsSelectAllChecked,
}) {
  const navigate = useNavigate();

  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 100,
  });

  const [visibleRowIds, setVisibleRowIds] = useState([]); // Store current page's row IDs
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [prevPageSize, setPrevPageSize] = useState(pagination.pageSize);

  // Upadate current page ids when pagination is chnages
  useEffect(() => {
    setPrevPageSize(pagination.pageSize);

    // new pagesize is less than prev page size then deselct all selected ids first
    if (pagination.pageSize !== prevPageSize) {
      setSelectedTransferIds([]);
      return;
    }

    // Update visible row IDs when pagination changes
    const currentPageRows = enquiriData.slice(
      pagination.page * pagination.pageSize,
      (pagination.page + 1) * pagination.pageSize
    );
    const visibleIds = currentPageRows.map((row) => row.id);
    setVisibleRowIds(visibleIds);

     // If there are no visible rows, set SelectAllChecked to false
     if (visibleIds.length === 0) {
      setIsSelectAllChecked(false);
      setIsIndeterminate(false);
      return;
    }


    // Check if all visible rows are selected
    const allVisibleSelected = visibleIds.every((id) =>
      selectedtransferIds.includes(id)
    );
    const someVisibleSelected = visibleIds.some((id) =>
      selectedtransferIds.includes(id)
    );

    setIsSelectAllChecked(allVisibleSelected);
    setIsIndeterminate(someVisibleSelected && !allVisibleSelected);
  }, [pagination, enquiriData, selectedtransferIds]);

  // HandleSelectAllCheckBox
  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setIsSelectAllChecked(checked);

    if (checked) {
      // Select all visible row IDs
      setSelectedTransferIds((prevSelectedIds) => [
        ...new Set([...prevSelectedIds, ...visibleRowIds]),
      ]);
    } else {
      // Deselect all visible row IDs
      setSelectedTransferIds((prevSelectedIds) =>
        prevSelectedIds.filter((id) => !visibleRowIds.includes(id))
      );
    }
  };

  // Individual CheckBox
  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;

    setSelectedTransferIds((prevSelectedIds) => {
      if (checked) {
        const newSelected = [...prevSelectedIds, id];
        // Check if all visible rows are selected after this checkbox is checked
        const allVisibleSelected = visibleRowIds.every((rowId) =>
          newSelected.includes(rowId)
        );
        setIsSelectAllChecked(allVisibleSelected);
        setIsIndeterminate(!allVisibleSelected);

        return newSelected;
      } else {
        // Deselect the row ID
        const newSelected = prevSelectedIds.filter((rowId) => rowId !== id);
        setIsSelectAllChecked(false);
        setIsIndeterminate(
          visibleRowIds.some((rowId) => newSelected.includes(rowId))
        );

        return newSelected;
      }
    });
  };

  const columns = [
    {
      field: "selection",
      sortable: false,
      renderHeader: (params) => (
        <Checkbox
          size="small"
          checked={isSelectAllChecked}
          indeterminate={isIndeterminate}
          onChange={handleSelectAllChange}
        />
      ),

      width: 70,
      renderCell: (params) => {
        const isChecked = selectedtransferIds.includes(params.row.id);
        return (
          <Checkbox
            size="small"
            checked={isChecked}
            onChange={(event) => handleCheckboxChange(event, params.row?.id)}
          />
        );
      },
    },
    {
      field: "name",
      headerName: "Enquiry Name",
      width: 150,
      renderCell: (params) => (
        <div>
          {/* {params.row?.first_name} {params.row?.last_name} */}
          <Button
            size="small"
            onClick={() =>
              window.open(`/enquirydetails/${params?.row?.id}`, "_blank")
            }
          >
            <PersonIcon /> {params.row.first_name} {params.row.last_name}
          </Button>
        </div>
      ),
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 150,
      headerClassName: "bold-header",
      renderCell: (params) => (
        <div>
          {params.row?.mobile_country_code}
          {params.row?.mobile}
        </div>
      ),
    },
    {
      field: "connected_call",
      headerName: "Cont.",
      width: 50,
      headerClassName: "bold-header",
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "not_connected_call",
      headerName: "N-Cont",
      width: 70,
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "total_open_task",
      headerName: "O.T",
      width: 50,
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "enquiry_date",
      headerName: "Enq Date",
      width: 100,
      renderCell: (params) => <div>{fDate(params.value)}</div>,
    },
    {
      field: "last_follow_up_date",
      headerName: "Last FollowUp ",
      width: 100,
      renderCell: (params) => <div>{fDate(params.value)}</div>,
    },
    {
      field: "next_follow_up_date",
      headerName: "Next FollowUp ",
      width: 100,
      renderCell: (params) => <div>{params.value}</div>,
    },
    {
      field: "prospective_level_id",
      headerName: "Prospective Level",
      width: 150,
      valueFormatter: (params) => params.value?.prospective_level || "",
    },
    {
      field: "recyclereson",
      headerName: "Recycle Reason",
      width: 200,
      renderCell: (params) => (
        <div>{params.row?.recycle_reason_id?.recycle_reason}</div>
      ),
    },
    {
      field: "nearest_branch_id",
      headerName: "Nearest Branch",
      width: 150,
      renderCell: (params) => (
        <div>{params.row.nearest_branch_id?.branch_name}</div>
      ),
    },
    {
      field: "sub_service_id",
      headerName: "Sub Service",
      width: 150,
      valueFormatter: (params) => params.value?.name || "",
    },

    {
      field: "campaign",
      headerName: "Campaign",
      width: 350,
      renderCell: (params) => (
        <div>{params.row?.campaign_id?.campaign_name}</div>
      ),
    },

    {
      field: "assign_to",
      headerName: "P.Advisor",
      width: 100,
      renderCell: (params) => <div>{params.row.assign_to?.first_name}</div>,
    },
    {
      field: "co_assign_to",
      headerName: "Co-Assignee",
      width: 100,
      renderCell: (params) => (
        <div>
          {params.row.co_assignee?.first_name}{" "}
          {params.row.co_assignee?.last_name}
        </div>
      ),
    },
    {
      field: "lead_source_type_id",
      headerName: "LS",
      width: 100,
      valueFormatter: (params) => params.value?.name || "",
    },
    {
      field: "stage_id",
      headerName: "Stage",
      width: 100,
      valueFormatter: (params) => params.value?.stage_name || "",
    },
    {
      field: "enrolled_date",
      headerName: "Enrolled date",
      width: 100,
      renderCell: (params) => (
        <div>{params.row?.enrolled_detail?.enrollment_date} </div>
      ),
    },

    {
      field: "enrolled_by",
      headerName: "Enrolled By",
      width: 150,
      renderCell: (params) => (
        <div>
          {params.row.enrolled_by?.first_name}{" "}
          {params.row.enrolled_by?.last_name}
        </div>
      ),
    },
  ];
  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }

  return (
    <>
      <Grid item xs={12} sm={8.5}>
        <Box
          sx={{
            height: "600px",
            overflow: "auto",
            width: "100%",
            "& .header-cell": {
              backgroundColor: "#abf7b1",
              fontWeight: 600,
              borderRight: "0.1px solid #C3C3C3",
            },
          }}
        >
          <DataGrid
          autoHeight
            rows={enquiriData}
            columns={columns}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            pageSize={pagination.pageSize}
            onPaginationModelChange={(model) => setPagination(model)}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Box>
      </Grid>

      <Divider orientation="vertical" flexItem sx={{ marginLeft: "15px" }} />
      <Grid item xs={12} sm={3.3}>
        <EmployeeEnquaryFilter
          setEnquiryData={setEnquiryData}
          enquiryOriginalData={enquiryOriginalData}
          setLoading={setLoading}
          selectedId={selectedId}
        />
      </Grid>
    </>
  );
}
