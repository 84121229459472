import {
  Backdrop,
  Box,
  Button,
  Card,
  CardContent,
  CircularProgress,
  Grid,
  Stack,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";

import { useNavigate } from "react-router-dom";

import CreateLessonPopup from "./LessonPopup";
import LessonplanForm from "./LessonplanForm";
import LessonPlanFilter from "./LessonPlanFilter";
import {useGetTokenOrLogout} from "../../utils/token"


const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const CustomIcon = styled("img")({
  width: "15px",
  height: "15px",
  // position: 'fixed',
  // top: 0,
  // left: 0,
});
function LessonPlan() {
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
 
  const [classes, setClasses] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const navigate = useNavigate();
  const [openPopup, setOpenPopup] = useState(false);
  const [selectedClass, setSelectedClass] = useState(null);
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [originalData, setOriginalData] = useState([]);
  console.log(selectedClass);
  const handleClosePopup = () => {
    setOpenPopup(false);
  };
  const handleOpenPopup = () => {
    setOpenPopup(true);
  };

  const handleViewLessonPlan = (classId, selectedClass) => {
    navigate(`/lessonplanlist/${classId}`, { state: { selectedClass } });
  };

  useEffect(() => {
    setIsLoading(true);

    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}route/fetchclass/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();

          let filteredData = [...data];

          if (filterCriteria) {
            filteredData = filteredData.filter((classItem) => {
              let passesFilter = true;

              if (filterCriteria.newClassName.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newClassName.includes(classItem?.id);
              }

              if (filterCriteria.newTrainerName.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newTrainerName.includes(
                    classItem.trainer_id?.id
                  );
              }

              if (filterCriteria.newBranch.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newBranch.includes(classItem.branch_id?.id);
              }

              if (filterCriteria.newModes.length > 0) {
                passesFilter =
                  passesFilter &&
                  filterCriteria.newModes.includes(classItem?.mode_of_delivery);
              }

              if (filterCriteria.createfrom && filterCriteria.createdto) {
                const startDate = filterCriteria.createfrom;
                const endDate = filterCriteria.createdto;
                const createddate = new Date(classItem?.createdAt);
                const formattedDate = createddate.toISOString().slice(0, 10);
                console.log("formated date is", createddate);
                passesFilter =
                  passesFilter &&
                  formattedDate >= startDate &&
                  formattedDate <= endDate;
              }
              return passesFilter;
            });
          }
          setClasses(filteredData);
          //setFilteredData(data);
          setIsLoading(false);
          setOriginalData(data);
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch {
        navigate("/page500");
      }
    };

    fetchData();
  }, [filterCriteria, userId]);

  const handleApplyFilter = (criteria) => {
    setFilterCriteria(criteria);
  };
  const handleResetFilter = () => {
    setFilterCriteria(null);
    setClasses(originalData);
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Box mb={2}>
        <LessonPlanFilter
          onApplyFilter={handleApplyFilter}
          onResetFilter={handleResetFilter}
        />
      </Box>

      {classes.length === 0 && !isLoading ? (
        // Display a message when no classes are found
        <Box textAlign="center" width="100%" mt={10}>
          <Typography variant="subtitle1">No Classes found.</Typography>
        </Box>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography
              sx={{
                color: "#000000",
                fontFamily: '"Poppins-Medium", Helvetica',
                fontSize: "32px",
              }}
              fontWeight={500}
            ></Typography>
          </Grid>
          {classes.map((classe, index) => (
            <Grid item xs={12} lg={4} md={6} sm={6}>
              <Card
                variant="outlined"
                sx={{
                  borderColor: "black", // Replace 'your_border_color' with the color you want
                  borderWidth: "2px",
                  borderRadius: "30px",
                }}
              >
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Stack direction="column" spacing={1} mb={2}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontFamily: '"Poppins-SemiBold", Helvetica',
                            fontSize: "24px",
                          }}
                          fontWeight={800}
                        >
                          {classe.name}
                        </Typography>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontFamily: '"Poppins-Regular", Helvetica',
                            fontSize: "16px",
                          }}
                          fontWeight={600}
                        >
                          Trainer: {classe?.trainer_id?.first_name}{" "}
                          {classe?.trainer_id?.last_name}
                        </Typography>
                      </Stack>
                      <Stack direction="column" spacing={2}>
                        <Stack direction="row" spacing={3}>
                          <Stack direction="row" spacing={2}>
                            <CustomIcon
                              src="/newTestPrep/Vector@2x.png"
                              alt="School Icon"
                            />
                            <Typography
                              sx={{
                                color: "#000000",
                                fontFamily: '"Poppins-Regular", Helvetica',
                                fontSize: "16px",
                              }}
                              fontWeight={400}
                            >
                              {classe?.start_time} - {classe?.end_time}
                            </Typography>
                          </Stack>
                          <Stack direction="row" spacing={2}>
                            <CustomIcon
                              src="/newTestPrep/:duck: icon _Laptop_.png"
                              alt="School Icon"
                            />
                            <Typography
                              sx={{
                                color: "#000000",
                                fontFamily: '"Poppins-Regular", Helvetica',
                                fontSize: "16px",
                              }}
                              fontWeight={400}
                            >
                              {classe?.mode_of_delivery}
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack direction="row" spacing={3}>
                          <Stack direction="row" spacing={2}>
                            <CustomIcon
                              src="/newTestPrep/Vector.png"
                              alt="School Icon"
                            />
                            <Typography
                              sx={{
                                color: "#000000",
                                fontFamily: '"Poppins-Regular", Helvetica',
                                fontSize: "16px",
                              }}
                              fontWeight={400}
                            >
                              {classe?.branch_id?.branch_name}{" "}
                            </Typography>
                          </Stack>
                          <Stack direction="row" spacing={2}>
                            <CustomIcon
                              src="/newTestPrep/Group 1000002781.png"
                              alt="School Icon"
                            />
                            <Typography
                              sx={{
                                color: "#000000",
                                fontFamily: '"Poppins-Regular", Helvetica',
                                fontSize: "16px",
                              }}
                              fontWeight={400}
                            >
                              {classe?.enrolled_student} Active Students
                            </Typography>
                          </Stack>
                        </Stack>
                        <Stack direction="row" spacing={4}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Button
                                style={{
                                  borderRadius: "7px",
                                  background: "#019E6F",
                                  boxShadow:
                                    " 0px 0px 15px 1px rgba(0, 0, 0, 0.05)",
                                  color: "#FFF",
                                  fontFamily: 'Poppins-Regular", Helvetica',
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                }}
                                onClick={() =>
                                  handleViewLessonPlan(classe.id, classe)
                                }
                              >
                                View Lesson Plan
                              </Button>
                            </Grid>
                          </Grid>
                        </Stack>
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          ))}
        </Grid>
      )}
      <CreateLessonPopup
        onClose={handleClosePopup}
        open={openPopup}
        // title="Upload Lesson Plan"
        component={<LessonplanForm selectedClass={selectedClass} />}
      />
    </>
  );
}
export default LessonPlan;
