import {
  Button,
  FormControl,
  Grid,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import CustomSnackbar from "../../../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";

export default function EventEditForm({ selectedid, onClose, selectedData }) {
  console.log("selecteddata", selectedData);
  const id = selectedData.id;
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const [status, setStatus] = useState("");
  const [reasonForNoRegistration, setReasonForNoRegistration] = useState(null);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  const handelstatus = (e) => {
    setStatus(e.target.value);
    console.log("status", e.target.value);
  };

  const handleReasonForNoRegistration = (e) => {
    setReasonForNoRegistration(e.target.value);
  };
  const handleSubmit = () => {
    const formdata = {
      registration_status: status,

      user_id: parseInt(userId),
    };

    if (status === "Not Registered") {
      formdata.reason_for_no_registration = reasonForNoRegistration;
    } else {
      formdata.reason_for_no_registration = null;
    }

    fetch(`${API_ENDPOINT}eventregistration/${id}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formdata),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Event Registered Successfully.");
          // Handle success, e.g., show a success message to the user
          showSnackbar("Registration Status Update  Successfully");
          setTimeout(() => {
            onClose();
          }, 1500);
        } else if (response.status === 404) {
          console.error("Error: Not Found");
          showSnackbar("Error", "error");
          setTimeout(() => {
            onClose();
          }, 1500);
        } else if (response.status === 500) {
          console.error("Error: Internal Server Error");
          // Handle 500 error
          setTimeout(() => {
            onClose();
          }, 1500);
        } else {
          console.error("Error: Unknown Error");
          setTimeout(() => {
            onClose();
          }, 1500);
        }
      })
      .catch((error) => {
        console.error("Error:", error);
        // Handle network errors or other issues
      });
  };
  useEffect(() => {
    if (selectedData && selectedData?.registration_status) {
      setStatus(selectedData?.registration_status); // Set initial test type from selected data
    }
    if (selectedData && selectedData?.reason_for_no_registration) {
      setReasonForNoRegistration(selectedData?.reason_for_no_registration); // Set initial test type from selected data
    }
  }, [selectedData]);
  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12} md={12} lg={12}>
          <FormControl fullWidth>
            <Typography style={{ color: "black" }}>
              Registration Status
            </Typography>
            <Select
              value={status}
              onChange={handelstatus}
              style={{ borderRadius: "15px" }}
            >
              <MenuItem value="Registered & Confirmed">
                Registered & Confirmed
              </MenuItem>
              <MenuItem value="Not Registered">Not Registered</MenuItem>
              <MenuItem value="Registered & Not Confirmed">
                Registered & Not Confirmed
              </MenuItem>
            </Select>
          </FormControl>
        </Grid>

        {status == "Not Registered" && (
          <Grid item xs={12} sm={12} md={12} lg={12}>
            <FormControl fullWidth>
              <Typography style={{ color: "black" }}>
                Reason For No Registration
              </Typography>
              <Select
                value={reasonForNoRegistration}
                onChange={handleReasonForNoRegistration}
                style={{ borderRadius: "15px" }}
              >
                <MenuItem value="Location Issues">Location Issues</MenuItem>
                <MenuItem value="Not Interested">Not Interested</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        )}
        <Grid item lg={12} textAlign="center">
          <Button
            variant="contained"
            color="secondary"
            style={{
              borderRadius: "24px",
              fontSize: "16px",
              fontWeight: 500,
              marginTop: "20px",
            }}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
