import { useState } from 'react';
// @mui
import { alpha } from '@mui/material/styles';
import { Box, Divider, Typography, Stack, MenuItem, Avatar, IconButton, Popover, ButtonBase, useTheme } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { logout } from '../../redux/features/auth/authSlice'
import { clearUserData } from '../../redux/features/user/userSlice';
//import { setStudentData } from '../../redux/features/student/studentSlice';
// ----------------------------------------------------------------------

const MENU_OPTIONS = [
 /* {
    label: 'Home',
    icon: 'eva:home-fill',
  },*/
  {
    label: 'My Dashboard',
    icon: 'eva:person-fill',
  },
  /*{
    label: 'Settings',
    icon: 'eva:settings-2-fill',
  },*/
];

// ----------------------------------------------------------------------

export default function AccountPopover() {
  const [open, setOpen] = useState(null);
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const theme = useTheme();
  const { firstName, lastName, email, role } = useSelector((state) => state.user)

  console.log(email)
  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const handleMenuClick = (label) => {
    handleClose();
    if (label === 'Home') {
      navigate('/login'); 
    } else if (label === 'My Dashboard') {
        if (role === 'advisor') {
            navigate('/advisor/dashboard');
          } else if (role === 'admin') {
            navigate('/admin/dashboard');
          } else if (role === 'trainer') {
            navigate('/trainer/dashboard');
          } 
    } else {
        handleClose()
    }
  };

  const handleLogout = () => {
    dispatch(logout())

    dispatch(clearUserData())

    //dispatch(setStudentData([]))

    navigate('/')
   // window.location.reload();
  }

  return (
    <>
      {/*<IconButton
        onClick={handleOpen}
        sx={{
          p: 0,
          ...(open && {
            '&:before': {
              zIndex: 1,
              content: "''",
              width: '100%',
              height: '100%',
              borderRadius: '50%',
              position: 'absolute',
              bgcolor: (theme) => alpha(theme.palette.grey[900], 0.8),
            },
          }),
        }}
      >
        <Avatar src={account.photoURL} alt="photoURL" />
    </IconButton> */}

<ButtonBase sx={{ borderRadius: '12px', overflow: 'hidden' }}>
          <Avatar
            variant="rounded"
            sx={{
              ...theme.typography.commonAvatar,
              ...theme.typography.mediumAvatar,
              transition: 'all .2s ease-in-out',
              background: theme.palette.secondary.dark,
              color: '#fff',
              //color: theme.palette.secondary.light,
              '&:hover': {
                background: theme.palette.secondary.light,
                color: theme.palette.secondary.dark
              }
            }}
            onClick={handleOpen}
            color="inherit"
          >
            
          </Avatar>
        </ButtonBase>

      <Popover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        PaperProps={{
          sx: {
            p: 0,
            mt: 1.5,
            ml: 0.75,
            width: 180,
            '& .MuiMenuItem-root': {
              typography: 'body2',
              borderRadius: 0.75,
            },
          },
        }}
      >
        <Box sx={{ my: 1.5, px: 2.5 }}>
          <Typography variant="subtitle2" noWrap>
            {firstName} {lastName}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {email}
          </Typography>
          <Typography variant="body2" sx={{ color: 'text.secondary' }} noWrap>
            {role}
          </Typography>
        </Box>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <Stack sx={{ p: 1 }}>
          {MENU_OPTIONS.map((option) => (
            <MenuItem key={option.label} onClick={() => handleMenuClick(option.label)}>
              {option.label}
            </MenuItem>
          ))}
        </Stack>

        <Divider sx={{ borderStyle: 'dashed' }} />

        <MenuItem sx={{ m: 1 }} onClick={handleLogout}>
          Logout
        </MenuItem>
      </Popover>
    </>
  );
}