import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow } from '@mui/material';
import React, { useState } from 'react'

export default function AdminStudentTable({data}) {
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
  
    const handleChangePage = (event, newPage) => {
      setPage(newPage);
    };
  
    const handleChangeRowsPerPage = (event) => {
      setRowsPerPage(parseInt(event.target.value, 10));
      setPage(0);
    };
  return (
   <>
    <TableContainer  sx={{borderRadius:'15px' ,padding:'10px' }}>
    <Table>
      <TableHead >
        <TableRow >
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 }}>Student ID</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 ,}}>Student Name</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 ,}}>Class Name</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600,}}>Enrolled Date</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Status</TableCell>
        </TableRow>
      </TableHead>
      <TableBody >
      {data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
          <TableRow key={index}  style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'inherit' ,width: '6%' }}>
            <TableCell >{row.student_id}</TableCell>
            <TableCell >{row.student_Name}</TableCell>
            <TableCell>{row.class_name}</TableCell>
            <TableCell>{row.enrolled_date}</TableCell>
            <TableCell >{row.status}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
    <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
  </TableContainer>
   </>
  )
}
