import { Alert, Box, Button, Checkbox, FormControlLabel, Grid, Snackbar, TextField } from '@mui/material';
import { addDays, getDay, subDays } from 'date-fns';
import React, { useEffect, useState } from 'react'
import ReactDatePicker from 'react-datepicker';
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SubCard from '../../../components/ui-component/card/SubCard';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT


function DateEnrolled2({classId, onContinue}) {
  console.log(classId)
    const [startDate, setStartDate] = useState(null);
    const [isTentative, setIsTentative] = useState(false);
    const [isPastDate, setIsPastDate] = useState(false);
    const [datesWithSlots, setDatesWithSlots] = useState([]);
    const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');

  const [tentativeDate, setTentativeDate] = useState(null);
  const [enrollmentType, setEnrollmentType] = useState('');
  const [pastDate, setPastDate] = useState(null);

    useEffect(() => {
      const fetchDatesWithSlots = async () => {
        try {
          if (!classId) {
            alert('Please select a class to view available slots.');
            return;
          }
  
          const response = await fetch(`${API_ENDPOINT}route/searchseat/${classId}`);
          const data = await response.json();
          setDatesWithSlots(data);
        } catch (error) {
          console.error('Error fetching dates with slots:', error);
        }
      };
  
      fetchDatesWithSlots();
    }, [classId]);

  console.log(datesWithSlots)

  
  
const filterDate = (date) => {
  
  const dateStr = date.toLocaleDateString(); // Use toLocaleDateString

  // Check if the date is present in the fetched data
  return datesWithSlots.some((d) => dateStr === new Date(d.date).toLocaleDateString()) && datesWithSlots.slots !== 0

  
};

const customDayClass = (date) => {
  const dateStr = date.toLocaleDateString(); // Use toLocaleDateString

  const dateWithSlots2 = datesWithSlots.find(
    (d) => dateStr === new Date(d.date).toLocaleDateString()
  );

  if (dateWithSlots2) {
    return dateWithSlots2.slots === 0
      ? 'react-datepicker__day--highlighted-custom-3 react-datepicker__day--disabled'
      : 'react-datepicker__day--highlighted-custom-4';
  }

  return '';
};


const handleContinueClick = () => {

  if (!enrollmentType) {
    setSnackbarMessage('Please select enrollment type.');
    setSnackbarOpen(true);
    return;
  }
  if (enrollmentType === 'now'  && !startDate) {
    setSnackbarMessage('Please select enrollment date.');
    setSnackbarOpen(true);
    return;
  }

  if (enrollmentType === 'past' && !pastDate ) {
    setSnackbarMessage('Please select enrollment date.');
    setSnackbarOpen(true);
    return;
  }

  if (enrollmentType === 'future' && !tentativeDate ) {
    setSnackbarMessage('Please select enrollment date.');
    setSnackbarOpen(true);
    return;
  }

  if(startDate) {
    const nextDay = new Date(startDate);
    nextDay.setDate(startDate.getDate() + 1);
  }

  // Call the callback to move to the next tab
  //onContinue(nextDay.toISOString(),);
  onContinue(isTentative, startDate, tentativeDate, enrollmentType, pastDate)
};

const handleSnackbarClose = () => {
  setSnackbarOpen(false);
};

useEffect(() => {
  if (enrollmentType === 'past') {
    setTentativeDate(null);
    setStartDate(null);
  } else if (enrollmentType === 'future') {
    setStartDate(null);
    setPastDate(null);
  } else if(enrollmentType === 'now'){
    setTentativeDate(null);
    setPastDate(null);
  }
  else {
    setStartDate(null);
    setPastDate(null);
    setTentativeDate(null);
  }
}, [enrollmentType]);

  return (
    <>

<Grid container direction="column" alignItems="flex-start">
        <FormControlLabel
          control={
            <Checkbox
              checked={enrollmentType === 'now'}
              onChange={() => setEnrollmentType(enrollmentType === 'now' ? '' : 'now')}
            />
          }
          label="Enroll now (within 10 days from now)"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={enrollmentType === 'future'}
              onChange={() => setEnrollmentType(enrollmentType === 'future' ? '' : 'future')}
            />
          }
          label="Future enrollment (tentative date, requires follow-up)"
        />

        <FormControlLabel
          control={
            <Checkbox
              checked={enrollmentType === 'past'}
              onChange={() => setEnrollmentType(enrollmentType === 'past' ? '' : 'past')}
            />
          }
          label="Past enrollment (any date in the past)"
        />
      </Grid>
      { enrollmentType === 'now' &&  (
    <Grid item xs={12}>
    <SubCard title="Select Enrollment Date">
    <Box m={2}>
    <DatePicker
    showIcon
    isClearable
    closeOnScroll={true}
    selected={startDate}
    startDate={startDate}
    filterDate={filterDate}
    onChange={(date) => setStartDate(date)}
    dateFormat="dd/MM/yyyy"
      dayClassName={customDayClass} 
  />
  </Box>
  </SubCard>
  </Grid>
  )}
  {enrollmentType === 'future' && (
    <Grid item xs={12}>
    <SubCard title="Select Tentative Enrolment Date">
    <Box m={2}>
    <TextField
                type='date'
                value={tentativeDate}
                onChange={(e) => setTentativeDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: { borderRadius: "15px" },
                  inputProps: { min: new Date().toISOString().split('T')[0] },
                }}
              />
  </Box>
  </SubCard>
  </Grid>
  )}

{enrollmentType === 'past' && (
    <Grid item xs={12}>
    <SubCard title="Select Past Enrollment Date">
    <Box m={2}>
    <TextField
                type='date'
                value={pastDate}
                onChange={(e) => setPastDate(e.target.value)}
                InputLabelProps={{ shrink: true }}
                InputProps={{
                  style: { borderRadius: "15px" },
                  inputProps: { max: new Date().toISOString().split('T')[0] },
                }}
              />
  </Box>
  </SubCard>
  </Grid>
  )}

<Grid container justifyContent="right" spacing={0} mt={2}>
<Grid item>
<Button
    variant="contained"
    color="secondary"
    size="large"
    onClick={handleContinueClick}
>
    Continue
</Button>
</Grid>
</Grid>

<Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      >
        <Alert onClose={handleSnackbarClose} severity="error">
          {snackbarMessage}
        </Alert>
      </Snackbar>
</>
  )
}



export default DateEnrolled2