import React, { useState } from 'react'
import MainCard from '../../../components/ui-component/card/MainCard'
import { Box, Button, CircularProgress, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material'
import { CSVExport } from '../../../components/ui-component/table/CSVExport'
import RoomScheduleDialog from './RoomSchedule';
import CreatePopup from '../../../../components/dialog/CreatePopup';
import AddRoomForm from './rooms/AddRoom';
import CustomSnackbar from '../../../components/ui-component/snackbar/CustomSnackbar';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT


function Row({ row, branchName, branchId }) {
    const theme = useTheme();
    const [open, setOpen] = React.useState(false);

    const handleViewSchedule = () => {
        setOpen(true);
      };

      

    return (
        <>
            <TableRow hover sx={{ '& > *': { borderBottom: 'unset' } }}>
                <TableCell sx={{ pl: 3 }}>
                    
                </TableCell>
                <TableCell component="th" scope="row">
                    {row.name}
                </TableCell>
                <TableCell >{row.capacity}</TableCell>
                <TableCell >{row?.teaching_mode}</TableCell>
                <TableCell >
                <Button
                                    color="secondary"
                                    variant='contained'
                                    size='small'
                                    sx={{
                                        color: theme.palette.dark,
                                        borderColor: theme.palette.orange.main,
                                        '&:hover ': { background: theme.palette.orange.light }
                                    }}
                                    onClick={handleViewSchedule}
                                >  
                                    View Schedule
                                </Button>
                </TableCell>
                
            </TableRow>
            {open && (
                <RoomScheduleDialog open={open} onClose={() => setOpen(false)} roomId={row.id} branchName={branchName} branchId={branchId} />
            )}
                               
                                   
                              
           
        </>
    );
}

function RowTable({ rooms, branchId, branchName }) {
    const userId = localStorage.getItem('userId');
    const [rows, setRows] = useState(rooms)
    const [isLoading, setIsLoading] = useState(false)
    const [isAddRoomPopupOpen, setAddRoomPopupOpen] = useState(false);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
   

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };

    const handleAddRoomClick = () => {
        setAddRoomPopupOpen(true);
      };
    
      const handleAddRoomClose = () => {
        setAddRoomPopupOpen(false);
      };
    
      const handleAddRoom = (newRoom) => {
        // Handle adding the new room to the state or perform API call
        // Assuming setRows is a function to update the rows state
        newRoom.branch_id = branchId
        newRoom.user_id = userId

        console.log(newRoom)

        fetch(`${API_ENDPOINT}room`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(newRoom),
          })
          .then((response) => {
            if(response.status === 201) {
               showSnackbar('Room created successfully', 'success');
              
              setRows((prevRows) => [...prevRows, newRoom]);
              window.location.reload()
            } else if(response.status === 401) {
                showSnackbar('User unauthorized to create room', 'error');
                //window.location.reload()
             }
            else {
              console.log('Error:', response)
              showSnackbar('Error creating room', 'error');
            }
          })
            .catch((error) => {
              console.error('Error assigning admin:', error);
              showSnackbar('Error creating room', 'error');
            });

        
    
        // Close the Add Room popup
        handleAddRoomClose();
      };

  return (
    <>
    <MainCard
        content={false}
        title="Rooms"
        secondary={
            <Stack direction="row" spacing={2} alignItems="center">
                <CSVExport data={rows} filename="rooms.csv"  />
                <Button variant='contained' onClick={handleAddRoomClick}> Add Room</Button>
            </Stack>
        }
    >
        {/* table */}
        
        <TableContainer>
            <Table aria-label="collapsible table">
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ pl: 3, backgroundColor: '#90ee90' }} />
                        <TableCell sx={{ backgroundColor: '#90ee90' }}>Room Name</TableCell>
                        <TableCell sx={{ backgroundColor: '#90ee90' }}>Capacity</TableCell>
                        <TableCell sx={{ backgroundColor: '#90ee90' }}>Teaching Mode</TableCell>
                        <TableCell sx={{ backgroundColor: '#90ee90' }} >Action</TableCell>
                       
                    </TableRow>
                </TableHead>
                <TableBody>

                {rows.length === 0 ? (
                <TableRow>
                  <TableCell sx={{ pl: 3 }} />
                  <TableCell colSpan={4} align="center">
                    <Typography variant='h4'>No data available</Typography>
                  </TableCell>
                </TableRow>
              ) : (
                rows.map((room, index) => (
                  <Row key={index} row={room} branchName={branchName} branchId={branchId}/>
                ))
              )}

                  </TableBody>
            </Table>
        </TableContainer>
    </MainCard> 

    <CreatePopup 
        open={isAddRoomPopupOpen} 
        onClose={handleAddRoomClose}
        component={<AddRoomForm onAddRoom={handleAddRoom} branchId={branchId} branchName={branchName} />}
        >
      </CreatePopup> 

      <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
    </>
  )
}

export default RowTable