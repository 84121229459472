import React from 'react'
import {
   
    Typography,
   
  } from "@mui/material";
export default function TestTypeAll({seletedtesttyps}) {
  return (
    <Typography variant='h5'>{seletedtesttyps.join(' , ')}
   </Typography>
  )
}
