import {
  Box,
  Button,
  Chip,
  CircularProgress,
  styled,
  Typography,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import CancelIcon from "@mui/icons-material/Cancel";
import { Person as PersonIcon } from "@mui/icons-material";
import { fDate } from "../../../utils/formatTime";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function SpecificTaskTable({
  onClose,
  fetchedData,
  taskloading,
}) {
  const user_role = localStorage.getItem("userRole");
  const userId = localStorage.getItem("userId");

  const handleViewMore = (row) => {
    console.log("View More:", row.enquiry_id?.id);
    console.log("View More:", row.demo_id);

    if (user_role === "admin" || user_role === "trainer") {
      console.log("Task Type:", row.type_of_task.name_of_task_type);

      if (
        ["Demo request", "Test prep demo", "Evaluation demo"].includes(
          row.type_of_task.name_of_task_type
        )
      ) {
        console.log("Navigating to Demo Details");
        if (row.demo_id) {
          window.open(`/demodetails/${row.demo_id}`, "_blank");
        } else {
          window.open("/page404", "_blank");
        }
      } else {
        console.log("Navigating to Enquiry Details");
        window.open(`/enquirydetails/${row.enquiry_id?.id}`, "_blank");
      }
    } else {
      console.log("Navigating to Enquiry Details");
      window.open(`/enquirydetails/${row.enquiry_id?.id}`, "_blank");
    }

   
  };

  console.log("filterdata", fetchedData);

  const columns = [
    {
      field: "name",
      headerName: "Enquiry Name",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => {
        return (
          <div>
            <Button size="small" onClick={() => handleViewMore(params.row)}>
              <PersonIcon /> {params.row.enquiry_id?.first_name}{" "}
              {params.row.enquiry_id?.last_name}
            </Button>
          </div>
        );
      },
    },
    {
      field: "tasks",
      headerName: "Tasks",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.type_of_task?.name_of_task_type}</div>,
      headerAlign: "center",
      align: "center",
    },

    {
      field: "dueDate",
      headerName: "Due Date",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate( params.row?.due_date)}</div>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "stage",
      headerName: "Stage",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.enquiry_id?.stage_id?.name}</div>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "subService",
      headerName: "Sub Service",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.enquiry_id?.sub_service_id?.name}</div>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "taskOwner",
      headerName: "Task Owner",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.task_owner?.first_name} {params.row?.task_owner?.last_name}</div>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.created_by?.first_name} {params.row?.created_by?.localStorage_name}</div>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "createdDate",
      headerName: "Created Date",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params.row?.createdAt)}</div>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => {
        let chipColor = "";
        let chipLabel = "";

        switch (params.value) {
          case 0:
            chipColor = "secondary";
            chipLabel = "Closed";
            break;
          case 1:
            chipColor = "error";
            chipLabel = "Open";
            break;
          default:
            chipColor = "orange";
            chipLabel = "Unknown";
        }

        return (
          <Chip
            label={chipLabel}
            color={chipColor === "default-color" ? "default" : chipColor}
          />
        );
      },
    },

    {
      field: "closeBy",
      headerName: "Close By",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.closed_by?.first_name} {params.row?.closed_by?.last_name}</div>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "closedDate",
      headerName: "Closed Date",
      width: 200,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params.row?.closed_date)}</div>,
      headerAlign: "center",
      align: "center",
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }

  console.log("fetchedData", fetchedData);
  return (
    <>
      {taskloading ? (
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <CircularProgress color="primary" />
        </div>
      ) : (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            "& .header-cell": {
              backgroundColor: "#abf7b1",
              fontWeight: 600,
              borderRight: "0.1px solid #C3C3C3",
            },
          }}
        >
          <DataGrid
            rows={fetchedData}
            columns={columns}
            autoHeight
            getRowId={() => Math.floor(Math.random() * 100000000)}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            sx={{ marginTop: "20px" }}
            pageSizeOptions={[25, 50, 100]}
          />
        </Box>
      )}
    </>
  );
}
