import React, { useEffect, useState } from "react";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
  Image,
  Line,
} from "@react-pdf/renderer";
import { useParams } from "react-router-dom";
import { Backdrop, Typography } from "@mui/material";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const styles = StyleSheet.create({
  page: {
    flexDirection: "column",
    padding: 20,
  },
  logo: {
    width: 174,
  },
  section: {
    margin: 1,
    padding: 1,
    flexGrow: 0.1,
  },
  header: {
    fontSize: 24,
    fontWeight: "bold",
    // marginBottom: 10,
  },
  subheader: {
    fontSize: 10,
    fontWeight: 600,
    marginTop: "5px",
    color: "#5E6470",
  },
  text: {
    fontSize: 12,
    fontWeight: 600,
    color: "#1A1C21",
    marginTop: "7px",
  },
  table: {
    display: "table",
    width: "auto",
    // borderStyle: 'solid',
    // borderWidth: 1,
    // borderRightWidth: 0,
    // borderBottomWidth: 0,
  },
  tableRow: {
    flexDirection: "row",
    backgroundColor: "#FFFFFF",
    borderBottomColor: "#BFBFBF",
    borderBottomWidth: 1,
    textAlign: "center",
  },
  tableCell: {
    margin: "auto",
    marginTop: 5,
    fontSize: 10,
    padding: 3,
    // textAlign: "center",
    flex: 1,
  },

  hrLine: {
    width: "100%",
    borderBottomColor: "#D7DAE0",
    borderBottomWidth: 1,
    marginTop: 10,
  },
});

// Define Invoice PDF component
const InvoicePDF = ({ invoiceData }) => (
  <Document>
    <Page size="A4" style={styles.page}>
      <View style={styles.section}>
        <View style={{ flexDirection: "row", justifyContent: "space-between" }}>
          <Image style={styles.logo} src="/Siec Logo.png" />
          <View>
            <Text style={{ ...styles.header, color: "#B2B7C2" }}>Receipt</Text>
            <Text style={{ ...styles.text, color: "#5E6470" }}>
              {invoiceData?.receipt_number}
            </Text>
          </View>
        </View>

        <View
          style={{
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <View style={{ flexDirection: "column" }}>
          <View>
            <Text style={styles.subheader}>Billed To</Text>
            <Text style={styles.text}>{invoiceData?.enquiry_id?.first_name} {invoiceData?.enquiry_id?.last_name}</Text>
          </View>
          <View style={{ marginTop: "10px" , width:'50%'}}>
            <Text style={styles.subheader}>Branch</Text>
            <Text style={styles.text}>{invoiceData?.enquiry_id?.branch_id?.branch_name}</Text>
            <Text style={styles.text}>{invoiceData?.enquiry_id?.branch_id?.address}</Text>
            <Text style={styles.text}>{invoiceData?.enquiry_id?.branch_id?.city_id?.city_name} {invoiceData?.enquiry_id?.branch_id?.state_id?.state_name}</Text>
          </View>
          </View>

          <View style={{ flexDirection: "column" }}>
            <View>
              <Text style={styles.subheader}>Receipt Date</Text>
              <Text style={styles.text}>{invoiceData?.paid_date}</Text>
            </View>
            <View style={{ marginTop: "10px" }}>
              <Text style={styles.subheader}>Paid Date </Text>
              <Text style={styles.text}>{invoiceData?.paid_date}</Text>
            </View>
          </View>

          <View
            style={{
              marginTop: "20px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Text style={styles.subheader}>Paid Amount</Text>
            <View
              style={{
                backgroundColor: "#D3FFE0",
                color: "#028428",
                textAlign: "center",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                marginTop: "7px",
                height: "30px",
                width: "150px",
              }}
            >
              <Text style={{ fontWeight: 900, fontSize: "16px" }}>
                INR {invoiceData?.paid_amount}
              </Text>
            </View>
          </View>
        </View>
      </View>

      <View style={{ marginTop: "30px" }}>
        <Text style={styles.text}>Receipt Details</Text>
        <View
          style={{ display: "flex", flexDirection: "row", marginTop: "8px" }}
        >
          <Text style={{ ...styles.subheader, marginRight: "10px" }}>#</Text>
          <View
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
            }}
          >
            <Text style={styles.subheader}>Title / Description</Text>
            <Text
              style={{
                marginLeft: "auto",
                fontWeight: 600,
                fontSize: "10px",
                color: "#5E6470",
              }}
            >
              Subtotal
            </Text>
          </View>
        </View>
        <Line style={styles.hrLine} />

        <View style={{ display: "flex", flexDirection: "row" }}>
          <Text style={{ ...styles.text, marginRight: "10px" }}>1</Text>

          <View
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
            }}
          >
            <View>
              <Text style={styles.text}>{invoiceData?.class_id?.name}</Text>
              <Text style={styles.subheader}>{invoiceData?.package_id?.package_name}</Text>
            </View>
            <Text
              style={{
                marginLeft: "auto",
                fontWeight: 600,
                fontSize: "10px",
                color: "#5E6470",
              }}
            >
              Rs {invoiceData?.total_amount}
            </Text>
          </View>
        </View>

        <Line style={styles.hrLine} />

      {/*  <View style={{ display: "flex", flexDirection: "row" }}>
          <Text style={{ ...styles.text, marginRight: "12px" }}></Text>
          <View
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
              marginLeft: "6px",
            }}
          >
            <Text style={styles.text}>Discount</Text>
            <Text
              style={{
                marginLeft: "auto",
                fontWeight: 600,
                fontSize: "10px",
                color: "#5E6470",
              }}
            >
              Rs {invoiceData?.discount || 0} 
            </Text>
          </View>
        </View>

      }  <View
          style={{ display: "flex", flexDirection: "row", marginTop: "7px" }}
        >
          <Text style={{ ...styles.text, marginRight: "10px" }}></Text>
          <View
            style={{
              display: "flex",
              justifyContent: "flex-start",
              flexDirection: "row",
              alignItems: "center",
              flex: 1,
              marginLeft: "6px",
            }}
          >
            <Text style={styles.text}>{invoiceData.Pendingname}</Text>
            <Text
              style={{
                marginLeft: "auto",
                fontWeight: 600,
                fontSize: "10px",
                color: "#5E6470",
              }}
            >
              Rs {invoiceData.pendingammount}
            </Text>
          </View>
            </View>*/}

        <View style={{ marginTop: "70px" }}>
          <Line style={styles.hrLine} />
        </View>
      </View>

      <View
        style={{
          ...styles.section,
          flexDirection: "row",
          justifyContent: "space-between",
          marginLeft: 10,
          padding: 0,
        }}
      >
        <Text style={{ ...styles.text }}>Total</Text>
        <Text style={{ ...styles.text }}>Rs {invoiceData?.paid_amount}</Text>
      </View>

      <View style={{ ...styles.section, marginTop: "200px" }}>
        <Text style={styles.text}>Thank you for the business!</Text>
        <View
          style={{
            flexDirection: "row",
            alignItems: "center",
            marginTop: "20px",
          }}
        >
          <Text style={{ ...styles.subheader, marginRight: "20px" }}>
            Payment info
          </Text>

          <Line style={styles.hrLine} />
        </View>
        <View
          style={{
            display: "flex",
            flexDirection: "row",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <View
            style={{
              display: "flex",
              flexDirection: "column",
              borderRightWidth: 1,
              borderColor: "black",
              paddingRight: 10,
            }}
          >
            <Text style={styles.text}>Ref Number </Text>
            <Text style={styles.subheader}>
              {invoiceData?.ref_number}
            </Text>
          </View>

          <View
            style={{
              display: "flex",
              flexDirection: "column",
              borderRightWidth: 1,
              borderColor: "black",
              paddingRight: 10,
            }}
          >
            <Text style={styles.text}>Payment Method </Text>
            <Text style={styles.subheader}>
              {invoiceData.payment_method?.payment_mode}
            </Text>
          </View>
          <View style={{ display: "flex", flexDirection: "column" }}>
            <Text style={styles.text}>Payment Status</Text>
            <Text style={styles.subheader}>
              {invoiceData.payment_status}
            </Text>
          </View>
          <View style={{ width: "200px" }}>
            <Text style={styles.subheader}>
            B-2/9 1st Floor, Opposite Happy Model School, Janakpuri, New Delhi 110058
            </Text>
          </View>
        </View>
      </View>
    </Page>
  </Document>
);

const InvoiceForm = () => {
  const { receiptId } = useParams()
  const [receiptData, setReceiptData] = useState({})
  const [isLoading, setIsLoading] = useState(false)
  const [error, setError] = useState(null);

  console.log(receiptId)
  // Define invoiceData object

  useEffect(() => {
    const fetchAdditionalDetails = async (id) => {
      try {
        setIsLoading(true);
        const response = await fetch(`${API_ENDPOINT}receipt/${id}`);
        if (response.ok) {
          const data = await response.json();
          setReceiptData(data);
          setIsLoading(false);
        } else {
          setIsLoading(false);
          setError('Error fetching additional details');
        }
      } catch (error) {
        setIsLoading(false);
        setError('Error fetching additional details');
      }
    };

    fetchAdditionalDetails(receiptId);
  }, [receiptId]);


  const invoiceData = {
    receiptNumber: "#AB2324-01",
    receiptDate: "01.02.2024",
    paidDate: "01.02.2024",
    billedTo: "Deepak Shukla",
    paidAmount: "4500.00",
    description: "IELTS Offline",
    dateRange: "01 Jul - 20 Jul",
    subtotal: "4,500.00",
    discount: "Discount",
    discountAmmount: "200.00",
    Pendingname: "Pending Amount",
    pendingammount: "0.00",

    totalAmount: " 4,300.00",
    paymentInfo: {
      refNumber: "000085752257",
      paymentMethod: "Card",
      paymentStatus: "Paid",
      address:
        "B-2/9 1st Floor, Opposite Happy Model School, Janakpuri, New Delhi 110058",
    },
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h2" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <img src='/sand-clock.png' alt="Error" style={{ width: '200px', height: '200px' }} />
      </Backdrop>

      {error && !isLoading &&  (
         <div
         style={{
           display: 'flex',
           flexDirection: 'column',
           justifyContent: 'center',
           alignItems: 'center',
           height: '100%',
         }}
       >
          <img src='/browser.png' alt="Error" style={{ width: '200px', height: '200px' }} />
          <Typography variant="h3">Error generating receipt</Typography>
          <Typography variant="h5">Please try again later</Typography>
        </div>
      )}
      {receiptData && !isLoading && !error && (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <InvoicePDF invoiceData={receiptData} />
        </PDFViewer>
      )}
    </>
  );
};

export default InvoiceForm;
