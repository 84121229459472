import { Box, Button, Checkbox, Chip, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, ListItemText, MenuItem, OutlinedInput, Select, Stack, TextField } from '@mui/material';
import React, { useEffect, useState } from 'react'
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import WhatsAppIcon from '@mui/icons-material/WhatsApp';
import CustomSnackbar from '../../../../components/ui-component/snackbar/CustomSnackbar';
import { gridSpacing } from '../../../../store/constant';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function AddTrainer({branchId, branchName, editMode, trainerInfo, onSubmit}) {
    const branch_id = branchId
    const userRole = localStorage.getItem('userRole');
    const [profile, setProfile] = useState({
        first_name: '',
        last_name: null,
        email: '',
        mobile: null,
        whatsapp_number: null,
        branch_id: null,
        status: 'active',
        trainer_expertise: [],
        work_experience: null,
        highest_qualification: null,
    });
    const [branches, setBranches] = useState([])
    const [testTypes, setTestTypes] = useState([])

    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        trainer_expertise: ''
       // branch_id: '',
       // user_role: '',
      });
    
      const [showAlert, setShowAlert] = useState(false);
      const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
   

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };
    
      const validateForm = () => {
        let isValid = true;
        const newErrors = { first_name: '', last_name: '' };
    
        if (!profile.first_name.trim()) {
          newErrors.first_name = 'First Name is required';
          isValid = false;
        }
    
       /* if (!profile.last_name.trim()) {
          newErrors.last_name = 'Last Name is required';
          isValid = false;
        }*/

        if (!editMode) {
            const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
            if (!profile.email.trim() || !emailRegex.test(profile.email.trim())) {
              newErrors.email = 'Invalid Email address';
              isValid = false;
            }
          }

          if (!profile.trainer_expertise || profile.trainer_expertise.length === 0) {
            newErrors.trainer_expertise = 'Select at least one expertise';
            isValid = false;
        }

    // Phone number validation with country code
    //const phoneRegex = /^\+[0-9]{1,4}[0-9]{3,14}$/;
   /* const phoneRegex = /^\+91[1-9][0-9]{9}$/;
    if (!profile.mobile.trim() || !phoneRegex.test(profile.mobile.trim())) {
      newErrors.mobile = 'Invalid Phone number with country code ie +91....';
      isValid = false;
    }*/

    if (profile.mobile !== null && profile.mobile.trim() !== '') {
      const phoneRegex = /^\+91[1-9][0-9]{9}$/;
      if (!phoneRegex.test(profile.mobile.trim())) {
          newErrors.mobile = 'Invalid Phone number with country code ie +91....';
          isValid = false;
      }
  }

    
        setErrors(newErrors);
       // setShowAlert(!isValid); // Show alert if the form is not valid
        return isValid;
      };

      useEffect(() => {
        // If in edit mode, populate the profile with trainerInfo
        if (editMode && trainerInfo) {
          setProfile((prevProfile) => ({
            ...prevProfile,
            first_name: trainerInfo.first_name || '',
            last_name: trainerInfo.last_name || null,
            email: trainerInfo.email || null,
            mobile: trainerInfo.mobile || null,
            gender: trainerInfo.gender || null,
            trainer_expertise: trainerInfo.trainer_expertise || [],
            highest_qualification: trainerInfo.highest_qualification || null,
            work_experience: trainerInfo.work_experience || null,
            dob: trainerInfo.dob || null,
          }));
        }
      }, [editMode, trainerInfo]);


      
      const handleSubmit = () => {

        if (!validateForm()) {
          // If form is not valid, do not proceed with submission
          return;
      } else {
        // Form has errors, handle accordingly
        onSubmit(profile)
      }

      };

      const handleRoleChange = (e) => {
        setProfile({ ...profile, user_role: e.target.value });
      };

      const handleGenderChange = (e) => {
        setProfile({ ...profile, gender: e.target.value });
      };

      useEffect(() => {
        // Fetch menu items using the fetch function
        fetch(`${API_ENDPOINT}advisordashboard/gettesttype`)
          .then(response => response.json())
          .then(data => {
            setTestTypes(data);
            console.log(data)
          })
          .catch(error => {
            console.error('Error fetching testtypes:', error);
          });
      }, []);

  return (
    <>
        <Box m={2}>
        <Grid container spacing={gridSpacing}>
    <Grid item xs={6}>
    <FormControl fullWidth>
        <InputLabel>First Name</InputLabel>
        
        <OutlinedInput
            
            value={profile.first_name}
            onChange={(e) => setProfile({ ...profile, first_name: e.target.value })}
            type="text"
            label="FirstName"
           
        />
        <FormHelperText style={{ color: 'red'}}>{errors.first_name}</FormHelperText>
    </FormControl>
</Grid>

<Grid item xs={6}>
    <FormControl fullWidth>
        <InputLabel>Last Name</InputLabel>
        <OutlinedInput
            value={profile.last_name}
            onChange={(e) => setProfile({ ...profile, last_name: e.target.value })}
            type="text"
            label="Last Name"
    
        />
        <FormHelperText style={{ color: 'red'}}>{errors.last_name}</FormHelperText>
    </FormControl>
</Grid>


{!editMode && (
<Grid item xs={12}>
<FormControl fullWidth>
<InputLabel>Email</InputLabel>
<OutlinedInput
value={profile.email}
onChange={(e) => setProfile({ ...profile, email: e.target.value })}
type="email"
label="Email"
startAdornment={
<InputAdornment position="start">
<MailTwoToneIcon />
</InputAdornment>
}
/>
<FormHelperText style={{ color: 'red' }}>{errors.email}</FormHelperText>
</FormControl>
</Grid>
)}



<Grid item xs={12}>
    <FormControl fullWidth>
        <InputLabel>Phone Number</InputLabel>
        <OutlinedInput
            value={profile.mobile}
            onChange={(e) => {
                setProfile({ ...profile, mobile: e.target.value });
            }}
            type="mobile"
            label="Phone Number"
            startAdornment={
                <InputAdornment position="start">
                    <CallTwoToneIcon />
                </InputAdornment>
            }
        />
        <FormHelperText style={{ color: 'red'}}>{errors.mobile}</FormHelperText>
    </FormControl>
</Grid>

<Grid item xs={12}>
    <FormControl fullWidth>
        <InputLabel>WhatsApp Number</InputLabel>
        <OutlinedInput
            value={profile.whatsapp_number}
            onChange={(e) => {
                setProfile({ ...profile, whatsapp_number: e.target.value });
            }}
            type="mobile"
            label="Whatsapp Number"
            startAdornment={
                <InputAdornment position="start">
                    <WhatsAppIcon />
                </InputAdornment>
            }
        />
        <FormHelperText style={{ color: 'red'}}>{errors.whatsapp_number}</FormHelperText>
    </FormControl>
</Grid>

<Grid item xs={12}>
<InputLabel >DOB</InputLabel>
    <FormControl fullWidth>
    
        <OutlinedInput
            value={profile.dob}
            type="date"
            label="DOB"
            InputProps={{
                inputProps: { max: '2005-12-31' },
            }}
            onChange={(e) => setProfile({ ...profile, dob: e.target.value })}
        />
    </FormControl>
</Grid>

<Grid item xs={12}>
<FormControl fullWidth>
<InputLabel>Gender</InputLabel>
<Select
    value={profile.gender}
    onChange={handleGenderChange}
    label="Gender"
    startAdornment={
    <InputAdornment position="start">
        {/* You can add an icon here if needed */}
    </InputAdornment>
    }
>
    <MenuItem value="male">Male</MenuItem>
    <MenuItem value="female">Female</MenuItem>
</Select>
</FormControl>
</Grid>



<Grid item xs={12}>
    <FormControl fullWidth>
        <InputLabel>Branch</InputLabel>
        <OutlinedInput
            value={branchName}
            type="text"
            label="Role"
        >
        </OutlinedInput>
        <FormHelperText style={{ color: 'red'}}>{errors.branch}</FormHelperText>
    </FormControl>
</Grid>

                { !editMode ?  (

                    <Grid item xs={12}>
                            <FormControl fullWidth>
                            <InputLabel>Expertise</InputLabel>
                            <Select
                                multiple  
                                label="Expertise"
                                value={profile.trainer_expertise || []}
                                onChange={(e) => setProfile({ ...profile, trainer_expertise: e.target.value })}
                                renderValue={(selected) => (
                                    <div>
                                      {selected?.map((value) => (
                                        <Chip key={value} label={testTypes.find((testtype) => testtype.id === value)?.name} />
                                      ))}
                                    </div>
                                  )}
                            >
                                {testTypes.map((testtype) => (
                                <MenuItem key={testtype.id} value={testtype.id}>
                                <Checkbox checked={profile.trainer_expertise && profile.trainer_expertise.includes(testtype.id)} />
                                <ListItemText primary={testtype.name} />
                                </MenuItem>
      ))}
                            </Select>
                            <FormHelperText style={{ color: 'red'}}>{errors.trainer_expertise}</FormHelperText>
                            </FormControl>
                        </Grid>

                ) : (
                    <Grid item xs={12}>
                    <FormControl fullWidth>
                    <InputLabel>Expertise</InputLabel>
                    <Select
                        multiple  
                        label="Expertise"
                        value={profile.trainer_expertise || []}
                        onChange={(e) => setProfile({ ...profile, trainer_expertise: e.target.value })}
                          renderValue={(selected) => {
                            return (
                              <div>
                                {selected?.map((value) => (
                                  <Chip key={value} label={testTypes.find((testtype) => testtype.name === value)?.name} />
                                ))}
                              </div>
                            );
                          }}
                    >
                        {testTypes.map((testtype) => (
                        <MenuItem key={testtype.id} value={testtype.name}>
                        <Checkbox
                            checked={
                                profile.trainer_expertise &&
                                (() => {
                                    return profile.trainer_expertise.includes(testtype.name);
                                })()
                            }
                            />
                        <ListItemText primary={testtype.name} />
                        </MenuItem>
))}
                    </Select>
                    </FormControl>
                </Grid>     
                )}
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                            <InputLabel>Highest Qualification</InputLabel>
                            <Select
                                label="Highest Education"
                                value={profile.highest_qualification}
                                onChange={(e) =>
                                setProfile({ ...profile, highest_qualification: e.target.value })
                                }
                                >
                                <MenuItem value="Diploma">Diploma</MenuItem>
                                <MenuItem value="Undergraduate">Undergraduate</MenuItem>
                                <MenuItem value="Masters">Masters</MenuItem>
                                </Select>
                            
                            </FormControl>
                        </Grid>
                        <Grid item xs={12}>
                            <FormControl fullWidth>
                           
                            <TextField
                                label="Work Experience in years"
                                value={profile.work_experience}
                                type='number'
                                onChange={(e) =>
                                setProfile({ ...profile, work_experience: e.target.value })
                                }
                            />
                            </FormControl>
                        </Grid>

                        {editMode && trainerInfo && (
                            <Grid item xs={12}>
                            <FormControl fullWidth>
                            <InputLabel>Status</InputLabel>
                            <Select
                                label="status"
                                value={profile.status}
                                onChange={(e) =>
                                setProfile({ ...profile, status: e.target.value })
                                }
                                >
                                <MenuItem value="active">Active</MenuItem>
                                <MenuItem value="inactive">Inactive</MenuItem>
                                
                                </Select>
                            
                            </FormControl>
                        </Grid>
                        )}
<Grid item xs={12}>
<Stack direction="row" spacing={2}>
<Button
      variant="contained"
      color="primary"
      type="submit"
      onClick={handleSubmit}
    >
      {editMode ? 'Edit Trainer' : 'Add Trainer'}
    </Button>
        </Stack>
        </Grid>


</Grid>
</Box>


<CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
</>
  )
}

export default AddTrainer