import React, { useState } from 'react';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import { FormControl, InputLabel, MenuItem, Select } from '@mui/material';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

const AddRoomForm = ({ onAddRoom, branchId, branchName }) => {
    console.log(branchName, branchId)
  const [roomName, setRoomName] = useState('');
  const [capacity, setCapacity] = useState('');
  const [teachingMode, setTeachingMode] = useState('offline');
  const [branch, setBranch] = useState(branchName);

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validation: Check if required fields are not empty
    if (!roomName || !capacity || !teachingMode) {
      alert('All fields are required');
      return;
    }

    const roomDetails = {
        name: roomName,
        capacity: capacity,
        teaching_mode: teachingMode
    }

    // Call the onAddRoom function with the form data
    onAddRoom(roomDetails);

    // Clear the form fields
    setRoomName('');
    setCapacity('');
    setTeachingMode('');
  };

  return (
    <form onSubmit={handleSubmit}>
      <Box mt={2} sx={{ display: 'flex', flexDirection: 'column', gap: 2 }}>
      <TextField
          label="Branch Name"
          variant="outlined"
          value={branch}
         // onChange={(e) => setBranchName(e.target.value)}
          required
        />
        <TextField
          label="Room Name"
          variant="outlined"
          placeholder='Room 1'
          value={roomName}
          onChange={(e) => setRoomName(e.target.value)}
          required
        />
        <TextField
          label="Capacity"
          variant="outlined"
          type="number"
          value={capacity}
          onChange={(e) => setCapacity(e.target.value)}
          required
        />
       <FormControl variant="outlined" required>
          <InputLabel id="teaching-mode-label">Teaching Mode</InputLabel>
          <Select
            label="Teaching Mode"
            labelId="teaching-mode-label"
            value={teachingMode}
            onChange={(e) => setTeachingMode(e.target.value)}
            required
          >
            <MenuItem value="offline">Offline</MenuItem>
            <MenuItem value="online">Online</MenuItem>
          </Select>
        </FormControl>
        <Stack direction="row" spacing={2}>
          <Button variant="contained" color="primary" type="submit">
            Add Room
          </Button>
        </Stack>
      </Box>
    </form>
  );
};

export default AddRoomForm;
