import React from 'react';
import { List, ListItem, ListItemText, ListItemSecondaryAction, Typography, IconButton, Paper } from '@mui/material';
import { NotificationsNone as NoNotificationsIcon } from '@mui/icons-material';

const NotificationList = () => {

    const notifications = [
       /* {
          title: 'Task 1',
          date: '2023-09-05',
          time: '10:00 AM',
        },
        {
          title: 'Task 2',
          date: '2023-09-06',
          time: '2:30 PM',
        },
        {
          title: 'Task 3',
          date: '2023-09-07',
          time: '9:45 AM',
        },
        {
          title: 'Task 4',
          date: null,
          time: null,
        },*/
      ];
      
      // Usage:
      // <NotificationList notifications={dummyNotifications} />
      
  return (
    
      <List>
        {notifications.length === 0 ? (
          <ListItem>
            <ListItemSecondaryAction>
              <IconButton disabled>
                <NoNotificationsIcon fontSize="large" />
              </IconButton>
            </ListItemSecondaryAction>
            <ListItemText
              primary="No Notifications"
              secondary="There are currently no notifications."
            />
          </ListItem>
        ) : (
          notifications.map((notification, index) => (
            <ListItem key={index}>
              <ListItemText
                primary={notification.title}
                secondary={notification.date ? `${notification.date} ${notification.time}` : 'No date'}
              />
            </ListItem>
          ))
        )}
      </List>
    
  );
};

export default NotificationList;
