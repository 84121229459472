import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";
import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  Font,
} from "@react-pdf/renderer";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});
const capitalizeFirstLetter = (string) => {
  return string.charAt(0).toUpperCase() + string.slice(1);
};

const styles = StyleSheet.create({
  document: { padding: 10 },
  heading: { fontSize: 16, marginBottom: 10, textAlign: "center" },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    marginBottom: 10,
    border: "0.5px solid black",
  },
  tableRow: { flexDirection: "row" },
  tableCell: {
    padding: 5,
    fontSize: 12,
    textAlign: "center",
    flex: 1,
    border: "0.5px solid black",
    borderCollapse: "collapse",
  },
  tableHeaderCell: {
    fontFamily: "Open Sans",
    padding: 5,
    fontSize: 14,
    textAlign: "center",
    fontWeight: "bold",
    flex: 1,
    border: "0.5px solid black",
    borderCollapse: "collapse",
  },
  tableHeader: { flexDirection: "row" },
  subtitle:{fontFamily: 'Open Sans',fontSize:14,fontWeight:'normal'},
  text:{flexDirection:'row', alignItems:'center',fontFamily:"Open Sans", marginTop:10},
  maintext:{fontWeight:'bold', fontSize:14 ,fontFamily:"Open Sans"}
});

const EvaluationTable = ({ evaluationData }) => {
  const filteredData = Object.keys(evaluationData)
    .filter(
      (key) =>
        key !== "demo_id" &&
        key !== "package_recommendation" &&
        key !== "id" &&
        key !== "average_score" &&
        key !== "recommendation_reason" &&
        key !== "created_by" &&
        key !== "updated_by" &&
        key !== "createdAt" &&
        key !== "updatedAt"
    )
    .reduce((obj, key) => {
      obj[key] = evaluationData[key];
      return obj;
    }, {});

  return (
    <>
      <View>
        <View style={styles.table}>
          <View style={styles.tableRow}>
            <Text style={styles.tableHeaderCell}>S. No</Text>
            <Text style={styles.tableHeaderCell}>Skill</Text>
            <Text style={styles.tableHeaderCell}>Score</Text>
          </View>
          {Object.entries(filteredData).map(([skill, score], index) => (
            <View style={styles.tableRow} key={skill}>
              <Text style={styles.tableCell}>{index + 1}</Text>
              <Text style={styles.tableCell}>
                {capitalizeFirstLetter(skill.replace(/_/g, " "))}
              </Text>
              <Text style={styles.tableCell}>{score}</Text>
            </View>
          ))}
        </View>

        <View style={{ marginTop: "20px" }}>
          <View style={styles.text}>
            <Text style={styles.subtitle}> Current Band Score Level </Text>{" "}
            <Text style={styles.maintext}>
              {" "}
              : {evaluationData.average_score}
            </Text>
          </View>
          <View style={styles.text}>
            {" "}
            <Text style={styles.subtitle}>
              Time/Duration required for improvement{" "}
            </Text>
            <Text style={styles.maintext}>
              {" "}
              : {evaluationData.package_recommendation}
            </Text>
          </View>

          <View style={styles.text}>
            {" "}
            <Text style={styles.subtitle}>
              Reason for this recommendation{" "}
            </Text>
            <Text style={styles.maintext}>
              {" "}
              : {evaluationData?.recommendation_reason}
            </Text>
          </View>
        </View>
      </View>
    </>
  );
};

export default EvaluationTable;
