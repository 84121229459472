import React, { useState } from 'react';
import { Tabs, Tab, Chip, Typography, Box, Grid, Divider, Card } from '@mui/material';
import styled from '@emotion/styled';
import NotificationList from './ListNotifications';

const CustomChip = styled(Chip)(({ theme }) => ({
    width: '80%', // Adjust the width as needed
    padding: theme.spacing(3),
    //borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
    cursor: 'pointer',
    '&:hover': {
      backgroundColor: theme.palette.primary.dark,
    },
  }));

const TabContent = ({ value }) => {
  return (
    <div>
      {value === 0 && <NotificationList />}
      {value === 1 &&  <NotificationList />}
      {value === 2 &&  <NotificationList />}
    </div>
  );
};

const ClickableChips = () => {
  const [selectedTab, setSelectedTab] = useState(0);

  const handleTabChange = (event, newValue) => {
    setSelectedTab(newValue);
  };

  return (
    <div>
        <Box sx={{ padding: '16px', borderRadius: '8px', marginRight: '30px', marginLeft: '30px' }}>

        <Chip 
            label= 'Notifications'
            style={{ width: '200px', fontSize: '16px', fontWeight: 'bold' }}
            
        />
        </Box>
     {/* <Tabs value={selectedTab} onChange={handleTabChange}>
        <Tab label="Tab 1" />
        <Tab label="Tab 2" />
        <Tab label="Tab 3" />
      </Tabs> */}

    <Box sx={{ padding: '16px', borderRadius: '8px', boxShadow: '0px 0px 5px rgba(0, 0, 0, 0.2)', marginRight: '30px', marginLeft: '30px' }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={6} >
        <Grid container spacing={3}>
        <Grid item xs={12}>
      <CustomChip
        //label="Click Me"
        onClick={() => setSelectedTab((selectedTab + 1) % 3)}
        label= {
            <>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant="body1" style={{ marginRight: '8px' }}>
                Task Notification
              </Typography>
              <Typography variant="body1" style={{ marginLeft: '8px' }}>
                Count: 0
              </Typography>
              </Box>
            </> 
        }
      />
      </Grid>

      <Grid item xs={12}>
      <CustomChip
        //label="Click Me"
        onClick={() => setSelectedTab((selectedTab + 1) % 3)}
        label= {
            <>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant="body1" style={{ marginRight: '8px' }}>
                Incoming Calls Notification
              </Typography>
              <Typography variant="body1" style={{ marginLeft: '8px' }}>
                Count: 0
              </Typography>
              </Box>
            </> 
        }
      />
      </Grid>

      <Grid item xs={12}>
      <CustomChip
        //label="Click Me"
        onClick={() => setSelectedTab((selectedTab + 1) % 3)}
        label= {
            <>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant="body1" style={{ marginRight: '8px' }}>
                HR One Notification
              </Typography>
              <Typography variant="body1" style={{ marginLeft: '8px' }}>
                Count: 0
              </Typography>
              </Box>
            </> 
        }
      />
      </Grid>

      <Grid item xs={12}>
      <CustomChip
        //label="Click Me"
        onClick={() => setSelectedTab((selectedTab + 1) % 3)}
        label= {
            <>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant="body1" style={{ marginRight: '8px' }}>
                Emails/Chats Notification
              </Typography>
              <Typography variant="body1" style={{ marginLeft: '8px' }}>
                Count: 0
              </Typography>
              </Box>
            </> 
        }
      />
      </Grid>

      <Grid item xs={12}>
      <CustomChip
        //label="Click Me"
        onClick={() => setSelectedTab((selectedTab + 1) % 3)}
        label= {
            <>
            <Box display='flex' justifyContent='space-between'>
              <Typography variant="body1" style={{ marginRight: '8px' }}>
                Student Panel Notification
              </Typography>
              <Typography variant="body1" style={{ marginLeft: '8px' }}>
                Count: 0
              </Typography>
              </Box>
            </> 
        }
      />
      </Grid>
      </Grid>
      
      </Grid>

     {/* <Grid item xs={12} md={2}>
      <Divider orientation="vertical" />
    </Grid> */}
        
        <Grid item xs={12} md={6}>
          <TabContent value={selectedTab} />
        </Grid>
        
        </Grid>
        </Box>
    </div>
  );
};

export default ClickableChips;
