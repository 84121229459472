import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { fDate } from "../../../utils/formatTime";
import ApproveOnlinePaymentFilter from "./ApproveOnlinePaymentFilter";
import ApproveOnlinePaymentInstallation from "./PaymentInstallation";
import CustomPopup from "../../../components/CustomPopup";
import { useGetTokenOrLogout } from "../../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const PATH_URL = process.env.REACT_APP_API_UPLOADS;

function ApproveOnlinePayments() {
  const navigate = useNavigate();
 
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const userRole=localStorage.getItem('userRole')
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [originalData, setOriginalData] = useState([]);
  const [openPopup, setOpenPopUp] = useState(false);
  const [detailsLoading, setDetailsLoading] = useState(false);
  const [detailsData, setDetailsData] = useState([]);
  const [opendeclined, setOpenDeclined] = useState(false);
  const [errors, setErrors] = useState(false);
  const [remark, setRemark] = useState(null);
const [selectedId, setSelectedId] =useState(null)
  const handleOpenDeclinedPopup = (id) => {
    setOpenDeclined(true);
    setSelectedId(id)
  };

  const handleCloseDeclinedPopup = () => {
    setOpenDeclined(false);
  };

  const handleOpenPopup = () => {
    setOpenPopUp(true);
  };

  const handleClosePopup = () => {
    setOpenPopUp(false);
  };
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleUpdateStatus = (paymentId, newStatus) => {
    // Update the status locally in the state
    const updatedPayments = payments.map((payment) =>
      payment.paymentId === paymentId
        ? { ...payment, payment_status: newStatus }
        : payment
    );
    setPayments(updatedPayments);

    // Send a request to the server to update the status
    fetch(`${API_ENDPOINT}route/editprovesional/${paymentId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        payment_status: newStatus,
        user_id: parseInt(userId),
      }),
    })
      .then((response) => {
        if (response.ok) {
          showSnackbar("Payment status updated successfully", "success");
          // Remove the row from state if the status is updated successfully
          const updatedPayments = payments.filter(
            (payment) => payment.id !== paymentId
          );
          setPayments(updatedPayments);
        } else {
          showSnackbar("Failed to update payment status", "error");
          throw new Error("Failed to update payment status");
        }
        // Handle successful response if needed
      })
      .catch((error) => {
        console.error("Error updating payment status:", error);
        showSnackbar("Error updating payment status", "error");
        // Revert the status locally if the server update fails
        setPayments(payments);
      });
  };

  //http://localhost:3001/api/leadmanagement/route2.0/provesionalonlinepayment/1
  useEffect(() => {
    setIsLoading(true);
    const token = getTokenOrLogout();
if (!token) return;
let headers = { 
  Authorization: `Bearer ${token}`,
  
};
    // Fetch expenses data
    fetch(`${API_ENDPOINT}route2.0/provesionalonlinepayment/${userId}`,{headers})
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        const activePayments = data.filter(
          (item) =>
        
            item?.payment_mode_id?.payment_mode === "Online payment"
        );
        console.log(activePayments);
        // Sort the filtered data by payment_date
        const sortedData = activePayments.sort(
          (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
        );

        
        setPayments(sortedData);
        console.log(sortedData);
        setIsLoading(false);
        setOriginalData(sortedData);
      })
      .catch((error) => {
        if (error.message.includes("401")) {
          navigate("/page401");
        } else if (error.message.includes("403")) {
          navigate("/page403");
        } else if (error.message.includes("404")) {
          navigate("/page404");
        }  else if (error.message.includes("440")) {
          navigate("/page440");
        } 
        else {
          navigate("/page500");
        }
        setIsLoading(false);
      
      });
  }, [userId]);

  const handleViewDepositSlip = (filePath) => {
    window.open(`${PATH_URL}paymentScreenshots/${filePath}`, "_blank");
  };

  
  const handleViewdetails = async (enquiry_id, class_id, package_id, data) => {
    console.log(
      "enquiry_id=",
      enquiry_id,
      "package_id=",
      package_id,
      "class_id=",
      class_id
    );
    console.log(data);
    setDetailsLoading(true);

    const requestData = {
      class_id: class_id,
      package_id: package_id,
      enquiry_id: enquiry_id,
      user_id: parseInt(userId),
    };
    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/showenqforaccount`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        const result = await response.json();
        setDetailsData(result);
        setDetailsLoading(false);
        handleOpenPopup();
      } else if (response.status === 401) {
        // Handle errors, e.g., show an error message
        showSnackbar("Please Verify that You are a User!!", "error");
        setDetailsLoading(false);
      } else if (response.status === 403) {
        showSnackbar("Unathorized User", "error");
        setDetailsLoading(false);
      } else {
        showSnackbar("Internal  Server Error", "error");
        setDetailsLoading(false);
      }
    } catch (error) {
      showSnackbar("Internal  Server Error", "error");
      setDetailsLoading(false);
    }
  };

  const handleRemarkChange = (e) => {
    setRemark(e.target.value);
    setErrors({ ...errors, remark: !e.target.value });
  };

  const handleDeclinedPayment = async () => {
    if (!remark) {
          setErrors({ remark: !remark });
          return;
        }
    
        const formdata={
         id :selectedId,
         remarks:remark,
         user_id :parseInt(userId)
        }

    try {
    
      const response = await fetch(
        `${API_ENDPOINT}route/declineonlinepayments`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(formdata),
        }
      );

      if (response.ok) {
        
        showSnackbar("Payment Declined Successfully")
        setTimeout(() => {
          window.location.reload()
        }, 1500);
        
      } else if (response.status === 400) {
        showSnackbar("Id not found",'error')
      
      } else if (response.status === 401) {
        showSnackbar("Unathorized User",'error')
      }
       else {
        showSnackbar("Error Posting Data", "error");
     
      }
    } catch (error) {
      console.error("Error posting data:", error);
      showSnackbar("Error Posting Data", "error");
     
    }
  };

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Backdrop open={detailsLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching Installment Details
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <ApproveOnlinePaymentFilter
           setPayments={setPayments}
           originalData={originalData}
           isLoading={isLoading}
           showSnackbar={showSnackbar}
           setIsLoading={setIsLoading}
          />
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{ borderRadius: "15px", padding: "10px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Enquiry Details
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Branch
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Date
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Razorpay Link / Payment ID
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Amount Collected
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Payment Initiated From
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Initiated By
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.length === 0 ? (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">No data available</Typography>
              </Grid>
            ) : (
              payments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f0f0f0" : "inherit",
                      width: "6%",
                    }}
                  >
                    <TableCell
                      sx={{
                        color: "blue",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "none",
                        },
                      }}
                      onClick={() =>
                        handleViewdetails(
                          row?.enquiry_id?.id,
                          row?.class_id,
                          row?.package_id,
                          row
                        )
                      }
                    >
                      {`${
                        row?.enquiry_id?.first_name
                          ? row?.enquiry_id?.first_name
                          : ""
                      } ${
                        row?.enquiry_id?.last_name
                          ? row?.enquiry_id?.last_name
                          : ""
                      }`}{" "}
                      <br />
                      {row.enquiry_id?.mobile && (
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            color: "#364152",
                          }}
                        >
                          {row.enquiry_id?.mobile}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.enquiry_id.branch_id?.branch_name}
                    </TableCell>
                    <TableCell>{fDate(row?.payment_date)}</TableCell>
                    <TableCell>
                      {row?.payment_initiated_from === "student panel"
                        ? row?.razorpay_payment_id
                        : row?.razorpay_link_id}
                    </TableCell>
                    <TableCell>{row?.amount_paid}</TableCell>
                    <TableCell>{row?.payment_initiated_from}</TableCell>
                    <TableCell>{row?.created_by?.first_name} {row?.created_by?.last_name}</TableCell>
                    <TableCell>
                      {row?.collected_status === "received" && (
                        <Chip label="Generated" color="error" />
                      )}
                      {row?.collected_status === "deposited" && (
                        <Chip label="Deposited" color="warning" />
                      )}
                      {row?.collected_status === "confirmed" && (
                        <Chip label="Confirmed" color="primary" />
                      )}
                      {row?.collected_status === "declined" && (
                        <Chip label="Declined" color="error" />
                      )}
                    </TableCell>
                    <TableCell>
                      <div style={{ display: "flex", gap: "10px" }}>
                        {row?.payment_screenshot_id && (
                          <Button
                            variant="contained"
                            color="secondary"
                            onClick={() =>
                              handleViewDepositSlip(
                                row?.payment_screenshot_id?.file_uploaded
                              )
                            }
                          >
                            View Payment Screenshot
                          </Button>
                        )}
                        {userRole==="accountant" && ( 
                          <>
                        {row?.collected_status === "deposited" && (
                          <Button
                            variant="contained"
                            
                            onClick={() => handleUpdateStatus(row.id, "paid")}
                          >
                            Change Status to Confirmed
                          </Button>
                        )}
                        {row?.collected_status === "deposited" && (
                          <Button
                            variant="contained"
                            color="error"
                            onClick={() => handleOpenDeclinedPopup(row.id)}
                          >
                            Decline
                          </Button>
                        )}
                        </>
                      )}
                      </div>
                    </TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={payments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
      <CustomPopup
        open={openPopup}
        onClose={handleClosePopup}
        component={
          <ApproveOnlinePaymentInstallation
            onClose={handleClosePopup}
            data={detailsData}
          />
        }
        title="Installment Details"
        showDivider="true"
        maxWidth="lg"
      />

      {/* Declined Dialog */}
      <Dialog
        open={opendeclined}
        onClose={handleCloseDeclinedPopup}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <Typography sx={{ fontWeight: 600 }}>
              Remark{" "}
              <span
                style={{
                  color: "red",
                  position: "absolute",
                  marginLeft: "4px",
                  fontSize: "1em",
                }}
              >
                *
              </span>
            </Typography>
            <TextField
              placeholder="Type anything…"
              value={remark}
              fullWidth
              multiline
              rows={4}
              onChange={handleRemarkChange}
              InputProps={{ style: { borderRadius: "15px" } }}
              error={errors.remark}
              helperText={errors.remark && "Remark is required"}
            />
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDeclinedPopup} variant="contained">
            Cancel
          </Button>
          <Button
            onClick={handleDeclinedPayment}
            autoFocus
            variant="contained"
            color="error"
          >
            Decline
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default ApproveOnlinePayments;
