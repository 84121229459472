import React, { useEffect, useState } from 'react'
import MainCard from '../../components/Maincard'
import { Box, Button, FormControl, Select, Checkbox, ListItemText,  Chip, CircularProgress, Grid, Menu, MenuItem, Stack,styled, TextField, Typography, Paper } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import { DataGrid } from '@mui/x-data-grid';
import { useNavigate } from 'react-router-dom';
import { useGetTokenOrLogout } from '../../utils/token';



export default function AdvisorReport() {
  const navigate=useNavigate()
 
   const getTokenOrLogout = useGetTokenOrLogout();
    const ITEM_HEIGHT = 48;
    const ITEM_PADDING_TOP = 8;
    const MenuProps = {
      PaperProps: {
        style: {
          maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
          width: 250,
        },
      },
    };
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const [branchlist, setBranchlist] = useState([]);
    const [branch, setBranch] = useState([]);
const [data,setData] =useState([])
    const [loading,setLoading]=useState(false)


  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handelbranchchange = (e) => {
    const selectedBranches = e.target.value;
console.log(selectedBranches)
    // If "Select All" is selected, toggle between selecting and deselecting all branches
    if (selectedBranches.includes("all")) {
      const allBranchIds = branchlist.map((branch) => branch.id);
      if (branch.length === allBranchIds.length) {
        setBranch([]); // Deselect all branches
       // Update form value to deselect all branches
      } else {
        setBranch(allBranchIds); // Select all branches
        // Update form value to select all branches
      }
    } else {
      setBranch(selectedBranches);
     
    }
  };

  const handleSearch = async () => {
    const token = getTokenOrLogout();
    console.log("token",token)
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json" // Make sure to keep the content type
    };

    try {
        setLoading(true);
        const response = await fetch(`${API_ENDPOINT}route2.0/advisorbybranch`, {
            method: 'POST',
            headers,
            body: JSON.stringify({ branch_id:branch })
        });

        if (response.status === 200) {
            const data = await response.json();
            // Handle successful response
            setData(data)
            console.log(data);
            setLoading(false)
        }  else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }}catch (error) {
          console.error("Error fetching data:", error);
          navigate("/page500");
        }
};

const handleclick = (row) => {
  const url = `/advisorrevenue/${row.user_id}`;
  const newTab = window.open(url, '_blank');
  if (newTab) {
      newTab.focus();
  } 
  
};

const handleReset=()=>{
setData([])
setBranch([])
}
    const columns = [
        {
          field: "name",
          headerName: "Name of Advisor",
flex:2,
          headerAlign: "center",
          headerClassName: "super-app-theme--header",
          align: "center",
          renderCell: (params) => (
       
            <> 
               <Typography color='primary' sx={{cursor:'pointer'}} onClick={() => handleclick(params.row)}>{params.row?.name}</Typography></>
          ),
        },
    
        {
          field: "branch",
          headerName: " Branch",
    flex:1,
          headerAlign: "center",
          headerClassName: "super-app-theme--header",
          align: "center",
          renderCell: (params) => (
            
           <>
           <Typography>{params.row?.branch}</Typography>
           </>
          ),
        },
    ]

   
    const StyledGridOverlay = styled("div")(({ theme }) => ({
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        "& .ant-empty-img-1": {
          fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
        },
        "& .ant-empty-img-2": {
          fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
        },
        "& .ant-empty-img-3": {
          fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
        },
        "& .ant-empty-img-4": {
          fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
        },
        "& .ant-empty-img-5": {
          fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
          fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
        },
      }));
    
      function CustomNoRowsOverlay() {
        return (
          <StyledGridOverlay>
            <Box sx={{ mt: 1 }}>No Data</Box>
          </StyledGridOverlay>
        );
      }
  return (
    <>
    <Grid container spacing={2}>

    <Grid item xs={12} lg={2}>
    
        <Paper sx={{borderRadius:'24px', height:'60px' , display:'flex' , flexDirection:'row', alignItems:'center', }}>
   
        <Box width='40px' ml={1}>
        <img src='/documents.png'  width='100%'/></Box>
          <Typography textAlign='center' variant='h3' ml={1}>Advisor Report</Typography>
      
     
        </Paper>
                
            </Grid>
        <Grid item xs={12}>
    <MainCard>
        
        <Grid container spacing={3}>
          
            <Grid item xs={12} lg={6}>
    
        <FormControl sx={{ mb: 1, width: "100%" }}>
              <Typography sx={{ fontWeight: 600 }}> Center / Branch</Typography>
              <Select
                multiple
                value={branch}
                onChange={handelbranchchange}
                MenuProps={MenuProps}
                fullWidth
                renderValue={(selected) =>
                  selected
                    .map((value) => {
                      const branches = branchlist.find(
                        (status) => status.id === value
                      );
                      return branches ? branches.branch_name : "";
                    })
                    .join(", ")
                }
                style={{ borderRadius: "15px" }}
              >
                <MenuItem key="select-all" value="all">
                    <Checkbox checked={branch.length === branchlist.length} />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                {branchlist.map((name) => (
                  <MenuItem key={name.id} value={name.id}>
                    <Checkbox checked={branch.indexOf(name.id) > -1} />
                    <ListItemText primary={name.branch_name} />
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
</Grid>

<Grid item xs={4} lg={1.2} mt={2}>
<Button color='secondary'  variant='contained' startIcon={<SearchIcon fontSize='medium'/>} style={{fontSize:'16px' , borderRadius:'24px'}} onClick={handleSearch}>Search</Button>
</Grid>
<Grid item xs={4} lg={2} mt={2}>
<Button color='error'  variant='contained'  style={{fontSize:'16px' , borderRadius:'24px'}} onClick={handleReset}>Clear</Button>
</Grid>

</Grid>
    </MainCard>

    </Grid>

    <Grid item  xs={12}>
  
    <MainCard >
    {loading ? (
      <Box sx={{display:'flex' , alignItems:'center', justifyContent:'center'}}>
        <CircularProgress/>
      </Box>
    ):(
    <DataGrid
                   rows={data}
                  columns={columns}
                  autoHeight
                  slots={{
                    noRowsOverlay: CustomNoRowsOverlay,
                  }}
                  initialState={{
                    pagination: {
                      paginationModel: {
                        pageSize: 25,
                      },
                    },
                  }}
                  sx={{ marginTop: "20px" }}
                  pageSizeOptions={[25, 50, 100]}
                />
                )}
    </MainCard>
   
    </Grid>
    </Grid>
    </>
  )
}
