import { Box, Stack, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
export default function LeadCalandar( {onDateChange}) {
    const [currentMonth, setCurrentMonth] = useState(new Date());

    const handlePrevMonth = () => {
      const newMonth = new Date(currentMonth);
      newMonth.setMonth(currentMonth.getMonth() - 1);
      setCurrentMonth(newMonth);
    };
  
    const handleNextMonth = () => {
      const today = new Date();
      if (currentMonth.getMonth() >= today.getMonth() && currentMonth.getFullYear() >= today.getFullYear()) {
        // If current month is today's month or beyond, don't go further
        return;
      }

      const newMonth = new Date(currentMonth);
      newMonth.setMonth(currentMonth.getMonth() + 1);
      setCurrentMonth(newMonth);
    };
  
    const monthOptions = { month: "long" };
    const yearOptions = { year: "numeric" };

    const today = new Date();
    const isNextDisabled = currentMonth.getMonth() >= today.getMonth() && currentMonth.getFullYear() >= today.getFullYear();

    useEffect(() => {
      onDateChange(currentMonth);
  }, [currentMonth, onDateChange]);

  return (
   <>
   <Stack display='flex'  flexDirection='row' style={{display:"flex" , justifyContent:'space-between'}}  >
      <Typography onClick={handlePrevMonth} style={{ cursor: "pointer" }}>
        <ArrowBackIosIcon />
      </Typography>
      <Typography>
        {currentMonth.toLocaleDateString("en-US", monthOptions)}{" "}
        {currentMonth.toLocaleDateString("en-US", yearOptions)}
      </Typography>
      <Typography onClick={handleNextMonth} style={{ cursor: "pointer", opacity: isNextDisabled ? 0.5 : 1 }}>
        <ArrowForwardIosIcon />
      </Typography>
      </Stack>
      </>
  )
}
