import React from 'react'
import MainCard from '../../newTestPrep/components/ui-component/card/MainCard'
import { Stack, Typography } from '@mui/material'

function PerformanceCards({imgSrc, text, count}) {
  return (
    <div>
        <MainCard>
        <Stack
          direction='row'
          justifyContent='space-between'
          alignItems='center'
          sx={{
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 400,
            lineHeight: '20px',
          }}
        >
          <Stack direction='row' spacing={1}>
            <img
              src={imgSrc}
              alt='Demo Scheduled'
              style={{ width: 34, height: 34 }} // Adjust size here
            />
            <Typography
              sx={{
                fontFamily: 'Poppins',
                fontSize: '16px',
                fontWeight: 600,
                lineHeight: '20px',
                letterSpacing: '-0.02em',
                textAlign: 'right',
                display: 'inline-block',
                maxWidth: '80px', // Ensure text wraps within available space
                whiteSpace: 'normal', // Prevent wrapping of individual words
                overflow: 'hidden',
                textOverflow: 'ellipsis', // Show ellipsis for overflowed text
              }}
            >
             {text}
            </Typography>
          </Stack>
          <Typography sx={{
            fontFamily: 'Poppins',
            fontSize: '16px',
            fontWeight: 500,
            lineHeight: '20px',
            letterSpacing: '-0.02em',
            textAlign: 'right',
          }}>
            {count}
          </Typography>
        </Stack>
      </MainCard>
    </div>
  )
}

export default PerformanceCards