// EvaluationQuestionsDialog.js

import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import { Backdrop, Box, Grid, MenuItem, Typography } from "@mui/material";

const EvaluationQuestionsDialog = ({
  open,
  onClose,
  onSubmit,
  selectedDemo,
}) => {
  const [englishProficiency, setEnglishProficiency] = useState("");
  const [recommendedPackages, setRecommendedPackages] = useState("");
  const [validationError, setValidationError] = useState("");
  const [skillScore, setSkillScore] = useState("");
  const [syntaxStructure, setSyntaxStructure] = useState(null);
  const [vocabulary, setVocabulary] = useState("");
  const [fluency, setFluency] = useState("");
  const [pitchRange, setPitchRange] = useState("");
  const [orthography, setOrthography] = useState("");
  const [pronunciation, setPronunciation] = useState("");
  const [bodyLanguage, setBodyLanguage] = useState("");
  const [behavioralSense, setBehavioralSense] = useState("");
  const [awarenessLevel, setAwarenessLevel] = useState("");
  const [textualScanning, setTextualScanning] = useState("");
  const [grammaticalSense, setGrammaticalSense] = useState("");
  const [logicDeduction, setLogicDeduction] = useState("");
  const [timeManagement, setTimeManagement] = useState("");
  const [expressingEfficiency, setExpressingEfficiency] = useState("");
  const [reason, setReason] = useState("");

  console.log(selectedDemo);

  const handleSubmit = () => {
    // Validate all fields
    if (
      !recommendedPackages ||
      !vocabulary ||
      !fluency ||
      !pitchRange ||
      !pronunciation ||
      !awarenessLevel ||
      !grammaticalSense ||
      !timeManagement ||
      !expressingEfficiency
    ) {
      setValidationError("All fields are required.");
      return;
    }

    setValidationError("");

    const evaluationAnswers = {
      vocabulary: parseInt(vocabulary),
      fluency: parseInt(fluency),
      pitch_range: parseInt(pitchRange),

      pronunciation: parseInt(pronunciation),

      awareness_level: parseInt(awarenessLevel),

      grammitical_sense: parseInt(grammaticalSense),

      time_management: parseInt(timeManagement),
      package_recommendation: recommendedPackages,
      recommendation_reason: reason,
    };

    console.log(evaluationAnswers);

    onSubmit(evaluationAnswers);

    // Reset all fields after submission

    setRecommendedPackages("");
    setSkillScore("");
    setSyntaxStructure("");
    setVocabulary("");
    setFluency("");
    setPitchRange("");
    setOrthography("");
    setPronunciation("");
    setBodyLanguage("");
    setBehavioralSense("");
    setAwarenessLevel("");
    setTextualScanning("");
    setGrammaticalSense("");
    setLogicDeduction("");
    setTimeManagement("");
    setExpressingEfficiency("");
    setReason("");
  };

  console.log(vocabulary);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{ invisible: true }}
      maxWidth="md"
      fullWidth
    >
      <DialogTitle variant="h4">Evaluation Questions</DialogTitle>
      <DialogContent>
        <div>
          <Box mb={2}>
            <Typography>Rate the skills (1-9)</Typography>
          </Box>
          <Grid container spacing={2}>
            <Grid item xs={6} lg={4}>
              <label>Vocabulary</label>
              <TextField
                label="Vocabulary"
                variant="outlined"
                fullWidth
                type="number"
                inputProps={{
                  min: 1,
                  max: 9,
                  step: 1,
                }}
                value={vocabulary}
                onChange={(e) => {
                  const enteredValue = parseInt(e.target.value, 10);
                  if (
                    !isNaN(enteredValue) &&
                    enteredValue >= 1 &&
                    enteredValue <= 9
                  ) {
                    setVocabulary(e.target.value);
                  }
                }}
              />
            </Grid>

            <Grid item xs={6} lg={4}>
              <label>Fluency</label>
              <TextField
                label="Fluency"
                variant="outlined"
                fullWidth
                type="number"
                inputProps={{
                  min: 1,
                  max: 9,
                  step: 1,
                }}
                value={fluency}
                onChange={(e) => {
                  const enteredValue = parseInt(e.target.value, 10);
                  if (
                    !isNaN(enteredValue) &&
                    enteredValue >= 1 &&
                    enteredValue <= 9
                  ) {
                    setFluency(e.target.value);
                  }
                }}
              />
            </Grid>

            <Grid item xs={6} lg={4}>
              <label>Pitch Range</label>
              <TextField
                label="Pitch Range"
                variant="outlined"
                fullWidth
                type="number"
                inputProps={{
                  min: 1,
                  max: 9,
                  step: 1,
                }}
                value={pitchRange}
                onChange={(e) => {
                  const enteredValue = parseInt(e.target.value, 10);
                  if (
                    !isNaN(enteredValue) &&
                    enteredValue >= 1 &&
                    enteredValue <= 9
                  ) {
                    setPitchRange(e.target.value);
                  }
                }}
              />
            </Grid>

            <Grid item xs={6} lg={4}>
              <label>Pronunciation</label>
              <TextField
                label="Pronunciation"
                variant="outlined"
                fullWidth
                type="number"
                inputProps={{
                  min: 1,
                  max: 9,
                  step: 1,
                }}
                value={pronunciation}
                onChange={(e) => {
                  const enteredValue = parseInt(e.target.value, 10);
                  if (
                    !isNaN(enteredValue) &&
                    enteredValue >= 1 &&
                    enteredValue <= 9
                  ) {
                    setPronunciation(e.target.value);
                  }
                }}
              />
            </Grid>

            <Grid item xs={6} lg={4}>
              <label>Awareness Level</label>
              <TextField
                label="Awareness Level"
                variant="outlined"
                fullWidth
                type="number"
                inputProps={{
                  min: 1,
                  max: 9,
                  step: 1,
                }}
                value={awarenessLevel}
                onChange={(e) => {
                  const enteredValue = parseInt(e.target.value, 10);
                  if (
                    !isNaN(enteredValue) &&
                    enteredValue >= 1 &&
                    enteredValue <= 9
                  ) {
                    setAwarenessLevel(e.target.value);
                  }
                }}
              />
            </Grid>

            <Grid item xs={6} lg={4}>
              <label>Grammatical Sense</label>
              <TextField
                label="Grammatical Sense"
                variant="outlined"
                fullWidth
                type="number"
                inputProps={{
                  min: 1,
                  max: 9,
                  step: 1,
                }}
                value={grammaticalSense}
                onChange={(e) => {
                  const enteredValue = parseInt(e.target.value, 10);
                  if (
                    !isNaN(enteredValue) &&
                    enteredValue >= 1 &&
                    enteredValue <= 9
                  ) {
                    setGrammaticalSense(e.target.value);
                  }
                }}
              />
            </Grid>

            <Grid item xs={6} lg={4}>
              <label>Time Management</label>
              <TextField
                label="Time Management"
                variant="outlined"
                fullWidth
                type="number"
                inputProps={{
                  min: 1,
                  max: 9,
                  step: 1,
                }}
                value={timeManagement}
                onChange={(e) => {
                  const enteredValue = parseInt(e.target.value, 10);
                  if (
                    !isNaN(enteredValue) &&
                    enteredValue >= 1 &&
                    enteredValue <= 9
                  ) {
                    setTimeManagement(e.target.value);
                  }
                }}
              />
            </Grid>

            <Grid item xs={6} lg={4}>
              <label>Expressing Efficiency</label>
              <TextField
                label="Expressing Efficiency"
                variant="outlined"
                fullWidth
                type="number"
                inputProps={{
                  min: 1,
                  max: 9,
                  step: 1,
                }}
                value={expressingEfficiency}
                onChange={(e) => {
                  const enteredValue = parseInt(e.target.value, 10);
                  if (
                    !isNaN(enteredValue) &&
                    enteredValue >= 1 &&
                    enteredValue <= 9
                  ) {
                    setExpressingEfficiency(e.target.value);
                  }
                }}
              />
            </Grid>

            <Grid item xs={12} lg={12}>
              <label>Time/Duration required for improvement:</label>
              <TextField
                label="Recommended Day Packages"
                select
                variant="outlined"
                fullWidth
                value={recommendedPackages}
                onChange={(e) => setRecommendedPackages(e.target.value)}
              >
                <MenuItem value="10 days">10 days</MenuItem>
                <MenuItem value="15 days">15 days</MenuItem>
                <MenuItem value="20 days">20 days</MenuItem>
                <MenuItem value="30 days">30 days</MenuItem>
                <MenuItem value="45 days">45 days</MenuItem>
                <MenuItem value="60 days">60 days</MenuItem>
              </TextField>
            </Grid>

            <Grid item xs={12} lg={12}>
              <label>Reason for this recommendation:</label>
              <TextField
                label="Reason"
                multiline
                variant="outlined"
                fullWidth
                value={reason}
                onChange={(e) => setReason(e.target.value)}
              ></TextField>
            </Grid>
          </Grid>
        </div>
      </DialogContent>

      {validationError && (
        <DialogContent>
          <div style={{ color: "red" }}>{validationError}</div>
        </DialogContent>
      )}
      <DialogActions>
        <Button onClick={onClose} color="error" variant="contained">
          Cancel
        </Button>
        <Button onClick={handleSubmit} color="secondary" variant="contained">
          Submit
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default EvaluationQuestionsDialog;
