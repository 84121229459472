import React, { useCallback, useEffect, useMemo, useState } from "react";
import { useLocation, useParams } from "react-router-dom";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import "react-big-calendar/lib/css/react-big-calendar.css";

import "./calendarStyle.css";
import MainCard from "../../../components/Maincard";
import {
  Backdrop,
  Box,
  CircularProgress,
  colors,
  Grid,
  Modal,
  styled,
  Typography,
} from "@mui/material";
import CustomPopup from "../../../components/CustomPopup";
import PackageData from "./PackageData";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";

const localizer = momentLocalizer(moment);

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function DemoCalendar() {
  const { id } = useParams();
  const location = useLocation();
  const [classDetails, setClassDetails] = useState({});

  const [events, setEvents] = useState([]);
  const [data, setdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [action, setaction] = useState("TODAY");
  const [firstDate, setFirstDate] = useState(null);
  const [lastDate, setLastDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [selectedDateData, setSelectedDateData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  useEffect(() => {
    // Retrieve the state from localStorage
    const storedClassDetails = localStorage.getItem("classDetails");
    if (storedClassDetails) {
      setClassDetails(JSON.parse(storedClassDetails));
    }
  }, [id]);

  // Fetch Slots Data

  useEffect(() => {
    setLoading(true);
    fetch(`${API_ENDPOINT}route2.0/classcalendardetail/${id}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else if (response.status === 400) {
          return; // No need to continue further
        } else if (response.status === 404) {
          // not found
        }
      })
      .then((data) => {
        if (!data) return; // Stop further execution if data is not present

        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        let firstDate = new Date(sortedData[0].date);
        firstDate.setDate(firstDate.getDate() - 1);

        setdata(sortedData);
        setFirstDate(firstDate);
        setLastDate(new Date(sortedData[sortedData.length - 1].date));
        const transformedEvents = sortedData.flatMap((item) => {
          const events = [];
          if (item.emp_status === "absent") {
            events.push({
              start: new Date(item.date),
              end: new Date(item.date),
              title: "No Demo Slots . Trainer is Absent",
              type: "absent",
              status: item.emp_status,
            });
          } else if (item.emp_status === "meeting") {
            events.push({
              start: new Date(item.date),
              end: new Date(item.date),
              title: `No Demo Slots   Trainer is in Meeting`,
              type: "meeting",
              status: item.emp_status,
            });
          } else {
            events.push({
              start: new Date(item.date),
              end: new Date(item.date),
              title: `Total Capacity: ${item.total_capacity}`,
              type: "total_capacity",
              status: item.emp_status,
            });

            events.push({
              start: new Date(item.date),
              end: new Date(item.date),
              title: `Book Demo Slot: ${item.booked_demo_slot}`,
              type: "book_slot",
              status: item.emp_status,
            });

            events.push({
              start: new Date(item.date),
              end: new Date(item.date),
              title: `Available Demo Slot: ${item.available_demo_slot}`,
              type: "available_slot",
              status: item.emp_status,
            });

            events.push({
              start: new Date(item.date),
              end: new Date(item.date),
              title: `Enrolled Students: ${item.enrolled_student}`,
              type: "enrolled_student",
              status: item.emp_status,
            });

            // item.demos.forEach((demo) => {
            //   events.push({
            //     start: new Date(item.date),
            //     end: new Date(item.date),
            //     title: `Demo for ${demo.enquiry_name} (Advisor: ${demo.enquiry_owner})`,
            //     type: "demo",
            //     status: item.emp_status,
            //   });
            // });
          }
          return events;
        });

        setEvents(transformedEvents);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });
  }, []);

  console.log(firstDate);
  //Color for event
  const eventPropGetter = (event) => {
    let backgroundColor;
    switch (event.type) {
      case "total_capacity":
        backgroundColor = "lightblue";
        break;
      case "enrolled_student":
        backgroundColor = "lightgreen";
        break;
      case "demo":
        backgroundColor = "lightcoral";
        break;
      case "book_slot":
        backgroundColor = "#ffcccb";
        break;
      case "available_slot":
        backgroundColor = "#FFD580";
        break;
      case "absent":
        backgroundColor = "#ffcccb";
        break;
      case "meeting":
        backgroundColor = "#ffcccb";
        break;
      default:
        backgroundColor = "lightgrey";
    }

    return {
      style: {
        backgroundColor,
        borderRadius: "7px",

        color: "#000",
        border: "0px",
        display: "block",
        fontSize: "16px",
      },
    };
  };

  //hide Time
  const formats = {
    eventTimeRangeFormat: () => {
      return "";
    },
  };

  //Custom Header Styling
  const CustomToolbar = ({ label, onNavigate }) => {
    const navigate = (action) => {
      console.log(action);
      setaction(action);
      onNavigate(action);
    };

    const prev = `<< Prev`;
    const next = "Next  >>";
    return (
      <div className="rbc-toolbar">
        <span className="rbc-btn-group">
          <button
            type="button"
            onClick={() => navigate("PREV")}
            style={{
              cursor: "pointer",
              background: action === "PREV" ? "#00cf83" : "",
              fontSize: "16px",
            }}
          >
            {prev}
          </button>

          <button
            type="button"
            onClick={() => navigate("TODAY")}
            style={{
              background: action === "TODAY" ? "#00cf83" : "",
              cursor: "pointer",
              fontSize: "16px",
            }}
          >
            Current Month
          </button>
          <button
            type="button"
            onClick={() => navigate("NEXT")}
            style={{
              cursor: "pointer",
              background: action === "NEXT" ? "#00cf83" : "",
              fontSize: "16px",
            }}
          >
            {next}
          </button>
        </span>

        <span
          className="rbc-toolbar-label"
          style={{ fontWeight: 600, fontSize: "22px" }}
        >
          {label}
        </span>

        {/* For label showing center  added no main use  */}
        <span className="rbc-btn-group" style={{ visibility: "hidden" }}>
          <button
            type="button"
            onClick={() => navigate("PREV")}
            style={{
              cursor: "pointer",
              background: action === "PREV" ? "#00cf83" : "",
              fontSize: "16px",
            }}
          >
            {prev}
          </button>

          <button
            type="button"
            onClick={() => navigate("TODAY")}
            style={{
              background: action === "TODAY" ? "#00cf83" : "",
              cursor: "pointer",
              fontSize: "16px",
            }}
          >
            Current Month
          </button>
          <button
            type="button"
            onClick={() => navigate("NEXT")}
            style={{
              cursor: "pointer",
              background: action === "NEXT" ? "#00cf83" : "",
              fontSize: "16px",
            }}
          >
            {next}
          </button>
        </span>
      </div>
    );
  };

  const slotPropGetter = useCallback([]);

  //Datecell Background Color
  const dayPropGetter = (date) => {
    if (firstDate && lastDate) {
      if (date < firstDate || date > lastDate) {
        return {
          style: {
            backgroundColor: "#E8E8E8",
          },
        };
      }
    }
    const eventForDate = events.find(
      (event) => event.start.toDateString() === date.toDateString()
    );
    console.log(eventForDate);
    if (
      eventForDate &&
      (eventForDate.status === "absent" || eventForDate.status === "meeting")
    ) {
      return {
        style: {
          backgroundColor: "red",
        },
      };
    } else if (date.getDay() === 0) {
      return {
        style: {
          backgroundColor: "#E8E8E8",
        },
      };
    }

    return {};
  };

  const fetchDateDetails = async (date) => {
    // Handle form submission logic here
    setLoading(true);

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/classcalendardetailbydate`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            class_id: parseInt(id),
            date: moment(date).format("YYYY-MM-DD"),
          }),
        }
      );

      if (response.ok) {
        const result = await response.json();
        setSelectedDateData(result);
        handleOpen();
        setLoading(false);
      } else if (response.status === 404) {
        setSelectedDateData([]);
        handleOpen();
        setLoading(false);
      } else {
        showSnackbar("Internal  Server Error", "error");
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setLoading(false);
    }
  };

  const handleDateClick = (slotInfo) => {
    const { start } = slotInfo;

    const { type } = slotInfo;
    console.log("date is", slotInfo);

    setSelectedDate(start);

    if (
      type === "enrolled_student" ||
      type === "total_capacity" ||
      type === "book_slot" ||
      type === "available_slot"
    ) {
      fetchDateDetails(start);
    }
  };

  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <MainCard>
        <Grid container spacing={2}>
          <Grid item xs={12} sm={12} lg={12} md={12} textAlign="center">
            {" "}
            <Typography sx={{ fontWeight: 600, fontSize: "22px" }}>
              {" "}
              Class and Demo slots for the next 30 days
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12}>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }}>
              {classDetails.name}
            </Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }} mt={1}>
              Trainer : {classDetails?.trainer_id?.first_name}{" "}
              {classDetails?.trainer_id?.last_name}
            </Typography>
            <Typography sx={{ fontSize: "16px", fontWeight: 600 }} mt={1}>
              Branch : {classDetails?.branch_id?.branch_name}
            </Typography>
          </Grid>
          <Grid item xs={12} sm={12} lg={12} md={12} mt={1}>
            <Calendar
              formats={formats}
              localizer={localizer}
              defaultDate={new Date()}
              defaultView="month"
              events={events}
              views={["month"]}
              style={{ width: "100%", height: "auto", minHeight: "800px" }}
              eventPropGetter={eventPropGetter}
              popup
              components={{ toolbar: (props) => <CustomToolbar {...props} /> }}
              slotPropGetter={slotPropGetter}
              dayPropGetter={dayPropGetter}
              min={firstDate}
              max={lastDate}
              startAccessor="start"
              onSelectEvent={handleDateClick}
              selectable
            />
          </Grid>
        </Grid>
      </MainCard>

      <CustomPopup
        open={open}
        onClose={handleClose}
        component={
          <PackageData
            onClose={handleClose}
            selectedDateData={selectedDateData}
            selectedDate={selectedDate}
            moment={moment}
            data={data}
          />
        }
        maxWidth="sm"
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
