import React from "react";
import "./classCard.css";
import { fDate } from "../../../utils/formatTime";
import { Link } from "react-router-dom";

  function ClassCard ({ className, slotsLeft, moduleName, startDate, startTime, endDate, endTime, trainerName, deliveryMode, classCapacity, classId, path}) {
  return (
    <div className="group">
      <div className="overlap">
        <img className="icon-users" alt="Icon users" src="/Tranier.svg" />
        <Link to={`${path}/${classId}`}>
        <div className="group-wrapper">
          <div className="div">
           {/*} <img className="icon-arrow-left" alt="Icon arrow left" src="icon-arrow-left.png" /> */}
            <div className="text-wrapper">View class</div>
          </div>
        </div>
        </Link>
        <img className="icon-chalkboard" alt="Icon chalkboard" src="/Tranier.svg" />
        <div className="overlap-group">
          <img className="image" alt="Image" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRz5j-xPvyWQofMQzCd0GosKM0XOxoLzdnQzY4S6wulDM5sGOY1Zmcy9rux9PoX9kYEgFM&usqp=CAU" />
        </div>
        <div className="study-abroad-GMAT">
         {className}
        </div>
        <p className="p">{fDate(startDate)} - {fDate(endDate)}</p>
        <div className="text-wrapper-2">Reading,Writing</div>
        <img className="vector" alt="Vector" src="/Clock.svg" />
        <div className="text-wrapper-3">{trainerName}</div>
        <div className="text-wrapper-4">{deliveryMode}</div>
        <div className="text-wrapper-5">{classCapacity} Students</div>
        <div className="text-wrapper-6">Class Trainer</div>
        <div className="text-wrapper-7">Delivery Mode</div>
        <div className="text-wrapper-8">Class Capacity</div>
        <img className="img" alt="Vector" src="vector-21.svg" />
        <img className="vector-2" alt="Vector" src="/Delivery Mode.svg" />
        <div className="overlap-group-wrapper">
          <div className="div-wrapper">
            <div className="text-wrapper-9">{slotsLeft} seats left</div>
          </div>
        </div>
        <img className="icon-alternate" alt="Icon alternate" src="/headphone.svg" />
      </div>
    </div>
  );
};

export default ClassCard

/*const CardWidget = styled(Card) (({ bgColor, color }) => ({
    backgroundColor: '#ffffff',
    border: `1px solid ${color}`,
    boxSizing: `border-box`,
    borderRadius: `22px`,
    marginTop: '20px',
    width: '80%', // Set the width to 90% of the parent container
    height: '100%', // Automatically adjust the height based on content
    margin: '0 auto', // Center the card horizontally
    padding: '16px',
    //width: `225px`,
    //height: `126px`,
    //padding: '16px',
    //position: `absolute`,
    //left: `0px`,
    //top: `0px`,
   // display: 'flex',
  //flexDirection: 'column',
  //justifyContent: 'center',
  //alignItems: 'center',
  }));

    const Rectangle = styled.div`
        background-color: #ffffff;
        border: 1px solid;
        border-color: #000000;
        border-radius: 21px;
        box-shadow: 0px 0px 15px 1px #0000000d;
        height: 100px;
        display: flex;
        align-items: center; /* Vertically center the content 
        justify-content: center;
        `;

  const TextWrapper = styled.div`
        color: #3d3d3d;
        font-family: "Poppins-Regular", Helvetica;
        font-size: 12px;
        font-weight: 400;
        left: 0;
        letter-spacing: 0;
        line-height: normal;
        position: fixed;
        top: 0;
        `;

    const StudyAbroadGMAT = styled.div`
            color: #000000;
            font-family: "Poppins-Medium", Helvetica;
            font-size: 28px;
            font-weight: 500;
            height: 70px;
            left: 0;
            letter-spacing: 0;
            line-height: 34.7px;
            position: fixed;
            top: 0;
            `;

    const LabelTextWrapper = styled.div`
            color: #000000;
            font-family: "Poppins-Regular", Helvetica;
            font-size: 16px;
            font-weight: 400;
            left: 0;
            letter-spacing: 0;
            line-height: normal;
            position: fixed;
            top: 0;
            `;


  const Text = styled("div")({
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    fontSynthesis: `none`,
    color: `rgba(0, 0, 0, 1)`,
    fontStyle: `normal`,
    fontFamily: `Poppins`,
    fontWeight: `600`,
    fontSize: `24px`,
    letterSpacing: `0px`,
    textDecoration: `none`,
    lineHeight: `105.50000667572021%`,
    textTransform: `none`,
  });

  const Number = styled("div") (({ bgColor, color }) =>({
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    fontSynthesis: `none`,
    color: color,
    fontStyle: `normal`,
    fontFamily: `Bebas Neue`,
    fontWeight: `400`,
    fontSize: `64px`,
    letterSpacing: `0px`,
    textDecoration: `none`,
    lineHeight: `105.50000667572021%`,
    textTransform: `none`,
  }));

function ClassCard() {
  return (
    <Grid container spacing>
    <Grid item xs={6} md={4} lg={3}>
    <CardWidget>
        <Box>
            <Grid container spacing>
                <Grid item xs={6}>
                    <Rectangle>
                    <CardMedia
                        component="img"
                        //height="100%"
                        image="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRz5j-xPvyWQofMQzCd0GosKM0XOxoLzdnQzY4S6wulDM5sGOY1Zmcy9rux9PoX9kYEgFM&usqp=CAU"
                        alt="Paella dish"
                        style={{ objectFit: 'cover', width: '100%', height: '100%' }}
      />
                    </Rectangle>
                </Grid>
                <Grid item xs={6}>
                    <TextWrapper>Hi</TextWrapper>
                </Grid>
            </Grid>
        </Box>

    </CardWidget>
    </Grid>
            </Grid>
  )
}

export default ClassCard*/