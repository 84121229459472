// Author : Ayesha Shaikh
//  Date : 03 Oct 2024

import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Divider,
  Grid,
  Stack,
  styled,
  Typography,
} from "@mui/material";

import React, { useEffect, useState } from "react";
import { fDate } from "../../../utils/formatTime";
import { DataGrid } from "@mui/x-data-grid";
import EmployeeEnquiryData from "./EmployeeEnquiryData";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import EmployeeTaskData from "./EmployeeTaskData";
import CustomPopup from "../../../components/CustomPopup";
import NoEnquiry from "../../../views/admin/enquiries/NoEnquiry";
import EnquiryTransfer from "../../../views/admin/enquiries/EnquiryTransfer";
import EmployeeEnquiryTransfer from "./EmployeeEnquiryTransfer";
import NoTaskPopup from "../enquiry/tasks/NoTaskPopup";
import UserTaskTransfer from "../../../section/tasks/UserTaskTransfer";
import EmployeeTaskTransfer from "./EmployeeTaskTransfer";
import PreveEnquiryData from "./PreveEnquiryData";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EmployeeEnquiryTaskData({
  selectedId,
  setCheckedState,
  selectedRow,
  employeeData,
  setEmployeeData,
  setSelectedRow,
}) {
  const userId = localStorage.getItem("userId");
  const [selectedCriteria, setSelectedCriteria] = useState("enquiry");
  const [enquiriData, setEnquiryData] = useState([]);
  const [enquiryOriginalData, setEnquiryOriginalData] = useState([]);
  const [taskData, settaskData] = useState([]);
  const [originalTaskData, setOriginalTaskData] = useState([]);
  const [prevenquirydata, setPrevEnquirydata] = useState([]);
  const [prevEnquiryOriginalData, setPrevEnquiryOriginalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [taskLoading, setTaskLoading] = useState(false);
  const [prevenqLoading, setPrevEnqLoading] = useState(false);
  const [openEnquiryTransfer, setOpenEnquiryTransfer] = useState(false);
  const [selectedtransferIds, setSelectedTransferIds] = useState([]);
  const [isSelectAllChecked, setIsSelectAllChecked] = useState(false);
  const [openTaskransfer, setOpenTaskTransfer] = useState(false);
  const [showDisable, setShowDisable] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  console.log("selectedId", selectedId);
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleChangeCriteria = (criteria) => {
    setSelectedCriteria(criteria);
    setSelectedTransferIds([]);
    setIsSelectAllChecked(false);
    if (criteria === "enquiry") {
      getEnquiryData();
    }

    if (criteria === "task") {
      getTaskData(criteria);
    }

    if (criteria === "prevenquiry") {
      getPervEnquiryData(criteria);
    }
  };

  useEffect(() => {
    getEnquiryData();
    getTaskData();
    getPervEnquiryData();
  }, []);

  const getEnquiryData = async (event) => {
    setLoading(true);

    const requestData = {
      user_id: parseInt(userId),
      filter_for: selectedId,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}employee/enqdataforemp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        setEnquiryData(result);
        setEnquiryOriginalData(result);
        setLoading(false);
      } else if (response.status === 401) {
        // Handle errors, e.g., show an error message
        showSnackbar("Unknown User!!", "error");

        setEnquiryData([]);
        setEnquiryOriginalData([]);
        setLoading(false);
      } else if (response.status === 403) {
        showSnackbar("Unathorized User", "error");
        setEnquiryData([]);
        setEnquiryOriginalData([]);
        setLoading(false);
      } else if (response.status === 404) {
        showSnackbar("Could't find enquiry data!!", "error");
        setEnquiryData([]);
        setEnquiryOriginalData([]);
        setLoading(false);
      } else {
        showSnackbar("Error Fetching task Data", "error");
        setEnquiryData([]);
        setEnquiryOriginalData([]);
        setLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showSnackbar("Error Fetching task Data", "error");
      setEnquiryData([]);
      setEnquiryOriginalData([]);
      setLoading(false);
    }
  };

  const getTaskData = async (criteria) => {
    console.log("criteria", criteria, selectedCriteria);
    setTaskLoading(true);

    const requestData = {
      user_id: parseInt(userId),
      filter_for: selectedId,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}employee/taskdataforemp`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        settaskData(result);
        setOriginalTaskData(result);
        setTaskLoading(false);
      } else if (response.status === 401) {
        // Handle errors, e.g., show an error message
        showSnackbar("Unknown User!!", "error");
        settaskData([]);
        setOriginalTaskData([]);
        setTaskLoading(false);
      } else if (response.status === 403) {
        showSnackbar("Unathorized User", "error");

        settaskData([]);
        setOriginalTaskData([]);
        setTaskLoading(false);
      } else if (response.status === 404) {
        if (criteria === "task") {
          showSnackbar("Could't find task data!!", "error");
        }

        settaskData([]);
        setOriginalTaskData([]);
        setTaskLoading(false);
      } else {
        showSnackbar("Error Fetching task Data", "error");

        settaskData([]);
        setOriginalTaskData([]);
        setTaskLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showSnackbar("Error Fetching task Data", "error");

      settaskData([]);
      setOriginalTaskData([]);
      setLoading(false);
    }
  };

  const getPervEnquiryData = async (criteria) => {
    setPrevEnqLoading(true);

    const requestData = {
      user_id: parseInt(userId),
      filter_for: selectedId,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}employee/prevenqdataforemp`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        setPrevEnquirydata(result);
        setPrevEnquiryOriginalData(result);
        setPrevEnqLoading(false);
      } else if (response.status === 401) {
        // Handle errors, e.g., show an error message
        showSnackbar("Unknown User!!", "error");
        setPrevEnquirydata([]);
        setPrevEnquiryOriginalData([]);
        setPrevEnqLoading(false);
      } else if (response.status === 403) {
        showSnackbar("Unathorized User", "error");
        setPrevEnquirydata([]);
        setPrevEnquiryOriginalData([]);
        setPrevEnqLoading(false);
      } else if (response.status === 404) {
        if (criteria === "prevenquiry") {
          showSnackbar("Could't find any previous enquiry data!!", "error");
        }

        setPrevEnquirydata([]);
        setPrevEnquiryOriginalData([]);
        setPrevEnqLoading(false);
      } else {
        showSnackbar("Error Fetching previous enquiry Data", "error");
        setPrevEnquirydata([]);
        setPrevEnquiryOriginalData([]);
        setPrevEnqLoading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      showSnackbar("Error Fetching previous enquiry Data", "error");
      setPrevEnquirydata([]);
      setPrevEnquiryOriginalData([]);
      setPrevEnqLoading(false);
    }
  };

  const totalEnquiries = enquiriData.length;
  const totalTask = taskData.length;
  const totalPrevenquiry = prevenquirydata.length;

  console.log("Daata", totalEnquiries, totalTask);

  console.log("selectedRow", selectedRow);
  // Handle the switch change for a specific row
  const handleChangeStatus = async () => {
    const isChecked = selectedRow?.is_active === 0 ? true : false;

    try {
      const requestData = {
        is_active: isChecked,
        user_id: parseInt(userId),
      };
      const response = await fetch(
        `${API_ENDPOINT}employee/inactive/profile/${selectedRow?.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        showSnackbar(
          isChecked === true
            ? "User acount is activated successfully"
            : "User acount is deactivated successfully",
          "success"
        );

        const updatedData = employeeData.map((item) =>
          item?.id === selectedRow.id
            ? { ...item, is_active: isChecked === true ? 1 : 0 }
            : item
        );

        const updatedRowData = {
          ...selectedRow,
          is_active: selectedRow.is_active === 1 ? 0 : 1,
        };

        setEmployeeData(updatedData);
        setSelectedRow(updatedRowData);
        setCheckedState((prevState) => ({
          ...prevState,
          [selectedRow?.id]: isChecked, // Revert the state if the request fails
        }));
        console.log("updatedData", employeeData);
      } else if (response.status === 401) {
        showSnackbar("Unknown User", "error");
      } else if (response.status === 404) {
        showSnackbar("Error to Updating Status", "error");
      } else if (response.status === 401) {
        showSnackbar("Unathorized User", "error");
      } else {
        showSnackbar("Error to Updating Status", "error");

        throw new Error("Error to Updating Status");
      }
    } catch (error) {
      showSnackbar("Error to Updating Status", "error");
    }
  };

  const handleOpenEnquiryTransfer = () => {
    setOpenEnquiryTransfer(true);
  };

  const handlecloseEnquiryTransfer = () => {
    setOpenEnquiryTransfer(false);
  };

  const handleOpenTaskTransfer = () => {
    setOpenTaskTransfer(true);
  };
  const handleCloseTaskTransfer = () => {
    setOpenTaskTransfer(false);
  };

  //  Fetch api for disable button

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}employee/make/active/${selectedId}`
        );
        if (response.ok) {
          const data = await response.json();
          setShowDisable(true);
        } else {
          setShowDisable(false);
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        setShowDisable(false);
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const disabled = totalEnquiries !== 0 || totalTask !== 0;
  console.log("disabled", disabled);
  return (
    <>
      {selectedCriteria === "enquiry" && (
        <Backdrop open={loading} style={{ zIndex: 9999 }}>
          <Stack direction="row" spacing={1}>
            <Typography
              variant="h4"
              color="secondary"
              fontWeight={800}
              marginRight={2}
            >
              Fetching Enquiry Data
            </Typography>
            <CircularProgress color="primary" />
          </Stack>
        </Backdrop>
      )}

      {selectedCriteria === "task" && (
        <Backdrop open={taskLoading} style={{ zIndex: 9999 }}>
          <Stack direction="row" spacing={1}>
            <Typography
              variant="h4"
              color="secondary"
              fontWeight={800}
              marginRight={2}
            >
              Fetching Task Data
            </Typography>
            <CircularProgress color="primary" />
          </Stack>
        </Backdrop>
      )}

      {selectedCriteria === "prevenquiry" && (
        <Backdrop open={prevenqLoading} style={{ zIndex: 9999 }}>
          <Stack direction="row" spacing={1}>
            <Typography
              variant="h4"
              color="secondary"
              fontWeight={800}
              marginRight={2}
            >
              Fetching Enquiry Data
            </Typography>
            <CircularProgress color="primary" />
          </Stack>
        </Backdrop>
      )}

      <Grid container spacing={2}>
        <Grid item xs={12} sm={6} container spacing={2}>
          <Grid item>
            <Button
              size="small"
              variant={
                selectedCriteria === "enquiry" ? "contained" : "outlined"
              }
              sx={{ borderRadius: "25px" }}
              onClick={() => handleChangeCriteria("enquiry")}
            >
              Active Enquiries ({totalEnquiries})
            </Button>
          </Grid>

          <Grid item>
            <Button
              size="small"
              variant={selectedCriteria === "task" ? "contained" : "outlined"}
              sx={{ borderRadius: "25px" }}
              onClick={() => handleChangeCriteria("task")}
            >
              Open Task ({totalTask})
            </Button>
          </Grid>

          <Grid item>
            <Button
              size="small"
              variant={
                selectedCriteria === "prevenquiry" ? "contained" : "outlined"
              }
              sx={{ borderRadius: "25px" }}
              onClick={() => handleChangeCriteria("prevenquiry")}
            >
              Previous Assign Enquiry ({totalPrevenquiry})
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={6} sm={6} container justifyContent="end" spacing={2}>
          {selectedCriteria === "enquiry" && (
            <Grid item>
              <Button
                style={{ borderRadius: "25px" }}
                size="small"
                variant="contained"
                onClick={() => handleOpenEnquiryTransfer()}
              >
                Mass Enquiry Transfer
              </Button>
            </Grid>
          )}

          {selectedCriteria === "task" && (
            <Grid item>
              <Button
                style={{ borderRadius: "25px" }}
                size="small"
                variant="contained"
                onClick={() => handleOpenTaskTransfer()}
              >
                Mass Task Transfer
              </Button>
            </Grid>
          )}

          <Grid item>
            <Button
              size="small"
              // disabled={disabled}
              variant="contained"
              color={selectedRow?.is_active === 1 ? "error" : "primary"}
              style={{ borderRadius: "25px" }}
              onClick={() => handleChangeStatus()}
              startIcon={<img src="/activateicon.png" width="20px" />}
            >
              {selectedRow?.is_active === 1 ? "Deactivate" : "Activate"}
            </Button>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          {" "}
          <Button
            style={{ borderRadius: "25px" }}
            variant="contained"
            size="small"
          >
            {" "}
            Selected Items : {selectedtransferIds.length}{" "}
          </Button>
        </Grid>

        {selectedCriteria === "enquiry" && (
          <>
            <Grid item xs={12} lg={12} container spacing={2}>
              <EmployeeEnquiryData
                enquiriData={enquiriData}
                setEnquiryData={setEnquiryData}
                enquiryOriginalData={enquiryOriginalData}
                setLoading={setLoading}
                selectedId={selectedId}
                setSelectedTransferIds={setSelectedTransferIds}
                selectedtransferIds={selectedtransferIds}
                isSelectAllChecked={isSelectAllChecked}
                setIsSelectAllChecked={setIsSelectAllChecked}
              />
            </Grid>
          </>
        )}

        {selectedCriteria === "task" && (
          <Grid item xs={12} container spacing={2}>
            <EmployeeTaskData
              taskData={taskData}
              settaskData={settaskData}
              setLoading={setTaskLoading}
              originalTaskData={originalTaskData}
              selectedId={selectedId}
              setSelectedTransferIds={setSelectedTransferIds}
              selectedtransferIds={selectedtransferIds}
              isSelectAllChecked={isSelectAllChecked}
              setIsSelectAllChecked={setIsSelectAllChecked}
            />
          </Grid>
        )}

        {selectedCriteria === "prevenquiry" && (
          <Grid item xs={12} lg={12} container spacing={2}>
            <PreveEnquiryData
              setPrevEnquirydata={setPrevEnquirydata}
              prevEnquiryOriginalData={prevEnquiryOriginalData}
              setLoading={setPrevEnqLoading}
              selectedId={selectedId}
              prevenquirydata={prevenquirydata}
              setSelectedTransferIds={setSelectedTransferIds}
              selectedtransferIds={selectedtransferIds}
              isSelectAllChecked={isSelectAllChecked}
              setIsSelectAllChecked={setIsSelectAllChecked}
            />
          </Grid>
        )}
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      {selectedtransferIds.length === 0 ? (
        <CustomPopup
          open={openEnquiryTransfer}
          onClose={handlecloseEnquiryTransfer}
          component={<NoEnquiry onClose={handlecloseEnquiryTransfer} />}
          maxWidth="xs"
        />
      ) : (
        <CustomPopup
          open={openEnquiryTransfer}
          onClose={handlecloseEnquiryTransfer}
          component={
            <EmployeeEnquiryTransfer
              onClose={handlecloseEnquiryTransfer}
              selectedId={selectedtransferIds}
              setRows={setEnquiryData}
              rows={enquiriData}
              setOriginalRows={setEnquiryOriginalData}
              setSelectedIds={setSelectedTransferIds}
            />
          }
          title="Enquiry Transfer"
          maxWidth="sm"
          showDivider={true}
        />
      )}

      {selectedtransferIds.length === 0 ? (
        <CustomPopup
          open={openTaskransfer}
          onClose={handleCloseTaskTransfer}
          component={<NoTaskPopup onClose={handleCloseTaskTransfer} />}
          maxWidth="xs"
        />
      ) : (
        <CustomPopup
          open={openTaskransfer}
          onClose={handleCloseTaskTransfer}
          component={
            <EmployeeTaskTransfer
              onClose={handleCloseTaskTransfer}
              selectedId={selectedtransferIds}
              setSelectedIds={setSelectedTransferIds}
              filteredTasks={taskData}
              setFilteredTasks={settaskData}
            />
          }
          title="Task Transfer"
          maxWidth="sm"
          showDivider={true}
        />
      )}
    </>
  );
}
