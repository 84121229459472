import {
  Autocomplete,
  Avatar,
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Grid,
  InputLabel,
  List,
  ListItem,
  Paper,
  TextField,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../components/Maincard";
import { DataGrid } from "@mui/x-data-grid";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import InfoCard from "../../newTestPrep/pages/Events/InfoCard";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import EmailIcon from "@mui/icons-material/Email";
import { fDateTime } from "../../utils/formatTime";
import CustomPopup from "../../components/CustomPopup";
import NoenquiryForm from "../../newTestPrep/pages/Events/NoenquiryForm";
import MaskTaskAssign from "../../newTestPrep/pages/Events/MaskTaskAssign";
import BulkEmailPopup from "../../newTestPrep/pages/Events/BulkEmail/BulkEmailPopup";
import BulkMailform from "../../newTestPrep/pages/Events/BulkEmail/BulkMailform";
import NoeMailForm from "../../newTestPrep/pages/Events/NoMailForm";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { useGetTokenOrLogout } from "../../utils/token";

export default function CampaignReportFilter({
  open,
  setCampaignLoading,
  campaignLoading,
  setCampaignreportData,
  originalData,
  setcampaignRowData
}) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();

  const [selectedButton, setSelectedButton] = useState(null);

  const [servicelist, setServiceList] = useState([]);
  const [service, setService] = useState([]);
  const [leadOwnerList, setLeadOwnerList] = useState([]);
  const [leadOwnerName, setLeadOwnerName] = useState([]);
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [leadStageList, setLeadstageList] = useState([]);
  const [leadstage, setLeadstage] = useState([]);
  const [prospectiveLevelList, setProspectiveLevelList] = useState([]);
  const [prospectiveLevel, setProspectiveLevel] = useState([]);
  const [countrylist, setcountrylist] = useState([]);
  const [country, setCountry] = useState([]);
  const [leadSourceList, setLeadSourceList] = useState([]);
  const [leadSource, setLeadsource] = useState([]);
  const [statelist, setStatelist] = useState([]);
  const [state, setState] = useState([]);
  const [cityList, setCityList] = useState([]);
  const [city, setCity] = useState([]);
  const [selectedcountryId, setselectedCountryId] = useState([]);
  const [selectedStateids, setSelectedStateIds] = useState([]);
  const [nearestBranch, setNearestBranch] = useState([]);
  const [campaignList, setCampaignList] = useState([]);
  const [campaignname, setCampaignName] = useState([]);
  const [selectedDuration, setSelectedDuration] = useState(null);
  const [loading, setloading] = useState(false);

  const [fromDate, setFromDate] = useState("");
  const [toDate, setTodate] = useState("");
  const [createdbylist, setCreatedbylist] = useState([]);
  const [createdby, setCreatedby] = useState([]);

  const noOptionsText = "Loading...";

  const years = Array.from(
    { length: 5 },
    (_, index) => new Date().getFullYear() - 3 + index
  );

  const [selectedYear, setSelectedYear] = useState(null);
  const handleselectedYear = async (value) => {
    setSelectedYear(value);
    console.log("value", value);

    setloading(true);
    const requestData = {
      campaign_type: selectedButton,
      duration: selectedDuration,
      year: JSON.stringify(value),
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/searchcampaign`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setloading(false);
        const result = await response.json();
        const sortedData = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setCampaignList(sortedData);
      } else if (response.status === 401) {
      } else if (response.status === 404) {
        setloading(false);
        setCampaignList([]);
      } else {
        setloading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setloading(false);
    }
  };

  const handleChipClick = async (criteria) => {
    setSelectedButton(criteria);
    setCampaignList([]);
    setSelectedYear("")

    setSelectedDuration("")
    if (criteria === "Generic") {
      setloading(true);
      const requestData = {
        campaign_type: criteria,
        duration: null,
        year: null,
      };

      try {
        const response = await fetch(`${API_ENDPOINT}route2.0/searchcampaign`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });

        if (response.ok) {
          setloading(false);
          const result = await response.json();
          const sortedData = result.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setCampaignList(sortedData);
        } else if (response.status === 401) {
          // Handle errors, e.g., show an error message
        } else if (response.status === 404) {
          setloading(false);
          setCampaignList([])
        } else if (response.status === 403) {
        } else {
          setloading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        setloading(false);
      }
    }
  };

  const handlDurationchange = async (criteria) => {
    setSelectedDuration(criteria);
    if (criteria === "customyear") {
      return;
    }
    setSelectedYear("")
    setloading(true);
    const requestData = {
      campaign_type: selectedButton,
      duration: criteria,
      year: null,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/searchcampaign`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setloading(false);
        const result = await response.json();
        const sortedData = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setCampaignList(sortedData);
      } else if (response.status === 401) {
        // Handle errors, e.g., show an error message
      } else if (response.status === 404) {
        setloading(false);
        setCampaignList([])
      } else {
        setloading(false);
      }
    } catch (error) {
      console.error("Error:", error);
      setloading(false);
    }
  };

  // const handleCampaignChange = (value) => {
  //   setCampaignName(value);
  // };

  const allcampaignList = [
    ...(campaignList.length > 1 ? [{ campaign_name: "Select All" }] : []),
    ...campaignList,
  ];

  console.log("allcampaignList", allcampaignList.length);

  const handleCampaignChange = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.campaign_name === "Select All")) {
      // If "Select All" is already selected, remove it from the selection
      if (
        campaignname.some((option) => option.campaign_name === "Select All")
      ) {
        setCampaignName([]);
      } else {
        // Otherwise, select all options
        setCampaignName(campaignList);
      }
    } else {
      setCampaignName(value);
    }
  };

  const handleFromdateChange = (e) => {
    setFromDate(e.target.value);
  };

  const handleTodDateChange = (e) => {
    setTodate(e.target.value);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}service`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setServiceList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleServiceChange = (value) => {
    setService(value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setLeadOwnerList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allleadownerList = [{ first_name: "Select All" }, ...leadOwnerList];

  const handleOwnerNameChange = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setLeadOwnerName(leadOwnerList);
    } else {
      setLeadOwnerName(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setBranchlist(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allBranchlist = [{ branch_name: "Select All" }, ...branchlist];

  const handlebranchChange = (event, value) => {
    console.log("branch_name", value);
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}stage`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setLeadstageList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allLeadStagelist = [{ stage_name: "Select All" }, ...leadStageList];

  const handleLeadStagechange = (event, value) => {
    if (value.some((option) => option.stage_name === "Select All")) {
      setLeadstage(leadStageList);
    } else {
      setLeadstage(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}prospectivelevel`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setProspectiveLevelList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allProspectiveLevelList = [
    { prospective_level: "Select All" },
    ...prospectiveLevelList,
  ];
  const handleProspectivelevelChange = (event, value) => {
    if (value.some((option) => option.prospective_level === "Select All")) {
      setProspectiveLevel(prospectiveLevelList);
    } else {
      setProspectiveLevel(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}targetcountries`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setcountrylist(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const AllcountryList = [{ country_name: "Select All" }, ...countrylist];

  const handleCountryChange = async (event, value) => {
    if (value.some((option) => option.country_name === "Select All")) {
      setCountry(countrylist);
    } else {
      setCountry(value);
    }
    const countryIds = value.map((i) => i.id);
    setselectedCountryId(countryIds);
    const formdata = {
      country_id: countryIds,
    };
    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/statebycountry`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formdata),
      });

      if (response.ok) {
        const result = await response.json();
        setStatelist(result);
        console.log("statelist", result);
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  const handleStateChange = async (value) => {
    setState(value);
    const stateIds = value.map((i) => i.id);
    setSelectedStateIds(stateIds);
    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/citybystate`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ state_id: stateIds }),
      });

      if (response.ok) {
        const result = await response.json();
        setCityList(result);
      } else {
      }
    } catch (error) {
      console.error("Error:", error);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}leadsource`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setLeadSourceList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allLeadSourceList = [{ name: "Select All" }, ...leadSourceList];

  const handleLeadsourcechange = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      setLeadsource(leadSourceList);
    } else {
      setLeadsource(value);
    }
  };

  const handleCityChange = (value) => {
    setCity(value);
  };

  const handleNearestBranchChange = (event, value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setNearestBranch(branchlist);
    } else {
      setNearestBranch(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setCreatedbylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchData();
  }, []);
  const allcreatedByList = [{ first_name: "Select All" }, ...createdbylist];
  const handelcretedby = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setCreatedby(createdbylist);
    } else {
      setCreatedby(value);
    }
  };

  const handleSearch = async (event) => {
    event.preventDefault();
    setCampaignLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json" // Make sure to keep the content type
    };


    const requestData = {
      campaign_id: campaignname.map((i) => i.id),
      from_date: fromDate || null,
      to_date: toDate || null,
      // service_id:service.map((i)=>i.id),
      assign_to: leadOwnerName.map((i) => i.id),
      branch_id: branch.map((i) => i.id),
      stage_id: leadstage.map((i) => i.id),
      prospective_level_id: prospectiveLevel.map((i) => i.id),
      //country_id: country.map((i) => i.id),
      lead_source_id: leadSource.map((i) => i.id),
      // state_id: state.map((i) => i.id),
      // city_id: city.map((i) => i.id),

      nearest_branch_id: nearestBranch.map((i) => i.id),
      created_by: createdby.map((i) => i.id),

      user_id: parseInt(userId),
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}route2.0/filtercampaignreport`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();
        const sortedData = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setCampaignreportData(sortedData);
        setcampaignRowData(sortedData)
        setCampaignLoading(false);
      } 
      else if (response.status === 404) {
             navigate("/page404");
           } else if (response.status === 401) {
             navigate("/page401");
           } else if (response.status === 403) {
             navigate("/page403");
           } else if (response.status === 440) {
             navigate("/page440");
           } else {
             navigate("/page500");
           }
    } catch (error) {
      setCampaignLoading(false);
      navigate("/page500");
    }
  };

  const resetFilter = () => {
    setCampaignName([]);
    setFromDate("");
    setTodate("");
    // setService([])
    setLeadOwnerName([]);
    setBranch([]);
    setLeadstage([]);
    setProspectiveLevel([]);
    setCountry([]);
    setLeadsource([]);
    setState([]);
    setCity([]);
    setNearestBranch([]);

    setCampaignreportData(originalData);
    setcampaignRowData(originalData)
    setSelectedButton("");
    setSelectedDuration("");
    setSelectedYear("");
    setCampaignList([]);
    setCreatedby([]);
  };

  console.log("campaignreportData", originalData);

  return (
    <>
      <Collapse in={open} fullWidth>
        <MainCard>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12} sm={12} md={12}>
              <Typography
                sx={{
                  color: "#2E4069",
                  fontSize: "19px",
                  fontWeight: 600,
                }}
              >
                Service
                <span
                  style={{
                    color: "red",
                    fontSize: "20px",
                    marginLeft: "3px",
                  }}
                >
                  *
                </span>
              </Typography>
              <Chip
                avatar={<Avatar alt="Natacha" src="/test (1).png" />}
                label="Test Preparation"
                variant="outlined"
                sx={{
                  marginTop: "10px",
                  fontSize: "14px",
                  fontWeight: 600,
                }}
              />
            </Grid>
            <Grid item xs={12} lg={12} sm={12} md={12}>
              <Typography
                sx={{ color: "#2E4069", fontSize: "19px", fontWeight: 600 }}
              >
                Campaign Type
                <span
                  style={{
                    color: "red",
                    fontSize: "20px",
                    marginLeft: "3px",
                  }}
                >
                  *
                </span>
              </Typography>
              <Box mt={1}>
                <Button
                  onClick={() => handleChipClick("Event")}
                  style={{
                    borderRadius: "25px",
                    border:
                      selectedButton === "Event"
                        ? "2px solid green"
                        : "2px solid #888888",
                    color: selectedButton === "Event" ? "green" : "black",
                    margin: "5px",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  Event
                </Button>

                <Button
                  variant="outlined"
                  onClick={() => handleChipClick("Generic")}
                  style={{
                    borderRadius: "25px",
                    border:
                      selectedButton === "Generic"
                        ? "2px solid green"
                        : "2px solid #888888",
                    color: selectedButton === "Generic" ? "green" : "black",
                    margin: "5px",
                    fontSize: "14px",
                    fontWeight: 600,
                  }}
                >
                  Generic
                </Button>
              </Box>
            </Grid>

            {selectedButton === "Event" && (
              <Grid item xs={12} lg={12} sm={12} md={12}>
                <Typography
                  sx={{
                    color: "#2E4069",
                    fontSize: "19px",
                    fontWeight: 600,
                  }}
                >
                  Duration
                  <span
                    style={{
                      color: "red",
                      fontSize: "20px",
                      marginLeft: "3px",
                    }}
                  >
                    *
                  </span>
                </Typography>
                <Grid container>
                  <Box mt={1} sx={{ display: "flex", flexDirection: "row" }}>
                    <Chip
                      label="Past"
                      onClick={() => handlDurationchange("past")}
                      variant="outlined"
                      style={{
                        borderRadius: "25px",
                        cursor: "pointer",
                        border:
                          selectedDuration === "past"
                            ? "2px solid green"
                            : "2px solid #888888",
                        color: selectedDuration === "past" ? "green" : "black",
                        margin: "5px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    />

                    <Chip
                      label="Last One Year"
                      onClick={() => handlDurationchange("lastoneyear")}
                      variant="outlined"
                      style={{
                        borderRadius: "25px",
                        cursor: "pointer",
                        border:
                          selectedDuration === "lastoneyear"
                            ? "2px solid green"
                            : "2px solid #888888",
                        color:
                          selectedDuration === "lastoneyear"
                            ? "green"
                            : "black",
                        margin: "5px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    />
                    <Chip
                      label="Recent & Upcoming"
                      onClick={() => handlDurationchange("recentupcoming")}
                      variant="outlined"
                      style={{
                        borderRadius: "25px",
                        cursor: "pointer",
                        border:
                          selectedDuration === "recentupcoming"
                            ? "2px solid green"
                            : "2px solid #888888",
                        color:
                          selectedDuration === "recentupcoming"
                            ? "green"
                            : "black",
                        margin: "5px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    />
                    <Chip
                      label="Custom Year"
                      onClick={() => handlDurationchange("customyear")}
                      variant="outlined"
                      style={{
                        borderRadius: "25px",
                        cursor: "pointer",
                        border:
                          selectedDuration === "customyear"
                            ? "2px solid green"
                            : "2px solid #888888",
                        color:
                          selectedDuration === "customyear" ? "green" : "black",
                        margin: "5px",
                        fontSize: "14px",
                        fontWeight: 600,
                      }}
                    />
                    {selectedDuration === "customyear" && (
                      <Autocomplete
                        value={selectedYear}
                        onChange={(event, value) => handleselectedYear(value)}
                        size="small"
                        sx={{ width: "300px" }}
                        options={years}
                        // getOptionLabel={(option) => option}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            InputProps={{
                              ...params.InputProps,
                              style: { borderRadius: "15px" }, // Add borderRadius here
                            }}
                          />
                        )}
                      />
                    )}
                  </Box>
                </Grid>
              </Grid>
            )}

            <Grid item xs={12} lg={4}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Campaign
              </Typography>
              <Autocomplete
                size="small"
                noOptionsText={
                  loading && allcampaignList.length === 0
                    ? noOptionsText
                    : "Please Select Above Filter First"
                }
                multiple
                value={campaignname}
                onChange={handleCampaignChange}
                options={allcampaignList}
                getOptionLabel={(option) => option.campaign_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here

                      endAdornment: (
                        <>
                          {loading ? (
                            <CircularProgress color="inherit" size={20} />
                          ) : null}
                          {params.InputProps.endAdornment}
                        </>
                      ),
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={6} lg={2}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                From Date
              </Typography>
              <TextField
                size="small"
                value={fromDate}
                onChange={handleFromdateChange}
                type="date"
                fullWidth
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
              />
            </Grid>
            <Grid item xs={6} lg={2}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                To Date
              </Typography>
              <TextField
                size="small"
                value={toDate}
                onChange={handleTodDateChange}
                type="date"
                fullWidth
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
              />
            </Grid>
            {/* <Grid item xs={12} lg={4} sm={6} md={6}>
                  <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                    Service
                  </Typography>
                  <Autocomplete
                    value={service}
                    multiple
                    onChange={  
                      handleServiceChange // Passing only the value
                    }
                    options={servicelist}
                    getOptionLabel={(option) => option.service_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid> */}

            <Grid item xs={12} lg={4} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Lead Owner{" "}
              </Typography>
              <Autocomplete
                size="small"
                value={leadOwnerName}
                multiple
                onChange={handleOwnerNameChange}
                options={allleadownerList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            {/* <Grid item xs={12} lg={4} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Branch{" "}
              </Typography>
              <Autocomplete
                size="small"
                value={branch}
                multiple
                onChange={handlebranchChange}
                options={allBranchlist}
                getOptionLabel={(option) => option.branch_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={4} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Lead Stage{" "}
              </Typography>
              <Autocomplete
                size="small"
                value={leadstage}
                multiple
                onChange={handleLeadStagechange}
                options={allLeadStagelist}
                getOptionLabel={(option) => option.stage_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={4} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Prospective Level{" "}
              </Typography>
              <Autocomplete
                size="small"
                value={prospectiveLevel}
                multiple
                onChange={handleProspectivelevelChange}
                options={allProspectiveLevelList}
                getOptionLabel={(option) => option.prospective_level}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={4} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                First Country Of Interest{" "}
              </Typography>
              <Autocomplete
                size="small"
                multiple
                value={country}
                onChange={handleCountryChange}
                options={AllcountryList}
                getOptionLabel={(option) => option.country_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid> */}

            {/* <Grid item xs={12} lg={4} sm={6} md={6}>
                  <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
             Campaign   Country {" "}
                  </Typography>
                  <Autocomplete
                   
                    multiple
                   
                    options=""
          
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid> */}

            {/* <Grid item xs={12} lg={4} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Lead Source{" "}
              </Typography>
              <Autocomplete
                size="small"
                value={leadSource}
                multiple
                onChange={handleLeadsourcechange}
                options={allLeadSourceList}
                getOptionLabel={(option) => option.name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid> */}

            {/* <Grid item xs={12} lg={4} sm={6} md={6}>
                  <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                    States{" "}
                  </Typography>
                  <Autocomplete
                   size="small"
                    value={state}
                    multiple
                    onChange={(event, value) => {
                      handleStateChange(value); // Passing only the value
                    }}
                    options={statelist}
                    getOptionLabel={(option) => option.state_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid> */}

            {/* <Grid item xs={12} lg={4} sm={6} md={6}>
                  <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                    Cities{" "}
                  </Typography>
                  <Autocomplete
                   size="small"
                    value={city}
                    multiple
                    onChange={(event, value) => {
                      handleCityChange(value); // Passing only the value
                    }}
                    options={cityList}
                    getOptionLabel={(option) => option.city_name}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "15px" }, // Add borderRadius here
                        }}
                      />
                    )}
                  />
                </Grid> */}

            {/* <Grid item xs={12} lg={4} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600, fontSize: "14px" }}>
                Nearest Branch{" "}
              </Typography>
              <Autocomplete
                size="small"
                value={nearestBranch}
                multiple
                onChange={handleNearestBranchChange}
                options={allBranchlist}
                getOptionLabel={(option) => option.branch_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid> */}

<Grid item xs={12} lg={4} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600 }}>Created By </Typography>
              <Autocomplete
                size="small"
                fullWidth
                value={createdby}
                multiple
                onChange={handelcretedby}
                options={allcreatedByList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} md={12} lg={12} sm={12} textAlign="end">
              <Button
                variant="contained"
                sx={{ borderRadius: "25px" }}
                startIcon={<SearchIcon />}
                onClick={handleSearch}
              >
                Search
              </Button>
              <Button
                variant="contained"
                color="error"
                sx={{ marginLeft: "10px", borderRadius: "25px" }}
                startIcon={<ClearIcon />}
                onClick={resetFilter}
              >
                Clear{" "}
              </Button>
            </Grid>
          </Grid>
        </MainCard>
      </Collapse>
    </>
  );
}
