import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Dialog,
  DialogContent,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { fDate } from "../../../utils/formatTime";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import EventIcon from "@mui/icons-material/Event";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import FutureEnrollmentFilters from "./FutureEnrollmentFilters";
import { useGetTokenOrLogout } from "../../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function FutureEnrollmentPanel() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const [classId, setClassId] = useState(null);
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [datesWithSlots, setDatesWithSlots] = useState([]);
  const [selectedRow, setSelectedRow] = useState({});
  const [selectedEnrollmentDate, setSelectedEnrollmentDate] = useState(null);
  const [filterCriteria, setFilterCriteria] = useState(null);
  const [originalData, setOriginalData] = useState([]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const [classIdPopup, setClassIdPopup] = useState(null);
  const [popupOpen, setPopupOpen] = useState(false);
  const [startDate, setStartDate] = useState(null);

  // Function to handle opening the popup with DatePicker
  const handleOpenPopup = (classId) => {
    setClassIdPopup(classId);
    setPopupOpen(true);
  };

  // Function to handle closing the popup
  const handleClosePopup = () => {
    setClassIdPopup(null);
    setPopupOpen(false);
  };

  const [extendPopupOpen, setExtendPopupOpen] = useState(false);
  const [newTentativeDate, setNewTentativeDate] = useState(null);

  const handleExtendTentativeDate = (row) => {
    setExtendPopupOpen(true);

    setSelectedRow(row);
    setClassId(row.class_id?.id);
  };

  const handleCloseExtendPopup = () => {
    setExtendPopupOpen(false);
    setNewTentativeDate(null);
  };

  const handleExtendTentativeSubmit = () => {
    // Add logic here to submit the new tentative date
    console.log("New Tentative Date:", newTentativeDate);
    // Close the popup after submission
    handleCloseExtendPopup();
  };

  useEffect(() => {
    setIsLoading(true);
    // Fetch expenses data
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
    
    };
    fetch(`${API_ENDPOINT}route2.0/getfutureenrollment/${userId}`,{headers})
      .then((response) => {
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }
        return response.json();
      })
      .then((data) => {
        console.log(data);
        // Sort the filtered data by payment_date
        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        let filteredData = [...sortedData];
        console.log(
          filteredData.map((item) => {
            console.log("date1", item.payment_date);
          })
        );
        if (filterCriteria) {
          filteredData = filteredData.filter((payment) => {
            let passesFilter = true;
    
            if (filterCriteria && filterCriteria.name) {
              const fullName =
                payment?.enquiry_id.first_name || payment?.enquiry_id.last_name
                  ? `${payment.enquiry_id.first_name} ${payment.enquiry_id.last_name}`.toLowerCase()
                  : ""; // Concatenating first and last names
    
              const filterString = filterCriteria.name.toLowerCase();
    
              passesFilter = passesFilter && fullName.includes(filterString);
            }
    
             if (filterCriteria.number) {
              passesFilter =
                passesFilter &&
                filterCriteria.number === payment.enquiry_id?.mobile;
                console.log( parseInt(filterCriteria.number))
            }
            if (filterCriteria.newBranch.length > 0) {
              passesFilter =
                passesFilter &&
                filterCriteria.newBranch.includes(payment.branch_id?.id);
            }
    
            if (filterCriteria.createfrom && filterCriteria.createdto) {
              const startDate = filterCriteria.createfrom;
              const endDate = filterCriteria.createdto;
              const createddate = new Date(payment?.createdAt);
              const formattedDate = createddate.toISOString().slice(0, 10);
              console.log("formated date is", createddate);
              passesFilter =
                passesFilter &&
                formattedDate >= startDate &&
                formattedDate <= endDate;
             
            }
            if (filterCriteria.newCreatedBy.length > 0) {
              passesFilter =
                passesFilter &&
                filterCriteria.newCreatedBy.includes(payment?.created_by?.id);
            }
    
            if (filterCriteria.newPackageName.length > 0) {
              passesFilter =
                passesFilter &&
                filterCriteria.newPackageName.includes(payment?.package_id?.id);
            }
    
           
    
            return passesFilter;
          });
    
         
        }
        setPayments(filteredData);
        console.log(sortedData);
        setIsLoading(false);
        setOriginalData(sortedData);
      })
      .catch((error) => {
        if (error.message.includes("401")) {
          navigate("/page401");
        } else if (error.message.includes("403")) {
          navigate("/page403");
        } else if (error.message.includes("404")) {
          navigate("/page404");
        }  else if (error.message.includes("440")) {
          navigate("/page440");
        } 
        else {
          navigate("/page500");
        }
        setIsLoading(false);
      
      });
  }, [userId,filterCriteria]);

  const handleAddEnrollmentDate = async (row) => {
    setSelectedRow(row);
    setClassId(row.class_id?.id); // Set the class ID

    try {
      const response = await fetch(
        `${API_ENDPOINT}route/searchseat/${row.class_id?.id}`
      );
      if (!response.ok) {
        throw new Error("Failed to fetch class slots");
      }
      const data = await response.json();
      if (data.length === 0) {
        showSnackbar("No dates available for this class", "error");
      } else {
        setDatesWithSlots(data); // Set the fetched class slots in state
        handleOpenPopup(row.class_id?.id); // Open the popup with the DatePicker
      }
    } catch (error) {
      console.error("Error fetching class slots:", error);
      showSnackbar("Error fetching class slots", "error");
    }
  };

  const filterDate = (date) => {
    const dateStr = date.toLocaleDateString(); // Use toLocaleDateString

    // Check if the date is present in the fetched data
    return (
      datesWithSlots.some(
        (d) => dateStr === new Date(d.date).toLocaleDateString()
      ) && datesWithSlots.slots !== 0
    );
  };

  const customDayClass = (date) => {
    const dateStr = date.toLocaleDateString(); // Use toLocaleDateString

    const dateWithSlots2 = datesWithSlots.find(
      (d) => dateStr === new Date(d.date).toLocaleDateString()
    );

    if (dateWithSlots2) {
      return dateWithSlots2.slots === 0
        ? "react-datepicker__day--highlighted-custom-3 react-datepicker__day--disabled"
        : "react-datepicker__day--highlighted-custom-4";
    }

    return "";
  };

  const handleSubmitEnrollmentDate = async () => {
    try {
      if (!selectedEnrollmentDate) {
        showSnackbar("Please select an enrollment date", "error");
        return;
      }

      // Add one day to the selected date
      const nextDay = new Date(selectedEnrollmentDate);
      nextDay.setDate(nextDay.getDate() + 1);

      const formattedDate = nextDay.toISOString().split("T")[0];

      const response = await fetch(
        `${API_ENDPOINT}lmstdclassdetail/updatesession/${selectedRow.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            enrollment_date: formattedDate,
            user_id: userId,
          }),
        }
      );
      if (!response.ok) {
        showSnackbar("Error updating enrollment date", "error");
        throw new Error(`${response.status}`);
      }
      // Handle success response
      showSnackbar("Enrollment date updated successfully", "success");

      // Remove selectedRow from payments array
      const updatedPayments = payments.filter(
        (item) => item.id !== selectedRow.id
      );
      setPayments(updatedPayments);

      setPopupOpen(false);
      setSelectedRow({});
      setClassId(null);
      setSelectedEnrollmentDate(null);
    } catch (error) {
      console.error("Error updating enrollment date:", error);
      showSnackbar("Error updating enrollment date", "error");
    }
  };

  const handleSubmitTentativeDate = async () => {
    try {
      if (!newTentativeDate) {
        showSnackbar("Please select a new tentative date", "error");
        return;
      }

      const response = await fetch(
        `${API_ENDPOINT}lmstdclassdetail/updatesession/${selectedRow.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            is_tentative_enroll_date: true,
            tentative_enroll_date: newTentativeDate,
            user_id: userId,
          }),
        }
      );
      if (!response.ok) {
        showSnackbar("Error updating tentative date", "error");
        throw new Error(`${response.status}`);
      }
      // Handle success response
      showSnackbar("Tentative date updated successfully", "success");

      // Remove selectedRow from payments array
      const updatedPayments = payments.filter(
        (item) => item.id !== selectedRow.id
      );
      setPayments(updatedPayments);

      setPopupOpen(false);
      setSelectedRow({});
      setClassId(null);
      setNewTentativeDate(null);
    } catch (error) {
      console.error("Error updating tentative date:", error);
      showSnackbar("Error updating tentative date", "error");
    }
  };

  const handleApplyFilter = (criteria) => {
    setFilterCriteria(criteria);
  };
  const handleResetFilter = () => {
    setFilterCriteria(null);
    setPayments(originalData);
  };

  // useEffect(() => {
  //   let filteredData = [...payments];
  //   console.log(
  //     filteredData.map((item) => {
  //       console.log("date1", item.payment_date);
  //     })
  //   );
  //   if (filterCriteria) {
  //     filteredData = filteredData.filter((payment) => {
  //       let passesFilter = true;

  //       if (filterCriteria && filterCriteria.name) {
  //         const fullName =
  //           payment?.enquiry_id.first_name || payment?.enquiry_id.last_name
  //             ? `${payment.enquiry_id.first_name} ${payment.enquiry_id.last_name}`.toLowerCase()
  //             : ""; // Concatenating first and last names

  //         const filterString = filterCriteria.name.toLowerCase();

  //         passesFilter = passesFilter && fullName.includes(filterString);
  //       }

  //        if (filterCriteria.number) {
  //         passesFilter =
  //           passesFilter &&
  //           filterCriteria.number === payment.enquiry_id?.mobile;
  //           console.log( parseInt(filterCriteria.number))
  //       }
  //       if (filterCriteria.newBranch.length > 0) {
  //         passesFilter =
  //           passesFilter &&
  //           filterCriteria.newBranch.includes(payment.branch_id?.id);
  //       }

  //       if (filterCriteria.createfrom && filterCriteria.createdto) {
  //         const startDate = filterCriteria.createfrom;
  //         const endDate = filterCriteria.createdto;
  //         const createddate = new Date(payment?.createdAt);
  //         const formattedDate = createddate.toISOString().slice(0, 10);
  //         console.log("formated date is", createddate);
  //         passesFilter =
  //           passesFilter &&
  //           formattedDate >= startDate &&
  //           formattedDate <= endDate;
         
  //       }
  //       if (filterCriteria.newCreatedBy.length > 0) {
  //         passesFilter =
  //           passesFilter &&
  //           filterCriteria.newCreatedBy.includes(payment?.created_by?.id);
  //       }

  //       if (filterCriteria.newPackageName.length > 0) {
  //         passesFilter =
  //           passesFilter &&
  //           filterCriteria.newPackageName.includes(payment?.package_id?.id);
  //       }

       

  //       return passesFilter;
  //     });

  //     setPayments(filteredData);
  //   }
  // }, [filterCriteria]);

  console.log("filter", filterCriteria);
  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <FutureEnrollmentFilters
            onApplyFilter={handleApplyFilter}
            onResetFilter={handleResetFilter}
          />
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{ borderRadius: "15px", padding: "10px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Enquiry Details
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Class Details
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Branch
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Package Details
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Tentative Enrollment Date
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Created By
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Created Date
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.length === 0 ? (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">No data available</Typography>
              </Grid>
            ) : (
              payments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f0f0f0" : "inherit",
                      width: "6%",
                    }}
                  >
                    <TableCell
                      sx={{
                        color: "blue",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "none",
                        },
                      }}
                      onClick={() =>
                        window.open(
                          `/enquirydetails/${row.enquiry_id.id}`,
                          "_blank"
                        )
                      }
                    >
                      {`${
                        row?.enquiry_id?.first_name
                          ? row?.enquiry_id?.first_name
                          : ""
                      } ${
                        row?.enquiry_id?.last_name
                          ? row?.enquiry_id?.last_name
                          : ""
                      }`}{" "}
                      <br />
                      {row.enquiry_id?.mobile && (
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            color: "#364152",
                          }}
                        >
                          {row.enquiry_id?.mobile}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>{row?.class_id?.name}</TableCell>
                    <TableCell>{row?.branch_id?.branch_name}</TableCell>
                    <TableCell>{row?.package_id?.package_name}</TableCell>
                    <TableCell>{fDate(row?.tentative_enroll_date)}</TableCell>
                    <TableCell>{row?.created_by?.first_name}</TableCell>
                    <TableCell>{fDate(row?.createdAt)}</TableCell>
                    <TableCell>
                      <Tooltip title="Add Enrollment Date">
                        <IconButton
                          onClick={() => {
                            // Set the class ID
                            handleAddEnrollmentDate(row); // Trigger the function to open the popup
                          }}
                          size="small"
                          color="secondary"
                        >
                          <AddCircleOutlineIcon />
                        </IconButton>
                      </Tooltip>
                      <Tooltip title="Extend Tentative Enrollment Date">
                        <IconButton
                          onClick={() => handleExtendTentativeDate(row)}
                          size="small"
                          color="error"
                        >
                          <EventIcon />
                        </IconButton>
                      </Tooltip>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={payments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <Dialog open={popupOpen} onClose={handleClosePopup}>
        <DialogContent>
          <Typography variant="h4">Select Enrollment Date</Typography>
          {datesWithSlots.length === 0 ? (
            <Typography>No dates available</Typography>
          ) : (
            <Box m={2}>
              <DatePicker
                showIcon
                isClearable
                closeOnScroll={true}
                startDate={startDate}
                filterDate={filterDate}
                selected={selectedEnrollmentDate}
                onChange={(date) => {
                  setSelectedEnrollmentDate(date);
                }}
                dateFormat="dd/MM/yyyy"
                dayClassName={customDayClass}
                // Set the fetched class slots as inline bookings
                inline
                includeDates={datesWithSlots.map((slot) => new Date(slot.date))}
              />
            </Box>
          )}
          <Button onClick={handleSubmitEnrollmentDate} variant="contained">
            Add Enrolment Date
          </Button>
          <Button onClick={() => setPopupOpen(false)} variant="outlined">
            Cancel
          </Button>
        </DialogContent>
      </Dialog>

      <Dialog open={extendPopupOpen} onClose={handleCloseExtendPopup}>
        <DialogContent>
          <Typography variant="h4">Extend Tentative Enrollment Date</Typography>
          <TextField
            label="New Tentative Enrollment Date"
            type="date"
            value={newTentativeDate}
            onChange={(e) => setNewTentativeDate(e.target.value)}
            InputLabelProps={{
              shrink: true,
            }}
            InputProps={{
                style: { borderRadius: "15px" },
                inputProps: { min: new Date().toISOString().split('T')[0] },
              }}
            fullWidth
            margin="normal"
          />
          <Button variant="contained" onClick={handleSubmitTentativeDate}>
            Submit
          </Button>
          <Button variant="outlined" onClick={handleCloseExtendPopup}>
            Cancel
          </Button>
        </DialogContent>
      </Dialog>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default FutureEnrollmentPanel;
