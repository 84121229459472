import { Button, FormControl, Grid, InputLabel, MenuItem, Select, TextField } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import React, { useState } from 'react'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import MainCard from '../../newTestPrep/components/ui-component/card/MainCard';

function LineChartFilter() {
    const [toDateMonthYear, setToDateMonthYear] = useState(null);
    const [toDateDay, setToDateDay] = useState(null);
    const [fromDateMonthYear, setFromDateMonthYear] = useState(null);
    const [fromDateDay, setFromDateDay] = useState(null);
    const [selectedBranch, setSelectedBranch] = useState('');
    const [filterData, setFilterData] = useState({
        branch_id: null,
        from_date_month: null,
        from_date_year: null,
        to_date_month: null,
        to_date_year: null,

    });

    const handleToDateMonthYearChange = (date) => {
        setToDateMonthYear(date);

        const selectedMonth = dayjs(date).month() + 1; // dayjs months are zero-indexed
        const selectedYear = dayjs(date).year();

        setFilterData((prevData) => ({
            ...prevData,
            to_date_month: selectedMonth,
            to_date_year: selectedYear,
        }));
    };

    const handleFromDateMonthYearChange = (date) => {
        setToDateMonthYear(date);

        const selectedMonth = dayjs(date).month() + 1; // dayjs months are zero-indexed
        const selectedYear = dayjs(date).year();

        setFilterData((prevData) => ({
            ...prevData,
            from_date_month: selectedMonth,
            from_date_year: selectedYear,
        }));
    };

    const handleBranchChange = (event) => {
        setSelectedBranch(event.target.value);
    };

  return (
    <>
    <MainCard>
      <Grid container spacing={2}>

      <Grid item xs={12} sm={6} md={4}>
                    <FormControl fullWidth variant="outlined">
                            <InputLabel htmlFor="branch-name">Branch Name</InputLabel>
                            <Select 
                                label="Branch Name" 
                                id="branch-name"
                                value={selectedBranch}
                                onChange={handleBranchChange}
                                >
                                <MenuItem value="exam1">Exam 1</MenuItem>
                                <MenuItem value="exam2">Exam 2</MenuItem>
                                
                            </Select>
                        </FormControl>
                    </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                            <Grid container spacing={2}>
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid item xs={12}>
                                    <DatePicker
                                        label="From"
                                        views={['month', 'year']}
                                        fullWidth
                                        value={fromDateMonthYear}
                                        onChange={handleFromDateMonthYearChange}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" />
                                        )}
                                    />
                                    </Grid>
                                   
                                </LocalizationProvider>
                                </Grid>
                            </Grid>
                            <Grid item xs={12} sm={6} md={4}>
                                <Grid container spacing={2}>
                               
                                <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <Grid item xs={12}>
                                    <DatePicker
                                        label="To"
                                        views={['month', 'year']}
                                        fullWidth
                                        value={toDateMonthYear}
                                        onChange={handleToDateMonthYearChange}
                                        renderInput={(params) => (
                                            <TextField {...params} variant="outlined" />
                                        )}
                                    />
                                    </Grid>
                                    
                                </LocalizationProvider>
                                </Grid>
                            </Grid>

                            <Grid item xs={12} sm={6} md={4}>
                        <Button variant="contained" color="primary" >
                            Apply Filter
                        </Button>
                   
                        <Button variant="outlined" color="secondary">
                            Reset Filter
                        </Button>
                    </Grid>
                </Grid>
                </MainCard>
                        </>
  )
}

export default LineChartFilter