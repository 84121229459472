import React, { useState } from 'react'
import { Grid, Paper, Table, TableBody, TableCell, TableContainer, TableHead, TablePagination, TableRow, Typography } from '@mui/material';
import { fDate } from '../../utils/formatTime';


export default function LessonPlanTable() {
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [data, setData] = useState([])

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  return (
  <>
  <Grid container spacing={2}>
        <Grid item xs={12}>
        <Typography sx={{ color: '#000000', fontFamily: '"Poppins-Medium", Helvetica', fontSize: '24px'}} fontWeight={500}>
               View Lesson Plan
                </Typography>
        </Grid>
        <Grid item xs={12}>
   <TableContainer component={Paper} sx={{borderRadius:'15px' ,padding:'10px' }}>
    <Table>
      <TableHead >
        <TableRow >
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 }}>Class Name</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 ,}}>Test Type</TableCell>
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 }}>Topic</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600,}}>Uploaded Date</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Uploaded By</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Actions</TableCell>
        </TableRow>
      </TableHead>
      <TableBody >
      {data.length === 0 ? (
                                <Grid item xs={12} style={{display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant='h4'>No uploaded data available</Typography>
                                </Grid>
                            ) : (
      data.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage).map((row, index) => (
          <TableRow key={index}  style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'inherit' ,width: '6%' }}>
            <TableCell >{row.name}</TableCell>
            <TableCell >{row?.category_id?.name}</TableCell>
            <TableCell >{row.branch_id?.branch_name}</TableCell>
            <TableCell>{fDate(row.start_date)}</TableCell>
            <TableCell >{row.amount}</TableCell>
          </TableRow>
        )))}
      </TableBody>
    </Table>
    <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={data.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
  </TableContainer>
  </Grid>
  </Grid>
    
  </>
  )
}
