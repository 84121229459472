import React, { useEffect, useState } from "react";
import { Alert, Box, Button, Checkbox, Chip, Drawer, FormControlLabel, FormGroup, Grid, Input, InputBase, Snackbar, Stack, TextareaAutosize, Typography } from "@mui/material";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";


const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
const TicketDrawer = ({ open, onClose }) => {
    const userId = localStorage.getItem("userId");
    const [showSection, setShowSection] = useState('');
    const [issueDescription, setIssueDescription] = useState('');
    const [feedbackDescription, setfeedbackDescription] = useState('');
    const [isUrgent, setIsUrgent] = useState(false);
    const [issueDescriptionError, setIssueDescriptionError] = useState(false);
    const [feedbackDescriptionError, setFeedbackDescriptionError] = useState(
      false
    );
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [snackbarSeverity, setSnackbarSeverity] = useState("success");
    const [mainFile, setMainFile] = useState(null);
    const [secondaryFile,setSecondaryFile1]=useState(null)
    
        const showSnackbar = (message, severity) => {
            setSnackbarMessage(message);
            setSnackbarSeverity(severity);
            setSnackbarOpen(true);
        };

 
  const handleSelectFile = (e) => setMainFile(e.target.files);

    const handleCheckboxChange = (section) => {
      if (showSection === section) {
        // If the clicked section is already open, close it
        setShowSection(null);
      } else {
        // Otherwise, open the clicked section and close the other one if it's open
        setShowSection(section);
      }
    };
  
    const handleSubmit = async () => {
      if (showSection === "issue" && !issueDescription) {
        setIssueDescriptionError(true);
        return;
      }
      if (showSection === "feedback" && !feedbackDescription) {
        setFeedbackDescriptionError(true);
        return;
      }

   
      const data = new FormData();
      data.append("type_of_ticket", showSection);
   

      if (showSection === "issue") {
        // Only append feedbackDescription if showSection is "feedback"
        data.append("issue_description", issueDescription || null);
    }
      if (showSection === "feedback") {
        // Only append feedbackDescription if showSection is "feedback"
        data.append("feedback_description", feedbackDescription || null);
    }

      data.append("ticket_priority", isUrgent ? "true" : "false");
      data.append("user_id", userId);
      data.append("screenshot_1",mainFile ? mainFile[0] : null );
      data.append("screenshot_2", secondaryFile ? secondaryFile[0] : null);
      
      try {
        const response = await fetch(`${API_ENDPOINT}ticket`, {
          method: "POST",
          // headers: {
          //   "Content-Type": "application/json",
          // },
          body: data,
        });
  
        if (response.ok) {
          console.log("Ticket submitted successfully!");
          showSnackbar('Ticket submitted successfully', 'success')
          setTimeout(() => {
            onClose();
          }, 2000);

          setIssueDescription(null);
          setfeedbackDescription(null);
          setIsUrgent(false);
          setIssueDescriptionError(false);
          setFeedbackDescriptionError(false);

          
        } else {
          showSnackbar('Failed to submit ticket', 'error')
          console.error("Failed to submit ticket");
        }
      } catch (error) {
        console.error("Error:", error);
        showSnackbar('An error occurred while submitting the ticket', 'error')
      }
    };
  
    const handleCloseSnackbar = () => {
      setSnackbarOpen(false);
    };

 ;


  return (
    <Drawer  anchor="right" open={open} onClose={onClose}>
    <Stack width='500px' >
      
    <Stack display='flex' alignItems='center' justifyContent='center'>
        <img src="\think.png" width='50px' />
                  
        
                  <Typography
                    sx={{
                      fontFamily: "Poppins",
                      fontSize: "24px",
                      fontWeight: 600,
                    }}
                  >
                    Raise New Ticket
                  </Typography>
            
                <Stack display='flex' flexDirection='row' >
                <Chip
                  onDelete={() => {}}
                  deleteIcon={<Checkbox   checked={showSection === "issue"}
                  onChange={() => handleCheckboxChange("issue")}   />}
                  label="Report An Issue"
                  clickable
                  color="primary"
                  style={{
                    backgroundColor: "#ffebee",
                    color: "#b71c1c",
                    width: "200px",
                    height: "40px",
                    fontSize: "14px",
                    fontWeight: 600,
                    borderRadius: "14px",
                  }}
                />
         
        
           
                <Chip
                  onDelete={() => {}}
                  deleteIcon={<Checkbox   checked={showSection === "feedback"}
                  onChange={() => handleCheckboxChange("feedback")}  />}
                  label="Provide Feedback"
                  clickable
                  color="primary"
                  style={{
                    backgroundColor: "#e8f5e9",
                    color: "#1b5e20",
                    width: "200px",
                    height: "40px",
                    fontSize: "14px",
                    fontWeight: 600,
                    borderRadius: "14px",
                    marginLeft:'7px'
                  }}
                />
           </Stack>
        
        </Stack>
    
              {showSection === "feedback" && (
              <>
              <Stack m={2} mt={4}>
         
                  <Typography
                    sx={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: 500 }}
                  >
                    We are pleased by your feedback. Kindly Specify:*
                  </Typography>
              
        
                  <TextareaAutosize
                    aria-label="empty textarea"
                    style={{
                      // width: "100%",
                      minHeight: "100px",
                      borderRadius: "14px",
                      padding:'10px',
                      marginTop:'10px'
                    }}
                    value={feedbackDescription}
                    onChange={(e) => {
                      setfeedbackDescription(e.target.value);
                      setFeedbackDescriptionError(false); // Reset the error when typing
                    }}
                  />
                  {feedbackDescriptionError && (
                    <Typography variant="caption" style={{ color: "red" }}>
                      Please specify the Feedback....
                    </Typography>
                  )}
            
        <Box sx={{ display: 'flex', justifyContent:'flex-end', margin:'10px', padding:'10px', position: 'absolute', bottom: 24, right: 0, }}>
          <Button
          variant="outlined"
            style={{
              // background: '#019E6F',
              borderColor:'green',
              color: 'black',
              fontSize: '18px',
              fontWeight: 500,
              borderRadius: '10px',
              height: '40px',
              marginRight:'10px'
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            style={{
              background: '#019E6F',
              color: 'white',
              fontSize: '18px',
              fontWeight: 500,
              borderRadius: '10px',
              height: '40px',
            }}
            onClick={handleSubmit}
          >
           Create New Ticket
          </Button>
        </Box>
        </Stack>
              </>
            )}
        
         
            {showSection === "issue" && (
              <>
          <Stack m={2} mt={4}>
                  <Typography
                    sx={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: 400 }}
                  >
                    We Apologies for the inconvenience. Kindly specify your issue:*
           </Typography>
        
                  <TextareaAutosize
                    aria-label="empty textarea"
                    style={{
                      width: "100%",
                      minHeight: "100px",
                      borderRadius: "14px",
                      padding:'10px'
                    }}
                    value={issueDescription}
                    onChange={(e) => {
                      setIssueDescription(e.target.value);
                      setIssueDescriptionError(false);
                    }}
                    required
                  />
                  {issueDescriptionError && (
                    <Typography variant="caption" style={{ color: "red" }}>
                      Please specify the issue description...
                    </Typography>
                  )}
            
        
                  <Typography
                    sx={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: 400, mt:'14px' }}
                  >
                    Is It Urgent:
                  </Typography>
                  <FormGroup style={{ display: "flex", flexDirection: "row" }}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isUrgent}
                          onChange={(e) => setIsUrgent(e.target.checked)}
                        />
                      }
                      label="Yes"
                    />
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={!isUrgent}
                          onChange={(e) => setIsUrgent(!e.target.checked)}
                        />
                      }
                      label="No"
                    />
                  </FormGroup>
        
        
        
                  <Typography
                    sx={{ fontFamily: "Poppins", fontSize: "18px", fontWeight: 400 , marginTop:'14px'}}
                  >
                    Kindly Upload concerned attachment:
                  </Typography>
            
        
          
                  <Stack display='flex' alignItems='center' justifyContent='space-between' flexDirection='row' marginTop='10px'>
                  <InputBase
                    type="file"
                    inputProps={{
                      accept: "image/jpeg, image/jpg, image/png",
                      style: { outline: "none", border: "none" },
                    }}
                    onChange={handleSelectFile}
                    fullWidth
                  />
            
        
           
                  <InputBase
                    type="file"
                    inputProps={{
                      accept: "image/jpeg, image/jpg, image/png",
                      style: { outline: "none", border: "none" },
                    }}
                    onChange={(e) => setSecondaryFile1(e.target.files)}
                    fullWidth
                  />
        </Stack>
        <Box sx={{ display: 'flex', justifyContent:'flex-end', margin:'10px', padding:'10px' , position: 'absolute', bottom: 24, right: 0,}}>
          <Button
          variant="outlined"
            style={{
              // background: '#019E6F',
              borderColor:'green',
              color: 'black',
              fontSize: '18px',
              fontWeight: 500,
              borderRadius: '10px',
              height: '40px',
              marginRight:'10px'
            }}
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            style={{
              background: '#019E6F',
              color: 'white',
              fontSize: '18px',
              fontWeight: 500,
              borderRadius: '10px',
              height: '40px',
            }}
            onClick={handleSubmit}
          >
            Create New Ticket
          </Button>
        </Box>
        </Stack>
        </>
        
              
            )}
        
        <CustomSnackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          severity={snackbarSeverity}
          />
            </Stack>
         </Drawer>
  );
};

export default TicketDrawer;
