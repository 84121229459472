import {
  Backdrop,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import { fDate } from "../../../utils/formatTime";
import ApprovePaymentFilter from "./ApprovePaymentFilter";
import { useGetTokenOrLogout } from "../../../utils/token";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function ApprovedPayments() {
  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const userId = localStorage.getItem("userId");
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [payments, setPayments] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);

  const [originalData, setOriginalData] = useState([]);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    setIsLoading(true);
    const fetchData = async () => {
      try {
        let url = "";
        // if (!filterCriteria) {
        //   url = `${API_ENDPOINT}route2.0/confirmedpayments/${userId}`;
        // } else {
        //   url = `${API_ENDPOINT}route2.0/fetchconfirmedpayment/${userId} `;
        // }

        
const token = getTokenOrLogout();
if (!token) return;
let headers = { 
  Authorization: `Bearer ${token}`,

};
        url = `${API_ENDPOINT}route2.0/confirmedpayments/${userId}`;
        const response = await fetch(url,{headers});
        if (!response.ok) {
          throw new Error(`${response.status}`);
        }

        const data = await response.json();

        const sortedData = data.sort(
          (a, b) => new Date(b.payment_date) - new Date(a.payment_date)
        );

        setPayments(sortedData);
        setIsLoading(false);
        setOriginalData(sortedData);
      } catch (error) {
        if (error.message.includes("401")) {
          navigate("/page401");
        } else if (error.message.includes("403")) {
          navigate("/page403");
        } else if (error.message.includes("404")) {
          navigate("/page404");
        }  else if (error.message.includes("440")) {
          navigate("/page440");
        } 
        else {
          navigate("/page500");
        }
        setIsLoading(false);
      }
      
    };
    fetchData();
  }, [userId]);

  return (
    <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <ApprovePaymentFilter
            setPayments={setPayments}
            originalData={originalData}
            isLoading={isLoading}
            showSnackbar={showSnackbar}
            setIsLoading={setIsLoading}
          />
        </Grid>
      </Grid>

      <TableContainer
        component={Paper}
        sx={{ borderRadius: "15px", padding: "10px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Enquiry Details
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Branch
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Date
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Payment Mode
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Amount Collected
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Initiated By
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Approved By
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Status
              </TableCell>
              <TableCell
                sx={{
                  color: "#000",
                  fontFamily: "Poppins",
                  fontSize: "18px",
                  fontStyle: "normal",
                  fontWeight: 600,
                }}
              >
                Actions
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {payments.length === 0 ? (
              <Grid
                item
                xs={12}
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                }}
              >
                <Typography variant="h5">No data available</Typography>
              </Grid>
            ) : (
              payments
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => (
                  <TableRow
                    key={index}
                    style={{
                      backgroundColor: index % 2 === 0 ? "#f0f0f0" : "inherit",
                      width: "6%",
                    }}
                  >
                    <TableCell
                      sx={{
                        color: "blue",
                        fontFamily: "Poppins",
                        fontSize: "16px",
                        fontStyle: "normal",
                        fontWeight: 600,
                        cursor: "pointer",
                        "&:hover": {
                          textDecoration: "none",
                        },
                      }}
                      onClick={() =>
                        window.open(
                          `/enquirydetails/${row.enquiry_id.id}`,
                          "_blank"
                        )
                      }
                    >
                      {`${
                        row?.enquiry_id?.first_name
                          ? row?.enquiry_id?.first_name
                          : ""
                      } ${
                        row?.enquiry_id?.last_name
                          ? row?.enquiry_id?.last_name
                          : ""
                      }`}{" "}
                      <br />
                      {row.enquiry_id?.mobile && (
                        <Typography
                          sx={{
                            fontFamily: "Poppins",
                            fontSize: "14px",
                            fontStyle: "normal",
                            fontWeight: 400,
                            color: "#364152",
                          }}
                        >
                          {row.enquiry_id?.mobile}
                        </Typography>
                      )}
                    </TableCell>
                    <TableCell>
                      {row?.enquiry_id?.branch_id?.branch_name}
                    </TableCell>
                    <TableCell>{fDate(row?.payment_date)}</TableCell>
                    <TableCell>{row?.payment_mode_id?.payment_mode}</TableCell>
                    <TableCell>{row?.amount_paid}</TableCell>
                    <TableCell>{row?.collected_by?.first_name}</TableCell>

                    <TableCell>{row?.approved_by?.first_name}</TableCell>
                    <TableCell>
                      {row?.collected_status === "received" && (
                        <Chip label="Received" color="error" />
                      )}
                      {row?.collected_status === "deposited" && (
                        <Chip label="Deposited" color="warning" />
                      )}
                      {row?.collected_status === "confirmed" && (
                        <Chip label="Confirmed" color="primary" />
                      )}
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                ))
            )}
          </TableBody>
        </Table>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={payments.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </TableContainer>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default ApprovedPayments;
