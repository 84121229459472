import { Box, CircularProgress, Grid, Paper, Stack, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../components/ui-component/card/MainCard";

export default function CampaignInfo({ selectedid }) {
  console.log("selected id is", selectedid);
  const userId = localStorage.getItem("userId");
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [campaignData, setCampaignData] = useState(null);
  const [loading, setLoading] = useState(true);


  useEffect(() => {
    // Function to fetch campaign data
    const fetchCampaignData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}campaign/viewmore`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            campaign_id: selectedid,
            user_id: parseInt(userId),
          }),
        });

        if (!response.ok) {
          throw new Error("Failed to fetch campaign data");
        }

        const data = await response.json();
        console.log(data)
        setCampaignData(data); // Set fetched data to state
      } catch (error) {
        console.error("Error fetching campaign data:", error);
        setLoading(false)
      } finally {
        setLoading(false); // Turn off loading regardless of success or failure
      }
    };

    fetchCampaignData(); // Call the function when component mounts
  }, [selectedid, userId]);
  return (
    <>
      {loading ? (
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <CircularProgress />
        </Box>
      ) : (
        // 
       <Grid container spacing={3} mt={2}> 
       <Grid item xs={12} lg={4} md={4} sm={6}>
       <Paper elevation={3} style={{padding:'10px', borderRadius:'20px'}} >
                  <Stack direction="row">
                    <img src="/advertising.png"  height={50}/>
                    <Stack direction="column" ml={2}>
                      <Typography variant="h4">Name Of Campaign</Typography>
                      <Typography variant="h5" mt={1}>
                        {campaignData?.campaign_name}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
       </Grid>

       <Grid item xs={12} lg={4} md={4} sm={6}>
       <Paper elevation={3}  style={{padding:'10px', borderRadius:'20px'}} >
                  <Stack direction="row">
                    <img src="/sponsorship.png"  height={50}/>
                    <Stack direction="column" ml={1}>
                      <Typography variant="h4">Campaign Type</Typography>
                      <Typography variant="h5" mt={1}>
                      {campaignData?.campaign_type_id?.name}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
       </Grid>

       <Grid item xs={12} lg={4} md={4} sm={6}>
       <Paper elevation={3}  style={{padding:'10px', borderRadius:'20px'}} >
                  <Stack direction="row">
                    <img src="/reset-password.png"  height={50}/>
                    <Stack direction="column" ml={1}>
                      <Typography variant="h4">Campaign Code</Typography>
                      <Typography variant="h5" mt={1}>
                      {campaignData?.campaign_code}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
       </Grid>

       <Grid item xs={12} lg={4} md={4} sm={6}>
       <Paper elevation={3}  style={{padding:'10px', borderRadius:'20px'}} >
                  <Stack direction="row">
                    <img src="/schedule.png"   height={50}/>
                    <Stack direction="column" ml={1}>
                      <Typography variant="h4">Start Date</Typography>
                      <Typography variant="h5" mt={1}>
                      {campaignData?.campaign_start_date}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
       </Grid>

       <Grid item xs={12} lg={4} md={4} sm={6}>
       <Paper elevation={3}  style={{padding:'10px', borderRadius:'20px'}} >
                  <Stack direction="row">
                    <img src="/schedule.png"   height={50}/>
                    <Stack direction="column" ml={1}>
                      <Typography variant="h4">End Date</Typography>
                      <Typography variant="h5" mt={1}>
                      {campaignData?.campaign_end_date}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
       </Grid>

       <Grid item xs={12} lg={4} md={4} sm={6}>
       <Paper elevation={3}  style={{padding:'10px', borderRadius:'20px'}} >
                  <Stack direction="row">
                    <img src="/money.png"   height={50}/>
                    <Stack direction="column" ml={1}>
                      <Typography variant="h4">Paid Status</Typography>
                      <Typography variant="h5" mt={1}>
                      {campaignData?.paid_status}
                      </Typography>
                    </Stack>
                  </Stack>
                </Paper>
       </Grid>

       <Grid item xs={12} md={6} lg={6} sm={6}>
              <Paper elevation={3} style={{padding:'10px', borderRadius:'20px'}} >
                <Box textAlign="center">
                  <img src="/branch (2).png" width={50}/>
                  <Typography variant="h4">Branch </Typography>
                  <Typography variant="h5" mt={2}>
                  {campaignData?.branch_id.join(", ")}
                  </Typography>
                </Box>
              </Paper>
            </Grid>


            <Grid item xs={12} md={6} lg={6} sm={6}>
              <Paper elevation={3} style={{padding:'10px', borderRadius:'20px'}}>
                <Box textAlign="center" >
                  <img src="/icons8-service-48.png" width={50} />
                  <Typography variant="h4">Sub Service </Typography>
                  <Typography variant="h5" mt={2}>
                  {campaignData?.sub_service_id?.join(", ")}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
            <Grid item xs={12} md={6} lg={6} sm={6}>
              <Paper elevation={3} style={{padding:'10px', borderRadius:'20px'}}>
                <Box textAlign="center" >
                  <img src="/countries.png" width={50} />
                  <Typography variant="h4">Country </Typography>
                  <Typography variant="h5" mt={2}>
                  {campaignData?.country_id.join(", ")}
                  </Typography>
                </Box>
              </Paper>
            </Grid>
       </Grid>
      )}
    </>
  );
}
