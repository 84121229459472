import { Alert, Snackbar } from '@mui/material';
import React, { useState } from 'react';


function CustomSnackbar({ open, onClose, message, severity, autoHideDuration }) {
  return (
    <Snackbar
      open={open}
      autoHideDuration={autoHideDuration || 5000} // Default duration is 3 seconds
      onClose={onClose}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
    >
      <Alert
        elevation={6}
        variant="filled"
        severity={severity || 'success'} // Default severity is 'success'
        onClose={onClose}
      >
        {message}
      </Alert>
    </Snackbar>
  );
}

export default CustomSnackbar;
