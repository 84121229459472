import React from "react";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  useMediaQuery,
  useTheme,
  IconButton,
  Divider,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
export default function NotificationPopup({ open, onClose, component }) {
  return (
    <>
      <Dialog
        onClose={onClose}
        open={open}
        maxWidth="sm"
        fullWidth
        PaperProps={{
          sx: {
            position: "absolute",
            top: 80,
            right: 10,
            margin: 0,
            // maxHeight: '100%',
            background: "#B9F6CA",
            boxShadow: "none",
            animation: "slideDown 0.3s forwards",
          },
        }}
      >
        <DialogTitle variant="h3" sx={{ fontSize: "20px", fontWeight: "700" }}>
          <IconButton
            edge="end"
            onClick={onClose}
            aria-label="close"
            sx={{ position: "absolute", right: 8, top: 2 }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>

        <DialogContent>{component}</DialogContent>
        <DialogActions></DialogActions>
      </Dialog>
    </>
  );
}
