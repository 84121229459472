import { Backdrop, Box, Button, Card, CardContent, CircularProgress, Grid, Stack, styled, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AdvisorClassFilter from './AdvisorClassFilter';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { useNavigate } from 'react-router-dom';
import { useGetTokenOrLogout } from '../../../utils/token';
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function Advisorclass() {
    const userId = localStorage.getItem("userId");
    const [classes, setClasses] = useState([]);
const navigate=useNavigate()
const getTokenOrLogout = useGetTokenOrLogout();

    const [originalData, setOriginalData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const CustomIcon = styled("img")({
        width: "15px",
        height: "15px",
        // position: 'fixed',
        // top: 0,
        // left: 0,
      });
    
     
      useEffect(() => {
        setIsLoading(true);
        const token = getTokenOrLogout();
    if (!token) return;
    let headers = { 
      Authorization: `Bearer ${token}`,
     
    };
        const fetchData = async () => {
          try {
            const response = await fetch(`${API_ENDPOINT}route2.0/fetchallclasses/${userId}`,{headers});
            if (response.ok) {
              const data = await response.json();
    
              setClasses(data);
             
              setOriginalData(data);
              setIsLoading(false);
            } 
            else if (response.status === 404) {
                   navigate("/page404");
                 } else if (response.status === 401) {
                   navigate("/page401");
                 } else if (response.status === 403) {
                   navigate("/page403");
                 } else if (response.status === 440) {
                   navigate("/page440");
                 } else {
                   navigate("/page500");
                 }}catch (error) {
                   console.error("Error fetching data:", error);
                   navigate("/page500");
                 }
        };
    
        fetchData();
      }, [userId]);
    
     

      const handleViewClick = (classe) => {
        localStorage.setItem('classDetails', JSON.stringify(classe));

    // Open a new window
    window.open(`/slot-details/${classe.id}`, "_blank");
  };
   
      
  return (
   <>
   <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="secondary" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
   <Box mb={2}>
        <AdvisorClassFilter
        originalData={originalData}
        setClasses={setClasses}
        setIsLoading={setIsLoading}
        />
      </Box>

      {classes.length === 0 && !isLoading ? (
        // Display a message when no classes are found
        <Box textAlign="center" width="100%" mt={10}>
          <Typography variant="subtitle1">No Classes found.</Typography>
        </Box>
      ) : (
   <Grid container spacing={2}>
       {classes.map((classe, index) => (
            <Grid item xs={12} lg={4} sm={6} md={6}>
              <Card
                variant="outlined"
                sx={{
                  borderColor: "black", 
                  borderWidth: "2px",
                  borderRadius: "30px",
                }}
              >
                <CardContent>
                  <Grid container spacing={4}>
                    <Grid item xs={12}>
                      <Stack direction="column" spacing={1} mb={2}>
                        <Typography
                          sx={{
                            color: "#000000",
                            fontFamily: '"Poppins-SemiBold", Helvetica',
                            fontSize: "24px",
                          }}
                          fontWeight={800}
                        >
                          {classe.name}
                        </Typography>

                        <Typography
                          sx={{
                            color: "#000000",
                            fontFamily: '"Poppins-Regular", Helvetica',
                            fontSize: "16px",
                          }}
                          fontWeight={600}
                        >
                          Trainer:
                           {classe?.trainer_id?.first_name}{" "}
                          {classe?.trainer_id?.last_name}
                        </Typography>
                      </Stack>

                      <Stack direction="column" spacing={2}>
                        <Stack direction="row" spacing={3}>
                          <Stack direction="row" spacing={2}>
                            <CustomIcon
                              src="/newTestPrep/Vector@2x.png"
                              alt="School Icon"
                            />
                            <Typography
                              sx={{
                                color: "#000000",
                                fontFamily: '"Poppins-Regular", Helvetica',
                                fontSize: "16px",
                              }}
                              fontWeight={400}
                            >
                              {classe?.start_time} - {classe?.end_time}
                            </Typography>
                          </Stack>

                          <Stack direction="row" spacing={2}>
                            <CustomIcon
                              src="/icon _Laptop_.png"
                              alt="School Icon"
                            />
                            <Typography
                              sx={{
                                color: "#000000",
                                fontFamily: '"Poppins-Regular", Helvetica',
                                fontSize: "16px",
                              }}
                              fontWeight={400}
                            >
                              {classe?.mode_of_delivery}
                            </Typography>
                          </Stack>
                        </Stack>

                        <Stack direction="row" spacing={3}>
                          <Stack direction="row" spacing={2}>
                            <CustomIcon
                              src="/newTestPrep/Vector.png"
                              alt="School Icon"
                            />
                            <Typography
                              sx={{
                                color: "#000000",
                                fontFamily: '"Poppins-Regular", Helvetica',
                                fontSize: "16px",
                              }}
                              fontWeight={400}
                            >
                              {classe?.branch_id?.branch_name}{" "}
                            </Typography>
                          </Stack>

                         
                        </Stack>

                        <Stack direction="row" spacing={3}>
                          <Stack direction="row" spacing={2}>
                            <CustomIcon
                              src="/testtype.png"
                              alt="Test Type Icon"
                            />
                            <Typography
                              sx={{
                                color: "#000000",
                                fontFamily: '"Poppins-Regular", Helvetica',
                                fontSize: "16px",
                              }}
                              fontWeight={400}
                            >
                             {classe?.test_type_id?.name}
                            </Typography>
                          </Stack>

                       
                        </Stack>


                        <Stack direction="row" spacing={4}>
                          <Grid container spacing={2}>
                            <Grid item xs={6}>
                              <Button
                                style={{
                                  borderRadius: "7px",
                                  background: "#019E6F",
                                  boxShadow:
                                    " 0px 0px 15px 1px rgba(0, 0, 0, 0.05)",
                                  color: "#FFF",
                                  fontFamily: 'Poppins-Regular", Helvetica',
                                  fontSize: "16px",
                                  fontStyle: "normal",
                                  fontWeight: 400,
                                }}
                       endIcon={<ArrowForwardIcon/>}
                       onClick={()=> handleViewClick(classe)}
                              >

                                View Slot Details 
                              </Button>
                            </Grid>
                          </Grid>
                        </Stack>
                
                      </Stack>
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
       ))}
        </Grid>
      )}
   </>
  )
}
