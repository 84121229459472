// Author : Ayesha Shaikh
// Created on 24 aug 2024

import { Backdrop, CircularProgress, Typography } from "@mui/material";
import {
  Document,
  Font,
  Page,
  PDFViewer,
  StyleSheet,
  Text,
  View,
} from "@react-pdf/renderer";
import React, { useEffect, useState } from "react";
import AdvisorClosedTask from "./AdvisorClosedTask";
import AdvisorCreatedTask from "./AdvisorCreatedTask";
import AdvisorAdditionalDetails from "./AdvisorAdditionalDetails";

Font.register({
  family: "Open Sans",
  fonts: [
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-regular.ttf",
    },
    {
      src: "https://cdn.jsdelivr.net/npm/open-sans-all@0.1.3/fonts/open-sans-600.ttf",
      fontWeight: 600,
    },
  ],
});

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function AdvisorReportPdf() {
  const userId = localStorage.getItem("userId");
  const [error, setError] = useState(null);
  const styles = StyleSheet.create({
    document: { padding: 10 },
    page: {
      fontFamily: "Open Sans",

      paddingTop: 30,
      paddingLeft: 60,
      paddingRight: 60,
      lineHeight: 1.5,
      flexDirection: "column",
    },
    table: {
      marginTop: 10,
      width: "100%",
      borderCollapse: "collapse",
      marginBottom: 10,
    },
    tableRow: { flexDirection: "row" },
    tableCell: {
      padding: 5,
      fontSize: 12,
      textAlign: "center",
      flex: 1,
      border: "0.5px solid black",
    },

    tableHeaderCell: {
      fontFamily: "Open Sans",
      padding: 5,
      fontSize: 12,
      textAlign: "center",
      fontWeight: "bold",
      flex: 1,
      border: "0.5px solid black",

      backgroundColor: "#7ff2ba",
      color: "#002e17",
    },
    tableCell1: {
      padding: 5,
      fontSize: 12,
      textAlign: "center",
      flex: 2,
      border: "0.5px solid black",
    },
    tableHeaderCell1: {
      fontFamily: "Open Sans",
      padding: 5,
      fontSize: 12,
      textAlign: "center",
      fontWeight: "bold",
      flex: 2,
      border: "0.5px solid black",

      backgroundColor: "#7ff2ba",
      color: "#002e17",
    },

    tableHeader: { flexDirection: "row" },
    text: {
      fontSize: 17,
    },
    name: {
      marginTop: 10,
      flexDirection: "row",
      alignItems: "center",
    },
    title: {
      fontFamily: "Open Sans",
      fontWeight: "normal",
      fontSize: 14,
    },
    subtitle: {
      fontFamily: "Open Sans",
      fontWeight: "bold",
      fontSize: 14,
    },
    subtitle1: {
      fontFamily: "Open Sans",

      fontSize: 14,
      borderRadius: "50%",

      paddingLeft: "10px",
      paddingRight: "10px",
      backgroundColor: "#7ff2ba",
    },
  });

  const [details, setDetails] = useState({});
  const [taskData, setTaskData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [taskcreateddata, setTaskCreatedData] = useState([]);
  const [completedData, setCompletedData] = useState([]);

  useEffect(() => {
    // Retrieve the state from localStorage
    const storedClassDetails = localStorage.getItem("details");
    console.log(JSON.parse(storedClassDetails));
    const details = JSON.parse(storedClassDetails);
    if (storedClassDetails) {
      setDetails(JSON.parse([storedClassDetails]));
    }

    setLoading(true);
    const requestData = {
      user_id: parseInt(userId),
      filter: details?.filterType,
      from_date: details.fromDate,
      to_date: details.toDate,
      report_for: details.reportFor?.user_id || null,
    };

    // Fetch data using selectedRowId and set it in the state

    fetch(`${API_ENDPOINT}reports/generatereport`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (response.status === 200) {
          // setTaskLoading(false);
          return response.json();
        } else if (response.status === 403) {
          setLoading(false);
          setError("unknown user");
        } else if (response.status === 404) {
          setLoading(false);
          setError("user not found");
        } else if (response.status === 400) {
          setLoading(false);
          setError("invalid filter date range");
        } else {
          setError("Server Error");
          setLoading(false);
        }
      })
      .then((result) => {
        const created = result.created?.map((i) => i);
        const completed = result.completed.map((i) => i);
        setTaskCreatedData(created);
        setCompletedData(completed);
        setTaskData(result);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
        setError("Error fetching data");
      });
  }, []);

  console.log(error);

  console.log("taskdata", taskData);

  const completedDataLength=completedData.length
  const totalLength = completedData.length + taskcreateddata.length;

  return (
    <>
      {loading ? (
        <Backdrop
          open={loading}
          style={{ zIndex: 9999 }}
          backgroundColor="white"
        >
          <Typography
            variant="h2"
            color="error"
            fontWeight={800}
            marginRight={2}
          >
            Generating Report
          </Typography>
          <img
            src="/Hourglass1.gif"
            alt="Error"
            style={{ width: "200px", height: "200px" }}
          />
        </Backdrop>
      ) : error && !loading ? (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            height: "100%",
          }}
        >
          <img
            src="/browser.png"
            alt="Error"
            style={{ width: "200px", height: "200px" }}
          />
          <Typography variant="h3">{error}</Typography>
          <Typography variant="h5">Please try again later</Typography>
        </div>
      ) : (
        <PDFViewer style={{ width: "100%", height: "100vh" }}>
          <Document style={styles.document}>
            <Page size="A4" style={styles.page}>
              <View>
                <Text>Dear Seniors,</Text>
                <Text>Greeting !!</Text>
                <View style={styles.name}>
                  <Text style={styles.title}>
                    Please refer the work report dated :{" "}
                  </Text>
                  <Text style={styles.subtitle}>
                    {taskData?.report_date_range}
                  </Text>
                </View>
              </View>
              <View style={styles.name}>
                <Text style={styles.title}>Name : </Text>
                <Text style={styles.subtitle}>
                  {taskData?.first_name} {taskData?.last_name}
                </Text>
                <Text style={styles.subtitle1}>{taskData?.role}</Text>
              </View>

              <View style={{ marginTop: "20px" }}>
                <AdvisorClosedTask
                  completedData={completedData}
                  styles={styles}
                />

{completedDataLength < 10 && ( 
   <View>
                <AdvisorCreatedTask
                  taskcreateddata={taskcreateddata}
                  styles={styles}
                />
                </View>
                
              )}

                {totalLength < 10 && (
                  <View style={{ marginTop: "30px" }}>
                    <AdvisorAdditionalDetails
                      taskData={taskData}
                      styles={styles}
                    />
                  </View>
                )}
              </View>
            </Page>

{completedDataLength >10 && (
  <Page size="A4" style={styles.page}>
      <View>
      <AdvisorCreatedTask
                  taskcreateddata={taskcreateddata}
                  styles={styles}
                />
      </View>
  </Page>
)}

            {totalLength > 10 && (
              <Page size="A4" style={styles.page}>
                <View style={{ marginTop: "30px" }}>
                  <AdvisorAdditionalDetails
                    taskData={taskData}
                    styles={styles}
                  />
                </View>
              </Page>
            )}
          </Document>
        </PDFViewer>
      )}
    </>
  );
}
