import { Box, AppBar, Toolbar, IconButton, Typography, Button } from '@mui/material'
import React from 'react'
import Logo from '../../../components/ui-component/Logo'
import { Link } from 'react-router-dom'

function AppBarComponent() {
  return (
    <Box sx={{ flexGrow: 1 }}  >
    <AppBar position="static" sx={{ bgcolor: "white", height:'80px' }}>
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          color='default'
          aria-label="menu"
          sx={{ mr: 2 }}
        >
        
        </IconButton>
        <Logo />
        <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
      
        </Typography>
        <Box display='flex'>
          <Link to='/center-registration'>
      <Button variant='contained' color="error">Register Coaching Center</Button>
      </Link>
      <Box m={2} />
      <Link to='/login'>
      <Button variant='contained' color="error">Center Login</Button>
      </Link>
    </Box>
      </Toolbar>
    </AppBar>
  </Box>
  )
}

export default AppBarComponent