import { Box, CircularProgress, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { fDate } from "../../../../../utils/formatTime";

export default function Walkins(enquiryData) {
  console.log("enquirydata", enquiryData);

  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const enquiryId = enquiryData.enquiryData.id;
  console.log("enquirydata", enquiryId);

  const [loading, setLoading] = useState(false);
  const [walknsData, setWalkinsData] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}route2.0/walkindetail/${enquiryId}`
        );
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);

          setWalkinsData(data);
          setLoading(false);
        } else {
          setLoading(false);
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  console.log("walkinsdata", walknsData);
  const columns = [
    {
      field: "reason",
      headerName: "Reason for Walk-in",
      // width: 250,
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.type_of_walking}</div>,
    },
    {
      field: "walkInDate",
      headerName: "Walk In Date",
      // width: 170,
      flex: 0.7,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params.row?.appointment_date)}</div>,
    },
    {
      field: "walkInTime",
      headerName: "Walk In Time",
      // width: 170,
      flex: 0.5,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.appointment_time}</div>,
    },
    {
      field: "branch",
      headerName: "Branch",
      // width: 170,
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>{params.row?.branch_visited_id?.branch_name}</div>
      ),
    },
    {
      field: "registeredBy",
      headerName: "Registerd By",
      // width: 250,
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params.row?.created_by?.first_name}{" "}
          {params.row?.created_by?.last_name}
        </div>
      ),
    },
    {
      field: "reference_by",
      headerName: "Reference By",
      // width: 250,
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params.row?.reference_walkin_id?.name}
        
        </div>
      ),
    },
  ];

  const rows = [];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1, height: "auto" }}>No Walk-in Data</Box>
      </StyledGridOverlay>
    );
  }
  const autoHeight = "200px";
  return (
    <>
      <div style={{ width: "100%", marginTop: "10px" }}>
        <style>
          {`
                .header-cell {
                    background-color: #00E676; /* Set your desired background color */
                }
                `}
        </style>

        {loading ? (
          <Box
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              height: "100px",
            }}
          >
            <CircularProgress />
          </Box>
        ) : (
          <DataGrid
            rows={walknsData}
            columns={columns}
            autoHeight={walknsData.length === 0 && autoHeight}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            initialState={{
              pagination: {
                paginationModel: {
                  pageSize: 25,
                },
              },
            }}
            sx={{
              marginTop: "20px",
              height: walknsData.length > 6 ? "400px" : "auto",
            }}
            pageSizeOptions={[25, 50, 100]}
          />
        )}
      </div>
    </>
  );
}
