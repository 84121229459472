import { Box, Button, CircularProgress, Grid, TextField, Typography } from "@mui/material";
import JoditEditor from "jodit-react";
import React, { useMemo, useRef, useState } from "react";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";

export default function EditlessonplanForm({
  content,
  selectedfromdate,
  selectedtodate,
  selectedid,
  selectedDayName,
  handleClosePopup,
 planlist,
}) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");

  console.log(content);
  console.log(selectedfromdate);
  console.log(selectedtodate);
  console.log(selectedid);
  console.log(selectedDayName);
  const daysOfWeek = [
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Function to disable all days except Mondays

  // const [fromDate, setFromDate] = useState("");
  // const [toDate, setToDate] = useState("");

  // const handelfromdatechange = (e) => {
  //   setFromDate(e.target.value);
  //   console.log(e.target.value);
  // };

  // const handeltodatechange = (e) => {
  //   setToDate(e.target.value);
  // };

  const defaultEditorState = content ? content : "";
  const [editorStates, setEditorStates] = useState(
    daysOfWeek.map(() => defaultEditorState)
  );
  const editor = useRef(null);

  const parser = new DOMParser();
  const htmlString = "<strong>Beware of the leopard</strong>";
  const doc3 = parser.parseFromString(htmlString, "text/html");
  const defaultEmail = doc3.body.firstChild.textContent;

  const config = {
    readonly: false,
    toolbarAdaptive: false,
    buttons: [
      "undo",
      "redo",
      "font",
      "fontsize",
      "bold",
      "italic",
      "underline",
      "eraser",
      "brush",
      "link",
      "ul",
      "ol",
      "indent",
      "outdent",
      "left",
      "fullsize",
      "source",
    ],
    showCharsCounter: false,
    showWordsCounter: false,
    showXPathInStatusbar: false,
  };
  const handleEditorChange = (index, newContent) => {
    setEditorStates((prevStates) => {
      const newEditorStates = [...prevStates];
      newEditorStates[index] = newContent;
      return newEditorStates;
    });
  };

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const editorComponents = useMemo(
    () =>
      daysOfWeek.map((day, index) => {
        if (day === selectedDayName) {
          return (
            <Box key={index}>
              <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                {day}
              </Typography>
              <JoditEditor
                ref={editor}
                value={editorStates[index]}
                config={config}
                tabIndex={index + 2}
                onBlur={(newContent) => handleEditorChange(index, newContent)}
              />
            </Box>
          );
        } else {
          return null; // Hide the editor for other days
        }
      }),
    [editorStates]
  );

  const handleSubmit = () => {
    // Prepare data for submission
 
    const data = {
      [`day_${daysOfWeek.indexOf(selectedDayName) + 1}`]:
        editorStates[daysOfWeek.indexOf(selectedDayName)],
      user_id: userId,
    };

    // Make POST request to the API
    fetch(`${API_ENDPOINT}lessonplan/${selectedid}`, {
      method: "put",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
    
      .then((response) => {
     
        if (response.ok) {
          showSnackbar("Lesson Plan Uploaded Successfully", "success");
        } else {
          showSnackbar("Failed To Upload Lesson Plan", "error");
          throw new Error("Failed To Upload Lesson Plan");
        }

        setEditorStates(daysOfWeek.map(() => ""));
        // Handle successful response if needed
        setTimeout(() => {
         handleClosePopup()
        }, 1500);
        // Handle successful response if needed
      })
   
      .catch((error) => {
        // Handle errors
        console.error("Error:", error);
        showSnackbar("Error in uploading lesson plan", "error");
       
      });
  };

  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          // justifyContent: "center",

          minHeight: "100vh", // Ensure sufficient height
          width: "100%",
          margin: "auto",
          left: "50%",
        }}
      >
        
        <Grid container spacing={2} justifyContent="center">
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <Typography
              sx={{ fontSize: "24px", fontWeight: "600", textAlign: "center" }}
            >
              Edit Lesson Plan
            </Typography>
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6} mt={2}>
            <TextField
              label="Select Date From"
              variant="outlined"
              fullWidth
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              value={selectedfromdate}
              // onChange={handelfromdatechange}
            />
          </Grid>
          <Grid item xs={12} sm={6} md={4} lg={6} mt={2}>
            <TextField
              label="Select Date To"
              variant="outlined"
              fullWidth
              type="date"
              InputLabelProps={{
                shrink: true,
              }}
              disabled
              value={selectedtodate}
              // onChange={handeltodatechange}
            />
          </Grid>
          {daysOfWeek.map((day, index) => (
            <Grid key={index} item xs={12} lg={12} sm={12} md={12}>
              {/* <Typography>{day}</Typography> */}

              {editorComponents[index]}
            </Grid>
          ))}
          <Grid item xs={6} lg={12} md={3} sm={3} textAlign="center">
            <Button
              style={{
                background: "#019E6F",
                color: "#FFFFFF",
                borderRadius: "10px",
                width: "200px",
                height: "40px",
                fontSize: "24px",
                fontWeight: "600",
              }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      </Box>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
