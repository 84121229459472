import { Box, Button, Divider, Grid, Stack, Typography } from '@mui/material'
import React, { useState } from 'react'
import AdminRevenuTabs from './AdminRevenuTabs'
import RevenueCard from './RevenueCard';
import MainCard from '../../components/Maincard';
import RevenueButton from '../AdminDashboardOverview/RevenueButton';
import RevenueChart from './RevenueChart';
import PackageCard from '../AdminDashboardOverview/AdminPackage';
import ResentTransaction from './ResentTransaction';


export default function AdminDashboardRevenue() {
    const cardData = [
        {
          id: 1,
          background: '#fcdcb6',
          iconBackground: '#FF6B47',
          iconSrc: '/Revenue.svg',
          value: 0,
          label: 'Revenue',
        //   lineSrc: '/ActiveStudentLine.svg',
        text:'+8% from last Month'
        },
        {
          id: 2,
          background: '#b6fcbd',
          iconBackground: '#3CD856',
          iconSrc: '/Expenses.svg',
          value: 0,
          label: 'Expenses',
        //   lineSrc: '/PresentStudentLine.svg',
        text:'+5% from  last Month'
        },
        {
          id: 3,
          background: '#f7c8e5',
          iconBackground: '#FA5A7D',
          iconSrc: '/Profit.svg',
          value: 0,
          label: 'Profit',
        //   lineSrc: '/Absentstudentline.svg',
        text:'+1,2% from  last Month'
        },
        {
            id: 4,
            background: '#c1b6fa',
            iconBackground: '#5e43f7',
            iconSrc: '/Active Student.svg',
            value: 0,
            label: 'New Student Enrolled',
            // lineSrc: '/Absentstudentline.svg',
            text:'0,5% from last Month'
          },
      ];
    
      const newData = [
        { name: 'Expenses', data: [50, 65, 75, 80, 70, 85, 95] },
        { name: 'Revenue', data: [80, 90, 110, 105, 95, 115, 100] },
        { name: 'Profit', data: [40, 45, 42, 30, 50, 55, 60] },
      ];

      const [showAllTransactions, setShowAllTransactions] = useState(false);
      const packageData = [
        {
         
          subtitle: "IELTS (International English Language Testing System)1",
          date: 'Jan 19 2024 12:23:22' ,
          
        },
        {
         
          subtitle: "IELTS (International English Language Testing System)2",
          date: 'Jan 19 2024 12:23:22' ,
      
        },
    
        {
         
          subtitle: "IELTS (International English Language Testing System)3",
          date: 'Jan 19 2024 12:23:22' ,
       
        },
        {
         
          subtitle: "IELTS (International English Language Testing System)4",
          date: 'Jan 19 2024 12:23:22' ,
       
        },
      ];
    
      const displayedTransactions = showAllTransactions
      ? packageData
      : packageData.slice(0, 3);
      
       
  return (
    <Box className="box" sx={{ height: '1086px', width: '100%', position: 'relative' , color:'' }}>
    {/* Gradient Background */}
    <Box
      className="rectangle"
      sx={{
        background: 'linear-gradient(248deg, #FFF6F0 -41.57%, rgba(255, 246, 240, 0.00) 97.88%)',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: -1,
      }}
    />

    <Box
      style={{ textAlign: "left" }}
      sx={{
        position: 'absolute',
        // top: '40%',
        // left: '40%',
        // transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        zIndex: 1,
      }}
    >
        <Grid container spacing={2}>

 


        
<Grid item xs={12} lg={9} sm={12} md={12}>
          <Grid container spacing={1}>
            {cardData.map((data) => (
            
                <RevenueCard key={data.id} data={data} />
             
            ))}
            <Grid item xs={12}>
              <MainCard>
                <Stack display='flex'  flexDirection='row' alignItems='center' justifyContent='space-between'>
                <Typography sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '24px', fontStyle: 'normal', fontWeight: 600 }}>Revenue Statistics</Typography>
      <RevenueButton/>
    </Stack>
                <Divider sx={{marginTop:'10px'}}/>
               
              
              <RevenueChart key={newData.id} data={newData} />
              
              </MainCard>
            </Grid>
          </Grid>
        </Grid>



        <Grid item xs={12} lg={3} md={12} sm={12}>
      <MainCard  >
        <Stack style={{ maxHeight: '400px', overflowY: 'auto' }} spacing={2}>
        <Stack spacing={2} >
          <Typography variant='h2' sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '20px', fontStyle: 'normal', fontWeight: 600 }}>Recent Transactions</Typography>
          
          {displayedTransactions.map((data, index) => (
              <React.Fragment key={data.id}>
                <ResentTransaction data={data} />
                {index < displayedTransactions.length - 1 && <Divider flexItem style={{ margin: '10px' }} />}
              </React.Fragment>
            ))}

<Button
              onClick={() => setShowAllTransactions(!showAllTransactions)}
              style={{ color: '#000', textAlign: 'center', fontFamily: 'Poppins', fontSize: '16px', fontStyle: 'normal', fontWeight: 400, width: '190px', height: '48px', borderRadius: '14px', background: "#F2F2F2" }}
            >
              {showAllTransactions ? 'See less transactions' : 'See all transactions'}
            </Button>
        </Stack>
        </Stack>
      </MainCard>
    </Grid>

        </Grid>
</Box>
</Box>
  )
}
