import React, { useEffect, useState } from 'react';
import { Alert, Button, Dialog, DialogContent, DialogTitle, Stack, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography, useTheme } from '@mui/material';
import MainCard from '../../../../components/ui-component/card/MainCard';
import { CSVExport } from '../../../../components/ui-component/table/CSVExport';
import CreateClassDialog from '../../../../../pages/adminPages/classes/AddClass';
import CustomSnackbar from '../../../../components/ui-component/snackbar/CustomSnackbar';

const RoomScheduleTable = ({ roomName, data, roomId, branchId, roomTeachingMode }) => {
    const theme = useTheme()
    console.log(data)
  // Fetch room schedule data based on roomName
  const [roomSchedule, setRoomSchedule] = useState(data);
  const [isViewSlotsOpen, setViewSlotsOpen] = useState(false);
  const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);

  const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');

  const room_Id = roomId;
  const branch_Id = branchId;
  const room_Name = roomName;
  const room_Teaching_mode = roomTeachingMode;

  const handleViewSlots = () => {
    setViewSlotsOpen(true);
    // Add any other logic you want to execute when "View Slots" is clicked
  };

  // Handler for closing the ViewSlotsComponent
  const handleCloseViewSlots = () => {
    setViewSlotsOpen(false);
  };

  const handleOpenCreateDialog = () => {
    // Check if the room ID is available
    if (!room_Id) {
      // Display a popup or show an alert to inform the user to add a room first
      showSnackbar('Please add a room before creating a slot.');
    } else {
      setIsCreateDialogOpen(true);
    }
  };

  const updateRoomSchedule = (newClass) => {
    setRoomSchedule([...roomSchedule, newClass]);
    window.location.reload()
  };


  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {

    setRoomSchedule(data);
  }, [data]);

  return (
    <>
    <MainCard
        content={false}
        title="Class Schedule"
        secondary={
            <Stack direction="row" spacing={2} alignItems="center">
                <CSVExport data={roomSchedule} filename="basic-table.csv"  />
                <Button variant='contained'onClick={handleOpenCreateDialog} > Add Slot</Button>
            </Stack>
        }
    >
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell sx={{ backgroundColor: '#90ee90' }}>Slot</TableCell>
            <TableCell sx={{ backgroundColor: '#90ee90' }}>Test Type</TableCell>
            <TableCell sx={{ backgroundColor: '#90ee90' }}>Trainer</TableCell>
            <TableCell sx={{ backgroundColor: '#90ee90' }}>Class Capacity</TableCell>
            <TableCell sx={{ backgroundColor: '#90ee90' }}>Active Students</TableCell>
            <TableCell sx={{ backgroundColor: '#90ee90' }}>Seats Available</TableCell>
            <TableCell sx={{ backgroundColor: '#90ee90' }}>Action</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
        {roomSchedule.length === 0 ? (
                <TableRow>
                  <TableCell sx={{ pl: 3 }} />
                  <TableCell colSpan={4} align="center">
                    <Typography variant='h4'>No data available</Typography>
                  </TableCell>
                </TableRow>
              ) : (
          roomSchedule.map((row, index) => (
            <TableRow key={index}>
              <TableCell>{row.start_time} - {row.end_time}</TableCell>
              <TableCell>{row.test_type_id?.name}</TableCell>
                  <TableCell>{row.trainer_id?.first_name}</TableCell>
              <TableCell>{row.capacity}</TableCell>
              <TableCell>{row.enrolled_student !== null ? row.enrolled_student : 0}</TableCell>
              <TableCell>{row.seat_availability}</TableCell>
              
            {/*}  <TableCell>
                  <Button
                                    color="secondary"
                                    variant='contained'
                                    size='small'
                                    sx={{
                                        color: theme.palette.dark,
                                        borderColor: theme.palette.orange.main,
                                        '&:hover ': { background: theme.palette.orange.light }
                                    }}
                                    //onClick={handleViewSchedule}
                                    onClick={handleViewSlots}
                                >  
                                    View Slots
                                </Button>
                  </TableCell>*/}
              <TableCell>{/* Add action button or content */}</TableCell>
            </TableRow>
          )))}
        </TableBody>
      </Table>
    </TableContainer>

    <Dialog open={isViewSlotsOpen} onClose={handleCloseViewSlots} fullWidth maxWidth='lg' >
      <DialogTitle variant='h4'>Class Schedule</DialogTitle>
      <DialogContent>
        </DialogContent>
        </Dialog>
    </MainCard>

        <CreateClassDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        roomId= {room_Id}
        branchId = {branch_Id}
        room_Name = {room_Name}
        room_Teaching_mode= {room_Teaching_mode}
        updateRoomSchedule={updateRoomSchedule}

        />

            <CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
</>
  );
};

export default RoomScheduleTable;
