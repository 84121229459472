import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import {
  Autocomplete,
  Button,
  Checkbox,
  Collapse,
  FormControl,
  Grid,
  ListItemText,
  MenuItem,
  Select,
  Stack,
  TextField,
  Typography,
} from "@mui/material";
import FilterListOutlinedIcon from "@mui/icons-material/FilterListOutlined";
export default function({onApplyFilter,onResetFilter}) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const [open, setOpen] = useState(false);
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [createdbylist, setCreatedbylist] = useState([]);
  const [createdby, setCreatedby] = useState([]);
  const [packageslist, setPackagesList] = useState([]);
  const [packagename, setpackagename] = useState([]);
  const [createfrom, setCreatedfrom] = useState("");
  const [createdto, setCreatedto] = useState("");
  const handleToggle = () => {
    setOpen(!open);
  };

  const [name, setName] = useState("");
  const [number, setNumber] = useState("");
  console.log("number",number)
  const handleNamechange = (e) => {
    setName(e.target.value);
  };

  const handleMobileChange = (e) => {
    setNumber(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allbranchList=[{branch_name:"Select All"},...branchlist]

  const handelbranchchange = (event,value) => {
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
    } else {
      setBranch(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setCreatedbylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allleadownerList = [{ first_name: "Select All" }, ...createdbylist];

  const handelcretedby = (event, value) => {
    if (value.some((option) => option.first_name === "Select All")) {
      setCreatedby(createdbylist);
    } else {
      setCreatedby(value);
    }
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}package`);
        if (response.ok) {
          const data = await response.json();
          setPackagesList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allPackageList=[{package_name:"Select All"},...packageslist]
  
  const handelpackagechange = (event,value) => {
 

    if (value.some((option) => option.package_name === "Select All")) {
      setpackagename(packageslist);
    } else {
      setpackagename(value);
    }
   
  };


  const handlecratedfrom = (e) => {
    setCreatedfrom(e.target.value);
  };

  const handlecreatedto = (e) => {
    setCreatedto(e.target.value);
  };

  const newBranch=branch.map((i)=>i.id)
 const newPackageName=packagename.map((i)=>i.id)
 const newCreatedBy =createdby.map((i=>i.id))
  const handleApplyFilter = () => {
    onApplyFilter({
    name,
  number,
  newCreatedBy,createfrom, createdto,
newBranch,newPackageName

    });

  };

  const resetfilter = () => {
    onResetFilter();
    setName("")
    setNumber("")
   setCreatedby([])
   setCreatedfrom("")
   setCreatedto("")
   setBranch([])
   setpackagename([])
   
   
  }
  return (
    <div>
      <MainCard>
        <Stack
          display="flex"
          flexDirection="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Typography
            sx={{
              fontFamily: "Poppins",
              marginLeft: "5px",
              fontSize: "24px",
              fontWeight: 700,
            }}
          >
            Update Future Enrollments
          </Typography>

          <FilterListOutlinedIcon onClick={handleToggle} fontSize="medium" />
        </Stack>
        <Collapse in={open} sx={{ marginTop: "10px" }}>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Enquiry Name </Typography>
              <TextField
              size="small"
                value={name}
                onChange={handleNamechange}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} lg={3} md={6} sm={6}>
              <Typography sx={{ fontWeight: 600 }}>Mobile Number </Typography>
              <TextField
                    size="small"
                value={number}
                onChange={handleMobileChange}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
                type="number"
              />
            </Grid>

            <Grid item xs={12} lg={3} md={6} sm={6}>
           
           <Typography sx={{ fontWeight: 600 }}>Branch</Typography>

          
     
     <Autocomplete
           size="small"
           fullWidth
           value={branch}
           multiple
           onChange={handelbranchchange}
           options={allbranchList}
           getOptionLabel={(option) =>
            option.branch_name
           }
           renderInput={(params) => (
             <TextField
               {...params}
               InputProps={{
                 ...params.InputProps,
                 style: { borderRadius: "15px" }, // Add borderRadius here
               }}
             />
           )}
         />
       </Grid>

            <Grid item xs={12} lg={3} sm={6} md={6}>
           
                <Typography sx={{ fontWeight: 600 }}>Created By</Typography>
             
            
  <Autocomplete
                size="small"
                value={createdby}
                multiple
                onChange={handelcretedby}
                options={allleadownerList}
                getOptionLabel={(option) =>
                  `${option.first_name ? option.first_name : ""} ${
                    option.last_name ? option.last_name : ""
                  }`
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={3} sm={6} md={6}>
           
                <Typography sx={{ fontWeight: 600 }}>
                  Package Details
                </Typography>
                {/* <Select
                  multiple
                  value={packagename}
                  onChange={handelpackagechange}
                  fullWidth
                  MenuProps={MenuProps}
                  style={{ borderRadius: "15px" }}
                  renderValue={(selected) =>
                    selected
                      .map((value) => {
                        if (value === "all") return "Select All";
                        const packages = packageslist.find(
                          (status) => status.id === value
                        );
                        return packages ? packages?.package_name : "";
                      })
                      .join(", ")
                  }
                >
                  <MenuItem key="select-all" value="all">
                    <Checkbox
                      checked={packagename.length === packageslist.length}
                    />
                    <ListItemText primary="Select All" />
                  </MenuItem>
                  {packageslist.map((name) => (
                    <MenuItem key={name.id} value={name.id}>
                      <Checkbox checked={packagename.indexOf(name.id) > -1} />
                      <ListItemText primary={name.package_name} />
                    </MenuItem>
                  ))}
                </Select> */}
      
      <Autocomplete
                size="small"
                value={packagename}
                multiple
                onChange={handelpackagechange}
                options={allPackageList}
                getOptionLabel={(option) =>
                 option.package_name
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>

            <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600 }}> Created From Date </Typography>
              <TextField
                    size="small"
                value={createfrom}
                onChange={handlecratedfrom}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
                type="date"
              />
            </Grid>

            <Grid item xs={12} lg={3} sm={6} md={6}>
              <Typography sx={{ fontWeight: 600 }}>Created To Date </Typography>
              <TextField
                    size="small"
                value={createdto}
                onChange={handlecreatedto}
                InputProps={{
                  style: { borderRadius: "15px" },
                }}
                fullWidth
                type="date"
              />
            </Grid>

            <Grid item xs={12} lg={12} sm={12} md={12} mt={2} textAlign="end">
              <Button
                variant="contained"
                color="error"
                style={{
                  width: "150px",
                  borderRadius: "24px",
                  fontWeight: 500,
                  fontSize: "14px",
                  marginRight: "8px",
                }}
                 onClick={resetfilter}
              >
                Reset Filter
              </Button>

              <Button
                variant="contained"
                color="primary"
                style={{
                  width: "150px",
                  borderRadius: "24px",
                  fontWeight: 500,
                  fontSize: "14px",
                }}
                 onClick={handleApplyFilter}
              >
                Apply Filter
              </Button>
            </Grid>
          </Grid>
        </Collapse>
      </MainCard>
    </div>
  );
}
