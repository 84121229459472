import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Paper, Select, Typography } from '@mui/material';
import React, { useEffect, useState } from 'react'
import QRCode from "react-qr-code";
import MainCard from '../../components/ui-component/card/MainCard';
import { toPng } from 'html-to-image';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function WalkInQR() {
    const userId = localStorage.getItem('userId');
    const [branches, setBranches] = useState([])
    const [branch, setBranch] = useState(null);
  const [qrcode, setQrcode] = useState(null);

  const production = process.env.REACT_APP_ENV === 'production';



  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route/fetchbranch/${userId}`)
      .then((response) => response.json())
      .then((data) => {
        setBranches(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

 
  const handleBranchSelect = (event) => {
    setBranch(event.target.value);
    setQrcode(null); 
  };

  // Generate QR code when button is clicked
  const handleGenerateQR = () => {
    setQrcode(branch); 
  };

 // Download QR code as PNG
 const downloadQRCode = () => {
    const qrCodeElement = document.getElementById('qrCodeContainer');
    toPng(qrCodeElement)
      .then(function (dataUrl) {
        const link = document.createElement('a');
        link.download = `branch_${branch}_qr_code.png`;
        link.href = dataUrl;
        link.click();
      })
      .catch((error) => {
        console.error('Error generating QR code image: ', error);
      });
  };

  return (
    <Grid container spacing={2}>
        <Grid item xs={6} lg={4}>
          <Paper
            sx={{
              height: "40px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "24px",
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
              Generate Branch Offline WalkIn QR
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={12}>
        <MainCard>
        <Grid container spacing={2}>
        <Grid item xs={12} lg={6}>
        <FormControl fullWidth>
          <InputLabel>Select Branch</InputLabel>
          <Select value={branch} onChange={handleBranchSelect}>
            {branches.map((b) => (
              <MenuItem key={b.id} value={b.id}>
                {b.branch_name}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={6}>
        <Button
          variant="contained"
          color="primary"
          disabled={!branch} 
          onClick={handleGenerateQR}
          sx={{ marginRight: 2 }}
        >
          Generate QR
        </Button>
        <Button
          variant="contained"
          color="secondary"
          disabled={!qrcode} 
          onClick={downloadQRCode}
        >
          Download QR
        </Button>
      </Grid>
      </Grid>
      <Box mt={2}>
  {qrcode && (
    <div id="qrCodeContainer" style={{ textAlign: 'center' }}>
      
      <img
        src='/Siec Logo.png' 
        alt="Logo"
        style={{
          width: '400px',
          margin: '0 auto 10px', 
          display: 'block'       
        }}
      />
      {/* QR Code */}
      <QRCode
        size={800}
        value={production 
            ? `https://student.siectestmasters.in/basic-registration?branch=${qrcode}` 
            : `https://dev.d1ufz94xk9odzx.amplifyapp.com/basic-registration?branch=${qrcode}`
        }
        viewBox={`0 0 256 256`}
        />

      {/* Branch Name */}
      <Typography variant='h4' sx={{ marginTop: 1, fontWeight: 'bold' }}>
        {branches.find(b => b.id === parseInt(branch))?.branch_name}
      </Typography>
    </div>
  )}
</Box>

        </MainCard>
        </Grid>
    
  </Grid>
  )
}

export default WalkInQR