import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import MainCard from "../../newTestPrep/components/ui-component/card/MainCard";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Grid, TextField, Typography } from "@mui/material";
import { fDate } from "../../utils/formatTime";

import styled from "styled-components";
import { Star } from "@mui/icons-material";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function TicketInfo() {
  const userId = localStorage.getItem("userId");


  const [openDialog, setOpenDialog] = useState(false);
const [closeReason, setCloseReason] = useState('');
  const StyledBox = styled(Box)`
    border-radius: 10px; // Set border radius
    overflow: hidden; // Hide overflowing content
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.1); // Add box shadow for better depth perception
    display: flex; // Set display to flex
    align-items: center; // Center vertically
    justify-content: center;
    height: 400px;
  `;

  // Define a styled img component with the desired styles
  const StyledImg = styled.img`
    max-width: 100%; /* Ensure the image does not exceed the box width */
    max-height: 100%; /* Ensure the image does not exceed the box height */
    width: auto; /* Set width to auto to maintain aspect ratio */
    height: auto; /* Set height to auto to maintain aspect ratio */
    margin: auto; /* Center the image vertically and horizontally */
    display: block;
  `;

  const { ticketId } = useParams();
  const [ticket, setTicket] = useState([]);
  const [loading, setLoading] = useState(true);
  console.log(ticket.id);
  console.log(ticket.ticket_priority);


  useEffect(() => {
    const fetchTicket = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}ticket/${ticketId}`);
        if (response.ok) {
          const data = await response.json();
          setTicket(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching ticket data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchTicket();
  }, [ticketId]);
  console.log(ticket);

  const handleCloseTicket = async () => {
    try {
      if (!ticket.id) {
        console.error("Ticket ID is missing or invalid");
        return;
      }
      const response = await fetch(`${API_ENDPOINT}ticket/${ticketId}`, {
        method: 'PUT',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ notes: closeReason,status:'completed',user_id:userId }),
      });
  
      if (response.ok) {
        setTicket(prevTicket => ({
          ...prevTicket,
          notes: closeReason,
      
        }));
        console.log('Ticket closed successfully');
        setOpenDialog(false);
        // Optionally, you can update ticket data here if needed
      } else {
        throw new Error(`Failed to close ticket with status: ${response.status}`);
      }
    } catch (error) {
      console.error('Error closing ticket:', error);
    }
  };
  
  return (
    <>
      <MainCard>
        <Grid container spacing={2}>
          <Grid item xs={12} lg={12} sm={12} md={12}>
            <Grid
              container
              display="flex"
              flexDirection="row"
              alignItems="center"
              spacing={3}
            >
              <Grid item>
                <Typography
                  sx={{
                    fontWeight: 600,
                    fontFamily: "Poppin",
                    fontSize: "32px",
                  }}
                >
                  {ticket.type_of_ticket}
                </Typography>
              </Grid>

              <Grid item>
                <Grid
                  container
                  display="flex"
                  flexDirection="row"
                  alignItems="center"
                  spacing={1}
                >
                  <Grid item>
                    <Typography
                      sx={{
                        width: "10px",
                        height: "10px",
                        background: "#A9A9A9",
                        borderRadius: "50%",
                      }}
                    ></Typography>
                  </Grid>

                  <Grid item>
                    <Typography
                      sx={{
                        fontWeight: 600,
                        fontFamily: "Poppin",
                        fontSize: "28px",
                        color: "#A9A9A9",
                      }}
                    >
                      {fDate(ticket.createdAt)}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography
              sx={{ fontFamily: "Poppin", fontWeight: 500, fontSize: "22px" }}
            >
              {ticket.issue_description} {ticket.feedback_description}
            </Typography>
          </Grid>

          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography>
              {ticket.ticket_priority ? (
                <span style={{ color: "gold", fontSize: "28px" }}>★★★★★</span>
              ) : (
                <span style={{ color: "gold", fontSize: "28px" }}>★★★</span>
              )}
            </Typography>
          </Grid>
          <Grid item xs={12} lg={6} md={6} sm={6}>
            <StyledBox>
           
            {ticket.screenshot_1 ?
  <StyledImg src={ticket.screenshot_1} alt="Ticket Screenshot 1" /> :
  <Typography>No image uploaded</Typography>
}
            </StyledBox>
          </Grid>
          <Grid item xs={12} lg={6} md={6} sm={6}>
            <StyledBox>
            {ticket.screenshot_2 ?
  <StyledImg src={ticket.screenshot_2} alt="Ticket Screenshot 1" /> :
  <Typography>No image uploaded</Typography>
}
            </StyledBox>
          </Grid>

          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography
              sx={{ fontFamily: "Poppin", fontWeight: 500, fontSize: "22px" }}
            >{ticket.notes}
            </Typography>
          </Grid>

          <Box
            sx={{
              display: "flex",
              justifyContent: "flex-end",
              width: "100%",
              marginTop: "10px",
            }}
          >
          <Button variant="contained" onClick={() => setOpenDialog(true)} style={{background:'#00C766',color:'black'}} >Close Ticket</Button>
          </Box>
        </Grid>
      </MainCard>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}  fullWidth>
  <DialogTitle variant="h3">Close Ticket</DialogTitle>
  <DialogContent>
    <TextField
      multiline
      rows={4}
      value={closeReason}
      onChange={(e) => setCloseReason(e.target.value)}
      label="Reason for closing"
      variant="outlined"
      fullWidth
      margin="dense"
    />
  </DialogContent>
  <DialogActions>
  <Button onClick={() => setOpenDialog(false)}  variant="contained" style={{color:'black',background:'#EE4B2B'}}>Cancel</Button>
    <Button onClick={handleCloseTicket}  color="primary" variant="contained" style={{background:'#00C766',color:'black'}}>Close Ticket</Button>
  </DialogActions>
</Dialog>

    </>
  );
}
