import React, { useEffect, useState } from "react";
import AdvisorNavbar from "../../../components/navbar/AdvisorNavbar";
import {
  Box,
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  FormLabel,
  Grid,
  IconButton,
  InputLabel,
  Radio,
  RadioGroup,
  Select,
  Stack,
  TextField,
  Tooltip,
  Typography,
  MenuItem,
  Chip,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Backdrop,
  CircularProgress,
  Autocomplete,
  Popper,
} from "@mui/material";
import Paper from "@mui/material/Paper";
import HelpOutlineIcon from "@mui/icons-material/HelpOutline";
import { styled } from "@mui/material/styles";
import { Controller, useForm } from "react-hook-form";
import { Country, State, City } from "country-state-city";

import { useNavigate } from "react-router-dom";
import ViewTaskDataGrid from "./sections/ViewTasks";
import ViewNotesDataGrid from "../notes/EnquiryNotes";
import EnquiryBookDemoForm from "../../../views/advisor/leadpages/EnquiryBookaDemo";
import CreatePopup from "../../../components/dialog/CreatePopup";
import DemoHistoryItem from "../../../newTestPrep/pages/enquiry/DemoHistoryItem";
import DemoHistoryList from "../../../newTestPrep/pages/enquiry/DemoHistoryList";
import EnquiryRescheduleDemoForm from "../../../views/advisor/leadpages/EnquiriesRescheduleDemo";
import axios from "axios";
import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import TaskDataGrid from "../../../section/tasks/AdvisorTaskDataGrid";
import EnquiryPayments from "./sections/payments/EnquiryPayments";
import DropOutRequest from "./sections/dropout/DropOutRequest";
import ViewClassHistory from "./sections/classes/ViewClassHistory";
import WhatsappMessages from "./sections/whatsapp/WhatsappMessages";
import CampaignDetail from "./sections/CampaignDetail/CampaignDetail";
import Email from "./sections/Email/Email";
import EventRegistration from "./sections/EventRegstration/EventRegistration";
import CustomPopup from "../../../components/CustomPopup";
import GradlynkForm from "./Gradlynk/GradlynkForm";
import GradlynkServicesTable from "./Gradlynk/GradlynkServicesTable";
import AnchorLink from "react-anchor-link-smooth-scroll";
import { Link } from "react-scroll";
import { DataGrid } from "@mui/x-data-grid";
import { useId } from "react";
import { fDateTime } from "../../../utils/formatTime";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
  ...theme.typography.body2,
  padding: theme.spacing(2),
  textAlign: "center",
  color: theme.palette.text.secondary,
  border: `2px solid #2196F3`,
  borderRadius: "12px",
}));

const BodyContainer = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  overflowY: "auto", // Enable vertical scrolling for the content
  height: "calc(100vh - 50px)", // Subtract header height
}));

function ViewEnquiry({ enquiryData, headerHeight }) {
  const navigate = useNavigate();
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    watch,
    setError,
    clearErrors,
    trigger,
    getValues,
  } = useForm();

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;

  const enquiryId = enquiryData?.id || null;
  const advisorId = localStorage.getItem("advisorId");
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");

  const [role, setRole] = useState(userRole);

  const [isWhatsappChecked, setIsWhatsappChecked] = useState(false);
  const [isDialogOpen, setIsDialogOpen] = useState(false);
  const [taskItems, setTaskItems] = useState([]);
  const [advisors, setAdvisors] = useState([]);
  const [notes, setNotes] = useState("");
  const [notesData, setNotesData] = useState([]);
  const [tasksData, setTasksData] = useState([]);
  const [isDemoPopupOpen, setIsDemoPopupOpen] = useState(false);
  const [isRescheduleDemoPopupOpen, setIsRescheduleDemoPopupOpen] =
    useState(false);
  const [branches, setBranches] = useState([]);
  const [demoHistory, setDemoHistory] = useState([]);
  const [payments, setPayments] = useState([]);
  const [classes, setClasses] = useState([]);
  const [testtypes, setTestTypes] = useState([]);
  const [subTestType, setSubtestType] = useState([]);
  const [subTestTypeFormat, setSubtestTypeFormat] = useState([]);
  const [users, setUsers] = useState([]);
  const [taskOwners, setTaskOwners] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [subLeadSources, setSubLeadSources] = useState([]);
  const [assignTo, setAssignTo] = useState(null);
  const [referenceByList, setReferenceByList] = useState([]);
  const [referenceBy, setReferenceBy] = useState(null);
  const [countryList, setcountryList] = useState([]);
  const [noteLoading,setNoteLoading] =useState(false)
  const [noteList,setNotesList] =useState([])

  const [branchId, setBranchId] = useState(
    enquiryData.nearest_branch_id?.id || null
  );
  const [selectedCountry, setSelectedCountry] = useState(
    enquiryData.country_id?.id || enquiryData.country_id || null
  );
  const [selectedState, setSelectedState] = useState(
    enquiryData.state_id?.id || enquiryData.state_id || null
  );
  const [selectedCity, setSelectedCity] = useState(
    enquiryData.city_id?.id || enquiryData.city_id || null
  );
  const [selectedService, setSelectedService] = useState(
    enquiryData.service_id?.id || null
  );
  const [selectedSourceType, setSelectedSourceType] = useState(
    enquiryData.lead_source_type_id?.id || null
  );

  const [recycleReasonId, setRecycleReasonId] = useState(null);
  const [recycleRequestLoading, setRecycleRequestLoading] = useState(false);

  const [maritalStatus, setMaritalStatus] = useState([]);
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [services, setServices] = useState([]);
  const [subServices, setSubServices] = useState([]);
  const [sourceTypes, setSourceTypes] = useState([]);
  const [subSourceTypes, setSubSourceTypes] = useState([]);
  const [purposeOfTest, setPurposeOfTest] = useState([]);
  const [leadTestExperience, setLeadTestExperience] = useState([]);
  const [examStatuses, setExamStatus] = useState([]);
  const [proficiencyLevel, setProficiencyLevel] = useState([]);
  const [prospectiveLevel, setProspectiveLevel] = useState([]);
  const [levelOfInterest, setLevelOfInterest] = useState([]);
  const [targetCountries, setTargetCountries] = useState([]);
  const [recycledReasons, setRecycleReasons] = useState([]);
  const [selectedTestTypeId, setSelectedTestTypeId] = useState();

  const [assignmentType, setAssignmentType] = useState(null);

  const [isDropOutRequest, setIsDropOutRequest] = useState(false);

  const [studentInfo, setStudentInfo] = useState(enquiryData || {});
  console.log("studentInfois", studentInfo?.enq_personal_detail_id);
  const [demoHistoryExpanded, setDemoHistoryExpanded] = useState(false);
  const [activityExpanded, setActivityExpanded] = useState(true);

  const handleDemoHistoryAccordionChange = () => {
    setDemoHistoryExpanded(!demoHistoryExpanded);
  };

  const handleActivityAccordionChange = () => {
    setActivityExpanded(!activityExpanded);
  };

  const examStatus = watch("exam_status_id");
  const testBooking = watch("test_taken");
  const deliveryMode = watch("delivery_mode");

  const recycledReason = watch("prospective_level_id");

  const [examStatusId, setExamStatusId] = useState(
    enquiryData.exam_status_id || null
  );

  const [prospectiveLevelId, setProspectiveLevelId] = useState(
    enquiryData.prospective_level_id?.id || null
  );

  const [pinCodeData, setPinCodeData] = useState({});
  const [pinCode, setPinCode] = useState(enquiryData.pincode || null);
  const [pincodeError, setPincodeError] = useState("");
  const [nearest_branch, setNearestBranch] = useState(
    enquiryData.nearest_branch_id?.id || null
  );

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [changes, setChanges] = useState({});

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const [showPayment, setShowPayment] = useState(false);
  const [showClasses, setShowClasses] = useState(false);
  const [demoLoading, setDemoLoading] = useState(false);
  const [paymentLoading, setPaymentLoading] = useState(false);
  const [classLoading, setClassLoading] = useState(false);

  const [isSendTestLink, setIsSendTestLink] = useState("no");
  const [gradlynkpopup, setGradlynkpopup] = useState(false);

  const [showGradlynkService, setShowGradlynkService] = useState(false);
  const [showAssignHistory, setShowAssignHistory] = useState(false);
  const [assignData, setAssignData] = useState([]);

  const handleToggleAssign = () => {
    setShowAssignHistory(!showAssignHistory);
  };

  useEffect(() => {
    fetch(`${API_ENDPOINT}route2.0/assigntohistory/${enquiryId}`)
      .then((response) => {
        if (response.ok) {
          return response.json();
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      })
      .then((data) => {
        if (!data) return; // Stop further execution if data is not present

        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setAssignData(sortedData);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
      });
  }, [enquiryId]);

  const handleGrandlynkPopupOpen = () => {
    setGradlynkpopup(true);
  };

  const handleGrandlynkPopupclose = () => {
    setGradlynkpopup(false);
  };
  const handleSendTestLinkChange = (event) => {
    setIsSendTestLink(event.target.value);
  };

  const handlePaymentHistoryClick = () => {
    setShowPayment(!showPayment);
  };

  const handleClassHistoryClick = () => {
    setShowClasses(!showClasses);
  };

  const handleGradlynkServiceClick = () => {
    setShowGradlynkService(!showGradlynkService);
  };

  const isBookDemoDisabled = demoHistory.length === 0;
  const isRescheduleDemoDisabled = demoHistory.some(
    (item) => item.status === "pending" || item.status === "confirmed"
  );

  const isEnrollStudentDisabled =
    enquiryData?.student_id?.status_of_student === "active";

  const handleCountryChange = (event) => {
    setSelectedCountry(event.target.value);
    setValue("country_id", event.target.value);
    setStudentInfo({ ...studentInfo, country_id: event.target.value });
    handleFieldChange("country_id", event.target.value);
  };

  const handleStateChange = (event) => {
    setSelectedState(event.target.value);
    setValue("state_id", event.target.value);
    setStudentInfo({ ...studentInfo, state_id: event.target.value });
    handleFieldChange("state_id", event.target.value);
  };

  const handleCityChange = (event) => {
    setSelectedCity(event.target.value);
    setValue("city_id", event.target.value);
    setStudentInfo({ ...studentInfo, city_id: event.target.value });
    handleFieldChange("city_id", event.target.value);
  };

  const handleServiceChange = (event) => {
    setSelectedService(event.target.value);
    setValue("service_id", event.target.value);
    setStudentInfo({ ...studentInfo, service_id: event.target.value });
  };

  const handleSourceTypeChange = (event) => {
    setSelectedSourceType(event.target.value);
    setValue("lead_source_type_id", event.target.value);
    setStudentInfo({ ...studentInfo, lead_source_type_id: event.target.value });
    handleFieldChange("lead_source_type_id", event.target.value);
  };

  const handleExamStatusChange = (value) => {
    setExamStatusId(value);
    setValue("exam_status_id", value);
    setStudentInfo({ ...studentInfo, exam_status_id: value });
  };

  const handleProspectiveLevelChange = (value) => {
    setProspectiveLevelId(value);
    setValue("prospective_level_id", value);
    setStudentInfo({ ...studentInfo, prospective_level_id: value });
  };

  const handleRecycleReasonChange = (value) => {
    setRecycleReasonId(value);

    //setValue('prospective_level_id', value);
    // setStudentInfo({ ...studentInfo, prospective_level_id: value })
    const recycleReasonRequest = async (value) => {
      setRecycleRequestLoading(true);
      const requestData = {
        enquiry_id: enquiryId,
        request_reason_id: value,
        user_id: userId,
      };
      try {
        const response = await fetch(`${API_ENDPOINT}recyclerequest`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        });
        // .then((response) => {
        if (response.status === 201) {
          showSnackbar("Recycle Request sent to data entry", "success");
          console.log("Recycle Request successfully", JSON.stringify(response));
          console.log("Form reset successfully");
          setRecycleRequestLoading(false);
          setTimeout(() => {
            navigate("/enquiries");
          }, 4000);
        } else if (response.status === 409) {
          showSnackbar("Recycle Request Exists For this Enquiry", "error");
          setRecycleRequestLoading(false);
        } else {
          showSnackbar(
            "Error submitting recycle request. Please try again.",
            "error"
          );
          setRecycleRequestLoading(false);
        }
      } catch (error) {
        console.error("Error:", error);
        // alert('An error occurred. Please try again later.');
        showSnackbar(
          "An error occurred submiting the recycle request. Please try again later.",
          "error"
        );
        setRecycleRequestLoading(false);
      }
    };
    recycleReasonRequest(value);
  };

  //logic for test booking input
  const handleTestBookingStatusChange = (value) => {
    setValue("test_taken", value);
    setStudentInfo({ ...studentInfo, test_taken: value });
  };

  const handleOpenDemoPopup = () => {
    setIsDemoPopupOpen(true);
  };

  const handleCloseDemoPopup = () => {
    setIsDemoPopupOpen(false);
  };

  const handleOpenRescheduleDemoPopup = () => {
    setIsRescheduleDemoPopupOpen(true);
  };

  const handleCloseRescheduleDemoPopup = () => {
    setIsRescheduleDemoPopupOpen(false);
  };

  const handleOpenDropOutRequest = () => {
    setIsDropOutRequest(true);
  };

  const handleCloseDropOutRequest = () => {
    setIsDropOutRequest(false);
  };

  const handleLeadSourceTypeChange = (selectedType) => {
    // Filter sub lead sources based on the selected lead source type category
    const filteredSubLeadSources = leadSources.filter(
      (source) => source.category === selectedType
    );
    setSubLeadSources(filteredSubLeadSources);
  };

  const handleFieldChange = (fieldName, value) => {
    // Update the changes object
    setChanges((prevChanges) => ({
      ...prevChanges,
      [fieldName]: value,
    }));
  };

  const copyLinkToClipboard = () => {
    const link = "http://164.52.208.95:4002/test";
    navigator.clipboard
      .writeText(link)
      .then(() => {
        console.log("Link copied to clipboard:", link);
        showSnackbar("Link copied to clipboard", "success");
      })
      .catch((error) => {
        console.error("Error copying link to clipboard:", error);
        showSnackbar("Error copying link to clipboard:", "error");
      });
  };

  const handleSendEvaluationLink = async (data) => {
    try {
      const response = await fetch(`${API_ENDPOINT}evaluationlink`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ id: enquiryId }),
      });
      // .then((response) => {
      if (response.status === 200) {
        showSnackbar("Link sent successfully", "success");
        console.log("Enquiry sent successfully", JSON.stringify(response));
        console.log("Form reset successfully");
      } else {
        showSnackbar("Error submitting enquiry. Please try again.", "error");
      }
    } catch (error) {
      console.error("Error:", error);
      // alert('An error occurred. Please try again later.');
      showSnackbar("An error occurred. Please try again later.", "error");
    }
  };

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}leadsource`)
      .then((response) => response.json())
      .then((data) => {
        setLeadSources(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching leadsource:", error);
      });
  }, []);

  const handlePinCodeChange = (event) => {
    const newPinCode = event.target.value;
    setStudentInfo({ ...studentInfo, pincode_id: newPinCode });
  };

  const fetchPinCodeData = async (pincode) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}route/detailbypincode/${pincode}`
      );
      if (response.status === 200) {
        return response.data;
      } else {
        console.log("Invalid pincode");
        setPincodeError("Invalid pin code");
        setNearestBranch(null);
        return null;
      }
    } catch (error) {
      console.error("Error fetching pin code data:", error);
      setPincodeError("Error fetching pinCode data");
      setNearestBranch(null);
      return null;
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const pinCodeData = await fetchPinCodeData(studentInfo.pincode);

        if (pinCodeData) {
          setPincodeError("");

          setNearestBranch(pinCodeData.branch_detail[0].id);

          setBranchId(pinCodeData.branch_detail[0].id);

          setStudentInfo((prevStudentInfo) => ({
            ...prevStudentInfo,
            state: pinCodeData.state,
            city: pinCodeData.city,
            nearest_branch: pinCodeData?.branch_detail[0].id,
          }));

          setChanges((prevStudentInfo) => ({
            ...prevStudentInfo,
            pincode: studentInfo.pincode,
            state: pinCodeData.state,
            city: pinCodeData.city,
            nearest_branch: pinCodeData?.branch_detail[0].id,
          }));
        } else {
          setPincodeError("Invalid pin code");
          setNearestBranch(null);
          // handle other state resets if needed
        }
      } catch (error) {
        console.error("Error fetching pin code data:", error);
        setPincodeError("Error fetching pin code data");
        setNearestBranch(null);
        showSnackbar("Error fetching pin code data", "error");
        // handle other state resets if needed
      }
    };

    if (studentInfo.pincode?.length === 6) {
      fetchData();
    }
  }, [studentInfo.pincode]);

  const handlePinCodeChange2 = (event) => {
    const newPinCode = event.target.value;
    if (newPinCode.length === 6) {
      setValue("pincode_id", newPinCode);
      fetchPinCodeData2(newPinCode);
    } else {
      setPincodeError("Invalid pin code 3");

      setSelectedState(null);
      setSelectedCity(null);
      setValue("pincode_id", null);
      setValue("state_id", null);
      setValue("city_id", null);
      // setValue('nearest_branch', null);
    }
  };

  const fetchPinCodeData2 = async (pincode) => {
    try {
      const response = await axios.get(
        `${API_ENDPOINT}oldzipcode/pincode/${pincode}`
      );
      //setPinCodeData(response.data);
      if (response.status === 200) {
        const postOfficeData = response.data; // Get the first PostOffice object
        setPinCodeData(postOfficeData);
        console.log(postOfficeData);
        if (postOfficeData) {
          // Update the State and City fields with the data from the API
          handleFieldChange("pincode_id", pincode);
          setPincodeError("");
          setSelectedState(parseInt(postOfficeData.state_id));
          setValue("state_id", parseInt(postOfficeData.state_id));
          handleFieldChange("state_id", parseInt(postOfficeData.state_id));

          setSelectedCity(parseInt(postOfficeData.city_id));
          setValue("city_id", parseInt(postOfficeData.city_id));
          handleFieldChange("city_id", parseInt(postOfficeData.city_id));
          // setValue('nearest_branch', postOfficeData?.branch_detail[0]?.id);
        }
      } else {
        console.log("Invalid pincode");
        console.log("API error");
        //alert('error')
        setPincodeError("Invalid pin code 1");
        //setNearestBranch(null)
        setError("pincode", { type: "manual", message: "Invalid pin code 2" });
        /* reset({
                state: '',
                city: '',
                nearest_branch: null
            })*/
        setValue("pincode_id", null);
        setValue("state_id", "");
        setValue("city_id", "");
        setSelectedState(null);
        setSelectedCity(null);
        //setValue('nearest_branch', null);
      }
    } catch (error) {
      console.error("Error fetching pin code data:", error);
      showSnackbar("Error fetching pin code data", "error");
      setPinCodeData(null);
    }
  };

  //logic for save button
  const handleSaveStudentInfo = async () => {
    /* const validationFields = ['mobile', 'whatsapp_number', 'email'];
          const isValid = await trigger(validationFields);

          if (!isValid) {
            validationFields.forEach((field) => {
              const error = getValues(field) ? errors[field]?.message : 'Field is required';
              setValue(field, getValues(field)); // Reset the field value to trigger re-render with error message
              console.error(`Validation failed for ${field}: ${error}`);
            });
      
            console.error('Validation failed. Please check the entered values.');
            return;
          }*/
    if (assignmentType === "co_assignee") {
      studentInfo.co_assignee = assignTo;
    } else {
      studentInfo.assign_to = assignTo;
    }

    studentInfo.nearest_branch = branchId;
    studentInfo.user_id = parseInt(userId);
    changes.user_id = parseInt(userId);

    updateSectionData(changes, "student_info");

    setChanges({});
  };

  //put api request for updating information with save
  const updateSectionData = (sectionData, sectionName) => {
    console.log(enquiryId);
    console.log(sectionData);

    fetch(`${API_ENDPOINT}lmenquirycrm/${enquiryId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(sectionData),
    })
      .then((response) => {
        console.log(response);
        if (response.ok) {
          // Data updated successfully

          console.log(`Data updated for ${sectionName}`);
          showSnackbar(`Data updated for ${sectionName}`, "success");
          //alert('Data updated successfully')
        } else {
          // Handle errors here
          console.error(`Error updating data for ${sectionName}`);
          showSnackbar(`Error updating data for ${sectionName}`, "error");
          //alert('Error updating data')
        }
      })
      .catch((error) => {
        console.error("API request error:", error);
        showSnackbar("Network Error", "error");
        //alert('Network Error')
      });
  };

  // Function to open the dialog for add task
  const openDialog = () => {
    setIsDialogOpen(true);
  };

  // Function to close the dialog
  const closeDialog = () => {
    setIsDialogOpen(false);
  };

  //logic for add note button
  const noteSubmit = () => {
    const requestData2 = {
      note: notes,
      advisor_id: advisorId,
      student_id: enquiryId,
    };

    fetch(`${API_ENDPOINT}note`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData2),
    })
      .then((response) => {
        if (response.status === 201) {
          alert("Note added successfully");
          setNotes("");
        } else {
          // API call failed, show error message
          alert("Error adding note. Please try again.");
        }
      })
      .catch((error) => {
        // Handle network errors or other issues
        console.error("Error:", error);
        alert("An error occurred. Please try again later.");
      });
  };

  useEffect(() => {
    // Fetching countries and set initial value
    const countries = Country.getAllCountries();
    setValue("country", "IN");

    //Fetching states and set initial value
    const states = State.getStatesOfCountry(selectedCountry);
    //setValue('state', states[0]?.isoCode || '');

    //Fetching cities and set the initial value
    const cities = City.getCitiesOfState(selectedCountry, selectedState);
    //setValue('city', cities[0]?.name || '');
  }, [setValue, selectedCountry, selectedState]);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}maritalstatuses`)
      .then((response) => response.json())
      .then((data) => {
        setMaritalStatus(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching maritalstatus:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}country`)
      .then((response) => response.json())
      .then((data) => {
        const sortedData = [...data].sort(
          (a, b) => a.phone_code - b.phone_code
        );
        const uniqueArray = sortedData.filter(
          (obj, index, self) =>
            index === self.findIndex((t) => t.phone_code === obj.phone_code)
        );
        setcountryList(data);
        setCountries(uniqueArray);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching country:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedCountry) {
      fetch(`${API_ENDPOINT}oldstate`)
        .then((response) => response.json())
        .then((data) => {
          const filteredState = data.filter(
            (state) => state.country_id === selectedCountry
          );
          setStates(filteredState);
          console.log(filteredState);
        })
        .catch((error) => {
          console.error("Error fetching state:", error);
        });
    }
  }, [selectedCountry]);

  useEffect(() => {
    if (selectedCountry && selectedState) {
      fetch(`${API_ENDPOINT}oldcity`)
        .then((response) => response.json())
        .then((data) => {
          const filteredCity = data.filter(
            (city) =>
              city.country === selectedCountry && city.state === selectedState
          );
          setCities(filteredCity);
          console.log(filteredCity);
        })
        .catch((error) => {
          console.error("Error fetching city:", error);
        });
    }
  }, [selectedCountry, selectedState]);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}targetcountries`)
      .then((response) => response.json())
      .then((data) => {
        setTargetCountries(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching target countries:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}service`)
      .then((response) => response.json())
      .then((data) => {
        setServices(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedService) {
      fetch(`${API_ENDPOINT}subservice`)
        .then((response) => response.json())
        .then((data) => {
          const filteredSubServices = data.filter(
            (sub) => sub.service_id === selectedService
          );
          setSubServices(filteredSubServices);
          console.log(filteredSubServices);
        })
        .catch((error) => {
          console.error("Error fetching sub services:", error);
        });
    }
  }, [selectedService]);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}purposeoftest`)
      .then((response) => response.json())
      .then((data) => {
        setPurposeOfTest(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching purpose of test:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}leadtestexperience`)
      .then((response) => response.json())
      .then((data) => {
        setLeadTestExperience(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching test experience:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}examstatus`)
      .then((response) => response.json())
      .then((data) => {
        setExamStatus(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching exam status:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}levelofinterest`)
      .then((response) => response.json())
      .then((data) => {
        setLevelOfInterest(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching level of interest:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}proficiencylevel`)
      .then((response) => response.json())
      .then((data) => {
        setProficiencyLevel(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching proficiency level:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}prospectivelevel`)
      .then((response) => response.json())
      .then((data) => {
        setProspectiveLevel(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching prospective level:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_ENDPOINT}leadsource`)
      .then((response) => response.json())
      .then((data) => {
        const filteredLeadSources = data.filter((sub) => sub.is_active === 1);
        console.log(filteredLeadSources);
        //setSubSourceTypes(filteredLeadSources);
        setLeadSources(filteredLeadSources);
      })
      .catch((error) => {
        console.error("Error fetching lead sources:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedSourceType) {
      fetch(`${API_ENDPOINT}subleadsource`)
        .then((response) => response.json())
        .then((data) => {
          const filteredLeadSources = data.filter(
            (sub) => sub.lead_source_type_id === selectedSourceType
          );
          setSubLeadSources(filteredLeadSources);
        })
        .catch((error) => {
          console.error("Error fetching sub lead sources:", error);
        });
    }
  }, [selectedSourceType]);

  // Fetch reference bY Api

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}referencewalkin`);
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);

          setReferenceByList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}typeoftask`)
      .then((response) => response.json())
      .then((data) => {
        setTaskItems(data);
      })
      .catch((error) => {
        console.error("Error fetching task items:", error);
      });
  }, []);

  //fetching tasks for task input dropdown
  useEffect(() => {
    fetch(`${API_ENDPOINT}typeoftask`)
      .then((response) => response.json())
      .then((data) => {
        setTaskItems(data);
      })
      .catch((error) => {
        console.error("Error fetching task items:", error);
      });
  }, []);

  console.log(enquiryData);

  //fetching assign to dropdown menu

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route/getalltesttype`)
      .then((response) => response.json())
      .then((data) => {
        setTestTypes(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching testtypes:", error);
      });
  }, []);

  useEffect(() => {
    const testType = testtypes.find(
      (i) =>
        i.id === studentInfo.test_type_id?.id ||
        i.id === studentInfo.test_type_id
    );
    setSelectedTestTypeId(testType);
  });

  console.log("selectedTestTypeId", selectedTestTypeId);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route2.0/getsubtesttype/${selectedTestTypeId?.id}`)
      .then((response) => response.json())
      .then((data) => {
        setSubtestType(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching testtypes:", error);
        setSubtestType([]);
      });
  }, [selectedTestTypeId?.id]);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}testdeliveryformat`)
      .then((response) => response.json())
      .then((data) => {
        setSubtestTypeFormat(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching testtypes:", error);
      });
  }, []);

  console.log("subTestType", subTestType);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}branch`)
      .then((response) => response.json())
      .then((data) => {
        setBranches(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}recyclereason`)
      .then((response) => response.json())
      .then((data) => {
        setRecycleReasons(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching recyclereason:", error);
      });
  }, []);

  useEffect(() => {
    fetch(`${API_ENDPOINT}advisor`)
      .then((response) => response.json())
      .then((data) => {
        setAdvisors(data);
      })
      .catch((error) => {
        console.error("Error fetching advisor items:", error);
      });
  }, []);

  useEffect(() => {
    fetch()
      .then((response) => response.json())
      .then((data) => {
        setNotesData(data);
      })
      .catch((error) => {
        console.error("Error fetching note items:", error);
      });
  }, [enquiryId]);
  console.log(notesData);

  useEffect(() => {
    fetch(`${API_ENDPOINT}advisordashboard/taskbystudent/${enquiryId}`)
      .then((response) => response.json())
      .then((data) => {
        setTasksData(data);
      })
      .catch((error) => {
        console.error("Error fetching task items:", error);
      });
  }, [enquiryId]);
  console.log(tasksData);

  useEffect(() => {
    setDemoLoading(true);
    fetch(`${API_ENDPOINT}route/demohistory/${enquiryId}`)
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => new Date(b.updatedAt) - new Date(a.updatedAt));
        setDemoHistory(data);
        setDemoLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching demo items:", error);
        setDemoLoading(false);
      });
  }, [enquiryId]);
  console.log(demoHistory);

  useEffect(() => {
    setPaymentLoading(true);
    fetch(`${API_ENDPOINT}route/paymentforenquiry/${enquiryId}`)
      .then((response) => response.json())
      .then((data) => {
        data.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
        setPayments(data);
        setPaymentLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching payments items:", error);
        setPaymentLoading(false);
      });
  }, [enquiryId]);
  console.log(payments);

  useEffect(() => {
    setClassLoading(true);
    fetch(`${API_ENDPOINT}route/stdclass/${enquiryId}`)
      .then((response) => response.json())
      .then((data) => {
        setClasses(data);
        setClassLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching classes items:", error);
        setClassLoading(false);
      });
  }, [enquiryId]);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route/getalluser`)
      .then((response) => response.json())
      .then((data) => {
        let filteredData = data.filter(
          (user) =>
            user.user_role === "advisor" ||
            user.user_role === "admin" ||
            user.user_role === "trainer"
        );

        if (role === "advisor" || role === "trainer") {
          // If the role is advisor, filter the list to show only the logged-in advisor
          filteredData = data.filter((user) => user.id === parseInt(userId));
        }

        const sortedData = filteredData.sort((a, b) => {
          const nameA = (a.first_name + " " + a.last_name).toUpperCase();
          const nameB = (b.first_name + " " + b.last_name).toUpperCase();
          return nameA.localeCompare(nameB);
        });

        const sortedData2 = data.sort((a, b) => {
          const nameA = (a.first_name + " " + a.last_name).toUpperCase();
          const nameB = (b.first_name + " " + b.last_name).toUpperCase();
          return nameA.localeCompare(nameB);
        });
        setUsers(sortedData);
        setTaskOwners(sortedData2);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  const handleEnrollStudent = () => {
    // Navigate to the new route and pass enquiryData as state
    navigate("/enroll-student", { state: { enquiryData } });
  };

  const handleViewClassDetails = () => {
    // Navigate to the new route and pass enquiryData as state
    if (enquiryData.student_id) {
      navigate(`/view-student/${enquiryData?.student_id?.id}`);
    } else {
      navigate("/page404");
    }
  };

  console.log(prospectiveLevelId);
  const isReadOnly = enquiryData.is_active === 0;

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }
  const rows = [];

  const columns = [
    {
      field: "assing_to",
      headerName: "Assigned To",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>
          {params.row?.assign_to?.first_name} {params.row?.assign_to?.last_name}
        </Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "assing_by",
      headerName: "Assigned By",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>
          {params.row?.assigned_by?.first_name}{" "}
          {params.row?.assigned_by?.last_name}
        </Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "assing_time",
      headerName: "Assigned Time",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>
          {params.row?.assigned_date} {params.row?.assgined_time}
        </Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
    {
      field: "assing_type",
      headerName: "Assign Type",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <Typography>{params.row?.assign_type}</Typography>
      ),
      headerAlign: "center",
      align: "center",
    },
  ];

  const StyledPopper = (props) => (
    <Popper
      {...props}
      style={{
        zIndex: 1300, // Ensure the dropdown appears above other elements
        width: "auto",
        maxWidth: "100%",
        marginTop: 0, // Ensure the dropdown starts right below the TextField
      }}
      placement="bottom-start" // Ensure dropdown appears below the TextField
      modifiers={[
        {
          name: "flip",
          enabled: true,
          options: {
            fallbackPlacements: ["bottom"],
          },
        },
        {
          name: "preventOverflow",
          options: {
            boundary: "viewport",
            padding: 8, // Ensure the dropdown stays within the viewport
          },
        },
      ]}
    />
  );

  console.log("studentInfo", studentInfo);





console.log("noteList",noteList)

  return (
    <div className="content">
      <Backdrop open={recycleRequestLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Processing Recycle Request...
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>

      <Box m={2} display="flex" justifyContent="space-between">
        <Stack direction="row" spacing={2}>
          {isBookDemoDisabled ? (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOpenDemoPopup}
              disabled={isEnrollStudentDisabled || isReadOnly}
            >
              Book Demo
            </Button>
          ) : (
            <Button
              variant="contained"
              color="secondary"
              onClick={handleOpenRescheduleDemoPopup}
              disabled={
                isRescheduleDemoDisabled ||
                isEnrollStudentDisabled ||
                isReadOnly
              }
            >
              Reschedule Demo
            </Button>
          )}

          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleEnrollStudent()}
            disabled={isEnrollStudentDisabled || isReadOnly}
          >
            Enroll Student
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={() => handleViewClassDetails()}
            disabled={!isEnrollStudentDisabled}
          >
            View Class Details
          </Button>
        </Stack>

        <Stack direction="row" spacing={2} ml="auto">
          <Button
            variant="contained"
            color="secondary"
            onClick={handleClassHistoryClick}
          >
            Class History
          </Button>

          <Button
            variant="contained"
            color="secondary"
            onClick={handlePaymentHistoryClick}
          >
            Payment History
          </Button>
        </Stack>
      </Box>

      {showPayment && (
        <Box mb={2}>
          <Item>
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              <EnquiryPayments
                enquiryId={enquiryId}
                payments={payments}
                loading={paymentLoading}
                isReadOnly={isReadOnly}
              />
            </div>
          </Item>
        </Box>
      )}

      {showClasses && (
        <Box mb={2}>
          <Item>
            <div style={{ maxHeight: "400px", overflowY: "auto" }}>
              <ViewClassHistory
                enquiryId={enquiryId}
                classes={classes}
                loading={classLoading}
                setClasses={setClasses}
                isReadOnly={isReadOnly}
              />
            </div>
          </Item>
        </Box>
      )}

      {/* Personal Info*/}
      <Grid container spacing="4">
        <Grid item xs={12} md={9} lg={9} sm={12}>
          <Box container spacing="2">
            <Stack direction="column" spacing={2}>
              <Item>
                <Box
                  display="flex"
                  justifyContent="space-between"
                  mb={2}
                  id="personal-info"
                >
                  <Typography variant="h4" fontWeight={700}>
                    Personal Information
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSaveStudentInfo}
                    disabled={enquiryData.is_active === 0}
                  >
                    Save
                  </Button>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      First Name
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="first_name"
                      control={control}
                      //defaultValue={ enquiryData?.first_name || '' }

                      rules={{ required: true }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          value={studentInfo.first_name || ""}
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              first_name: e.target.value,
                            });
                            handleFieldChange("first_name", e.target.value);
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          error={Boolean(errors.first_name)}
                          helperText={
                            errors.first_name && "First name is required"
                          }
                        />
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Last Name
                    </InputLabel>
                    <Controller
                      name="last_name"
                      control={control}
                      //rules={{ required: true }}
                      //defaultValue={ enquiryData?.last_name || '' }
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          value={studentInfo.last_name || ""}
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              last_name: e.target.value,
                            });
                            handleFieldChange("last_name", e.target.value);
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          error={Boolean(errors.last_name)}
                          helperText={
                            errors.last_name && "Last name is required"
                          }
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>Gender</InputLabel>
                    <Controller
                      name="gender"
                      control={control}
                      //defaultValue={ enquiryData?.gender || '' }
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          value={studentInfo.gender || ""}
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              gender: e.target.value,
                            });
                            handleFieldChange("gender", e.target.value);
                          }}
                          error={Boolean(errors.gender)}
                          helperText={errors.gender && "Gender is required"}
                        >
                          <MenuItem value="male">Male</MenuItem>
                          <MenuItem value="female">Female</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Date of Birth
                    </InputLabel>
                    <Controller
                      name="dob"
                      control={control}
                      // defaultValue={ enquiryData?.dob || '' }
                      render={({ field }) => (
                        <TextField
                          {...field}
                          fullWidth
                          value={studentInfo.dob || ""}
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              dob: e.target.value,
                            });
                            handleFieldChange("dob", e.target.value);
                          }}
                          type="date"
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                            inputProps: { max: "2005-12-31" },
                          }}
                          error={Boolean(errors.dob)}
                          helperText={errors.dob && "Date of Birth is required"}
                        />
                      )}
                    />
                    {errors.dob && (
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          top: 0,
                          right: -8,
                        }}
                      >
                        *
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Marital Status
                    </InputLabel>
                    <Controller
                      name="marital_status"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          value={studentInfo.marital_status || ""}
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              marital_status: e.target.value,
                            });
                            handleFieldChange("marital_status", e.target.value);
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          error={!!errors.marital_status}
                          //helperText={errors.gender && 'Gender is required'}
                        >
                          {maritalStatus.map((maritalstatus) => (
                            <MenuItem
                              key={maritalstatus.id}
                              value={maritalstatus.id}
                            >
                              {maritalstatus.maritial_type}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>
                </Grid>
              </Item>

              <Item>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Typography id="contact-info" variant="h4" fontWeight={700}>
                    Contact Information
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSaveStudentInfo}
                    disabled={isReadOnly}
                  >
                    Save
                  </Button>
                </Box>
                <Grid container spacing={4}>
                  <Grid item xs={6} md={4} lg={4} container>
                    <Grid item xs={4} md={4} lg={4}>
                      <InputLabel
                        style={{ color: "black", position: "relative" }}
                      >
                        ISD Code
                        <span
                          style={{
                            color: "red",
                            position: "absolute",
                            marginLeft: "4px",
                            fontSize: "1.5em",
                          }}
                        >
                          *
                        </span>
                      </InputLabel>
                      <Controller
                        name="mobile_country_code"
                        control={control}
                        //defaultValue={91}
                        //rules={{ required: "Country code is required" }}
                        render={({ field }) => (
                          <TextField
                            select
                            {...field}
                            InputProps={{
                              style: { borderRadius: "12px" },
                              readOnly: isReadOnly,
                            }}
                            fullWidth
                            value={studentInfo.mobile_country_code}
                            onChange={(e) => {
                              setStudentInfo({
                                ...studentInfo,
                                mobile_country_code: e.target.value,
                              });
                              handleFieldChange(
                                "mobile_country_code",
                                e.target.value
                              );
                              clearErrors("mobile");
                            }}
                            error={Boolean(errors.mobile_country_code)}
                            //helperText={errors.country && 'Country is required'}
                            renderValue={(selected) => selected?.phone_code}
                          >
                            {countries.map((country) => (
                              <MenuItem
                                key={country.phone_code}
                                value={country.phone_code}
                              >
                                {country.phone_code}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {errors.mobile_country_code && (
                        <span style={{ color: "red" }}>
                          {errors.mobile_country_code.message}
                        </span>
                      )}
                    </Grid>

                    <Grid item xs={8} md={8} lg={8}>
                      <InputLabel
                        style={{ color: "black", position: "relative" }}
                      >
                        Mobile
                        <span
                          style={{
                            color: "red",
                            position: "absolute",
                            marginLeft: "4px",
                            fontSize: "1.5em",
                          }}
                        >
                          *
                        </span>
                      </InputLabel>
                      <Controller
                        name="mobile"
                        control={control}
                        //defaultValue='+91'
                        /*rules={{ 
                                required: "Contact number is required",
                                pattern: {
                                    value: /^[1-9][0-9]{9}$/,
                                    message: "Invalid mobile number."
                                }
                                }}*/
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={studentInfo.mobile}
                            onChange={(e) => {
                              setStudentInfo({
                                ...studentInfo,
                                mobile: e.target.value,
                              });
                              handleFieldChange("mobile", e.target.value);
                              // Validate mobile number if ISD code is 91
                              if (studentInfo.mobile_country_code === "91") {
                                const isValidMobile = /^[1-9][0-9]{9}$/.test(
                                  studentInfo.mobile
                                );
                                if (!isValidMobile) {
                                  setError("mobile", {
                                    type: "manual",
                                    message:
                                      "Invalid mobile number. It should be a 10-digit number starting with a non-zero digit.",
                                  });
                                } else {
                                  clearErrors("mobile");
                                }
                              } else {
                                clearErrors("mobile"); // Remove any mobile errors for other ISD codes
                              }
                            }}
                            InputProps={{
                              style: { borderRadius: "12px" },
                              readOnly: isReadOnly,
                            }}
                            error={!!errors.mobile}
                            //helperText={errors.mobile?.message}
                            fullWidth
                          />
                        )}
                      ></Controller>
                      {errors.mobile && (
                        <span style={{ color: "red" }}>
                          {errors.mobile.message}
                        </span>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={6} md={4} lg={4} container>
                    <Grid item xs={4} md={4} lg={4}>
                      <InputLabel
                        style={{ color: "black", position: "relative" }}
                      >
                        ISD Code
                        <span
                          style={{
                            color: "red",
                            position: "absolute",
                            marginLeft: "4px",
                            fontSize: "1.5em",
                          }}
                        >
                          *
                        </span>
                      </InputLabel>
                      <Controller
                        name="whatsapp_country_code"
                        control={control}
                        //defaultValue={91}
                        //rules={{ required: "Country code is required" }}
                        render={({ field }) => (
                          <TextField
                            select
                            {...field}
                            value={studentInfo.whatsapp_country_code}
                            onChange={(e) => {
                              setStudentInfo({
                                ...studentInfo,
                                whatsapp_country_code: e.target.value,
                              });
                              handleFieldChange(
                                "whatsapp_country_code",
                                e.target.value
                              );
                            }}
                            InputProps={{
                              style: { borderRadius: "12px" },
                              readOnly: isReadOnly,
                            }}
                            fullWidth
                            // defaultValue={91}
                            error={Boolean(errors.whatsapp_country_code)}
                          >
                            {countries.map((country) => (
                              <MenuItem
                                key={country.phone_code}
                                value={country.phone_code}
                              >
                                {country.phone_code}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {errors.whatsapp_country_code && (
                        <span style={{ color: "red" }}>
                          {errors.whatsapp_country_code.message}
                        </span>
                      )}
                    </Grid>
                    <Grid item xs={8} md={8} lg={8}>
                      <InputLabel
                        style={{ color: "black", position: "relative" }}
                      >
                        Whatsapp Number
                        <span
                          style={{
                            color: "red",
                            position: "absolute",
                            marginLeft: "4px",
                            fontSize: "1.5em",
                          }}
                        >
                          *
                        </span>
                      </InputLabel>
                      <Controller
                        name="whatsapp_number"
                        control={control}
                        /* rules={{ 
                                    pattern: {
                                        required: "Whatsapp number is required",
                                        value: /^[1-9][0-9]{9}$/,
                                        message: "Invalid whatsapp number."
                                    }
                                    }}*/
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={studentInfo.whatsapp_number}
                            onChange={(e) => {
                              setStudentInfo({
                                ...studentInfo,
                                whatsapp_number: e.target.value,
                              });
                              handleFieldChange(
                                "whatsapp_number",
                                e.target.value
                              );
                            }}
                            InputProps={{
                              style: { borderRadius: "12px" },
                              readOnly: isReadOnly,
                            }}
                            error={!!errors.whatsapp_number}
                            // helperText={errors.whatsapp_number?.message}
                            fullWidth
                          />
                        )}
                      ></Controller>
                      {errors.whatsapp_number && (
                        <span style={{ color: "red" }}>
                          {errors.whatsapp_number.message}
                        </span>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={6} md={4} lg={4} container>
                    <Grid item xs={4} md={4} lg={4}>
                      <InputLabel
                        style={{ color: "black", position: "relative" }}
                      >
                        ISD Code
                      </InputLabel>
                      <Controller
                        name="secondary_mobile_country_code"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            select
                            {...field}
                            value={studentInfo.secondary_mobile_country_code}
                            onChange={(e) => {
                              setStudentInfo({
                                ...studentInfo,
                                secondary_mobile_country_code: e.target.value,
                              });
                              handleFieldChange(
                                "secondary_mobile_country_code",
                                e.target.value
                              );
                            }}
                            InputProps={{
                              style: { borderRadius: "12px" },
                              readOnly: isReadOnly,
                            }}
                            fullWidth
                            error={Boolean(
                              errors.secondary_mobile_country_code
                            )}
                          >
                            {countries.map((country) => (
                              <MenuItem
                                key={country.phone_code}
                                value={country.phone_code}
                              >
                                {country.phone_code}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {errors.secondary_mobile_country_code && (
                        <span style={{ color: "red" }}>
                          {errors.secondary_mobile_country_code.message}
                        </span>
                      )}
                    </Grid>

                    <Grid item xs={8} md={8} lg={8}>
                      <InputLabel
                        style={{ color: "black", position: "relative" }}
                      >
                        Secondary Mobile
                      </InputLabel>
                      <Controller
                        name="secondary_mobile"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            {...field}
                            value={studentInfo.secondary_mobile}
                            onChange={(e) => {
                              setStudentInfo({
                                ...studentInfo,
                                secondary_mobile: e.target.value,
                              });
                              handleFieldChange(
                                "secondary_mobile",
                                e.target.value
                              );
                            }}
                            InputProps={{
                              style: { borderRadius: "12px" },
                              readOnly: isReadOnly,
                            }}
                            error={!!errors.secondary_mobile}
                            fullWidth
                          />
                        )}
                      ></Controller>
                      {errors.secondary_mobile && (
                        <span style={{ color: "red" }}>
                          {errors.secondary_mobile.message}
                        </span>
                      )}
                    </Grid>
                  </Grid>

                  <Grid item xs={6} md={4} lg={4}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      Email
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: "Email is required",
                        pattern: {
                          value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                          message: "Invalid email address",
                        },
                      }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={studentInfo.email}
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              email: e.target.value,
                            });
                            handleFieldChange("email", e.target.value);
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          variant="outlined"
                          error={!!errors.email}
                          fullWidth
                        />
                      )}
                    ></Controller>
                    {errors.email && (
                      <span style={{ color: "red" }}>
                        {errors.email.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={4}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      Secondary Email
                    </InputLabel>
                    <Controller
                      name="secondary_email"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          value={studentInfo.secondary_email}
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              secondary_email: e.target.value,
                            });
                            handleFieldChange(
                              "secondary_email",
                              e.target.value
                            );
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          variant="outlined"
                          error={!!errors.secondary_email}
                          fullWidth
                        />
                      )}
                    ></Controller>
                    {errors.secondary_email && (
                      <span style={{ color: "red" }}>
                        {errors.secondary_email.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      Country of Residence
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="country_id"
                      control={control}
                      defaultValue={101}
                      rules={{ required: "Country is required" }}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          value={selectedCountry}
                          onChange={handleCountryChange}
                          // defaultValue={101}
                          error={Boolean(errors.country_id)}
                          //helperText={errors.country && 'Country is required'}
                        >
                          {countryList.map((country) => (
                            <MenuItem key={country.id} value={country.id}>
                              {country.country_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.country_id && (
                      <span style={{ color: "red" }}>
                        {errors.country_id.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>Pincode</InputLabel>
                    <Controller
                      name="pincode_id"
                      control={control}
                      //rules={{ required: "Pin code is required", pattern: { value: /^\d{6}$/, message: "Invalid pin code" } }}
                      render={({ field }) => (
                        <TextField
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          error={Boolean(errors.pincode_id)}
                          // onChange={handlePinCodeChange}
                          value={studentInfo.pincode_id || null}
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              pincode_id: e.target.value,
                            });
                            handlePinCodeChange2(e);
                          }}
                        />
                      )}
                    />
                    {errors.pincode_id && (
                      <span className="error-message" style={{ color: "red" }}>
                        {errors.pincode_id.message}
                      </span>
                    )}
                    {pincodeError && (
                      <span className="error-message" style={{ color: "red" }}>
                        {pincodeError}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>State</InputLabel>
                    <Controller
                      name="state_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          value={selectedState}
                          onChange={handleStateChange}
                          // defaultValue={pinCodeData?.state}
                          error={Boolean(errors.state_id)}
                          //helperText={errors.state}
                        >
                          {states.map((state) => (
                            <MenuItem key={state.id} value={state.id}>
                              {state.state_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>City</InputLabel>
                    <Controller
                      name="city_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          value={selectedCity}
                          onChange={handleCityChange}
                          //defaultValue="IN"
                          error={Boolean(errors.city_id)}
                          // helperText={errors.city }
                        >
                          {cities.map((city) => (
                            <MenuItem key={city.id} value={city.id}>
                              {city.city_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Nearest Branch
                    </InputLabel>
                    <Controller
                      name="nearest_branch_id"
                      control={control}
                      //rules={{ required: "Branch name is required" }}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          value={nearest_branch}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          error={Boolean(errors.nearest_branch_id)}
                          onChange={(e) => {
                            setNearestBranch(e.target.value);
                            setStudentInfo({
                              ...studentInfo,
                              nearest_branch_id: e.target.value,
                            });
                            handleFieldChange(
                              "nearest_branch_id",
                              e.target.value
                            );
                          }}
                          //onChange={(event) => setNearestBranch(event.target.value)}
                          // helperText={errors.nearest_branch && 'Branch Name is required'}
                        >
                          {branches.map((branch) => (
                            <MenuItem key={branch.id} value={branch.id}>
                              {branch.branch_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.nearest_branch_id && (
                      <span style={{ color: "red" }}>
                        {errors.nearest_branch_id.message}
                      </span>
                    )}
                  </Grid>
                </Grid>
              </Item>

              {/* Mandatory Information */}

              <Item>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Typography id="mandatory_info" variant="h4" fontWeight={700}>
                    Mandatory Information
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSaveStudentInfo}
                    disabled={isReadOnly}
                  >
                    Save
                  </Button>
                </Box>
                <Grid container spacing={4}>
                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      Service Name
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="service_id"
                      control={control}
                      rules={{ required: "Service name is required" }}
                      defaultValue="test preparation"
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                            // disabled: true
                          }}
                          fullWidth
                          value={selectedService}
                          onChange={handleServiceChange}
                          // defaultValue='test prep'
                          error={Boolean(errors.service_id)}
                          //helperText={errors.service_name}
                        >
                          {services.map((service) => (
                            <MenuItem key={service.id} value={service.id}>
                              {service.service_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.service_id && (
                      <span style={{ color: "red" }}>
                        {errors.service_id.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      Sub Service
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="sub_service_id"
                      control={control}
                      //rules={{ required: "Sub Service is required" }}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          value={
                            studentInfo.sub_service_id?.id ||
                            studentInfo.sub_service_id ||
                            null
                          }
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              sub_service_id: e.target.value,
                            });
                            handleFieldChange("sub_service_id", e.target.value);
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          // defaultValue='test prep'
                          error={Boolean(errors.sub_service_id)}
                          //helperText={errors.service_name}
                        >
                          {subServices.map((service) => (
                            <MenuItem key={service.id} value={service.id}>
                              {service.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.service_name && (
                      <span style={{ color: "red" }}>
                        {errors.service_name.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      Purpose Of Test
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="purpose_of_test_id"
                      control={control}
                      //rules={{ required: "Purpose of Test is required" }}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          value={
                            studentInfo.purpose_of_test_id?.id ||
                            studentInfo.purpose_of_test_id ||
                            null
                          }
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              purpose_of_test_id: e.target.value,
                            });
                            handleFieldChange(
                              "purpose_of_test_id",
                              e.target.value
                            );
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          // defaultValue='test prep'
                          error={Boolean(errors.purpose_of_test_id)}
                          //helperText={errors.service_name}
                        >
                          {purposeOfTest.map((purpose) => (
                            <MenuItem key={purpose.id} value={purpose.id}>
                              {purpose.purpose_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.purpose_of_test_id && (
                      <span style={{ color: "red" }}>
                        {errors.purpose_of_test_id.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      Lead Class Experience
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="lead_test_experience_id"
                      control={control}
                      //rules={{ required: "Field is required" }}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          value={studentInfo.lead_test_experience_id || null}
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              lead_test_experience_id: e.target.value,
                            });
                            handleFieldChange(
                              "lead_test_experience_id",
                              e.target.value
                            );
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          error={Boolean(errors.lead_class_experience_id)}
                          //helperText={errors.exam_status}
                          // onChange={(e) => handleExamStatusChange(e.target.value)}
                        >
                          {leadTestExperience.map((experience) => (
                            <MenuItem key={experience.id} value={experience.id}>
                              {experience.test_experience}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.lead_class_experience_id && (
                      <span style={{ color: "red" }}>
                        {errors.lead_class_experience_id.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      Exam Type Class
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="test_type_id"
                      control={control}
                      rules={{ required: "Exam Type is required" }}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          value={
                            studentInfo.test_type_id?.id ||
                            studentInfo.test_type_id ||
                            null
                          }
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              test_type_id: e.target.value,
                            });
                            handleFieldChange("test_type_id", e.target.value);
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          error={Boolean(errors.test_type_id)}
                          //helperText={errors.test_type}
                        >
                          {testtypes.map((testtype) => (
                            <MenuItem key={testtype.id} value={testtype.id}>
                              {testtype.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.test_type_id && (
                      <span style={{ color: "red" }}>
                        {errors.test_type_id.message}
                      </span>
                    )}
                  </Grid>

                  {/* Ielts Specification */}
                  {selectedTestTypeId?.name === "IELTS" && (
                    <>
                      <Grid item xs={6} md={4} lg={3}>
                        <InputLabel
                          style={{ color: "black", position: "relative" }}
                        >
                          Sub Test Type
                          <span
                            style={{
                              color: "red",
                              position: "absolute",
                              marginLeft: "4px",
                              fontSize: "1.5em",
                            }}
                          >
                            *
                          </span>
                        </InputLabel>
                        <Controller
                          name="sub_test_type_id"
                          control={control}
                          rules={{ required: "Sub Exam Type is required" }}
                          render={({ field }) => (
                            <TextField
                              select
                              {...field}
                              value={
                                studentInfo.sub_test_type_id?.id ||
                                studentInfo.sub_test_type_id ||
                                null
                              }
                              onChange={(e) => {
                                setStudentInfo({
                                  ...studentInfo,
                                  sub_test_type_id: e.target.value,
                                });
                                handleFieldChange(
                                  "sub_test_type_id",
                                  e.target.value
                                );
                              }}
                              InputProps={{
                                style: { borderRadius: "12px" },
                                readOnly: isReadOnly,
                              }}
                              fullWidth
                              error={Boolean(errors.sub_test_type_id)}
                              //helperText={errors.test_type}
                            >
                              {subTestType.map((testtype) => (
                                <MenuItem key={testtype.id} value={testtype.id}>
                                  {testtype.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                        {errors.sub_test_type_id && (
                          <span style={{ color: "red" }}>
                            {errors.sub_test_type_id.message}
                          </span>
                        )}
                      </Grid>

                      <Grid item xs={6} md={4} lg={3}>
                        <InputLabel
                          style={{ color: "black", position: "relative" }}
                        >
                          Test Delivery Format
                          <span
                            style={{
                              color: "red",
                              position: "absolute",
                              marginLeft: "4px",
                              fontSize: "1.5em",
                            }}
                          >
                            *
                          </span>
                        </InputLabel>
                        <Controller
                          name="test_delivery_format_id"
                          control={control}
                          rules={{
                            required: "Test Delivery Format is required",
                          }}
                          render={({ field }) => (
                            <TextField
                              select
                              {...field}
                              value={
                                studentInfo.test_delivery_format_id?.id ||
                                studentInfo.test_delivery_format_id ||
                                null
                              }
                              onChange={(e) => {
                                setStudentInfo({
                                  ...studentInfo,
                                  test_delivery_format_id: e.target.value,
                                });
                                handleFieldChange(
                                  "test_delivery_format_id",
                                  e.target.value
                                );
                              }}
                              InputProps={{
                                style: { borderRadius: "12px" },
                                readOnly: isReadOnly,
                              }}
                              fullWidth
                              error={Boolean(errors.test_delivery_format_id)}
                              //helperText={errors.test_type}
                            >
                              {subTestTypeFormat.map((testtype) => (
                                <MenuItem key={testtype.id} value={testtype.id}>
                                  {testtype.name}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                        {errors.test_delivery_format_id && (
                          <span style={{ color: "red" }}>
                            {errors.test_delivery_format_id.message}
                          </span>
                        )}
                      </Grid>
                    </>
                  )}

                  {selectedTestTypeId?.name === "PTE" && (
                    <Grid item xs={6} md={4} lg={3}>
                      <InputLabel
                        style={{ color: "black", position: "relative" }}
                      >
                        Sub Test Type
                        <span
                          style={{
                            color: "red",
                            position: "absolute",
                            marginLeft: "4px",
                            fontSize: "1.5em",
                          }}
                        >
                          *
                        </span>
                      </InputLabel>
                      <Controller
                        name="sub_test_type_id"
                        control={control}
                        rules={{ required: "Sub Exam Type is required" }}
                        render={({ field }) => (
                          <TextField
                            select
                            {...field}
                            value={
                              studentInfo.sub_test_type_id?.id ||
                              studentInfo.sub_test_type_id ||
                              null
                            }
                            onChange={(e) => {
                              setStudentInfo({
                                ...studentInfo,
                                sub_test_type_id: e.target.value,
                              });
                              handleFieldChange(
                                "sub_test_type_id",
                                e.target.value
                              );
                            }}
                            InputProps={{
                              style: { borderRadius: "12px" },
                              readOnly: isReadOnly,
                            }}
                            fullWidth
                            error={Boolean(errors.sub_test_type_id)}
                            //helperText={errors.test_type}
                          >
                            {subTestType.map((testtype) => (
                              <MenuItem key={testtype.id} value={testtype.id}>
                                {testtype.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {errors.sub_test_type_id && (
                        <span style={{ color: "red" }}>
                          {errors.sub_test_type_id.message}
                        </span>
                      )}
                    </Grid>
                  )}
                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      Preferred Delivery Mode
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="preferred_delivery_mode"
                      control={control}
                      rules={{ required: "Field is required" }}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          value={studentInfo.preferred_delivery_mode || null}
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              preferred_delivery_mode: e.target.value,
                            });
                            handleFieldChange(
                              "preferred_delivery_mode",
                              e.target.value
                            );
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          error={Boolean(errors.preferred_delivery_mode)}
                          //helperText={errors.exam_status}
                          // onChange={(e) => handleExamStatusChange(e.target.value)}
                        >
                          <MenuItem value="online">Online</MenuItem>
                          <MenuItem value="offline">Offline</MenuItem>
                        </TextField>
                      )}
                    />
                    {errors.preferred_delivery_mode && (
                      <span style={{ color: "red" }}>
                        {errors.preferred_delivery_mode.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      First Country of Interest
                    </InputLabel>
                    <Controller
                      name="target_country_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          value={studentInfo.target_country_id || null}
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              target_country_id: e.target.value,
                            });
                            handleFieldChange(
                              "target_country_id",
                              e.target.value
                            );
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          error={Boolean(errors.target_country)}
                          //helperText={errors.target_country}
                        >
                          {targetCountries.map((country) => (
                            <MenuItem key={country.id} value={country.id}>
                              {country.country_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Lead Source Type
                    </InputLabel>
                    <Controller
                      name="lead_source_type_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          error={Boolean(errors.lead_source_type_id)}
                          /*onChange={e => {
                                            field.onChange(e);
                                            handleLeadSourceTypeChange(e.target.value);
                                          }}*/
                          value={selectedSourceType}
                          onChange={handleSourceTypeChange}
                          // helperText={errors.lead_source_type}
                        >
                          {leadSources.map((type) => (
                            <MenuItem key={type.id} value={type.id}>
                              {type.name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Sub Lead Source Type
                    </InputLabel>
                    <Controller
                      name="sub_lead_source_type_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          value={
                            studentInfo.sub_lead_source_type_id?.id ||
                            studentInfo.sub_lead_source_type_id ||
                            null
                          }
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              sub_lead_source_type_id: e.target.value,
                            });
                            handleFieldChange(
                              "sub_lead_source_type_id",
                              e.target.value
                            );
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          error={Boolean(errors.sub_lead_source_type_id)}
                        >
                          {subLeadSources.map((subSource) => (
                            <MenuItem key={subSource.id} value={subSource.id}>
                              {subSource.source_type}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>

                  {/* Reference By */}

                  {(studentInfo.sub_lead_source_type_id?.id === 53 ||
                    studentInfo.sub_lead_source_type_id === 53) && (
                    <Grid item xs={6} md={4} lg={3}>
                      <InputLabel style={{ color: "black" }}>
                        Reference By
                      </InputLabel>
                      <Controller
                        name="reference_walkin_id"
                        control={control}
                        render={({ field }) => (
                          <TextField
                            select
                            {...field}
                            value={
                              studentInfo.reference_walkin_id?.id ||
                              studentInfo.reference_walkin_id ||
                              null
                            }
                            onChange={(e) => {
                              setStudentInfo({
                                ...studentInfo,
                                reference_walkin_id: e.target.value,
                              });
                              handleFieldChange(
                                "reference_walkin_id",
                                e.target.value
                              );
                            }}
                            InputProps={{
                              style: { borderRadius: "12px" },
                              readOnly: true,
                            }}
                            fullWidth
                            error={Boolean(errors.reference_walkin_id)}
                          >
                            {referenceByList.map((reference) => (
                              <MenuItem key={reference.id} value={reference.id}>
                                {reference.name}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>
                  )}
                  <Grid item xs={6} md={4} lg={3}>
                    <Button
                      variant="contained"
                      style={{ borderRadius: "25px" }}
                      onClick={() => handleGrandlynkPopupOpen()}
                      disabled={isReadOnly}
                    >
                      Add Gradlynk Service{" "}
                    </Button>
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <Button
                      variant="contained"
                      color="error"
                      style={{ borderRadius: "25px" }}
                      onClick={handleGradlynkServiceClick}
                    >
                      Show Gradlynk Service History{" "}
                    </Button>
                  </Grid>
                </Grid>
              </Item>

              {showGradlynkService && (
                <Box mb={2}>
                  <Item>
                    <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                      <GradlynkServicesTable enquiryId={enquiryId} />
                    </div>
                  </Item>
                </Box>
              )}

              <Item>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Typography id="test-info" variant="h4" fontWeight={700}>
                    Test Details Information
                  </Typography>
                  <Button
                    variant="contained"
                    color="secondary"
                    onClick={handleSaveStudentInfo}
                    disabled={isReadOnly}
                  >
                    Save
                  </Button>
                </Box>
                <Grid item xs={12} md={12} lg={12}>
                  <Box display="flex">
                    <Stack direction="row" spacing={2}>
                      <Typography variant="h5" sx={{ color: "red" }}>
                        Send English Proficiency Level Test Link to Student's
                        Email and WhatsApp?
                      </Typography>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={handleSendEvaluationLink}
                        disabled={isReadOnly}
                      >
                        Send
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        onClick={copyLinkToClipboard}
                        disabled={isReadOnly}
                      >
                        Copy Link
                      </Button>
                    </Stack>
                  </Box>
                </Grid>
                <Grid container spacing={4}>
                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      Exam Status
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="exam_status_id"
                      control={control}
                      rules={{ required: "Field is required" }}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          value={studentInfo.exam_status_id || null}
                          onChange={(e) => {
                            handleExamStatusChange(e.target.value);
                            setStudentInfo({
                              ...studentInfo,
                              exam_status_id: e.target.value,
                            });
                            handleFieldChange("exam_status_id", e.target.value);
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          error={Boolean(errors.exam_status_id)}
                          //helperText={errors.exam_status}
                          // onChange={(e) => handleExamStatusChange(e.target.value)}
                        >
                          {examStatuses.map((status) => (
                            <MenuItem key={status.id} value={status.id}>
                              {status.exam_status_name}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.exam_status_id && (
                      <span style={{ color: "red" }}>
                        {errors.exam_status_id.message}
                      </span>
                    )}
                  </Grid>

                  {examStatusId === 1 && (
                    <>
                      <Grid item xs={6} md={4} lg={3}>
                        <InputLabel style={{ color: "black" }}>
                          Date of Exam
                        </InputLabel>
                        <Controller
                          name="tentative_test_date"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              type="date"
                              value={studentInfo.tentative_test_date || null}
                              onChange={(e) => {
                                setStudentInfo({
                                  ...studentInfo,
                                  tentative_test_date: e.target.value,
                                });
                                handleFieldChange(
                                  "tentative_test_date",
                                  e.target.value
                                );
                              }}
                              InputProps={{
                                style: { borderRadius: "12px" },
                                readOnly: isReadOnly,
                                inputProps: {
                                  min: new Date().toISOString().split("T")[0],
                                },
                              }}
                              error={Boolean(errors.date_of_exam)}
                              //helperText={errors.date_of_exam && 'Date of Exam is required'}
                            />
                          )}
                        />
                        {errors.date_of_exam && (
                          <span
                            style={{
                              color: "red",
                              position: "absolute",
                              top: 0,
                              right: -8,
                            }}
                          >
                            *
                          </span>
                        )}
                      </Grid>

                      <Grid item xs={6} md={4} lg={3}>
                        <InputLabel style={{ color: "black" }}>
                          Assumptive Proficiency Level
                        </InputLabel>
                        <Controller
                          name="assumptive_proficiency_level_id"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              select
                              {...field}
                              value={
                                studentInfo.assumptive_proficiency_level_id ||
                                null
                              }
                              onChange={(e) => {
                                setStudentInfo({
                                  ...studentInfo,
                                  assumptive_proficiency_level_id:
                                    e.target.value,
                                });
                                handleFieldChange(
                                  "assumptive_proficiency_level_id",
                                  e.target.value
                                );
                              }}
                              InputProps={{
                                style: { borderRadius: "12px" },
                                readOnly: isReadOnly,
                              }}
                              fullWidth
                              error={Boolean(errors.proficiency_level)}
                              //helperText={errors.proficiency_level}
                            >
                              {proficiencyLevel.map((status) => (
                                <MenuItem key={status.id} value={status.id}>
                                  {status.english_proficiency}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>

                      <Grid item xs={6} md={4} lg={3}>
                        <InputLabel style={{ color: "black" }}>
                          Target Test Score
                        </InputLabel>
                        <Controller
                          name="target_score"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              type="number"
                              value={studentInfo.target_score || null}
                              onChange={(e) => {
                                setStudentInfo({
                                  ...studentInfo,
                                  target_score: e.target.value,
                                });
                                handleFieldChange(
                                  "target_score",
                                  e.target.value
                                );
                              }}
                              InputProps={{
                                style: { borderRadius: "12px" },
                                readOnly: isReadOnly,
                                inputProps: {
                                  min: 1,
                                  max: 9,
                                },
                              }}
                              error={Boolean(errors.target_score)}
                              //helperText={errors.target_score }
                            />
                          )}
                        />
                        {errors.target_score && (
                          <span
                            style={{
                              color: "red",
                              position: "absolute",
                              top: 0,
                              right: -8,
                            }}
                          >
                            *
                          </span>
                        )}
                      </Grid>
                    </>
                  )}

                  {examStatusId === 2 && (
                    <>
                      <Grid item xs={6} md={4} lg={3}>
                        <InputLabel style={{ color: "black" }}>
                          Do you want to book the test?
                        </InputLabel>
                        <Controller
                          name="test_taken"
                          control={control}
                          rules={{ required: "Field is required" }}
                          render={({ field }) => (
                            <TextField
                              select
                              {...field}
                              InputProps={{
                                style: { borderRadius: "12px" },
                                readOnly: isReadOnly,
                              }}
                              fullWidth
                              error={Boolean(errors.test_taken)}
                              value={studentInfo.test_taken || null}
                              onChange={(e) => {
                                handleTestBookingStatusChange(e.target.value);
                                handleFieldChange("test_taken", e.target.value);
                              }}
                              //helperText={errors.test_booking}
                              // onChange={(e) => handleTestBookingStatusChange(e.target.value)}
                            >
                              <MenuItem value="yes">Yes</MenuItem>
                              <MenuItem value="no">No</MenuItem>
                            </TextField>
                          )}
                        />
                      </Grid>

                      {testBooking === "yes" && (
                        <>
                          <Grid item xs={6} md={4} lg={3}>
                            <InputLabel style={{ color: "black" }}>
                              Tentative Date of Exam
                            </InputLabel>
                            <Controller
                              name="tentative_test_date"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  type="date"
                                  value={
                                    studentInfo.tentative_test_date || null
                                  }
                                  onChange={(e) => {
                                    setStudentInfo({
                                      ...studentInfo,
                                      tentative_test_date: e.target.value,
                                    });
                                    handleFieldChange(
                                      "tentative_test_date",
                                      e.target.value
                                    );
                                  }}
                                  InputProps={{
                                    style: { borderRadius: "12px" },
                                    readOnly: isReadOnly,
                                    inputProps: {
                                      min: new Date()
                                        .toISOString()
                                        .split("T")[0],
                                    },
                                  }}
                                  error={Boolean(errors.test_date)}
                                  //helperText={errors.date_of_exam && 'Date of Exam is required'}
                                />
                              )}
                            />
                            {errors.date_of_exam && (
                              <span
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  top: 0,
                                  right: -8,
                                }}
                              >
                                *
                              </span>
                            )}
                          </Grid>

                          <Grid item xs={6} md={4} lg={3}>
                            <InputLabel style={{ color: "black" }}>
                              Assumptive Proficiency Level
                            </InputLabel>
                            <Controller
                              name="assumptive_proficiency_level_id"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  select
                                  {...field}
                                  value={
                                    studentInfo.assumptive_proficiency_level_id ||
                                    null
                                  }
                                  onChange={(e) => {
                                    setStudentInfo({
                                      ...studentInfo,
                                      assumptive_proficiency_level_id:
                                        e.target.value,
                                    });
                                    handleFieldChange(
                                      "assumptive_proficiency_level_id",
                                      e.target.value
                                    );
                                  }}
                                  InputProps={{
                                    style: { borderRadius: "12px" },
                                    readOnly: isReadOnly,
                                  }}
                                  fullWidth
                                  error={Boolean(
                                    errors.english_proficiency_level
                                  )}
                                  // helperText={errors.proficiency_level}
                                >
                                  {proficiencyLevel.map((status) => (
                                    <MenuItem key={status.id} value={status.id}>
                                      {status.english_proficiency}
                                    </MenuItem>
                                  ))}
                                </TextField>
                              )}
                            />
                          </Grid>

                          <Grid item xs={6} md={4} lg={3}>
                            <InputLabel style={{ color: "black" }}>
                              Target Test Score
                            </InputLabel>
                            <Controller
                              name="target_score"
                              control={control}
                              render={({ field }) => (
                                <TextField
                                  {...field}
                                  fullWidth
                                  type="number"
                                  value={studentInfo.target_score || null}
                                  onChange={(e) => {
                                    setStudentInfo({
                                      ...studentInfo,
                                      target_score: e.target.value,
                                    });
                                    handleFieldChange(
                                      "target_score",
                                      e.target.value
                                    );
                                  }}
                                  InputProps={{
                                    style: { borderRadius: "12px" },
                                    readOnly: isReadOnly,
                                  }}
                                  error={Boolean(errors.target_score)}
                                  //helperText={errors.target_score }
                                />
                              )}
                            />
                            {errors.target_score && (
                              <span
                                style={{
                                  color: "red",
                                  position: "absolute",
                                  top: 0,
                                  right: -8,
                                }}
                              >
                                *
                              </span>
                            )}
                          </Grid>
                        </>
                      )}

                      {testBooking === "no" && (
                        <Grid item xs={6} md={4} lg={3}>
                          <InputLabel style={{ color: "black" }}>
                            Assumptive Proficiency Level
                          </InputLabel>
                          <Controller
                            name="assumptive_proficiency_level_id"
                            control={control}
                            render={({ field }) => (
                              <TextField
                                select
                                {...field}
                                value={
                                  studentInfo.assumptive_proficiency_level_id ||
                                  null
                                }
                                onChange={(e) => {
                                  setStudentInfo({
                                    ...studentInfo,
                                    assumptive_proficiency_level_id:
                                      e.target.value,
                                  });
                                  handleFieldChange(
                                    "assumptive_proficiency_level_id",
                                    e.target.value
                                  );
                                }}
                                InputProps={{
                                  style: { borderRadius: "12px" },
                                  readOnly: isReadOnly,
                                }}
                                fullWidth
                                error={Boolean(
                                  errors.english_proficiency_level
                                )}
                                // helperText={errors.proficiency_level}
                              >
                                {proficiencyLevel.map((status) => (
                                  <MenuItem key={status.id} value={status.id}>
                                    {status.english_proficiency}
                                  </MenuItem>
                                ))}
                              </TextField>
                            )}
                          />
                        </Grid>
                      )}
                    </>
                  )}

                  {examStatusId === 3 && (
                    <>
                      <Grid item xs={6} md={4} lg={3}>
                        <InputLabel style={{ color: "black" }}>
                          Date of Exam
                        </InputLabel>
                        <Controller
                          name="tentative_test_date"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              type="date"
                              value={studentInfo.tentative_test_date || null}
                              onChange={(e) => {
                                setStudentInfo({
                                  ...studentInfo,
                                  tentative_test_date: e.target.value,
                                });
                                handleFieldChange(
                                  "tentative_test_date",
                                  e.target.value
                                );
                              }}
                              InputProps={{
                                style: { borderRadius: "12px" },
                                readOnly: isReadOnly,
                                inputProps: {
                                  min: new Date().toISOString().split("T")[0],
                                },
                              }}
                              error={Boolean(errors.test_date)}
                              // helperText={errors.date_of_exam && 'Date of Exam is required'}
                            />
                          )}
                        />
                        {errors.date_of_exam && (
                          <span
                            style={{
                              color: "red",
                              position: "absolute",
                              top: 0,
                              right: -8,
                            }}
                          >
                            *
                          </span>
                        )}
                      </Grid>

                      <Grid item xs={6} md={4} lg={3}>
                        <InputLabel style={{ color: "black" }}>
                          Assumptive Proficiency Level
                        </InputLabel>
                        <Controller
                          name="assumptive_proficiency_level_id"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              select
                              {...field}
                              value={
                                studentInfo.assumptive_proficiency_level_id ||
                                null
                              }
                              onChange={(e) => {
                                setStudentInfo({
                                  ...studentInfo,
                                  assumptive_proficiency_level_id:
                                    e.target.value,
                                });
                                handleFieldChange(
                                  "assumptive_proficiency_level_id",
                                  e.target.value
                                );
                              }}
                              InputProps={{
                                style: { borderRadius: "12px" },
                                readOnly: isReadOnly,
                              }}
                              fullWidth
                              error={Boolean(errors.proficiency_level)}
                              // helperText={errors.proficiency_level}
                            >
                              {proficiencyLevel.map((status) => (
                                <MenuItem key={status.id} value={status.id}>
                                  {status.english_proficiency}
                                </MenuItem>
                              ))}
                            </TextField>
                          )}
                        />
                      </Grid>

                      <Grid item xs={6} md={4} lg={3}>
                        <InputLabel style={{ color: "black" }}>
                          Test Score
                        </InputLabel>
                        <Controller
                          name="target_score"
                          control={control}
                          render={({ field }) => (
                            <TextField
                              {...field}
                              fullWidth
                              type="number"
                              value={studentInfo.target_score || null}
                              onChange={(e) => {
                                setStudentInfo({
                                  ...studentInfo,
                                  target_score: e.target.value,
                                });
                                handleFieldChange(
                                  "target_score",
                                  e.target.value
                                );
                              }}
                              InputProps={{
                                style: { borderRadius: "12px" },
                                readOnly: isReadOnly,
                              }}
                              error={Boolean(errors.target_score)}
                              //  helperText={errors.test_score }
                            />
                          )}
                        />
                        {errors.test_score && (
                          <span
                            style={{
                              color: "red",
                              position: "absolute",
                              top: 0,
                              right: -8,
                            }}
                          >
                            *
                          </span>
                        )}
                      </Grid>
                    </>
                  )}

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Ready to Enroll?
                    </InputLabel>
                    <Controller
                      name="ready_to_enroll"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          value={studentInfo.ready_to_enroll || null}
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              ready_to_enroll: e.target.value,
                            });
                            handleFieldChange(
                              "ready_to_enroll",
                              e.target.value
                            );
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          error={Boolean(errors.ready_to_enroll)}
                          // helperText={errors.ready_to_enroll}
                        >
                          <MenuItem value={true}>Yes</MenuItem>
                          <MenuItem value={false}>No</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Level of Interest
                    </InputLabel>
                    <Controller
                      name="level_of_interest_id"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          value={studentInfo.level_of_interest_id || null}
                          onChange={(e) => {
                            setStudentInfo({
                              ...studentInfo,
                              level_of_interest_id: e.target.value,
                            });
                            handleFieldChange(
                              "level_of_interest_id",
                              e.target.value
                            );
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          error={Boolean(errors.level_of_interest_id)}
                          // helperText={errors.proficiency_level_of_interest}
                        >
                          {levelOfInterest.map((status) => (
                            <MenuItem key={status.id} value={status.id}>
                              {status.level_of_interest}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                  </Grid>

                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      Prospective Level
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name="prospective_level_id"
                      control={control}
                      rules={{ required: "Field is required" }}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          value={
                            studentInfo.prospective_level_id?.id ||
                            studentInfo.prospective_level_id ||
                            null
                          }
                          onChange={(e) => {
                            handleProspectiveLevelChange(e.target.value);
                            setStudentInfo({
                              ...studentInfo,
                              prospective_level_id: e.target.value,
                            });
                            handleFieldChange(
                              "prospective_level_id",
                              e.target.value
                            );
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          error={Boolean(errors.prospective_level_id)}
                          // helperText={errors.prospective_level}
                        >
                          {prospectiveLevel.map((status) => (
                            <MenuItem key={status.id} value={status.id}>
                              {status.prospective_level}
                            </MenuItem>
                          ))}
                        </TextField>
                      )}
                    />
                    {errors.prospective_level_id && (
                      <span style={{ color: "red" }}>
                        {errors.prospective_level_id.message}
                      </span>
                    )}
                  </Grid>

                  {prospectiveLevelId === 5 && (
                    <Grid item xs={6} md={4} lg={3}>
                      <InputLabel
                        style={{ color: "black", position: "relative" }}
                      >
                        Recycle Reason
                        <span
                          style={{
                            color: "red",
                            position: "absolute",
                            marginLeft: "4px",
                            fontSize: "1.5em",
                          }}
                        >
                          *
                        </span>
                      </InputLabel>
                      <Controller
                        name="recycle_reason_id"
                        control={control}
                        rules={{ required: "Field is required" }}
                        render={({ field }) => (
                          <TextField
                            select
                            {...field}
                            value={studentInfo.recycle_reason_id || null}
                            onChange={(e) => {
                              handleRecycleReasonChange(e.target.value);
                              setStudentInfo({
                                ...studentInfo,
                                recycle_reason_id: e.target.value,
                              });
                              handleFieldChange(
                                "recycle_reason_id",
                                e.target.value
                              );
                            }}
                            InputProps={{
                              style: { borderRadius: "12px" },
                              readOnly: isReadOnly,
                            }}
                            fullWidth
                            error={Boolean(errors.recycle_reason_id)}
                            // helperText={errors.prospective_level}
                          >
                            {recycledReasons.map((status) => (
                              <MenuItem key={status.id} value={status.id}>
                                {status.recycle_reason}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                      {errors.recycle_reason_id && (
                        <span style={{ color: "red" }}>
                          {errors.recycle_reason_id.message}
                        </span>
                      )}
                    </Grid>
                  )}
                </Grid>
              </Item>

              <Item>
                <Box display="flex" justifyContent="space-between" mb={2}>
                  <Typography id="assign" variant="h4" fontWeight={700}>
                    Assign
                  </Typography>
                </Box>
                <Grid container spacing={2}>
                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel style={{ color: "black" }}>
                      Type Of Assignment
                    </InputLabel>
                    <Controller
                      name="assignment_type"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          select
                          {...field}
                          value={assignmentType}
                          onChange={(e) => {
                            setAssignmentType(e.target.value);
                            // handleFieldChange('ready_to_enroll', e.target.value)
                          }}
                          InputProps={{
                            style: { borderRadius: "12px" },
                            readOnly: isReadOnly,
                          }}
                          fullWidth
                          error={Boolean(errors.assignment_type)}
                          // helperText={errors.ready_to_enroll}
                        >
                          <MenuItem value="permanent_assign">
                            Permanent Assignment
                          </MenuItem>
                          <MenuItem value="co_assignee">Co Assignment</MenuItem>
                        </TextField>
                      )}
                    />
                  </Grid>
                  <Grid item xs={6} md={4} lg={3}>
                    <InputLabel
                      style={{ color: "black", position: "relative" }}
                    >
                      Employee
                      <span
                        style={{
                          color: "red",
                          position: "absolute",
                          marginLeft: "4px",
                          fontSize: "1.5em",
                        }}
                      >
                        *
                      </span>
                    </InputLabel>
                    <Controller
                      name={
                        assignmentType === "co_assignee"
                          ? "co_assignee"
                          : "assign_to"
                      }
                      control={control}
                      rules={{ required: "Field is required" }}
                      render={({ field }) => (
                        // <TextField
                        //   select
                        //   {...field}
                        //   value={assignTo}
                        //   onChange={(e) => {
                        //     setAssignTo(e.target.value);
                        //     handleFieldChange(
                        //       assignmentType === "co_assignee"
                        //         ? "co_assignee"
                        //         : "assign_to",
                        //       e.target.value
                        //     );
                        //   }}
                        //   InputProps={{
                        //     style: { borderRadius: "12px" },
                        //     readOnly: isReadOnly,
                        //   }}
                        //   fullWidth
                        //   error={Boolean(errors.assign_to)}
                        //   //helperText={errors.assign_to}
                        //   //value={ advisorId || ''}
                        // >
                        //   {taskOwners.map((item) => (
                        //     <MenuItem key={item.id} value={item.id}>
                        //       {item.first_name} {item.last_name}
                        //     </MenuItem>
                        //   ))}
                        // </TextField>

                        <Autocomplete
                          disableClearable
                          PopperComponent={StyledPopper}
                          disablePortal
                          value={assignTo}
                          options={taskOwners}
                          onChange={(event, value) => {
                            const id = value?.id;
                            setAssignTo(value);
                            handleFieldChange(
                              assignmentType === "co_assignee"
                                ? "co_assignee"
                                : "assign_to",
                              id
                            );
                          }}
                          getOptionLabel={(option) =>
                            `${option.first_name ? option.first_name : ""} ${
                              option.last_name ? option.last_name : ""
                            }`
                          }
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="outlined"
                              InputProps={{
                                ...params.InputProps,
                                style: { borderRadius: "12px" },
                              }}
                            />
                          )}
                          sx={{
                            "& .MuiAutocomplete-listbox": {
                              maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
                              width: 250,
                            },
                          }}
                        />
                      )}
                    />
                    {errors.assign_to && (
                      <span style={{ color: "red" }}>
                        {errors.assign_to.message}
                      </span>
                    )}
                  </Grid>

                  <Grid item xs={6} md={4} lg={6}>
                    <Typography sx={{ visibility: "hidden" }}>Save</Typography>
                    <Button
                      variant="contained"
                      color="secondary"
                      onClick={handleSaveStudentInfo}
                      disabled={isReadOnly}
                    >
                      Save
                    </Button>

                    <Button
                      variant="contained"
                      style={{ marginLeft: "10px" }}
                      color={showAssignHistory ? "error" : "primary"}
                      onClick={handleToggleAssign}
                    >
                      {showAssignHistory
                        ? "Close Assign History"
                        : "View Assign History"}
                    </Button>
                  </Grid>

                  <Grid item xs={12} md={12} lg={12} sm={12}>
                    <Grid container spacing={1}>
                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        sm={6}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{ fontWeight: 600, fontSize: "15px" }}
                          color="primary"
                        >
                          Permanent Assign :
                        </Typography>
                        <Typography
                          style={{ fontWeight: 600, fontSize: "15px" }}
                          ml={1}
                        >
                          {enquiryData?.assign_to?.first_name}{" "}
                          {enquiryData?.assign_to?.last_name}
                        </Typography>
                      </Grid>

                      <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        sm={6}
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          alignItems: "center",
                        }}
                      >
                        <Typography
                          style={{ fontWeight: 600, fontSize: "15px" }}
                          color="primary"
                        >
                          Co - Assign:
                        </Typography>
                        <Typography
                          style={{ fontWeight: 600, fontSize: "15px" }}
                          ml={1}
                        >
                          {enquiryData?.co_assignee?.first_name}{" "}
                          {enquiryData?.co_assignee?.last_name}
                        </Typography>
                      </Grid>
                    </Grid>
                  </Grid>

                  {showAssignHistory && (
                    <Grid item xs={12} md={12} lg={12} sm={12}>
                      <Box
                        sx={{
                          height: "100%",
                          width: "100%",
                          "& .header-cell": {
                            backgroundColor: "#00e676",
                            fontWeight: 600,
                            borderRight: "0.1px solid #C3C3C3",
                          },
                        }}
                      >
                        <DataGrid
                          rows={assignData}
                          columns={columns}
                          autoHeight
                          getRowId={() => Math.floor(Math.random() * 100000000)}
                          slots={{
                            noRowsOverlay: CustomNoRowsOverlay,
                          }}
                          initialState={{
                            pagination: {
                              paginationModel: {
                                pageSize: 25,
                              },
                            },
                          }}
                          sx={{ marginTop: "20px" }}
                          pageSizeOptions={[25, 50, 100]}
                        />
                      </Box>
                    </Grid>
                  )}
                </Grid>
              </Item>

              <Item>
                <Typography id="campaign" variant="h4" fontWeight={700}>
                  Campaign Detail
                </Typography>
                <CampaignDetail enquiryData={enquiryData} />
              </Item>

              <Item>
                <Typography id="event" variant="h4" fontWeight={700}>
                  Event Registration & Walk-ins
                </Typography>
                <EventRegistration enquiryData={enquiryData} />
              </Item>
              <Item>
                <div style={{ maxHeight: "400px", overflowY: "auto" }}>
                  <Typography id="add-task" variant="h4" fontWeight={700}>
                    View Tasks
                  </Typography>
                  <ViewTaskDataGrid
                    isDialogOpen={isDialogOpen}
                    openDialog={openDialog}
                    closeDialog={closeDialog}
                    tasksData={tasksData}
                    studentId={enquiryId}
                    enquiryData={enquiryData}
                  />
                </div>
                <CustomPopup
                  open={isDemoPopupOpen}
                  onClose={handleCloseDemoPopup}
                  title="Book Demo"
                  maxWidth="md"
                  component={<EnquiryBookDemoForm enquiryData={enquiryData} />}
                />
              </Item>

              <Item>
                <Typography id="whatsapp" variant="h4" fontWeight={700}>
                  WhatsApp
                </Typography>
                <WhatsappMessages enquiryData={enquiryData} />
              </Item>

              <Item>
                <Typography id="email" variant="h4" fontWeight={700}>
                  Email
                </Typography>
                <Email enquiryData={enquiryData} />
              </Item>
            </Stack>
          </Box>
        </Grid>

        <Grid
          item
          xs={12}
          md={2.9}
          lg={2.9}
          sm={12}
          style={{
            position: "sticky",
            top: 250,
            height: "65vh",
            overflowY: "auto",
            marginLeft: "5px",
            textAlign: "center",
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              <Box
                sx={{
                  display: { xs: "none", lg: "flex", xl: "flex", md: "flex" },
                  flexWrap: "wrap",
                  p: 1,
                  rowGap: 1,
                  alignContent: "flex-start",
                  columnGap: 1,
                }}
              >
                <Link
                  to="personal-info"
                  smooth={true}
                  duration={500}
                  offset={-headerHeight}
                >
                  <Button
                    style={{
                      background: "white",
                      color: "black",
                      borderRadius: "8px",
                    }}
                  >
                    Personal Info
                  </Button>
                </Link>

                <Link
                  to="contact-info"
                  smooth={true}
                  duration={500}
                  offset={-headerHeight}
                >
                  <Button
                    style={{
                      background: "white",
                      color: "black",
                      borderRadius: "8px",
                    }}
                  >
                    Contact Info
                  </Button>
                </Link>

                <Link
                  to="mandatory_info"
                  smooth={true}
                  duration={500}
                  offset={-headerHeight}
                >
                  <Button
                    style={{
                      background: "white",
                      color: "black",
                      borderRadius: "8px",
                    }}
                  >
                    Mandatory Info
                  </Button>
                </Link>

                <Link
                  to="test-info"
                  smooth={true}
                  duration={500}
                  offset={-headerHeight}
                >
                  <Button
                    style={{
                      background: "white",
                      color: "black",
                      borderRadius: "8px",
                    }}
                  >
                    Test Details
                  </Button>
                </Link>

                <Link
                  to="assign"
                  smooth={true}
                  duration={500}
                  offset={-headerHeight}
                >
                  <Button
                    style={{
                      background: "white",
                      color: "black",
                      borderRadius: "8px",
                    }}
                  >
                    Assign
                  </Button>
                </Link>

                <Link
                  to="campaign"
                  smooth={true}
                  duration={500}
                  offset={-headerHeight}
                >
                  <Button
                    style={{
                      background: "white",
                      color: "black",
                      borderRadius: "8px",
                    }}
                  >
                    Campaign Details
                  </Button>
                </Link>

                <Link
                  to="add-task"
                  smooth={true}
                  duration={500}
                  offset={-headerHeight}
                >
                  <Button
                    style={{
                      background: "white",
                      color: "black",
                      borderRadius: "8px",
                    }}
                  >
                    View Tasks
                  </Button>
                </Link>

                <Link
                  to="event"
                  smooth={true}
                  duration={500}
                  offset={-headerHeight}
                >
                  <Button
                    style={{
                      background: "white",
                      color: "black",
                      borderRadius: "8px",
                    }}
                  >
                    Event Registration & Walk-ins
                  </Button>
                </Link>

                <Link
                  to="whatsapp"
                  smooth={true}
                  duration={500}
                  offset={-headerHeight}
                >
                  <Button
                    style={{
                      background: "white",
                      color: "black",
                      borderRadius: "8px",
                    }}
                  >
                    Whatsapp
                  </Button>
                </Link>

                <Link
                  to="email"
                  smooth={true}
                  duration={500}
                  offset={-headerHeight}
                >
                  <Button
                    style={{
                      background: "white",
                      color: "black",
                      borderRadius: "8px",
                    }}
                  >
                    Email
                  </Button>
                </Link>
              </Box>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Accordion
                expanded={demoHistoryExpanded}
                onChange={handleDemoHistoryAccordionChange}
                style={{
                  border: `2px solid #2196F3`,
                  borderRadius: "12px",
                }}
              >
                <AccordionSummary>
                  <Typography variant="h4" fontWeight={700}>
                    Demo History
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Box mt={2}>
                    <Grid container spacing={6}>
                      <Grid item xs={12} md={12}>
                        <DemoHistoryList
                          studentId={enquiryId}
                          demoHistory={demoHistory}
                          loading={demoLoading}
                        />
                      </Grid>
                    </Grid>
                  </Box>
                </AccordionDetails>
              </Accordion>
            </Grid>

            <Grid item xs={12} md={12} lg={12}>
              <Accordion
                expanded={activityExpanded}
                onChange={handleActivityAccordionChange}
                style={{
                  border: `2px solid #2196F3`,
                  borderRadius: "12px",
                }}
              >
                <AccordionSummary>
                  <Typography variant="h4" fontWeight={700}>
                    Notes
                  </Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Grid container spacing={3}>
                    <Grid item xs={12} md={12}>
                      <ViewNotesDataGrid
                        studentId={enquiryId}
                        userId={userId}
                        enquiryPersonalId={studentInfo?.enq_personal_detail_id}
                        studentInfo={studentInfo}
                      />
                    </Grid>
                  </Grid>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </Grid>

        <CustomPopup
          open={isRescheduleDemoPopupOpen}
          onClose={handleCloseRescheduleDemoPopup}
          title="Reschedule Demo"
          maxWidth="md"
          component={
            <EnquiryRescheduleDemoForm
              enquiryData={enquiryData}
              demoHistory={demoHistory}
            />
          }
        />

        <CreatePopup
          open={isDropOutRequest}
          onClose={handleCloseDropOutRequest}
          title="Drop out request"
          component={<DropOutRequest enquiryData={enquiryData} />}
        />

        <CustomSnackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </Grid>

      <CustomPopup
        open={gradlynkpopup}
        onClose={handleGrandlynkPopupclose}
        component={
          <GradlynkForm
            enquiryData={enquiryData}
            onClose={handleGrandlynkPopupclose}
          />
        }
        title="Add Gradlynk Service "
        maxWidth="sm"
        showDivider={true}
      />
    </div>
  );
}

export default ViewEnquiry;
