import { Box, FormControl, Stack, TextField, Typography } from '@mui/material'
import React, { useState } from 'react'
import { Button } from 'react-scroll'

export default function Categoryform({addCategory, onCloseTab, onClose}) {

  const [categoryName, setCategoryName] = useState(null);

  const handleAddCategory = () => {
    if(!categoryName) {
      alert ('Please add Category name') 
      return
    }

    addCategory(categoryName)
    setCategoryName('')
    onCloseTab()
    onClose()
  }

  return (
    <>
      <FormControl>
        <Typography>Add Category</Typography>
        <TextField 
          id="outlined-basic" 
          variant="outlined"
          type="text"
          value={categoryName}
          onChange={(e) => setCategoryName(e.target.value)}
           />
        <Box sx={{textAlign:'center'}}>
        <Button 
          onClick={handleAddCategory}
          style={{ width: '100px', height: '30px', borderRadius: '7px', background: '#025175', color: '#FFF', fontFamily: "Poppins", fontSize: "18px", fontStyle: 'normal', fontWeight: 400,marginTop:'7px' }}>Save</Button>
        </Box>
      </FormControl>
    </>
  )
}
