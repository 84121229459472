import React, { useState, useEffect } from "react";
import {
  Grid,
  Typography,
  TextField,
  MenuItem,
  InputLabel,
  Chip,
  Button,
  Box,
} from "@mui/material";
import dayjs from "dayjs";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { fDate } from "../../../utils/formatTime";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function EmployeeViewForm({ data, onClose }) {
  console.log(data);
  const [leaveRequest, setLeaveRequest] = useState(data);
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [typeOfLeave, setTypeOfLeave] = useState(data?.leave_type_id?.id);
  const [typeOfLeaveDay, setTypeOfLeaveDay] = useState(data?.leave_day_type);
  const [leaveDateFrom, setLeaveDateFrom] = useState(data?.from_date);
  const [leaveTimeFrom, setLeaveTimeFrom] = useState(data?.from_time);
  const [leaveTimeTo, setLeaveTimeTo] = useState(data?.to_time);
  const [leaveDateTo, setLeaveDateTo] = useState(data?.to_date);
  const [numberOfDays, setNumberOfDays] = useState(0);
  const [additionalNote, setAdditionalNote] = useState(data?.additional_note);
  const [errors, setErrors] = useState({});
  const [leaveTypes, setLeaveTypes] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleTypeOfLeaveChange = (event) => {
    setTypeOfLeave(event.target.value);
  };

  const handleTypeOfLeaveDayChange = (event) => {
    setTypeOfLeaveDay(event.target.value);
  };

  const handleLeaveDateFromChange = (event) => {
    const date = event.target.value;
    setLeaveDateFrom(date);
    calculateNumberOfDays(date, leaveDateTo);
  };

  const handleLeaveDateToChange = (event) => {
    const date = event.target.value;
    setLeaveDateTo(date);
    calculateNumberOfDays(leaveDateFrom, date);
  };

  const handleAdditionalNoteChange = (event) => {
    setAdditionalNote(event.target.value);
  };

  const calculateNumberOfDays = (fromDate, toDate) => {
    if (fromDate && toDate) {
      let start = dayjs(fromDate);
      const end = dayjs(toDate);
      let days = 0;

      while (start <= end) {
        // If the day is not Sunday (0), count it
        if (start.day() !== 0) {
          days++;
        }
        start = start.add(1, "day");
      }

      setNumberOfDays(days);
    } else {
      setNumberOfDays(0);
    }
  };

  const handleFromTime = (e) => {
    setLeaveTimeFrom(e.target.value);
  };

  const handleToTime = (e) => {
    setLeaveTimeTo(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}empleavetype`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setLeaveTypes(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
    calculateNumberOfDays(leaveDateFrom, leaveDateTo);
  }, []);

  const isEditable = leaveRequest?.leave_status === "pending";

  const handleUpdateStatus = (leaveId, newStatus) => {
    // Send a request to the server to update the status
    fetch(`${API_ENDPOINT}empleave/${leaveId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        leave_status: newStatus,
        user_id: parseInt(userId),
      }),
    })
      .then((response) => {
        if (response.ok) {
          showSnackbar("Leave status updated successfully", "success");
          setLeaveRequest((prev) => ({ ...prev, leave_status: newStatus }));
        } else {
          showSnackbar("Failed to update leave status", "error");
          throw new Error("Failed to update leave status");
        }
        // Handle successful response if needed
      })
      .catch((error) => {
        console.error("Error updating leave status:", error);
        showSnackbar("Error updating leave status", "error");
        // Revert the status locally if the server update fails
      });
  };

  const handleUpdateDetails = (leaveId) => {
    let leaveDetails = {
      leave_type_id: typeOfLeave,
      from_date: leaveDateFrom,
      to_date: leaveDateTo,
      additional_note: additionalNote,
      from_time: leaveTimeFrom,
      to_time: leaveTimeTo,
      user_id: parseInt(userId),
    };

    // Send a request to the server to update the status
    fetch(`${API_ENDPOINT}empleave/${leaveId}`, {
      method: "PUT",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(leaveDetails),
    })
      .then((response) => {
        if (response.ok) {
          showSnackbar("Leave details updated successfully", "success");
          setLeaveRequest((prev) => ({
            ...prev,
            leave_type_id: { id: typeOfLeave },
            from_date: leaveDateFrom,
            to_date: leaveDateTo,
            additional_note: additionalNote,
          }));
        } else {
          showSnackbar("Failed to update leave details", "error");
          throw new Error("Failed to update leave status");
        }
        // Handle successful response if needed
      })
      .catch((error) => {
        console.error("Error updating leave status:", error);
        showSnackbar("Error updating leave details", "error");
        // Revert the status locally if the server update fails
      });
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} md={6} lg={6} sm={6}>
          <Typography variant="h5" m={1}>
            Employee Name: {leaveRequest?.emp_user_id?.first_name}{" "}
            {leaveRequest?.emp_user_id?.last_name}
          </Typography>
          <Typography variant="h5" m={1}>
            Role: {leaveRequest?.emp_user_id?.role}
          </Typography>
          <Typography variant="h5" m={1}>
            Branch Name: {leaveRequest?.emp_user_id?.branch_name}
          </Typography>
        </Grid>
        <Grid item xs={12} md={6} lg={6} sm={6}>
          Status:
          {leaveRequest?.leave_status === "accepted" && (
            <Chip label="Approved" color="secondary" />
          )}
          {leaveRequest?.leave_status === "denied" && (
            <Chip label="Denied" color="primary" />
          )}
          {leaveRequest?.leave_status === "pending" && (
            <Chip label="Pending" color="warning" />
          )}
          {leaveRequest?.leave_status === "cancelled" && (
            <Chip label="Cancelled" color="error" />
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={6} sm={6}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            Type of Leave
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.5em",
              }}
            >
              *
            </span>
          </InputLabel>
          <TextField
            select
            size="small"
            value={typeOfLeave}
            onChange={handleTypeOfLeaveChange}
            InputProps={{ style: { borderRadius: "12px" } }}
            fullWidth
            disabled={!isEditable}
          >
            {leaveTypes.map((item) => (
              <MenuItem key={item.id} value={item.id}>
                {item.leave_type}
              </MenuItem>
            ))}
          </TextField>
          {errors.typeOfLeave && (
            <Typography color="error" variant="caption">
              Type of leave is required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={6} sm={6}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            Type of Leave Day
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.5em",
              }}
            >
              *
            </span>
          </InputLabel>
          <TextField
            select
            size="small"
            value={typeOfLeaveDay}
            onChange={handleTypeOfLeaveDayChange}
            InputProps={{ style: { borderRadius: "12px" } }}
            fullWidth
            disabled={!isEditable}
          >
            <MenuItem value="full">Full Day</MenuItem>
            <MenuItem value="first_half">First Half Day</MenuItem>
            <MenuItem value="second_half">Second Half Day</MenuItem>
            <MenuItem value="short_morning">Short Leave - Morning</MenuItem>
            <MenuItem value="short_evening">Short Leave - Evening</MenuItem>
          </TextField>
          {errors.typeOfLeaveDay && (
            <Typography color="error" variant="caption">
              Type of leave is required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={6} sm={6}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            Leave Date From
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.5em",
              }}
            >
              *
            </span>
          </InputLabel>
          <TextField
            type="date"
            size="small"
            value={leaveDateFrom}
            onChange={handleLeaveDateFromChange}
            InputProps={{ style: { borderRadius: "12px" } }}
            fullWidth
            disabled={!isEditable}
          />
          {errors.leaveDateFrom && (
            <Typography color="error" variant="caption">
              Leave date from is required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12} md={6} lg={6} sm={6}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            Leave Date To
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1.5em",
              }}
            >
              *
            </span>
          </InputLabel>
          <TextField
            type="date"
            size="small"
            value={leaveDateTo}
            onChange={handleLeaveDateToChange}
            InputProps={{ style: { borderRadius: "12px" } }}
            fullWidth
            disabled={!isEditable}
          />
          {errors.leaveDateTo && (
            <Typography color="error" variant="caption">
              Leave date to is required
            </Typography>
          )}
        </Grid>

        {typeOfLeaveDay !== "full" && (
          <>
            <Grid item xs={12} md={6} lg={6} sm={6}>
              <InputLabel style={{ color: "black", position: "relative" }}>
                From Time
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <TextField
                value={leaveTimeFrom}
                onChange={handleFromTime}
                fullWidth
                type="time"
                size="small"
                InputProps={{ style: { borderRadius: "12px" } }}
                disabled={!isEditable}
              />
              {errors.leaveTimeFrom && (
                <Typography color="error" variant="caption">
                  Leave Time from is required
                </Typography>
              )}
            </Grid>

            <Grid item xs={12} md={6} lg={6} sm={6}>
              <InputLabel style={{ color: "black", position: "relative" }}>
                To Time
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <TextField
                value={leaveTimeTo}
                onChange={handleToTime}
                fullWidth
                type="time"
                size="small"
                InputProps={{ style: { borderRadius: "12px" } }}
                disabled={!isEditable}
              />
              {errors.leaveTimeTo && (
                <Typography color="error" variant="caption">
                  Leave Time To is required
                </Typography>
              )}
            </Grid>
          </>
        )}
        <Grid item xs={12} md={12} lg={12} sm={12}>
          <InputLabel style={{ color: "black", position: "relative" }}>
            Additional Note
          </InputLabel>
          <TextField
            size="small"
            value={additionalNote}
            onChange={handleAdditionalNoteChange}
            InputProps={{ style: { borderRadius: "12px" } }}
            fullWidth
            multiline
            rows={4}
            disabled={!isEditable}
          />
        </Grid>

        <Grid item xs={12} md={12} lg={12} sm={12}>
          <Typography variant="h5" mt={2}>
            Number of Leave Days: {numberOfDays}
          </Typography>
        </Grid>

        {!isEditable && (
          <Grid item xs={12} md={12} lg={12} sm={12}>
            <Typography variant="h4" mt={2}>
              Approved / Denied / Cancelled By:{" "}
              {leaveRequest?.approved_by?.first_name}
            </Typography>
            <Typography variant="h4" mt={2}>
              Date: {fDate(leaveRequest?.approved_date)}
            </Typography>
          </Grid>
        )}

        {userRole === "admin" && (
          <Box display="flex">
            {isEditable && (
              <>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={() =>
                    handleUpdateStatus(leaveRequest.id, "accepted")
                  }
                  style={{ marginTop: "20px" }}
                >
                  Confirm Leave Request
                </Button>

                <Box m={2} />

                <Button
                  variant="contained"
                  color="error"
                  onClick={() => handleUpdateStatus(leaveRequest.id, "denied")}
                  style={{ marginTop: "20px" }}
                >
                  Deny Leave Request
                </Button>
              </>
            )}
          </Box>
        )}

        {parseInt(userId) === leaveRequest?.created_by.id && (
          <Box display="flex">
            {isEditable && (
              <>
                <Button
                  variant="contained"
                  color="primary"
                  onClick={() => handleUpdateDetails(leaveRequest.id)}
                  style={{ marginTop: "20px" }}
                >
                  Edit Leave Request
                </Button>
                <Box m={2} />
                <Button
                  variant="contained"
                  color="error"
                  onClick={() =>
                    handleUpdateStatus(leaveRequest.id, "cancelled")
                  }
                  style={{ marginTop: "20px" }}
                >
                  Cancel Leave Request
                </Button>
              </>
            )}
          </Box>
        )}
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default EmployeeViewForm;
