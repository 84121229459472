import React from 'react'
import TaskEfficiencyChart from '../../section/report/TaskEfficiencyReport'
import Widget from '../../components/widget/Widget'
import { Box, Card, CardContent, Grid, Typography } from '@mui/material'

function TaskReport() {
  return (
    <>
    <div className='app'>
    
    <div className="content">
   
    <Grid container spacing={6} >
    <Grid item xs={6} md={3}>
        <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Tasks Assigned
          </Typography>
          <Typography variant="h4">0</Typography>
        </CardContent>
      </Card>
      </Grid>

      <Grid item xs={6} md={3}>
        <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Today Open Tasks
          </Typography>
          <Typography variant="h4">0</Typography>
        </CardContent>
      </Card>
      </Grid>

      <Grid item xs={6} md={3}>
        <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Today Closed Tasks
          </Typography>
          <Typography variant="h4">0</Typography>
        </CardContent>
      </Card>
      </Grid>

      <Grid item xs={6} md={3}>
        <Card>
        <CardContent>
          <Typography variant="h6" gutterBottom>
            Total Task Efficiency
          </Typography>
          <Typography variant="h4">0%</Typography>
        </CardContent>
      </Card>
      </Grid>
      </Grid>

      <Grid container spacing>
     
      <Grid item xs={12}>
      <TaskEfficiencyChart />
      </Grid>
      </Grid>
      </div>
      </div>
    </>
  )
}

export default TaskReport