import React, { useState } from "react";
import MainCard from "../../../components/Maincard";
import {
  Backdrop,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import ClearIcon from "@mui/icons-material/Clear";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import LaunchOutlinedIcon from "@mui/icons-material/LaunchOutlined";
import InfoCard from "./InfoCard";
import CustomPopup from "../../../components/CustomPopup";
import SvoReportTable from "./SvoReportTable";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import { useGetTokenOrLogout } from "../../../utils/token";
import { useNavigate } from "react-router-dom";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function SvoReport() {
  const userId = localStorage.getItem("userId");
  const getTokenOrLogout = useGetTokenOrLogout();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [svoReportData, setSvoReportData] = useState([]);
  const [fromDate, setFromDate] = useState("");
  const [toDate, setTodate] = useState("");
  const [loading, setLoading] = useState(false);
  const [testprepLoading, setTestPrepLoading] = useState(false);
  const [testpreData, setTestPrepData] = useState([]);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const today = new Date().toISOString().split("T")[0];
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handleChangeFromDate = (e) => {
    setFromDate(e.target.value);
  };
  const handleChangeToDate = (e) => {
    setTodate(e.target.value);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    // Handle form submission logic here
    setLoading(true);
    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
      "Content-Type": "application/json", // Make sure to keep the content type
    };

    const requestData = {
      user_id: parseInt(userId),
      from_date: fromDate,
      to_date: toDate,
    };

    try {
      const response = await fetch(`${API_ENDPOINT}reports/getsvoreport`, {
        method: "POST",
        headers,
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        setLoading(false);
        const result = await response.json();

        setSvoReportData(result);
      } else if (response.status === 404) {
        navigate("/page404");
      } else if (response.status === 401) {
        navigate("/page401");
      } else if (response.status === 403) {
        navigate("/page403");
      } else if (response.status === 440) {
        navigate("/page440");
      } else {
        navigate("/page500");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
      showSnackbar(" Error", "error");
      navigate("/page500");
    }
  };

  const handleReset = () => {
    setSvoReportData([]);
    setFromDate("");
    setTodate("");
  };

  const handleSubmitTestprep = async (event) => {
    setTestPrepLoading(true);

    // Handle form submission logic here

    const requestData = {
      user_id: parseInt(userId),
      from_date: fromDate,
      to_date: toDate,
    };

    try {
      const response = await fetch(
        `${API_ENDPOINT}reports/getsvoreportwithsubservice`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        setTestPrepLoading(false);
        const result = await response.json();

        setTestPrepData(result);
        if (result.length !== 0) handleOpen();
      } else if (response.status === 403) {
        // Handle errors, e.g., show an error message
        setTestPrepLoading(false);
        showSnackbar("Unknown User", "error");
      } else if (response.status === 404) {
        setTestPrepLoading(false);
        showSnackbar("Please enter valid date", "error");
      } else if (response.status === 500) {
        setTestPrepLoading(false);
        showSnackbar("Server Error", "error");
      } else {
        setTestPrepLoading(false);
        showSnackbar(" Error", "error");
      }
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
      showSnackbar(" Error", "error");
    }
  };
  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Backdrop open={testprepLoading} style={{ zIndex: 9999 }}>
        <Typography
          variant="h4"
          color="secondary"
          fontWeight={800}
          marginRight={2}
        >
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <Paper
            sx={{
              width: "200px",
              fontSize: "24px",
              fontWeight: 600,
              height: "50px",
              borderRadius: "25px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            SVO Report
          </Paper>
        </Grid>
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={6} lg={3} md={4} sm={4}>
                <Typography>From Date</Typography>
                <TextField
                  size="small"
                  value={fromDate}
                  onChange={handleChangeFromDate}
                  fullWidth
                  type="date"
                  InputProps={{
                    style: { borderRadius: "15px" },
                    inputProps: { max: today },
                  }}
                />
              </Grid>
              <Grid item xs={6} lg={3} md={4} sm={4}>
                <Typography>To Date</Typography>
                <TextField
                  size="small"
                  value={toDate}
                  onChange={handleChangeToDate}
                  fullWidth
                  type="date"
                  InputProps={{
                    style: { borderRadius: "15px" },
                    inputProps: { max: today },
                  }}
                />
              </Grid>
              <Grid item xs={12} lg={6} md={12} sm={12}>
                <Typography sx={{ visibility: "hidden" }}>To Date</Typography>
                <Button
                  variant="contained"
                  style={{
                    fontWeight: 500,
                    fontSize: "15px",
                    borderRadius: "25px",
                  }}
                  onClick={handleSubmit}
                >
                  {<SearchIcon sx={{ fontSize: 20 }} />} Search
                </Button>

                <Button
                  variant="contained"
                  color="error"
                  style={{
                    fontWeight: 500,
                    fontSize: "15px",
                    borderRadius: "25px",
                    marginLeft: "10px",
                  }}
                  onClick={handleReset}
                >
                  {<ClearIcon sx={{ fontSize: 20 }} />} Clear
                </Button>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12}>
          <MainCard>
            {!loading && svoReportData.length === 0 ? (
              <Typography variant="h4">No SVO Data Found</Typography>
            ) : !loading && svoReportData.length !== 0 ? (
              <Grid container spacing={2}>
                <Grid item xs={12} lg={12} md={12} sm={12}>
                  <Button
                    variant="outlined"
                    style={{
                      borderRadius: "25px",
                      borderColor: "#b2b2b4",
                      fontWeight: 600,
                      fontSize: "20px",
                    }}
                    startIcon={<SettingsOutlinedIcon />}
                    endIcon={<LaunchOutlinedIcon />}
                    onClick={handleSubmitTestprep}
                  >
                    Test Preparation
                  </Button>
                </Grid>
                <Grid item xs={6} lg={3} md={4} sm={4}>
                  <InfoCard
                    name="Client Count"
                    value={svoReportData.totalEnquiries}
                  />
                </Grid>
                <Grid item xs={6} lg={3} md={4} sm={4}>
                  <InfoCard
                    name="Super Prospective"
                    value={svoReportData.superprospectiveCount}
                  />
                </Grid>
                <Grid item xs={6} lg={3} md={4} sm={4}>
                  <InfoCard
                    name="Demo Scheduled"
                    value={svoReportData.demoBookedCount}
                  />
                </Grid>
                <Grid item xs={6} lg={3} md={4} sm={4}>
                  <InfoCard
                    name="Enrolled"
                    value={svoReportData.enrolledCount}
                  />
                </Grid>
              </Grid>
            ) : (
              <Typography variant="h4">No SVO Data Found</Typography>
            )}
          </MainCard>
        </Grid>
      </Grid>

      <CustomPopup
        open={open}
        onClose={handleClose}
        component={
          <SvoReportTable onClose={handleClose} testpreData={testpreData} />
        }
        title="Test Preparation"
        maxWidth="md"
        showDivider={true}
      />

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
