// DemoCancellationDialog.js

import React, { useState } from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Button from '@mui/material/Button';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';

const DemoCancellationDialog = ({ open, onClose, onConfirmCancellation, studentName }) => {
  const [reason, setReason] = useState('');

  const handleConfirm = () => {
    onConfirmCancellation(reason);
   // onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle variant='h4'>Cancellation of {studentName}'s Demo</DialogTitle>
      <DialogContent>
        <FormControl fullWidth>
          <InputLabel id="reason-label">Reason for Cancellation</InputLabel>
          <Select
            labelId="reason-label"
            id="reason"
            value={reason}
            onChange={(e) => setReason(e.target.value)}
            fullWidth
          >
            <MenuItem value="unavailability">Student is unavailable</MenuItem>
            <MenuItem value="change of delivery mode">Student would like to change delivery mode</MenuItem>
            {/* Add more reasons as needed */}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleConfirm} color="error" variant='contained'>
          Confirm Cancellation
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default DemoCancellationDialog;
