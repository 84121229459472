import { Card, CardContent, Typography } from '@mui/material'
import React from 'react'

export default function InfoCard({name, value}) {
  return (
    <>
    <Card variant="outlined" sx={{borderRadius:'15px'}} style={{backgroundColor:'#f0f2f4'}}>
    <CardContent>
      <Typography sx={{ fontSize: 18, fontWeight:500 ,color:"#000" }}>
    {name}
      </Typography>
      <Typography variant="h4" component="div" sx={{color:"blue"}} mt={1.5}>
      {value}
      </Typography>
      
    </CardContent>
    </Card>
 
  </>
  )
}
