import { combineReducers, configureStore } from '@reduxjs/toolkit'
import authReducer from '../features/auth/authSlice'
import userReducer from '../features/user/userSlice'
import customizationReducer from '../../newTestPrep/store/customizationReducer'
import tokenReducer from '../features/token/tokenSlice'

const rootReducer = combineReducers({
        auth: authReducer,
        user: userReducer,
        customization: customizationReducer,
        token: tokenReducer
})

const store = configureStore({
    reducer: rootReducer,
  });
  
  
  export default store;

