import {
  Autocomplete,
  Backdrop,
    Button,
    Checkbox,
    CircularProgress,
    FormControl,
    Grid,
    InputLabel,
    ListItemText,
    MenuItem,
    OutlinedInput,
    Select,
    Stack,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
import CustomSnackbar from "../../../components/ui-component/snackbar/CustomSnackbar";
 
  
  export default function TaskTransfer({ selectedId, onClose,setSelectedIds,filteredTasks, setFilteredTasks }) {
    const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
    const userId = localStorage.getItem('userId');
    const [taskOwnerList, setTaskOwnerList] = useState([]);
    const [taskowner, setTaskowner] = useState(null);
const [date, setDate] =useState(null)
    const [isLoading, setIsLoading] = useState(false)
  
    const [snackbarOpen, setSnackbarOpen] = React.useState(false);
    const [snackbarMessage, setSnackbarMessage] = React.useState("");
    const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  
    const showSnackbar = (message, severity) => {
      setSnackbarMessage(message);
      setSnackbarSeverity(severity);
      setSnackbarOpen(true);
    };
    
  
    useEffect(() => {
      const fetchData = async () => {
        try {
          const response = await fetch(`${API_ENDPOINT}route/getalluser`);
          if (response.ok) {
            const data = await response.json();
        const sortedData = data.sort((a, b) => a.first_name.localeCompare(b.first_name));
        setTaskOwnerList(sortedData);
          } else {
            throw new Error(`Request failed with status: ${response.status}`);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      };
  
      fetchData();
    }, []);
  
    const handleChangeTaskOwner = (event,value) => {
      setTaskowner(value);
    };
  
    const handleChangeDate=(e)=>{
      setDate(e.target.value)
    }

    const handleSubmit = () => {
       
        setIsLoading(true);

      const formdata = {
        task_id: selectedId,
        task_owner: taskowner?.id,
        user_id: parseInt(userId),
        due_date: date
      };
  
      fetch(`${API_ENDPOINT}route2.0/masstasktransfer`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formdata),
      })
        .then((response) => {
          if (response.ok) {
            console.log("Task Assign successfully.");
            showSnackbar("Task Transferred successfully");
            setIsLoading(false);

            const data = filteredTasks.filter((item) => {
              return !selectedId.includes(item.id);
            });
  
            setFilteredTasks(data);
          
  
            setTimeout(() => {
              onClose();
            }, 1000);
  
            setTimeout(() => {
              setSelectedIds([]);
            }, 1100);
  


           
          } 
          else if (response.status===405){
            setIsLoading(false);
              showSnackbar('Please select task owner or due date', 'error')
          }else {
            console.error("Error: Unknown Error");
            showSnackbar("Error transferring task", "error");
            setIsLoading(false);
            
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          // Handle network errors or other issues
          showSnackbar("Error: Server Error", "error");
          setIsLoading(false);
        });
    };
  
    return (
      <>
      <Backdrop open={isLoading} style={{ zIndex: 9999 }}>
        <Stack direction="row" spacing={1}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Transferring Tasks
          </Typography>
          <CircularProgress color="primary" />
        </Stack>
      </Backdrop>
        <Grid container spacing={2}>
          
  
       
<Grid item xs={12} lg={12} md={12} sm={12}>
        <Typography sx={{fontSize:"16px", fontWeight:600}}>Enquiry Owner</Typography>
     

<Autocomplete
value={taskowner}
                   
                 disablePortal
                    onChange={ 
                      handleChangeTaskOwner // Passing only the value
                    }
                
                    options={taskOwnerList}
                    getOptionLabel={(option) => `${option.first_name?option.first_name:""} ${option.last_name?option.last_name:""}`}
                    renderInput={(params) => <TextField {...params}    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: '15px' }, // Add borderRadius here
                    }} />}
                  />
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12}>
        <Typography sx={{fontSize:"16px", fontWeight:600}}>Due Date</Typography>
          <TextField value={date} onChange={handleChangeDate} fullWidth type="date" InputProps={{style:{borderRadius: '15px'}}}/>
        </Grid>
  
          <Grid item xs={12} lg={12} md={12} sm={12} textAlign="end">
            <Button variant="contained" onClick={handleSubmit}>
              Submit
            </Button>
          </Grid>
        </Grid>
  
        <CustomSnackbar
          open={snackbarOpen}
          onClose={() => setSnackbarOpen(false)}
          message={snackbarMessage}
          severity={snackbarSeverity}
        />
      </>
    );
  }
  