import { Box, Divider, List, ListItem, ListItemText, Typography, styled } from '@mui/material';
import React, { useState } from 'react'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { fDateTime } from '../../../utils/formatTime';
import { Link } from 'react-router-dom';

const Card = styled("div")({
    backgroundColor: `rgba(255, 255, 255, 1)`,
    boxShadow: `0px 0px 15px rgba(0, 0, 0, 0.05)`,
    borderRadius: `34px`,
    width: '100%', // Set the width to 90% of the parent container
    height: '100%', // Automatically adjust the height based on content
    margin: '0 auto', // Center the card horizontally
    padding: '16px',
  });

  const Title = styled("div")({
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    fontSynthesis: `none`,
    color: `rgba(24, 24, 24, 1)`,
    fontStyle: `normal`,
    fontFamily: `Poppins`,
    fontWeight: `400`,
    fontSize: `20px`,
    letterSpacing: `0px`,
    textDecoration: `none`,
    textTransform: `none`,
    //position: `absolute`,
    //left: `26px`,
    //top: `65px`,
  });

  const TimeStamp = styled("div")({
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    fontSynthesis: `none`,
    color: `rgba(165, 165, 165, 1)`,
    fontStyle: `normal`,
    fontFamily: `Poppins`,
    fontWeight: `400`,
    fontSize: `14px`,
    letterSpacing: `0px`,
    textDecoration: `none`,
    textTransform: `none`,
    //position: `absolute`,
    //left: `26px`,
    //top: `92px`,
  });

  const ViewAllNotififations = styled("div")({
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    fontSynthesis: `none`,
    color: `rgba(47, 94, 237, 1)`,
    fontStyle: `normal`,
    fontFamily: `Poppins`,
    fontWeight: `400`,
    fontSize: `16px`,
    letterSpacing: `0px`,
    textDecoration: `none`,
    lineHeight: `120.00000476837158%`,
    textTransform: `none`,
    width: `167px`,
    height: `24px`,
    position: `absolute`,
    left: `0px`,
    top: `0px`,
  });

  const Header = styled("div")({
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    fontSynthesis: `none`,
    color: `rgba(0, 0, 0, 1)`,
    fontStyle: `normal`,
    fontFamily: `Poppins`,
    fontWeight: `600`,
    fontSize: `26px`,
    letterSpacing: `0px`,
    textDecoration: `none`,
    textTransform: `none`,
    //position: `absolute`,
    //left: `35px`,
    //top: `0px`,
  });

  const Icon = styled("img")({
    height: `25px`,
    width: `25px`,
    //position: `absolute`,
    //left: `50px`,
    //top: `32px`,
  });


function AdvisorNotifications({list}) {
    const userRole = localStorage.getItem('userRole')
    const [showAll, setShowAll] = useState(false);
    const maxItemsToShow = 4;
      
  return (
    <Card>
        <Box display='flex' alignItems="center" justifyContent="center">
            <Icon alt='notification-icon' src={'/wetransfer_add-enquiry-svg_2023-07-25_1047/Notification.svg'}></Icon>
            <Header>Notifications</Header>
        </Box>
        <Divider variant="inset"/>
        <Box>
        {list.length === 0 ? (
          <Box textAlign="center" mt={2}>
            <Typography variant="body1">No notifications.</Typography>
          </Box>
        ) : (
        <List>
          {list.slice(0, showAll ? list.length : maxItemsToShow).map((notif) => (
            <React.Fragment key={notif.id}>
              <ListItem>
                <ArrowForwardIcon fontSize='small' />
                <ListItemText primary={notif.title || notif.name} secondary={fDateTime(notif.createdAt) || fDateTime(notif.demo_date) || fDateTime(notif.start_date)} />
              </ListItem>
              <Divider component="li" />
            </React.Fragment>
          ))}
        </List>
        )}
         {list.length > maxItemsToShow && !showAll && (
      <Box textAlign="center" mt={2}>
        {userRole === 'advisor' && (
          <Link to='/advisor/advisornotifications'>
            <Typography variant="subtitle1" color="primary" style={{ cursor: 'pointer' }}>
              See All Advisor Notifications <ArrowForwardIcon fontSize="small" />
            </Typography>
          </Link>
        )}
        {userRole === 'trainer' && (
          <Link to='/trainer/notifications'>
            <Typography variant="subtitle1" color="primary" style={{ cursor: 'pointer' }}>
              See All Trainer Notifications <ArrowForwardIcon fontSize="small" />
            </Typography>
          </Link>
        )}
         {userRole === 'admin' && (
          <Link to='/admin/notifications'>
            <Typography variant="subtitle1" color="primary" style={{ cursor: 'pointer' }}>
              See All Admin Notifications <ArrowForwardIcon fontSize="small" />
            </Typography>
          </Link>
        )}
      </Box>
    )}
        
        </Box>

    </Card>
  )
}

export default AdvisorNotifications