import {
    Autocomplete,
    Button,
    Grid,
    styled,
    TextareaAutosize,
    TextField,
    Typography,
  } from "@mui/material";
  import React, { useEffect, useState } from "react";
  

  import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EditMeetingForm({selectedData}) {
  const today = new Date().toISOString().split("T")[0];
    const id =selectedData?.id
    console.log("selectedData", selectedData)
    const userId = localStorage.getItem("userId");
  const [userlist, setUserlist] = useState([]);
  const [username, setUserName] = useState([]);
  const [agenda, setAgenda] = useState(selectedData?.meeting_agenda );
  const [date, setDate] = useState(selectedData?.meeting_date);
  const [fromTime, setFromTime] = useState(selectedData?.from_time);
  const [toTime, setToTime] = useState(selectedData?.to_time);
  const [details, setDetails] = useState(selectedData?.additional_detail);
  const [title, setTitle] = useState(selectedData?.meeting_title);

  const [errors, setErrors] = useState({
    username: false,
    agenda: false,
    date: false,
    fromTime: false,
    toTime: false,
  });

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  // TextArea styles




  // Fetch User List
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );
          setUserlist(sortedData);
         
       
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  
   
  console.log("userName",username)
  // Handle user change
  const handleUserchange = (event, value) => {
    setUserName(value);
    setErrors({ ...errors, username: !value });
  };

  const handleAgendaChange = (e) => {
    setAgenda(e.target.value);
    setErrors({ ...errors, agenda: !e.target.value });
  };

  const handleDateChange = (e) => {
    setDate(e.target.value);
    setErrors({ ...errors, date: !e.target.value });
  };

  const handleFromTimeChange = (e) => {
    setFromTime(e.target.value);
    setErrors({ ...errors, fromTime: !e.target.value });
  };

  const handleToTimeChange = (e) => {
    setToTime(e.target.value);
    setErrors({ ...errors, toTime: !e.target.value });
  };

  const handleDetailsChange = (e) => {
    setDetails(e.target.value);
  };

  const handleTitle = (e) => {
    setTitle(e.target.value);
    setErrors({ ...errors, title: !e.target.value });
  };

  useEffect(()=>{
    const filterdUsername = userlist.filter((data)=>selectedData.participating_id.includes(data.id.toString()))
  setUserName (filterdUsername)
  })
  const handleSubmit = () => {
    if (!username.length || !agenda || !date || !fromTime || !toTime || !title) {
      setErrors({
        username: !username.length,
        agenda: !agenda,
        date: !date,
        fromTime: !fromTime,
        toTime: !toTime,
        title:!title
      });
      return;
    }
    const formdata={
      meeting_title:title,
meeting_agenda:agenda,
participating_id:username.map((i)=>i.id),
meeting_date:date,
from_time:fromTime,
to_time:toTime,
additional_detail:details,
user_id: parseInt(userId)

    }
    fetch(`${API_ENDPOINT}empmeeting/${id}`, {
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formdata),
      })
        .then((response) => {
          if (response.ok) {
            showSnackbar("Meeting Update Successfully.");
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          } 
          else if(response.status === 401){
            showSnackbar("Unknown User","error")
          }
          else if(response.status === 403){
            showSnackbar("Unathorized User","error")
          }
          else {
            console.error("Error: Unknown Error");
            showSnackbar("Error: Unknown Error", "error");
          }
        })
        .catch((error) => {
          console.error("Error:", error);
          showSnackbar("Error: Unknown Error", "error");
        });
  };
  return (
  <>
 <Grid container spacing={2}>
 <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }}>
            Title of Meeting{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <TextField
            fullWidth
            size="small"
            InputProps={{ style: { borderRadius: "15px" } }}
          value={title}
          onChange={handleTitle}
          />
           {errors.title && (
            <Typography color="error" variant="caption">
              Title is required
            </Typography>
          )}
        </Grid>
       
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }}>
            Select Users{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <Autocomplete
            size="small"
            disablePortal
            multiple
            value={username}
            onChange={handleUserchange}
            options={userlist}
            getOptionLabel={(option) =>
              `${option.first_name ? option.first_name : ""} ${
                option.last_name ? option.last_name : ""
              }`
            }
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { borderRadius: "15px" },
                }}
              />
            )}
          />
          {errors.username && (
            <Typography color="error" variant="caption">
              User name is required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }}>
            Select Date{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <TextField
            fullWidth
            size="small"
            InputProps={{ style: { borderRadius: "15px" }, inputProps: { min: today }, }}
            type="date"
            value={date}
            onChange={handleDateChange}
          />
          {errors.date && (
            <Typography color="error" variant="caption">
              Date is required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography sx={{ fontWeight: 600 }}>
            Select From Time{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <TextField
            fullWidth
            size="small"
            InputProps={{ style: { borderRadius: "15px" } }}
            type="time"
            value={fromTime}
            onChange={handleFromTimeChange}
          />
          {errors.fromTime && (
            <Typography color="error" variant="caption">
              From time is required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12} lg={6}>
          <Typography sx={{ fontWeight: 600 }}>
            Select To Time{" "}
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <TextField
            fullWidth
            size="small"
            InputProps={{ style: { borderRadius: "15px" } }}
            type="time"
            value={toTime}
            onChange={handleToTimeChange}
          />
          {errors.toTime && (
            <Typography color="error" variant="caption">
              To time is required
            </Typography>
          )}
        </Grid>

        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }}>
            Agenda Of Meeting
            <span
              style={{
                color: "red",
                position: "absolute",
                marginLeft: "4px",
                fontSize: "1em",
              }}
            >
              *
            </span>
          </Typography>
          <TextField
            placeholder="Type anything…"
            value={agenda}
            fullWidth
            multiline
            rows={4}
            onChange={handleAgendaChange}
            InputProps={{ style: { borderRadius: "15px" ,  } }}
          />
          {errors.agenda && (
            <Typography color="error" variant="caption">
              Agenda is required
            </Typography>
          )}
        </Grid>
        <Grid item xs={12}>
          <Typography sx={{ fontWeight: 600 }}>Additional Details</Typography>
          <TextField
          fullWidth
            placeholder="Type anything…"
            value={details}
            onChange={handleDetailsChange}
            multiline
            rows={4}
            InputProps={{ style: { borderRadius: "15px" ,  } }}
          />
        </Grid>
        <Grid item xs={12} textAlign="end">
          <Button variant="contained" color="secondary" onClick={handleSubmit}>
            Submit
          </Button>
        </Grid>
      </Grid>
<CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

  </>
  )
}
