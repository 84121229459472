import { Box, Grid, Stack, Typography, styled } from '@mui/material'
import React, { useEffect, useState } from 'react'
import AdminTab from './AdminTab'
import MainCard from '../../components/Maincard'
import ActiveStudentCard from './ActiveStudentCard';
import AdminStudentTable from './AdminStudentTable';
import ReactApexChart from 'react-apexcharts';

export default function AdminDashboardStudent() {
    

  const cardData = [
    {
      id: 1,
      background: '#fcdcb6',
      iconBackground: '#FF6B47',
      iconSrc: '/Active Student.svg',
      value: 1,
      label: 'Active Student',
      lineSrc: '/ActiveStudentLine.svg',
    },
    {
      id: 2,
      background: '#b6fcbd',
      iconBackground: '#3CD856',
      iconSrc: '/PresentStudent.svg',
      value: 0,
      label: 'Student Present',
      lineSrc: '/PresentStudentLine.svg',
    },
    {
      id: 3,
      background: '#f7c8e5',
      iconBackground: '#FA5A7D',
      iconSrc: '/AbsentStudent.svg',
      value: 0,
      label: 'Student Absent',
      lineSrc: '/Absentstudentline.svg',
    },
  ];

  const data = [
    { student_id: '#20462', student_Name: 'Amandeep',class_name:'IELTS Online 11 :00- 13:00', enrolled_date: '15-01-2024', status: 'Present' },
    
    // Add more rows as needed
  ];


  // Donut Chart
  const updatedSeriesData = [
    {  present: 1, absent:  0 },
  ]

 

  const [donutChartData, setDonutChartData] = useState({
    series: [],
    options: {
      chart: {
        width: 380,
        type: 'donut',
      },

      plotOptions: {
        pie: {
          donut: {
            size: '80%',
            customScale: .1,
            labels: {
              show: false,
              name: {
                show: false,

              },
              value: {
                show: true, // Show value inside the donut chart
                formatter: function (val) {
                  return `${val}% completed`;
                },
              },
            }
          }
        },


      },


      dataLabels: {

        enabled: false,
        formatter: function (val, opts) {
          // Show data label only for the 'Team E' segment
          if (opts.seriesIndex === 0) {
            return '';
          } else {

            return `${val}%`;
          }
        },


      },
      legend: {
        show: false, // Hide legend
      },

      colors: ['#01BE85', '#F95F4B',],
      responsive: [{

        options: {
          chart: {
            width: 10
          },

          legend: {
            show: false,


          }
        }
      }],
      tooltip: {
        enabled: false,
      },

    
    },

  });

  const presentAbsentArray = updatedSeriesData.map(data => [data.present, data.absent]).flat();

  const getTotalValue = () => {
    return presentAbsentArray.reduce((sum, value) => sum + value, 0);
  };
  useEffect(() => {
 
    setDonutChartData((prevData) => ({
      ...prevData,
   
      series: presentAbsentArray,
    }));
  });
  return (
    <Box className="box" sx={{ height: '1086px', width: '100%', position: 'relative' , color:'' }}>
    {/* Gradient Background */}
    <Box
      className="rectangle"
      sx={{
        background: 'linear-gradient(248deg, #FFF6F0 -41.57%, rgba(255, 246, 240, 0.00) 97.88%)',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: -1,
      }}
    />

    <Box
      style={{ textAlign: "left" }}
      sx={{
        position: 'absolute',
        // top: '40%',
        // left: '40%',
        // transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        zIndex: 1,
      }}
    >

<Grid container spacing={2}>


 

<Grid item xs={12} lg={9} sm={12} md={12}>
          <Grid container spacing={2}>
            {cardData.map((data) => (
            
                <ActiveStudentCard key={data.id} data={data} />
             
            ))}
            <Grid item xs={12}>
              <MainCard>
                <Typography sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '23px', fontStyle: 'normal', fontWeight: 600 }}>
                  Student Details
                </Typography>
                <AdminStudentTable data={data} />
              </MainCard>
            </Grid>
          </Grid>
        </Grid>


<Grid item xs={12} lg={3} md={6} sm={6}>
              <MainCard>

                <Stack display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                  <Typography sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '24px', fontStyle: 'normal', fontWeight: 600 }}>Active Students</Typography>
                  <Box style={{ textAlign: 'center', position: 'relative' }}>
                    <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <Typography sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'40px', fontStyle:'normal',fontWeight:700}} >
        {getTotalValue()}
      </Typography >
      <Typography sx={{color:'#000' , fontFamily:'Poppins' ,fontSize:'normal' , fontWeight:400}}>Total No. of Students</Typography>
      </Box>
      <ReactApexChart options={donutChartData.options} series={donutChartData.series} type="donut" width="280px" />
    </Box>
                 {/* Display series names below the chart */}
     
 
 
                </Stack>
                {updatedSeriesData.map((data)=>(
                  <>
                 <Stack display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
  <Stack>
    <Box sx={{ bgcolor: '#01BE85', width: 10, height: 10, borderRadius:'50%' }}/>
  </Stack>
  <Stack display='flex' flexDirection='row' alignItems='center' width='100%'  justifyContent='space-between' marginLeft={1}>
    <Typography>Presente Student</Typography>
    <Typography>{data.present}</Typography>
  </Stack>
</Stack>
<Stack display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' >
  <Stack>
    <Box sx={{ bgcolor: '#F95F4B', width: 10, height: 10, borderRadius:'50%' }}/>
  </Stack>
  <Stack display='flex' flexDirection='row' alignItems='center' width='100%'  justifyContent='space-between' marginLeft={1}>
    <Typography>Absent Student</Typography>
    <Typography>{data.absent}</Typography>
  </Stack>
</Stack>
                </>
))}
              </MainCard>
            </Grid>
            </Grid>

       </Box>
       </Box>
  )
}
