import styled from '@emotion/styled';
import { Box, Card, Typography } from '@mui/material'
import React from 'react'

const CardWidget = styled("Card") (({ bgColor, color }) => ({
    backgroundColor: bgColor,
    border: `1px solid ${color}`,
    boxSizing: `border-box`,
    borderRadius: `22px`,
    width: '100%', // Set the width to 90% of the parent container
    height: '100%', // Automatically adjust the height based on content
    margin: '0 auto', // Center the card horizontally
    padding: '16px',
    //width: `225px`,
    //height: `126px`,
    //padding: '16px',
    //position: `absolute`,
    //left: `0px`,
    //top: `0px`,
    display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  }));

  const Text = styled("div")({
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    fontSynthesis: `none`,
    color: `rgba(0, 0, 0, 1)`,
    fontStyle: `normal`,
    fontFamily: `Poppins`,
    fontWeight: `600`,
    fontSize: `24px`,
    letterSpacing: `0px`,
    textDecoration: `none`,
    lineHeight: `105.50000667572021%`,
    textTransform: `none`,
  });

  const Number = styled("div") (({ bgColor, color }) =>({
    textAlign: `left`,
    whiteSpace: `pre-wrap`,
    fontSynthesis: `none`,
    color: color,
    fontStyle: `normal`,
    fontFamily: `Bebas Neue`,
    fontWeight: `400`,
    fontSize: `64px`,
    letterSpacing: `0px`,
    textDecoration: `none`,
    lineHeight: `105.50000667572021%`,
    textTransform: `none`,
  }));

function AdvisorWidget({bgColor, color, number, text}) {
  return (
    <CardWidget bgColor={bgColor} color={color}>
    <Box container spacing={2} display='flex' alignItems="center" justifyContent="center" height="100%">
      <Box>
        <Number color={color}>{number}</Number>
      </Box>
      <Box>
        <Text>{text}</Text>
      </Box>
    </Box>
  </CardWidget>
  )
}

export default AdvisorWidget