import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token: localStorage.getItem('token') || null,
}

const tokenSlice = createSlice({
    name: 'token',
    initialState,
    reducers: {
        setToken: (state, action) => {
            state.token = action.payload;
            localStorage.setItem('token', action.payload); 
          },
          removeToken: (state) => {
            state.token = null;
            localStorage.removeItem('token'); 
          },
    }
})

export const { setToken, removeToken } = tokenSlice.actions;

// Selector to get the token
export const selectToken = (state) => state.token.token;

export default tokenSlice.reducer;