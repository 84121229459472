import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from "@mui/material";
import React from "react";
import CloseIcon from "@mui/icons-material/Close";
export default function EditLessonplan({
  handleOpenPopup,
  handleClosePopup,
  open,
  title,
  component,
}) {
  return (
    <Dialog
      onClose={handleClosePopup}
      open={open}
      scroll="body"
      maxWidth="lg"
      sx={{ borderRadius: "50px" }}
    >
      <DialogTitle
        variant="h3"
        textAlign="center"
        sx={{ textAlign: "center", fontSize: "28px", fontWeight: "700" }}
      >
        {title}
        <IconButton
          edge="end"
          color="inherit"
          onClick={handleClosePopup}
          aria-label="close"
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>{component}</DialogContent>
      <DialogActions>
        {/* <Button variant='contained' color='secondary' onClick={onClose}>Cancel</Button> */}
      </DialogActions>
    </Dialog>
  );
}
