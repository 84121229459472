import { Box, Dialog, DialogContent, DialogTitle, Divider, IconButton } from '@mui/material'
import React from 'react'
import CloseOutlinedIcon from '@mui/icons-material/CloseOutlined';
export default function EmailPopup({handelclose,open,component,title,maxWidth}) {
  return (
<>
<Dialog onClose={handelclose} open={open} maxWidth={maxWidth} fullWidth>
    <Box sx={{background:'#E1EBEE'}}>
      <DialogTitle color='primary' sx={{fontWeight:600, fontSize:'22px'}}>{title}
      
      <IconButton aria-label="close" onClick={handelclose} sx={{position:'absolute', right:0,top:0 }}>
              <CloseOutlinedIcon />
            </IconButton>
      </DialogTitle>

      <DialogContent>{component}</DialogContent>
      </Box>
    </Dialog>
</>
  )
}
