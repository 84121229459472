import React, { useEffect, useState } from "react";
import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { useNavigate } from "react-router-dom";
import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Grid,
  Paper,
  TextField,
  Typography,
} from "@mui/material";
import { DataGrid, GridToolbar } from "@mui/x-data-grid";
import { fDate } from "../../../utils/formatTime";
import { Person as PersonIcon } from "@mui/icons-material";
import MainCard from "../../../components/Maincard";
import { useGetTokenOrLogout } from "../../../utils/token";

export default function ViewTodaysEnquiry() {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const [enquirydata, setEnquiryData] = useState([]);
  const [originalData, setOriGinalData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const navigate = useNavigate();
  const getTokenOrLogout = useGetTokenOrLogout();
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  useEffect(() => {
    setLoading(true);

    const token = getTokenOrLogout();
    if (!token) return;
    let headers = {
      Authorization: `Bearer ${token}`,
    };

    const fetchData = async () => {
      try {
        const response = await fetch(
          `${API_ENDPOINT}admindashboard/todayenquiries/${userId}`,
          { headers }
        );
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          if (data.length === 0) {
            showSnackbar("No No enquiries found for today.", "error");
            setLoading(false);
          } else {
            const sortedData = data.sort(
              (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
            );
            setEnquiryData(sortedData);
            setOriGinalData(sortedData);
            setLoading(false);
          }
        } else if (response.status === 404) {
          navigate("/page404");
        } else if (response.status === 401) {
          navigate("/page401");
        } else if (response.status === 403) {
          navigate("/page403");
        } else if (response.status === 440) {
          navigate("/page440");
        } else {
          navigate("/page500");
        }
      } catch {
        navigate("/page500");
      }
    };

    fetchData();
  }, [userId]);

  const handleViewMore = (row) => {
    // Handle the "View More" action here
    // console.log('View More:', row);

    const url = `/enquirydetails/${row.id}`;
    const newState = { enquiryDetails: row };

    // Open link in new tab
    const newTab = window.open(url, "_blank");

    // If new tab opened successfully, navigate with state data
    if (newTab) {
      newTab.onload = () => {
        newTab.history.replaceState(newState, "", url);
      };
    } else {
      // If new tab was blocked by browser, navigate with state data in current tab
      navigate(url, { state: newState });
    }
  };

  const columns = [
    {
      field: "name",
      headerName: "Enquiry Name",
      width: 150,
      headerClassName: "header-cell",
      valueFormatter: (params) =>
        `${params.value?.first_name} ${params.value?.last_name}` || "",
      renderCell: (params) => {
        // console.log('Cell Renderer - params.row:', params.row);
        return (
          <div>
            <Button size="small" onClick={() => handleViewMore(params.row)}>
              <PersonIcon /> {params.row.first_name} {params.row.last_name}
            </Button>
          </div>
        );
      },
    },
    {
      field: "mobile",
      headerName: "Mobile",
      width: 150,
      headerClassName: "header-cell",

      renderCell: (params) => (
        <div>
          {params.row?.mobile_country_code}
          {params.row?.mobile}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      width: 200,
      headerClassName: "header-cell",

      renderCell: (params) => <div>{params.value}</div>,
    },

    {
      field: "service_id",
      headerName: "Service",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.service_id?.service_name}</div>,
    },

    {
      field: "sub_service_id",
      headerName: "Sub Service",
      width: 150,
      headerClassName: "header-cell",
      valueFormatter: (params) => params.value?.name || "",
    },

    {
      field: "campaign_id",
      headerName: "Campaign",
      width: 350,
      headerClassName: "header-cell",
      valueFormatter: (params) => params.value?.campaign_name || "",
    },

    {
      field: "lead_source_type_id",
      headerName: "Lead Source",
      width: 100,
      headerClassName: "header-cell",
      valueFormatter: (params) => params.value?.name || "",
    },

    {
      field: "sub_lead_source_type_id",
      headerName: "Sub Lead Source",
      width: 150,
      headerClassName: "header-cell",
      valueFormatter: (params) => params.value?.source_type || "",
    },

    {
      field: "assign_to",
      headerName: "Assign To",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {" "}
          {params.row?.assign_to?.first_name} {params.row?.assign_to?.last_name}
        </div>
      ),
    },

    {
      field: "enquiry_date",
      headerName: "Enquiry Date",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params.value)}</div>,
    },
    {
      field: "stage_id",
      headerName: "Stage",
      width: 100,
      headerClassName: "header-cell",
      valueFormatter: (params) => params.value?.stage_name || "",
    },
    {
      field: "uploaded_by",
      headerName: "Uploaded By",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => (
        <div>
          {params.row?.created_by?.first_name}{" "}
          {params.row?.created_by?.last_name}
        </div>
      ),
    },
  ];

  //handleSearch
  const handleSearch = (e) => {
    const value = e.target.value;

    let filteredData1 = originalData.filter((data) =>
      `${data?.first_name} ${data?.last_name}`
        ?.toLowerCase()
        .replace(/\s+/g, "")
        .includes(value.toLowerCase().replace(/\s+/g, ""))
    );

    setEnquiryData(filteredData1);
  };
  return (
    <>
      <Backdrop open={loading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Fetching
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
      <Grid container spacing={2}>
        <Grid item xs={4} lg={2}>
          <Paper
            sx={{
              height: "40px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "24px",
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
              Imported Leads
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} lg={12} sm={12} md={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12} lg={12} sm={12} md={12} textAlign="end">
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    flexDirection: "row",
                    justifyContent: "end",
                  }}
                >
                  <Typography sx={{ fontWeight: 600 }}>
                    Search:{"  "}
                  </Typography>
                  <TextField
                    onChange={handleSearch}
                    size="small"
                    InputProps={{ style: { borderRadius: "15px" } }}
                  />
                </Box>
              </Grid>

              <Grid item xs={12}>
                {" "}
                <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    "& .header-cell": {
                      backgroundColor: "#abf7b1",
                      fontWeight: 600,
                      borderRight: "0.1px solid #C3C3C3",
                    },
                  }}
                >
                  <DataGrid
                    rows={enquirydata}
                    columns={columns}
                    autoHeight
                    //getRowId={(row) => row.lead_no}
                    //autoPageSize
                    //rowHeight={70}
                    // getRowId={(row) => row.mobile}
                    pageSize={5}
                    defaultDensity="compact"
                    //rowHeight={100}
                    rowsPerPageOptions={[5, 10, 20]}
                    //onPageSizeChange={(newPageSize) => console.log(newPageSize)}
                    //rowsPerPage={5}
                    pagination
                    components={{
                      NoRowsOverlay: () => (
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          <p>No Enquiries available</p>
                        </div>
                      ),
                    }}
                  />
                </Box>
              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
