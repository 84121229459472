import { Box, CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material'
import React, { useState } from 'react'

export default function AdvisorRevenueTable({data,isLoading}) {
   
  return (

    <TableContainer  sx={{borderRadius:'15px' ,padding:'10px' ,height:'320px' ,width:'100%'}}>
    <Table>
      <TableHead >
        <TableRow >
          <TableCell sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 }}>Enquiry Name</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600 ,}}>Branch</TableCell>

          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600,}}>Collected</TableCell>
          <TableCell  sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'18px' , fontStyle:'normal' , fontWeight:600}}>Pending</TableCell>
        </TableRow>
      </TableHead>
      <TableBody >
        {isLoading ? (
           <Box display="flex" justifyContent="center" alignItems='center' mt={2}> 
          <CircularProgress/>
           </Box>
        ) : (
         data.length === 0 ? (
          <Box display="flex" justifyContent="center" alignItems='center' mt={2}> 
          <Typography variant='h4'>No data available</Typography>
          </Box>
        ) : (
          data.map((row, index) => (
            <TableRow key={index}  style={{ backgroundColor: index % 2 === 0 ? '#f0f0f0' : 'inherit' ,width: '6%' }}>
              <TableCell >{row.enquiry_id?.first_name}  {row.enquiry_id?.last_name}</TableCell>
              <TableCell >{row.branch_id?.branch_name}</TableCell>
        
              <TableCell>{row?.collected_revenue ? row.collected_revenue : 0} /-</TableCell>
             
              <TableCell >{row?.pending_revenue ? row.pending_revenue :0} /-</TableCell>
            </TableRow>
          ))
        )
      )}
      
      </TableBody>
    </Table>
   
  </TableContainer>
  )
}
