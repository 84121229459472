import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

// material-ui
import { ButtonBase } from '@mui/material';

// project imports
//import config from 'config';
import { MENU_OPEN } from '../../../store/actions';
import Logo from '../../../components/ui-component/Logo';

const LogoSection = () => {
  const defaultId = 'default';
  const dispatch = useDispatch();
  return (
    <ButtonBase disableRipple onClick={() => dispatch({ type: MENU_OPEN, id: defaultId })} component={Link} >
      <Logo />
    </ButtonBase>
  );
};

export default LogoSection;