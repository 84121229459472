import { Button, Dialog, DialogActions, DialogContent, DialogTitle, FormControl, FormHelperText, Grid, InputAdornment, InputLabel, MenuItem, OutlinedInput, Select } from '@mui/material';
import React, { useState } from 'react'
import HighlightOffTwoToneIcon from '@mui/icons-material/HighlightOffTwoTone';
import BusinessTwoToneIcon from '@mui/icons-material/BusinessTwoTone';
import WorkTwoToneIcon from '@mui/icons-material/WorkTwoTone';
import MailTwoToneIcon from '@mui/icons-material/MailTwoTone';
import CallTwoToneIcon from '@mui/icons-material/CallTwoTone';
import UploadTwoToneIcon from '@mui/icons-material/UploadTwoTone';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import TodayTwoToneIcon from '@mui/icons-material/TodayTwoTone';
import CustomSnackbar from '../../components/ui-component/snackbar/CustomSnackbar';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function AddBranchAdmin({open, onClose, branchId, branchName}) {
    const branch_id = branchId
    const userRole = localStorage.getItem('userRole');
    const [profile, setProfile] = useState({
        first_name: '',
        last_name: null,
        email: '',
        mobile: '+91',
        branch_id: null
    });
    const [branches, setBranches] = useState([])
    const [testTypes, setTestTypes] = useState([])

    const [errors, setErrors] = useState({
        first_name: '',
        last_name: '',
        email: '',
        mobile: '',
        branch_id: '',
        user_role: '',
      });
    
      const [showAlert, setShowAlert] = useState(false);
      const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
   

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
      };
    
      const validateForm = () => {
        let isValid = true;
        const newErrors = { first_name: '', last_name: '' };
    
        if (!profile.first_name.trim()) {
          newErrors.first_name = 'First Name is required';
          isValid = false;
        }
    
     /*   if (!profile.last_name.trim()) {
          newErrors.last_name = 'Last Name is required';
          isValid = false;
        }*/

        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!profile.email.trim() || !emailRegex.test(profile.email.trim())) {
      newErrors.email = 'Invalid Email address';
      isValid = false;
    }

    // Phone number validation with country code
   // const phoneRegex = /^\+[0-9]{1,4}[0-9]{3,14}$/;
   const phoneRegex = /^\+91[1-9][0-9]{9}$/;
    if (!profile.mobile.trim() || !phoneRegex.test(profile.mobile.trim())) {
      newErrors.mobile = 'Invalid Phone number with country code ie +91....';
      isValid = false;
    }

    
        setErrors(newErrors);
        setShowAlert(!isValid); // Show alert if the form is not valid
        return isValid;
      };

      const handleAddBranchAdmin = () => {
        profile.branch_id = branchId;
        console.log(profile)
          fetch(`${API_ENDPOINT}admin`, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify(profile),
          })
          .then((response) => {
            if(response.status === 201) {
               showSnackbar('Admin assigned successfully', 'success');
               window.location.reload()
            } else {
              console.log('Error:', response)
              showSnackbar('Error assigning admin', 'error');
            }
          })
            .catch((error) => {
              console.error('Error assigning admin:', error);
              showSnackbar('Error assigning admin', 'error');
            });
       
      };

      const handleSubmit = () => {
        if (validateForm()) {
         
            handleAddBranchAdmin()

        } else {
          // Form has errors, handle accordingly
          alert('Error assigning admin')
        }
      };

      const handleRoleChange = (e) => {
        setProfile({ ...profile, user_role: e.target.value });
      };

      const handleGenderChange = (e) => {
        setProfile({ ...profile, gender: e.target.value });
      };

  return (
    <>
    <Dialog open={open} onClose={onClose} maxWidth='md' fullWidth>
      <DialogTitle variant='title'>Assign Branch Admin</DialogTitle>
      <DialogContent>
        <Grid container spacing={2}>
    <Grid item xs={6}>
    <FormControl fullWidth>
        <InputLabel>First Name</InputLabel>
        
        <OutlinedInput
            
            value={profile.first_name}
            onChange={(e) => setProfile({ ...profile, first_name: e.target.value })}
            type="text"
            label="FirstName"
           
        />
        <FormHelperText style={{ color: 'red'}}>{errors.first_name}</FormHelperText>
    </FormControl>
</Grid>

<Grid item xs={6}>
    <FormControl fullWidth>
        <InputLabel>Last Name</InputLabel>
        <OutlinedInput
            value={profile.last_name}
            onChange={(e) => setProfile({ ...profile, last_name: e.target.value })}
            type="text"
            label="Last Name"
    
        />
        <FormHelperText style={{ color: 'red'}}>{errors.last_name}</FormHelperText>
    </FormControl>
</Grid>



<Grid item xs={12}>
<FormControl fullWidth>
<InputLabel>Email</InputLabel>
<OutlinedInput
value={profile.email}
onChange={(e) => setProfile({ ...profile, email: e.target.value })}
type="email"
label="Email"
startAdornment={
<InputAdornment position="start">
<MailTwoToneIcon />
</InputAdornment>
}
/>
<FormHelperText style={{ color: 'red' }}>{errors.email}</FormHelperText>
</FormControl>
</Grid>



<Grid item xs={12}>
    <FormControl fullWidth>
        <InputLabel>Phone Number</InputLabel>
        <OutlinedInput
            value={profile.mobile}
            onChange={(e) => {
                setProfile({ ...profile, mobile: e.target.value });
            }}
            type="mobile"
            label="Phone Number"
            startAdornment={
                <InputAdornment position="start">
                    <CallTwoToneIcon />
                </InputAdornment>
            }
        />
        <FormHelperText style={{ color: 'red'}}>{errors.mobile}</FormHelperText>
    </FormControl>
</Grid>

<Grid item xs={12}>
<InputLabel >DOB</InputLabel>
    <FormControl fullWidth>
    
        <OutlinedInput
            value={profile.dob}
            type="date"
            label="DOB"
            InputProps={{
                inputProps: { max: '2005-12-31' },
            }}
            onChange={(e) => setProfile({ ...profile, dob: e.target.value })}
        />
    </FormControl>
</Grid>

<Grid item xs={12}>
<FormControl fullWidth>
<InputLabel>Gender</InputLabel>
<Select
    value={profile.gender}
    onChange={handleGenderChange}
    label="Gender"
    startAdornment={
    <InputAdornment position="start">
        {/* You can add an icon here if needed */}
    </InputAdornment>
    }
>
    <MenuItem value="male">Male</MenuItem>
    <MenuItem value="female">Female</MenuItem>
</Select>
</FormControl>
</Grid>



<Grid item xs={12}>
    <FormControl fullWidth>
        <InputLabel>Branch</InputLabel>
        <OutlinedInput
            value={branchName}
            type="text"
            label="Role"
        >
        </OutlinedInput>
        <FormHelperText style={{ color: 'red'}}>{errors.branch}</FormHelperText>
    </FormControl>
</Grid>


</Grid>
</DialogContent>

<DialogActions>
        <Button variant='contained' onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button variant='contained' color="primary" onClick={handleSubmit}>
          Add
        </Button>
      </DialogActions>
</Dialog>

<CustomSnackbar
                    open={snackbarOpen}
                    onClose={() => setSnackbarOpen(false)}
                    message={snackbarMessage}
                    severity={snackbarSeverity}
                />
</>
  )
}

export default AddBranchAdmin