import React from 'react'
import Head from './Head'
import LeadConversation from './LeadConversation'
import { Box } from '@mui/material'

export default function MainPage() {
  return (
    <Box className="box" sx={{ height: '1086px', width: '100%', position: 'relative' , color:'' }}>
    {/* Gradient Background */}
    <Box
      className="rectangle"
      sx={{
        background: 'linear-gradient(248deg, #FFF6F0 -41.57%, rgba(255, 246, 240, 0.00) 97.88%)',
        height: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        zIndex: -1,
      }}
    />

    <Box
      style={{ textAlign: "left" }}
      sx={{
        position: 'absolute',
        // top: '40%',
        // left: '40%',
        // transform: 'translate(-50%, -50%)',
        textAlign: 'center',
        zIndex: 1,
      }}
    >
<Head/>
<LeadConversation/>

    </Box>
    </Box>
  )
}
