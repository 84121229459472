import React, { useState } from 'react'
import { TextField, Button, Box, Paper, Grid, Avatar, Typography, Alert, AlertTitle } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Link } from 'react-router-dom';


const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function SignUp() {
  const [username, setUsername] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [errors, setErrors] = useState({});
  const [msg, setMsg] = useState('')
  const [alert, setAlert] = useState('')
  const [isOtpEnabled, setIsOtpEnabled] = useState(false);
  const [ generatedOTP, setGeneratedOTP ] = useState('')
  const [otpMatched, setOTPMatched] = useState(false);
  const [otp, setOTP] = useState('');
  const [isSignUpEnabled, setIsSignUpEnabled] = useState(false);

  const paperStyle = { padding: '30px 20px', width: 300, margin: "20px auto" }
  const headerStyle = { margin: 0 }
  const avatarStyle = { backgroundColor: '#1bbd7e' }
  const marginTop = { marginTop: 5 }

  const validateEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email)
  }

  const validateMobile = (mobile) => {
    // Basic mobile validation regex (10 digits)
    const mobileRegex = /^[+]\d{1,3}\d{8,10}$/;
    return mobileRegex.test(mobile);
  };

  const validatePassword = (password) => {
    const passwordRegex = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[!@#$%^&*]).{8,20}$/;
    return passwordRegex.test(password);
  };

  const handleGenerateOTP = (e) => {
    e.preventDefault();

    const newErrors = {};

    if (!validateEmail(email)) {
      newErrors.email = 'Invalid email address';
    }

    if (!validateMobile(mobile)) {
      newErrors.mobile = 'Invalid mobile number';
    }

    if (!validatePassword(password)) {
      newErrors.password =
        'Password should be 8 to 20 characters long and contain at least one uppercase letter, one lowercase letter, one digit, and one special character';
    }

    if (confirmPassword !== password) {
      newErrors.confirmPassword = 'Passwords do not match';
    }
    if(Object.keys(newErrors).length === 0) {
    // Perform sign-up logic with the form data
    console.log('Username:', username);
    console.log('Email:', email);
    console.log('Mobile:', mobile);
    console.log('Password:', password);
    console.log('Confirm Password:', confirmPassword);

    const userData = {
        username: username,
        email: email,
        mobile: mobile,
        password: password,
        confirm_password: confirmPassword
    }

    fetch(`${API_ENDPOINT}user/generating/otp`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(userData)
    })
    .then(response => {
      if(response.ok) {
        return response.json();
      } else if (response.status === 409) {
        setAlert('error')
        setMsg('This Email already has an account')
      } else {
        setAlert('error')
        setMsg('Failed to create an account')
      }
    })

    .then(data => {
      // Handle the returned data
      console.log('Response data:', data);
      // You can further process the data or update state variables as needed
      setMsg('OTP Sent to your phone');
      setAlert('success');
      setIsOtpEnabled(true)
      
      const receivedOTP = data;
        console.log( receivedOTP )
        const receivedOTPString = receivedOTP.toString()

        setGeneratedOTP(receivedOTPString)

        console.log('Generated OTP:', generatedOTP);

        setIsSignUpEnabled(true); // Enable the Sign Up button

  })
  .catch(error => {
    setAlert('error');
    setMsg('An error occurred during sign up');
    console.error('Error:', error);
  });

    
    } else {
      setErrors(newErrors)
    }
  };

  const handleSignUp = () => {
    // Handle OTP submission logic here, e.g., make API calls, etc.
 console.log('Submitted OTP:', otp);
 console.log('Generated OTP:', generatedOTP)

 if( otp === generatedOTP){
     console.log('Success')
     setOTPMatched(true)
     setOTP('')
     setGeneratedOTP('')

     const userData = {
      username: username,
      email: email,
      mobile: mobile,
      password: password,
      confirm_password: confirmPassword
  }
   
    fetch(`${API_ENDPOINT}user/signup`, {
         method: 'POST',
         headers: {
           'Content-Type': 'application/json'
         },
         body: JSON.stringify(userData)
       })

       .then(response => {
         if(response.ok) {
             setMsg('Account created successfully');
             setAlert('success');
             setUsername('')
             setMobile('')
             setEmail('');
             setPassword('');
             setConfirmPassword('');
             setErrors({});
                     
   
         } else if (response.status === 409) {
           setAlert('error')
           setMsg('This Email already has an account')
         } else if (response.status === 406) {
           setAlert('error')
           setMsg('This Mobile already has an account')
         }
         else {
           setAlert('error')
           setMsg('Failed to create an account')
         }
       })
     
 } else {
     console.log('Failed')
     setOTPMatched(false)
     setOTP('')
     setGeneratedOTP('')
 }
}

  return (
    <Grid>
     {/*} <Paper elevation={20} style={paperStyle}>
        <Grid align='center'>
          <Avatar style={avatarStyle}>
            <AddCircleOutlineIcon />
          </Avatar>
          <h2 style={headerStyle}>Sign Up</h2>
            <Typography variant='caption' gutterBottom>Please fill this form to create an account !</Typography>
  </Grid>*/}
    <form onSubmit={handleGenerateOTP}>
      <Box mt={2}
      >
      <TextField
        label="Username"
        value={username}
        onChange={(e) => setUsername(e.target.value)}
        required
        fullWidth
      />
      </Box>
      <Box mt={2}>
      <TextField
        label="Email"
        value={email}
        onChange={(e) => setEmail(e.target.value)}
        required
        fullWidth
        type="email"
        error={!!errors.email}
        helperText={errors.email}
      />
      </Box>

      <Box mt={2}>
      </Box>

      <Box mt={2}>
      <TextField
        label="Password"
        value={password}
        onChange={(e) => setPassword(e.target.value)}
        required
        fullWidth
        type="password"
        error={!!errors.password}
        helperText={errors.password}
      />
      </Box>
      <Box mt={2}>
      <TextField
        label="Confirm Password"
        value={confirmPassword}
        onChange={(e) => setConfirmPassword(e.target.value)}
        required
        fullWidth
        type="password"
        error={!!errors.confirmPassword}
        helperText={errors.confirmPassword}
      />
      </Box>

      <Box mt={2}>
        <TextField
          label="OTP"
          disabled={!isOtpEnabled}
          fullWidth
          value={otp}
          onChange={(e) => setOTP(e.target.value)}
          />
      </Box>

      {msg && <Alert severity={alert}>
      <AlertTitle>{alert}</AlertTitle>
        {msg}
        </Alert>}
      <Box mt={2}>
      <Button 
      type="submit"  
      color="primary"
      disabled={otp.length !== 6}
      onClick={handleSignUp}
      >
        Sign Up
      </Button>

      <Button type="submit" variant="contained" color="primary" onClick={() => setIsOtpEnabled(true)}>
        Generate OTP
      </Button>

      </Box>
      <p>
        Already have an account?{' '}
        <Link to="/login" underline="hover">
          Login
        </Link>
      </p>
    </form>
   
    </Grid>
  )
}

export default SignUp;