import { Label } from '@mui/icons-material'
import { Box, Button, Checkbox, Chip, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Stack, TextField, TextareaAutosize, Typography, styled } from '@mui/material'
import { grey } from '@mui/material/colors';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import React, { useEffect, useState } from 'react'
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ExpensePopup from './ExpensePopup';
import Categoryform from './Categoryform';
import CustomSnackbar from '../../newTestPrep/components/ui-component/snackbar/CustomSnackbar';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

export default function ExpenseForm({onClose}) {
  const userId = localStorage.getItem('userId')
  const [categories, setCategories] = useState([])
  const [branches, setBranches] = useState([])
  const [isLoading, setIsLoading] = useState(false)
    const [branch, setBranch] = React.useState(null);
 const [category,setCategory] = useState('')
 const [nameExpense, setNameExpense] = useState('')
 const [startDate, setStartDate] = useState('')
 const [amount, setAmount] = useState(null)
 const [occurrence, setOccurrence] = useState(null)
 const [occursEvery, setOccursEvery] = useState('')
 const [stopDate, setStopDate] = useState(null)
 const [note, setNote] = useState(null)
  
 const [isRecurring, setIsRecurring] = useState(false);

const [selectedCategory, setSelectedCategory] = useState(null);

const [isNameExpenseValid, setNameExpenseValid] = useState(true);
const [isAmountValid, setAmountValid] = useState(true);
const [isStartDateValid, setStartDateValid] = useState(true);
const [isCategoryValid, setCategoryValid] = useState(true);
const [isBranchValid, setBranchValid] = useState(true);

const handleChipClick = (categoryId) => {
  setSelectedCategory(categoryId === selectedCategory ? null : categoryId);
};

const [snackbarOpen, setSnackbarOpen] = useState(false);
const [snackbarMessage, setSnackbarMessage] = useState('');
const [snackbarSeverity, setSnackbarSeverity] = useState('success');

    const showSnackbar = (message, severity) => {
        setSnackbarMessage(message);
        setSnackbarSeverity(severity);
        setSnackbarOpen(true);
    };


const [isPopupOpen, setPopupOpen] = useState(false);

const handleOpenPopup = () => {
  setPopupOpen(true);
  console.log("opend")
};

const handleClosePopup = () => {
  setPopupOpen(false);
};

const handleReset = () => {
  setBranch('');
  setNameExpense('');
  setAmount(null);
  setStartDate('');
  setNote('');
  setIsRecurring(false);
  setOccursEvery('');
  setOccurrence('');
  setStopDate('');
  setSelectedCategory(null);

  // Reset validation states
  setNameExpenseValid(true);
  setAmountValid(true);
  setStartDateValid(true);
  setCategoryValid(true);
}

const handleSaveExpense = async () => {
  if (!branch || !nameExpense || amount === null || !startDate || selectedCategory === null) {
    if (!nameExpense) {
      setNameExpenseValid(false);
    } else {
      setNameExpenseValid(true);
    }
  
    if (amount === null) {
      setAmountValid(false);
    } else {
      setAmountValid(true);
    }
  
    if (!startDate) {
      setStartDateValid(false);
    } else {
      setStartDateValid(true);
    }
  
    if (selectedCategory === null) {
      setCategoryValid(false);
    } else {
      setCategoryValid(true);
    }

    if(!branch){
      setBranchValid(false);
    } else {
      setBranchValid(true);
    }

    showSnackbar('Please fill in all required fields', 'error');


    return;
  }

  try {
    const response = await fetch(`${API_ENDPOINT}expense`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        branch_id: branch,
        name: nameExpense,
        category_id: selectedCategory,
        start_date: startDate,
        amount: amount,
        is_it_recurring: isRecurring,
        occurs_every: occursEvery,
        occurrence_times: occurrence,
        stop_date: stopDate,
        description: note,
        user_id: userId,
      }),
    });

    if (response.ok) {
      console.log('Expense saved successfully');
      showSnackbar('Expense saved successfully', 'success');
      onClose()
      setTimeout(() => {
        window.location.reload();
      }, 3000);
    } else {
      console.error('Failed to save expense');
      showSnackbar('Failed to save expense', 'error');
    }
  } catch (error) {
    console.error('Error:', error);
    showSnackbar('Server Error', 'error');
  }

  // Reset the validation states
  setNameExpenseValid(true);
  setAmountValid(true);
  setStartDateValid(true);
  setCategoryValid(true);
};



const handleSaveCategory = async (category) => {
  try {
    const response = await fetch(`${API_ENDPOINT}category`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({ name: category, user_id: userId }),
    });

    if (response.ok) {
      console.log('Category saved successfully');
     showSnackbar('Category saved successfully', 'success')
    } else {
      console.error('Failed to save category');
      showSnackbar('Failed to save category', 'error')
    }
  } catch (error) {
    console.error('Error:', error);
    showSnackbar('Server Error', 'error')
  }
};

        useEffect(() => {
          setIsLoading(true)
          // Fetch packages data
          fetch(`${API_ENDPOINT}category`)
            .then((response) => response.json())
            .then((data) => {
              // Update the rows state with the fetched data
              console.log(data)
              setCategories(data);
              //setFilteredData(data);
              setIsLoading(false)
            })
            .catch((error) => {
              console.error('Error fetching categories data:', error);
              setIsLoading(false)
            });
        }, []);

        useEffect(() => {
          setIsLoading(true)
          // Fetch packages data
          fetch(`${API_ENDPOINT}route/fetchbranch/${userId}`)
            .then((response) => response.json())
            .then((data) => {
              // Update the rows state with the fetched data
              console.log(data)
              setBranches(data)
              //setFilteredData(data);
              setIsLoading(false)
            })
            .catch((error) => {
              console.error('Error fetching branches data:', error);
              setIsLoading(false)
            });
        }, []);


  return (
   <>
   <Typography sx={{textAlign:'center', color:'#000', fontFamily:'Poppins', fontStyle:'normal', fontSize:'19px' , fontWeight:600}}>Add Expense</Typography>
   <Stack display='flex' spacing={1.5}>
     <FormControl fullWidth>
       
          <Stack>
        <Typography>Branch</Typography>
        <Select
        //   labelId="demo-simple-select-label"
        //   id="demo-simple-select"
          value={branch}
          onChange={(event) => setBranch(event.target.value)}
          error={!isBranchValid}
          helperText={!isBranchValid ? 'Branch is required' : ''}
        >
          {branches.map((branch) => (
            <MenuItem key={branch.id} value={branch.id}>{branch.branch_name}</MenuItem>
          ))}

        </Select>
        </Stack>
        </FormControl>

        <FormControl>
        <Stack>
        <Typography>Name Of Expense</Typography>
        <TextField 
          id="outlined-basic"  
          variant="outlined"
          value={nameExpense}
          onChange={(e) => setNameExpense(e.target.value)}
          error={!isNameExpenseValid}
          helperText={!isNameExpenseValid ? 'Name is required' : ''}
           />
        </Stack>
        </FormControl>
        
        <FormControl>
        <Stack>
          <Typography>Category</Typography>
          <Stack direction='row' spacing={1} alignItems='center'>
          {categories.map(category => (
        <Chip
          key={category.id}
          label={category.name}
          onClick={() => handleChipClick(category.id)}
          style={{
            backgroundColor: category.id === selectedCategory ? '#4caf50' : null, // Set your desired background color
            color: category.id === selectedCategory ? '#ffffff' : null, // Set your desired text color
          }}
        />
      ))}
      <AddCircleOutlineIcon onClick={handleOpenPopup}/>

    </Stack>
    {!isCategoryValid && (
    <Typography sx={{ color: 'red', fontSize: '12px' }}>Category is required</Typography>
  )}

    <ExpensePopup
          open={isPopupOpen}
          handleClose={handleClosePopup}
          //  title="Confirmation"
          content={<Categoryform  addCategory={handleSaveCategory} onCloseTab={handleClosePopup} onClose={onClose}/>}
        />
        </Stack>
        </FormControl>

        <FormControl>
          <Stack>
            <Typography>Amount</Typography>
          <TextField 
                  id="outlined-basic" 
                  variant="outlined" 
                  type='number'
                  placeholder='In Rupees'
                  value={amount}
                  onChange={(e) => setAmount(e.target.value)}
                  error={!isAmountValid}
                  helperText={!isAmountValid ? 'Amount is required' : ''}
                  />
                  </Stack>
          </FormControl>

        <FormControl>
          <Stack>
            <Typography>Start Date</Typography>
          <TextField 
                  id="outlined-basic" 
                  variant="outlined" 
                  type='date'
                  value={startDate}
                  onChange={(e) => setStartDate(e.target.value)}
                  error={!isStartDateValid}
                  helperText={!isStartDateValid ? 'Start Date is required' : ''}
                  />
                  </Stack>
          </FormControl>

<FormControl>
<Stack width='150px'>
    <FormControlLabel
        control={<Checkbox checked={isRecurring} onChange={(e) => setIsRecurring(e.target.checked)} />}
        label="Is it recurring"
      />
</Stack>
</FormControl>

{isRecurring && (
        <>

    <FormControl>
          <Stack>
            <Typography>Occurs Every</Typography>
            <Stack display="flex" flexDirection="row">
              <Stack sx={{ width: '30%' }}>
                <TextField 
                  id="outlined-basic" 
                  variant="outlined" 
                  type='number'
                  placeholder='01'
                  value={occurrence}
                  onChange={(e) => setOccurrence(e.target.value)}
                  />
              </Stack>
              <Stack sx={{ width: '100%' }}>
                <Select
                  fullWidth
                  placeholder='Month'
                  value={occursEvery}
                  onChange={(event) => setOccursEvery(event.target.value)}
                >
                  <MenuItem value='day'>Day(s)</MenuItem>
                  <MenuItem value='week'>Week(s)</MenuItem>
                  <MenuItem value='month'>Month(s)</MenuItem>
                  <MenuItem value='year'>Year(s)</MenuItem>
                </Select>
              </Stack>
            </Stack>
          </Stack>
          </FormControl>

          <FormControl>
          <Stack>
            <Typography>Stop Date</Typography>
          <TextField 
                  id="outlined-basic" 
                  variant="outlined" 
                  type='date'
                  value={stopDate}
                  onChange={(e) => setStopDate(e.target.value)}
                  />
                  </Stack>
          </FormControl>
        </>
      )}

<Stack>
    <Typography>Note</Typography>
    <TextareaAutosize 
      placeholder="Type anything…" 
      value={note}
      onChange={(e) => setNote(e.target.value)}
      style={{height:'60px'}} />
    </Stack>
  
    <Stack display='flex' flexDirection='row'>
      <Box>
      <Button
         onClick={handleSaveExpense}
        style={{ width: '100px', height: '30px', borderRadius: '7px', background: '#faa748', color: '#FFF', fontFamily: "Poppins", fontSize: "18px", fontStyle: 'normal', fontWeight: 400}}>Save</Button>
      </Box>
      <Box sx={{ marginLeft: '8px' }}> {/* Adjust the spacing between the second and third buttons */}
        <Button 
        onClick={onClose}
        style={{ width: '100px', height: '30px', borderRadius: '7px', background: '#7a7b80', color: '#FFF', fontFamily: "Poppins", fontSize: "18px", fontStyle: 'normal', fontWeight: 400}}>Cancel</Button>
      </Box>

      <Box sx={{ marginLeft: '8px' }}> {/* Adjust the spacing between the second and third buttons */}
        <Button 
        onClick={handleReset}
        style={{ width: '100px', height: '30px', borderRadius: '7px', background: '#025175', color: '#FFF', fontFamily: "Poppins", fontSize: "18px", fontStyle: 'normal', fontWeight: 400}}>Reset</Button>
      </Box>
    </Stack>
   </Stack>

   <CustomSnackbar
open={snackbarOpen}
onClose={() => setSnackbarOpen(false)}
message={snackbarMessage}
severity={snackbarSeverity}
/>
  
   </>
  )
}
