import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  OutlinedInput,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function ViewTaskFilter({
  task,
  setTask,
  onApplyFilter,
  onResetFilter,
  createdby,
  setCreatedby,
  stage,
  setStage,
  taskOwner,
  setTaskowner,
  status,
  setStatus,
  state,
  setState,
  city,
  setCity,
  name,
  setName,
  createdDatefrom,
  setCreateddatefrom,
  createdDateTo,
  setCreateddateTo,
  duedatefrom,
  setDuedatefrom,
  duedateTo,
  setDuedateTo,
  closedby,
  setClosedby,
  closedDatefrom,
  setCloseddatefrom,
  closedDateTo,
  setCloseddateTo,
  isLoading,
  setIsLoading,
  tasks,
  setTasks,
  selectedChip,
  originaltaskData,
  filteredTasks,
  setFilteredTasks,
  followupReason,
  setFollowupReason,
  selectedFolloupId,
  setSelectedFollowupId,
  prospectiveLevel,
  setProspectiveLevel,
  prospectiveLevelId,
  setProspectiveLevelId,
  RecycleReason,
  setRecycleReason,
  campaign,
  setCampaign,
  subservice,
  setSubservice,
}) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const today = new Date().toISOString().split("T")[0];
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");
  const [tasklist, setTasklist] = useState([]);
  // const [task,setTask]=useState([])
  // const [createdby,setCreatedby]=useState([])
  const [stageslist, setStageslist] = useState([]);
  const [createdbylist, setCreatedbylist] = useState([]);
  // const[taskOwner,setTaskowner] =useState([])
  const [statelist, setstatelist] = useState([]);
  const [citylist, setCitylist] = useState([]);
  const [selectedStateIds, setSelectedStateIds] = useState([]);
  const [FollowUpReasonList, setFollowupReasonList] = useState([]);
  const [subServiceList,setSubServiceList] =useState([])

  const [recycleResonList, setRecycleResonList] =useState([])
const [prospectiveLevelList, setProspectiveLevelList] =useState([])
 const [campaignList, setCampaignList] =useState([])

  const statuslist = [
    { id: 0, name: "Open", value: 1 },
    { id: 1, name: "Close", value: 0 },
  ];
  const handelstatuschange = (value) => {
    setStatus(value);
  };

  const handleApplyFilter = async (event) => {
    event.preventDefault();

    setIsLoading(true);
    const requestData = {
      filter_task: selectedChip,
      due_from_date: duedatefrom || null,
      due_to_date: duedateTo || null,
      created_from_date: createdDatefrom || null,
      created_to_date: createdDateTo || null,
      created_by: createdby.map((i) => i.id),
      type_of_task: task.map((i) => i.id),
      task_owner: taskOwner.map((i) => i.id),
      user_id: parseInt(userId),
      status: status.map((i) => i.id),
      closed_from_date: closedDatefrom || null,
      closed_to_date: closedDateTo || null,
      closed_by: closedby.map((i) => i.id),
      followup_reason: followupReason.map((i) => i.id),
      stage_id: stage.map((i) => i.id),
      prospective_level_id:prospectiveLevelId,
      campaign_id:campaign.map((i) => i.id),
      sub_service_id:subservice.map((i) => i.id),
      recycle_reason_id:RecycleReason.map((i) => i.id)
    };

    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/viewtaskfilter`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });

      if (response.ok) {
        const result = await response.json();
        const sortedData = result.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setIsLoading(false);

        setFilteredTasks(sortedData);
      } else if (response.status === 403) {
        setIsLoading(false);
      } else {
        setIsLoading(false);
        setFilteredTasks([]);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const resetfilter = () => {
    setStatus([]);
    setTask([]);
    setCreatedby([]);

    setStage([]);
    setState([]);
    setCity([]);
    setTaskowner([]);
    setCreateddatefrom("");
    setCreateddateTo("");
    setDuedatefrom("");
    setDuedateTo("");

    setFollowupReason([]);
    setCloseddatefrom("");
    setCloseddateTo("");
    setClosedby([]);
    setFilteredTasks(originaltaskData);
    setSelectedFollowupId([]);
    setStage([])
    setProspectiveLevelId([])
    setCampaign([])
    setSubservice([])
    setProspectiveLevel([])
    setRecycleReason([])
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}typeoftask`);
        if (response.ok) {
          const data = await response.json();
          setTasklist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log(tasklist);

  const alltypeoftasklist = [{ name_of_task_type: "Select All" }, ...tasklist];

  const handleTypeOfTaskChange = (event, value) => {
    const ids = value.map((i) => i.id);
    // Check if "Select All" is selected
    if (value.some((option) => option.name_of_task_type === "Select All")) {
      // If "Select All" is already selected, remove it from the selection
      if (task.some((option) => option.name_of_task_type === "Select All")) {
        setTask([]);
      } else {
        // Otherwise, select all options
        setTask(tasklist);
        setSelectedFollowupId(ids);
      }
    } else {
      setTask(value);
      setSelectedFollowupId(ids);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}taskfollowupreason`);
        if (response.ok) {
          const data = await response.json();
          setFollowupReasonList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const alltypeofReasonlist = [
    { follow_up_reason: "Select All" },
    ...FollowUpReasonList,
  ];
  const handleTypeOfReasonChange = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.follow_up_reason === "Select All")) {
      setFollowupReason(FollowUpReasonList);
    } else {
      setFollowupReason(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(` ${API_ENDPOINT}route/getalluser`);
        if (response.ok) {
          const data = await response.json();
          setCreatedbylist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log(createdbylist);

  const allcreatedBylist = [{ first_name: "Select All" }, ...createdbylist];

  const handelcreatedby = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.first_name === "Select All")) {
      // If "Select All" is already selected, remove it from the selection
      if (createdby.some((option) => option.first_name === "Select All")) {
        setCreatedby([]);
      } else {
        // Otherwise, select all options
        setCreatedby(createdbylist);
      }
    } else {
      setCreatedby(value);
    }
  };

  const allclosedBylist = [{ first_name: "Select All" }, ...createdbylist];

  const handleclosedby = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.first_name === "Select All")) {
      // If "Select All" is already selected, remove it from the selection
      if (closedby.some((option) => option.first_name === "Select All")) {
        setClosedby([]);
      } else {
        // Otherwise, select all options
        setClosedby(createdbylist);
      }
    } else {
      setClosedby(value);
    }
  };

  const alltaskOwnerlist = [{ first_name: "Select All" }, ...createdbylist];

  const handeltaskowner = (event, value) => {
    // Check if "Select All" is selected
    if (value.some((option) => option.first_name === "Select All")) {
      // If "Select All" is already selected, remove it from the selection
      if (taskOwner.some((option) => option.first_name === "Select All")) {
        setTaskowner([]);
      } else {
        // Otherwise, select all options
        setTaskowner(createdbylist);
      }
    } else {
      setTaskowner(value);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}stage`);
        if (response.ok) {
          const data = await response.json();
          setStageslist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log(stageslist);
  const allStageList=[{stage_name:"Select All"},...stageslist]
  const handelstagechange = (event, value) => {
   if( value.some((option)=>option.stage_name==="Select All")){
      setStage(stageslist)
    }
else { 
  setStage(value);
 }
   
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}oldstate`);
        if (response.ok) {
          const data = await response.json();
          const filteredState = data.filter(
            (state) => state.country_id === 101
          );
          setstatelist(filteredState);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);
  console.log(statelist);
  console.log("state id is ", selectedStateIds);

  useEffect(() => {
    const fetchCities = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}oldcity`);
        if (response.ok) {
          const data = await response.json();
          // Filter cities based on selected state IDs
          const filteredCities = data.filter((city) =>
            selectedStateIds.includes(city.state)
          );
          setCitylist(filteredCities);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();
  }, [selectedStateIds]);

  console.log(citylist);

  const handelstatechange = (e) => {
    setState(e.target.value);
    setSelectedStateIds(e.target.value);
  };

  const handelcitychange = (e) => {
    setCity(e.target.value);
  };

  const handelnamechange = (e) => {
    setName(e.target.value);
  };

  const handelcreateddatefrom = (e) => {
    setCreateddatefrom(e.target.value);
  };
  const handelcreateddateTo = (e) => {
    setCreateddateTo(e.target.value);
  };
  const handelDuedatefrom = (e) => {
    setDuedatefrom(e.target.value);
    console.log(e.target.value);
  };
  const handelduedateTo = (e) => {
    setDuedateTo(e.target.value);
    console.log(e.target.value);
  };

  const handlecloseddatefrom = (e) => {
    setCloseddatefrom(e.target.value);
  };
  const handlecloseddateTo = (e) => {
    setCloseddateTo(e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}prospectivelevel`);
        if (response.ok) {
          const data = await response.json();
          console.log(data);
          setProspectiveLevelList(data);

      
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        
      }
    };

    fetchData();
  }, []);

  const allprspectivelevel =[{prospective_level:"Select All"},...prospectiveLevelList]

  const handleChangeprospective = (event,value) => {
 if(value.some((option)=> option.prospective_level==="Select All")){
  const id=prospectiveLevelList.map((i)=>i.id)
  console.log("id is", id)
  setProspectiveLevel(prospectiveLevelList)
setProspectiveLevelId(id)
 }else{
  const ids=value.map((i)=>i.id)
  setProspectiveLevel(value)
  console.log("id is", ids)
  setProspectiveLevelId(ids)

 }
}

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${API_ENDPOINT}recyclereason`);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
       setRecycleResonList(data);

    
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      
    }
  };

  fetchData();
}, []);

const allrecycleReasonList=[{recycle_reason:"Select All"},...recycleResonList]


const handleChangeRecycleReson=(event, value)=>{
  if(value.some((option)=>option.recycle_reason==="Select All")){
    setRecycleReason(recycleResonList)
  }else { setRecycleReason(value)}

}


useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${API_ENDPOINT}campaign/event/withname`);
      if (response.ok) {
        const data = await response.json();
        console.log("data", data);
        const sortedData = data.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );
        setCampaignList(sortedData);
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  fetchData();
}, []);

const allCampaignList =[{campaign_name:"Select All"},...campaignList]

const handlecampaignChange = (event,value) => {
if(value.some((option)=> option.campaign_name==="Select All")){


setCampaign(campaignList)

}else{

setCampaign(value)


}
}

useEffect(() => {
  const fetchData = async () => {
    try {
      const response = await fetch(`${API_ENDPOINT}subservice`);
      if (response.ok) {
        const data = await response.json();
        console.log(data);
       setSubServiceList(data);

    
      } else {
        throw new Error(`Request failed with status: ${response.status}`);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      
    }
  };

  fetchData();
}, []);

const allSubServiceList =[{name:"Select All"},...subServiceList]

const handleSubServiveChange = (event,value) => {
if(value.some((option)=> option.name==="Select All")){


setSubservice(subServiceList)

}else{

  setSubservice(value)


}
}

const [accordionExpanded, setAccordionExpanded] = useState(false);

const handleAccordionChange = () => {
  setAccordionExpanded(!accordionExpanded);
};
  return (
    <>
      <Typography sx={{ fontSize: "18px", fontWeight: 600 }}>
        Task Filter
      </Typography>
      <Grid
        container
        mt={3}
        spacing={2}
        sx={{ width: "100%", overflow: "auto" ,   height: accordionExpanded ? "70vh" : "70vh" }}
      >
       

        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Typography>Type Of Task</Typography>

          <Autocomplete
            value={task}
            size="small"
            multiple
            onChange={handleTypeOfTaskChange}
            options={alltypeoftasklist}
            getOptionLabel={(option) => option.name_of_task_type}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { borderRadius: "15px" }, // Add borderRadius here
                }}
              />
            )}
          />
        </Grid>

        {selectedFolloupId.includes(2) && (
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography>Follow Up Reasons</Typography>

            <Autocomplete
              value={followupReason}
              size="small"
              multiple
              onChange={handleTypeOfReasonChange}
              options={alltypeofReasonlist}
              getOptionLabel={(option) => option.follow_up_reason}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Typography>Task Created By</Typography>

          <Autocomplete
            value={createdby}
            size="small"
            multiple
            onChange={
              handelcreatedby // Passing only the value
            }
            options={allcreatedBylist}
            getOptionLabel={(option) => option.first_name}
            renderInput={(params) => (
              <TextField
                {...params}
                InputProps={{
                  ...params.InputProps,
                  style: { borderRadius: "15px" }, // Add borderRadius here
                }}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Typography sx={{ fontWeight: 600 }}>Task Created Date</Typography>
        </Grid>
        <Grid item xs={12} lg={6} md={6} sm={6}>
          <Typography mb={1}>From Date</Typography>
          <TextField
            sx={{ mb: 1, width: "100%" }}
            type="date"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={createdDatefrom}
            onChange={handelcreateddatefrom}
            InputProps={{
              style: { borderRadius: "15px", height: "40px" },
              inputProps: { max: today },
            }}
          />
        </Grid>

        <Grid item xs={12} lg={6} md={6} sm={6}>
          <Typography mb={1}>To Date</Typography>
          <TextField
            sx={{ mb: 1, width: "100%" }}
            type="date"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={createdDateTo}
            onChange={handelcreateddateTo}
            InputProps={{
              style: { borderRadius: "15px", height: "40px" },
              inputProps: { max: today },
            }}
          />
        </Grid>

        <Grid item xs={12} lg={12} md={12} sm={12}>
          <Typography sx={{ fontWeight: 600 }}>Task Due Date</Typography>
        </Grid>
        <Grid item xs={12} lg={6} md={6} sm={6}>
          <Typography mb={1}>From Date</Typography>
          <TextField
            sx={{ mb: 1, width: "100%" }}
            type="date"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={duedatefrom}
            onChange={handelDuedatefrom}
            InputProps={{
              style: { borderRadius: "15px", height: "40px" },
              //inputProps: { max: today },
            }}
          />
        </Grid>

        <Grid item xs={12} lg={6} md={6} sm={6}>
          <Typography mb={1}>To Date</Typography>
          <TextField
            sx={{ mb: 1, width: "100%" }}
            type="date"
            variant="outlined"
            InputLabelProps={{
              shrink: true,
            }}
            value={duedateTo}
            onChange={handelduedateTo}
            InputProps={{
              style: { borderRadius: "15px", height: "40px" },
              // inputProps: { max: today },
            }}
          />
        </Grid>

        {selectedChip === "completed" &&
          (userRole === "data_entry" || userRole === "center_admin") && (
            <Grid item xs={12} lg={12} md={12} sm={12}>
              <Typography>Task Closed By</Typography>

              <Autocomplete
                value={closedby}
                size="small"
                multiple
                onChange={handleclosedby}
                options={allclosedBylist}
                getOptionLabel={(option) => option.first_name}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    InputProps={{
                      ...params.InputProps,
                      style: { borderRadius: "15px" }, // Add borderRadius here
                    }}
                  />
                )}
              />
            </Grid>
          )}

        {selectedChip === "completed" && (
          <>
            <Grid item xs={12} lg={12} md={12} sm={12}>
              <Typography sx={{ fontWeight: 600 }}>Task Closed Date</Typography>
            </Grid>
            <Grid item xs={12} lg={6} md={6} sm={6}>
              <Typography>From Date</Typography>
              <TextField
                sx={{ mb: 1, width: "100%" }}
                type="date"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={closedDatefrom}
                onChange={handlecloseddatefrom}
                InputProps={{
                  style: { borderRadius: "15px" },
                  inputProps: { max: today },
                }}
              />
            </Grid>

            <Grid item xs={12} lg={6} md={6} sm={6}>
              <Typography>To Date</Typography>
              <TextField
                sx={{ mb: 1, width: "100%" }}
                type="date"
                variant="outlined"
                InputLabelProps={{
                  shrink: true,
                }}
                value={closedDateTo}
                onChange={handlecloseddateTo}
                InputProps={{
                  style: { borderRadius: "15px" },
                  inputProps: { max: today },
                }}
              />
            </Grid>
          </>
        )}

      

        {(userRole === "center_admin" || userRole === "data_entry") && (
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography>Task Owner</Typography>

            <Autocomplete
              value={taskOwner}
              size="small"
              multiple
              onChange={handeltaskowner}
              options={alltaskOwnerlist}
              getOptionLabel={(option) => option.first_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>
        )}


<Grid item xs={12} lg={12} md={12} sm={12}>
<Accordion expanded={accordionExpanded} onChange={handleAccordionChange}>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
          
        >
          <Typography variant="h4"  >   Additional Filters</Typography>
       
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography>Stage</Typography>

            <Autocomplete
            fullWidth
              value={stage}
              size="small"
              multiple
              onChange={handelstagechange}
              options={allStageList}
              getOptionLabel={(option) => option.stage_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography>Prospective Level</Typography>

            <Autocomplete
             fullWidth
              value={prospectiveLevel}
              size="small"
              multiple
              onChange={handleChangeprospective}
              options={allprspectivelevel}
              getOptionLabel={(option) => option.prospective_level}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          {prospectiveLevelId.includes(5) && ( 
  <>

               

<Grid item xs={12} lg={12} md={12} sm={12}>
                    <Typography sx={{ marginRight: "10px" }}>
                     Recycle Reason
                    </Typography>
                   
                 
          

                <Autocomplete
                 fullWidth
                  multiple
                  size="small"
              value={RecycleReason}
                 onChange={handleChangeRecycleReson}
                  options={allrecycleReasonList}
                  getOptionLabel={(option) => option.recycle_reason}
                  renderInput={(params) => <TextField {...params}   InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}  />}
                />
                </Grid>
                </>
                
              )}
              
              <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography>Campaign</Typography>

            <Autocomplete
             fullWidth
              value={campaign}
              size="small"
              multiple
              onChange={handlecampaignChange}
              options={allCampaignList}
              getOptionLabel={(option) => option.campaign_name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>

          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography>Sub Service</Typography>

            <Autocomplete
             fullWidth
              value={subservice}
              size="small"
              multiple
              onChange={handleSubServiveChange}
              options={allSubServiceList}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <TextField
                  {...params}
                  InputProps={{
                    ...params.InputProps,
                    style: { borderRadius: "15px" }, // Add borderRadius here
                  }}
                />
              )}
            />
          </Grid>
          </Grid>
        </AccordionDetails>
          </Accordion>

</Grid>
</Grid>

<Grid container >
        <Grid item xs={12} sm={12} lg={12} md={12}  style={{position:'absolute' , bottom:40, right:100}} >
       
        
              <Button
                variant="contained"
                color="primary"
                style={{borderRadius:'25px'}}
                onClick={handleApplyFilter}
              >
                {" "}
                Apply Filter
              </Button>
           

      
              <Button
                variant="contained"
             style={{marginLeft:'10px', borderRadius:'25px'}}
                onClick={resetfilter}
                color="error"
              >
                {" "}
                Reset Filter
              </Button>
            </Grid>
            </Grid>
           
     
    </>
  );
}
