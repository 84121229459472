import { Paper, Typography } from "@mui/material";
import React from "react";

export default function InfoCard({ label, value,  color,background }) {
  return (
    <Paper
      style={{
        border:"0.5px solid #b2b2b4",
        borderRadius: "25px",
        height: "35px",
        width: "auto",
        textAlign: "center",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginLeft: "13px",
        padding: "15px",
        background:background
      }}
    >
      <Typography sx={{ color: { color }, fontSize: "14px", fontWeight: 500 }}>
        {label}
      </Typography>
      <Typography sx={{ fontSize: "14px", fontWeight: 500, marginLeft: "7px" }}>
        {value}
      </Typography>
    </Paper>
  );
}
