import {
  Autocomplete,
  Button,
  Checkbox,
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import StarIcon from "@mui/icons-material/Star";
import { Controller, useForm } from "react-hook-form";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
export default function CampaignEditForm({ selectedCampaign, onClose }) {
  console.log("selectedcampaign", selectedCampaign);
  console.log(selectedCampaign.campaign_type_id);
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };
  const userId = localStorage.getItem("userId");
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
  } = useForm();

  const [countrylist, setcountrylist] = useState([]);
  const [country, setCountry] = useState([]);
  const [statelist, setStatelist] = useState([]);
  const [state, setstate] = useState([]);
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [campaigntypelist, setCampaigntypelist] = useState([]);
  const [campaigntype, setCampaigntype] = useState("");
  const [servicelist, setServiceList] = useState([]);
  const [Service, setService] = useState();
  const [paid, setPaid] = useState("");
  const [associatedlist, setAssociatedlist] = useState([]);
  const [associated, setAssociated] = useState(null);
  const [showAssociatedCampaign, setShowAssociatedCampaign] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [isLoading, setIsLoading] = useState(false);
  const [testTypeList, setTestTypeList] = useState([]);
  const [testTypeName, setTestTypename] = useState([]);
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };
  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}targetcountries`);
        if (response.ok) {
          const data = await response.json();

          setcountrylist(data);
          const defaultValue = data.filter((country) =>
            selectedCampaign?.country_id?.includes(country?.country_name)
          );
          if (defaultValue.length > 0) {
            const defaultValueIds = defaultValue.map((i) => i.country_name);
            console.log("defaultValueIds", defaultValueIds);
            setValue("country_id", defaultValueIds);
            setCountry(defaultValue);
          }
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log(country);

  const handelcountrychange = (event, value) => {
    const selectedCountries = value.map((i) => i.country_name);
    setCountry(value);

    setValue("country_id", selectedCountries);
  };

  // useEffect(() => {
  //   const fetchData = async () => {
  //     try {
  //       const response = await fetch(`${API_ENDPOINT}oldstate`);
  //       if (response.ok) {
  //         const data = await response.json();

  //         const filteredStates = data.filter((state) =>
  //           country.includes(state.country_id)
  //         );

  //         setStatelist(filteredStates);
  //       } else {
  //         throw new Error(`Request failed with status: ${response.status}`);
  //       }
  //     } catch (error) {
  //       console.error("Error fetching data:", error);
  //     }
  //   };

  //   fetchData();
  // }, [country]);
  // console.log(statelist);

  const handelstatechange = (e) => {
    const selectedStates = e.target.value;
    setstate(selectedStates); // Update state array
    setValue("state_id", selectedStates); // Set form value
    console.log("state is ", selectedStates);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);

          const defaultValue = data.filter((branch) =>
            selectedCampaign?.branch_id?.includes(branch?.branch_name)
          );
          if (defaultValue.length > 0) {
            const defaultValueIds = defaultValue.map((i) => i.branch_name);
            console.log("defaultValueIdsbranch", defaultValueIds);
            setValue("branch_id", defaultValueIds);
            setBranch(defaultValue);
          }
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  console.log(branchlist);

  const handelbranchchange = (event, value) => {
    const selectedBranches = value.map((i) => i?.branch_name);
    setBranch(value); // Update branch state
    setValue("branch_id", selectedBranches); // Set form value
    console.log(selectedBranches);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}campaigntype`);
        if (response.ok) {
          const data = await response.json();
          setCampaigntypelist(data);
          const defaultValue = data.find(
            (i) => selectedCampaign?.campaign_type_id?.name === i.name
          );
          const selecteddefaultValue = defaultValue?.name;
          console.log("defaultValuetype", selecteddefaultValue);
          if (defaultValue) {
            setValue("campaign_type_id", selecteddefaultValue);
            setCampaigntype(selecteddefaultValue);
          }
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handelcampaignType = (e) => {
    setAssociated(null)
    const selectedCampaignType = e.target.value;
    setCampaigntype(selectedCampaignType);
    console.log("type is ", selectedCampaignType);
    setValue("campaign_type_id", selectedCampaignType);
  };

  console.log("campaigntype", campaigntype);

  useEffect(() => {
    if (campaigntype === "Event") {
      setShowAssociatedCampaign(true);
    } else {
      setShowAssociatedCampaign(false);
    }
  }, [campaigntype]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        setIsLoading(true);
        const response = await fetch(
          `${API_ENDPOINT}route/getcampaign/${userId}`
        );
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
          const filteredcampaign = data.filter(
            (campaign) => campaign.campaign_type_id?.name === "Generic"
          );

          setAssociatedlist(filteredcampaign);

          if (filteredcampaign.length > 0) {
            const defaultValue = filteredcampaign.find(
              (i) => selectedCampaign?.associated_campaign_id === i.id
            );

            const selecteddefaultValue = defaultValue?.id;
            console.log("defaultValues", selecteddefaultValue);
            if (selecteddefaultValue) {
              setValue("associated_campaign_id", selecteddefaultValue);
              setAssociated(defaultValue);
            }
          }
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, []);

  const associatechange = (event, value) => {
    const associateCampaign = value?.id;
    setAssociated(value);
    setValue("associated_campaign_id", associateCampaign);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}service`);
        if (response.ok) {
          const data = await response.json();
          setServiceList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handelservice = (e) => {
    setService(e.target.value);
    console.log("service is", e.target.value);
    setValue("service_id", e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}subservice`);
        if (response.ok) {
          const data = await response.json();
          setTestTypeList(data);
          const defaultValue = data.filter((testtype) =>
            selectedCampaign?.sub_service_id?.includes(testtype?.name)
          );
          if (defaultValue.length > 0) {
            const defaultValueIds = defaultValue.map((i) => i.id);
            console.log("defaultValueIdstest", defaultValueIds);
            setValue("sub_service_id", defaultValueIds);
            setTestTypename(defaultValue);
          }
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allTestTypeList = [{ name: "Select All" }, ...testTypeList];
  const handleTestTypeChange = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      const selectedvalues = testTypeList.map((i) => i.id);
      setTestTypename(testTypeList);
      setValue("sub_service_id", selectedvalues);
    } else {
      setTestTypename(value);
      const selectedvalues = value.map((i) => i.id);
      setValue("sub_service_id", selectedvalues);
    }
  };

  // useEffect(() => {
  //   // Check if selectedCampaign has data and set initial values
  //   if (selectedCampaign) {
  //     setValue("campaign_type_id", selectedCampaign.campaign_type_id?.name);
  //     setValue("campaign_name", selectedCampaign.campaign_name);
  //     setValue(
  //       "external_campaign_name",
  //       selectedCampaign.external_campaign_name
  //     );
  //     setValue("campaign_start_date", selectedCampaign.campaign_start_date);
  //     setValue("campaign_end_date", selectedCampaign.campaign_end_date);
  //     setValue("paid_status", selectedCampaign.paid_status);

  //     setValue("branch_id", selectedCampaign.branch_id);
  //     setValue("service_id", selectedCampaign.service_id?.id);
  //     setValue("country_id", selectedCampaign.country_id);

  //     setValue(
  //       "associated_campaign_id",
  //       selectedCampaign.associated_campaign_id
  //     );

  //     const selectedCampaignType = campaigntypelist.find(
  //       (type) => type.name === selectedCampaign.campaign_type_id?.name
  //     );

  //     const campain = selectedCampaignType?.name;

  //     console.log(campain);
  //     if (selectedCampaignType) {
  //       setCampaigntype(campain);

  //       if (campain === "Event") {
  //         setShowAssociatedCampaign(true);
  //       } else {
  //         setShowAssociatedCampaign(false);
  //       }
  //     }

  //     const selectedPaidStatus = paidstatus.find(
  //       (status) => status.value === selectedCampaign.paid_status
  //     );

  //     console.log("paid", selectedPaidStatus);
  //     if (selectedPaidStatus) {
  //       setPaid(selectedPaidStatus.value);
  //     }

  //     const selectedService = servicelist.find(
  //       (service) => service.id === selectedCampaign.service_id.id
  //     );

  //     console.log("selectedservice", selectedService);
  //     const service = selectedService?.id;
  //     if (selectedService) {
  //       setService(service);
  //     }

  //     const selectedbranches =
  //       selectedCampaign && selectedCampaign.branch_id
  //         ? branchlist.filter((branch) => {
  //             console.log("Selected Country IDs:", selectedCampaign.branch_id);
  //             console.log("Country ID:", branch.branch_name);
  //             return selectedCampaign.branch_id.includes(branch.branch_name);
  //           })
  //         : [];

  //     console.log(selectedbranches);
  //     if (selectedbranches.length > 0) {
  //       const branchNames = selectedbranches.map(
  //         (branch) => branch.branch_name
  //       );
  //       setBranch(branchNames);
  //     } else {
  //       console.error("No matching branch found");
  //     }

  //     const selectedCountries =
  //       selectedCampaign && selectedCampaign.country_id
  //         ? countrylist.filter((country) => {
  //             console.log("Selected Country IDs:", selectedCampaign.country_id);
  //             console.log("Country ID:", country.country_name);
  //             return selectedCampaign.country_id.includes(country.country_name);
  //           })
  //         : [];

  //     console.log(selectedCountries);
  //     if (selectedCountries.length > 0) {
  //       const countryNames = selectedCountries.map(
  //         (country) => country.country_name
  //       );
  //       setCountry(countryNames);
  //     } else {
  //       console.error("No matching countries found");
  //     }

  //     const selectedassociatedcampain = associatedlist.find(
  //       (associated) =>
  //         associated.id === selectedCampaign.associated_campaign_id
  //     );

  //     console.log("asso", selectedassociatedcampain);
  //     const associate = selectedassociatedcampain?.id;

  //     if (selectedassociatedcampain) {
  //       setAssociated(associate);
  //     }
  //   }
  // }, [
  //   selectedCampaign,
  //   setValue,
  //   campaigntypelist,
  //   countrylist,
  //   branchlist,
  //   associatedlist,
  // ]);

  console.log("campaign", campaigntype);
  const onSubmit = async (data) => {
    try {
      const requestData = {
        ...data,
        user_id: parseInt(userId),
      };
      const response = await fetch(
        `${API_ENDPOINT}campaign/${selectedCampaign.id}`,
        {
          method: "PUT",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      if (response.ok) {
        showSnackbar("Campaign Update Successfully", "success");
        console.log("Campaign data submitted successfully.");
        setTimeout(() => {
          onClose();
        }, 1500);
      } else {
        showSnackbar("Failed to Update Campaign", "error");
        throw new Error("Failed to update  Camapign");
      }
    } catch (error) {
      console.error("Error Updating campaign data:", error);
    }
  };

  const paidstatus = [
    { id: 1, value: "Paid" },
    { id: 2, value: "Unpaid" },
  ];

  useEffect(() => {
    if (selectedCampaign) {
      const selectedValuepaidStatus = paidstatus.find(
        (i) => i.value === selectedCampaign?.paid_status
      );
      console.log("selectedValuepaidStatus", selectedValuepaidStatus?.value);
      setValue("paid_status", selectedValuepaidStatus?.value);
      setPaid(selectedValuepaidStatus?.value);
    }
  }, []);

  const handelpaidstatus = (e) => {
    setPaid(e.target.value);
    console.log("paid status is", e.target.value);
    setValue("paid_status", e.target.value);
  };
  return (
    <>
      {associatedlist.length === 0 ? (
        <Typography>Loading....</Typography>
      ) : (
        <Grid container spacing={2}>
          <Grid item xs={6} lg={6} md={6} sm={6}>
            <FormControl fullWidth error={Boolean(errors.campaign_type_id)}>
              <Typography sx={{ fontWeight: 600 }}>
                Campaign Type{" "}
                <span style={{ color: "red", fontSize: "18px" }}>*</span>
              </Typography>
              <Controller
                name="campaign_type_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Select
                  size="small"
                    {...field}
                    sx={{ borderRadius: "14px" }}
                    value={campaigntype}
                    onChange={handelcampaignType}
                  >
                    {campaigntypelist.map((item) => (
                      <MenuItem key={item.id} value={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.campaign_type_id && (
                <FormHelperText>Campaign Type is required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography sx={{ fontWeight: 600 }}>
              Campaign Name
              <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>
            <Controller
              name="campaign_name"
              control={control}
              defaultValue={selectedCampaign?.campaign_name}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                size="small"
                  {...field}
                  InputProps={{
                    style: { borderRadius: "14px" },
                  }}
                  fullWidth
                  error={errors.campaign_name}
                  helperText={
                    errors.campaign_name && "Campaign Name is required"
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography sx={{ fontWeight: 600 }}>
              External Campaign Name
            </Typography>
            <Controller
              name="external_campaign_name"
              control={control}
              defaultValue={selectedCampaign?.external_campaign_name}
              render={({ field }) => (
                <TextField
                size="small"
                  {...field}
                  InputProps={{
                    style: { borderRadius: "14px" },
                  }}
                  fullWidth
                />
              )}
            />
          </Grid>
          {showAssociatedCampaign && (
            <Grid item xs={12} lg={12} md={12} sm={12}>
              <FormControl
                fullWidth
                error={Boolean(errors.associated_campaign_id)}
              >
                <Typography sx={{ fontWeight: 600 }}>
                  Associated Campaign{" "}
                </Typography>
                <Controller
                  name="associated_campaign_id"
                  control={control}
                  render={({ field }) => (
                    <Autocomplete
                      disablePortal
                      size="small"
                      {...field}
                      value={associated}
                      options={associatedlist}
                      getOptionLabel={(option) => option.campaign_name}
                      onChange={associatechange}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              </FormControl>
            </Grid>
          )}
          <Grid item xs={12} lg={6}>
            <Typography sx={{ fontWeight: 600 }}>
              Campaign Start Date
              <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>
            <Controller
              name="campaign_start_date"
              control={control}
              defaultValue={selectedCampaign?.campaign_start_date}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                size="small"
                  {...field}
                  InputProps={{
                    style: { borderRadius: "14px" },
                  }}
                  type="date"
                  fullWidth
                  error={errors.campaign_start_date}
                  helperText={
                    errors.campaign_start_date &&
                    " Campaign Start Date is required"
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={12} lg={6}>
            <Typography sx={{ fontWeight: 600 }}>
              Campaign End Date
              <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>
            <Controller
              name="campaign_end_date"
              control={control}
              defaultValue={selectedCampaign?.campaign_end_date}
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  {...field}
                  size="small"
                  InputProps={{
                    style: { borderRadius: "14px" },
                  }}
                  type="date"
                  fullWidth
                  error={errors.campaign_end_date}
                  helperText={
                    errors.campaign_end_date && " Campaign End Date is required"
                  }
                />
              )}
            />
          </Grid>

          <Grid item xs={6} lg={6} md={6} sm={6}>
            <FormControl fullWidth error={errors.paid_status}>
              <Typography sx={{ fontWeight: 600 }}>
                Paid Status
                <span style={{ color: "red", fontSize: "18px" }}>*</span>
              </Typography>
              <Controller
                name="paid_status"
                control={control}
                rules={{ required: true }} // Add your validation rules here
                render={({ field }) => (
                  <Select
                    {...field}
                    size="small"
                    sx={{ borderRadius: "14px" }}
                    value={paid}
                    onChange={handelpaidstatus}
                  >
                    {paidstatus.map((item) => (
                      <MenuItem key={item.id} value={item.value}>
                        {item.value}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
              {errors.paid_status && (
                <FormHelperText>Paid Status is required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormControl fullWidth error={errors.branch_id}>
              <Typography sx={{ fontWeight: 600 }}>
                Branch <span style={{ color: "red", fontSize: "18px" }}>*</span>
              </Typography>

              <Controller
                name="branch_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    disablePortal
                    size="small"
                    {...field}
                    multiple
                    value={branch}
                    options={branchlist}
                    getOptionLabel={(option) => option.branch_name}
                    onChange={handelbranchchange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "12px" },
                        }}
                        fullWidth
                      />
                    )}
                  />
                )}
              />

              {errors.branch_id && (
                <FormHelperText>Branch is required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={6} lg={6} md={6} sm={6}>
            <FormControl fullWidth>
              <Typography sx={{ fontWeight: 600 }}>Service</Typography>
              <Controller
                name="service_id"
                control={control}
                render={({ field }) => (
                  <Select
                    {...field}
                    size="small"
                    defaultValue={selectedCampaign?.service_id?.id}
                    sx={{ borderRadius: "14px" }}
                    value={Service}
                    onChange={handelservice}
                    readOnly
                  >
                    {servicelist.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.service_name}
                      </MenuItem>
                    ))}
                  </Select>
                )}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} lg={6}>
            <FormControl fullWidth error={errors.sub_service_id}>
              <Typography sx={{ fontWeight: 600 }}>
       Sub Service{" "}
                <span style={{ color: "red", fontSize: "18px" }}>*</span>
              </Typography>

              <Controller
                name="sub_service_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    disablePortal
                    size="small"
                    {...field}
                    multiple
                    value={testTypeName}
                    options={allTestTypeList}
                    getOptionLabel={(option) => option.name}
                    onChange={handleTestTypeChange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "12px" },
                        }}
                        fullWidth
                      />
                    )}
                  />
                )}
              />
              {errors.sub_service_id && (
                <FormHelperText >
                  Test Type is required
                </FormHelperText>
              )}
            </FormControl>
          </Grid>

          <Grid item xs={6} lg={6} md={6} sm={6}>
            <FormControl fullWidth error={errors.country_id}>
              <Typography sx={{ fontWeight: 600 }}>
                Country{" "}
                <span style={{ color: "red", fontSize: "18px" }}>*</span>
              </Typography>

              <Controller
                name="country_id"
                control={control}
                rules={{ required: true }}
                render={({ field }) => (
                  <Autocomplete
                    disablePortal
                    size="small"
                    {...field}
                    multiple
                    value={country}
                    options={countrylist}
                    getOptionLabel={(option) => option.country_name}
                    onChange={handelcountrychange}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        InputProps={{
                          ...params.InputProps,
                          style: { borderRadius: "12px" },
                        }}
                        fullWidth
                      />
                    )}
                  />
                )}
              />

              {errors.country_id && (
                <FormHelperText>Country is required</FormHelperText>
              )}
            </FormControl>
          </Grid>

          {/* <Grid item xs={6} lg={6} md={6} sm={6}>
          <FormControl fullWidth>
            <Typography sx={{ fontWeight: 600 }}>State</Typography>
            <Controller
              name="state_id"
              control={control}
      
              render={({ field }) => (
                <Select
                  {...field}
                  sx={{ borderRadius: "14px" }}
                  value={state}
                  onChange={handelstatechange}
                  MenuProps={MenuProps}
                  renderValue={(selected) =>
                    selected
                      .map((value) => {
                        const selectedState = statelist.find(
                          (state) => state.id === value
                        );
                        return selectedState ? selectedState.state_name : "";
                      })
                      .join(", ")
                  }
                  multiple
                >
                  {statelist.map((name) => (
                    <MenuItem key={name.id} value={name.id}>
                      <Checkbox checked={state.indexOf(name.id) > -1} />
                      <ListItemText primary={name.state_name} />
                    </MenuItem>
                  ))}
                </Select>
              )}
            />
          </FormControl>
        </Grid> */}

          <Grid item xs={12} lg={12} sx={{ textAlign: "end" }}>
            <Button
              variant="contained"
              color="error"
              style={{
                width: "120px",
                borderRadius: "14px",
                fontWeight: 500,
                fontSize: "16px",
                marginRight: "8px",
              }}
              onClick={onClose}
            >
              Cancel
            </Button>

            <Button
              variant="contained"
              color="primary"
              style={{
                width: "120px",
                borderRadius: "14px",
                fontWeight: 500,
                fontSize: "16px",
              }}
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </Grid>
        </Grid>
      )}
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
