// Author : Ayesha Shaikh
// Date : 17 Aug 2024

import {
  Backdrop,
  Box,
  Button,
  Chip,
  CircularProgress,
  Grid,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  styled,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import MainCard from "../../../components/Maincard";
import { DataGrid } from "@mui/x-data-grid";
import PersonOutlineOutlinedIcon from "@mui/icons-material/PersonOutlineOutlined";
import EmailOutlinedIcon from "@mui/icons-material/EmailOutlined";
import CallOutlinedIcon from "@mui/icons-material/CallOutlined";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import { fDate } from "../../../utils/formatTime";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function EnquirySummary() {
  const navigate = useNavigate();
  const location = useLocation();
  const { personalid } = useParams();
  const userId = localStorage.getItem("userId");

  const [summaryData, setSummaryData] = useState([]);

  const [notesList, setNotesList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [criteria, selectedCriteria] = useState("all_note");

  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  // Fetch Summary Data
  useEffect(() => {
    fetchData();
  }, []);

  const fetchData = async () => {
    const requestData = {
      user_id: parseInt(userId),
      enq_personal_detail_id: parseInt(personalid),
    };
    try {
      setLoading(true);
      const response = await fetch(
        `${API_ENDPOINT}route2.0/getenqbypersonaldetail`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );

      if (response.ok) {
        const result = await response.json();

        const noteslist = result?.note_list;
        setNotesList(noteslist);
        setSummaryData(result);
        setLoading(false);
      } else if (response.status === 401) {
        showSnackbar("Unknown User", "error");
        setLoading(false);
      } else if (response.status === 400) {
        showSnackbar("Personal Id Is Missing", "error");
        setLoading(false);
      } else {
        setLoading(false);
        showSnackbar("Error fetching data:", "error");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      showSnackbar("Error fetching data:", "error");
      setLoading(false);
    }
  };

  const rowsWithSerialNumbers = notesList.map((row, index) => ({
    ...row,
    serialNumber: index + 1,
  }));

console.log("rowsWithSerialNumbers",rowsWithSerialNumbers)

  const columns = [
    {
      field: "serialNumber",
      headerName: "S.No",
      flex: 0.2,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
    },

    {
      field: "subService",
      headerName: "Sub Service",
      flex: 1,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>{params?.row?.enquiry_id?.sub_service_id?.name}</div>
      ),
    },
    {
      field: "notes",
      headerName: "Notes",
      flex: 1.5,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div style={{ whiteSpace: "pre-wrap", wordWrap: "break-word",  wordBreak: "break-word" }}>{params?.row?.note}</div>,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      flex: 1,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => (
        <div>
          {params?.row?.note_by?.first_name} {params?.row?.note_by?.last_name}
        </div>
      ),
    },
    {
      field: "cretedDate",
      headerName: "Created Date",
      flex: 1,
      headerClassName: "header-cell",
      headerAlign: "center",
      align: "center",
      renderCell: (params) => <div>{fDate(params?.row?.createdAt)}</div>,
    },
  ];

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: "20px",
    "& .MuiTableCell-head": {
      backgroundColor: "#abf7b1",
      fontWeight: 600,
      padding: "8px 16px",

      borderRight: "0.1px solid #C3C3C3",
  
      color: "#000",
      textAlign: "center", 
    },
    "& .MuiTableCell-body": {
      fontSize: "14px",

      borderRight: "0.1px solid #C3C3C3",
     
      textAlign: "center",
      padding: "8px 16px",
      overflow: "hidden",          // Hide overflowing content
      textOverflow: "ellipsis",    // Show ellipsis when content overflows
      
      whiteSpace: "normal",          // Allow text to wrap inside the cell
      wordWrap: "break-word",   
    
    },
   

  
  }));


  // View Enquiry Details page
  const handleViewMore = (row) => {
    // Handle the "View More" action here
    // console.log('View More:', row);

    const url = `/enquirydetails/${row?.id}`;
    const newState = { enquirydetails: row };
    console.log("rowdata", newState);
    // Open link in new tab
    const newTab = window.open(url, "_blank");

    // If new tab opened successfully, navigate with state data
    if (newTab) {
      newTab.onload = () => {
        newTab.history.replaceState(newState, "", url);
      };
    } else {
      // If new tab was blocked by browser, navigate with state data in current tab
      navigate(url, { state: newState });
    }
  };

  // Filter note according to selectedcriteria
  const handleSelectedCriteria = (criteria) => {
    selectedCriteria(criteria);
    const noteList = summaryData?.note_list;
    if (criteria === "all_note") {
      setNotesList(noteList);
    } else {
      const noteList = summaryData.note_list?.filter(
        (note) => parseInt(criteria) === note?.enquiry_id?.sub_service_id?.id
      );
      setNotesList(noteList);
    }
  };


  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={2} sm={4} md={4}>
          <Paper
            sx={{
              height: "45px",
              textAlign: "center",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "24px",
            }}
          >
            <Typography sx={{ fontSize: "20px", fontWeight: 600 }}>
              Enquiry Summary
            </Typography>
          </Paper>
        </Grid>

        <Backdrop open={loading} style={{ zIndex: 9999 }}>
          <Typography
            variant="h4"
            color="secondary"
            fontWeight={800}
            marginRight={2}
          >
            Fething Summary Details
          </Typography>
          <CircularProgress color="primary" />
        </Backdrop>
        <Grid item xs={12} md={12} sm={12} lg={12}>
          <Stack
            sx={{ display: "flex", flexDirection: "row", marginLeft: "20px" }}
          >
            <Box
              style={{
                background: "#F3DEFA",
                padding: "10px",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
              }}
            >
              <PersonOutlineOutlinedIcon
                sx={{ marginRight: "5px", color: "#A03BBF" }}
              />
              <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
                {summaryData?.enq_personal_detail_id?.first_name}{" "}
                {summaryData?.enq_personal_detail_id?.last_name}
              </Typography>
            </Box>

            <Box
              style={{
                background: "#EdEBFB",
                padding: "10px",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginLeft: "5px",
              }}
            >
              <EmailOutlinedIcon
                sx={{ marginRight: "5px", color: "#635ba3" }}
              />
              <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
                {summaryData?.enq_personal_detail_id?.email}
              </Typography>
            </Box>

            <Box
              style={{
                background: "#F9EBD8",
                padding: "10px",
                borderTopLeftRadius: "12px",
                borderTopRightRadius: "12px",
                display: "flex",
                alignItems: "center",
                flexDirection: "row",
                marginLeft: "5px",
              }}
            >
              <CallOutlinedIcon sx={{ marginRight: "5px", color: "#F7B88F" }} />
              <Typography sx={{ fontSize: "15px", fontWeight: 500 }}>
                {summaryData?.enq_personal_detail_id?.mobile}
              </Typography>
            </Box>
          </Stack>
          <MainCard>
            <Grid container spacing={2}>
              {summaryData?.enquiry_list?.map((enqiry) => (
                <Grid item xs={12} md={6} sm={6} lg={3}>
                  <Paper
                    sx={{
                      backgroundColor: "#F5E0E4",
                      padding: "10px",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    onClick={() => handleViewMore(enqiry)}
                  >
                    <Typography variant="h3" sx={{ color: "#DA4C4E" }}>
                      {enqiry?.sub_service_id?.name}
                    </Typography>
                    <Stack spacing={1} mt={1}>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                          Stage :
                        </Typography>
                        <Typography sx={{ fontSize: "15px" }} ml={1}>
                          {enqiry?.stage_id?.stage_name}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                          Prospective Level :
                        </Typography>
                        <Typography sx={{ fontSize: "15px" }} ml={1}>
                          {enqiry?.prospective_level_id?.prospective_level}{" "}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                          Assign To :
                        </Typography>
                        <Typography sx={{ fontSize: "15px" }} ml={1}>
                          {enqiry?.assign_to?.first_name}{" "}
                          {enqiry?.assign_to?.last_name}{" "}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                          CoAssign :
                        </Typography>
                        <Typography sx={{ fontSize: "15px" }} ml={1}>
                          {enqiry?.co_assignee?.first_name}{" "}
                          {enqiry?.co_assignee?.last_name}{" "}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                          Enquiried Date :
                        </Typography>
                        <Typography sx={{ fontSize: "15px" }} ml={1}>
                          {enqiry?.enquiry_date}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                          Last Follow Up :
                        </Typography>
                        <Typography sx={{ fontSize: "15px" }} ml={1}>
                          {enqiry?.last_follow_up_date}
                        </Typography>
                      </Stack>
                      <Stack
                        sx={{
                          display: "flex",
                          alignItems: "center",
                          flexDirection: "row",
                        }}
                      >
                        <Typography sx={{ fontWeight: 600, fontSize: "15px" }}>
                          Next Due Date :
                        </Typography>
                        <Typography sx={{ fontSize: "15px" }} ml={1}>
                          {enqiry?.next_follow_up_date}
                        </Typography>
                      </Stack>{" "}
                    </Stack>
                  </Paper>
                </Grid>
              ))}
            </Grid>
          </MainCard>
        </Grid>

        <Grid item xs={12} md={12} lg={12} sm={12}>
          <MainCard>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Typography variant="h4">Notes</Typography>
              </Grid>

              <Grid item xs={12}>
                <Chip
                  size="small"
                  label="All Notes"
                  onClick={() => handleSelectedCriteria("all_note")}
                  variant={criteria === "all_note" ? "contained" : "outlined"}
                  color={criteria === "all_note" ? "secondary" : "default"}
                  sx={{ margin: "3px" }}
                />

                {summaryData?.enquiry_list?.map((subservice) => (
                  <Chip
                    sx={{ margin: "3px" }}
                    value={subservice?.sub_service_id?.id}
                    key={subservice?.sub_service_id?.name}
                    label={subservice?.sub_service_id?.name}
                    onClick={() =>
                      handleSelectedCriteria(subservice?.sub_service_id?.id)
                    }
                    color={
                      criteria === subservice?.sub_service_id?.id
                        ? "secondary"
                        : "default"
                    }
                    variant={
                      criteria === subservice?.sub_service_id?.id
                        ? "contained"
                        : "outlined"
                    }
                  />
                ))}
              </Grid>

              <Grid item xs={12}> 
                {/* <Box
                  sx={{
                    height: "100%",
                    width: "100%",
                    "& .header-cell": {
                      backgroundColor: "#abf7b1",
                      fontWeight: 600,
                      borderRight: "0.1px solid #C3C3C3",
                    },
                  }}
                >
                  <DataGrid
                    rows={rowsWithSerialNumbers}
                    columns={columns}
                    getRowId={() => Math.floor(Math.random() * 100000000)}
                    slots={{
                      noRowsOverlay: CustomNoRowsOverlay,
                    }}
                    autoHeight
                    initialState={{
                      pagination: {
                        paginationModel: {
                          pageSize: 100,
                        },
                      },
                    }}
                    pageSizeOptions={[25, 50, 100]}
                  />
                </Box> */}

<Box
                  sx={{
                    height: "100%",
                    width: "100%",}} >
<StyledTableContainer component={Paper}>
      <Table sx={{ minWidth: 650, tableLayout: 'fixed' }} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell  sx={{ width: '10%' ,borderLeft:"0.1px solid #C3C3C3"}}>S.No</TableCell>
            <TableCell  sx={{ width: '20%' }}>Sub Service</TableCell>
            <TableCell  sx={{ width: '30%' }} >Notes</TableCell>
            <TableCell  sx={{ width: '20%' }}>Created By</TableCell>
            <TableCell  sx={{ width: '20%' }}>Created Date</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {rowsWithSerialNumbers.map((row) => (
            <TableRow
              key={row.name}
              
            >
              <TableCell component="th" scope="row" sx={{borderLeft:"0.1px solid #C3C3C3"}}>
                {row.serialNumber}
              </TableCell>
              <TableCell >{row?.enquiry_id?.sub_service_id?.name}</TableCell>
              <TableCell >{row.note}</TableCell>
              <TableCell >   {row?.note_by?.first_name} {row?.note_by?.last_name}</TableCell>
              <TableCell >{fDate(row.createdAt)}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </StyledTableContainer>
    </Box>

              </Grid>
            </Grid>
          </MainCard>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
