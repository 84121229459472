import { Box, Stepper, Step, StepLabel, Button, Menu, MenuItem, IconButton, CircularProgress, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'
import EditIcon from '@mui/icons-material/Edit';
import CustomSnackbar from '../../newTestPrep/components/ui-component/snackbar/CustomSnackbar';

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

function LeadStepper( {enquiryData}) {
  const { stage_id } = enquiryData;
  const stage_name = stage_id ? enquiryData.stage_id.stage_name : null;
  const stageId = stage_id ? enquiryData.stage_id.id : null;
  const enquiryId = enquiryData.id
  const [stages, setStages] = useState([])
  const [loading, setLoading] = useState(false);

  console.log(stage_name)
  console.log(enquiryData)

      const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
      };

      const [snackbarOpen, setSnackbarOpen] = useState(false);
      const [snackbarMessage, setSnackbarMessage] = useState('');
      const [snackbarSeverity, setSnackbarSeverity] = useState('success');
      
          const showSnackbar = (message, severity) => {
              setSnackbarMessage(message);
              setSnackbarSeverity(severity);
              setSnackbarOpen(true);
          };
    

      //const activeStepIndex = stage_name ? steps.indexOf(stage_name) : 0;

      const [activeStepIndex, setActiveStepIndex] = useState(stageId ? stageId - 1 : 0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [stageEditId, setStageEditId] = useState(null);

  const handleStepEdit = (event, index) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuItemClick = (index) => {
    setActiveStepIndex(index - 1);
    setAnchorEl(null);
    // You may want to update the stage_id in enquiryData here
    setStageEditId(index)
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    // Fetch menu items using the fetch function
    setLoading(true);
    fetch(`${API_ENDPOINT}stage`)
      .then(response => response.json())
      .then(data => {
        setStages(data);
        setLoading(false);
        console.log(data)
      })
      .catch(error => {
        console.error('Error fetching stages:', error);
        setLoading(false);
      });
  }, []);

   //logic for save button
   const handleSaveStudentInfo = async () => {

       updateSectionData(stageEditId, 'stage info');

     };
   
    //put api request for updating information with save
     const updateSectionData = (sectionData, sectionName) => {
       const requestData = {
        stage_id: sectionData
       }

       fetch(`${API_ENDPOINT}lmenquirycrm/${enquiryId}`, {
         method: 'PUT', 
         headers: {
           'Content-Type': 'application/json',
           
         },
         body: JSON.stringify(requestData),
       })
         .then((response) => {
           console.log(response)
           if (response.ok) {
             // Data updated successfully
             
             console.log(`Data updated for ${sectionName}`);
             showSnackbar(`Data updated for ${sectionName}`, 'success');
             alert('Stage updated successfully')
             //alert('Data updated successfully')
           } else {
             // Handle errors here
             console.error(`Error updating data for ${sectionName}`);
             showSnackbar(`Error updating data for ${sectionName}`, 'error');
             alert('Error updating stage')
             //alert('Error updating data')
           }
         })
         .catch((error) => {
           console.error('API request error:', error);
           showSnackbar('Network Error', 'error');
           alert('Network Error')
           //alert('Network Error')
         });
     };

     if (loading) {
      return <CircularProgress />;
    }

  return (
    <>
    {stages.length === 0 ? (
        <Typography variant="body1" align="center">
          No stages data available
        </Typography>
      ) : (
    <Box sx={{ width: "75%", margin: "0 auto", mt:'2' }}>
        <Stepper activeStep={activeStepIndex} alternativeLabel >
            {stages.map((label, index) => (
            <Step key={label.id}>
            <StepLabel>
              {capitalizeFirstLetter(label.stage_name)}
              
            </StepLabel>
          </Step>
            ))}
           {/*} <IconButton
                aria-label="edit"
                onClick={(event) => handleStepEdit(event)}
                size="small"
                color="secondary"
              >
                <EditIcon />
              </IconButton>

              <Box >
        <Button variant="contained" onClick={handleSaveStudentInfo}>Save</Button>
            </Box>*/}

     
        </Stepper>

        <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {stages.map((label, index) => (
          <MenuItem key={label.id} onClick={() => handleMenuItemClick(label.id)}>
            {capitalizeFirstLetter(label.stage_name)}
          </MenuItem>
        ))}
      </Menu>

      <CustomSnackbar
      open={snackbarOpen}
      onClose={() => setSnackbarOpen(false)}
      message={snackbarMessage}
      severity={snackbarSeverity}
      />
      
    </Box>
      )}


      
</>
  )
}

export default LeadStepper