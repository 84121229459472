import React, { useEffect, useState } from 'react'
import AdminHead from '../AdminDashboard.jsx/AdminHead'
import { Avatar, Box, Button, Divider, FormControl, FormControlLabel, FormLabel, Grid, Radio, RadioGroup, Stack, Typography } from '@mui/material'
import LeadWidget from '../LeadConversation/LeadWidget';
import MainCard from '../../components/Maincard';
import ReactApexChart from 'react-apexcharts';
import PackageCard from './AdminPackage';
import RevenueButton from './RevenueButton';

export default function AdminDasboardOverview() {
  const cardData = [
    {
      title: 'New Enquiries',
      backgroundColor: '#fcdcb6',
      iconBackgroundColor: '#FB6D3E',
      value: 0,
      lineImage: '/new_EnquiryLine.svg',
      iconImage: '/new_Enquiry.svg',
    },
    {
      title: 'Demo Classes',
      backgroundColor: '#b6fcbd',
      iconBackgroundColor: '#019E6F',
      value: 0,
      lineImage: '/Demo_Line.svg',
      iconImage: '/demo_classes.svg',
    },
    {
      title: 'Student Enrolled',
      backgroundColor: '#d6e0ff',
      iconBackgroundColor: '#2F5EED',
      value: 0,
      lineImage: '/Student_Eline.svg',
      iconImage: '/student_enrolled.svg',
    },
  ];



// Donut Chart
  const updatedSeriesData = [
    {  present: 1, absent:  0 },
  ]

 

  const [donutChartData, setDonutChartData] = useState({
    series: [],
    options: {
      chart: {
        width: 380,
        type: 'donut',
      },

      plotOptions: {
        pie: {
          donut: {
            size: '80%',
            customScale: .1,
            labels: {
              show: false,
              name: {
                show: false,

              },
              value: {
                show: true, // Show value inside the donut chart
                formatter: function (val) {
                  return `${val}% completed`;
                },
              },
            }
          }
        },


      },


      dataLabels: {

        enabled: false,
        formatter: function (val, opts) {
          // Show data label only for the 'Team E' segment
          if (opts.seriesIndex === 0) {
            return '';
          } else {

            return `${val}%`;
          }
        },


      },
      legend: {
        show: false, // Hide legend
      },

      colors: ['#01BE85', '#F95F4B',],
      responsive: [{

        options: {
          chart: {
            width: 10
          },

          legend: {
            show: false,


          }
        }
      }],
      tooltip: {
        enabled: false,
      },

    
    },

  });

  const presentAbsentArray = updatedSeriesData.map(data => [data.present, data.absent]).flat();

  const getTotalValue = () => {
    return presentAbsentArray.reduce((sum, value) => sum + value, 0);
  };
  useEffect(() => {
 
    setDonutChartData((prevData) => ({
      ...prevData,
   
      series: presentAbsentArray,
    }));
  });

  // Column Chart
  const newData = [
    { name: 'Expenses', data: [50, 65, 75, 80, 70, 85, 95] },
    { name: 'Revenue', data: [80, 90, 110, 105, 95, 115, 100] },
    { name: 'Profit', data: [40, 45, 42, 30, 50, 55, 60] },
  ];
  const [chartData, setChartData] = useState({
    series: [],
    options: {
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '55%',
          endingShape: 'rounded',
          roundedBars: true,

        },
      },
      dataLabels: {
        enabled: false,
      },

      stroke: {
        show: true,
        width: 2,
        colors: ['transparent'],
      },

      xaxis: {
        categories: ['Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday', 'Sunday'],
      },
      yaxis: {
        title: {
          // text: '$ (thousands)',
        },
      },
      fill: {
        opacity: 1,
        colors: ['#2F5EED', '#FF9E69', '#04C088'],
      },
      tooltip: {
        enabled: false,
        y: {
          formatter: function (val) {
            return `$ ${val} thousands`;
          },
        },
      },
    },
  });

  useEffect(() => {
    setChartData((prevData) => ({
      ...prevData,
      series: newData.map(({ name, data }) => ({ name, data })),
    }));
  });

  const packageData = [
    {
     
      subtitle: "IELTS (International English Language Testing System)1",
      price: "₹ 3000/-",
      purchases: 0,
    },
    {
     
      subtitle: "IELTS (International English Language Testing System)2",
      price: "₹ 2500/-",
      purchases: 0,
    },

    {
     
      subtitle: "IELTS (International English Language Testing System)3",
      price: "₹ 2500/-",
      purchases: 0,
    },
    {
     
      subtitle: "IELTS (International English Language Testing System)4",
      price: "₹ 2500/-",
      purchases: 0,
    },
  ];

  
    const [displayedPackages, setDisplayedPackages] = useState(3); // Initially display 2 items
    const maxDisplayedPackages = packageData.length;
    const handleToggleDisplay = () => {
      setDisplayedPackages((prev) => (prev === 3 ? maxDisplayedPackages : 3));
    };
  return (
    <>
      <Box className="box" sx={{ height: '1086px', width: '100%', position: 'relative', color: ''  }}>
        {/* Gradient Background */}
        <Box

          className="rectangle"
          sx={{
            background: 'linear-gradient(248deg, #FFF6F0 -41.57%, rgba(255, 246, 240, 0.00) 97.88%)',
            height: '100%',
            position: 'absolute',
            top: 0,
            left: 0,
            width: '100%',
            zIndex: -1,
          }}
        />

        <Box
          style={{ textAlign: "left" }}
          sx={{
            position: 'absolute',
            // top: '40%',
            // left: '40%',
            // transform: 'translate(-50%, -50%)',
            textAlign: 'center',
            zIndex: 1,
          }}
        >
          <Grid container spacing={2}>
          

            {cardData.map((data, index) => (
              <LeadWidget key={index} {...data} />
            ))}



            <Grid item xs={12} lg={3} md={6} sm={6}>
              <MainCard>

                <Stack display='flex' flexDirection='column' justifyContent='center' alignItems='center'>
                  <Typography sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '24px', fontStyle: 'normal', fontWeight: 600 }}>Active Students</Typography>
                  <Box style={{ textAlign: 'center', position: 'relative' }}>
                    <Box style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
      <Typography sx={{color:'#000' , fontFamily:'Poppins' , fontSize:'40px', fontStyle:'normal',fontWeight:700}} >
        {getTotalValue()}
      </Typography >
      <Typography sx={{color:'#000' , fontFamily:'Poppins' ,fontSize:'normal' , fontWeight:400}}>Total No. of Students</Typography>
      </Box>
      <ReactApexChart options={donutChartData.options} series={donutChartData.series} type="donut" width="280px" />
    </Box>
                 {/* Display series names below the chart */}
     
 
 
                </Stack>
                {updatedSeriesData.map((data)=>(
                  <>
                 <Stack display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
  <Stack>
    <Box sx={{ bgcolor: '#01BE85', width: 10, height: 10, borderRadius:'50%' }}/>
  </Stack>
  <Stack display='flex' flexDirection='row' alignItems='center' width='100%'  justifyContent='space-between' marginLeft={1}>
    <Typography>Presente Student</Typography>
    <Typography>{data.present}</Typography>
  </Stack>
</Stack>
<Stack display='flex' flexDirection='row' alignItems='center' justifyContent='space-between' >
  <Stack>
    <Box sx={{ bgcolor: '#F95F4B', width: 10, height: 10, borderRadius:'50%' }}/>
  </Stack>
  <Stack display='flex' flexDirection='row' alignItems='center' width='100%'  justifyContent='space-between' marginLeft={1}>
    <Typography>Absent Student</Typography>
    <Typography>{data.absent}</Typography>
  </Stack>
</Stack>
                </>
))}
              </MainCard>
            </Grid>

            <Grid item xs={12} lg={9} md={9} sm={12}>
              <MainCard>
                <Stack display='flex'  flexDirection='row' alignItems='center' justifyContent='space-between'>
                <Typography sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '24px', fontStyle: 'normal', fontWeight: 600 }}>Revenue Statistics</Typography>
      <RevenueButton/>
    </Stack>
                <Divider sx={{marginTop:'10px'}}/>
                <ReactApexChart className="rounded-bars-chart" options={chartData.options} series={chartData.series} type="bar" height={350} />
              
              </MainCard>
            </Grid>

            {/* <Grid item xs={12} lg={3} >
              <MainCard>
             <Stack spacing={2}>
                <Typography variant='h2' sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '24px', fontStyle: 'normal', fontWeight: 600 }}>Best Selling Packages 🎉 </Typography>
                <Stack spacing={1}>
                <Typography sx={{ color: '#777', fontFamily: 'Poppins', fontSize: '16px', fontStyle: 'normal', fontWeight: 400 }}>IELTS (International English Language
                  Testing System)</Typography>
                  <Stack display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
                    <Stack display='flex' flexDirection='row' alignContent='center'>
                      <img src='/PackagePrice.svg' />
                <Typography sx={{ color: '#5C5C5C', fontFamily: 'Poppins', fontSize: '12px', fontStyle: 'normal', fontWeight: 400 }}>Price ₹ 3000/-</Typography>
                </Stack>
                <Button style={{ width: '100px', height: '25px', borderRadius: '7px', background: '#019E6F', color: '#FFF', fontFamily: 'Poppins', fontSize: '12px', fontStyle: 'normal', fontWeight: 400 }}>300 Purchases</Button>
                </Stack>
                </Stack>
                </Stack>
              </MainCard>
            </Grid>
        */}

<Grid item xs={12} lg={3} md={12} sm={12}>
      <MainCard  >
        <Stack style={{ maxHeight: '400px', overflowY: 'auto' }} spacing={2}>
        <Stack spacing={2} >
          <Typography variant='h2' sx={{ color: '#000', fontFamily: 'Poppins', fontSize: '24px', fontStyle: 'normal', fontWeight: 600 }}>Best Selling Packages 🎉</Typography>
          {packageData.slice(0, displayedPackages).map((data, index) => (
            <React.Fragment key={index}>
              <PackageCard subtitle={data.subtitle} price={data.price} purchases={data.purchases} />
              {index < displayedPackages - 1 && <Divider flexItem />}
            </React.Fragment>
          ))}
          <Typography sx={{ color: "#019E6F", fontFamily: 'Poppins', fontSize: '14px', fontStyle: 'normal', fontWeight: 500, textAlign: 'center', cursor: 'pointer' }} onClick={handleToggleDisplay}>
            {displayedPackages === 3 ? 'Show More' : 'Show Less'}
          </Typography>
        </Stack>
        </Stack>
      </MainCard>
    </Grid>
          </Grid>
        </Box>

      </Box>
    </>
  )
}
