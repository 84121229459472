import {
  Autocomplete,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  ListItemText,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import CustomSnackbar from "../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
export default function DiscountForm({ onClose }) {
  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const {
    handleSubmit,
    control,
    setValue,
    formState: { errors },
    setError,
    reset,
  } = useForm();

  const userId = localStorage.getItem("userId");
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

  const [reason, setReason] = useState("");
  const [branchlist, setBranchlist] = useState([]);
  const [branch, setBranch] = useState([]);
  const [selectedBranch, setSelectedbranch] = useState([]);
  const [testtypelist, setTesttypeList] = useState([]);
  const [testtype, setTesttype] = useState([]);
  const [selectedtestTypeid, setselectedTestTypeId] = useState([]);
  const [packageslist, setPackagesList] = useState([]);
  const [packagename, setpackagename] = useState([]);
  const [discountType, setDiscountType] = useState("");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [loading, setLoading] = useState(false);

  const noOptionsText = "Loading...";

  console.log(discountType);
  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const reasons = [
    { id: 1, name: "Educational Workshop" },
    { id: 2, name: "Student Discount" },
    { id: 3, name: "Teacher Appreciation Week" },
    { id: 4, name: "Graduation Special" },
    { id: 5, name: "Educational Conference" },
    { id: 6, name: "Scholarship Program" },
  ];

  const handleReasonchange = (e) => {
    setReason(e.target.value);
    setValue("reason_for_creating_discount", e.target.value);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}branch`);
        if (response.ok) {
          const data = await response.json();

          setBranchlist(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const allbranchList = [{ branch_name: "Select All" }, ...branchlist];

  const handelbranchchange = (event, value) => {
    console.log("branch is", value);
    if (value.some((option) => option.branch_name === "Select All")) {
      setBranch(branchlist);
      const ids = branchlist.map((i) => i.id);
      setValue("branch", ids);
    } else {
      const selectebranches = value.map((i) => i.id);
      setBranch(value);
      setValue("branch", selectebranches);
    }
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/getalltesttype`);
        if (response.ok) {
          const data = await response.json();

          setTesttypeList(data);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const alltestTypeList = [{ name: "Select All" }, ...testtypelist];
  const handeltestTypechange = (event, value) => {
    if (value.some((option) => option.name === "Select All")) {
      setTesttype(testtypelist);
      const ids = testtypelist.map((i) => i.id);
      setValue("test_type", ids);
      setselectedTestTypeId(ids);
    } else {
      const selectedtesttype = value.map((i) => i.id);
      setTesttype(value);
      setValue("test_type", selectedtesttype);
      setselectedTestTypeId(selectedtesttype);
    }
  };

  useEffect(() => {
    setLoading(true);
    const requestData = {
      test_type_id: selectedtestTypeid,
    };
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}route/packageoftesttype`, {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
            // add any other headers you need
          },
          body: JSON.stringify(requestData),
        });
        if (response.ok) {
          setLoading(false);
          const result = await response.json();
          setPackagesList(result);
          setLoading(false);
        } else {
          setPackagesList([]);
        }
      } catch (error) {
        setLoading(false);
        console.error("Error fetching data:", error);
      } finally {
        setLoading(false);
      }
    };

    fetchData();
  }, [selectedtestTypeid]);

  const validateDiscountAmount = (value) => {
    console.log("value is ", value);
    if (!value || value === null || value === "") {
      return "Discount percentage is required";
    }

    if (value > 99) {
      return "Discount percentage must be a number between 1 and 99";
    }
    return true;
  };

  const allpackageList = [
    ...(packageslist.length > 1 ? [{ package_name: "Select All" }] : []),
    ...packageslist,
  ];
  const handelpackagechange = (event, value) => {
    if (value.some((option) => option.package_name === "Select All")) {
      setpackagename(packageslist);
      const ids = packageslist.map((i) => i.id);
      setValue("package", ids);
    } else {
      const ids = value.map((i) => i.id);
      setpackagename(value);
      setValue("package", ids);
    }
  };
  const onSubmit = async (data) => {
    try {
      data.discount_amount = parseInt(data.discount_amount);
      data.discount_percentage = parseInt(data.discount_percentage);
      const requestData = {
        ...data,

        user_id: parseInt(userId),
      };

      const response = await fetch(`${API_ENDPOINT}discoutform`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      });
      if (response.ok) {
        showSnackbar("Discount Created Successfully", "success");

        setTimeout(() => {
          window.location.reload()
        }, 1500);
      } else {
        showSnackbar("Failed to Create Discount", "error");
        throw new Error("Failed to Create Discount");
      }
    } catch (error) {
      console.error("Error Creating a Discount:", error);
    }
  };

  return (
    <>
      <Grid container spacing={2}>
        <Grid item xs={12} lg={6} md={6} sm={6}>
          <Typography sx={{ fontWeight: 600 }}>
            Name Of Discount{" "}
            <span style={{ color: "red", fontSize: "18px" }}>*</span>
          </Typography>
          <Controller
            name="name"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                size="small"
                {...field}
                InputProps={{
                  style: { borderRadius: "14px" },
                }}
                fullWidth
                error={errors.name}
                helperText={errors.name && "Discount Name is required"}
              />
            )}
          />
        </Grid>

        <Grid item xs={6} lg={6} md={6} sm={6}>
          <FormControl
            fullWidth
            error={Boolean(errors.reason_for_creating_discount)}
          >
            <Typography sx={{ fontWeight: 600 }}>
              Reason For Creating Discount{" "}
              <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>
            <Controller
              name="reason_for_creating_discount"
              control={control}
              rules={{ required: true }} // Add your validation rules here
              render={({ field }) => (
                <Select
                  size="small"
                  {...field}
                  sx={{ borderRadius: "14px" }}
                  value={reason}
                  onChange={handleReasonchange}
                >
                  {reasons.map((reason) => (
                    <MenuItem key={reason.id} value={reason.name}>
                      {reason.name}
                    </MenuItem>
                  ))}
                </Select>
              )}
            />

            {errors.reason_for_creating_discount && (
              <FormHelperText>
                Reason for creating discount is required
              </FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControl fullWidth error={errors.branch}>
            <Typography sx={{ fontWeight: 600 }}>
              Branch <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>

            <Controller
              name="branch"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                // <Select
                //   {...field}
                //   sx={{ borderRadius: "14px" }}
                //   value={branch}
                //   onChange={handelbranchchange}
                //   MenuProps={MenuProps}
                //   multiple
                //   renderValue={(selected) =>
                //     selected
                //       .map((value) => {
                //         if (value === "all") return "Select All";
                //         const branches = branchlist.find(
                //           (status) => status.id === value
                //         );
                //         return branches ? branches.branch_name : "";
                //       })
                //       .join(", ")
                //   }
                // >
                //   <MenuItem key="select-all" value="all">
                //     <Checkbox checked={branch.length === branchlist.length} />
                //     <ListItemText primary="Select All" />
                //   </MenuItem>
                //   {branchlist.map((name) => (
                //     <MenuItem key={name.id} value={name.id}>
                //       <Checkbox checked={branch.indexOf(name.id) > -1} />
                //       <ListItemText primary={name.branch_name} />
                //     </MenuItem>
                //   ))}
                // </Select>

                <Autocomplete
                  size="small"
                  disablePortal
                  {...field}
                  value={branch}
                  multiple
                  options={allbranchList}
                  getOptionLabel={(option) => option.branch_name}
                  onChange={handelbranchchange}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "12px" },
                      }}
                      fullWidth
                    />
                  )}
                />
              )}
            />
            {errors.branch && (
              <FormHelperText>Branch is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControl fullWidth error={errors.test_type}>
            <Typography sx={{ fontWeight: 600 }}>
              Test Type{" "}
              <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>

            <Controller
              name="test_type"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                // <Select
                //   {...field}
                //   sx={{ borderRadius: "14px" }}
                //   value={testtype}
                //   onChange={handeltestTypechange}
                //   MenuProps={MenuProps}
                //   multiple
                //   renderValue={(selected) =>
                //     selected
                //       .map((value) => {
                //         if (value === "all") return "Select All";
                //         const branches = testtypelist.find(
                //           (status) => status.id === value
                //         );
                //         return branches ? branches.name : "";
                //       })
                //       .join(", ")
                //   }
                // >
                //    <MenuItem key="select-all" value="all">
                //     <Checkbox checked={testtype.length ===testtypelist.length} />
                //     <ListItemText primary="Select All" />
                //   </MenuItem>
                //   {testtypelist.map((item) => (
                //     <MenuItem key={item.id} value={item.id}>
                //       <Checkbox checked={testtype.indexOf(item.id) > -1} />
                //       <ListItemText primary={item.name} />
                //     </MenuItem>
                //   ))}
                // </Select>
                <Autocomplete
                  {...field}
                  size="small"
                  disablePortal
                  value={testtype}
                  multiple
                  onChange={handeltestTypechange}
                  options={alltestTypeList}
                  getOptionLabel={(option) => option.name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "15px" },
                      }}
                    />
                  )}
                />
              )}
            />
            {errors.test_type && (
              <FormHelperText>Test Type is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControl fullWidth error={errors.package}>
            <Typography sx={{ fontWeight: 600 }}>
              Package <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>

            <Controller
              name="package"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                // <Select
                //   {...field}
                //   sx={{ borderRadius: "14px" }}
                //   value={packagename}
                //   onChange={handelpackagechange}
                //   MenuProps={MenuProps}
                //   multiple
                //   renderValue={(selected) =>
                //     selected
                //       .map((value) => {
                //         if (value === "all") return "Select All";
                //         const branches = packageslist.find(
                //           (status) => status.id === value
                //         );
                //         return branches ? branches.package_name: "";
                //       })
                //       .join(", ")
                //   }
                // >
                //     <MenuItem key="select-all" value="all">
                //     <Checkbox checked={packagename.length ===packageslist.length} />
                //     <ListItemText primary="Select All" />
                //   </MenuItem>
                //   {packageslist.map((name) => (
                //     <MenuItem key={name.id} value={name.id}>
                //       <Checkbox checked={packagename.indexOf(name.id) > -1} />
                //       <ListItemText primary={name.package_name} />
                //     </MenuItem>
                //   ))}
                // </Select>

                <Autocomplete
                  {...field}
                  disablePortal
                  size="small"
                  noOptionsText={
                    loading && packageslist.length === 0
                      ? noOptionsText
                      : "Please Select  Test Type First"
                  }
                  value={packagename}
                  multiple
                  onChange={handelpackagechange}
                  options={allpackageList}
                  getOptionLabel={(option) => option.package_name}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      InputProps={{
                        ...params.InputProps,
                        style: { borderRadius: "15px" },
                        endAdornment: (
                          <>
                            {loading ? (
                              <CircularProgress color="inherit" size={20} />
                            ) : null}
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                    />
                  )}
                />
              )}
            />
            {errors.package && (
              <FormHelperText>Package is required</FormHelperText>
            )}
          </FormControl>
        </Grid>

        <Grid item xs={12} lg={6}>
          <Typography sx={{ fontWeight: 600 }}>
            Start Date <span style={{ color: "red", fontSize: "18px" }}>*</span>
          </Typography>
          <Controller
            name="start_date"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                size="small"
                {...field}
                InputProps={{
                  style: { borderRadius: "14px" },
                }}
                type="date"
                fullWidth
                error={errors.start_date}
                helperText={errors.start_date && "  Start Date is required"}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <Typography sx={{ fontWeight: 600 }}>
            End Date <span style={{ color: "red", fontSize: "18px" }}>*</span>
          </Typography>
          <Controller
            name="end_date"
            control={control}
            defaultValue=""
            rules={{ required: true }}
            render={({ field }) => (
              <TextField
                size="small"
                {...field}
                InputProps={{
                  style: { borderRadius: "14px" },
                }}
                type="date"
                fullWidth
                error={errors.end_date}
                helperText={errors.end_date && " End Date is required"}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={6}>
          <FormControl
            component="fieldset"
            error={Boolean(errors.type_of_discount)}
          >
            <Typography sx={{ fontWeight: 600 }}>
              Type of Discount{" "}
              <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>
            <Controller
              name="type_of_discount"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <RadioGroup {...field} row>
                  <FormControlLabel
                    value="cash"
                    control={
                      <Radio
                        color="primary"
                        onClick={() => setDiscountType("cash")}
                      />
                    }
                    label="Cash"
                  />
                  <FormControlLabel
                    value="percentage"
                    control={
                      <Radio onClick={() => setDiscountType("percentage")} />
                    }
                    label="Percentage"
                  />
                </RadioGroup>
              )}
            />
            {errors.type_of_discount && (
              <FormHelperText>Please select a type of discount</FormHelperText>
            )}
          </FormControl>
        </Grid>

        {discountType === "cash" && (
          <Grid item xs={12} lg={6}>
            <Typography sx={{ fontWeight: 600 }}>
              Cash Amount{" "}
              <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>
            <Controller
              name="discount_amount"
              control={control}
              defaultValue=""
              rules={{ required: true }}
              render={({ field }) => (
                <TextField
                  size="small"
                  {...field}
                  InputProps={{
                    style: { borderRadius: "14px" },
                  }}
                  type="number"
                  fullWidth
                  error={errors.discount_amount}
                  helperText={
                    errors.discount_amount && "Cash Amount is required"
                  }
                />
              )}
            />
          </Grid>
        )}

        {discountType === "percentage" && (
          <Grid item xs={12} lg={6}>
            <Typography sx={{ fontWeight: 600 }}>
              Discount Percentage{" "}
              <span style={{ color: "red", fontSize: "18px" }}>*</span>
            </Typography>
            <Controller
              name="discount_percentage"
              control={control}
              defaultValue=""
              rules={{
                required: "Discount percentage is required",
                validate: validateDiscountAmount,
              }}
              render={({ field }) => (
                <TextField
                  size="small"
                  {...field}
                  InputProps={{
                    style: { borderRadius: "14px" },
                  }}
                  type="number"
                  fullWidth
                  error={errors.discount_percentage}
                  helperText={
                    errors.discount_percentage
                      ? errors.discount_percentage.message
                      : ""
                  }
                />
              )}
            />
          </Grid>
        )}
        <Grid item xs={12} lg={12}>
          <Typography sx={{ fontWeight: 600 }}>Remark</Typography>
          <Controller
            name="remarks"
            control={control}
            defaultValue=""
            render={({ field }) => (
              <TextField
                {...field}
                InputProps={{
                  style: { borderRadius: "14px" },
                }}
                fullWidth
                multiline
                rows={4}
              />
            )}
          />
        </Grid>

        <Grid item xs={12} lg={12} sx={{ textAlign: "end" }}>
          <Button
            variant="contained"
            color="error"
            style={{
              width: "120px",
              borderRadius: "14px",
              fontWeight: 500,
              fontSize: "16px",
              marginRight: "8px",
            }}
            onClick={onClose}
          >
            Cancel
          </Button>

          <Button
            variant="contained"
            color="primary"
            style={{
              width: "120px",
              borderRadius: "14px",
              fontWeight: 500,
              fontSize: "16px",
            }}
            onClick={handleSubmit(onSubmit)}
          >
            Submit
          </Button>
        </Grid>
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
