import React, { useState, useEffect } from "react";
import {
  FormControl,
  Typography,
  Select,
  MenuItem,
  TextField,
  Button,
  RadioGroup,
  FormControlLabel,
  Radio,
  Box,
  CircularProgress,
  Alert,
  Grid,
  Card,
  useTheme,
  CardContent,
  Autocomplete,
  FormLabel,
  FormHelperText,
  duration,
} from "@mui/material";
import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";
import OtherTestTypeComp from "./OtherTestTypeComp";
import GreGmatSatComp from "./GreGmatSatComp";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function EnquiryBookDemoForm({ enquiryData, onClose }) {
  const theme = useTheme();
  console.log("enquiryData", enquiryData);
  const userId = localStorage.getItem("userId");
  const [studentInfo, setStudentInfo] = useState(enquiryData || {});
  const [formData, setFormData] = useState({
    studentId: enquiryData ? enquiryData.id : null,
    studentName: enquiryData ? enquiryData.first_name : "",

    subTestTypeName: enquiryData
      ? enquiryData.sub_test_type_id?.id || enquiryData.sub_test_type_id
      : null,
    testDeliveryFormat: enquiryData
      ? enquiryData.test_delivery_format_id?.id ||
        enquiryData.test_delivery_format_id
      : null,
    deliveryMode: "online",
    branch_id: enquiryData ? enquiryData.branch_id?.id : null,
    demo_date: null,
    note: null,
    fromTime: null,
    toTime: null,
    exam_already_given: null,
    score: null,
    band_score_required: null,
    is_exam_booked: false,
    tentative_date: null,
    preparation: null,
    duration: null,
    institute_name: null,
  });

  const [testType, setTestType] = useState(
    enquiryData
      ? enquiryData.test_type_id?.id || enquiryData.test_type_id
      : null
  );
  const [selectedClassData, setSelectedClassData] = useState(null);
  const [testTypeList, setTestTypeList] = useState([]);
  const [branches, setBranches] = useState([]);
  const [demoDates, setDemoDates] = useState([]);
  const [selectedDate, setSelectedDate] = useState("");
  const [trainerSlots, setTrainerSlots] = useState([]);
  const [errorMessage, setErrorMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [testtypeLoading, setTestTypeLoading] = useState(true);
  const [subTestType, setSubtestType] = useState([]);
  const [selectedTestTypeId, setSelectedTestTypeId] = useState(null);

  const [subTestTypeFormat, setSubtestTypeFormat] = useState([]);
  const [classType, setClassType] = useState("manual");
  const [ismanual, setIsmanual] = useState(true);
  const [trainerList, setTrainerList] = useState([]);
  const [selectedTrainerList, setSelectedTrainerList] = useState([]);
  const [trainerName, settrainerName] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const [errors, setErrors] = useState(false);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  console.log(formData);

  //  Change values
  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleChangeTestType = (e) => {
    setTrainerSlots([]);

    setTestType(e.target.value);
    const trainerlist = trainerList.filter((trainer) =>
      trainer.trainer_expertise.includes(e.target.value)
    );
    setSelectedTrainerList(trainerlist);
  };
  //  Search Demos by date
  const handleSearchDemoDates = () => {
    if (!formData.demo_date) {
      showSnackbar("Please select a date to view slots", "error");
      return;
    }
    setTrainerSlots([]); // Clear trainer slots when searching for training
    setLoading(true);

    const requestData = {
      enquiry_id: formData.studentId,
      //student_name: formData.studentName,
      test_type_id: testType,
      delivery_mode: formData.deliveryMode,
      branch_id: formData.branch_id,
      available_demo_date: formData.demo_date,
      same_trainer: null,
      note: formData.note,
      sub_test_type_id:
        testType === "IELTS" || testType === "PTE"
          ? formData.subTestTypeName
          : null,
      test_delivery_format_id:
        testType === "IELTS" ? formData.testDeliveryFormat : null,
    };

    console.log(requestData);

    // Make the POST request to your API endpoint
    fetch(`${API_ENDPOINT}advisordashboard/searchdemo`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(requestData),
    })
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        } else if (response.status === 403) {
          throw new Error(
            "Couldn't find any slot for this test type, try next day!!"
          );
        } else if (response.status === 404) {
          throw new Error("Cannot return data, 404");
        } else {
          throw new Error("Unexpected error occurred");
        }
      })
      .then((data) => {
        console.log(data);
        if (data && data.length > 0) {
          setTrainerSlots(data);
          setErrorMessage("");
          setLoading(false);
        } else {
          setTrainerSlots([]);
          setErrorMessage("No slots found for the day"); // Set the error message
          setLoading(false);
        }
      })
      .catch((error) => {
        setErrorMessage(error.message); // Set the error message in state
        console.error("Error:", error);
        setLoading(false);
      });
  };

  const validateForm = () => {
    const newErrors = {};

    if (
      selectedTestTypeId?.name !== "GMAT" &&
      selectedTestTypeId?.name !== "GRE" &&
      selectedTestTypeId?.name !== "SAT"
    ) {
      // Apply validation only for non-GMAT, non-GRE, non-SAT test types
      newErrors.exam_already_given = !formData.exam_already_given;
      newErrors.score =
        !formData.score && formData.exam_already_given === "Yes";
      newErrors.preparation = !formData.preparation;
      newErrors.band_score_required =
        !formData.band_score_required && formData.exam_already_given === "No";
      newErrors.is_exam_booked = !formData.is_exam_booked;
    }

    setErrors(newErrors);
    // Return true if there are no errors
    return !Object.values(newErrors).some((error) => error);
  };

  //  Book Demo

  const handleBookDemo = () => {
    if (validateForm()) {
      if (!formData.selectedSlot && classType === "date") {
        // Display an error message if trainer or slot is not selected
        showSnackbar("Please select a slot to book the demo", "error");
        return;
      }

      const requestData = {
        enquiry_id: formData.studentId,
        //student_name: formData.studentName,
        test_type_id: testType,
        delivery_mode: formData.deliveryMode,
        branch_id: formData.branch_id,
        available_demo_date: formData.demo_date,
        note: formData.note,
        slot:
          classType === "date"
            ? selectedClassData.slot
            : `${formData.fromTime}`,
        duration: formData.duration || null,
        class_id: formData.selectedSlot,
        trainer_id:
          classType === "date" ? selectedClassData.trainer_id : trainerName?.id,
        user_id: parseInt(userId),
        sub_test_type_id:
          selectedTestTypeId?.name === "IELTS" ||
          selectedTestTypeId?.name === "PTE"
            ? formData.subTestTypeName
            : null,
        test_delivery_format_id:
          selectedTestTypeId?.name === "IELTS"
            ? formData.testDeliveryFormat
            : null,
        exam_already_given: formData.exam_already_given || null,
        score: parseInt(formData.score),

        band_score_required: parseInt(formData.band_score_required),
        is_exam_booked: formData.is_exam_booked || null,
        tentative_date: formData.tentative_date,
        preparation: formData.preparation,
        institute_name: formData.institute_name,
        is_manual: ismanual,
      };
      console.log(requestData);

      // Make the POST request to your API endpoint for booking
      fetch(`${API_ENDPOINT}demo`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(requestData),
      })
        .then((response) => {
          if (response.status === 201) {
            // Demo booked successfully, you can handle success as needed
            //  alert('Demo booked successfully');
            showSnackbar("Demo booked successfully", "success");
            //onClose()
            // setTimeout(() => {
            //   window.location.reload();
            // }, 1000);
          } else if (response.status === 409) {
            // alert('Demo already exists for this student')
            showSnackbar("Demo already exists for this student", "error");
          } else {
            throw new Error("Demo booking failed"); // Handle booking failure
          }
        })
        .catch((error) => {
          showSnackbar("Demo booking failed", "error");
          // alert('Demo booking failed'); // Display an error message for booking failure
          console.error("Error:", error);
        });
    }
  };

  // Fetch Branch list
  useEffect(() => {
    fetch(`${API_ENDPOINT}branch`)
      .then((response) => response.json())
      .then((data) => {
        setBranches(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

  // Fetch Testtype list
  useEffect(() => {
    setTestTypeLoading(true);
    fetch(`${API_ENDPOINT}advisordashboard/gettesttype`)
      .then((response) => response.json())
      .then((data) => {
        setTestTypeList(data);
        setTestTypeLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching task items:", error);
        setTestTypeLoading(false);
      });
  }, []);

  // Fetch sub testType List
  useEffect(() => {
    fetch(`${API_ENDPOINT}route2.0/getsubtesttype/${testType}`)
      .then((response) => response.json())
      .then((data) => {
        setSubtestType(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching testtypes:", error);
        setSubtestType([]);
      });
  }, [testType]);

  console.log("formData.testType", formData.testType);

  //get testtype by selected name
  useEffect(() => {
    if (testType !== null) {
      const testTypedata = testTypeList.find((i) => i.id === testType);
      setSelectedTestTypeId(testTypedata);
    }
  }, [testType]);

  // Get delivery format list
  useEffect(() => {
    fetch(`${API_ENDPOINT}testdeliveryformat`)
      .then((response) => response.json())
      .then((data) => {
        setSubtestTypeFormat(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching testtypes:", error);
      });
  }, []);

  const handleCriteria = (criteria) => {
    setFormData({
      ...formData,
      fromTime: null,
      toTime: null,
      selectedSlot: null,
    });
    settrainerName(null);
    setClassType(criteria);
    setTrainerSlots([]);

    if (criteria === "date") {
      handleSearchDemoDates();
      setIsmanual(false);
    } else {
      setIsmanual(true);
    }
  };

  // Fatch TrainerList
  useEffect(() => {
    // Fetch employee data
    fetch(`${API_ENDPOINT}admindashboard/gettrainer`)
      .then((response) => response.json())
      .then((data) => {
        console.log(data);

        setTrainerList(data);
      })
      .catch((error) => {
        console.error("Error fetching employee data:", error);
      });
  }, []);

  const handleChangeTrainer = (event, value) => {
    settrainerName(value);
  };

  console.log("institute_name", formData.institute_name);
  return (
    <>
      {testtypeLoading ? (
        <Typography sx={{ fontSize: "16px" }}>Loading.....</Typography>
      ) : (
        <form>
          <Grid container spacing={2}>
            <Grid item xs={12} lg={4}>
              <Typography>Student Name</Typography>
              <TextField
                size="small"
                name="studentName"
                fullWidth
                value={formData.studentName}
                //onChange={handleChange}
                required
              />
            </Grid>

            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <Typography>Test Type</Typography>
                <Select
                  size="small"
                  value={testType}
                  onChange={handleChangeTestType}
                  required
                >
                  {testTypeList.map((testType) => (
                    <MenuItem key={testType.id} value={testType.id}>
                      {testType.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>

            {selectedTestTypeId?.name === "IELTS" && (
              <>
                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth>
                    <Typography>Sub Test Type</Typography>
                    <Select
                      size="small"
                      name="subTestTypeName"
                      value={formData.subTestTypeName}
                      onChange={handleChange}
                      required
                    >
                      {subTestType.map((subtestType) => (
                        <MenuItem key={subtestType.id} value={subtestType.id}>
                          {subtestType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>

                <Grid item xs={12} lg={4}>
                  <FormControl fullWidth>
                    <Typography> Test Delivery Format</Typography>
                    <Select
                      size="small"
                      name="testDeliveryFormat"
                      value={formData.testDeliveryFormat}
                      onChange={handleChange}
                      required
                    >
                      {subTestTypeFormat.map((subtestType) => (
                        <MenuItem key={subtestType.id} value={subtestType.id}>
                          {subtestType.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
              </>
            )}

            {selectedTestTypeId?.name === "PTE" && (
              <Grid item xs={12} lg={4}>
                <FormControl fullWidth>
                  <Typography>Sub Test Type</Typography>
                  <Select
                    size="small"
                    name="subTestTypeName"
                    value={formData.subTestTypeName}
                    onChange={handleChange}
                    required
                  >
                    {subTestType.map((subtestType) => (
                      <MenuItem key={subtestType.id} value={subtestType.id}>
                        {subtestType.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} lg={4}>
              <FormControl fullWidth>
                <Typography>Delivery Mode</Typography>
                <Select
                  size="small"
                  name="deliveryMode"
                  value={formData.deliveryMode}
                  onChange={handleChange}
                >
                  <MenuItem value="online">Online</MenuItem>
                  <MenuItem value="offline">Offline</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {formData.deliveryMode === "offline" && (
              <Grid item xs={12} lg={4}>
                <FormControl fullWidth>
                  <Typography>Branch</Typography>
                  <Select
                    size="small"
                    name="branch_id"
                    value={formData.branch_id}
                    onChange={handleChange}
                    required
                  >
                    {branches.map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>
                        {branch.branch_name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            )}

            <Grid item xs={12} lg={4}>
              <Typography>Demo Date</Typography>
              <TextField
                size="small"
                name="demo_date"
                //label="Select Date"
                type="date"
                fullWidth
                InputProps={{
                  inputProps: { min: new Date().toISOString().split("T")[0] },
                }}
                value={formData.demo_date}
                onChange={handleChange}
                required
              />
            </Grid>

            {selectedTestTypeId !== null &&
            (selectedTestTypeId?.name === "GRE" ||
              selectedTestTypeId?.name === "GMAT" ||
              selectedTestTypeId?.name === "SAT") ? (
              <GreGmatSatComp
                handleCriteria={handleCriteria}
                formData={formData}
                handleChange={handleChange}
                classType={classType}
                trainerName={trainerName}
                loading={loading}
                handleChangeTrainer={handleChangeTrainer}
                trainerSlots={trainerSlots}
                setSelectedClassData={setSelectedClassData}
                trainerList={selectedTrainerList}
                handleBookDemo={handleBookDemo}
                ButtonName="Book Demo"
              />
            ) : selectedTestTypeId !== null &&
              (selectedTestTypeId?.name !== "GRE" ||
                selectedTestTypeId?.name !== "GMAT" ||
                selectedTestTypeId?.name !== "SAT") ? (
              <OtherTestTypeComp
                formData={formData}
                handleChange={handleChange}
                handleCriteria={handleCriteria}
                setSelectedClassData={setSelectedClassData}
                loading={loading}
                errors={errors}
                trainerSlots={trainerSlots}
                handleBookDemo={handleBookDemo}
                ButtonName="Book Demo"
              />
            ) : (
              " "
            )}

            {errorMessage && <Alert variant="error">{errorMessage}</Alert>}
          </Grid>
        </form>
      )}
      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}

export default EnquiryBookDemoForm;
