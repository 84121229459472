// Author : Ayesha Shaikh
//  Date : 03 Oct 2024

import {
  Box,
  Button,
  Checkbox,
  Chip,
  Divider,
  Grid,
  IconButton,
  styled,
} from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React, { useEffect, useState } from "react";
import { fDate } from "../../../utils/formatTime";
import CallIcon from "@mui/icons-material/Call";
import CustomSnackbar from "../../components/ui-component/snackbar/CustomSnackbar";
import EmployeeTaskFilter from "./EmployeeTaskFilter";
import { Person as PersonIcon } from "@mui/icons-material";
const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

export default function EmployeeTaskData({
  taskData,
  settaskData,
  setLoading,
  originalTaskData,
  selectedId,
  selectedtransferIds,
  setSelectedTransferIds,
  isSelectAllChecked,
  setIsSelectAllChecked,
}) {
  const userId = localStorage.getItem("userId");
  const [snackbarOpen, setSnackbarOpen] = React.useState(false);
  const [snackbarMessage, setSnackbarMessage] = React.useState("");
  const [snackbarSeverity, setSnackbarSeverity] = React.useState("success");
  const [pagination, setPagination] = useState({
    page: 0,
    pageSize: 100,
  });

  const [visibleRowIds, setVisibleRowIds] = useState([]); // Store current page's row IDs
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [prevPageSize, setPrevPageSize] = useState(pagination.pageSize);

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const handleClickToCall = (id) => {
    const data = {
      user_id: parseInt(userId),
      enquiry_id: id,
    };

    console.log(data);

    // Access the authorization token from environment variables
    const authToken = process.env.REACT_APP_CALL_TOKEN;

    // Send the POST request to the server
    fetch(`${API_ENDPOINT}clicktocall`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          console.log("Success");
          showSnackbar("Call Connected", "success");
        } else {
          console.log("Error:", response);
          showSnackbar("Call Not Connected", "error");
        }
      })
      .catch((error) => {
        console.error("Error submitting data:", error);
        showSnackbar("Call Not Connected", "error");
      });
  };

  // Upadate current page ids when pagination is chnages
  useEffect(() => {
    setPrevPageSize(pagination.pageSize);

    // new pagesize is less than prev page size then deselct all selected ids first
    if (pagination.pageSize !== prevPageSize) {
      setSelectedTransferIds([]);
      return;
    }

    // Update visible row IDs when pagination changes
    const currentPageRows = taskData.slice(
      pagination.page * pagination.pageSize,
      (pagination.page + 1) * pagination.pageSize
    );
    const visibleIds = currentPageRows.map((row) => row.id);
    setVisibleRowIds(visibleIds);

    // Check if all visible rows are selected
    const allVisibleSelected = visibleIds.every((id) =>
      selectedtransferIds.includes(id)
    );
    const someVisibleSelected = visibleIds.some((id) =>
      selectedtransferIds.includes(id)
    );

    setIsSelectAllChecked(allVisibleSelected);
    setIsIndeterminate(someVisibleSelected && !allVisibleSelected);
  }, [pagination, taskData, selectedtransferIds]);

  // HandleSelectAllCheckBox
  const handleSelectAllChange = (event) => {
    const checked = event.target.checked;
    setIsSelectAllChecked(checked);

    if (checked) {
      // Select all visible row IDs
      setSelectedTransferIds((prevSelectedIds) => [
        ...new Set([...prevSelectedIds, ...visibleRowIds]),
      ]);
    } else {
      // Deselect all visible row IDs
      setSelectedTransferIds((prevSelectedIds) =>
        prevSelectedIds.filter((id) => !visibleRowIds.includes(id))
      );
    }
  };

  // Individual CheckBox
  const handleCheckboxChange = (event, id) => {
    const checked = event.target.checked;

    setSelectedTransferIds((prevSelectedIds) => {
      if (checked) {
        const newSelected = [...prevSelectedIds, id];
        // Check if all visible rows are selected after this checkbox is checked
        const allVisibleSelected = visibleRowIds.every((rowId) =>
          newSelected.includes(rowId)
        );
        setIsSelectAllChecked(allVisibleSelected);
        setIsIndeterminate(!allVisibleSelected);

        return newSelected;
      } else {
        // Deselect the row ID
        const newSelected = prevSelectedIds.filter((rowId) => rowId !== id);
        setIsSelectAllChecked(false);
        setIsIndeterminate(
          visibleRowIds.some((rowId) => newSelected.includes(rowId))
        );

        return newSelected;
      }
    });
  };

  const columns = [
    {
      field: "selection",
      sortable: false,
      renderHeader: (params) => (
        <Checkbox
          size="small"
          checked={isSelectAllChecked}
          indeterminate={isIndeterminate}
          onChange={handleSelectAllChange}
        />
      ),

      width: 70,
      renderCell: (params) => {
        const isChecked = selectedtransferIds.includes(params.row.id);
        return (
          <Checkbox
            size="small"
            checked={isChecked}
            onChange={(event) => handleCheckboxChange(event, params.row?.id)}
          />
        );
      },
    },

    {
      field: "name",
      headerName: "Enquiry Name",
      width: 150,
      headerClassName: "header-cell",
      renderCell: (params) => {
        console.log("Cell Renderer - params.row:", params.row);
        return (
          <div>
            <Button
              size="small"
              onClick={() =>
                window.open(
                  `/enquirydetails/${params?.row?.enquiry_id?.id}`,
                  "_blank"
                )
              }
            >
              <PersonIcon />
              {params.row.enquiry_id?.first_name}{" "}
              {params.row.enquiry_id?.last_name}
            </Button>
          </div>
        );
      },
    },

    {
      field: "taskType",
      headerName: "Type of Task",
      width: 150,
      headerClassName: "header-cell",
      filterable: true,
      renderCell: (params) => (
        <div>{params.row.type_of_task?.name_of_task_type}</div>
      ),
    },
    {
      field: "campaign_name",
      headerName: "Campaign Name",
      width: 350,
      headerClassName: "header-cell",
      filterable: true,
      renderCell: (params) => (
        <div>{params.row.campaign_id?.campaign_name}</div>
      ),
    },
    {
      field: "due_date",
      headerName: "Due Date",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params.value)}</div>,
    },
    {
      field: "stage",
      headerName: "Stage",
      width: 150,
      headerClassName: "header-cell",
      filterable: true,
      renderCell: (params) => (
        <div>{params.row.enquiry_id?.stage_id?.stage_name}</div>
      ),
    },
    {
      field: "test_type",
      headerName: "Sub Service",
      width: 150,
      headerClassName: "header-cell",
      filterable: true,
      renderCell: (params) => (
        <div>{params.row.enquiry_id?.sub_service_id?.name}</div>
      ),
    },
    {
      field: "followupreason",
      headerName: "Follow Up Reason",
      width: 200,
      headerClassName: "header-cell",
      filterable: true,
      renderCell: (params) => (
        <div>{params.row.follow_up_reason_id?.follow_up_reason}</div>
      ),
    },
    {
      field: "taskOwner",
      headerName: "Task Owner",
      width: 150,
      headerClassName: "header-cell",
      filterable: true,
      renderCell: (params) => <div>{params.row.task_owner?.first_name}</div>,
    },
    {
      field: "createdBy",
      headerName: "Created By",
      width: 150,
      headerClassName: "header-cell",
      filterable: true,
      renderCell: (params) => <div>{params.row.created_by?.first_name}</div>,
    },
    {
      field: "createdAt",
      headerName: "Created Date",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{fDate(params.value)}</div>,
    },
    {
      field: "status",
      headerName: "Status",
      width: 100,
      headerClassName: "header-cell",
      renderCell: (params) => {
        let chipColor = "";
        let chipLabel = "";

        switch (params.value) {
          case 0:
            chipColor = "secondary";
            chipLabel = "Closed";
            break;
          case 1:
            chipColor = "error";
            chipLabel = "Open";
            break;
          default:
            chipColor = "orange";
            chipLabel = "Unknown";
        }

        return (
          <Chip
            label={chipLabel}
            color={chipColor === "default-color" ? "default" : chipColor}
          />
        );
      },
    },
    {
      field: "closedBy",
      headerName: "Closed By",
      width: 150,
      headerClassName: "header-cell",
      filterable: true,
      renderCell: (params) => <div>{params.row.closed_by?.first_name}</div>,
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }
  return (
    <>
      <Grid item xs={12} sm={8.5}>
        <Box
          sx={{
            height: "600px",
            overflow: "auto",
            width: "100%",
            "& .header-cell": {
              //  backgroundColor: "#abf7b1",
              fontWeight: 600,
              // borderRight: "0.1px solid #C3C3C3",
            },
          }}
        >
          <DataGrid
            rows={taskData}
            columns={columns}
            slots={{
              noRowsOverlay: CustomNoRowsOverlay,
            }}
            autoHeight
            pageSize={pagination.pageSize}
            onPaginationModelChange={(model) => setPagination(model)}
            // sx={{ marginTop: "20px" }}
            pageSizeOptions={[10, 25, 50, 100]}
          />
        </Box>
      </Grid>

      <Divider orientation="vertical" flexItem sx={{ marginLeft: "15px" }} />

      <Grid item xs={12} sm={3.3}>
        <EmployeeTaskFilter
          settaskData={settaskData}
          setLoading={setLoading}
          originalTaskData={originalTaskData}
          selectedId={selectedId}
        />
      </Grid>

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />
    </>
  );
}
