import PropTypes from 'prop-types';
import { useState } from 'react';

// material-ui
import { Button, Grid, IconButton, TextField, Stack } from '@mui/material';

// third-party
//import { sub } from 'date-fns';

// project imports
//import { openSnackbar } from 'store/slices/snackbar';
import SubCard from '../../components/ui-component/card/SubCard'; 
//import { useDispatch, useSelector } from 'store';
//import { addItem } from 'store/slices/kanban';

// assets
import CalendarTodayTwoToneIcon from '@mui/icons-material/CalendarTodayTwoTone';
import PeopleAltTwoToneIcon from '@mui/icons-material/PeopleAltTwoTone';
import CloseIcon from '@mui/icons-material/Close';
import CustomSnackbar from '../../components/ui-component/snackbar/CustomSnackbar';



const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT

// ==============================|| KANBAN BOARD - ADD ITEM ||============================== //

const AddItem = ({studentId}) => {
    //const dispatch = useDispatch();
    const  userId  = localStorage.getItem('userId')

    const [addTaskBox, setAddTaskBox] = useState(false);
    //const { columns, items, userStory } = useSelector((state) => state.kanban);

    const [snackbarOpen, setSnackbarOpen] = useState(false);
   const [snackbarMessage, setSnackbarMessage] = useState('');
   const [snackbarSeverity, setSnackbarSeverity] = useState('success');
   
       const showSnackbar = (message, severity) => {
           setSnackbarMessage(message);
           setSnackbarSeverity(severity);
           setSnackbarOpen(true);
       };

    const handleAddTaskChange = () => {
        setAddTaskBox((prev) => !prev);
    };

    const [title, setTitle] = useState('');
    const [isTitle, setIsTitle] = useState(false);

    const addTask = () => {
        console.log(title)
       if (title.length > 0) {
            const requestData2 = {
                    note: title,
                   // advisor_id: advisorId,
                    enquiry_id: studentId,
                    user_id: parseInt(userId)

                }
                console.log(requestData2);

                fetch(`${API_ENDPOINT}note`, {
                    method: 'POST', 
                    headers: {
                      'Content-Type': 'application/json', 
                    },
                    body: JSON.stringify(requestData2),
                  })
                    .then((response) => {
                      if (response.status === 201) {
                       
                        showSnackbar('Note added successfully', 'success');
                        setTitle('')
                        
                      } else {
                        // API call failed, show error message
                        
                        showSnackbar('Error adding note. Please try again.', 'error');
                      }
                    })
                    .catch((error) => {
                      // Handle network errors or other issues
                      console.error('Error:', error);
                   
                      showSnackbar('A network error occurred. Please try again later.', 'error');
                     
                    });
            handleAddTaskChange();
            setTitle('');
        } else {
            setIsTitle(true);
        }
    };

    const handleAddTask = (event) => {
        if (event.key === 'Enter' || event.keyCode === 13) {
            addTask();
        }
    };

    const handleTaskTitle = (event) => {
        const newTitle = event.target.value;
        setTitle(newTitle);
        if (newTitle.length <= 0) {
            setIsTitle(true);
        } else {
            setIsTitle(false);
        }
    };

    return (
        <>
        <Grid container alignItems="center" spacing={1} sx={{ marginTop: 1 }}>
            {addTaskBox && (
                <Grid item xs={12}>
                    <SubCard contentSX={{ p: 2, transition: 'background-color 0.25s ease-out' }}>
                        <Grid container alignItems="center" spacing={0.5}>
                            <Grid item xs={12}>
                                <TextField
                                    fullWidth
                                    placeholder="Add Note"
                                    value={title}
                                    onChange={handleTaskTitle}
                                    sx={{
                                        mb: 2,
                                        '& input': { bgcolor: 'transparent', p: 0, borderRadius: '0px' },
                                        '& fieldset': { display: 'none' },
                                        '& .MuiFormHelperText-root': {
                                            ml: 0
                                        },
                                        '& .MuiOutlinedInput-root': {
                                            bgcolor: 'transparent'
                                        }
                                    }}
                                    onKeyUp={handleAddTask}
                                    helperText={isTitle ? 'Task title is required.' : ''}
                                    error={isTitle}
                                />
                            </Grid>
                            
                            <Grid item xs zeroMinWidth />
                            <Grid item>
                                <Stack direction="row" alignItems="center" spacing={0.5}>
                                    <IconButton size="small" color="error" onClick={handleAddTaskChange} aria-label="cancel add task">
                                        <CloseIcon fontSize="small" />
                                    </IconButton>
                                    <Button variant="contained" color="primary" onClick={addTask} size="small">
                                        Add
                                    </Button>
                                </Stack>
                            </Grid>
                        </Grid>
                    </SubCard>
                </Grid>
            )}
            {!addTaskBox && (
                <Grid item xs={12}>
                    <Button variant="contained" color="secondary" sx={{ width: '100%' }} onClick={handleAddTaskChange}>
                        Add Note
                    </Button>
                </Grid>
            )}

        <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                severity={snackbarSeverity}
                />
        </Grid>
        </>
    );
};

AddItem.propTypes = {
    columnId: PropTypes.string
};

export default AddItem;