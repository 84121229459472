import React from 'react';
import { useForm, Controller } from 'react-hook-form';
import { TextField, Button, Container, Grid, Typography, Divider, FormControl, InputLabel, Input, FormHelperText } from '@mui/material';
import { Link } from 'react-router-dom';

const RegistrationForm = () => {
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm();

  const onSubmit = (data) => {
    console.log(data);
    // Handle form submission logic here
  };

  return (
    <Container maxWidth="xs">
      <form onSubmit={handleSubmit(onSubmit)}>
        <Grid container spacing={2}>
          
          <Grid item xs={6}>
            <Controller
              name="firstName"
              control={control}
              required
              defaultValue=""
              render={({ field }) => <TextField {...field} label="First Name" fullWidth />}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="lastName"
              control={control}
              defaultValue=""
              render={({ field }) => <TextField {...field} label="Last Name" fullWidth />}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="businessName"
              control={control}
              defaultValue=""
              render={({ field }) => <TextField {...field} label="Business Name" fullWidth />}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="centerName"
              control={control}
              defaultValue=""
              render={({ field }) => <TextField {...field} label="Center Name" fullWidth />}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="address"
              control={control}
              defaultValue=""
              render={({ field }) => <TextField {...field} label="Center Address" fullWidth />}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="pincode"
              control={control}
              defaultValue=""
              render={({ field }) => <TextField {...field} label="Pincode" fullWidth />}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="state"
              control={control}
              defaultValue=""
              render={({ field }) => <TextField {...field} label="State" fullWidth />}
            />
          </Grid>
          <Grid item xs={6}>
            <Controller
              name="city"
              control={control}
              defaultValue=""
              render={({ field }) => <TextField {...field} label="City" fullWidth />}
            />
          </Grid>

        {/*}  <Grid item xs={12}>
                    <Divider />
                  </Grid>

          <Grid item xs={12}>
            <Controller
              name="bankName"
              control={control}
              defaultValue=""
              render={({ field }) => <TextField {...field} label="Bank Name" fullWidth />}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="branchName"
              control={control}
              defaultValue=""
              render={({ field }) => <TextField {...field} label="Branch Name" fullWidth />}
            />
          </Grid>
          <Grid item xs={12}>
            <Controller
              name="accountNumber"
              control={control}
              defaultValue=""
              render={({ field }) => <TextField {...field} label="Account Number" fullWidth />}
            />
          </Grid>

          <Grid item xs={12}>
            <FormControl fullWidth>
             {/*} <InputLabel htmlFor="idProof">ID Proof (Driving License or Aadhar Card)</InputLabel>
              <Controller
                name="idProof"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Input {...field} type="file" id="idProof" accept=".jpg, .jpeg, .png, .pdf" />
                )}
              />
              <FormHelperText>ID Proof(Driving License or Aadhar Card)</FormHelperText>
            </FormControl>
          </Grid>*/}

          <Grid item xs={12}>
           
            <Button type="submit" variant="contained" color="primary" fullWidth>
              Register
            </Button>
            
          </Grid>
        </Grid>
      </form>
    </Container>
  );
};

export default RegistrationForm;
