import { Link } from 'react-router-dom';

// material-ui
import { useTheme } from '@mui/material/styles';
import { Box, Divider, Grid, Stack, Typography, useMediaQuery } from '@mui/material';
import AuthWrapper1 from '../AuthWrapper1';
import AuthCardWrapper from '../AuthCardWrapper';
import Logo from '../../../components/ui-component/Logo';
import LoginForm from '../auth-forms/AuthForm';

// project imports


// assets

// ================================|| AUTH3 - LOGIN ||================================ //

const Login5 = () => {
 
  const theme = useTheme();
  const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));
  return (
    <>
<Grid container spacing={2}>
<Grid item xs={12} md={7} lg={6} sm={12} >
  <Box ml={2} mt={2}>
    <Link to='/' style={{textDecoration:'none'}}>
<Logo/>
</Link>
</Box>

<Stack
            sx={{
              height: '100vh',
               width: matchDownSM ? '90%' : '50%',
              margin:"auto",
              marginLeft: matchDownSM ? '5%' : '25%',
              marginRight: matchDownSM ? '5%' : '25%',
              display: 'flex',
              flexDirection: 'column',
      
        

              marginTop:'70px',

              
            }}
          >

 <Typography sx={{color:'#000000' , fontFamily:'Poppins', fontWeight:600, fontSize:'36px', textAlign:'center'}}>Welcome Back</Typography>
 <Typography sx={{color:'#000000' , fontFamily:'Poppins', fontWeight:400, fontSize:'18px', textAlign:'center'}}>Login into your account</Typography>
 <Box marginTop='20px'>
 <LoginForm/>
 </Box>
 <Typography sx={{color:'#000000' , fontFamily:'Poppins', fontWeight:300, fontSize:'14px', textAlign:'center',marginTop:'15px'}}>   Don&apos;t have an account? Contact Admin</Typography>
 </Stack>
 
</Grid>

<Grid item xs={12} lg={6} sm={5} md={5} sx={{ display: { xs: 'none', lg: 'block', xl: 'block' ,md:"block"} }}>
<img src='/crm_login_image.png' width='100%'/>
</Grid>
</Grid>
    </>
  );
};

export default Login5;