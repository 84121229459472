import { Box, styled } from "@mui/material";
import { DataGrid } from "@mui/x-data-grid";
import React from "react";

export default function SvoReportTable({ testpreData }) {
  console.log(testpreData.subServiceCounts.map((i) => i));
  const rowdata = testpreData.subServiceCounts.map((i) => i);
  const totalCount = rowdata.reduce((total, item) => {
    return total + item.totalEnquiries;
  }, 0);

  const totalDemo = rowdata.reduce((total, item) => {
    return total + item.demoBookedCount;
  }, 0);

  const totalEnrolled = rowdata.reduce((total, item) => {
    return total + item.enrolledCount;
  }, 0);

  const tableMainData = [
    ...rowdata,
    {
      sub_service_id: "Total",
      totalEnquiries: totalCount,
      demoBookedCount: totalDemo,
      enrolledCount: totalEnrolled,
    },
  ];

  console.log("totalCount", totalCount);
  const columns = [
    {
      field: "subService",
      headerName: "Sub Service",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.sub_service_id}</div>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "clientCount",
      headerName: "Client Count",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.totalEnquiries}</div>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "demoScheduled",
      headerName: "Demo Scheduled",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.demoBookedCount}</div>,
      headerAlign: "center",
      align: "center",
    },
    {
      field: "enrolled",
      headerName: "Enrolled",
      flex: 1,
      headerClassName: "header-cell",
      renderCell: (params) => <div>{params.row?.enrolledCount}</div>,
      headerAlign: "center",
      align: "center",
    },
  ];

  const StyledGridOverlay = styled("div")(({ theme }) => ({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
    "& .ant-empty-img-1": {
      fill: theme.palette.mode === "light" ? "#aeb8c2" : "#262626",
    },
    "& .ant-empty-img-2": {
      fill: theme.palette.mode === "light" ? "#f5f5f7" : "#595959",
    },
    "& .ant-empty-img-3": {
      fill: theme.palette.mode === "light" ? "#dce0e6" : "#434343",
    },
    "& .ant-empty-img-4": {
      fill: theme.palette.mode === "light" ? "#fff" : "#1c1c1c",
    },
    "& .ant-empty-img-5": {
      fillOpacity: theme.palette.mode === "light" ? "0.8" : "0.08",
      fill: theme.palette.mode === "light" ? "#f5f5f5" : "#fff",
    },
  }));

  function CustomNoRowsOverlay() {
    return (
      <StyledGridOverlay>
        <Box sx={{ mt: 1 }}>No Data Available</Box>
      </StyledGridOverlay>
    );
  }
  return (
    <>
      <Box
        sx={{
          height: "100%",
          width: "100%",
          "& .header-cell": {
            backgroundColor: "#abf7b1",
            fontWeight: 600,
            borderRight: "0.1px solid #C3C3C3",
          },
        }}
      >
        <DataGrid
          rows={tableMainData}
          columns={columns}
          autoHeight
          getRowId={() => Math.floor(Math.random() * 100000000)}
          slots={{
            noRowsOverlay: CustomNoRowsOverlay,
          }}
          initialState={{
            pagination: {
              paginationModel: {
                pageSize: 25,
              },
            },
          }}
          sx={{ marginTop: "20px" }}
          pageSizeOptions={[25, 50, 100]}
        />
      </Box>
    </>
  );
}
