// Author : Ayesha Shaikh
//  Date : 13 sept 2024

import { Grid, TextField, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
export default function ViewEmployeeDetails({selectedId}) {
const [loading,setLoading] =useState(false)
const [employeeData, setEmployeeData] =useState([])

  useEffect(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const response = await fetch(`${API_ENDPOINT}employee/${selectedId}`);
        if (response.ok) {
          const data = await response.json();
          setEmployeeData(data);
        
          setLoading(false);
        } else if (response.status === 401) {
        
          setLoading(false);
        } else if (response.status === 404) {
     
          setLoading(false);
        } else if (response.status === 401) {
       
          setLoading(false);
        } else {
          setLoading(false);
        
          throw new Error("Error Fetching  Employees");
        }
      } catch (error) {  
     
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  return (
 <>
 {loading ?  ( 
 <Typography>Loading....</Typography>
 ) : (
 <Grid container spacing={2}>
  <Grid item xs={12} lg={4} sm={6}>
    <Typography>Employee Name</Typography>
    <TextField  value={`${employeeData.first_name?employeeData?.first_name:" "} ${employeeData.last_name?employeeData?.last_name : " "}`}  fullWidth size='small' InputProps={{ readOnly: true,style:{borderRadius:'14px'}}}   
   />
  </Grid>
 
 

  <Grid item xs={12} lg={4} sm={6}>
    <Typography>Email</Typography>
    <TextField  value={employeeData?.email}  fullWidth size='small' InputProps={{ readOnly: true,style:{borderRadius:'14px'}}}   
   />

   
  </Grid>

<Grid item xs={12} lg={4}>
  <Grid container>
  <Grid item xs={12} lg={4} sm={6}>
    <Typography>ISD Code</Typography>
    <TextField  value={employeeData?.mobile_country_code}  fullWidth size='small' InputProps={{ readOnly: true,style:{borderRadius:'14px'}}}   
   />
   </Grid>
  <Grid item xs={12} lg={8} sm={6}>
    <Typography>Mobile No</Typography>
    <TextField  value={employeeData?.mobile}  fullWidth size='small' InputProps={{ readOnly: true,style:{borderRadius:'14px'}}}   
   />
   </Grid>
   </Grid>
   </Grid>


   <Grid item xs={12} lg={4} sm={6}>
    <Typography>Gender</Typography>
    <TextField  value={employeeData?.gender}  fullWidth size='small' InputProps={{ readOnly: true,style:{borderRadius:'14px'}}}   
   />
   </Grid>


   <Grid item xs={12} lg={4} sm={6}>
    <Typography>Date Of Birth</Typography>
    <TextField  value={employeeData?.dob}  fullWidth size='small' InputProps={{ readOnly: true,style:{borderRadius:'14px'}}}   
   />
   </Grid>

   <Grid item xs={12} lg={4} sm={6}>
    <Typography>Joining Date</Typography>
    <TextField  value={employeeData?.joining_date}  fullWidth size='small' InputProps={{ readOnly: true,style:{borderRadius:'14px'}}}   
   />
   </Grid>

   <Grid item xs={12} lg={4} sm={6}>
    <Typography>Designation</Typography>
    <TextField  value={employeeData?.designation}  fullWidth size='small' InputProps={{ readOnly: true,style:{borderRadius:'14px'}}}   
   />
   </Grid>


   <Grid item xs={12} lg={4} sm={6}>
    <Typography>Role</Typography>
    <TextField  value={employeeData?.role}  fullWidth size='small' InputProps={{ readOnly: true,style:{borderRadius:'14px'}}}   
   />
   </Grid>

   <Grid item xs={12} lg={4} sm={6}>
    <Typography>Branch</Typography>
    <TextField  value={employeeData?.branch?.branch_name}  fullWidth size='small' InputProps={{ readOnly: true,style:{borderRadius:'14px'}}}   
   />
   </Grid>
  
   <Grid item xs={12} lg={4} sm={6}>
    <Typography>Branch Manager</Typography>
    <TextField  value={employeeData?.branch_manager}  fullWidth size='small' InputProps={{ readOnly: true,style:{borderRadius:'14px'}}}   
   />
   </Grid>

   <Grid item xs={12} lg={4} sm={6}>
    <Typography>Reporting Manager</Typography>
    <TextField  value={employeeData?.reporting_manager}  fullWidth size='small' InputProps={{ readOnly: true,style:{borderRadius:'14px'}}}   
   />
   </Grid>

  

   <Grid item xs={12} lg={4} sm={6}>
    <Typography>Report CC </Typography>
    <TextField  value={employeeData?.add_report_cc_user}  fullWidth size='small' InputProps={{ readOnly: true,style:{borderRadius:'14px'}}}   
   />
   </Grid>
 </Grid>
  )}
 </>
  )
}
