import React from 'react'


import Hero from './hero/Hero'
import AppBarComponent from './appbar/AppBar'
import { Grid } from '@mui/material'

function LandingPage() {
  return (
    <>
    <AppBarComponent />
    
        <Hero />
       
  
    </>
  )
}

export default LandingPage