// Author: Ayesha Shaikh
// Date : 10 aug 2024


import {
  Paper,
  styled,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from "@mui/material";
import React from "react";
import LaunchIcon from "@mui/icons-material/Launch";
import { useNavigate } from "react-router-dom";

export default function StudentInQueTable({ data, event_counselling_status }) {
  const navigate = useNavigate();

  const StyledTableContainer = styled(TableContainer)(({ theme }) => ({
    marginTop: "20px",
    "& .MuiTableCell-head": {
      backgroundColor: "#abf7b1",
      fontWeight: 600,
      padding: "8px 16px",

      borderRight: "0.1px solid #C3C3C3",
      borderLeft: "0.1px solid #C3C3C3",
      color: "#000",
    },
    "& .MuiTableCell-body": {
      fontSize: "14px",
      fontWeight: 600,
      borderRight: "0.1px solid #C3C3C3",
      borderLeft: "0.1px solid #C3C3C3",
      align: "center",
      padding: "8px 16px",
      // "&:nth-of-type(n+2)": {
      //   color: "blue",
      // },
    },
  }));

  const handlenavigate1 = (enquiry_id, eventid, deskid) => {
    const selectedData = {
      enquiry_id,
      eventid,
      deskid,
      event_counselling_status,
    };
    navigate(`/student-information/${enquiry_id}`, {
      state: { data: selectedData },
    });
  };

  return (
    <>
      <Typography sx={{ fontSize: "20px", fontWeight: 600, color: "blue" }}>
        Student in Queue
      </Typography>

      <StyledTableContainer component={Paper} style={{ maxHeight: "300px" }}>
        <Table aria-label="simple table" stickyHeader>
          <TableHead>
            <TableRow>
              <TableCell>NAME</TableCell>
              <TableCell>Service</TableCell>
              <TableCell>Counselling Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.map((student) => (
              <TableRow>
                <TableCell
                  component="th"
                  scope="row"
                  sx={{ color: "blue", display: "flex" }}
                  onClick={() =>
                    handlenavigate1(
                      student?.enq_personal_details_id,
                      student?.event_id,
                      student?.event_desk_id
                    )
                  }
                >
                  {student?.enquiry?.first_name}{" "}
                  {
                    <LaunchIcon
                      fontSize="small"
                      style={{ marginLeft: "5px" }}
                    />
                  }
                </TableCell>
                <TableCell component="th" scope="row">
                  Test Preparation
                </TableCell>

                <TableCell component="th" scope="row">
                  {student?.counselling_date}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </StyledTableContainer>
    </>
  );
}
