import React from 'react'
import {
    Document,
    Page,
    PDFViewer,
    StyleSheet,
    Text,
    View,
  } from "@react-pdf/renderer";

export default function AdvisorClosedTask({completedData,styles}) {
  return (
  <>

<Text style={styles.text}>Task Details</Text>
                <Text
                  style={{
                    color: "brown",
                    fontWeight: 600,
                    marginTop: "10px",
                    fontSize: "15px",
                  }}
                >
                  Closed Task
                </Text>
                {completedData.length === 0 ? (
                  <Text
                    style={{
                      fontFamily: "Open Sans",
                      fontWeight: "bold",
                      fontSize: 12,
                      textAlign: "center",
                    }}
                  >
                  No Closed Tasks
                  </Text>
                ) : (
                  <View style={styles.table}>
                    <View style={styles.tableRow}>
                      <Text style={styles.tableHeaderCell1}>Task Name</Text>
                      <Text style={styles.tableHeaderCell}>Total Closed</Text>
                      <Text style={styles.tableHeaderCell}>
                        Completed (connected / Attended / Visited / Accepeted)
                      </Text>
                      <Text style={styles.tableHeaderCell}>
                        Incompleted (Not Connected / Not Attended / Not Visited
                        / Not Accepted )
                      </Text>
                    </View>
                    {completedData.map((task, index) => (
                      <View style={styles.tableRow} key={index}>
                        <Text style={styles.tableCell1}>
                          {task.type_of_task}
                        </Text>
                        <Text style={styles.tableCell}>{task.totalTasks}</Text>
                        <Text style={styles.tableCell}>
                          {task.contactableTasks}
                        </Text>
                        <Text style={styles.tableCell}>
                          {task.nonContactableTasks}
                        </Text>
                      </View>
                    ))}
                  </View>
                )}

                

  </>
  )
}
