import React, { useEffect, useState } from 'react'
import MainCard from '../../newTestPrep/components/ui-component/card/MainCard'
import { Box, Button, Grid, Stack, Typography } from '@mui/material'
import axios from 'axios';
import { Country, State, City }  from 'country-state-city';
import { useNavigate } from 'react-router-dom';

export default function ReportCards({ data, selectedState, selectedcity,filteredData}) {
  const navigate = useNavigate()
   // Filter data based on selected state
  //  const filteredData = data.filter((card) => {
  //   if (selectedState.length > 0 && !selectedState.includes(card.state_id)) {
  //     return false;
  //   }
  //   if (selectedcity.length > 0 && !selectedcity.includes(card.city_id)) {
  //     return false;
  //   }
  //   return true;
  // });

  console.log("filteredData",filteredData)
    const cardsPerPage = 9;
    const [currentpage, setcurrentpage]=useState(1)
    const indexOfLastCard = currentpage * cardsPerPage;
    const indexOfFirstCard = indexOfLastCard - cardsPerPage;
    const currentCards =filteredData.slice(indexOfFirstCard, indexOfLastCard);
    const totalPages = Math.ceil(filteredData.length / cardsPerPage);
    const handleNextPage = () => {
        setcurrentpage((prevPage) => Math.min(prevPage + 1, totalPages));
    };
  
    const handlePrevPage = () => {
        setcurrentpage((prevPage) => Math.max(prevPage - 1, 1));
    };

    const handleViewMore = (id, name) => {
      navigate(`/centerdashboard/${id}`, {
        state: { branchName: name }
      });
    }
   
  return (
    <>
    <Grid container spacing={5} >
    {currentCards.map((data, index) => (
        <>       
           <Grid item xs={12} lg={4} md={6} sm={6} key={index} >
   <MainCard >
    <Typography sx={{fontWeight:600, fontSize:'32px', fontFamily:'Poppins', color:'#000000'}}>{data.branch_name}</Typography>
    <Stack  display='flex' flexDirection='row' alignItems='center'>
    <img src='/reporticon.png'/>
    <Typography sx={{fontFamily:'Poppins', fontWeight:500, fontSize:'20px' , color:'#000000' ,marginLeft:'10px'}}>Total Revenue: ₹ {data.total_revenue}</Typography>
    </Stack>
    <Grid container spacing={2} marginTop={0.5}>
        <Grid item xs={12} lg={5} md={6} sm={6} >
            <Typography sx={{background:'#F4F4F4' , color:'#425166', fontFamily:'Poppins' , fontWeight:'400' , fontSize:'14px',borderRadius:'6px'}}>Collected:{data.collected_revenue}</Typography>
        </Grid>
        <Grid item xs={12} lg={5} md={6} sm={6}>
            <Typography sx={{background:'#F4F4F4' , color:'#425166', fontFamily:'Poppins' , fontWeight:'400' , fontSize:'14px', borderRadius:'6px'}}>Pending:{data.pending_revenue}</Typography>
        </Grid>
        <Grid item xs={12} lg={7} md={7} sm={7}>
            <Typography sx={{background:'#F4F4F4' , color:'#425166', fontFamily:'Poppins' , fontWeight:'400' , fontSize:'14px', borderRadius:'6px'}}>New Enrollment: {data.total_enrollment}</Typography>
        </Grid>
    </Grid>
    <Box sx={{textAlign:'right', marginTop:'25px'}}>
    <Button style={{fontFamily:'Poppins', fontWeight:400, fontSize:'16px', color:'#FFFFFF', background:'#019E6F' , borderRadius:'10px'}} onClick={() => handleViewMore(data.id, data.branch_name)}>View more details</Button>
    </Box>
   
   </MainCard>
   </Grid>
   </>
    ))}
    </Grid>
    <Box sx={{ textAlign: 'right', marginTop: '20px' }}>
        <Button disabled={currentpage === 1} onClick={handlePrevPage} style={{ marginRight: '10px'}}>Previous Page</Button>
       
        <Button 
    variant='contained' 
    style={{
        fontFamily: 'Poppins', 
        fontWeight: 400, 
        fontSize: '14px', // Decreased font size
        color: '#FFFFFF', 
        background: '#019E6F', 
        height: '25px' // Decreased height
    }}
>
    {currentpage}
</Button>
        <Button disabled={currentpage === totalPages} onClick={handleNextPage} >Next Page</Button>
      </Box>
    </>
  )
}
