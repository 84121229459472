import { Stack, Typography } from '@mui/material'
import React from 'react'
import { Button } from 'react-scroll'

export default function ResentTransaction({data}) {
  return (
 <>

<Stack spacing={1}>
            <Typography sx={{ color: 'black', fontFamily: 'Poppins', fontSize: '12px', fontStyle: 'normal', fontWeight: 400 }}>
              {data.subtitle}
            </Typography>
            <Stack display='flex' flexDirection='row' alignItems='center' justifyContent='space-between'>
              <Stack display='flex' flexDirection='row' alignContent='center'>
               
                <Typography sx={{ color: '#5C5C5C', fontFamily: 'Poppins', fontSize: '12px', fontStyle: 'normal', fontWeight: 400 }}>
                 {data.date}
                </Typography>
              </Stack>
             
            </Stack>
         
          </Stack>
        
 </>
  )
}
