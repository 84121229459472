import {
  Box,
  Button,
  CircularProgress,
  FormControl,
  Grid,
  IconButton,
  OutlinedInput,
  Paper,
  Select,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import RemoveRedEyeOutlinedIcon from "@mui/icons-material/RemoveRedEyeOutlined";
import CheckIcon from "@mui/icons-material/Check";
import parse from "html-react-parser";
export default function TemplateForm({
  onSelectedData,
  handelclosetemplatepopup,
  showSnackbar
}) {
  const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;
  const [templatelist, setTemplatelist] = useState([]);
  const [templateTitle, setTemplateTitle] = useState("");
 const [loader,setLoader] =useState(false)
  const [openRow, setOpenRow] = React.useState(null);


  useEffect(() => {
    const fetchData = async () => {
      try {
        setLoader(true)
        const response = await fetch(`${API_ENDPOINT}route/fetchtemplate`);
        if (response.ok) {
          const data = await response.json();

          setTemplatelist(data);
          console.log(data)
          setLoader(false); 
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
        setLoader(false); 
      }
    };

    fetchData();
  }, []);

  const handleSearch = () => {
    const filtered = templatelist.filter((template) =>
      template.template_title
        .toLowerCase()
        .includes(templateTitle.toLowerCase())
    );
    setTemplatelist(filtered);
  };

  const toggleRow = (index) => {
    setOpenRow((prevOpenRow) => (prevOpenRow === index ? null : index));
  };

  const handelclick = (description,subject, attachments) => {
    console.log(description);
    showSnackbar("Template selected", "success");
    onSelectedData(description,subject,attachments);
     handelclosetemplatepopup();
  
   
  };
  return (
    <>
      <Grid container direction="row" alignItems="center" spacing={2} mb={3}>
        <Grid item xs={6} lg={5}>
          <Typography sx={{ fontWeight: 600, textAlign: "left" }}>
            Search By Template Title
          </Typography>
          <TextField
            id="outlined-basic"
            variant="outlined"
            fullWidth
            InputProps={{
              style: {
                borderRadius: "15px",
              },
            }}
            value={templateTitle}
            onChange={(e) => setTemplateTitle(e.target.value)}
          />
        </Grid>

        <Grid item xs={4} lg={3} marginTop="8px">
          <Button
            variant="contained"
            color="primary"
            style={{ borderRadius: "24px" }}
            onClick={handleSearch}
          >
            {" "}
            search{" "}
          </Button>
        </Grid>
      </Grid>

      {loader ? ( // If loader is true, display loader
        <Box display="flex" justifyContent="center">
          <CircularProgress />
        </Box>
      ) :  templatelist.length === 0 ? ( // If loader is false and data is empty, display "No data"
      <Typography>No data</Typography>
    ) : (
      <TableContainer component={Paper}>
        <Table
          aria-label="simple table"
          sx={{ border: "1px solid rgba(224, 224, 224, 1)" }}
        >
          <TableHead>
            <TableRow sx={{ height: "10px" }}>
              <TableCell
                sx={{
                  background: "#E1EBEE",
                  borderRight: "1px solid rgba(224, 224, 224, 1)",
                  padding: "10px",
                  lineHeight: "1",
                  verticalAlign: "top",
                  fontWeight: 600,
                }}
              >
                Template Name
              </TableCell>
              <TableCell
                sx={{
                  background: "#E1EBEE",
                  height: "10px",
                  padding: "10px",
                  lineHeight: "1",
                  verticalAlign: "top",
                  textAlign: "center",
                  fontWeight: 600,
                }}
              >
                Action
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {templatelist.map((item, index) => (
              <React.Fragment key={item.id}>
                <TableRow>
                  <TableCell
                    component="th"
                    scope="row"
                    sx={{
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      borderRight: "1px solid rgba(224, 224, 224, 1)",
                    }}
                  >
                   {item.template_title}

                    {openRow === index && (
                      <>
                      <Typography sx={{ marginTop: "10px" }}>

                        <Typography sx={{fontWeight:600, fontSize:'18px' , marginTop:'10px'}}>Body</Typography>
                      {parse(String(item.description))}

                      </Typography>
                     
                      </>
                    )}
                  </TableCell>

                  <TableCell
                    sx={{
                      borderBottom: "1px solid rgba(224, 224, 224, 1)",
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <Box
                      onClick={() => toggleRow(index)}
                      sx={{
                        height: "25px",
                        width: "30px",
                        borderRadius: "40%",
                        background: "blue",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                      }}
                    >
                      <RemoveRedEyeOutlinedIcon fontSize="small" />
                    </Box>
                    <Box
                      onClick={() => handelclick(item.description,item?.email_subject, item?.attachments)}
                      sx={{
                        height: "25px",
                        width: "30px",
                        borderRadius: "40%",
                        background: "green",
                        color: "white",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        marginLeft: "10px",
                      }}
                    >
                      <CheckIcon fontSize="small" />
                    </Box>
                  </TableCell>
                </TableRow>
              </React.Fragment>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
        )}
    </>
  );
}
