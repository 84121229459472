import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  Autocomplete,
  Backdrop,
  Box,
  Button,
  CircularProgress,
  createTheme,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  Stack,
  TextField,
  ThemeProvider,
  Typography,
} from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import SearchIcon from "@mui/icons-material/Search";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import CustomSnackbar from "../../../newTestPrep/components/ui-component/snackbar/CustomSnackbar";

const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT;

function AddWalkIn() {
  const navigate = useNavigate();

  const userId = localStorage.getItem("userId");
  const userRole = localStorage.getItem("userRole");

  const [role, setRole] = useState(userRole);
  const { advisorId } = useSelector((state) => state.user);
  const [enquiryDetails, setEnquiryDetails] = useState({});
  const [enquiryPersonalDetails, setEnquiryPersonalDetails] = useState({});
  const [searching, setSearching] = useState(false);
  const [enquiryFound, setEnquiryFound] = useState(true); // Set this based on your search logic
  const [showForm, setShowForm] = useState(false);
  const [showNoEnquiryPopup, setShowNoEnquiryPopup] = useState(false);
  const [advisors, setAdvisors] = useState([]);
  const [leadSources, setLeadSources] = useState([]);
  const [subLeadSources, setSubLeadSources] = useState([]);
  const [branches, setBranches] = useState([]);
  const [users, setUsers] = useState([]);
  const [testTypes, setTestTypes] = useState([]);
  const [countries, setCountries] = useState([]);
  const [countriesList, setCountriesList] = useState([]);
  const [services, setServices] = useState([]);
  const [eventReasons, setEventReasons] = useState([]);

  const [selectedService, setSelectedService] = useState(1);
  const [selectedSourceType, setSelectedSourceType] = useState(null);
  const [selectedSubSourceType, setSelectedSubSourceType] = useState(null);
  const [selectedSubSourceTypeID, setSelectedSubSourceTypeID] = useState(null);
  const [referenceByList, setReferenceByList] = useState([]);
  const [referenceBy, setReferenceBy] = useState(null);
  const [subServices, setSubServices] = useState([]);

  const [closestTime, setClosestTime] = useState("");

  const [emailField, setEmailField] = useState("");

  const [walkInStatus, setWalkInStatus] = useState("direct");

  const [isSavingLoading, setIsSavingLoading] = useState(false);
  const [showOtherField, setShowOtherField] = useState(false);
  const [referenceEmployee, setReferenceEmployee] = useState(null);

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    setValue,
  } = useForm();

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [snackbarSeverity, setSnackbarSeverity] = useState("success");

  const showSnackbar = (message, severity) => {
    setSnackbarMessage(message);
    setSnackbarSeverity(severity);
    setSnackbarOpen(true);
  };

  const [isEvent, setIsEvent] = useState(false);
  const [duration, setDuration] = useState("today");
  const [events, setEvents] = useState([]);

  const handleTypeOfWalkingChange = (event) => {
    setIsEvent(event.target.value === "event");
  };

  const handleDurationChange = (event) => {
    setDuration(event.target.value);
  };

  const fetchEvents = async (duration) => {
    try {
      const response = await fetch(`${API_ENDPOINT}route2.0/showcampaign`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({ filter: duration }),
      });

      if (!response.ok) {
        throw new Error("Network response was not ok");
      }

      const data = await response.json();
      setEvents(data);
    } catch (error) {
      console.error("Error fetching events:", error);
    }
  };

  useEffect(() => {
    if (isEvent) {
      fetchEvents(duration);
    }
  }, [duration, isEvent]);

  //handle search functionality

  const handleSearch = async () => {
    setSearching(true);

    // await new Promise(resolve => setTimeout(resolve, 1000));

    //const emailOrPhone = data.emailField;

    const requestData = {
      email: emailField,
    };

    try {
      // Make a POST request to your API endpoint
      const response = await fetch(
        `${API_ENDPOINT}advisordashboard/registerwalkin`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify(requestData),
        }
      );
      console.log(response);

      if (response.status === 200) {
        const responseData = await response.json();

        console.log("API Response:", responseData);

        setShowForm(true);
        setEnquiryDetails(responseData.enquiry);
        setEnquiryPersonalDetails(responseData.enquiry_personal_details);
        setWalkInStatus(responseData.walkInStatus);
      } else if (response.status === 404) {
        const responseData = await response.json();
        setWalkInStatus(responseData.walkInStatus);
        setShowNoEnquiryPopup(true);
        setShowForm(true);
      } else if (response.status === 400) {
        showSnackbar("Email/ Mobile Required", "error");
      } else {
        console.error(
          "API Request Failed:",
          response.status,
          response.statusText
        );
        showSnackbar("API Request Failed:", "error");
        setShowNoEnquiryPopup(true);
      }
    } catch (error) {
      console.error("An error occurred during the API request:", error);
      showSnackbar("An error occurred during the API request", "error");
      // Handle the error, show an error message, or take other actions as needed
    }
    setSearching(false);
  };

  console.log(enquiryDetails);
  console.log(enquiryDetails?.last_name);

  //fetches the assign_to input- all advisors

  useEffect(() => {
    fetch(`${API_ENDPOINT}advisor`)
      .then((response) => response.json())
      .then((data) => {
        setAdvisors(data);
      })
      .catch((error) => {
        console.error("Error fetching advisor items:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}leadsource`)
      .then((response) => response.json())
      .then((data) => {
        setLeadSources(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching leadsource:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route/getalltesttype`)
      .then((response) => response.json())
      .then((data) => {
        setTestTypes(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching testtypes:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}route/getalluser`)
      .then((response) => response.json())
      .then((data) => {
        let filteredData = data;

        /*  if (role === 'advisor') {
                // If the role is advisor, filter the list to show only the logged-in advisor
                filteredData = data.filter(user => user.id === parseInt(userId));
            }*/

        const sortedData = filteredData.sort((a, b) => {
          const nameA = (a.first_name + " " + a.last_name).toUpperCase();
          const nameB = (b.first_name + " " + b.last_name).toUpperCase();
          return nameA.localeCompare(nameB);
        });
        setUsers(sortedData);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching users:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}branch`)
      .then((response) => response.json())
      .then((data) => {
        setBranches(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching branches:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}country`)
      .then((response) => response.json())
      .then((data) => {
        const sortedData = [...data].sort(
          (a, b) => a.phone_code - b.phone_code
        );
        const uniqueArray = sortedData.filter(
          (obj, index, self) =>
            index === self.findIndex((t) => t.phone_code === obj.phone_code)
        );
        setCountriesList(data);
        setCountries(uniqueArray);

        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching country:", error);
      });
  }, []);

  useEffect(() => {
    // Fetch menu items using the fetch function
    fetch(`${API_ENDPOINT}service`)
      .then((response) => response.json())
      .then((data) => {
        setServices(data);
        console.log(data);
      })
      .catch((error) => {
        console.error("Error fetching services:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedService) {
      fetch(`${API_ENDPOINT}subservice`)
        .then((response) => response.json())
        .then((data) => {
          const filteredSubServices = data.filter(
            (sub) => sub.service_id === selectedService
          );
          setSubServices(filteredSubServices);
          console.log(filteredSubServices);
        })
        .catch((error) => {
          console.error("Error fetching sub services:", error);
        });
    }
  }, [selectedService]);

  useEffect(() => {
    fetch(`${API_ENDPOINT}leadsource`)
      .then((response) => response.json())
      .then((data) => {
        const filteredLeadSources = data.filter((sub) => sub.is_active === 1);
        //setSubSourceTypes(filteredLeadSources);
        setLeadSources(filteredLeadSources);
      })
      .catch((error) => {
        console.error("Error fetching lead sources:", error);
      });
  }, []);

  useEffect(() => {
    if (selectedSourceType) {
      fetch(`${API_ENDPOINT}subleadsource`)
        .then((response) => response.json())
        .then((data) => {
          const filteredLeadSources = data.filter(
            (sub) => sub.lead_source_type_id === selectedSourceType
          );
          setSubLeadSources(filteredLeadSources);
        })
        .catch((error) => {
          console.error("Error fetching sub lead sources:", error);
        });
    }
  }, [selectedSourceType]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await fetch(`${API_ENDPOINT}referencewalkin`);
        if (response.ok) {
          const data = await response.json();
          console.log("data", data);
          const sortedData = data.sort(
            (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
          );

          setReferenceByList(sortedData);
        } else {
          throw new Error(`Request failed with status: ${response.status}`);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, []);

  const handleServiceChange = (event) => {
    setSelectedService(event.target.value);
    setValue("service_id", event.target.value);
  };

  const handleSourceTypeChange = (event) => {
    setSelectedSourceType(event.target.value);
    setValue("lead_source_type_id", event.target.value);
    setSelectedSubSourceType(null);
    setSelectedSubSourceTypeID(null);
  };

  const handleSubSourceTypeChange = (event, value) => {
    const ids = value?.source_type;
    setSelectedSubSourceType(value);
    setSelectedSubSourceTypeID(ids);
    setValue("sub_lead_source_type_id", value?.id);
  };

  const handleReferenceEmployeeChange = (event, value) => {
    setReferenceEmployee(value);
    setValue("reference_employee_id", value?.id);
  };

  const handleReferenceChange = (event, value) => {
    if (value && value.name === "Other") {
      setShowOtherField(true); // Show the additional text field
      setReferenceBy(value); // Clear the selection
      //setValue('reference_walkin_id', 'other');
      setValue("reference_walkin_id", value?.id || null);
      // Reset the controlled value
    } else {
      setShowOtherField(false); // Hide the additional text field
      setReferenceBy(value);
      setValue("reference_walkin_id", value?.id || null);
      setValue("reference_employee_id", null);
    }
  };

  useEffect(() => {
    fetch(`${API_ENDPOINT}eventreason`)
      .then((response) => response.json())
      .then((data) => {
        setEventReasons(data);
      })
      .catch((error) => {
        console.error("Error fetching events reason items:", error);
      });
  }, []);

  //handles the submit form

  const onSubmit = (data) => {
    console.log(data);
    setIsSavingLoading(true);

    if (referenceBy?.id === "Other") {
      data.reference_walkin_id = null;
    }

    data.walk_in_status = walkInStatus;
    data.user_id = userId;

    fetch(`${API_ENDPOINT}route/bookappointment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.status === 200) {
          //alert('Enquiry sent successfully');
          setIsSavingLoading(false);
          showSnackbar("Walk in appointment sent successfully", "success");
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        } else if (response.status === 403) {
          showSnackbar("Unathorized User", "error");
        } else {
          // API call failed, show error message
          //alert('Error submitting enquiry. Please try again.');
          setIsSavingLoading(false);
          showSnackbar(
            "Error submitting walk in enquiry. Please try again",
            "error"
          );
        }
      })
      .catch((error) => {
        // Handle network errors or other issues
        console.error("Error:", error);
        // alert('An error occurred. Please try again later.');
        setIsSavingLoading(false);
        showSnackbar("An error occurred. Please try again later.", "error");
      });
  };

  // const selectedOption = watch('email', 'phone');

  const CenteredBox = styled(Box)({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    minWidth: "100vh", // Adjust as needed
    padding: "1rem", // Adjust as needed
  });

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === "dark" ? "#1A2027" : "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(2),
    textAlign: "center",
    color: theme.palette.text.secondary,
    border: `2px solid #2196F3`,
    borderRadius: "12px",
  }));

  // Get current time in HH:mm format adjusted to local time zone
  const currentTime = new Date().toLocaleTimeString([], {
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
  });
  console.log("currentTime", currentTime);

  const theme = createTheme({
    components: {
      // Name of the component
      MuiAutocomplete: {
        styleOverrides: {
          // Name of the slot
          noOptions: {
            // Some CSS
            color: "red",
            fontSize: "14px",
          },
        },
      },
    },
  });

  return (
    <>
      <Box mt={2} mb={2} ml={2}>
        <Typography
          variant="h4"
          fontWeight="bold"
          sx={{ m: "0 0 5px 0" }}
          color="#171A1FFF"
        >
          Register Walk In
        </Typography>
      </Box>

      <Grid container spacing={2} mb={2}>
        <Grid item xs={12}>
          <TextField
            value={emailField}
            onChange={(e) => setEmailField(e.target.value)}
            InputProps={{
              style: { borderRadius: "12px" },
            }}
            fullWidth
            label="Email/Phone"
          />
        </Grid>
        <Grid item xs={12}>
          <Button variant="contained" color="secondary" onClick={handleSearch}>
            Search <SearchIcon />
          </Button>
        </Grid>
      </Grid>

      {showForm && (
        <Item>
          <Grid container spacing={4}>
            <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: "black" }}>
                Walk in Status
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <Controller
                name="walk_in_status"
                control={control}
                defaultValue={walkInStatus}
                render={({ field }) => (
                  <TextField
                    size="small"
                    select
                    {...field}
                    InputProps={{
                      style: { borderRadius: "12px" },
                      readOnly: true,
                    }}
                    fullWidth
                    error={Boolean(errors.walk_in_status)}
                    disabled
                    // helperText={errors.walk_in_status}
                  >
                    <MenuItem value="direct">Direct Walk In</MenuItem>
                    <MenuItem value="indirect">Indirect Walk In</MenuItem>
                    <MenuItem value="existing">Existing Walk In</MenuItem>
                  </TextField>
                )}
              />
              {errors.walk_in_status && (
                <span style={{ color: "red" }}>
                  {errors.walk_in_status.message}
                </span>
              )}
            </Grid>

            <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: "black" }}>
                Type of Walk In
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <Controller
                name="type_of_walking"
                control={control}
                rules={{ required: "Field is required" }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    select
                    {...field}
                    onChange={(e) => {
                      field.onChange(e);
                      handleTypeOfWalkingChange(e);
                    }}
                    InputProps={{
                      style: { borderRadius: "12px" },
                    }}
                    fullWidth
                    error={Boolean(errors.type_of_walking)}
                    //helperText={errors.type_of_walking}
                  >
                    <MenuItem value="normal">Normal Walk In</MenuItem>
                    <MenuItem value="event">Event Walk In</MenuItem>
                  </TextField>
                )}
              />
              {errors.type_of_walking && (
                <span style={{ color: "red" }}>
                  {errors.type_of_walking.message}
                </span>
              )}
            </Grid>

            {isEvent && (
              <Grid item xs={6} md={4} lg={3}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <InputLabel style={{ color: "black", flexGrow: 1 }}>
                    Event Name
                    <span
                      style={{
                        color: "red",
                        position: "absolute",
                        marginLeft: "4px",
                        fontSize: "1.5em",
                      }}
                    >
                      *
                    </span>
                  </InputLabel>
                  <Select
                    labelId="event-filter-select-label"
                    id="event-filter-select"
                    value={duration}
                    onChange={handleDurationChange}
                    style={{ width: "120px", height: "15px" }}
                  >
                    <MenuItem value="today">Today</MenuItem>
                    <MenuItem value="week">Last One Week</MenuItem>
                  </Select>
                </Stack>

                <Controller
                  name="event_id"
                  control={control}
                  rules={{ required: "Field is required" }}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      select
                      {...field}
                      InputProps={{
                        style: { borderRadius: "12px" },
                      }}
                      fullWidth
                      error={Boolean(errors.event_id)}
                      style={{ marginTop: "10px" }}
                    >
                      {events.map((event, index) => (
                        <MenuItem key={index} value={event.id}>
                          {event.campaign_name}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                {errors.event_id && (
                  <span style={{ color: "red" }}>
                    {errors.event_id.message}
                  </span>
                )}
              </Grid>
            )}
          </Grid>
          <Typography id="personal-info" variant="h5" fontWeight={700}>
            Personal Information
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: "black" }}>
                First Name
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <Controller
                name="first_name"
                control={control}
                defaultValue={enquiryPersonalDetails?.first_name}
                rules={{ required: "First Name is required" }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    {...field}
                    fullWidth
                    InputProps={{
                      style: { borderRadius: "12px" },
                    }}
                    error={Boolean(errors.first_name)}
                    //helperText={errors.first_name && 'First name is required'}
                    //value={enquiryDetails?.first_name || ''}
                  />
                )}
              />
              {errors.first_name && (
                <span style={{ color: "red" }}>
                  {errors.first_name.message}
                </span>
              )}
            </Grid>
            <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: "black" }}>Last Name</InputLabel>
              <Controller
                name="last_name"
                control={control}
                defaultValue={enquiryPersonalDetails?.last_name}
                // rules={{ required: 'Last Name is required' }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    {...field}
                    fullWidth
                    InputProps={{
                      style: { borderRadius: "12px" },
                    }}
                    error={Boolean(errors.last_name)}
                    //helperText={errors.last_name && 'Last name is required'}
                    //value={enquiryDetails?.last_name || ''}
                  />
                )}
              />
              {errors.last_name && (
                <span style={{ color: "red" }}>{errors.last_name.message}</span>
              )}
            </Grid>

            {/* Contact Information */}

            <Grid item xs={6} md={4} lg={3} container>
              <Grid item xs={4} md={4} lg={4}>
                <InputLabel style={{ color: "black", position: "relative" }}>
                  ISD Code
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.5em",
                    }}
                  >
                    *
                  </span>
                </InputLabel>
                <Controller
                  name="mobile_country_code"
                  control={control}
                  defaultValue={
                    enquiryPersonalDetails?.mobile_country_code || 91
                  }
                  rules={{ required: "Country code is required" }}
                  render={({ field }) => (
                    <TextField
                      select
                      size="small"
                      {...field}
                      InputProps={{
                        style: { borderRadius: "12px" },
                      }}
                      fullWidth
                      defaultValue={91}
                      error={Boolean(errors.mobile_country_code)}
                      //helperText={errors.country && 'Country is required'}
                    >
                      {countries.map((country) => (
                        <MenuItem
                          key={country.phone_code}
                          value={country.phone_code}
                        >
                          {country.phone_code}
                        </MenuItem>
                      ))}
                    </TextField>
                  )}
                />
                {errors.mobile_country_code && (
                  <span style={{ color: "red" }}>
                    {errors.mobile_country_code.message}
                  </span>
                )}
              </Grid>

              <Grid item xs={8} md={8} lg={8}>
                <InputLabel style={{ color: "black", position: "relative" }}>
                  Mobile
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.5em",
                    }}
                  >
                    *
                  </span>
                </InputLabel>
                <Controller
                  name="mobile"
                  control={control}
                  //defaultValue='+91'
                  defaultValue={enquiryPersonalDetails?.mobile}
                  rules={{
                    required: "Contact number is required",
                    pattern: {
                      value: /^[1-9][0-9]{9}$/,
                      message: "Invalid mobile number.",
                    },
                  }}
                  render={({ field }) => (
                    <TextField
                      size="small"
                      {...field}
                      InputProps={{
                        style: { borderRadius: "12px" },
                      }}
                      error={!!errors.mobile}
                      //helperText={errors.mobile?.message}
                      fullWidth
                    />
                  )}
                ></Controller>
                {errors.mobile && (
                  <span style={{ color: "red" }}>{errors.mobile.message}</span>
                )}
              </Grid>
            </Grid>

            <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: "black" }}>
                Email
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <Controller
                name="email"
                control={control}
                defaultValue={enquiryPersonalDetails?.email}
                rules={{
                  //required: "Email is required",
                  pattern: {
                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
                    message: "Invalid email address",
                  },
                }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    {...field}
                    InputProps={{
                      style: { borderRadius: "12px" },
                    }}
                    variant="outlined"
                    error={Boolean(errors.email)}
                    //helperText={errors.email}
                    fullWidth
                    //value={enquiryDetails?.email || ''}
                  />
                )}
              ></Controller>
              {errors.email && (
                <span style={{ color: "red" }}>{errors.email.message}</span>
              )}
            </Grid>
          </Grid>
          <Typography id="personal-info" variant="h5" fontWeight={700}>
            Mandatory Information
          </Typography>
          <Grid container spacing={4}>
            <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: "black" }}>
                Branch Visited
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <Controller
                name="branch_visited"
                control={control}
                defaultValue={enquiryDetails?.branch_id}
                rules={{ required: "Field is required" }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    select
                    {...field}
                    // rules={{ required: "Field is required" }}
                    InputProps={{
                      style: { borderRadius: "12px" },
                    }}
                    fullWidth
                    error={Boolean(errors.branch_visited)}
                    // helperText={errors.branch_visited && 'Branch Name is required'}
                  >
                    {branches.map((branch) => (
                      <MenuItem key={branch.id} value={branch.id}>
                        {branch.branch_name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              {errors.branch_visited && (
                <span style={{ color: "red" }}>
                  {errors.branch_visited.message}
                </span>
              )}
            </Grid>

            <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: "black" }}>
                Service Name
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <Controller
                name="service_id"
                control={control}
                defaultValue={enquiryDetails?.service_id || 1}
                rules={{ required: "Field is required" }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    select
                    {...field}
                    InputProps={{
                      style: { borderRadius: "12px" },
                    }}
                    fullWidth
                    error={Boolean(errors.service_name)}
                    value={selectedService}
                    onChange={handleServiceChange}
                    // helperText={errors.service_name}
                  >
                    {services.map((service) => (
                      <MenuItem key={service.id} value={service.id}>
                        {service.service_name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              {errors.service_name && (
                <span style={{ color: "red" }}>
                  {errors.service_name.message}
                </span>
              )}
            </Grid>

            <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: "black" }}>
                Sub Service
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <Controller
                name="sub_service_id"
                control={control}
                defaultValue={enquiryDetails?.sub_service_id}
                rules={{ required: "Field is required" }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    select
                    {...field}
                    InputProps={{
                      style: { borderRadius: "12px" },
                    }}
                    fullWidth
                    error={Boolean(errors.sub_service)}
                    //  helperText={errors.sub_service}
                  >
                    {subServices.map((service) => (
                      <MenuItem key={service.id} value={service.id}>
                        {service.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              {errors.sub_service && (
                <span style={{ color: "red" }}>
                  {errors.sub_service.message}
                </span>
              )}
            </Grid>

            <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: "black" }}>
                Assign To
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <Controller
                name="assign_to"
                control={control}
                rules={{ required: "Field is required" }}
                defaultValue={enquiryDetails?.assign_to || parseInt(userId)}
                render={({ field }) => (
                  <TextField
                    select
                    size="small"
                    {...field}
                    InputProps={{
                      style: { borderRadius: "12px" },
                    }}
                    fullWidth
                    error={Boolean(errors.assign_to)}
                    // helperText={errors.assign_to}
                    //value={ advisorId || ''}
                  >
                    {users.map((item) => (
                      <MenuItem key={item.id} value={item.id}>
                        {item.first_name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              {errors.assign_to && (
                <span style={{ color: "red" }}>{errors.assign_to.message}</span>
              )}
            </Grid>

            <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: "black" }}>
                Preferred Appointment Date
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <Controller
                name="preferred_appointment_date"
                control={control}
                defaultValue={new Date().toISOString().split("T")[0]}
                rules={{ required: "Field is required" }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    type="date"
                    {...field}
                    InputProps={{
                      style: { borderRadius: "12px" },
                      inputProps: {
                        min: new Date().toISOString().split("T")[0],
                      },
                    }}
                    fullWidth
                    error={Boolean(errors.preferred_appointment_date)}
                    // helperText={errors.preferred_appointment_date}
                  ></TextField>
                )}
              />
              {errors.preferred_appointment_date && (
                <span style={{ color: "red" }}>
                  {errors.preferred_appointment_date.message}
                </span>
              )}
            </Grid>

            <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: "black" }}>
                Time
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <Controller
                name="time"
                control={control}
                defaultValue={currentTime}
                // value={currentTime}
                rules={{ required: "Field is required" }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    type="time"
                    {...field}
                    InputProps={{
                      style: { borderRadius: "12px" },
                    }}
                    fullWidth
                    error={Boolean(errors.time)}
                    // helperText={errors.time}
                  ></TextField>
                )}
              />
              {errors.time && (
                <span style={{ color: "red" }}>{errors.time.message}</span>
              )}
            </Grid>

            <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: "black" }}>
                Lead Source Type
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <Controller
                name="lead_source_type_id"
                control={control}
                defaultValue={enquiryDetails?.lead_source_type_id}
                rules={{ required: "Lead Source is required" }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    select
                    {...field}
                    InputProps={{
                      style: { borderRadius: "12px" },
                    }}
                    fullWidth
                    error={Boolean(errors.lead_source_type_id)}
                    /*onChange={e => {
                                            field.onChange(e);
                                            handleLeadSourceTypeChange(e.target.value);
                                          }}*/
                    value={selectedSourceType}
                    onChange={handleSourceTypeChange}
                    // helperText={errors.lead_source_type}
                  >
                    {leadSources.map((type) => (
                      <MenuItem key={type.id} value={type.id}>
                        {type.name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              {errors.lead_source_type_id && (
                <span style={{ color: "red" }}>
                  {errors.lead_source_type_id.message}
                </span>
              )}
            </Grid>

            <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: "black" }}>
                Sub Lead Source Type
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <Controller
                name="sub_lead_source_type_id"
                control={control}
                rules={{ required: "Sub Lead Source is required" }}
                render={({ field }) => (
                  <ThemeProvider theme={theme}>
                    <Autocomplete
                      disablePortal
                      size="small"
                      {...field}
                      value={selectedSubSourceType}
                      onChange={handleSubSourceTypeChange}
                      noOptionsText={
                        selectedSourceType === null
                          ? "Select Source Type First"
                          : subLeadSources.length === 0
                          ? "No Data Available"
                          : ""
                      }
                      options={subLeadSources}
                      getOptionLabel={(option) => option.source_type}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                        />
                      )}
                    />
                  </ThemeProvider>
                )}
              />
              {errors.sub_lead_source_type_id && (
                <span style={{ color: "red" }}>
                  {errors.sub_lead_source_type_id.message}
                </span>
              )}
            </Grid>

            {/* Referece By  */}
            {selectedSubSourceTypeID === "Reference" && (
              <Grid item xs={6} md={4} lg={3}>
                <InputLabel style={{ color: "black" }}>
                  Reference By
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.5em",
                    }}
                  >
                    *
                  </span>
                </InputLabel>
                <Controller
                  name="reference_walkin_id"
                  control={control}
                  rules={{ required: "Reference by is required" }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      size="small"
                      value={referenceBy}
                      onChange={handleReferenceChange}
                      options={[
                        ...referenceByList,
                        { id: "Other", name: "Other" },
                      ]}
                      getOptionLabel={(option) => option.name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
                {errors.reference_walkin_id && (
                  <span style={{ color: "red" }}>
                    {errors.reference_walkin_id.message}
                  </span>
                )}
              </Grid>
            )}

            {showOtherField && (
              <Grid item xs={6} md={4} lg={3}>
                <InputLabel style={{ color: "black" }}>
                  Employee Reference List
                  <span
                    style={{
                      color: "red",
                      position: "absolute",
                      marginLeft: "4px",
                      fontSize: "1.5em",
                    }}
                  >
                    *
                  </span>
                </InputLabel>
                <Controller
                  name="reference_employee_id"
                  control={control}
                  //rules={{ required: "Reference by is required" }}
                  render={({ field }) => (
                    <Autocomplete
                      {...field}
                      size="small"
                      value={referenceEmployee}
                      onChange={handleReferenceEmployeeChange}
                      options={users}
                      getOptionLabel={(option) => option.first_name}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          InputProps={{
                            ...params.InputProps,
                            style: { borderRadius: "12px" },
                          }}
                          fullWidth
                        />
                      )}
                    />
                  )}
                />
              </Grid>
            )}

            <Grid item xs={6} md={4} lg={3}>
              <InputLabel style={{ color: "black" }}>
                Reason for visit
                <span
                  style={{
                    color: "red",
                    position: "absolute",
                    marginLeft: "4px",
                    fontSize: "1.5em",
                  }}
                >
                  *
                </span>
              </InputLabel>
              <Controller
                name="event_reason_id"
                control={control}
                rules={{ required: "Reason for visit is required" }}
                render={({ field }) => (
                  <TextField
                    size="small"
                    select
                    {...field}
                    InputProps={{
                      style: { borderRadius: "12px" },
                    }}
                    fullWidth
                    error={Boolean(errors.event_reason_id)}
                  >
                    {eventReasons.map((subSource) => (
                      <MenuItem key={subSource.id} value={subSource.id}>
                        {subSource.reason_name}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              />
              {errors.event_reason_id && (
                <span style={{ color: "red" }}>
                  {errors.event_reason_id.message}
                </span>
              )}
            </Grid>
          </Grid>

          <Box mt={2} display="flex" justifyContent="right">
            <Button
              variant="contained"
              color="secondary"
              onClick={handleSubmit(onSubmit)}
            >
              Submit
            </Button>
          </Box>
        </Item>
      )}

      <CustomSnackbar
        open={snackbarOpen}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
        severity={snackbarSeverity}
      />

      {/* <Dialog open={showForm}>
                <DialogTitle>Pick Personal Details</DialogTitle>
                <DialogContent>
                   
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowForm(false)}>Cancel</Button>
                    <Button onClick={() => setShowForm(false)}>Save</Button>
                </DialogActions>
            </Dialog> */}

      <Dialog open={showNoEnquiryPopup}>
        <DialogTitle variant="h4">No Enquiry Found</DialogTitle>
        <DialogContent>
          No enquiry exists with the provided details.
        </DialogContent>
        <DialogActions>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => setShowNoEnquiryPopup(false)}
          >
            OK
          </Button>
        </DialogActions>
      </Dialog>
      <Backdrop open={searching} style={{ zIndex: 999 }}>
        <CircularProgress color="inherit" />
      </Backdrop>

      <Backdrop open={isSavingLoading} style={{ zIndex: 9999 }}>
        <Typography variant="h4" color="error" fontWeight={800} marginRight={2}>
          Saving Details
        </Typography>
        <CircularProgress color="primary" />
      </Backdrop>
    </>
  );
}

export default AddWalkIn;
