import React from 'react'
import { useTheme } from '@mui/material/styles';
import { Box, Typography, useMediaQuery } from '@mui/material';

function Logo() {
    const theme = useTheme();
    const matchDownSM = useMediaQuery(theme.breakpoints.down('md'));

  return (
    <Box display='flex' alignItems='center'>
            <img src='/test_master_logo_dark 1.png' alt="Berry" width="100" />
            <Box display='flex' flexDirection='column'>
            <Typography flexWrap color='black' variant='h2'>Test</Typography> 
            <Typography flexWrap color='black' variant='h2'>Masters</Typography> 
            </Box>
        </Box>
  )
}

export default Logo