import React, { useEffect, useState } from 'react';

// material-ui
import { useTheme } from '@mui/material/styles';
import {
    Box,
    Button,
    Chip,
    CircularProgress,
    Collapse,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Tooltip,
    Typography
} from '@mui/material';

// project imports
import Avatar from '../../components/ui-component/avatar/Avatar'; 
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import TaskAltIcon from '@mui/icons-material/TaskAlt';

//import { useDispatch, useSelector } from 'store';
//import { getUsersListStyle1 } from 'store/slices/user';

// assets
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ChatBubbleTwoToneIcon from '@mui/icons-material/ChatBubbleTwoTone';
import BlockTwoToneIcon from '@mui/icons-material/BlockTwoTone';
import SubCard from '../../components/ui-component/card/SubCard';
import { CSVExport } from '../../components/ui-component/table/CSVExport';
import ButtonComponent from '../../../components/button/ButtonComponent';
import CreateClassDialog from '../../../pages/adminPages/classes/AddClass';
import CustomSnackbar from '../../components/ui-component/snackbar/CustomSnackbar';


const API_ENDPOINT = process.env.REACT_APP_API_ENDPOINT
// ==============================|| USER LIST 1 ||============================== //
const trainerData2 = {
    id: 1,
    name: 'Ms Pooja',
    email: 'mscoja@gmail.com',
    branch_name: 'Gurgaon',
    expertise: 'IELTS',
    status: 'active',
    classes: [
        {
            id: 1,
            slot: '8:00 - 10:00',
            test_type: 'IELTS',
            delivery_mode: 'offline',
            capacity: 25,
            active_students: 1,
            no_of_slots_left: 24,
            status: 'active'
        },
        {
            id: 2,
            slot: '10:00 - 12:00',
            test_type: 'IELTS',
            delivery_mode: 'offline',
            capacity: 25,
            active_students: 0,
            no_of_slots_left: 25,
            status: 'inactive'
        },
        {
            id: 3,
            slot: '12:00 - 14:00',
            test_type: 'IELTS',
            delivery_mode: 'offline',
            capacity: 25,
            active_students: 1,
            no_of_slots_left: 24,
            status: 'active'
        },
        {
            id: 4,
            slot: '14:00 - 16:00',
            test_type: 'IELTS',
            delivery_mode: 'offline',
            capacity: 25,
            active_students: 10,
            no_of_slots_left: 15,
            status: 'active'
        }
    ]
}
const TrainerList = ({searchQuery, filterStatus, filterBranch}) => {
    const theme = useTheme();
    //const dispatch = useDispatch();
    const [open, setOpen] = useState(false)
    const [trainerData, setTrainerData] = useState([])
    //const [data, setData] = useState([trainerData]);
    const [dialogOpen1, setDialogOpen1] = useState(false);
    const [dialogOpen2, setDialogOpen2] = useState(false);
    const [selectedTrainer, setSelectedTrainer] = useState(null);
    const [selectedClass, setSelectedClass] = useState(null);
    const [isCreateDialogOpen, setIsCreateDialogOpen] = useState(false);
    const [filteredTrainers, setFilteredTrainers] = useState([])
    const [selectedTrainerId, setSelectedTrainerId] = useState(null);
   
    const [isLoading, setIsLoading] = useState(false);

    const [openRows, setOpenRows] = React.useState({});

    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [snackbarSeverity, setSnackbarSeverity] = useState('success');
    
        const showSnackbar = (message, severity) => {
            setSnackbarMessage(message);
            setSnackbarSeverity(severity);
            setSnackbarOpen(true);
        };


 const handleToggleRow = (index) => {
    setOpenRows((prevOpenRows) => {
      const isOpen = prevOpenRows[index] || false;
      return { ...prevOpenRows, [index]: !isOpen };
    });
  };
 

    const filteredData = trainerData?.filter((item) =>
    item.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  const handleMakeActive = (trainerId, userId, status) => {
    console.log(trainerId)
    const trainer = trainerData.find((item) => item.id === trainerId);

    if (trainer.classes.length > 0) {
      // If the trainer has active classes, show a confirmation dialog
      setDialogOpen1(true);
      setSelectedTrainer(trainer);
    } else {
        const newStatus = status === 'active' ? 'inactive' : 'active';

        const apiUrl = `${API_ENDPOINT}route/updateemployee/${userId}`;

        fetch(apiUrl, {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify({
                status: newStatus, // Set the new status
            }),
        })
            .then((response) => {
                if (response.ok) {
                    const updatedData = trainerData.map((item) =>
                    item.id === trainerId ? { ...item, status: newStatus } : item
                );
                setTrainerData(updatedData);

                    showSnackbar(`Trainer ${newStatus === 'active' ? 'made active' : 'made inactive'} successfully`, 'success');
                } else {
                    // Handle error case
                    console.error('Failed to update class status');
                    showSnackbar('Failed to update class status', 'error');
                }
            })
            .catch((error) => {
                console.error('Error updating class status:', error);
                showSnackbar('Failed to update class status', 'error');
            })
    }
  };

  const handleCloseDialog = () => {
    setDialogOpen1(false);
    setSelectedTrainer(null);
  };

  const handleMakeClassInactive = (classId) => {
    const trainerIndex = trainerData.findIndex((trainer) => trainer.classes.some((c) => c.id === classId));
    const classIndex = trainerData[trainerIndex]?.classes.findIndex((c) => c.id === classId);

    if (classIndex !== -1) {
      const selectedClass = trainerData[trainerIndex]?.classes[classIndex];

      if (selectedClass.active_students > 0) {
        // If there are active students, show a confirmation dialog
        setOpen(true);
        setSelectedClass(selectedClass);
      } else {
        // Perform the logic to make the class inactive (e.g., update status in the API)
        // ...

        // You might also want to update the local state to reflect the change
        const updatedData = [...trainerData];
        updatedData[trainerIndex].classes[classIndex].status = 'inactive';
        setTrainerData(updatedData);
      }
    }
  };

  const handleMakeClassStatusChange = (classId) => {
    const trainerIndex = trainerData.findIndex((trainer) => trainer.classes.some((c) => c.id === classId));
    const classIndex = trainerData[trainerIndex]?.classes.findIndex((c) => c.id === classId);

    if (classIndex !== -1) {
        const selectedClass = trainerData[trainerIndex]?.classes[classIndex];

        if (selectedClass.active_students > 0 && selectedClass.status === 'active') {
            // If there are active students and the class is active, show a confirmation dialog
            setOpen(true);
            setSelectedClass(selectedClass);
        } else {
            // Perform the logic to change the class status (e.g., update status in the API)
            const newStatus = selectedClass.status === 'active' ? 'inactive' : 'active';

            const apiUrl = `${API_ENDPOINT}route/updateclass/${classId}`;

            fetch(apiUrl, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    status: newStatus, // Set the new status
                }),
            })
                .then((response) => {
                    if (response.ok) {
                        // Update local state to reflect the change
                        const updatedData = [...trainerData];
                        updatedData[trainerIndex].classes[classIndex].status = newStatus;
                        setTrainerData(updatedData);

                        // Optional: Notify the user that the class status has been changed
                        showSnackbar(`Trainer ${newStatus === 'active' ? 'made active' : 'made inactive'} successfully`, 'success');
                    } else {
                        // Handle error case
                        console.error('Failed to update trainer status');
                        showSnackbar('Failed to update trainer status', 'error');
                    }
                })
                .catch((error) => {
                    console.error('Error updating trainer status:', error);
                    showSnackbar('Failed to update trainer status', 'error');
                });
        }
    }
};

  const handleCloseDialog2 = () => {
    setOpen(false);
    setSelectedClass(null);
  };

  useEffect(() => {
    setIsLoading(true)
    // Fetch employee data
    fetch(`${API_ENDPOINT}route/getalltrainer`)
      .then((response) => response.json())
      .then((data) => {
        // Update the rows state with the fetched data
        console.log(data)
        setTrainerData(data);
        setIsLoading(false)
      })
      .catch((error) => {
        console.error('Error fetching employee data:', error);
        setIsLoading(false)
      });
  }, []);

  useEffect(() => {
    // Filter employees based on the search query, role, and branch
    setFilteredTrainers(
      trainerData.filter(
        (trainer) =>
          (trainer.name?.toLowerCase()?.includes(searchQuery.toLowerCase()) || false) &&
          (filterStatus ? trainer.status === filterStatus : true) &&
          (filterBranch ? trainer.branch_id?.id === filterBranch : true)
      )
    );
  }, [searchQuery, trainerData, filterStatus, filterBranch]);

  const handleOpenCreateDialog = (trainerId) => {
    setSelectedTrainerId(trainerId);
    setIsCreateDialogOpen(true);
  };

    return (
        <TableContainer>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell sx={{ pl: 3 }} />
                        <TableCell>Trainer Profile</TableCell>
                        <TableCell>Branch</TableCell>
                        <TableCell>Expertise</TableCell>
                        <TableCell>Status</TableCell>
                        <TableCell align="center" sx={{ pr: 3 }}>
                            Actions
                        </TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                {isLoading ? (
                            <Box style={{ minHeight: '50vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <CircularProgress color="primary" />
                          </Box>
                        ) : (

                            filteredTrainers.length === 0 ? (
                                <Grid item xs={12} style={{ minHeight: '100vh', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <Typography variant='h5'>No trainer data available</Typography>
                                </Grid>
                            ) : (
                        filteredTrainers.map((row, index) => (
                            <>
                            <TableRow hover key={index} sx={{ '& > *': { borderBottom: 'unset' } }}  >
                            <TableCell sx={{ pl: 3 }}>
                            <IconButton aria-label="expand row" size="small" /*onClick={() => setOpen(!open)}*/ onClick={() => handleToggleRow(index)}>
                                {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                            </IconButton>
                            </TableCell>
                                <TableCell>
                                    <Grid container spacing={2} alignItems="center">
                                        <Grid item>
                                            <Avatar alt="User 1" />
                                        </Grid>
                                        <Grid item xs zeroMinWidth>
                                            <Typography align="left" variant="subtitle1" component="div">
                                                {row.name}
                                               
                                            </Typography>
                                            <Typography align="left" variant="subtitle2" noWrap>
                                                {row.email}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </TableCell>
                                <TableCell>{row.branch_id?.branch_name}</TableCell>
                                <TableCell>{row.expertise}</TableCell>
                               
                                <TableCell>
                                    {row.status === 'active' && (
                                        <Chip
                                            label="Active"
                                            size="small"
                                            sx={{
                                                background:
                                                    theme.palette.mode === 'dark'
                                                        ? theme.palette.dark.main
                                                        : theme.palette.success.light + 60,
                                                color: theme.palette.success.dark
                                            }}
                                        />
                                    )}
                                    {row.status === 'inactive' && (
                                        <Chip
                                            label="Inactive"
                                            size="small"
                                            sx={{
                                                background:
                                                    theme.palette.mode === 'dark'
                                                        ? theme.palette.dark.main
                                                        : theme.palette.orange.light + 80,
                                                color: theme.palette.orange.dark
                                            }}
                                        />
                                    )}
                                    
                                </TableCell>
                                <TableCell align="center" sx={{ pr: 3 }}>
                                    <Stack direction="row" justifyContent="center" alignItems="center" spacing={2}>
                                        
                                    {row.status === 'active' ? (
                           
                                <Button
                                    color="error"
                                    variant='contained'
                                    size="small"
                                    sx={{
                                        color: theme.palette.dark,
                                        borderColor: theme.palette.orange.main,
                                        '&:hover ': { background: theme.palette.orange.light }
                                    }}
                                    
                                    onClick={() => handleMakeActive(row.id, row.user_id, row.status)}
                                >
                                    Make Inactive
                                </Button>
                            
                        ) : (
                           
                           
                                <Button
                                    color="secondary"
                                    variant='contained'
                                    size='small'
                                    sx={{
                                        color: theme.palette.dark,
                                        borderColor: theme.palette.orange.main,
                                        '&:hover ': { background: theme.palette.orange.light }
                                    }}
                                    onClick={() => handleMakeActive(row.id, row.user_id, row.status)}
                                >  
                                    Make Active
                                </Button>
                        )}

                                                            <ButtonComponent 
                                                            title='Add Class'
                                                            path={() => handleOpenCreateDialog(row.id)}
                                                            ></ButtonComponent>

                                    </Stack>
                                </TableCell>
                            </TableRow>

                        <TableRow>
                        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
                            <Collapse in={openRows[index]} timeout="auto" unmountOnExit>
                               
                                    <Box sx={{ margin: 1 }}>
                                        <TableContainer>
                                            <SubCard
                                                sx={{ bgcolor: theme.palette.mode === 'dark' ? 'dark.800' : 'grey.50', mb: 2 }}
                                                title="Classes"
                                                content={false}
                                                secondary={
                                                    <Stack direction="row" spacing={2} alignItems="center">
                                                      {/*}  <ButtonComponent 
                                                            title='Add Class'
                                                            path={() => setIsCreateDialogOpen(true)}
                                                ></ButtonComponent>*/}
                                                        <CSVExport
                                                            data={row.classes?.map((classe) => classe)}
                                                            filename="collapse-table.csv"
                                                           // header={header}
                                                        />
                                                    </Stack>
                                                }
                                            >
                                                <Table size="small" aria-label="classes">
                                                    <TableHead>
                                                        <TableRow>
                                                            <TableCell>Slot</TableCell>
                                                            <TableCell>Test Type</TableCell>
                                     
                                                            <TableCell>Delivery mode</TableCell>
                                                            <TableCell>Capacity</TableCell>
                                                            <TableCell>Active Students</TableCell>
                                                            <TableCell>Status</TableCell>
                                                            <TableCell align="center" sx={{ pr: 3 }}>
                                                                Actions
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {row.classes?.map((classe => (
                                                            <TableRow key={classe.id}>
                                                                <TableCell component="th" scope="row">
                                                                    {classe.slot}
                                                                </TableCell>
                                                                <TableCell>{classe.test_type.name}</TableCell>
                                                                <TableCell >{classe.delivery_mode}</TableCell>
                                                                <TableCell >{classe.capacity}</TableCell>
                                                                <TableCell >{classe.active_students}</TableCell>
                                                                <TableCell>
                                    {classe.status === 'active' && (
                                        <Chip
                                            label="Active"
                                            size="small"
                                            sx={{
                                                background:
                                                    theme.palette.mode === 'dark'
                                                        ? theme.palette.dark.main
                                                        : theme.palette.success.light + 60,
                                                color: theme.palette.success.dark
                                            }}
                                        />
                                    )}
                                    {classe.status === 'inactive' && (
                                        <Chip
                                            label="Inactive"
                                            size="small"
                                            sx={{
                                                background:
                                                    theme.palette.mode === 'dark'
                                                        ? theme.palette.dark.main
                                                        : theme.palette.orange.light + 80,
                                                color: theme.palette.orange.dark
                                            }}
                                        />
                                    )}
                                </TableCell>
                                <TableCell align="center" sx={{ pr: 3 }}>
                                    <Stack direction="row" justifyContent="center" alignItems="center">
                                        
                                    <Button
                                    color={selectedClass && selectedClass?.status === 'active' ? 'secondary' : 'error'}
                                    variant='contained'
                                    size='small'
                                    sx={{
                                        color: theme.palette.dark,
                                        borderColor: theme.palette.orange.main,
                                        '&:hover ': { background: theme.palette.orange.light }
                                    }}
                                   // onClick={() => handleMakeClassStatusChange(selectedClass?.id)}
                                >
                                    {selectedClass && selectedClass?.status === 'active' ? 'Make Active' : 'Make Inactive'}
                                </Button>

                                    </Stack>
                                </TableCell>
                                                            </TableRow>
                                                        )))}
                                                    </TableBody>
                                                </Table>
                                            </SubCard>
                                        </TableContainer>
                                    </Box>
                              
                            </Collapse>
                        </TableCell>
                        </TableRow>
                        </>
                        ))
                        ))}
                </TableBody>
                      
            </Table>

            <Dialog open={dialogOpen1} onClose={handleCloseDialog}>
        <DialogTitle variant='h3'>Active Classes</DialogTitle>
        <DialogContent>
          <Typography>
            {`Active classes for ${selectedTrainer?.name}. Make trainer classes inactive before changing status.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          
        </DialogActions>
      </Dialog>

      <Dialog open={dialogOpen2} onClose={handleCloseDialog2}>
        <DialogTitle>Active Students</DialogTitle>
        <DialogContent>
          <Typography>
            {`Active students in class ${selectedClass?.slot}. Cannot make class inactive with active students.`}
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog2}>Cancel</Button>
         
        </DialogActions>
      </Dialog>

      <CreateClassDialog
        open={isCreateDialogOpen}
        onClose={() => setIsCreateDialogOpen(false)}
        trainerId= {selectedTrainerId}
        
      />

            <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                severity={snackbarSeverity}
                />

        </TableContainer>
    );
};

export default TrainerList;