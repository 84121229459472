import { Box, Grid, Typography, useMediaQuery ,Button} from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

function Page500Server() {
  const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down('sm'));
    const buttonstyle1={
        backgroundColor: '#019E6F',
            borderRadius: '12px',
            fontSize: '24px',
            width:"180px",
            height:"40px",
            flexShrink:0,
            marginTop:"17px",
           color:'#FFF',
            fontFamily:'Poppins',
            fontWeight:400,
      }

  return (
    <div>
        <Box className="box" sx={{ height: '100vh', width: '100%', position: 'relative',display:'flex', justifyContent:'center',alignItems:'center' }} >
 <Box
        className="rectangle"
        sx={{
          background: 'linear-gradient(248deg, #FFF6F0 -41.57%, rgba(255, 246, 240, 0.00) 97.88%)',
          height: '100%',
          position: 'absolute',
          top: 0,
          left: 0,
          width: '100%',
          zIndex: -1,
        }}
      />

      <Grid container justifyContent='center' flexDirection='column' textAlign='center' >

      <Typography sx={{ height: isSmallScreen ? '250px' : '250px', textAlign:'center' }}>
  <img src='/500-server-error.png' alt='description' style={{ height: '100%', objectFit: 'cover' }} />
</Typography>

    <Typography variant='h2' sx={{color:'#000',fontFamily:'Poppins',fontSize:'50px',fontStyle:'normal',fontWeight:600}}>Server under Maintenance</Typography>
    <Typography sx={{textAlign:'center',color:'#8A8A8A',fontFamily:'Poppins',fontSize:'20px',fontStyle:'normal',fontWeight:400}} >We're experencing an Internal server problem. <br/> Please try again later.</Typography>
   
   <Link to='/login'>
   <Button variant="contained" style={buttonstyle1}>Go Home</Button>
   </Link>
      </Grid>
      
</Box>
    </div>
  )
}

export default Page500Server